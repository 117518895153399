@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 70px;
  height: 70px;
  border: 7px solid #ddd;
  border-top: 7px solid #404472;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}
.loading-spinner-small {
  width: 40px;
  height: 40px;
  border: 5px solid #ddd;
  border-top: 5px solid #404472;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.spinner-container-small {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 50px;
  position: absolute;
  left: 40%;
}