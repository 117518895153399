/*! -----------------------------------------------------------------------------O------

    Template Name: Mofi Admin
    Template URI: http://admin.pixelstrap.com/Mofi/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------

    1.Base css
        1.1 Reset CSS
        1.2 Typography CSS

    2.Components css
        2.1 According CSS
        2.2 Alert CSS
        2.3 Avatars CSS
        2.4 Badge CSS
        2.5 Bookmark CSS
        2.6 Breadcrumb CSS
        2.7 Basic-card CSS
        2.8 Builders CSS
        2.9 Buttons CSS
        2.10 Card CSS
        2.11 Color CSS
        2.12 Datatable CSS
        2.13 Datapicker CSS
        2.14 Dropdown CSS
        2.15 Form-builder CSS
        2.16 Form-input CSS
        2.17 Form-wizard CSS
        2.18 Forms CSS
        2.19 Icons CSS
        2.20 List CSS
        2.21 Loader CSS 
        2.22 Modal CSS
        2.23 Popover CSS
        2.24 Print CSS
        2.25 Radio CSS
        2.26 Ribbon CSS
        2.27 Range-slider CSS
        2.28 Switch CSS
        2.29 Tab CSS
        2.30 Table CSS
        2.31 Toasts CSS
        2.32 Touchspin CSS
        2.33 Tour CSS
        2.34 Tree CSS
        2.35 Typeahead-search CSS
        2.36 Scrollbar CSS

    3. Pages
        3.1 Blog CSS
        3.2 Bookmark-app CSS
        3.3 Cart CSS
        3.4 Chart CSS
        3.5 Chat CSS
        3.6 Checkout CSS
        3.7 Comingsoon CSS
        3.8 Contacts CSS
        3.9 Dashboard_2 CSS
        3.10 Dashboard_3 CSS 
        3.11 Dashboard_4 CSS
        3.12 Dashboard_5 CSS
        3.13 Dashboard_default CSS
        3.14 Ecommerce CSS
        3.15 Email-application CSS
        3.16 Errorpage CSS
        3.17 Faq CSS
        3.18 File CSS
        3.19 Gallery CSS
        3.20 Helper CSS
        3.21 Internationalization CSS
        3.22 Job-search CSS
        3.23 Jsgrid CSS
        3.24 Kanban CSS
        3.25 Knowledgebase CSS
        3.26 Landing CSS
        3.27 Language CSS
        3.28 Learning CSS
        3.29 Login CSS
        3.30 Megaoption CSS
        3.31 Order-history CSS
        3.32 Page CSS
        3.33 Pricing CSS
        3.34 Progress CSS
        3.35 Projectlist CSS
        3.36 Rating CSS
        3.37 Social-app CSS
        3.38 Sweet-alert CSS
        3.39 Task CSS
        3.40 Timeline-v CSS
        3.41 User-profile CSS
        3.42 Wishlist CSS
        3.43 Dashboard_6.CSS
        3.44 Dashboard_7 CSS
        3.45 Dashboard_8.CSS

    4. Themes
        4.1. Dark CSS
        4.2 Theme-customizer CSS
        4.3 Update CSS

    5. Layout
        5.1 Footer CSS
        5.2 Grid CSS
        5.3 Header CSS
        5.4 Navs CSS
        5.5 Search CSS
        5.6 Select2 CSS
        5.7 Sidebar CSS
        5.8 Rtl CSS
        5.9 Box-layout CSS

 */

@import url('https://unpkg.com/boxicons@latest/css/boxicons.min.css');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@500;700&amp;family=Poppins:wght@300;400;500;600;700;800;900&amp;display=swap');



:root {
  --theme-deafult: #337ab7;
  --theme-secondary: #48A3D7;
  --light-background: rgba(242, 243, 247, 0.7);
  --body-font-color: #2F2F3B;
  --chart-border: #ECF3FA;
  --recent-chart-bg: #FCFCFD;
  --light-bg: #EEF1F6;
  --white: #fff;
  --light2: #F5F6F9;
  --sidebar-border: rgba(0, 0, 0, 0.1);
  --chart-text-color: rgba(82, 82, 108, 0.8);
  --recent-solid-border: rgba(82, 82, 108, 0.3);
  --chart-solid-border: rgba(82, 82, 108, 0.2);
  --chart-progress-light: rgba(82, 82, 108, 0.1);
  --recent-box-bg: #F2F4F7;
  --recent-border: rgba(97, 101, 122, 0.25);
  --course-light-btn: #F2F3F7;
  --course-bg: #F7F7F7;
  --balance-profie-bg: #E0DFEF;
  --view-grid-bg: #ECF3FA80;
  --view-border-marker: #cfcdfc;
  --light-slate: #F5F6F9;
  --text-gray: rgba(82, 82, 108, 0.8);
  --product-shadow: 0px 1px 0px rgba(82, 82, 108, 0.15);
  --light-shade-primary: rgba(245, 244, 255);
  --fc-button-bg-color: var(--theme-deafult);
  --fc-button-active-bg-color: var(--theme-deafult);
  --fc-button-active-border-color: var(--theme-deafult);
  --fc-button-border-color: var(--theme-deafult);
  --fc-button-hover-bg-color: var(--theme-deafult);
  --fc-button-hover-border-color: var(--theme-deafult);
  --bs-heading-color: #3D434A;
  --normal: #ECEAF3;
  --normal-shadow: #D9D8E3;
  --normal-mouth: #9795A4;
  --normal-eye: #595861;
  --active: #F8DA69;
  --active-shadow: #F4B555;
  --active-mouth: #F05136;
  --active-eye: #313036;
  --active-tear: #76b5e7;
  --active-shadow-angry: #e94f1d;
  /* Do not show the "remove tag" (x) button when only a single tag remains */
}
:root .customLook {
  --tag-bg: var(--theme-deafult);
  --tag-hover: var(--theme-secondary);
  --tag-text-color: var(--white);
  --tags-border-color: silver;
  --tag-border-radius: 25px;
  --tag-text-color--edit: #111;
  --tag-remove-bg: var(--tag-hover);
  --tag-pad: .6em 1em;
  --tag-inset-shadow-size: 1.35em;
  --tag-remove-btn-bg--hover: #000;
  --tagify-dd-color-primary: var(var(--theme-deafult));
  --tagify-dd-bg-color: var(--course-light-btn);
}


/**=====================
    1.1 Reset CSS start
==========================**/
.font-outfit {
  font-family: "Nunito", sans-serif, sans-serif;
}

.f-light {
  color: rgba(155, 155, 155, 0.8);
}

svg.f-light {
  fill: #9B9B9B;
  opacity: 0.8;
}

.light-card {
  background-color: var(--light-background);
}

.light-background {
  background-color: var(--light-bg);
}

.icon-arrow-down.icon-rotate,
.icon-arrow-up.icon-rotate {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  display: inline-block;
}

/*====== Padding css starts ======*/
.p-0 {
  padding: 0px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-15 {
  padding: 15px;
}

.p-20 {
  padding: 20px;
}

.p-25 {
  padding: 25px;
}

.p-30 {
  padding: 30px;
}

.p-35 {
  padding: 35px;
}

.p-40 {
  padding: 40px;
}

.p-45 {
  padding: 45px;
}

.p-50 {
  padding: 50px;
}

/*====== Padding css ends ======*/
/*====== Padding-left css starts ======*/
.p-l-0 {
  padding-left: 0px;
}

.p-l-5 {
  padding-left: 5px;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-20 {
  padding-left: 20px;
}

.p-l-25 {
  padding-left: 25px;
}

.p-l-30 {
  padding-left: 30px;
}

.p-l-35 {
  padding-left: 35px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-l-45 {
  padding-left: 45px;
}

.p-l-50 {
  padding-left: 50px;
}

/*====== Padding-left css ends ======*/
/*====== Padding-top css starts ======*/
.p-t-0 {
  padding-top: 0px !important;
}

.p-t-5 {
  padding-top: 5px !important;
}

.p-t-10 {
  padding-top: 10px !important;
}

.p-t-15 {
  padding-top: 15px !important;
}

.p-t-20 {
  padding-top: 20px !important;
}

.p-t-25 {
  padding-top: 25px !important;
}

.p-t-30 {
  padding-top: 30px !important;
}

.p-t-35 {
  padding-top: 35px !important;
}

.p-t-40 {
  padding-top: 40px !important;
}

.p-t-45 {
  padding-top: 45px !important;
}

.p-t-50 {
  padding-top: 50px !important;
}

/*====== Padding-top css ends ======*/
/*====== Padding-bottom css starts ======*/
.p-b-0 {
  padding-bottom: 0px !important;
}

.p-b-5 {
  padding-bottom: 5px !important;
}

.p-b-10 {
  padding-bottom: 10px !important;
}

.p-b-15 {
  padding-bottom: 15px !important;
}

.p-b-20 {
  padding-bottom: 20px !important;
}

.p-b-25 {
  padding-bottom: 25px !important;
}

.p-b-30 {
  padding-bottom: 30px !important;
}

.p-b-35 {
  padding-bottom: 35px !important;
}

.p-b-40 {
  padding-bottom: 40px !important;
}

.p-b-45 {
  padding-bottom: 45px !important;
}

.p-b-50 {
  padding-bottom: 50px !important;
}

/*====== Padding-bottom css ends ======*/
/*====== Padding-right css starts ======*/
.p-r-0 {
  padding-right: 0px;
}

.p-r-5 {
  padding-right: 5px;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-20 {
  padding-right: 20px;
}

.p-r-25 {
  padding-right: 25px;
}

.p-r-30 {
  padding-right: 30px;
}

.p-r-35 {
  padding-right: 35px;
}

.p-r-40 {
  padding-right: 40px;
}

.p-r-45 {
  padding-right: 45px;
}

.p-r-50 {
  padding-right: 50px;
}

/*====== Padding-right css ends ======*/
/*====== Margin css starts ======*/
.m-0 {
  margin: 0px !important;
}

.m-5 {
  margin: 5px !important;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.m-25 {
  margin: 25px !important;
}

.m-30 {
  margin: 30px !important;
}

.m-35 {
  margin: 35px !important;
}

.m-40 {
  margin: 40px !important;
}

.m-45 {
  margin: 45px !important;
}

.m-50 {
  margin: 50px !important;
}

/*====== Margin css ends ======*/
/*====== Margin-top css starts ======*/
.m-t-0 {
  margin-top: 0px !important;
}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-t-15 {
  margin-top: 15px !important;
}

.m-t-20 {
  margin-top: 20px !important;
}

.m-t-25 {
  margin-top: 25px !important;
}

.m-t-30 {
  margin-top: 30px !important;
}

.m-t-35 {
  margin-top: 35px !important;
}

.m-t-40 {
  margin-top: 40px !important;
}

.m-t-45 {
  margin-top: 45px !important;
}

.m-t-50 {
  margin-top: 50px !important;
}

/*====== Margin-top css ends ======*/
/*====== Margin-Bottom css starts ======*/
.m-b-0 {
  margin-bottom: 0px !important;
}

.m-b-5 {
  margin-bottom: 5px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.m-b-15 {
  margin-bottom: 15px !important;
}

.m-b-20 {
  margin-bottom: 20px !important;
}

.m-b-25 {
  margin-bottom: 25px !important;
}

.m-b-30 {
  margin-bottom: 30px !important;
}

.m-b-35 {
  margin-bottom: 35px !important;
}

.m-b-40 {
  margin-bottom: 40px !important;
}

.m-b-45 {
  margin-bottom: 45px !important;
}

.m-b-50 {
  margin-bottom: 50px !important;
}

/*====== Margin-Bottom css ends ======*/
/*====== Margin-left css starts ======*/
.m-l-0 {
  margin-left: 0px !important;
}

.m-l-5 {
  margin-left: 5px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.m-l-15 {
  margin-left: 15px !important;
}

.m-l-20 {
  margin-left: 20px !important;
}

.m-l-25 {
  margin-left: 25px !important;
}

.m-l-30 {
  margin-left: 30px !important;
}

.m-l-35 {
  margin-left: 35px !important;
}

.m-l-40 {
  margin-left: 40px !important;
}

.m-l-45 {
  margin-left: 45px !important;
}

.m-l-50 {
  margin-left: 50px !important;
}

/*====== Margin-left css ends ======*/
/*====== Margin-right css starts ======*/
.m-r-0 {
  margin-right: 0px;
}

.m-r-5 {
  margin-right: 5px;
}

.m-r-10 {
  margin-right: 10px;
}

.m-r-15 {
  margin-right: 15px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-r-25 {
  margin-right: 25px;
}

.m-r-30 {
  margin-right: 30px;
}

.m-r-35 {
  margin-right: 35px;
}

.m-r-40 {
  margin-right: 40px;
}

.m-r-45 {
  margin-right: 45px;
}

.m-r-50 {
  margin-right: 50px;
}

/*====== Margin-right css ends ======*/
/*====== Border-radius css starts ======*/
.b-r-0 {
  border-radius: 0px !important;
}

.b-r-1 {
  border-radius: 1px !important;
}

.b-r-2 {
  border-radius: 2px !important;
}

.b-r-3 {
  border-radius: 3px !important;
}

.b-r-4 {
  border-radius: 4px !important;
}

.b-r-5 {
  border-radius: 5px !important;
}

.b-r-6 {
  border-radius: 6px !important;
}

.b-r-7 {
  border-radius: 7px !important;
}

.b-r-8 {
  border-radius: 8px !important;
}

.b-r-9 {
  border-radius: 9px !important;
}

.b-r-10 {
  border-radius: 10px !important;
}

/*====== Border-radius css ends ======*/
/*====== Font-size css starts ======*/
.f-12 {
  font-size: 12px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}

.f-20 {
  font-size: 20px !important;
}

.f-22 {
  font-size: 22px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-26 {
  font-size: 26px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-30 {
  font-size: 30px !important;
}

.f-32 {
  font-size: 32px !important;
}

.f-34 {
  font-size: 34px !important;
}

.f-36 {
  font-size: 36px !important;
}

.f-38 {
  font-size: 38px !important;
}

.f-40 {
  font-size: 40px !important;
}

.f-42 {
  font-size: 42px !important;
}

.f-44 {
  font-size: 44px !important;
}

.f-46 {
  font-size: 46px !important;
}

.f-48 {
  font-size: 48px !important;
}

.f-50 {
  font-size: 50px !important;
}

.f-52 {
  font-size: 52px !important;
}

.f-54 {
  font-size: 54px !important;
}

.f-56 {
  font-size: 56px !important;
}

.f-58 {
  font-size: 58px !important;
}

.f-60 {
  font-size: 60px !important;
}

.f-62 {
  font-size: 62px !important;
}

.f-64 {
  font-size: 64px !important;
}

.f-66 {
  font-size: 66px !important;
}

.f-68 {
  font-size: 68px !important;
}

.f-70 {
  font-size: 70px !important;
}

.f-72 {
  font-size: 72px !important;
}

.f-74 {
  font-size: 74px !important;
}

.f-76 {
  font-size: 76px !important;
}

.f-78 {
  font-size: 78px !important;
}

.f-80 {
  font-size: 80px !important;
}

.f-82 {
  font-size: 82px !important;
}

.f-84 {
  font-size: 84px !important;
}

.f-86 {
  font-size: 86px !important;
}

.f-88 {
  font-size: 88px !important;
}

.f-90 {
  font-size: 90px !important;
}

.f-92 {
  font-size: 92px !important;
}

.f-94 {
  font-size: 94px !important;
}

.f-96 {
  font-size: 96px !important;
}

.f-98 {
  font-size: 98px !important;
}

.f-100 {
  font-size: 100px !important;
}

/*====== Font-size css ends ======*/
/*====== Font-weight css starts ======*/
.f-w-100 {
  font-weight: 100;
}

.f-w-300 {
  font-weight: 300;
}

.f-w-500 {
  font-weight: 500;
}

.f-w-400 {
  font-weight: 400;
}

.f-w-600 {
  font-weight: 600;
}

.f-w-700 {
  font-weight: 700;
}

.f-w-900 {
  font-weight: 900;
}

/*====== Font-weight css ends ======*/
/*====== Font-style css starts ======*/
.f-s-normal {
  font-style: normal;
}

.f-s-italic {
  font-style: italic;
}

.f-s-oblique {
  font-style: oblique;
}

.f-s-initial {
  font-style: initial;
}

.f-s-inherit {
  font-style: inherit;
}

/*====== Font-style css ends ======*/
/*====== Text-Decoration css starts ======*/
.text-overline {
  text-decoration: overline;
}

.text-line-through {
  text-decoration: line-through;
}

.text-underline {
  text-decoration: underline;
}

.text-solid {
  -webkit-text-decoration: solid;
          text-decoration: solid;
}

.text-blink {
  text-decoration: blink;
}

.text-dotted {
  -webkit-text-decoration: dotted;
          text-decoration: dotted;
}

.text-initial {
  text-decoration: initial;
}

.text-none {
  text-decoration: none;
}

.text-solid {
  -webkit-text-decoration: solid;
          text-decoration: solid;
}

.text-wavy {
  -webkit-text-decoration: wavy;
          text-decoration: wavy;
}

.text-inherit {
  text-decoration: inherit;
}

.text-double {
  -webkit-text-decoration: double;
          text-decoration: double;
}

/*====== Text-Decoration css ends ======*/
/*====== Vertical-Align css starts ======*/
.baseline {
  vertical-align: baseline;
}

.sub {
  vertical-align: sub;
}

.super {
  vertical-align: super;
}

.top {
  vertical-align: top;
}

.text-top {
  vertical-align: text-top;
}

.middle {
  vertical-align: middle;
}

.bottom {
  vertical-align: bottom;
}

.text-bottom {
  vertical-align: text-bottom;
}

.initial {
  vertical-align: initial;
}

.inherit {
  vertical-align: inherit;
}

/*====== Vertical-Align css ends ======*/
/*====== Position css starts ======*/
.p-static {
  position: static;
}

.p-absolute {
  position: absolute;
}

.p-fixed {
  position: fixed;
}

.p-relative {
  position: relative;
}

.p-initial {
  position: initial;
}

.p-inherit {
  position: inherit;
}

/*====== Position css ends ======*/
/*====== Float css starts ======*/
.f-left {
  float: left;
}

.f-right {
  float: right;
}

.f-none {
  float: none;
}

/*====== Float css ends ======*/
/*====== Overflow css starts ======*/
.o-hidden {
  overflow: hidden;
}

.o-visible {
  overflow: visible;
}

.o-auto {
  overflow: auto;
}

/*====== Overflow css ends ======*/
/*====== Image-sizes css starts ======*/
.img-10 {
  width: 10px !important;
}

.img-h-10 {
  height: 10px !important;
}

.img-20 {
  width: 20px !important;
}

.img-h-20 {
  height: 20px !important;
}

.img-30 {
  width: 30px !important;
}

.img-h-30 {
  height: 30px !important;
}

.img-40 {
  width: 40px !important;
}

.img-h-40 {
  height: 40px !important;
}

.img-50 {
  width: 50px !important;
}

.img-h-50 {
  height: 50px !important;
}

.img-60 {
  width: 60px !important;
}

.img-h-60 {
  height: 60px !important;
}

.img-70 {
  width: 70px !important;
}

.img-h-70 {
  height: 70px !important;
}

.img-80 {
  width: 80px !important;
}

.img-h-80 {
  height: 80px !important;
}

.img-90 {
  width: 90px !important;
}

.img-h-90 {
  height: 90px !important;
}

.img-100 {
  width: 100px !important;
}

.img-h-100 {
  height: 100px !important;
}

/*====== Image-sizes css ends ======*/
/*======= Text css starts ===========*/
.font-primary {
  color: #337ab7 !important;
}

.stroke-primary {
  stroke: #337ab7 !important;
}

.font-secondary {
  color: #48A3D7 !important;
}

.stroke-secondary {
  stroke: #48A3D7 !important;
}

.font-success {
  color: #0DA759 !important;
}

.stroke-success {
  stroke: #0DA759 !important;
}

.font-danger {
  color: #E44141 !important;
}

.stroke-danger {
  stroke: #E44141 !important;
}

.font-info {
  color: #16C7F9 !important;
}

.stroke-info {
  stroke: #16C7F9 !important;
}

.font-tertiary {
  color: #C95E9E !important;
}

.stroke-tertiary {
  stroke: #C95E9E !important;
}

.font-light {
  color: #f4f4f4 !important;
}

.stroke-light {
  stroke: #f4f4f4 !important;
}

.font-dark {
  color: #2c323f !important;
}

.stroke-dark {
  stroke: #2c323f !important;
}

.font-warning {
  color: #D77748 !important;
}

.stroke-warning {
  stroke: #D77748 !important;
}

.font-primary {
  color: var(--theme-deafult) !important;
}

.font-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Text css ends ===========*/
/*======= Label-color css starts  ======= */
.label {
  border-radius: 2px;
  color: #fff;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 0;
  text-transform: capitalize;
}

.label-theme {
  background-color: #337ab7;
}

.label-primary {
  background-color: #337ab7;
}

.label-secondary {
  background-color: #48A3D7;
}

.label-success {
  background-color: #0DA759;
}

.label-danger {
  background-color: #E44141;
}

.label-info {
  background-color: #16C7F9;
}

.label-tertiary {
  background-color: #C95E9E;
}

.label-light {
  background-color: #f4f4f4;
}

.label-dark {
  background-color: #2c323f;
}

.label-warning {
  background-color: #D77748;
}

/*======= Label-color css ends  ======= */
/*======= Badge-color css starts  ======= */
.badge-primary {
  background-color: #337ab7;
}

.badge-secondary {
  background-color: #48A3D7;
}

.badge-success {
  background-color: #0DA759;
}

.badge-danger {
  background-color: #E44141;
}

.badge-info {
  background-color: #16C7F9;
}

.badge-tertiary {
  background-color: #C95E9E;
}

.badge-light {
  background-color: #f4f4f4;
}

.badge-dark {
  background-color: #2c323f;
}

.badge-warning {
  background-color: #D77748;
}

.badge-primary {
  background-color: var(--theme-deafult) !important;
}

.badge-secondary {
  background-color: var(--theme-secondary) !important;
}

/*======= Badge-color css end  ======= */
/*======= Background-color css starts  ======= */
.bg-primary {
  background-color: #337ab7 !important;
  color: #fff;
}

.fill-primary {
  fill: #337ab7 !important;
}

.bg-light-primary {
  background-color: rgba(122, 112, 186, 0.2) !important;
  color: #fff;
}

.b-light1-primary {
  background-color: rgba(122, 112, 186, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-primary {
  background-color: rgba(122, 112, 186, 0.2) !important;
  color: #337ab7;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-primary:hover {
  background-color: #337ab7 !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-secondary {
  background-color: #48A3D7 !important;
  color: #fff;
}

.fill-secondary {
  fill: #48A3D7 !important;
}

.bg-light-secondary {
  background-color: rgba(72, 163, 215, 0.2) !important;
  color: #fff;
}

.b-light1-secondary {
  background-color: rgba(72, 163, 215, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-secondary {
  background-color: rgba(72, 163, 215, 0.2) !important;
  color: #48A3D7;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-secondary:hover {
  background-color: #48a3d7 !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-success {
  background-color: #0DA759 !important;
  color: #fff;
}

.fill-success {
  fill: #0DA759 !important;
}

.bg-light-success {
  background-color: rgba(13, 167, 89, 0.2) !important;
  color: #fff;
}

.b-light1-success {
  background-color: rgba(13, 167, 89, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-success {
  background-color: rgba(13, 167, 89, 0.2) !important;
  color: #0DA759;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-success:hover {
  background-color: #0da759 !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-danger {
  background-color: #E44141 !important;
  color: #fff;
}

.fill-danger {
  fill: #E44141 !important;
}

.bg-light-danger {
  background-color: rgba(228, 65, 65, 0.2) !important;
  color: #fff;
}

.b-light1-danger {
  background-color: rgba(228, 65, 65, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-danger {
  background-color: rgba(228, 65, 65, 0.2) !important;
  color: #E44141;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-danger:hover {
  background-color: #e44141 !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-info {
  background-color: #16C7F9 !important;
  color: #fff;
}

.fill-info {
  fill: #16C7F9 !important;
}

.bg-light-info {
  background-color: rgba(22, 199, 249, 0.2) !important;
  color: #fff;
}

.b-light1-info {
  background-color: rgba(22, 199, 249, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-info {
  background-color: rgba(22, 199, 249, 0.2) !important;
  color: #16C7F9;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-info:hover {
  background-color: #16c7f9 !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-tertiary {
  background-color: #C95E9E !important;
  color: #fff;
}

.fill-tertiary {
  fill: #C95E9E !important;
}

.bg-light-tertiary {
  background-color: rgba(201, 94, 158, 0.2) !important;
  color: #fff;
}

.b-light1-tertiary {
  background-color: rgba(201, 94, 158, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-tertiary {
  background-color: rgba(201, 94, 158, 0.2) !important;
  color: #C95E9E;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-tertiary:hover {
  background-color: #c95e9e !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-light {
  background-color: #f4f4f4 !important;
  color: #fff;
}

.fill-light {
  fill: #f4f4f4 !important;
}

.bg-light-light {
  background-color: rgba(244, 244, 244, 0.2) !important;
  color: #fff;
}

.b-light1-light {
  background-color: rgba(244, 244, 244, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-light {
  background-color: rgba(244, 244, 244, 0.2) !important;
  color: #f4f4f4;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-light:hover {
  background-color: #f4f4f4 !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-dark {
  background-color: #2c323f !important;
  color: #fff;
}

.fill-dark {
  fill: #2c323f !important;
}

.bg-light-dark {
  background-color: rgba(44, 50, 63, 0.2) !important;
  color: #fff;
}

.b-light1-dark {
  background-color: rgba(44, 50, 63, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-dark {
  background-color: rgba(44, 50, 63, 0.2) !important;
  color: #2c323f;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-dark:hover {
  background-color: #2c323f !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-warning {
  background-color: #D77748 !important;
  color: #fff;
}

.fill-warning {
  fill: #D77748 !important;
}

.bg-light-warning {
  background-color: rgba(215, 119, 72, 0.2) !important;
  color: #fff;
}

.b-light1-warning {
  background-color: rgba(215, 119, 72, 0.1) !important;
  color: primary, secondary, success, danger, info, tertiary, light, dark, warning;
}

.background-light-warning {
  background-color: rgba(215, 119, 72, 0.2) !important;
  color: #D77748;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.background-light-warning:hover {
  background-color: #d77748 !important;
  color: #fff !important;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.bg-primary {
  background-color: var(--theme-deafult) !important;
}

.bg-secondary {
  background-color: var(--theme-secondary) !important;
}

.badge-light-primary {
  background-color: #f4f3f9;
  color: #337ab7;
}
.badge-light-primary:hover {
  background-color: #f4f3f9;
  color: #337ab7;
}

.badge-light-primary {
  background-color: #f4f3f9;
  color: #337ab7;
}
.badge-light-primary:hover {
  background-color: #f4f3f9;
  color: #337ab7;
}

.badge-light-secondary {
  background-color: #e7f3fa;
  color: #48A3D7;
}
.badge-light-secondary:hover {
  background-color: #e7f3fa;
  color: #48A3D7;
}

.badge-light-secondary {
  background-color: #e7f3fa;
  color: #48A3D7;
}
.badge-light-secondary:hover {
  background-color: #e7f3fa;
  color: #48A3D7;
}

.badge-light-tertiary {
  background-color: #faeff5;
  color: #C95E9E;
}
.badge-light-tertiary:hover {
  background-color: #faeff5;
  color: #C95E9E;
}

.badge-light-tertiary {
  background-color: #faeff5;
  color: #C95E9E;
}
.badge-light-tertiary:hover {
  background-color: #faeff5;
  color: #C95E9E;
}

.badge-light-success {
  background-color: #81f5ba;
  color: #0DA759;
}
.badge-light-success:hover {
  background-color: #81f5ba;
  color: #0DA759;
}

.badge-light-success {
  background-color: #81f5ba;
  color: #0DA759;
}
.badge-light-success:hover {
  background-color: #81f5ba;
  color: #0DA759;
}

.badge-light-danger {
  background-color: #fcebeb;
  color: #E44141;
}
.badge-light-danger:hover {
  background-color: #fcebeb;
  color: #E44141;
}

.badge-light-danger {
  background-color: #fcebeb;
  color: #E44141;
}
.badge-light-danger:hover {
  background-color: #fcebeb;
  color: #E44141;
}

.badge-light-info {
  background-color: #d3f4fe;
  color: #16C7F9;
}
.badge-light-info:hover {
  background-color: #d3f4fe;
  color: #16C7F9;
}

.badge-light-info {
  background-color: #d3f4fe;
  color: #16C7F9;
}
.badge-light-info:hover {
  background-color: #d3f4fe;
  color: #16C7F9;
}

.badge-light-light {
  background-color: #b7b7c9;
  color: #52526c;
}
.badge-light-light:hover {
  background-color: #b7b7c9;
  color: #52526c;
}

.badge-light-light {
  background-color: #b7b7c9;
  color: #52526c;
}
.badge-light-light:hover {
  background-color: #b7b7c9;
  color: #52526c;
}

.badge-light-dark {
  background-color: #8490a9;
  color: #2c323f;
}
.badge-light-dark:hover {
  background-color: #8490a9;
  color: #2c323f;
}

.badge-light-dark {
  background-color: #8490a9;
  color: #2c323f;
}
.badge-light-dark:hover {
  background-color: #8490a9;
  color: #2c323f;
}

.badge-light-warning {
  background-color: #faede7;
  color: #D77748;
}
.badge-light-warning:hover {
  background-color: #faede7;
  color: #D77748;
}

.badge-light-warning {
  background-color: #faede7;
  color: #D77748;
}
.badge-light-warning:hover {
  background-color: #faede7;
  color: #D77748;
}

.badge-light-light {
  background-color: #e9e9ee;
}

.badge-light-primary {
  background-color: rgba(122, 112, 186, 0.1);
}

.badge-light-success {
  background-color: rgba(13, 167, 89, 0.15);
}

.badge-light-secondary {
  background-color: rgba(72, 163, 215, 0.08);
}

.badge-light-warning {
  background-color: rgba(215, 119, 72, 0.1);
}

/*======= Background-color css end  ======= */
/*======= Font-color css starts  ======= */
.txt-primary {
  color: #337ab7 !important;
}

.txt-secondary {
  color: #48A3D7 !important;
}

.txt-success {
  color: #0DA759 !important;
}

.txt-danger {
  color: #E44141 !important;
}

.txt-info {
  color: #16C7F9 !important;
}

.txt-tertiary {
  color: #C95E9E !important;
}

.txt-light {
  color: #f4f4f4 !important;
}

.txt-dark {
  color: #2c323f !important;
}

.txt-warning {
  color: #D77748 !important;
}

.txt-google-plus {
  color: #c64e40 !important;
}

.txt-twitter {
  color: #6fa2d8 !important;
}

.txt-linkedin {
  color: #0077B5 !important;
}

.txt-fb {
  color: #50598e !important;
}

.txt-primary {
  color: var(--theme-deafult) !important;
}

.txt-secondary {
  color: var(--theme-secondary) !important;
}

/*======= Font-color css end  ======= */
/*======= Button-color css starts  ======= */
@-webkit-keyframes push {
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes push {
  50% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.btn-primary_ {
  background-color: #337ab7 !important;
  /* background-color: #b0d2f1 !important; */
  border-color: #337ab7 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn.btn-primaryi {
  /* background-color: #2a3650 !important; */
  background-color: var(--theme-deafult) !important;
  height: auto !important;
  padding: 10px 3em !important;
}
.btn.btn-primaryi:hover {
  background-color: #554c8c !important;
  border-color: #554c8c !important;
}
.btn-primary_.disabled, .btn-primary_:disabled {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}
.btn-primary_:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #dad7ec;
          box-shadow: 0 0 0 0.2rem #dad7ec;
}

.btn-light1-primary {
  background-color: rgba(122, 112, 186, 0.1) !important;
}

.btn-secondary {
  background-color: #48A3D7 !important;
  border-color: #48A3D7 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: #48A3D7 !important;
  border-color: #48A3D7 !important;
}
.btn-secondary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #c6e2f2;
          box-shadow: 0 0 0 0.2rem #c6e2f2;
}

.btn-light1-secondary {
  background-color: rgba(72, 163, 215, 0.1) !important;
}

.btn-success {
  background-color: #0DA759 !important;
  border-color: #0DA759 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn-success.disabled, .btn-success:disabled {
  background-color: #0DA759 !important;
  border-color: #0DA759 !important;
}
.btn-success:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #5bf2a6;
          box-shadow: 0 0 0 0.2rem #5bf2a6;
}

.btn-light1-success {
  background-color: rgba(13, 167, 89, 0.1) !important;
}

.btn-danger {
  background-color: #E44141 !important;
  border-color: #E44141 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn-danger.disabled, .btn-danger:disabled {
  background-color: #E44141 !important;
  border-color: #E44141 !important;
}
.btn-danger:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #f7c7c7;
          box-shadow: 0 0 0 0.2rem #f7c7c7;
}

.btn-light1-danger {
  background-color: rgba(228, 65, 65, 0.1) !important;
}

.btn-tertiary {
  background-color: #C95E9E !important;
  border-color: #C95E9E !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn-tertiary.disabled, .btn-tertiary:disabled {
  background-color: #C95E9E !important;
  border-color: #C95E9E !important;
}
.btn-tertiary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #efd1e3;
          box-shadow: 0 0 0 0.2rem #efd1e3;
}

.btn-light1-tertiary {
  background-color: rgba(201, 94, 158, 0.1) !important;
}

.btn-info {
  background-color: #16C7F9 !important;
  border-color: #16C7F9 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  color: #fff;
}
.btn-info:hover, .btn-info:focus, .btn-info:active, .btn-info.active {
  color: #fff;
}
.btn-info.disabled, .btn-info:disabled {
  background-color: #16C7F9 !important;
  border-color: #16C7F9 !important;
}
.btn-info:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #abebfd;
          box-shadow: 0 0 0 0.2rem #abebfd;
}

.btn-light1-info {
  background-color: rgba(22, 199, 249, 0.1) !important;
}

.btn-light {
  background-color: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn-light.disabled, .btn-light:disabled {
  background-color: #f4f4f4 !important;
  border-color: #f4f4f4 !important;
}
.btn-light:focus {
  -webkit-box-shadow: 0 0 0 0.2rem white;
          box-shadow: 0 0 0 0.2rem white;
}

.btn-light1-light {
  background-color: rgba(244, 244, 244, 0.1) !important;
}

.btn-dark {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn-dark.disabled, .btn-dark:disabled {
  background-color: #2c323f !important;
  border-color: #2c323f !important;
}
.btn-dark:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #6c7a98;
          box-shadow: 0 0 0 0.2rem #6c7a98;
}

.btn-light1-dark {
  background-color: rgba(44, 50, 63, 0.1) !important;
}

.btn-warning {
  background-color: #D77748 !important;
  border-color: #D77748 !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
}
.btn-warning.disabled, .btn-warning:disabled {
  background-color: #D77748 !important;
  border-color: #D77748 !important;
}
.btn-warning:focus {
  -webkit-box-shadow: 0 0 0 0.2rem #f2d4c6;
          box-shadow: 0 0 0 0.2rem #f2d4c6;
}

.btn-light1-warning {
  background-color: rgba(215, 119, 72, 0.1) !important;
}

.btn-light {
  background-color: rgba(122, 112, 186, 0.1) !important;
  border-color: rgba(122, 112, 186, 0.1) !important;
  border-style: solid !important;
}
.btn-light:hover {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}

.btn-primary_ {
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}
.btn-primary_.disabled, .btn-primary_:disabled {
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
}

.btn-secondary {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;
}
.btn-secondary.disabled, .btn-secondary:disabled {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;
}

.btn-hover-effect:hover {
  -webkit-animation: push 0.5s linear 1;
          animation: push 0.5s linear 1;
}

/*======= Button-color css ends  ======= */
.btn-outline-primary-2x {
  border-width: 2px;
  border-color: #337ab7;
  color: #337ab7;
  background-color: transparent;
}
.btn-outline-primary-2x:hover, .btn-outline-primary-2x:focus, .btn-outline-primary-2x:active, .btn-outline-primary-2x.active {
  color: white;
  background-color: #5c50a7 !important;
  border-color: #5c50a7 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-secondary-2x {
  border-width: 2px;
  border-color: #48A3D7;
  color: #48A3D7;
  background-color: transparent;
}
.btn-outline-secondary-2x:hover, .btn-outline-secondary-2x:focus, .btn-outline-secondary-2x:active, .btn-outline-secondary-2x.active {
  color: white;
  background-color: #2a8bc2 !important;
  border-color: #2a8bc2 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-success-2x {
  border-width: 2px;
  border-color: #0DA759;
  color: #0DA759;
  background-color: transparent;
}
.btn-outline-success-2x:hover, .btn-outline-success-2x:focus, .btn-outline-success-2x:active, .btn-outline-success-2x.active {
  color: white;
  background-color: #097840 !important;
  border-color: #097840 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-danger-2x {
  border-width: 2px;
  border-color: #E44141;
  color: #E44141;
  background-color: transparent;
}
.btn-outline-danger-2x:hover, .btn-outline-danger-2x:focus, .btn-outline-danger-2x:active, .btn-outline-danger-2x.active {
  color: white;
  background-color: #d41e1e !important;
  border-color: #d41e1e !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-info-2x {
  border-width: 2px;
  border-color: #16C7F9;
  color: #16C7F9;
  background-color: transparent;
}
.btn-outline-info-2x:hover, .btn-outline-info-2x:focus, .btn-outline-info-2x:active, .btn-outline-info-2x.active {
  color: white;
  background-color: #06a8d6 !important;
  border-color: #06a8d6 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-tertiary-2x {
  border-width: 2px;
  border-color: #C95E9E;
  color: #C95E9E;
  background-color: transparent;
}
.btn-outline-tertiary-2x:hover, .btn-outline-tertiary-2x:focus, .btn-outline-tertiary-2x:active, .btn-outline-tertiary-2x.active {
  color: white;
  background-color: #b73d86 !important;
  border-color: #b73d86 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-light-2x {
  border-width: 2px;
  border-color: #f4f4f4;
  color: #f4f4f4;
  background-color: transparent;
}
.btn-outline-light-2x:hover, .btn-outline-light-2x:focus, .btn-outline-light-2x:active, .btn-outline-light-2x.active {
  color: white;
  background-color: #dbdbdb !important;
  border-color: #dbdbdb !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-dark-2x {
  border-width: 2px;
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
}
.btn-outline-dark-2x:hover, .btn-outline-dark-2x:focus, .btn-outline-dark-2x:active, .btn-outline-dark-2x.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-warning-2x {
  border-width: 2px;
  border-color: #D77748;
  color: #D77748;
  background-color: transparent;
}
.btn-outline-warning-2x:hover, .btn-outline-warning-2x:focus, .btn-outline-warning-2x:active, .btn-outline-warning-2x.active {
  color: white;
  background-color: #c25c2a !important;
  border-color: #c25c2a !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.btn-outline-primary-2x {
  border-color: var(--theme-deafult) !important;
  color: var(--theme-deafult) !important;
}
.btn-outline-primary-2x:hover {
  border-color: var(--theme-deafult) !important;
  background-color: var(--theme-deafult) !important;
  color: #fff !important;
}

.btn-outline-secondary-2x {
  border-color: var(--theme-secondary) !important;
  color: var(--theme-secondary) !important;
}
.btn-outline-secondary-2x:hover {
  border-color: var(--theme-secondary) !important;
  background-color: var(--theme-secondary) !important;
  color: #fff !important;
}

.btn-outline-primary {
  border-color: #337ab7;
  color: #337ab7;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-primary.disabled {
  color: #337ab7;
}
.btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary:active, .btn-outline-primary.active {
  color: white;
  background-color: #5c50a7 !important;
  border-color: #5c50a7 !important;
}

.btn-outline-secondary {
  border-color: #48A3D7;
  color: #48A3D7;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-secondary.disabled {
  color: #48A3D7;
}
.btn-outline-secondary:hover, .btn-outline-secondary:focus, .btn-outline-secondary:active, .btn-outline-secondary.active {
  color: white;
  background-color: #2a8bc2 !important;
  border-color: #2a8bc2 !important;
}

.btn-outline-success {
  border-color: #0DA759;
  color: #0DA759;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-success.disabled {
  color: #0DA759;
}
.btn-outline-success:hover, .btn-outline-success:focus, .btn-outline-success:active, .btn-outline-success.active {
  color: white;
  background-color: #097840 !important;
  border-color: #097840 !important;
}

.btn-outline-danger {
  border-color: #E44141;
  color: #E44141;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-danger.disabled {
  color: #E44141;
}
.btn-outline-danger:hover, .btn-outline-danger:focus, .btn-outline-danger:active, .btn-outline-danger.active {
  color: white;
  background-color: #d41e1e !important;
  border-color: #d41e1e !important;
}

.btn-outline-info {
  border-color: #16C7F9;
  color: #16C7F9;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-info.disabled {
  color: #16C7F9;
}
.btn-outline-info:hover, .btn-outline-info:focus, .btn-outline-info:active, .btn-outline-info.active {
  color: white;
  background-color: #06a8d6 !important;
  border-color: #06a8d6 !important;
}

.btn-outline-tertiary {
  border-color: #C95E9E;
  color: #C95E9E;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-tertiary.disabled {
  color: #C95E9E;
}
.btn-outline-tertiary:hover, .btn-outline-tertiary:focus, .btn-outline-tertiary:active, .btn-outline-tertiary.active {
  color: white;
  background-color: #b73d86 !important;
  border-color: #b73d86 !important;
}

.btn-outline-light {
  border-color: #f4f4f4;
  color: #f4f4f4;
  background-color: transparent;
  border-style: solid;
  color: #3D434A;
}
.btn-outline-light.disabled {
  color: #f4f4f4;
}
.btn-outline-light:hover, .btn-outline-light:focus, .btn-outline-light:active, .btn-outline-light.active {
  color: white;
  background-color: #dbdbdb !important;
  border-color: #dbdbdb !important;
}

.btn-outline-dark {
  border-color: #2c323f;
  color: #2c323f;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-dark.disabled {
  color: #2c323f;
}
.btn-outline-dark:hover, .btn-outline-dark:focus, .btn-outline-dark:active, .btn-outline-dark.active {
  color: white;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning {
  border-color: #D77748;
  color: #D77748;
  background-color: transparent;
  border-style: solid;
}
.btn-outline-warning.disabled {
  color: #D77748;
}
.btn-outline-warning:hover, .btn-outline-warning:focus, .btn-outline-warning:active, .btn-outline-warning.active {
  color: white;
  background-color: #c25c2a !important;
  border-color: #c25c2a !important;
}

.btn-outline-primary {
  border-color: var(--theme-deafult) !important;
  color: var(--theme-deafult) !important;
}
.btn-outline-primary:hover {
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
  color: #fff !important;
}

.btn-outline-secondary {
  border-color: var(--theme-secondary) !important;
  color: var(--theme-secondary) !important;
}
.btn-outline-secondary:hover {
  background-color: var(--theme-secondary) !important;
  border-color: var(--theme-secondary) !important;
  color: #fff !important;
}

.btn-primary_:not([disabled]):not(.disabled).active {
  background-color: #5c50a7;
  border-color: #5c50a7;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-primary_:not([disabled]):not(.disabled).active:hover, .btn-primary_:not([disabled]):not(.disabled).active:focus, .btn-primary_:not([disabled]):not(.disabled).active:active, .btn-primary_:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #5c50a7;
  border-color: #5c50a7;
}

.btn-secondary:not([disabled]):not(.disabled).active {
  background-color: #2a8bc2;
  border-color: #2a8bc2;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-secondary:not([disabled]):not(.disabled).active:hover, .btn-secondary:not([disabled]):not(.disabled).active:focus, .btn-secondary:not([disabled]):not(.disabled).active:active, .btn-secondary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #2a8bc2;
  border-color: #2a8bc2;
}

.btn-success:not([disabled]):not(.disabled).active {
  background-color: #097840;
  border-color: #097840;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-success:not([disabled]):not(.disabled).active:hover, .btn-success:not([disabled]):not(.disabled).active:focus, .btn-success:not([disabled]):not(.disabled).active:active, .btn-success:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #097840;
  border-color: #097840;
}

.btn-danger:not([disabled]):not(.disabled).active {
  background-color: #d41e1e;
  border-color: #d41e1e;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-danger:not([disabled]):not(.disabled).active:hover, .btn-danger:not([disabled]):not(.disabled).active:focus, .btn-danger:not([disabled]):not(.disabled).active:active, .btn-danger:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #d41e1e;
  border-color: #d41e1e;
}

.btn-info:not([disabled]):not(.disabled).active {
  background-color: #06a8d6;
  border-color: #06a8d6;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-info:not([disabled]):not(.disabled).active:hover, .btn-info:not([disabled]):not(.disabled).active:focus, .btn-info:not([disabled]):not(.disabled).active:active, .btn-info:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #06a8d6;
  border-color: #06a8d6;
}

.btn-tertiary:not([disabled]):not(.disabled).active {
  background-color: #b73d86;
  border-color: #b73d86;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-tertiary:not([disabled]):not(.disabled).active:hover, .btn-tertiary:not([disabled]):not(.disabled).active:focus, .btn-tertiary:not([disabled]):not(.disabled).active:active, .btn-tertiary:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #b73d86;
  border-color: #b73d86;
}

.btn-light:not([disabled]):not(.disabled).active {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-light:not([disabled]):not(.disabled).active:hover, .btn-light:not([disabled]):not(.disabled).active:focus, .btn-light:not([disabled]):not(.disabled).active:active, .btn-light:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.btn-dark:not([disabled]):not(.disabled).active {
  background-color: #171a21;
  border-color: #171a21;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-dark:not([disabled]):not(.disabled).active:hover, .btn-dark:not([disabled]):not(.disabled).active:focus, .btn-dark:not([disabled]):not(.disabled).active:active, .btn-dark:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #171a21;
  border-color: #171a21;
}

.btn-warning:not([disabled]):not(.disabled).active {
  background-color: #c25c2a;
  border-color: #c25c2a;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.btn-warning:not([disabled]):not(.disabled).active:hover, .btn-warning:not([disabled]):not(.disabled).active:focus, .btn-warning:not([disabled]):not(.disabled).active:active, .btn-warning:not([disabled]):not(.disabled).active.active {
  color: white;
  background-color: #c25c2a;
  border-color: #c25c2a;
}

.btn-outline-primary-2x:not([disabled]):not(.disabled).active {
  background-color: #337ab7;
  border-color: #337ab7;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-primary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-primary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-primary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #5c50a7 !important;
  border-color: #5c50a7 !important;
}

.btn-outline-secondary-2x:not([disabled]):not(.disabled).active {
  background-color: #48A3D7;
  border-color: #48A3D7;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-secondary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-secondary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #2a8bc2 !important;
  border-color: #2a8bc2 !important;
}

.btn-outline-success-2x:not([disabled]):not(.disabled).active {
  background-color: #0DA759;
  border-color: #0DA759;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-success-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-success-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-success-2x:not([disabled]):not(.disabled).active:active, .btn-outline-success-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #097840 !important;
  border-color: #097840 !important;
}

.btn-outline-danger-2x:not([disabled]):not(.disabled).active {
  background-color: #E44141;
  border-color: #E44141;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-danger-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-danger-2x:not([disabled]):not(.disabled).active:active, .btn-outline-danger-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #d41e1e !important;
  border-color: #d41e1e !important;
}

.btn-outline-info-2x:not([disabled]):not(.disabled).active {
  background-color: #16C7F9;
  border-color: #16C7F9;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-info-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-info-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-info-2x:not([disabled]):not(.disabled).active:active, .btn-outline-info-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #06a8d6 !important;
  border-color: #06a8d6 !important;
}

.btn-outline-tertiary-2x:not([disabled]):not(.disabled).active {
  background-color: #C95E9E;
  border-color: #C95E9E;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-tertiary-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-tertiary-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-tertiary-2x:not([disabled]):not(.disabled).active:active, .btn-outline-tertiary-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #b73d86 !important;
  border-color: #b73d86 !important;
}

.btn-outline-light-2x:not([disabled]):not(.disabled).active {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-light-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-light-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-light-2x:not([disabled]):not(.disabled).active:active, .btn-outline-light-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #dbdbdb !important;
  border-color: #dbdbdb !important;
}

.btn-outline-dark-2x:not([disabled]):not(.disabled).active {
  background-color: #2c323f;
  border-color: #2c323f;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-dark-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-dark-2x:not([disabled]):not(.disabled).active:active, .btn-outline-dark-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #171a21 !important;
  border-color: #171a21 !important;
}

.btn-outline-warning-2x:not([disabled]):not(.disabled).active {
  background-color: #D77748;
  border-color: #D77748;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  color: white;
}
.btn-outline-warning-2x:not([disabled]):not(.disabled).active:hover, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:focus, .btn-outline-warning-2x:not([disabled]):not(.disabled).active:active, .btn-outline-warning-2x:not([disabled]):not(.disabled).active.active {
  color: white;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  background-color: #c25c2a !important;
  border-color: #c25c2a !important;
}

/*======= Table-Border-Bottom-color css starts  ======= */
table.table thead th {
  background-color: #ddd;
  padding: 7px !important;
  color: #222;
  font-weight: 600;
}

.swal_inputs input, .swal_inputs select{
    /* min-width: 100% !important; */
    width: 98% !important;
    display: block;
    background: #eee !important;
    border: 1px solid #ccc;
    border-radius: 6px !important;
    padding: 12px !important;
    height: auto !important;
}

.swal_inputs_pass input {
  font-size: 26px !important;
  padding: 7px 12px !important;
}
.swal_inputs_pass input::placeholder {
  font-size: 18px !important;
}

table thead .border-bottom-primary th,
table tbody .border-bottom-primary th,
table tbody .border-bottom-primary td {
  border-bottom: 1px solid #337ab7 !important;
}

table thead .border-bottom-secondary th,
table tbody .border-bottom-secondary th,
table tbody .border-bottom-secondary td {
  border-bottom: 1px solid #48A3D7 !important;
}

table thead .border-bottom-success th,
table tbody .border-bottom-success th,
table tbody .border-bottom-success td {
  border-bottom: 1px solid #0DA759 !important;
}

table thead .border-bottom-danger th,
table tbody .border-bottom-danger th,
table tbody .border-bottom-danger td {
  border-bottom: 1px solid #E44141 !important;
}

table thead .border-bottom-info th,
table tbody .border-bottom-info th,
table tbody .border-bottom-info td {
  border-bottom: 1px solid #16C7F9 !important;
}

table thead .border-bottom-light th,
table tbody .border-bottom-light th,
table tbody .border-bottom-light td {
  border-bottom: 1px solid #f4f4f4 !important;
}

table thead .border-bottom-tertiary th,
table tbody .border-bottom-tertiary th,
table tbody .border-bottom-tertiary td {
  border-bottom: 1px solid #C95E9E !important;
}

table thead .border-bottom-dark th,
table tbody .border-bottom-dark th,
table tbody .border-bottom-dark td {
  border-bottom: 1px solid #2c323f !important;
}

table thead .border-bottom-warning th,
table tbody .border-bottom-warning th,
table tbody .border-bottom-warning td {
  border-bottom: 1px solid #D77748 !important;
}

/*======= Table-Border-Bottom-color css ends  ======= */
/*======= Table styling css starts  ======= */
.table-styling .table-primary,
.table-styling.table-primary {
  background-color: #337ab7;
  color: #fff;
  border: 3px solid #337ab7;
}
.table-styling .table-primary thead,
.table-styling.table-primary thead {
  background-color: #5c50a7;
  border: 3px solid #5c50a7;
}
.table-styling .table-primary th,
.table-styling .table-primary td,
.table-styling.table-primary th,
.table-styling.table-primary td {
  background-color: transparent;
}

.table-styling .table-secondary,
.table-styling.table-secondary {
  background-color: #48A3D7;
  color: #fff;
  border: 3px solid #48A3D7;
}
.table-styling .table-secondary thead,
.table-styling.table-secondary thead {
  background-color: #2a8bc2;
  border: 3px solid #2a8bc2;
}
.table-styling .table-secondary th,
.table-styling .table-secondary td,
.table-styling.table-secondary th,
.table-styling.table-secondary td {
  background-color: transparent;
}

.table-styling .table-success,
.table-styling.table-success {
  background-color: #0DA759;
  color: #fff;
  border: 3px solid #0DA759;
}
.table-styling .table-success thead,
.table-styling.table-success thead {
  background-color: #097840;
  border: 3px solid #097840;
}
.table-styling .table-success th,
.table-styling .table-success td,
.table-styling.table-success th,
.table-styling.table-success td {
  background-color: transparent;
}

.table-styling .table-danger,
.table-styling.table-danger {
  background-color: #E44141;
  color: #fff;
  border: 3px solid #E44141;
}
.table-styling .table-danger thead,
.table-styling.table-danger thead {
  background-color: #d41e1e;
  border: 3px solid #d41e1e;
}
.table-styling .table-danger th,
.table-styling .table-danger td,
.table-styling.table-danger th,
.table-styling.table-danger td {
  background-color: transparent;
}

.table-styling .table-info,
.table-styling.table-info {
  background-color: #16C7F9;
  color: #fff;
  border: 3px solid #16C7F9;
}
.table-styling .table-info thead,
.table-styling.table-info thead {
  background-color: #06a8d6;
  border: 3px solid #06a8d6;
}
.table-styling .table-info th,
.table-styling .table-info td,
.table-styling.table-info th,
.table-styling.table-info td {
  background-color: transparent;
}

.table-styling .table-tertiary,
.table-styling.table-tertiary {
  background-color: #f4f4f4;
  color: #fff;
  border: 3px solid #f4f4f4;
}
.table-styling .table-tertiary thead,
.table-styling.table-tertiary thead {
  background-color: #dbdbdb;
  border: 3px solid #dbdbdb;
}
.table-styling .table-tertiary th,
.table-styling .table-tertiary td,
.table-styling.table-tertiary th,
.table-styling.table-tertiary td {
  background-color: transparent;
}

.table-styling .table-light,
.table-styling.table-light {
  background-color: #C95E9E;
  color: #fff;
  border: 3px solid #C95E9E;
}
.table-styling .table-light thead,
.table-styling.table-light thead {
  background-color: #b73d86;
  border: 3px solid #b73d86;
}
.table-styling .table-light th,
.table-styling .table-light td,
.table-styling.table-light th,
.table-styling.table-light td {
  background-color: transparent;
}

.table-styling .table-dark,
.table-styling.table-dark {
  background-color: #2c323f;
  color: #fff;
  border: 3px solid #2c323f;
}
.table-styling .table-dark thead,
.table-styling.table-dark thead {
  background-color: #171a21;
  border: 3px solid #171a21;
}
.table-styling .table-dark th,
.table-styling .table-dark td,
.table-styling.table-dark th,
.table-styling.table-dark td {
  background-color: transparent;
}

.table-styling .table-warning,
.table-styling.table-warning {
  background-color: #D77748;
  color: #fff;
  border: 3px solid #D77748;
}
.table-styling .table-warning thead,
.table-styling.table-warning thead {
  background-color: #c25c2a;
  border: 3px solid #c25c2a;
}
.table-styling .table-warning th,
.table-styling .table-warning td,
.table-styling.table-warning th,
.table-styling.table-warning td {
  background-color: transparent;
}

/*======= Table styling css ends  ======= */
/*======= Svg stroke css start  ======= */
.stroke-primary {
  stroke: #337ab7 !important;
}

.stroke-secondary {
  stroke: #48A3D7 !important;
}

.stroke-success {
  stroke: #0DA759 !important;
}

.stroke-danger {
  stroke: #E44141 !important;
}

.stroke-info {
  stroke: #16C7F9 !important;
}

.stroke-tertiary {
  stroke: #C95E9E !important;
}

.stroke-light {
  stroke: #f4f4f4 !important;
}

.stroke-dark {
  stroke: #2c323f !important;
}

.stroke-warning {
  stroke: #D77748 !important;
}

/*======= Svg stroke css end  ======= */
/*======= Checkbox color css start  ======= */
.checkbox-primary.form-check-input:checked {
  background-color: #337ab7;
  border-color: #337ab7;
}

.checkbox-secondary.form-check-input:checked {
  background-color: #48A3D7;
  border-color: #48A3D7;
}

.checkbox-success.form-check-input:checked {
  background-color: #0DA759;
  border-color: #0DA759;
}

.checkbox-danger.form-check-input:checked {
  background-color: #E44141;
  border-color: #E44141;
}

.checkbox-info.form-check-input:checked {
  background-color: #16C7F9;
  border-color: #16C7F9;
}

.checkbox-tertiary.form-check-input:checked {
  background-color: #C95E9E;
  border-color: #C95E9E;
}

.checkbox-light.form-check-input:checked {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.checkbox-dark.form-check-input:checked {
  background-color: #2c323f;
  border-color: #2c323f;
}

.checkbox-warning.form-check-input:checked {
  background-color: #D77748;
  border-color: #D77748;
}

/*======= Checkbox color css end  ======= */
/*======= All-Borders-color css starts  ======= */
.b-primary {
  border: 1px solid #337ab7 !important;
}

.b-light-primary {
  border: 1px solid rgba(122, 112, 186, 0.3) !important;
}

.b-t-primary {
  border-top: 1px solid #337ab7 !important;
}

.border-t-primary {
  border-top: 1px solid #337ab7 !important;
}

.b-b-primary {
  border-bottom: 1px solid #337ab7 !important;
}

.border-b-primary {
  border-bottom: 1px solid #337ab7 !important;
}

.b-l-primary {
  border-left: 1px solid #337ab7 !important;
}

.border-l-primary {
  border-left: 1px solid #337ab7 !important;
}

.b-r-primary {
  border-right: 1px solid #337ab7 !important;
}

.border-r-primary {
  border-right: 1px solid #337ab7 !important;
}

.b-secondary {
  border: 1px solid #48A3D7 !important;
}

.b-light-secondary {
  border: 1px solid rgba(72, 163, 215, 0.3) !important;
}

.b-t-secondary {
  border-top: 1px solid #48A3D7 !important;
}

.border-t-secondary {
  border-top: 1px solid #48A3D7 !important;
}

.b-b-secondary {
  border-bottom: 1px solid #48A3D7 !important;
}

.border-b-secondary {
  border-bottom: 1px solid #48A3D7 !important;
}

.b-l-secondary {
  border-left: 1px solid #48A3D7 !important;
}

.border-l-secondary {
  border-left: 1px solid #48A3D7 !important;
}

.b-r-secondary {
  border-right: 1px solid #48A3D7 !important;
}

.border-r-secondary {
  border-right: 1px solid #48A3D7 !important;
}

.b-success {
  border: 1px solid #0DA759 !important;
}

.b-light-success {
  border: 1px solid rgba(13, 167, 89, 0.3) !important;
}

.b-t-success {
  border-top: 1px solid #0DA759 !important;
}

.border-t-success {
  border-top: 1px solid #0DA759 !important;
}

.b-b-success {
  border-bottom: 1px solid #0DA759 !important;
}

.border-b-success {
  border-bottom: 1px solid #0DA759 !important;
}

.b-l-success {
  border-left: 1px solid #0DA759 !important;
}

.border-l-success {
  border-left: 1px solid #0DA759 !important;
}

.b-r-success {
  border-right: 1px solid #0DA759 !important;
}

.border-r-success {
  border-right: 1px solid #0DA759 !important;
}

.b-danger {
  border: 1px solid #E44141 !important;
}

.b-light-danger {
  border: 1px solid rgba(228, 65, 65, 0.3) !important;
}

.b-t-danger {
  border-top: 1px solid #E44141 !important;
}

.border-t-danger {
  border-top: 1px solid #E44141 !important;
}

.b-b-danger {
  border-bottom: 1px solid #E44141 !important;
}

.border-b-danger {
  border-bottom: 1px solid #E44141 !important;
}

.b-l-danger {
  border-left: 1px solid #E44141 !important;
}

.border-l-danger {
  border-left: 1px solid #E44141 !important;
}

.b-r-danger {
  border-right: 1px solid #E44141 !important;
}

.border-r-danger {
  border-right: 1px solid #E44141 !important;
}

.b-tertiary {
  border: 1px solid #C95E9E !important;
}

.b-light-tertiary {
  border: 1px solid rgba(201, 94, 158, 0.3) !important;
}

.b-t-tertiary {
  border-top: 1px solid #C95E9E !important;
}

.border-t-tertiary {
  border-top: 1px solid #C95E9E !important;
}

.b-b-tertiary {
  border-bottom: 1px solid #C95E9E !important;
}

.border-b-tertiary {
  border-bottom: 1px solid #C95E9E !important;
}

.b-l-tertiary {
  border-left: 1px solid #C95E9E !important;
}

.border-l-tertiary {
  border-left: 1px solid #C95E9E !important;
}

.b-r-tertiary {
  border-right: 1px solid #C95E9E !important;
}

.border-r-tertiary {
  border-right: 1px solid #C95E9E !important;
}

.b-info {
  border: 1px solid #16C7F9 !important;
}

.b-light-info {
  border: 1px solid rgba(22, 199, 249, 0.3) !important;
}

.b-t-info {
  border-top: 1px solid #16C7F9 !important;
}

.border-t-info {
  border-top: 1px solid #16C7F9 !important;
}

.b-b-info {
  border-bottom: 1px solid #16C7F9 !important;
}

.border-b-info {
  border-bottom: 1px solid #16C7F9 !important;
}

.b-l-info {
  border-left: 1px solid #16C7F9 !important;
}

.border-l-info {
  border-left: 1px solid #16C7F9 !important;
}

.b-r-info {
  border-right: 1px solid #16C7F9 !important;
}

.border-r-info {
  border-right: 1px solid #16C7F9 !important;
}

.b-light {
  border: 1px solid #f4f4f4 !important;
}

.b-light-light {
  border: 1px solid rgba(244, 244, 244, 0.3) !important;
}

.b-t-light {
  border-top: 1px solid #f4f4f4 !important;
}

.border-t-light {
  border-top: 1px solid #f4f4f4 !important;
}

.b-b-light {
  border-bottom: 1px solid #f4f4f4 !important;
}

.border-b-light {
  border-bottom: 1px solid #f4f4f4 !important;
}

.b-l-light {
  border-left: 1px solid #f4f4f4 !important;
}

.border-l-light {
  border-left: 1px solid #f4f4f4 !important;
}

.b-r-light {
  border-right: 1px solid #f4f4f4 !important;
}

.border-r-light {
  border-right: 1px solid #f4f4f4 !important;
}

.b-dark {
  border: 1px solid #2c323f !important;
}

.b-light-dark {
  border: 1px solid rgba(44, 50, 63, 0.3) !important;
}

.b-t-dark {
  border-top: 1px solid #2c323f !important;
}

.border-t-dark {
  border-top: 1px solid #2c323f !important;
}

.b-b-dark {
  border-bottom: 1px solid #2c323f !important;
}

.border-b-dark {
  border-bottom: 1px solid #2c323f !important;
}

.b-l-dark {
  border-left: 1px solid #2c323f !important;
}

.border-l-dark {
  border-left: 1px solid #2c323f !important;
}

.b-r-dark {
  border-right: 1px solid #2c323f !important;
}

.border-r-dark {
  border-right: 1px solid #2c323f !important;
}

.b-warning {
  border: 1px solid #D77748 !important;
}

.b-light-warning {
  border: 1px solid rgba(215, 119, 72, 0.3) !important;
}

.b-t-warning {
  border-top: 1px solid #D77748 !important;
}

.border-t-warning {
  border-top: 1px solid #D77748 !important;
}

.b-b-warning {
  border-bottom: 1px solid #D77748 !important;
}

.border-b-warning {
  border-bottom: 1px solid #D77748 !important;
}

.b-l-warning {
  border-left: 1px solid #D77748 !important;
}

.border-l-warning {
  border-left: 1px solid #D77748 !important;
}

.b-r-warning {
  border-right: 1px solid #D77748 !important;
}

.border-r-warning {
  border-right: 1px solid #D77748 !important;
}

/*======= All-Borders-color css ends  ======= */
/*====== Border width css starts ======*/
.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-6 {
  border-width: 6px !important;
}

.border-7 {
  border-width: 7px !important;
}

.border-8 {
  border-width: 8px !important;
}

.border-9 {
  border-width: 9px !important;
}

.border-10 {
  border-width: 10px !important;
}

/*====== Border width css ends ======*/
.opacity-0 {
  opacity: 0;
}

.map-block {
  height: 350px;
  width: 100%;
}

.map-js-height {
  height: 500px;
}
.map-js-height .btn-group input {
  background-color: #337ab7;
  color: #fff;
}
.map-js-height .btn-group input + input {
  background-color: #48A3D7;
}

/**====== custom scrollbar css start ======**/
.custom-scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(106, 113, 133, 0.3);
}
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(68, 102, 242, 0.15);
}

.theme-scrollbar::-webkit-scrollbar-track,
.datatable-container::-webkit-scrollbar-track,
.jsgrid-grid-body::-webkit-scrollbar-track,
.jsgrid-grid-header::-webkit-scrollbar-track,
.dataTables_scrollBody::-webkit-scrollbar-track,
.anchor::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(106, 113, 133, 0.3);
}
.theme-scrollbar::-webkit-scrollbar,
.datatable-container::-webkit-scrollbar,
.jsgrid-grid-body::-webkit-scrollbar,
.jsgrid-grid-header::-webkit-scrollbar,
.dataTables_scrollBody::-webkit-scrollbar,
.anchor::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}
.theme-scrollbar::-webkit-scrollbar-thumb,
.datatable-container::-webkit-scrollbar-thumb,
.jsgrid-grid-body::-webkit-scrollbar-thumb,
.jsgrid-grid-header::-webkit-scrollbar-thumb,
.dataTables_scrollBody::-webkit-scrollbar-thumb,
.anchor::-webkit-scrollbar-thumb {
  background-color: rgba(68, 102, 242, 0.15);
}

/**====== Custom scrollbar css end ======**/
/**====== Animation css Start ======**/
.comment {
  color: #9f9ba5;
  font-style: italic;
  display: block;
}

.line {
  color: #2c323f;
}
.line pre {
  font-size: 100%;
}

/**====== Animation css end ======**/
/**====== list style css Start ======**/
.list-circle {
  list-style: circle;
}

/**====== list style css end ======**/
/**====== Modal style css Start ======**/
.theme-close {
  opacity: 1;
  height: 40px;
  width: 40px;
  position: absolute;
  font-weight: 400;
  z-index: 1;
  right: 0;
  background-color: #fff !important;
  border-radius: 5px;
}

/**====== Modal style css end ======**/
/**====== Animation css start ======**/
.options > div {
  color: rgba(106, 113, 133, 0.3);
  display: inline-block;
  padding: 2px 10px;
  border: 1px solid;
  margin: 0 8px 8px 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.options > div:hover {
  background-color: #337ab7;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

/**====== Animation css Ends ======**/
.modal-footer {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.img-cropper #putData {
  margin-bottom: 0;
}
.img-cropper .img-container {
  min-height: auto;
  margin-bottom: 0;
}
.img-cropper .docs-data > .input-group:last-child {
  margin-bottom: 0;
}
.img-cropper .docs-preview {
  margin-top: 20px;
  margin-bottom: 10px;
}

.dropzone .dz-preview .dz-error-message {
  background: #fff !important;
  color: #337ab7 !important;
  border: 1px solid #337ab7;
}
.dropzone .dz-preview .dz-error-message:after {
  border-bottom: 6px solid #337ab7 !important;
}

.typeahead .theme-form .form-group {
  margin-bottom: 0;
}

.editor-statusbar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.page-builder .ge-canvas.ge-editing .row {
  padding: 30px;
  margin-bottom: 0;
  background-color: rgba(122, 112, 186, 0.02);
}
.page-builder .btn-screen {
  padding: 0 18px 0 0;
}

.bg-overlay.active {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  z-index: 8;
  top: 0;
}

button:focus {
  outline: none !important;
}

.primary .svg-fill {
  fill: var(--theme-deafult);
}
.primary .svg-fill:not(.half-circle) {
  stroke: var(--theme-deafult);
}
.primary .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(122, 112, 186, 0.1) 19.06%, rgba(122, 112, 186, 0) 79.03%) !important;
}

.secondary .svg-fill {
  fill: var(--theme-secondary);
}
.secondary .svg-fill:not(.half-circle) {
  stroke: var(--theme-secondary);
}
.secondary .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(72, 163, 215, 0.1) 19.06%, rgba(72, 163, 215, 0) 79.03%) !important;
}

.success .svg-fill {
  fill: #0DA759;
}
.success .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(13, 167, 89, 0.1) 19.06%, rgba(13, 167, 89, 0) 79.03%) !important;
}

.warning .svg-fill {
  fill: #D77748;
}
.warning .bg-gradient {
  background-image: linear-gradient(144.16deg, rgba(215, 119, 72, 0.1) 19.06%, rgba(215, 119, 72, 0) 79.03%) !important;
}

.dotted {
  border-style: dotted;
}

.solid {
  border-style: solid;
}

.double {
  border-style: double;
}

.groove {
  border-style: groove;
}

.ridge {
  border-style: ridge;
}

.inset {
  border-style: inset;
}

.outset {
  border-style: outset;
}

@-webkit-keyframes fadeIncustom {
  0% {
    opacity: 0;
    top: 50px;
  }
  75% {
    opacity: 0.6;
    top: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIncustom {
  0% {
    opacity: 0;
    top: 50px;
  }
  75% {
    opacity: 0.6;
    top: 0;
  }
  100% {
    opacity: 1;
  }
}
.code-box-copy {
  width: 100%;
  overflow: auto;
}

@media (max-width: 1660px) and (min-width: 1400px) {
  .custom-rs-4 {
    width: 33.33333333%;
  }
  .custom-rs-12 {
    width: 100%;
  }
}
.progress-striped-primary {
  background-color: rgba(122, 112, 186, 0.1);
}
.progress-striped-primary .progress-bar {
  background-image: linear-gradient(120deg, #337ab7 25%, transparent 25%, transparent 50%, #337ab7 50%, #337ab7 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-primary.progress-bar {
  position: relative;
}
.progress-border-primary.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #337ab7;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-primary.progress {
  background-color: rgba(122, 112, 186, 0.15);
}
.progress-border-primary .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #337ab7;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-primary .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-primary .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #337ab7;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-primary .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-secondary {
  background-color: rgba(72, 163, 215, 0.1);
}
.progress-striped-secondary .progress-bar {
  background-image: linear-gradient(120deg, #48a3d7 25%, transparent 25%, transparent 50%, #48a3d7 50%, #48a3d7 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-secondary.progress-bar {
  position: relative;
}
.progress-border-secondary.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #48A3D7;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-secondary.progress {
  background-color: rgba(72, 163, 215, 0.15);
}
.progress-border-secondary .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #48A3D7;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-secondary .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-secondary .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #48A3D7;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-secondary .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-tertiary {
  background-color: rgba(201, 94, 158, 0.1);
}
.progress-striped-tertiary .progress-bar {
  background-image: linear-gradient(120deg, #c95e9e 25%, transparent 25%, transparent 50%, #c95e9e 50%, #c95e9e 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-tertiary.progress-bar {
  position: relative;
}
.progress-border-tertiary.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #C95E9E;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-tertiary.progress {
  background-color: rgba(201, 94, 158, 0.15);
}
.progress-border-tertiary .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #C95E9E;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-tertiary .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-tertiary .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #C95E9E;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-tertiary .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-warning {
  background-color: rgba(215, 119, 72, 0.1);
}
.progress-striped-warning .progress-bar {
  background-image: linear-gradient(120deg, #d77748 25%, transparent 25%, transparent 50%, #d77748 50%, #d77748 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-warning.progress-bar {
  position: relative;
}
.progress-border-warning.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #D77748;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-warning.progress {
  background-color: rgba(215, 119, 72, 0.15);
}
.progress-border-warning .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #D77748;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-warning .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-warning .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #D77748;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-warning .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-info {
  background-color: rgba(22, 199, 249, 0.1);
}
.progress-striped-info .progress-bar {
  background-image: linear-gradient(120deg, #16c7f9 25%, transparent 25%, transparent 50%, #16c7f9 50%, #16c7f9 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-info.progress-bar {
  position: relative;
}
.progress-border-info.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #16C7F9;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-info.progress {
  background-color: rgba(22, 199, 249, 0.15);
}
.progress-border-info .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #16C7F9;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-info .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-info .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #16C7F9;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-info .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-success {
  background-color: rgba(13, 167, 89, 0.1);
}
.progress-striped-success .progress-bar {
  background-image: linear-gradient(120deg, #0da759 25%, transparent 25%, transparent 50%, #0da759 50%, #0da759 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-success.progress-bar {
  position: relative;
}
.progress-border-success.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #0DA759;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-success.progress {
  background-color: rgba(13, 167, 89, 0.15);
}
.progress-border-success .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #0DA759;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-success .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-success .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #0DA759;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-success .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-danger {
  background-color: rgba(228, 65, 65, 0.1);
}
.progress-striped-danger .progress-bar {
  background-image: linear-gradient(120deg, #e44141 25%, transparent 25%, transparent 50%, #e44141 50%, #e44141 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-danger.progress-bar {
  position: relative;
}
.progress-border-danger.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #E44141;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-danger.progress {
  background-color: rgba(228, 65, 65, 0.15);
}
.progress-border-danger .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #E44141;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-danger .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-danger .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #E44141;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-danger .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-light {
  background-color: rgba(244, 244, 244, 0.1);
}
.progress-striped-light .progress-bar {
  background-image: linear-gradient(120deg, #f4f4f4 25%, transparent 25%, transparent 50%, #f4f4f4 50%, #f4f4f4 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-light.progress-bar {
  position: relative;
}
.progress-border-light.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #f4f4f4;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-light.progress {
  background-color: rgba(244, 244, 244, 0.15);
}
.progress-border-light .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #f4f4f4;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-light .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-light .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #f4f4f4;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-light .progress-bar:after {
  left: 0px;
  right: unset;
}

.progress-striped-dark {
  background-color: rgba(44, 50, 63, 0.1);
}
.progress-striped-dark .progress-bar {
  background-image: linear-gradient(120deg, #2c323f 25%, transparent 25%, transparent 50%, #2c323f 50%, #2c323f 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
  -webkit-animation: progress-bar-stripes 5s linear infinite;
          animation: progress-bar-stripes 5s linear infinite;
}

.progress-border-dark.progress-bar {
  position: relative;
}
.progress-border-dark.progress-bar:after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #2c323f;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.progress-border-dark.progress {
  background-color: rgba(44, 50, 63, 0.15);
}
.progress-border-dark .progress-bar {
  position: relative;
  overflow: visible;
  background-color: #2c323f;
  border-radius: 15px 0 0 15px;
}
[dir=rtl] .progress-border-dark .progress-bar {
  border-radius: 0 15px 15px 0;
}
.progress-border-dark .progress-bar:after {
  position: absolute;
  content: "";
  top: -4px;
  right: 0;
  background-color: #2c323f;
  width: 1px;
  height: 16px;
}
[dir=rtl] .progress-border-dark .progress-bar:after {
  left: 0px;
  right: unset;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position: 40px 0;
  }
  100% {
    background-position: 0 0;
  }
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-marker {
  width: 10px;
  height: 10px;
}
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-goals-group,
.apexcharts-canvas .apexcharts-tooltip .apexcharts-tooltip-z-group {
  display: none;
}

.bg-gray {
  background-color: var(--text-gray);
}

.text-gray {
  color: var(--text-gray) !important;
}

.common-space {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/* Override the default tooltip arrow color */
.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  border-top-color: #E0DDFF;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  border-right-color: #E0DDFF;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  border-bottom-color: #E0DDFF;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  border-left-color: #E0DDFF;
}

@media screen and (max-width: 1399px) and (min-width: 1200px) {
  .proorder-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .proorder-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .proorder-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .proorder-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .proorder-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .proorder-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .proorder-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .proorder-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .proorder-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .proorder-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .proorder-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .proorder-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .proorder-xl-13 {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
}
@media screen and (max-width: 1199px) and (min-width: 768px) {
  .proorder-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .proorder-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .proorder-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .proorder-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .proorder-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .proorder-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .proorder-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .proorder-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .proorder-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .proorder-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .proorder-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .proorder-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .proorder-md-13 {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
}
@media (max-width: 1500px) and (min-width: 1200px) {
  .col-xl-100 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .col-xl-50 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .col-xl-40 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-xl-33 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.333333%;
  }
  .col-xl-70 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
}
@media screen and (max-width: 1399px) and (min-width: 1199px) {
  .proorder-4-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
}
/**=====================
    1.1 Reset CSS Ends
==========================**/
/**=====================
    1.2 Typography CSS start
==========================**/
body {
  font-size: 15px !important;
  overflow-x: hidden;
  font-family: "Nunito", sans-serif, sans-serif !important;
  color: #3D434A;
  background: #ddd !important;
  font-weight: 600 !important;
}


html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

h1 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0px;
}

h2 {
  font-size: 26px;
  font-weight: 500;
  letter-spacing: 0px;
}

h3 {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0px;
}

h4 {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
}

h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: 0px;
}

h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0px;
}

ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 0;
}

* a {
  color: var(--theme-deafult);
  text-decoration: none;
  /* letter-spacing: 1px; */
}

.f-m-light {
  margin: 0;
}

a {
  font-size: 0.875rem;
  text-decoration: none;
  color: var(--theme-deafult);
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
}
a:hover {
  color: var(--theme-deafult);
  -webkit-transition: all 0.5s all;
  transition: all 0.5s all;
}
a:focus-visible {
  outline: none;
}

.btn {
  font-size: 16px !important;
  font-weight: 600 !important;
}


*.btn:focus {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

p {
  font-size: 14px;
  line-height: 1.4;
  letter-spacing: 0px;
}

code {
  color: var(--theme-deafult) !important;
  border-radius: 2px;
  text-transform: lowercase;
}

span {
  display: inline-block;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.d-flex .flex-grow-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

blockquote {
  border-left: 4px solid #337ab7;
  padding: 18px;
}
[dir=rtl] blockquote {
  border-left: unset;
  border-right: 4px solid #337ab7;
}

.figure.text-center blockquote {
  border: none !important;
}
.figure.text-end blockquote {
  border-left: none;
  border-right: 4px solid #337ab7;
  border-right-color: #D77748;
}
[dir=rtl] .figure.text-end blockquote {
  border-right: unset;
  border-left: 4px solid #337ab7 !important;
  border-left-color: #D77748 !important;
}

.row > div {
  position: relative;
}

:focus {
  outline-color: var(--theme-deafult);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

svg {
  vertical-align: baseline;
}

input:focus {
  outline-color: transparent;
}

label {
  margin-bottom: 0.5rem;
}

.media-widgets .flex-grow-1 {
  margin-bottom: 30px;
}

.typography h1,
.typography .h1,
.typography h2,
.typography .h2,
.typography h3,
.typography .h3,
.typography h4,
.typography .h4,
.typography h5,
.typography .h5,
.typography h6,
.typography .h6 {
  margin: 18px 0;
  margin-top: 0;
}
.typography h1:last-child,
.typography .h1:last-child,
.typography h2:last-child,
.typography .h2:last-child,
.typography h3:last-child,
.typography .h3:last-child,
.typography h4:last-child,
.typography .h4:last-child,
.typography h5:last-child,
.typography .h5:last-child,
.typography h6:last-child,
.typography .h6:last-child {
  margin-bottom: 0;
}
.typography small {
  padding-left: 10px;
  color: #2c323f;
}

pre {
  background-color: rgba(122, 112, 186, 0.03);
  padding: 20px 0;
}

.owl-theme .owl-dots .owl-dot span {
  background: #bab5db;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: var(--theme-deafult);
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-bottom: -10px;
  margin-top: 25px;
}

.click2edit ~ .note-editor.note-frame {
  margin-bottom: 30px;
}

.note-editor.note-frame {
  border-color: #f4f4f4;
}
.note-editor.fullscreen .note-toolbar-wrapper {
  height: 0 !important;
}

.note-editor.note-frame {
  border-color: #f4f4f4;
}

.modal .modal-body .card {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.modal-dialog .modal-content .modal-body p a {
  margin-right: 0;
}

.notify-alert .close {
  color: #fff;
}
[dir=rtl] .notify-alert .close {
  right: unset !important;
  left: 10px !important;
}
.notify-alert.alert-light .close {
  color: #3D434A;
}

.alert-theme button {
  top: 45px !important;
  right: 20px !important;
  color: var(--theme-deafult);
}
[dir=rtl] .alert-theme button {
  right: unset !important;
  left: 20px !important;
}
.alert-theme span + span + span {
  background-color: #fff;
  color: var(--theme-deafult);
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
          box-shadow: 0 2px 14px -3px rgba(0, 0, 0, 0.2);
}
.alert-theme .close {
  color: var(--theme-deafult);
}
.alert-theme i {
  padding: 20px;
  padding-right: 0 !important;
  margin-right: 20px !important;
}
.alert-theme .progress {
  margin-top: -2px !important;
  height: 2px;
  width: 100%;
}
.alert-theme .progress .progress-bar {
  background-color: var(--theme-deafult);
}

.alert-copy {
  border: 1px solid var(--theme-deafult);
  line-height: 1;
  display: inline-block;
  width: 300px;
  color: #fff;
  background-color: var(--theme-deafult);
}
.alert-copy .close {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #fff;
  line-height: 1.4;
  font-weight: 100;
  top: -15px;
}
[dir=rtl] .alert-copy .close {
  right: unset !important;
  left: 10px !important;
}
.alert-copy .close:focus {
  outline: none;
}

.ace-editor {
  height: 400px;
  width: 100%;
}

.editor-toolbar {
  width: 100% !important;
}
.editor-toolbar.fullscreen {
  z-index: 100;
}

.CodeMirror {
  top: 0 !important;
}

.CodeMirror-fullscreen {
  top: 50px !important;
}

.irs-from,
.irs-to,
.irs-single {
  background: var(--theme-deafult);
}
.irs-from:after,
.irs-to:after,
.irs-single:after {
  border-top-color: var(--theme-deafult);
}

.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
  background-color: var(--theme-deafult);
}

.swal-button {
  background-color: var(--theme-deafult);
}
.swal-button:active {
  background-color: #6a5fb2;
}

.swal-button--cancel {
  background-color: #EFEFEE !important;
}

.clipboaard-container h6 {
  line-height: 1.6;
  font-size: 14px;
}

fieldset .bootstrap-touchspin-postfix,
fieldset .bootstrap-touchspin-prefix {
  border-radius: 0;
  padding: 0.4rem 0.75rem;
}
fieldset .input-group-lg .touchspin {
  height: 50px;
}

.f1 .f1-steps .f1-step .f1-step-icon {
  padding: 12px;
}

.form-inline .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.input-group-text i {
  line-height: 1.5;
}

.animated-modal .form-select {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}

.card-wrapper {
  padding: 14px;
}
.card-wrapper dl dt {
  font-weight: 500;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #f1e6f5 #ffffff;
}

table tbody tr {
  vertical-align: middle;
}

.common-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.typography-table thead tr th {
  font-size: 16px;
}
.typography-table tbody tr td:last-child {
  min-width: 280px;
}
.typography-table tbody tr td:first-child {
  min-width: 100px;
}

/**=====================
    1.2 Typography CSS Ends
==========================**/
/**=====================
    2.1 According CSS start
==========================**/
.default-according .avatar-showcase {
  margin-bottom: 0;
}
.default-according .card {
  margin-bottom: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.default-according .card + .card {
  margin-top: 10px;
}
.default-according .card .btn-link {
  font-weight: 500;
  color: #3D434A;
  text-decoration: none;
}
.default-according .card .btn-link:focus, .default-according .card .btn-link:hover {
  text-decoration: none;
}
.default-according .card .card-header {
  padding: 0.75rem 1.25rem;
  border: 1px solid rgba(106, 113, 133, 0.3);
}
.default-according .card .card-header i {
  position: absolute;
  left: 18px;
  font-size: 20px;
  top: 20px;
}
.default-according .card .card-header h5 {
  margin-top: 2px;
}
.default-according .card .card-body {
  border: 1px solid rgba(106, 113, 133, 0.3);
  border-top: none;
}

.default-according.style-1 button {
  width: 100%;
  text-align: left;
}
.default-according.style-1 button:before {
  right: 20px;
  position: absolute;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media (max-width: 575px) {
  .default-according.style-1 button:before {
    right: 15px;
  }
}
.default-according.style-1 button[aria-expanded=true]:before {
  content: "\eb73";
  font-family: IcoFont;
}
.default-according.style-1 button[aria-expanded=false]:before {
  content: "\eb7a";
  font-family: IcoFont;
}

.accordion-button .svg-color {
  position: absolute;
  right: 20px;
}
[dir=rtl] .accordion-button .svg-color {
  left: 20px;
  right: unset;
}
.accordion-button .svg-color::after {
  content: "";
  background-image: unset;
}
.accordion-button:not(.collapsed) {
  background-color: unset;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.accordion-button:not(.collapsed)::after {
  background-image: unset;
}
.accordion-button::after {
  background-image: unset;
}
.accordion-button:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.accordion-button.accordion-light-primary:not(.collapsed) {
  background-color: #E0DDFF;
}
.accordion-button.accordion-light-primary:active, .accordion-button.accordion-light-primary.active {
  background-color: #E0DDFF;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-primary {
  background-color: #E0DDFF !important;
}

.accordion-button.accordion-light-secondary:not(.collapsed) {
  background-color: #c7eaff;
}
.accordion-button.accordion-light-secondary:active, .accordion-button.accordion-light-secondary.active {
  background-color: #c7eaff;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-secondary {
  background-color: #c7eaff !important;
}

.accordion-button.accordion-light-success:not(.collapsed) {
  background-color: #c8f9e1;
}
.accordion-button.accordion-light-success:active, .accordion-button.accordion-light-success.active {
  background-color: #c8f9e1;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-success {
  background-color: #c8f9e1 !important;
}

.accordion-button.accordion-light-warning:not(.collapsed) {
  background-color: #fbdac9;
}
.accordion-button.accordion-light-warning:active, .accordion-button.accordion-light-warning.active {
  background-color: #fbdac9;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-warning {
  background-color: #fbdac9 !important;
}

.accordion-button.accordion-light-info:not(.collapsed) {
  background-color: #d0f3fd;
}
.accordion-button.accordion-light-info:active, .accordion-button.accordion-light-info.active {
  background-color: #d0f3fd;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-info {
  background-color: #d0f3fd !important;
}

.accordion-button.accordion-light-light:not(.collapsed) {
  background-color: #F9F9FB;
}
.accordion-button.accordion-light-light:active, .accordion-button.accordion-light-light.active {
  background-color: #F9F9FB;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-light {
  background-color: #F9F9FB !important;
}

.accordion-button.accordion-light-danger:not(.collapsed) {
  background-color: #FEDDDB;
}
.accordion-button.accordion-light-danger:active, .accordion-button.accordion-light-danger.active {
  background-color: #FEDDDB;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-danger {
  background-color: #FEDDDB !important;
}

.accordion-button.accordion-light-dark:not(.collapsed) {
  background-color: #EAEBF2;
}
.accordion-button.accordion-light-dark:active, .accordion-button.accordion-light-dark.active {
  background-color: #EAEBF2;
  color: #000;
}

.multi-collapse.show .collapse-wrapper.accordion-light-dark {
  background-color: #EAEBF2 !important;
}

.accordion-item .accordion-button .svg-wrapper {
  width: 18px;
  height: 18px;
}

.accordion-wrapper:active {
  border: 1px solid #337ab7;
  border-top: 1px solid #337ab7 !important;
}

.accordion-border .accordion-item {
  border: 0;
}

.icons-accordion .accordion-item:last-child .accordion-body {
  padding-bottom: 0 !important;
}

.accordions-content li {
  color: #2c323f;
}

.collapse-accordion .btn-dark.show {
  border-radius: 15px;
}

/**=====================
    2.1 According CSS Ends
==========================**/
/**=====================
     2.2 Alert CSS start
==========================**/
.notify-alert .close {
  background: unset;
  border: none;
  font-size: 25px;
}

.alert {
  border-radius: 0.15rem;
  padding: 15px;
}
.alert svg {
  vertical-align: middle;
}
.alert .progress {
  margin-top: 15px;
}
.alert [data-notify=icon] {
  margin-right: 10px;
  line-height: 20px;
  position: absolute;
}
.alert [data-notify=message] {
  display: inline-block;
  width: 100%;
}
.alert i {
  margin-right: 5px;
  font-size: 16px;
}
.alert p {
  margin-bottom: 0;
}
.alert .close {
  opacity: 1;
}
.alert .close span {
  font-size: 24px;
  font-weight: 400;
  display: inline-block;
  border-radius: 3px;
  text-shadow: none;
  padding: 0 5px;
  padding-right: 0;
}

.alert-dismissible {
  padding-right: 2.5rem;
}
[dir=rtl] .alert-dismissible {
  padding-right: 15px;
  padding-left: 2.5rem;
}
.alert-dismissible .close {
  padding: 10px 1.25rem;
  top: 4px;
  padding-right: 13px;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.alert-dismissible .close:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
  color: inherit;
}

.card-body button:focus {
  outline: none;
}
.card-body .alert:last-child {
  margin-bottom: 0;
}
.card-body .alert svg {
  width: 14px;
  height: 14px;
  margin-right: 4px;
  position: absolute;
  top: 18px;
}
.card-body .alert svg ~ p {
  padding-left: 20px;
}

strong {
  display: inline-block;
}
strong ~ p {
  display: inline-block;
}

.alert-primary {
  background-color: #337ab7;
  border-color: rgba(122, 112, 186, 0.9);
  color: #fff;
}
.alert-primary .progress {
  height: 5px;
  background-color: #776db8;
  border-radius: 0;
}
.alert-primary .progress-bar {
  background-color: white;
}
.alert-primary .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-primary .alert-link {
  color: #337ab7;
}
.alert-primary hr {
  border-top-color: #337ab7;
}

.border-primary {
  border-color: #337ab7 !important;
}

.income-wrapper ul li .income-dot.dot-primary {
  background-color: #337ab7;
}

.touchspin-wrapper .btn-touchspin.touchspin-primary {
  background-color: #337ab7;
  border: 1px solid #337ab7;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-primary {
  background-color: unset;
  border: 1px solid #337ab7;
  color: #337ab7;
}
.touchspin-wrapper input[type=number].spin-outline-primary {
  outline: 1px solid #337ab7;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-primary {
  background-color: #337ab7;
  border: 1px solid #337ab7;
  color: #fff;
}

.form-switch .form-check-input.switch-primary:checked {
  background-color: #337ab7;
  border-color: #337ab7;
}

.payment-second .mega-icons.stroke-primary {
  stroke: #337ab7;
}

.toast-primary {
  color: #337ab7 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-primary, .list-group-horizontal-md .list-group-item.border-left-primary, .list-group-horizontal-lg .list-group-item.border-left-primary, .list-group-horizontal-xl .list-group-item.border-left-primary, .list-group-horizontal-xxl .list-group-item.border-left-primary {
  border-left: 4px solid #337ab7;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-primary, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-primary {
  border-left: unset;
  border-right: 4px solid #337ab7;
}

.main-radio-toggle .btn-check.radio-light-primary:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(122, 112, 186, 0.55);
          box-shadow: 0 0 0 4px rgba(122, 112, 186, 0.55);
}

.fill-radios .radio.radio-primary input[type=radio]:checked + label::before {
  border: 10px solid #337ab7;
}
.fill-radios .radio.radio-primary input[type=radio]:checked + label::after {
  background-color: #337ab7;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-primary,
.list-group [type=radio].checkbox-primary {
  background-color: unset;
  border-color: #337ab7;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-primary:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-primary:active, .list-group .form-check-input:checked[type=checkbox].checkbox-primary.active,
.list-group [type=radio].checkbox-primary:focus,
.list-group [type=radio].checkbox-primary:active,
.list-group [type=radio].checkbox-primary.active {
  border-color: #337ab7;
  outline: 0;
  background-color: #337ab7;
}
.list-group .alert-primary.light {
  background-color: rgba(122, 112, 186, 0.4);
  border-color: rgba(122, 112, 186, 0.7);
}
.list-group .alert-primary.dark {
  background-color: #337ab7;
  border-color: #337ab7;
  color: #fafafa;
}
.list-group .alert-primary.dark .alert-link {
  color: #fff;
}
.list-group .alert-primary.outline,
.list-group .alert-primary.outline-2x {
  background-color: transparent;
  border-color: #337ab7;
  color: #337ab7;
}
.list-group .alert-primary.outline .btn-close,
.list-group .alert-primary.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-primary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #337ab7;
  color: #3D434A;
}
.list-group .alert-primary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #337ab7;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-primary.inverse:before {
    left: 45px;
  }
}
.list-group .alert-primary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #337ab7;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-primary.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-secondary {
  background-color: #48a3d7;
  border-color: rgba(72, 163, 215, 0.9);
  color: #fff;
}
.alert-secondary .progress {
  height: 5px;
  background-color: #44a1d6;
  border-radius: 0;
}
.alert-secondary .progress-bar {
  background-color: white;
}
.alert-secondary .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-secondary .alert-link {
  color: #48A3D7;
}
.alert-secondary hr {
  border-top-color: #48A3D7;
}

.border-secondary {
  border-color: #48A3D7 !important;
}

.income-wrapper ul li .income-dot.dot-secondary {
  background-color: #48A3D7;
}

.touchspin-wrapper .btn-touchspin.touchspin-secondary {
  background-color: #48A3D7;
  border: 1px solid #48A3D7;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-secondary {
  background-color: unset;
  border: 1px solid #48A3D7;
  color: #48A3D7;
}
.touchspin-wrapper input[type=number].spin-outline-secondary {
  outline: 1px solid #48A3D7;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-secondary {
  background-color: #48A3D7;
  border: 1px solid #48A3D7;
  color: #fff;
}

.form-switch .form-check-input.switch-secondary:checked {
  background-color: #48A3D7;
  border-color: #48A3D7;
}

.payment-second .mega-icons.stroke-secondary {
  stroke: #48A3D7;
}

.toast-secondary {
  color: #48A3D7 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-secondary, .list-group-horizontal-md .list-group-item.border-left-secondary, .list-group-horizontal-lg .list-group-item.border-left-secondary, .list-group-horizontal-xl .list-group-item.border-left-secondary, .list-group-horizontal-xxl .list-group-item.border-left-secondary {
  border-left: 4px solid #48A3D7;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-secondary, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-secondary {
  border-left: unset;
  border-right: 4px solid #48A3D7;
}

.main-radio-toggle .btn-check.radio-light-secondary:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(72, 163, 215, 0.55);
          box-shadow: 0 0 0 4px rgba(72, 163, 215, 0.55);
}

.fill-radios .radio.radio-secondary input[type=radio]:checked + label::before {
  border: 10px solid #48A3D7;
}
.fill-radios .radio.radio-secondary input[type=radio]:checked + label::after {
  background-color: #48A3D7;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-secondary,
.list-group [type=radio].checkbox-secondary {
  background-color: unset;
  border-color: #48A3D7;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-secondary:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-secondary:active, .list-group .form-check-input:checked[type=checkbox].checkbox-secondary.active,
.list-group [type=radio].checkbox-secondary:focus,
.list-group [type=radio].checkbox-secondary:active,
.list-group [type=radio].checkbox-secondary.active {
  border-color: #48A3D7;
  outline: 0;
  background-color: #48A3D7;
}
.list-group .alert-secondary.light {
  background-color: rgba(72, 163, 215, 0.4);
  border-color: rgba(72, 163, 215, 0.7);
}
.list-group .alert-secondary.dark {
  background-color: #48A3D7;
  border-color: #48A3D7;
  color: #fafafa;
}
.list-group .alert-secondary.dark .alert-link {
  color: #fff;
}
.list-group .alert-secondary.outline,
.list-group .alert-secondary.outline-2x {
  background-color: transparent;
  border-color: #48A3D7;
  color: #48A3D7;
}
.list-group .alert-secondary.outline .btn-close,
.list-group .alert-secondary.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-secondary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #48A3D7;
  color: #3D434A;
}
.list-group .alert-secondary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #48A3D7;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-secondary.inverse:before {
    left: 45px;
  }
}
.list-group .alert-secondary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #48A3D7;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-secondary.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-tertiary {
  background-color: #c95e9e;
  border-color: rgba(201, 94, 158, 0.9);
  color: #fff;
}
.alert-tertiary .progress {
  height: 5px;
  background-color: #c85a9c;
  border-radius: 0;
}
.alert-tertiary .progress-bar {
  background-color: white;
}
.alert-tertiary .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-tertiary .alert-link {
  color: #C95E9E;
}
.alert-tertiary hr {
  border-top-color: #C95E9E;
}

.border-tertiary {
  border-color: #C95E9E !important;
}

.income-wrapper ul li .income-dot.dot-tertiary {
  background-color: #C95E9E;
}

.touchspin-wrapper .btn-touchspin.touchspin-tertiary {
  background-color: #C95E9E;
  border: 1px solid #C95E9E;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-tertiary {
  background-color: unset;
  border: 1px solid #C95E9E;
  color: #C95E9E;
}
.touchspin-wrapper input[type=number].spin-outline-tertiary {
  outline: 1px solid #C95E9E;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-tertiary {
  background-color: #C95E9E;
  border: 1px solid #C95E9E;
  color: #fff;
}

.form-switch .form-check-input.switch-tertiary:checked {
  background-color: #C95E9E;
  border-color: #C95E9E;
}

.payment-second .mega-icons.stroke-tertiary {
  stroke: #C95E9E;
}

.toast-tertiary {
  color: #C95E9E !important;
}

.list-group-horizontal-sm .list-group-item.border-left-tertiary, .list-group-horizontal-md .list-group-item.border-left-tertiary, .list-group-horizontal-lg .list-group-item.border-left-tertiary, .list-group-horizontal-xl .list-group-item.border-left-tertiary, .list-group-horizontal-xxl .list-group-item.border-left-tertiary {
  border-left: 4px solid #C95E9E;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-tertiary, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-tertiary, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-tertiary, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-tertiary, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-tertiary {
  border-left: unset;
  border-right: 4px solid #C95E9E;
}

.main-radio-toggle .btn-check.radio-light-tertiary:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(201, 94, 158, 0.55);
          box-shadow: 0 0 0 4px rgba(201, 94, 158, 0.55);
}

.fill-radios .radio.radio-tertiary input[type=radio]:checked + label::before {
  border: 10px solid #C95E9E;
}
.fill-radios .radio.radio-tertiary input[type=radio]:checked + label::after {
  background-color: #C95E9E;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-tertiary,
.list-group [type=radio].checkbox-tertiary {
  background-color: unset;
  border-color: #C95E9E;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-tertiary:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-tertiary:active, .list-group .form-check-input:checked[type=checkbox].checkbox-tertiary.active,
.list-group [type=radio].checkbox-tertiary:focus,
.list-group [type=radio].checkbox-tertiary:active,
.list-group [type=radio].checkbox-tertiary.active {
  border-color: #C95E9E;
  outline: 0;
  background-color: #C95E9E;
}
.list-group .alert-tertiary.light {
  background-color: rgba(201, 94, 158, 0.4);
  border-color: rgba(201, 94, 158, 0.7);
}
.list-group .alert-tertiary.dark {
  background-color: #C95E9E;
  border-color: #C95E9E;
  color: #fafafa;
}
.list-group .alert-tertiary.dark .alert-link {
  color: #fff;
}
.list-group .alert-tertiary.outline,
.list-group .alert-tertiary.outline-2x {
  background-color: transparent;
  border-color: #C95E9E;
  color: #C95E9E;
}
.list-group .alert-tertiary.outline .btn-close,
.list-group .alert-tertiary.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-tertiary.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #C95E9E;
  color: #3D434A;
}
.list-group .alert-tertiary.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #C95E9E;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-tertiary.inverse:before {
    left: 45px;
  }
}
.list-group .alert-tertiary.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #C95E9E;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-tertiary.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-success {
  background-color: #0da759;
  border-color: rgba(13, 167, 89, 0.9);
  color: #fff;
}
.alert-success .progress {
  height: 5px;
  background-color: #0da256;
  border-radius: 0;
}
.alert-success .progress-bar {
  background-color: #b9fad9;
}
.alert-success .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-success .alert-link {
  color: #0DA759;
}
.alert-success hr {
  border-top-color: #0DA759;
}

.border-success {
  border-color: #0DA759 !important;
}

.income-wrapper ul li .income-dot.dot-success {
  background-color: #0DA759;
}

.touchspin-wrapper .btn-touchspin.touchspin-success {
  background-color: #0DA759;
  border: 1px solid #0DA759;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-success {
  background-color: unset;
  border: 1px solid #0DA759;
  color: #0DA759;
}
.touchspin-wrapper input[type=number].spin-outline-success {
  outline: 1px solid #0DA759;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-success {
  background-color: #0DA759;
  border: 1px solid #0DA759;
  color: #fff;
}

.form-switch .form-check-input.switch-success:checked {
  background-color: #0DA759;
  border-color: #0DA759;
}

.payment-second .mega-icons.stroke-success {
  stroke: #0DA759;
}

.toast-success {
  color: #0DA759 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-success, .list-group-horizontal-md .list-group-item.border-left-success, .list-group-horizontal-lg .list-group-item.border-left-success, .list-group-horizontal-xl .list-group-item.border-left-success, .list-group-horizontal-xxl .list-group-item.border-left-success {
  border-left: 4px solid #0DA759;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-success, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-success {
  border-left: unset;
  border-right: 4px solid #0DA759;
}

.main-radio-toggle .btn-check.radio-light-success:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(13, 167, 89, 0.55);
          box-shadow: 0 0 0 4px rgba(13, 167, 89, 0.55);
}

.fill-radios .radio.radio-success input[type=radio]:checked + label::before {
  border: 10px solid #0DA759;
}
.fill-radios .radio.radio-success input[type=radio]:checked + label::after {
  background-color: #0DA759;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-success,
.list-group [type=radio].checkbox-success {
  background-color: unset;
  border-color: #0DA759;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-success:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-success:active, .list-group .form-check-input:checked[type=checkbox].checkbox-success.active,
.list-group [type=radio].checkbox-success:focus,
.list-group [type=radio].checkbox-success:active,
.list-group [type=radio].checkbox-success.active {
  border-color: #0DA759;
  outline: 0;
  background-color: #0DA759;
}
.list-group .alert-success.light {
  background-color: rgba(13, 167, 89, 0.4);
  border-color: rgba(13, 167, 89, 0.7);
}
.list-group .alert-success.dark {
  background-color: #0DA759;
  border-color: #0DA759;
  color: #fafafa;
}
.list-group .alert-success.dark .alert-link {
  color: #fff;
}
.list-group .alert-success.outline,
.list-group .alert-success.outline-2x {
  background-color: transparent;
  border-color: #0DA759;
  color: #0DA759;
}
.list-group .alert-success.outline .btn-close,
.list-group .alert-success.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-success.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #0DA759;
  color: #3D434A;
}
.list-group .alert-success.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #0DA759;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-success.inverse:before {
    left: 45px;
  }
}
.list-group .alert-success.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #0DA759;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-success.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-danger {
  background-color: #e44141;
  border-color: rgba(228, 65, 65, 0.9);
  color: #fff;
}
.alert-danger .progress {
  height: 5px;
  background-color: #e33d3d;
  border-radius: 0;
}
.alert-danger .progress-bar {
  background-color: white;
}
.alert-danger .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-danger .alert-link {
  color: #E44141;
}
.alert-danger hr {
  border-top-color: #E44141;
}

.border-danger {
  border-color: #E44141 !important;
}

.income-wrapper ul li .income-dot.dot-danger {
  background-color: #E44141;
}

.touchspin-wrapper .btn-touchspin.touchspin-danger {
  background-color: #E44141;
  border: 1px solid #E44141;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-danger {
  background-color: unset;
  border: 1px solid #E44141;
  color: #E44141;
}
.touchspin-wrapper input[type=number].spin-outline-danger {
  outline: 1px solid #E44141;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-danger {
  background-color: #E44141;
  border: 1px solid #E44141;
  color: #fff;
}

.form-switch .form-check-input.switch-danger:checked {
  background-color: #E44141;
  border-color: #E44141;
}

.payment-second .mega-icons.stroke-danger {
  stroke: #E44141;
}

.toast-danger {
  color: #E44141 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-danger, .list-group-horizontal-md .list-group-item.border-left-danger, .list-group-horizontal-lg .list-group-item.border-left-danger, .list-group-horizontal-xl .list-group-item.border-left-danger, .list-group-horizontal-xxl .list-group-item.border-left-danger {
  border-left: 4px solid #E44141;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-danger, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-danger {
  border-left: unset;
  border-right: 4px solid #E44141;
}

.main-radio-toggle .btn-check.radio-light-danger:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(228, 65, 65, 0.55);
          box-shadow: 0 0 0 4px rgba(228, 65, 65, 0.55);
}

.fill-radios .radio.radio-danger input[type=radio]:checked + label::before {
  border: 10px solid #E44141;
}
.fill-radios .radio.radio-danger input[type=radio]:checked + label::after {
  background-color: #E44141;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-danger,
.list-group [type=radio].checkbox-danger {
  background-color: unset;
  border-color: #E44141;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-danger:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-danger:active, .list-group .form-check-input:checked[type=checkbox].checkbox-danger.active,
.list-group [type=radio].checkbox-danger:focus,
.list-group [type=radio].checkbox-danger:active,
.list-group [type=radio].checkbox-danger.active {
  border-color: #E44141;
  outline: 0;
  background-color: #E44141;
}
.list-group .alert-danger.light {
  background-color: rgba(228, 65, 65, 0.4);
  border-color: rgba(228, 65, 65, 0.7);
}
.list-group .alert-danger.dark {
  background-color: #E44141;
  border-color: #E44141;
  color: #fafafa;
}
.list-group .alert-danger.dark .alert-link {
  color: #fff;
}
.list-group .alert-danger.outline,
.list-group .alert-danger.outline-2x {
  background-color: transparent;
  border-color: #E44141;
  color: #E44141;
}
.list-group .alert-danger.outline .btn-close,
.list-group .alert-danger.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-danger.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #E44141;
  color: #3D434A;
}
.list-group .alert-danger.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #E44141;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-danger.inverse:before {
    left: 45px;
  }
}
.list-group .alert-danger.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #E44141;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-danger.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-warning {
  background-color: #d77748;
  border-color: rgba(215, 119, 72, 0.9);
  color: #fff;
}
.alert-warning .progress {
  height: 5px;
  background-color: #d67444;
  border-radius: 0;
}
.alert-warning .progress-bar {
  background-color: white;
}
.alert-warning .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-warning .alert-link {
  color: #D77748;
}
.alert-warning hr {
  border-top-color: #D77748;
}

.border-warning {
  border-color: #D77748 !important;
}

.income-wrapper ul li .income-dot.dot-warning {
  background-color: #D77748;
}

.touchspin-wrapper .btn-touchspin.touchspin-warning {
  background-color: #D77748;
  border: 1px solid #D77748;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-warning {
  background-color: unset;
  border: 1px solid #D77748;
  color: #D77748;
}
.touchspin-wrapper input[type=number].spin-outline-warning {
  outline: 1px solid #D77748;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-warning {
  background-color: #D77748;
  border: 1px solid #D77748;
  color: #fff;
}

.form-switch .form-check-input.switch-warning:checked {
  background-color: #D77748;
  border-color: #D77748;
}

.payment-second .mega-icons.stroke-warning {
  stroke: #D77748;
}

.toast-warning {
  color: #D77748 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-warning, .list-group-horizontal-md .list-group-item.border-left-warning, .list-group-horizontal-lg .list-group-item.border-left-warning, .list-group-horizontal-xl .list-group-item.border-left-warning, .list-group-horizontal-xxl .list-group-item.border-left-warning {
  border-left: 4px solid #D77748;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-warning, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-warning {
  border-left: unset;
  border-right: 4px solid #D77748;
}

.main-radio-toggle .btn-check.radio-light-warning:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(215, 119, 72, 0.55);
          box-shadow: 0 0 0 4px rgba(215, 119, 72, 0.55);
}

.fill-radios .radio.radio-warning input[type=radio]:checked + label::before {
  border: 10px solid #D77748;
}
.fill-radios .radio.radio-warning input[type=radio]:checked + label::after {
  background-color: #D77748;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-warning,
.list-group [type=radio].checkbox-warning {
  background-color: unset;
  border-color: #D77748;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-warning:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-warning:active, .list-group .form-check-input:checked[type=checkbox].checkbox-warning.active,
.list-group [type=radio].checkbox-warning:focus,
.list-group [type=radio].checkbox-warning:active,
.list-group [type=radio].checkbox-warning.active {
  border-color: #D77748;
  outline: 0;
  background-color: #D77748;
}
.list-group .alert-warning.light {
  background-color: rgba(215, 119, 72, 0.4);
  border-color: rgba(215, 119, 72, 0.7);
}
.list-group .alert-warning.dark {
  background-color: #D77748;
  border-color: #D77748;
  color: #fafafa;
}
.list-group .alert-warning.dark .alert-link {
  color: #fff;
}
.list-group .alert-warning.outline,
.list-group .alert-warning.outline-2x {
  background-color: transparent;
  border-color: #D77748;
  color: #D77748;
}
.list-group .alert-warning.outline .btn-close,
.list-group .alert-warning.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-warning.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #D77748;
  color: #3D434A;
}
.list-group .alert-warning.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #D77748;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-warning.inverse:before {
    left: 45px;
  }
}
.list-group .alert-warning.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #D77748;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-warning.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-info {
  background-color: #16c7f9;
  border-color: rgba(22, 199, 249, 0.9);
  color: #fff;
}
.alert-info .progress {
  height: 5px;
  background-color: #11c6f9;
  border-radius: 0;
}
.alert-info .progress-bar {
  background-color: white;
}
.alert-info .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-info .alert-link {
  color: #16C7F9;
}
.alert-info hr {
  border-top-color: #16C7F9;
}

.border-info {
  border-color: #16C7F9 !important;
}

.income-wrapper ul li .income-dot.dot-info {
  background-color: #16C7F9;
}

.touchspin-wrapper .btn-touchspin.touchspin-info {
  background-color: #16C7F9;
  border: 1px solid #16C7F9;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-info {
  background-color: unset;
  border: 1px solid #16C7F9;
  color: #16C7F9;
}
.touchspin-wrapper input[type=number].spin-outline-info {
  outline: 1px solid #16C7F9;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-info {
  background-color: #16C7F9;
  border: 1px solid #16C7F9;
  color: #fff;
}

.form-switch .form-check-input.switch-info:checked {
  background-color: #16C7F9;
  border-color: #16C7F9;
}

.payment-second .mega-icons.stroke-info {
  stroke: #16C7F9;
}

.toast-info {
  color: #16C7F9 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-info, .list-group-horizontal-md .list-group-item.border-left-info, .list-group-horizontal-lg .list-group-item.border-left-info, .list-group-horizontal-xl .list-group-item.border-left-info, .list-group-horizontal-xxl .list-group-item.border-left-info {
  border-left: 4px solid #16C7F9;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-info, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-info {
  border-left: unset;
  border-right: 4px solid #16C7F9;
}

.main-radio-toggle .btn-check.radio-light-info:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(22, 199, 249, 0.55);
          box-shadow: 0 0 0 4px rgba(22, 199, 249, 0.55);
}

.fill-radios .radio.radio-info input[type=radio]:checked + label::before {
  border: 10px solid #16C7F9;
}
.fill-radios .radio.radio-info input[type=radio]:checked + label::after {
  background-color: #16C7F9;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-info,
.list-group [type=radio].checkbox-info {
  background-color: unset;
  border-color: #16C7F9;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-info:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-info:active, .list-group .form-check-input:checked[type=checkbox].checkbox-info.active,
.list-group [type=radio].checkbox-info:focus,
.list-group [type=radio].checkbox-info:active,
.list-group [type=radio].checkbox-info.active {
  border-color: #16C7F9;
  outline: 0;
  background-color: #16C7F9;
}
.list-group .alert-info.light {
  background-color: rgba(22, 199, 249, 0.4);
  border-color: rgba(22, 199, 249, 0.7);
}
.list-group .alert-info.dark {
  background-color: #16C7F9;
  border-color: #16C7F9;
  color: #fafafa;
}
.list-group .alert-info.dark .alert-link {
  color: #fff;
}
.list-group .alert-info.outline,
.list-group .alert-info.outline-2x {
  background-color: transparent;
  border-color: #16C7F9;
  color: #16C7F9;
}
.list-group .alert-info.outline .btn-close,
.list-group .alert-info.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-info.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #16C7F9;
  color: #3D434A;
}
.list-group .alert-info.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #16C7F9;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-info.inverse:before {
    left: 45px;
  }
}
.list-group .alert-info.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #16C7F9;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-info.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-light {
  background-color: #f4f4f4;
  border-color: rgba(244, 244, 244, 0.9);
  color: #fff;
  color: #2c323f;
  background-color: #f4f4f4;
}
.alert-light .progress {
  height: 5px;
  background-color: #f1f1f1;
  border-radius: 0;
}
.alert-light .progress-bar {
  background-color: white;
}
.alert-light .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-light .alert-link {
  color: #f4f4f4;
  color: #2c323f;
}
.alert-light .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.alert-light hr {
  border-top-color: #f4f4f4;
}

.border-light {
  border-color: #f4f4f4 !important;
}

.income-wrapper ul li .income-dot.dot-light {
  background-color: #f4f4f4;
}

.touchspin-wrapper .btn-touchspin.touchspin-light {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-light {
  background-color: unset;
  border: 1px solid #f4f4f4;
  color: #f4f4f4;
}
.touchspin-wrapper input[type=number].spin-outline-light {
  outline: 1px solid #f4f4f4;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-light {
  background-color: #f4f4f4;
  border: 1px solid #f4f4f4;
  color: #fff;
}

.form-switch .form-check-input.switch-light:checked {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.payment-second .mega-icons.stroke-light {
  stroke: #f4f4f4;
}

.toast-light {
  color: #f4f4f4 !important;
}

.list-group-horizontal-sm .list-group-item.border-left-light, .list-group-horizontal-md .list-group-item.border-left-light, .list-group-horizontal-lg .list-group-item.border-left-light, .list-group-horizontal-xl .list-group-item.border-left-light, .list-group-horizontal-xxl .list-group-item.border-left-light {
  border-left: 4px solid #f4f4f4;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-light, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-light {
  border-left: unset;
  border-right: 4px solid #f4f4f4;
}

.main-radio-toggle .btn-check.radio-light-light:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(244, 244, 244, 0.55);
          box-shadow: 0 0 0 4px rgba(244, 244, 244, 0.55);
}

.fill-radios .radio.radio-light input[type=radio]:checked + label::before {
  border: 10px solid #f4f4f4;
}
.fill-radios .radio.radio-light input[type=radio]:checked + label::after {
  background-color: #f4f4f4;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-light,
.list-group [type=radio].checkbox-light {
  background-color: unset;
  border-color: #f4f4f4;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-light:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-light:active, .list-group .form-check-input:checked[type=checkbox].checkbox-light.active,
.list-group [type=radio].checkbox-light:focus,
.list-group [type=radio].checkbox-light:active,
.list-group [type=radio].checkbox-light.active {
  border-color: #f4f4f4;
  outline: 0;
  background-color: #f4f4f4;
}
.list-group .alert-light.light {
  background-color: rgba(244, 244, 244, 0.4);
  border-color: rgba(244, 244, 244, 0.7);
}
.list-group .alert-light.dark {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: #fafafa;
  color: #2c323f;
}
.list-group .alert-light.dark .alert-link {
  color: #fff;
  color: #2c323f;
}
.list-group .alert-light.outline,
.list-group .alert-light.outline-2x {
  background-color: transparent;
  border-color: #f4f4f4;
  color: #f4f4f4;
  color: #2c323f;
}
.list-group .alert-light.outline .btn-close,
.list-group .alert-light.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-light.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #f4f4f4;
  color: #3D434A;
}
.list-group .alert-light.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #f4f4f4;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-light.inverse:before {
    left: 45px;
  }
}
.list-group .alert-light.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #f4f4f4;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-light.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.alert-dark {
  background-color: #2c323f;
  border-color: rgba(44, 50, 63, 0.9);
  color: #fff;
}
.alert-dark .progress {
  height: 5px;
  background-color: #2a303c;
  border-radius: 0;
}
.alert-dark .progress-bar {
  background-color: #a8b0c2;
}
.alert-dark .btn-close {
  -webkit-filter: brightness(1) invert(1);
          filter: brightness(1) invert(1);
}
.alert-dark .alert-link {
  color: #2c323f;
}
.alert-dark hr {
  border-top-color: #2c323f;
}

.border-dark {
  border-color: #2c323f !important;
}

.income-wrapper ul li .income-dot.dot-dark {
  background-color: #2c323f;
}

.touchspin-wrapper .btn-touchspin.touchspin-dark {
  background-color: #2c323f;
  border: 1px solid #2c323f;
  color: #fff;
}
.touchspin-wrapper .btn-touchspin.spin-border-dark {
  background-color: unset;
  border: 1px solid #2c323f;
  color: #2c323f;
}
.touchspin-wrapper input[type=number].spin-outline-dark {
  outline: 1px solid #2c323f;
  border: none;
  border-radius: 3px;
}

.pre-post-touchspin .btn-touchspin.touchspin-dark {
  background-color: #2c323f;
  border: 1px solid #2c323f;
  color: #fff;
}

.form-switch .form-check-input.switch-dark:checked {
  background-color: #2c323f;
  border-color: #2c323f;
}

.payment-second .mega-icons.stroke-dark {
  stroke: #2c323f;
}

.toast-dark {
  color: #2c323f !important;
}

.list-group-horizontal-sm .list-group-item.border-left-dark, .list-group-horizontal-md .list-group-item.border-left-dark, .list-group-horizontal-lg .list-group-item.border-left-dark, .list-group-horizontal-xl .list-group-item.border-left-dark, .list-group-horizontal-xxl .list-group-item.border-left-dark {
  border-left: 4px solid #2c323f;
}
[dir=rtl] .list-group-horizontal-sm .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-md .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-lg .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-xl .list-group-item.border-left-dark, [dir=rtl] .list-group-horizontal-xxl .list-group-item.border-left-dark {
  border-left: unset;
  border-right: 4px solid #2c323f;
}

.main-radio-toggle .btn-check.radio-light-dark:focus + .btn {
  -webkit-box-shadow: 0 0 0 4px rgba(44, 50, 63, 0.55);
          box-shadow: 0 0 0 4px rgba(44, 50, 63, 0.55);
}

.fill-radios .radio.radio-dark input[type=radio]:checked + label::before {
  border: 10px solid #2c323f;
}
.fill-radios .radio.radio-dark input[type=radio]:checked + label::after {
  background-color: #2c323f;
}

.list-group .form-check-input:checked[type=checkbox].checkbox-dark,
.list-group [type=radio].checkbox-dark {
  background-color: unset;
  border-color: #2c323f;
  outline: 0;
  background-color: unset;
}
.list-group .form-check-input:checked[type=checkbox].checkbox-dark:focus, .list-group .form-check-input:checked[type=checkbox].checkbox-dark:active, .list-group .form-check-input:checked[type=checkbox].checkbox-dark.active,
.list-group [type=radio].checkbox-dark:focus,
.list-group [type=radio].checkbox-dark:active,
.list-group [type=radio].checkbox-dark.active {
  border-color: #2c323f;
  outline: 0;
  background-color: #2c323f;
}
.list-group .alert-dark.light {
  background-color: rgba(44, 50, 63, 0.4);
  border-color: rgba(44, 50, 63, 0.7);
}
.list-group .alert-dark.dark {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fafafa;
}
.list-group .alert-dark.dark .alert-link {
  color: #fff;
}
.list-group .alert-dark.outline,
.list-group .alert-dark.outline-2x {
  background-color: transparent;
  border-color: #2c323f;
  color: #2c323f;
}
.list-group .alert-dark.outline .btn-close,
.list-group .alert-dark.outline-2x .btn-close {
  -webkit-filter: unset;
          filter: unset;
}
.list-group .alert-dark.inverse {
  background-color: transparent;
  padding: 13px 20px 13px 65px;
  border-color: #2c323f;
  color: #3D434A;
}
.list-group .alert-dark.inverse:before {
  top: 16px;
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #2c323f;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
@media (max-width: 576px) {
  .list-group .alert-dark.inverse:before {
    left: 45px;
  }
}
.list-group .alert-dark.inverse i {
  padding: 17px 20px;
  display: inline-block;
  background-color: #2c323f;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
@media (max-width: 576px) {
  .list-group .alert-dark.inverse i {
    padding: 17px 14px;
  }
}
.list-group .btn-close {
  -webkit-filter: unset;
          filter: unset;
  top: -3px;
}

.outline-2x {
  border-width: 2px;
  padding: 11px 1.25rem;
}

.alert-icons.outline-2x {
  padding: 15px;
}
.alert-icons svg {
  position: absolute !important;
  top: 18px !important;
}

.alert-dark .btn-close,
.alert-info .btn-close {
  -webkit-filter: none;
          filter: none;
}

.border-left-wrapper {
  border-left: 4px solid #2c323f;
}

.alert-light-primary {
  background-color: #E0DDFF;
}

.alert-light-secondary {
  background-color: #c7eaff;
}

.alert-light-success {
  background-color: #c8f9e1;
}

.alert-light-tertiary {
  background-color: #fbd8ed;
}

.alert-light-warning {
  background-color: #fbdac9;
}

.alert-light-info {
  background-color: #d0f3fd;
}

.alert-light-light {
  background-color: #F9F9FB;
}

.alert-light-danger {
  background-color: #FEDDDB;
}

.alert-light-dark {
  background-color: #EAEBF2;
}

.alert-arrow {
  min-width: 55px;
  height: 55px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.alert-arrow i {
  margin-right: 0;
}

@media (max-width: 576px) {
  .ellipsis.content {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 294px;
  }
}
@media (max-width: 575px) {
  .ellipsis.content {
    max-width: 180px;
  }
}

.alert-dismissible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}

.alert.notify-alert.alert-copy {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

/**=====================
   2.2 Alert CSS end
==========================**/
/**=====================
  2.3 Avatars CSS start
==========================**/
.avatars .avatar {
  display: inline-block;
  margin-right: 10px;
  position: relative;
  width: auto;
}
.avatars .avatar:last-child {
  margin-right: 0;
}
.avatars .avatar .b-r-30 {
  border-radius: 30px;
}
.avatars .avatar .b-r-35 {
  border-radius: 35px;
}
.avatars .avatar .b-r-25 {
  border-radius: 25px;
}
.avatars .avatar .b-r-15 {
  border-radius: 15px;
}
.avatars .avatar.ratio img {
  width: auto;
}
.avatars .avatar .status {
  position: absolute;
  height: 12%;
  width: 12%;
  border-radius: 100%;
  bottom: 12%;
  right: 4%;
}
.avatars .avatar .img-100 {
  width: calc(75px + 25 * (100vw - 320px) / 1600) !important;
  height: calc(75px + 25 * (100vw - 320px) / 1600);
}
.avatars .avatar .img-90 {
  width: calc(65px + 25 * (100vw - 320px) / 1600) !important;
  height: calc(65px + 25 * (100vw - 320px) / 1600);
}
.avatars .avatar .img-80 {
  width: calc(55px + 25 * (100vw - 320px) / 1600) !important;
  height: calc(55px + 25 * (100vw - 320px) / 1600);
}
.avatars .avatar .img-70 {
  width: calc(45px + 25 * (100vw - 320px) / 1600) !important;
  height: calc(45px + 25 * (100vw - 320px) / 1600);
}

.status-offline {
  background-color: #E44141;
}

.status-online {
  background-color: #0DA759;
}

.status-dnd {
  background-color: #D77748;
}

.customers ul {
  display: inline-block;
}
.customers ul li img {
  border: 2px solid var(--white);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.customers ul li img:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
          transform: translateY(-4px) scale(1.02);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.customers ul li + li {
  margin-left: -7%;
}
.customers.avatar-group {
  margin-right: 30px;
}
.customers.avatar-group:last-child {
  margin-right: 0;
}

.avatar-showcase {
  margin-bottom: -10px;
}
.avatar-showcase .avatars .avatar {
  margin-bottom: 10px;
}
.avatar-showcase .avatar-group {
  margin-bottom: 10px;
}
.avatar-showcase .ratio > * {
  position: relative;
  top: unset;
  left: unset;
}

/**=====================
    2.3 Avatars CSS Ends
==========================**/
/**=====================
    2.4 Badge CSS start
==========================**/
.badge-spacing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 12px;
}
.badge-spacing .badge {
  padding: 6px 12px;
  font-size: 12px;
}
.badge-spacing .badge.badge svg {
  padding-top: 0px;
}
.badge-spacing .badge.rounded-circle {
  padding: 10px 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.badge-spacing .badge.badge-p-space {
  padding: 10px 13px;
}
.badge-spacing .badge + .badge {
  margin-left: 0px;
}
.badge-spacing .badge.btn-p-space {
  padding: 8px 10px;
}

a.badge:hover {
  color: inherit;
}
a.badge:hover.badge-dark:hover {
  color: #fff;
}

.badge {
  padding: 0.44em 0.7em;
  font-family: "Nunito", sans-serif, sans-serif;
  font-weight: 500;
}
.badge + .badge {
  margin-left: 5px;
}
.badge svg {
  width: 16px;
  height: 16px;
  padding-top: 3px;
}

.b-ln-height {
  line-height: 5px;
}

.pill-badge-primary {
  background-color: var(--theme-deafult);
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(var(--theme-deafult), 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(var(--theme-deafult), 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-secondary {
  background-color: #48A3D7;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(72, 163, 215, 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(72, 163, 215, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-success {
  background-color: #0DA759;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(13, 167, 89, 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(13, 167, 89, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-danger {
  background-color: #E44141;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(228, 65, 65, 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(228, 65, 65, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-info {
  background-color: #16C7F9;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(22, 199, 249, 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(22, 199, 249, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-light {
  background-color: #f4f4f4;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(244, 244, 244, 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(244, 244, 244, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-dark {
  background-color: #2c323f;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(44, 50, 63, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-warning {
  background-color: #D77748;
  -webkit-box-shadow: -0.5px 4.33px 16px 0px rgba(215, 119, 72, 0.25);
          box-shadow: -0.5px 4.33px 16px 0px rgba(215, 119, 72, 0.25);
  color: #fff;
  border-radius: 0;
}

.pill-badge-primary {
  background-color: var(--theme-deafult);
}

.pill-badge-secondary {
  background-color: var(--theme-secondary);
}

.round-badge-primary {
  border-radius: 6px;
  background-color: var(--theme-deafult);
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(var(--theme-deafult), 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(var(--theme-deafult), 0.18);
  color: #fff !important;
}

.round-badge-secondary {
  border-radius: 6px;
  background-color: #48A3D7;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(72, 163, 215, 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(72, 163, 215, 0.18);
  color: #fff !important;
}

.round-badge-success {
  border-radius: 6px;
  background-color: #0DA759;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(13, 167, 89, 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(13, 167, 89, 0.18);
  color: #fff !important;
}

.round-badge-danger {
  border-radius: 6px;
  background-color: #E44141;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(228, 65, 65, 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(228, 65, 65, 0.18);
  color: #fff !important;
}

.round-badge-info {
  border-radius: 6px;
  background-color: #16C7F9;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(22, 199, 249, 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(22, 199, 249, 0.18);
  color: #fff !important;
}

.round-badge-light {
  border-radius: 6px;
  background-color: #f4f4f4;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(244, 244, 244, 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(244, 244, 244, 0.18);
  color: #fff !important;
}

.round-badge-dark {
  border-radius: 6px;
  background-color: #2c323f;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(44, 50, 63, 0.18);
  color: #fff !important;
}

.round-badge-warning {
  border-radius: 6px;
  background-color: #D77748;
  -webkit-box-shadow: -0.5px -6.67px 14px 0px rgba(215, 119, 72, 0.18);
          box-shadow: -0.5px -6.67px 14px 0px rgba(215, 119, 72, 0.18);
  color: #fff !important;
}

.round-badge-primary {
  background-color: var(--theme-deafult);
}

.round-badge-secondary {
  background-color: var(--theme-secondary);
}

.badge-heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 8px;
}

/**=====================
  2.4 Badge CSS Ends
==========================**/
/**=====================
    2.5 Bookmark CSS start
==========================**/
.bookmark ul li {
  display: inline-block;
}
.bookmark ul li .search-form {
  position: absolute;
  bottom: 53px;
  z-index: 1;
}
.bookmark ul li .search-form .form-control-search {
  top: 65px;
  position: absolute;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  right: -20px;
  -webkit-transform: translateY(-35px) scaleY(0);
          transform: translateY(-35px) scaleY(0);
  opacity: 0;
  visibility: hidden;
}
.bookmark ul li .search-form .form-control-search input {
  width: 240px;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 21px rgba(0, 0, 0, 0.1);
          box-shadow: 0 5px 21px rgba(0, 0, 0, 0.1);
}
.bookmark ul li .search-form .form-control-search.open {
  -webkit-transform: translateY(0px) scaleY(1);
          transform: translateY(0px) scaleY(1);
  opacity: 1;
  visibility: visible;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
  z-index: 100;
}
.bookmark ul li .search-form .form-control-search:after {
  top: 12px;
  left: 20px;
  z-index: 1;
}
.bookmark ul li .search-form .form-control-search:before {
  left: 50px;
  top: 9px;
  z-index: 2;
}
.bookmark ul li a {
  position: relative;
  color: #3D434A;
}
.bookmark ul li a svg {
  vertical-align: middle;
  height: 20px;
  width: 20px;
}
.bookmark ul li + li {
  margin-left: 10px;
}
.bookmark ul li:last-child {
  padding-right: 0;
}
.bookmark ul li:last-child a {
  border-radius: 5px;
}
.bookmark ul li:last-child a svg {
  color: #ff9f40;
}

.offcanvas-bookmark.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body .bookmark ul li a {
  z-index: 99999;
  color: rgba(255, 255, 255, 0.5);
}
.offcanvas-bookmark:before {
  position: fixed;
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 1;
  right: 0;
  left: 0;
}

body.modal-open .select2-container--open {
  width: unset !important;
  display: block;
}
body.modal-open .select2-container--open .select2-dropdown {
  z-index: 1076;
}

/**=====================
    2.5 Bookmark CSS Ends
==========================**/
/**=====================
2.6 Breadcrumb CSS Start
==========================**/
.breadcrumb-colored {
  padding: 12px 16px;
  border-radius: 4px;
}
.breadcrumb-colored li a {
  color: #fff;
}
.breadcrumb-colored li.active {
  color: #fff;
  opacity: 0.7;
}
.breadcrumb-colored .breadcrumb-item:before {
  color: #fff;
}
.breadcrumb-colored .breadcrumb-item.txt-dark:before {
  color: #000;
}

.breadcrumb-icon {
  --bs-breadcrumb-divider: ">";
}
.breadcrumb-icon li {
  display: inline-block;
}

.breadcrumb-no-divider {
  --bs-breadcrumb-divider: "";
}

.breadcrumb-item.active {
  text-transform: capitalize;
  color: #337ab7;
  font-weight: 600;
}

.breadcrumb-space .breadcrumb-item::before {
  padding-right: 0;
}

/**=====================
	2.6 Breadcrumb CSS ends
==========================**/
/**=====================
    2.7 Basic-card CSS Start
==========================**/
.dark-color-header {
  background-color: unset !important;
  color: #337ab7;
}
.dark-color-header ul li:first-child i, .dark-color-header ul li:last-child i {
  color: #337ab7 !important;
}
.dark-color-header ul li i {
  color: #f4f4f4 !important;
}

/**=====================
  2.7 Basic-card CSS Ends
==========================**/
/**=====================
     2.8 Builders CSS start
==========================**/
.form-builder .help-block {
  margin-bottom: 0;
  color: rgba(106, 113, 133, 0.3);
  text-transform: capitalize;
}
.form-builder .btn-light {
  color: rgba(106, 113, 133, 0.3);
}
.form-builder .copy-btn {
  padding: 8px;
}
.form-builder .nav-primary .nav-link.active,
.form-builder .nav-primary .nav-primary .show > .nav-link,
.form-builder .nav-primary .nav-pills.nav-primary .nav-link.active,
.form-builder .nav-primary .nav-pills.nav-primary .show > .nav-link {
  border-radius: 5px;
}
.form-builder .nav-primary .nav-link,
.form-builder .nav-primary .nav-pills.nav-primary .nav-link {
  color: #000;
  font-weight: 500;
}
.form-builder .theme-form {
  margin-top: 30px;
}

.login-main a {
  font-size: 14px !important;
  font-weight: 500 !important;
}

/**=====================
    2.8 Builders CSS Ends
==========================**/
/**=====================
  2.9 Buttons CSS start
==========================**/
.btn-link {
  color: var(--theme-deafult);
}

.purchase-btn {
  background: -webkit-gradient(linear, left top, right top, color-stop(-18.33%, var(--theme-deafult)), color-stop(62.78%, #563EE0));
  background: linear-gradient(90deg, var(--theme-deafult) -18.33%, #563EE0 62.78%);
  padding: 12px 20px;
  min-width: 200px;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.purchase-btn:hover {
  background: -webkit-gradient(linear, left top, right top, color-stop(-18.33%, #563EE0), color-stop(62.78%, var(--theme-deafult)));
  background: linear-gradient(90deg, #563EE0 -18.33%, var(--theme-deafult) 62.78%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
@media (max-width: 991px) {
  .purchase-btn {
    min-width: 160px;
  }
}
@media (max-width: 767px) {
  .purchase-btn {
    min-width: 140px;
    padding: 10px 17px;
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .purchase-btn {
    font-size: 14px;
  }
}

.btn-light:not([disabled]):not(.disabled).active.active {
  color: #3D434A;
  background-color: #efefef !important;
}

.btn-primary_-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(#948cc7), color-stop(51%, #6054ad), to(#948cc7));
  background-image: linear-gradient(to right, #948cc7 0%, #6054ad 51%, #948cc7 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}
.btn-primary_-gradien:focus, .btn-primary_-gradien:active, .btn-primary_-gradien.active {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(#948cc7), color-stop(0%), color-stop(#6054ad), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, #948cc7, 0%, #6054ad, 100%, #fff) !important;
}

.btn-secondary-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(#69b4de), color-stop(51%, #2c91ca), to(#69b4de));
  background-image: linear-gradient(to right, #69b4de 0%, #2c91ca 51%, #69b4de 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-secondary-gradien:hover, .btn-secondary-gradien:focus, .btn-secondary-gradien:active, .btn-secondary-gradien.active, .btn-secondary-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(#69b4de), color-stop(0%), color-stop(#2c91ca), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, #69b4de, 0%, #2c91ca, 100%, #fff) !important;
}

.btn-success-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(#10cd6d), color-stop(51%, #0a8145), to(#10cd6d));
  background-image: linear-gradient(to right, #10cd6d 0%, #0a8145 51%, #10cd6d 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-success-gradien:hover, .btn-success-gradien:focus, .btn-success-gradien:active, .btn-success-gradien.active, .btn-success-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(#10cd6d), color-stop(0%), color-stop(#0a8145), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, #10cd6d, 0%, #0a8145, 100%, #fff) !important;
}

.btn-danger-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(#e96565), color-stop(51%, #dd1f1f), to(#e96565));
  background-image: linear-gradient(to right, #e96565 0%, #dd1f1f 51%, #e96565 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-danger-gradien:hover, .btn-danger-gradien:focus, .btn-danger-gradien:active, .btn-danger-gradien.active, .btn-danger-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(#e96565), color-stop(0%), color-stop(#dd1f1f), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, #e96565, 0%, #dd1f1f, 100%, #fff) !important;
}

.btn-warning-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(#de9069), color-stop(51%, #ca602c), to(#de9069));
  background-image: linear-gradient(to right, #de9069 0%, #ca602c 51%, #de9069 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-warning-gradien:hover, .btn-warning-gradien:focus, .btn-warning-gradien:active, .btn-warning-gradien.active, .btn-warning-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(#de9069), color-stop(0%), color-stop(#ca602c), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, #de9069, 0%, #ca602c, 100%, #fff) !important;
}

.btn-info-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(#3ed1fa), color-stop(51%, #06b0e0), to(#3ed1fa));
  background-image: linear-gradient(to right, #3ed1fa 0%, #06b0e0 51%, #3ed1fa 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-info-gradien:hover, .btn-info-gradien:focus, .btn-info-gradien:active, .btn-info-gradien.active, .btn-info-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(#3ed1fa), color-stop(0%), color-stop(#06b0e0), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, #3ed1fa, 0%, #06b0e0, 100%, #fff) !important;
}

.btn-light-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(white), color-stop(51%, #e0e0e0), to(white));
  background-image: linear-gradient(to right, white 0%, #e0e0e0 51%, white 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-light-gradien:hover, .btn-light-gradien:focus, .btn-light-gradien:active, .btn-light-gradien.active, .btn-light-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(white), color-stop(0%), color-stop(#e0e0e0), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, white, 0%, #e0e0e0, 100%, #fff) !important;
}

.btn-dark-gradien {
  background-image: -webkit-gradient(linear, left top, right top, from(#3d4557), color-stop(51%, #1b1f27), to(#3d4557));
  background-image: linear-gradient(to right, #3d4557 0%, #1b1f27 51%, #3d4557 100%);
  border: none;
  color: #fff;
  background-size: auto 200%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-dark-gradien:hover, .btn-dark-gradien:focus, .btn-dark-gradien:active, .btn-dark-gradien.active, .btn-dark-gradien.hover {
  -webkit-transition: 1.5s;
  transition: 1.5s;
  background-position: right center;
  background-image: -webkit-gradient(linear, left top, right top, from(#3d4557), color-stop(0%), color-stop(#1b1f27), color-stop(100%), to(#fff)) !important;
  background-image: linear-gradient(to right, #3d4557, 0%, #1b1f27, 100%, #fff) !important;
}

.btn {
  padding: 0.375rem 1.75rem;
}
.btn.bg-light-primary:hover {
  background: #337ab7 !important;
  color: #fff !important;
}
.btn.bg-light-secondary:hover {
  background: #48A3D7 !important;
  color: #fff !important;
}
.btn.bg-light-warning:hover {
  background: #D77748 !important;
  color: #fff !important;
}
.btn.bg-light-tertiary:hover {
  background: #C95E9E !important;
  color: #fff !important;
}

.btn-lg {
  font-size: 18px;
}

.btn-sm {
  font-size: 12px;
}

.btn-xs {
  padding: 0.05rem 0.4rem;
  font-size: 11px;
}

.large-btn {
  margin-bottom: -20px;
}
.large-btn .btn {
  margin-right: 5px;
  margin-bottom: 15px;
}

.btn-outline-white {
  border: 1px solid #fff;
  color: #fff;
}
.btn-outline-white:hover, .btn-outline-white:focus {
  background: #fff;
}

.btn-air-primary {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-primary:hover, .btn-air-primary:active, .btn-air-primary:not([disabled]):not(.disabled):active {
  background-color: #5c50a7;
  border-color: #5c50a7;
}

.input-air-primary {
  -webkit-box-shadow: 0 3px 5px 1px rgba(122, 112, 186, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(122, 112, 186, 0.1) !important;
}
.input-air-primary:focus {
  border-color: #337ab7;
}

.btn-air-secondary {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-secondary:hover, .btn-air-secondary:active, .btn-air-secondary:not([disabled]):not(.disabled):active {
  background-color: #2a8bc2;
  border-color: #2a8bc2;
}

.input-air-secondary {
  -webkit-box-shadow: 0 3px 5px 1px rgba(72, 163, 215, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(72, 163, 215, 0.1) !important;
}
.input-air-secondary:focus {
  border-color: #48A3D7;
}

.btn-air-success {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-success:hover, .btn-air-success:active, .btn-air-success:not([disabled]):not(.disabled):active {
  background-color: #097840;
  border-color: #097840;
}

.input-air-success {
  -webkit-box-shadow: 0 3px 5px 1px rgba(13, 167, 89, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(13, 167, 89, 0.1) !important;
}
.input-air-success:focus {
  border-color: #0DA759;
}

.btn-air-danger {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-danger:hover, .btn-air-danger:active, .btn-air-danger:not([disabled]):not(.disabled):active {
  background-color: #d41e1e;
  border-color: #d41e1e;
}

.input-air-danger {
  -webkit-box-shadow: 0 3px 5px 1px rgba(228, 65, 65, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(228, 65, 65, 0.1) !important;
}
.input-air-danger:focus {
  border-color: #E44141;
}

.btn-air-tertiary {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-tertiary:hover, .btn-air-tertiary:active, .btn-air-tertiary:not([disabled]):not(.disabled):active {
  background-color: #b73d86;
  border-color: #b73d86;
}

.input-air-tertiary {
  -webkit-box-shadow: 0 3px 5px 1px rgba(201, 94, 158, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(201, 94, 158, 0.1) !important;
}
.input-air-tertiary:focus {
  border-color: #C95E9E;
}

.btn-air-info {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-info:hover, .btn-air-info:active, .btn-air-info:not([disabled]):not(.disabled):active {
  background-color: #06a8d6;
  border-color: #06a8d6;
}

.input-air-info {
  -webkit-box-shadow: 0 3px 5px 1px rgba(22, 199, 249, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(22, 199, 249, 0.1) !important;
}
.input-air-info:focus {
  border-color: #16C7F9;
}

.btn-air-light {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-light:hover, .btn-air-light:active, .btn-air-light:not([disabled]):not(.disabled):active {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
}

.input-air-light {
  -webkit-box-shadow: 0 3px 5px 1px rgba(244, 244, 244, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(244, 244, 244, 0.1) !important;
}
.input-air-light:focus {
  border-color: #f4f4f4;
}

.btn-air-dark {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-dark:hover, .btn-air-dark:active, .btn-air-dark:not([disabled]):not(.disabled):active {
  background-color: #171a21;
  border-color: #171a21;
}

.input-air-dark {
  -webkit-box-shadow: 0 3px 5px 1px rgba(44, 50, 63, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(44, 50, 63, 0.1) !important;
}
.input-air-dark:focus {
  border-color: #2c323f;
}

.btn-air-warning {
  -webkit-box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
          box-shadow: 0 5px 10px 2px rgba(88, 103, 221, 0.19) !important;
}
.btn-air-warning:hover, .btn-air-warning:active, .btn-air-warning:not([disabled]):not(.disabled):active {
  background-color: #c25c2a;
  border-color: #c25c2a;
}

.input-air-warning {
  -webkit-box-shadow: 0 3px 5px 1px rgba(215, 119, 72, 0.1) !important;
          box-shadow: 0 3px 5px 1px rgba(215, 119, 72, 0.1) !important;
}
.input-air-warning:focus {
  border-color: #D77748;
}

.btn-gradient {
  color: #fff !important;
  font-weight: 500;
  padding: 10px 30px;
  border-radius: 5px;
  background: linear-gradient(26deg, var(--theme-deafult) 0%, #a26cf8 100%);
  letter-spacing: 0rem;
}
.btn-gradient:hover {
  background-size: 100% !important;
}

.btn-warning {
  color: #fff;
}
.btn-warning:hover, .btn-warning.disabled {
  color: #fff;
}

[class*=-gradien]:hover {
  background-size: 50% 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #fff;
}

.btn-square {
  border-radius: 0px;
}

.btn-pill {
  border-radius: 60px;
}

.btn-group-pill .btn:first-child {
  border-radius: 60px 0 0 60px;
}
.btn-group-pill .btn:last-child {
  border-radius: 0 60px 60px 0;
}

.btn-group-square .btn:first-child {
  border-radius: 0px 0 0 0px;
}
.btn-group-square .btn:last-child {
  border-radius: 0 0px 0px 0;
}

.btn-showcase {
  margin-bottom: -10px;
}
.btn-showcase .btn {
  margin-bottom: 10px;
  margin-right: 10px;
}

.btn-dropdown-showcase {
  margin-bottom: -10px;
}
.btn-dropdown-showcase .dropdown {
  margin-bottom: 10px;
  margin-right: 18px;
}
.btn-dropdown-showcase .btn-group {
  margin-bottom: 10px;
  margin-right: 18px;
}

.btn-group .radio label::before {
  top: 2px;
}
.btn-group .radio label::after {
  top: 7px;
}
.btn-group .checkbox label {
  margin-top: 3px;
}

.btn-group-showcase > div {
  margin-bottom: -20px;
}
.btn-group-showcase > div label {
  margin-bottom: 0;
}
.btn-group-showcase .btn-group {
  margin-right: 20px;
  margin-bottom: 20px;
}
.btn-group-showcase .checkbox input[type=checkbox] {
  display: none;
}

.tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 1;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.card-header-right-icon .btn.badge-light-primary:hover {
  color: var(--white);
}

/**=====================
    2.9 Buttons CSS end
==========================**/
/**=====================
    2.10 Card CSS Start
==========================**/
.card {
  margin-bottom: 30px;
  border: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0px;
  border-radius: 17px;
  -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
          box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.card:hover {
  -webkit-box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
          box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.card .card-header {
  background-color: #fff;
  padding: 6px 24px;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  position: relative;
  text-transform: capitalize;
}
.card .card-header.card-no-border {
  border-bottom: none !important;
}
.card .card-header h5:not(.mb-0), .card .card-header h5:not(.m-0) {
  margin-bottom: 0;
  text-transform: capitalize;
}
.card .card-header p {
  color: #6A7185;
}
.card .card-header > span {
  font-size: 14px;
  color: #9B9B9B;
  margin-top: 5px;
  display: block;
  letter-spacing: 0;
}
.card .card-header .card-header-right {
  border-radius: 0 0 0 7px;
  right: 25px;
  top: 10px;
  display: inline-block;
  float: right;
  padding: 8px 0;
  position: absolute;
  background-color: transparent;
  z-index: 1;
}
.card .card-header .card-header-right .card-option {
  text-align: right;
  width: 35px;
  height: 20px;
  overflow: hidden;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.card .card-header .card-header-right .card-option li {
  display: inline-block;
}
.card .card-header .card-header-right .card-option li:first-child i {
  -webkit-transition: 1s;
  transition: 1s;
  font-size: 16px;
  color: var(--theme-deafult);
}
.card .card-header .card-header-right .card-option li:first-child i.icofont {
  color: unset;
}
.card .card-header .card-header-right i {
  margin: 0 5px;
  cursor: pointer;
  color: #2c323f;
  line-height: 20px;
}
.card .card-header .card-header-right i.icofont-refresh {
  font-size: 13px;
}
.card .card-body {
  padding: 16px 24px;
  background-color: transparent;
}
.card .card-body p:last-child {
  margin-bottom: 0;
}
.card .sub-title {
  padding-bottom: 12px;
  font-size: calc(15px + 3 * (100vw - 320px) / 1600);
}
.card .card-footer {
  background-color: #fff;
  border-top: 1px solid rgba(106, 113, 133, 0.3);
  padding: 16px 24px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
}
.card.card-load .card-loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 8;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.card.card-load .card-loader i {
  margin: 0 auto;
  color: var(--theme-deafult);
  font-size: 20px;
}
.card.full-card {
  position: fixed;
  top: 0;
  z-index: 99999;
  -webkit-box-shadow: none;
          box-shadow: none;
  right: 0;
  border-radius: 0;
  border: 1px solid #efefef;
  width: calc(100vw - 12px);
  height: 100vh;
}
.card.full-card .card-body {
  overflow: auto;
}

.page-body-wrapper .card .sub-title {
  font-family: "Nunito", sans-serif, sans-serif;
  font-weight: normal;
  font-weight: 500;
  color: #9B9B9B;
}

.card-absolute {
  margin-top: 20px;
}
.card-absolute .card-header {
  position: absolute;
  top: -20px;
  left: 15px;
  border-radius: 0.25rem;
  padding: 10px 15px;
}
.card-absolute .card-header h5 {
  font-size: 17px;
}
.card-absolute .card-body {
  margin-top: 10px;
}

.card-header .border-tab {
  margin-bottom: -13px;
}

.activateBtn, .deactivateBtn{
  font-size: 13px;
  cursor: pointer;
}
.activateBtn{
  font-size: 14px;
  color: green !important;
}

.fade-out {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.deactivateBtn{
  color: red !important;
}

.custom-card {
  overflow: hidden;
  padding: 30px;
}
.custom-card .card-header {
  padding: 0;
}
.custom-card .card-header img {
  border-radius: 50%;
  margin-top: -100px;
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}
.custom-card .card-profile {
  text-align: center;
}
.custom-card .card-profile img {
  height: 110px;
  padding: 7px;
  background-color: #fff;
  z-index: 1;
  position: relative;
}
.custom-card .card-social {
  text-align: center;
}
.custom-card .card-social li {
  display: inline-block;
  padding: 15px 0;
}
.custom-card .card-social li:last-child a {
  margin-right: 0;
}
.custom-card .card-social li a {
  padding: 0;
  margin-right: 15px;
  color: rgb(188, 198, 222);
  font-size: 16px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.custom-card .card-social li a:hover {
  color: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.custom-card .profile-details h6 {
  margin-bottom: 30px;
  margin-top: 10px;
  color: #9B9B9B;
  font-size: 14px;
}
.custom-card .card-footer {
  padding: 0;
}
.custom-card .card-footer > div {
  padding: 15px;
  text-align: center;
}
.custom-card .card-footer > div + div {
  border-left: 1px solid #efefef;
}
.custom-card .card-footer > div h3 {
  margin-bottom: 0;
  font-size: 24px;
}
.custom-card .card-footer > div h6 {
  font-size: 14px;
  color: #9B9B9B;
}
.custom-card .card-footer > div h5 {
  font-size: 16px;
  margin-bottom: 0;
}
.custom-card .card-footer > div i {
  font-size: 24px;
  display: inline-block;
  margin-bottom: 15px;
}
.custom-card .card-footer > div .m-b-card {
  margin-bottom: 10px;
}

.social-profile {
  text-align: center;
  background-image: url(../../images/profile-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.social-profile .card-body {
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 1399px) {
  .social-profile .card-body {
    padding: 23px 15px;
  }
}
.social-details {
  margin-top: 15px;
}
.social-details h5 a {
  color: var(--body-font-color);
  font-weight: 600;
}
.social-details .social-follow {
  -webkit-column-count: 3;
     -moz-column-count: 3;
          column-count: 3;
  -webkit-column-gap: 30px;
     -moz-column-gap: 30px;
          column-gap: 30px;
  margin-top: 25px;
}
@media (max-width: 1399px) {
  .social-details .social-follow {
    -webkit-column-gap: 23px;
       -moz-column-gap: 23px;
            column-gap: 23px;
  }
}
.social-details .social-follow li {
  position: relative;
}
.social-details .social-follow li + li::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 14px;
  background-color: var(--recent-solid-border);
  left: -15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
[dir=rtl] .social-details .social-follow li + li::before {
  left: unset;
  right: -15px;
}
.social-details .card-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 10px;
  margin-top: 15px;
}
.social-img-wrap {
  background: linear-gradient(253deg, var(--theme-deafult) 11.51%, rgba(115, 102, 255, 0) 82.07%);
  display: inline-block;
  border-radius: 100%;
  padding: 2px;
  position: relative;
}
.social-img-wrap .edit-icon {
  position: absolute;
  right: 0;
  bottom: 0;
}
.social-img-wrap .edit-icon svg {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid var(--white);
  background: var(--white);
}
.social-img {
  padding: 5px;
  background: var(--white);
  border-radius: 100%;
}
.social-img img {
  height: 68px;
  border-radius: 100%;
}

/**=====================
    2.10 Card CSS End
==========================**/
/**=====================
     2.11 Color CSS Start
==========================**/
.color-box {
  margin-bottom: -10px;
}
.color-box button {
  margin-bottom: 10px;
}
.color-box button + button {
  margin-left: 5px;
}

.light-font {
  color: #9B9B9B;
}

/**=====================
     2.11 Color CSS Ends
==========================**/
.timeline-calendar {
  overflow: auto;
}
.timeline-calendar .custom-calendar {
  height: 345px;
}
.timeline-calendar .custom-calendar .time-line {
  overflow: auto;
}
.timeline-calendar .custom-calendar .time-line.fc-theme-standard th {
  border: none;
}
.timeline-calendar .custom-calendar .time-line .fc-col-header-cell-cushion {
  padding: 15px;
}
.timeline-calendar .custom-calendar .time-line .fc-scrollgrid-sync-table {
  display: none;
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-divider {
  display: none;
}
.timeline-calendar .custom-calendar .time-line .fc-scrollgrid table thead tr {
  background-color: rgba(122, 112, 186, 0.1);
}
.timeline-calendar .custom-calendar .time-line .fc-header-toolbar {
  position: absolute;
  right: 246px;
  top: 8px;
  margin-top: 10px;
}
.timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-button-group {
  display: none;
  gap: 10px;
}
.timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button {
  border-color: #f4f4f4;
  background-color: #fff;
}
.timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button span {
  color: #3D434A;
}
.timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-today-button {
  display: none;
}
.timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  display: none;
}
.timeline-calendar .custom-calendar .time-line .fc-header-toolbar .fc-toolbar-chunk:last-child {
  display: none;
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness {
  border: none;
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 4px;
  min-width: 179px;
  min-height: 54px;
  max-height: 54px;
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main {
  color: var(--body-font-color);
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-sticky {
  color: #9B9B9B;
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-sticky .fc-task-text-box .fc-task-text {
  color: #3D434A;
  font-family: "Nunito", sans-serif;
  font-size: 14px;
  font-weight: 500;
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-sticky .fc-task-text-box:last-child span {
  color: #9B9B9B;
}
.timeline-calendar .custom-calendar .time-line .fc-timegrid-col .fc-timegrid-col-frame .fc-timegrid-event-harness .fc-v-event .fc-event-main .fc-event-main-frame {
  padding: 8px 12px;
}
.timeline-calendar .custom-calendar .time-line .fc-scrollgrid-section .fc-timegrid-slots table tbody tr {
  border: none;
}
.timeline-calendar .custom-calendar .time-line .fc-scrollgrid-section .fc-timegrid-slots table tbody tr td.fc-scrollgrid-shrink {
  padding: 10px;
}
.timeline-calendar .custom-calendar .time-line .fc-scrollgrid-section .fc-timegrid-slots table tbody tr td.fc-timegrid-slot-minor {
  padding: 10px;
}

/**=====================
    2.12 Datatable CSS Start
==========================**/
div.dt-button-background {
  display: none !important;
}
div.DTS div.dataTables_scrollBody table {
  z-index: 1;
}
div .table-avtar {
  height: 32px;
  margin-right: 10px;
}
[dir=rtl] div .table-avtar {
  margin-right: unset;
  margin-left: 10px;
}
div .action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
div .action i {
  font-size: 16px;
}
div .action .pdf i {
  font-size: 20px;
  color: #E44141;
}
div .action .edit {
  margin-right: 5px;
}
div .action .edit i {
  color: #0DA759;
}
[dir=rtl] div .action .edit {
  margin-left: 5px;
}
div .action .delete i {
  color: #E44141;
}

#auto-generate-content_wrapper.dataTables_wrapper button {
  background-color: var(--white);
  border: 1px solid var(--theme-deafult);
  color: var(--theme-deafult);
}

.dataTables_wrapper {
  width: calc(100% - 1px);
  padding: 0;
}
.dataTables_wrapper.no-footer .dataTables_scrollBody {
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.dataTables_wrapper .dt-buttons .btn {
  border-radius: 0.25rem !important;
}
.dataTables_wrapper .btn-group button {
  margin-right: 5px;
}
.dataTables_wrapper button {
  font-weight: 400;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  border-radius: 0.25rem;
  color: #fff;
}
.dataTables_wrapper + .dataTables_wrapper {
  margin-top: 20px;
}
.dataTables_wrapper .dataTables_paginate {
  margin-left: 15px !important;
  border: 1px solid #f4f4f4;
  border-radius: 0.25rem;
  padding-top: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button {
  margin: 0;
}
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button:active {
  background: var(--theme-deafult);
  color: #fff !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: 1px solid var(--theme-deafult);
  color: #2c323f !important;
  background: transparent !important;
}
.dataTables_wrapper .dataTables_length {
  margin-bottom: 30px;
}
.dataTables_wrapper .dataTables_length select {
  border-color: #f4f4f4;
  color: #2c323f;
  padding: 0 10px;
  margin: 0 10px;
  height: 2.7142em;
  background-color: #fff;
}
.dataTables_wrapper table.dataTable {
  border: 1px solid #efefef;
}
.dataTables_wrapper table.dataTable tbody td.select-checkbox,
.dataTables_wrapper table.dataTable tbody th.select-checkbox {
  padding-right: 40px !important;
}
.dataTables_wrapper table.dataTable tbody td.select-checkbox:before,
.dataTables_wrapper table.dataTable tbody th.select-checkbox:before {
  right: 20px;
  top: 22px;
  left: unset;
}
.dataTables_wrapper table.dataTable tbody td.select-checkbox:after,
.dataTables_wrapper table.dataTable tbody th.select-checkbox:after {
  margin-top: -5px;
  margin-left: 21px;
}
.dataTables_wrapper table.dataTable thead th,
.dataTables_wrapper table.dataTable thead td {
  border-bottom: 2px solid #efefef;
}
.dataTables_wrapper table.dataTable th,
.dataTables_wrapper table.dataTable td {
  padding: 0.75rem;
}
.dataTables_wrapper .dataTables_filter {
  margin-bottom: 25px;
  margin-left: 15px;
}
.dataTables_wrapper .dataTables_filter input[type=search] {
  border: 1px solid #efefef;
  padding: 0 10px;
  margin-left: 10px;
  height: 37px;
  border-radius: 0;
}

.dataTables_scrollHeadInner {
  width: 100% !important;
}

table .fixedHeader-floating {
  position: fixed !important;
  background-color: #fff;
}
table .box > div {
  padding: 5px 15px;
  background-color: var(--theme-deafult);
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}
table.dataTable.fixedHeader-locked, table.dataTable.fixedHeader-floating {
  width: calc(100vw - 250px) !important;
  max-width: calc(100vw - 250px) !important;
  overflow: hidden !important;
  right: 0 !important;
  z-index: 99;
  left: 260px !important;
}
table.dataTable:not(.fixedHeader-locked):not(.fixedHeader-floating) {
  width: 100% !important;
}
table.dataTable {
  border-collapse: collapse !important;
  margin-top: 0 !important;
}
table.dataTable thead .sorting:before, table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before,
table.dataTable thead .sorting_desc_disabled:after {
  bottom: 12px !important;
}
table.dataTable input,
table.dataTable select {
  border: 1px solid #8D8D8D;
  height: 37px;
}

.user-datatable tr td:first-child,
.user-datatable tr th:first-child {
  min-width: 150px;
}

.jsgrid .jsgrid-button {
  width: 20px;
  height: 20px;
}
.jsgrid .jsgrid-delete-button {
  background-position: 0 -78px;
  width: 22px;
  height: 22px;
}
.jsgrid .jsgrid-edit-button {
  background-position: -2px -122px;
  width: 22px;
  height: 22px;
}
.jsgrid .jsgrid-insert-mode-button {
  background-position: -2px -160px;
  width: 25px;
  height: 25px;
}
.jsgrid .jsgrid-insert-button {
  background-position: -3px -202px;
  width: 16px;
  height: 16px;
}

.toolbar {
  padding: 5px 15px;
  background-color: var(--theme-deafult);
  display: inline-block;
  color: #fff;
  border-radius: 5px;
  margin-bottom: 15px;
  margin-right: 15px;
}

code.option {
  color: #E44141 !important;
}
code.api {
  color: #0DA759 !important;
}

.dt-ext .dataTables_wrapper .page-item.active .page-link {
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult) !important;
  color: #fff;
}
.dt-ext .dataTables_wrapper .dataTables_paginate {
  border: none;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button {
  padding: 0;
  border: none;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.disabled, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.hover, .dt-ext .dataTables_wrapper .dataTables_paginate .paginate_button.active {
  border: none !important;
}
.dt-ext .dataTables_wrapper .dataTables_paginate .page-link {
  margin-left: 0px;
  color: var(--theme-deafult);
}
.dt-ext .dataTables_wrapper button.dt-button,
.dt-ext .dataTables_wrapper div.dt-button,
.dt-ext .dataTables_wrapper a.dt-button,
.dt-ext .dataTables_wrapper button.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:focus:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper button.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper div.dt-button.active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button:active:not(.disabled),
.dt-ext .dataTables_wrapper a.dt-button.active:not(.disabled) {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
  border-radius: 2px;
  color: #fff;
  background-image: none;
  font-size: 14px;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-success,
.dt-ext .dataTables_wrapper div.dt-button.btn-success,
.dt-ext .dataTables_wrapper a.dt-button.btn-success {
  background-color: #0DA759;
  border-color: #0DA759;
}
.dt-ext .dataTables_wrapper button.dt-button.btn-primary_,
.dt-ext .dataTables_wrapper div.dt-button.btn-primary_,
.dt-ext .dataTables_wrapper a.dt-button.btn-primary_ {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
}
.dt-ext .dataTables_wrapper button.dt-button.btn-danger,
.dt-ext .dataTables_wrapper div.dt-button.btn-danger,
.dt-ext .dataTables_wrapper a.dt-button.btn-danger {
  background-color: #E44141;
  border-color: #E44141;
}
.dt-ext .dataTables_wrapper table.dataTable th.focus,
.dt-ext .dataTables_wrapper table.dataTable td.focus {
  outline: 3px solid var(--theme-deafult);
}
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected > .sorting_1,
.dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.odd.selected > .sorting_1 {
  background-color: #f4f3f9;
}
.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.stripe tbody > tr.odd > .selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd.selected,
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.odd > .selected {
  background-color: #f4f3f9;
}
.dt-ext .dataTables_wrapper table.dataTable.display tbody > tr.even.selected > .sorting_1,
.dt-ext .dataTables_wrapper table.dataTable.order-column.stripe tbody > tr.even.selected > .sorting_1 {
  background-color: #f4f3f9;
}
.dt-ext .dataTables_wrapper table.dataTable tbody > tr.selected,
.dt-ext .dataTables_wrapper table.dataTable tbody > tr > .selected {
  background-color: #f4f3f9;
}

td.highlight {
  background-color: #f4f4f4 !important;
}

td.details-control {
  background: url("../../images/details_open.png") no-repeat center center;
  cursor: pointer;
}

tr.shown td.details-control {
  background: url("../../images/details_close.png") no-repeat center center;
}

.dataTables_scroll ~ .dataTables_paginate {
  margin-top: 20px !important;
}

.product-table th:last-child {
  min-width: 120px;
}
.product-table h6 {
  font-weight: 600;
  color: #2c323f;
}

table.dataTable.display tbody tr.odd > .sorting_1 {
  background-color: #fdfeff;
}
table.dataTable.display tbody tr.odd .badge {
  line-height: 15px;
}
table.dataTable.display tbody tr.even .badge {
  line-height: 15px;
}

.jsgrid-row .jsgrid-cell .badge,
.jsgrid-alt-row .jsgrid-cell .badge {
  line-height: 15px !important;
}

/**=====================
     2.12 Datatable CSS Ends
==========================**/
/**=====================
    2.13 Datapicker CSS Start
==========================**/
.clockpicker-canvas line {
  stroke: var(--theme-deafult);
}

.clockpicker-canvas-fg,
.clockpicker-canvas-bearing {
  fill: var(--theme-deafult);
}

.datepicker {
  z-index: 99;
}

.datepicker--day-name {
  color: #337ab7;
  font-weight: bold;
}

.datepicker--cell.-current- {
  color: #000;
  border-radius: 5px;
  font-weight: bold;
  border: 2px solid var(--theme-deafult);
}
.datepicker--cell.-focus- {
  background: var(--theme-deafult);
  color: #fff;
}
.datepicker--cell.-selected- {
  background: var(--theme-deafult) !important;
}
.datepicker--cell-day {
  width: 14.2857142857%;
}

.datepickers-container,
.bootstrap-datetimepicker-widget {
  z-index: 7;
}

.datetime-picker .bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
}
.datetime-picker .input-group-text i {
  line-height: 1.3;
}

.clockpicker-popover {
  border: none;
  -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
          box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  z-index: 7;
}
.clockpicker-popover .popover-content {
  background-color: rgba(122, 112, 186, 0.05);
}

.clockpicker-canvas-bg {
  fill: #f4f3f9;
}

.clockpicker-tick:hover {
  color: inherit;
  background-color: #f4f3f9;
}

.calendar-basic .md-sidebar-aside {
  background-color: #fff;
}
.calendar-basic #external-events {
  padding: 20px;
  border: 1px solid #d7e2e9;
}
.calendar-basic #external-events h4 {
  margin-bottom: 14px;
}
.calendar-basic #external-events p {
  margin-top: 12px;
}
.calendar-basic #external-events .fc-h-event {
  padding: 12px;
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.calendar-basic #external-events .fc-h-event + .fc-h-event {
  margin-top: 10px;
}
.calendar-basic #external-events .fc-h-event .fc-event-main {
  font-size: 13px;
}
.calendar-basic .calendar-default .fc-daygrid-dot-event .fc-event-title {
  text-overflow: ellipsis;
}
.calendar-basic .calendar-default .fc td[role=presentation] {
  border: 0;
}
.calendar-basic .calendar-default .fc .fc-daygrid-day-number,
.calendar-basic .calendar-default .fc .fc-col-header-cell-cushion {
  color: #3D434A;
}
.calendar-basic .calendar-default .fc .fc-button .fc-icon {
  height: 23px;
}
.calendar-basic .calendar-default .fc-h-event {
  padding: 2px;
  background-color: #337ab7;
  border: 1px solid #337ab7;
}
.calendar-basic .calendar-default .fc-toolbar-chunk .fc-button-group ~ .fc-today-button {
  margin: 0 8px;
}

.fc-event-dragging {
  padding: 10px;
  background-color: var(--theme-deafult);
}

.fc .fc-toolbar {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 10px;
}
.fc .fc-button-primary:focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 112, 186, 0.5);
          box-shadow: 0 0 0 0.2rem rgba(122, 112, 186, 0.5);
}

.main-inline-calender.input-group {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.main-inline-calender.input-group .form-control {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-calendar.inline {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-innerContainer {
  display: block;
}
.main-inline-calender.input-group .flatpickr-rContainer {
  display: block;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-weekdays {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days {
  width: 100%;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days .dayContainer {
  width: 100%;
  max-width: none;
  min-width: unset;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day {
  border-radius: 5px;
  width: 100%;
  max-width: unset;
}
.main-inline-calender.input-group .flatpickr-rContainer .flatpickr-days .dayContainer .flatpickr-day:hover {
  color: var(--body-font-color);
}

.flatpickr-day.selected,
.flatpickr-day .endRange {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
}
.flatpickr-day.selected:hover,
.flatpickr-day .endRange:hover {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
}

.main-flatpickr .form-control:-moz-read-only {
  background-color: unset;
}

.main-flatpickr .form-control:read-only {
  background-color: unset;
}

.flatpickr-months {
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
}

.flatpickr-current-month {
  font-size: calc(14px + 5 * (100vw - 320px) / 1600);
}

@media (max-width: 400px) {
  .flatpickr-calendar.animate {
    width: 77%;
    left: 50% !important;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}
.flatpickr-calendar .flatpickr-days {
  width: unset;
}
.flatpickr-calendar .flatpickr-days .dayContainer {
  width: unset;
  min-width: unset;
  max-width: unset;
}

@media (max-width: 768px) {
  .flatpicker-calender .form-control {
    font-size: 14px;
  }
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.timepicker-wrapper .row {
  margin-bottom: 16px;
}
.timepicker-wrapper .row:last-child {
  margin-bottom: 0;
}

/**=====================
   2.13 Datapicker CSS End
==========================**/
.default-dashboard {
  /* -------------------------------------------------
      Datepicker cells
     ------------------------------------------------- */
  /* -------------------------------------------------
      Datepicker
     ------------------------------------------------- */
  /* -------------------------------------------------
      Navigation
     ------------------------------------------------- */
  /* -------------------------------------------------
      Timepicker
     ------------------------------------------------- */
}
.default-dashboard .datepicker--cell-day.-other-month-,
.default-dashboard .datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .datepicker--cell-day.-other-month-:hover,
.default-dashboard .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}
.default-dashboard .-disabled-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .-selected-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}
.default-dashboard .datepicker--cell.-current- {
  border: 2px solid #D77748;
}
.default-dashboard .-selected-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}
.default-dashboard .-in-range-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.default-dashboard .-in-range-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}
.default-dashboard .datepicker--cell-day.-other-month-:empty,
.default-dashboard .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}
.default-dashboard .datepicker--cells {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.default-dashboard .datepicker--cell {
  border-radius: 8px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  position: relative;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-weight: 700;
  color: #3D434A;
  height: 46px;
  z-index: 1;
}
.default-dashboard .datepicker--cell.-focus- {
  background: #337ab7;
  color: #fff;
}
.default-dashboard .datepicker--cell.-current- {
  color: #D77748;
}
.default-dashboard .datepicker--cell.-current-.-focus- {
  color: #4a4a4a;
}
.default-dashboard .datepicker--cell.-current-.-in-range- {
  color: #4eb5e6;
}
.default-dashboard .datepicker--cell.-in-range- {
  background: rgba(122, 112, 186, 0.1);
  color: #4a4a4a;
  border-radius: 0;
}
.default-dashboard .datepicker--cell.-in-range-:first-child {
  border-radius: 25px 0 0 25px;
}
.default-dashboard .datepicker--cell.-in-range-:last-child {
  border-radius: 0 25px 25px 0;
}
.default-dashboard .datepicker--cell.-in-range-.-focus- {
  background-color: rgba(122, 112, 186, 0.2);
}
.default-dashboard .datepicker--cell.-disabled- {
  cursor: default;
  color: #48A3D7;
}
.default-dashboard .datepicker--cell.-disabled-.-focus- {
  color: #aeaeae;
}
.default-dashboard .datepicker--cell.-disabled-.-in-range- {
  color: #a1a1a1;
}
.default-dashboard .datepicker--cell.-disabled-.-current-.-focus- {
  color: #aeaeae;
}
.default-dashboard .datepicker--cell.-range-from- {
  border: 1px solid rgba(122, 112, 186, 0.5);
  background-color: rgba(122, 112, 186, 0.1);
  border-radius: 25px 0 0 25px;
}
.default-dashboard .datepicker--cell.-range-to- {
  border: 1px solid rgba(122, 112, 186, 0.5);
  background-color: rgba(122, 112, 186, 0.1);
  border-radius: 0 25px 25px 0;
  color: #337ab7;
}
.default-dashboard .datepicker--cell.-range-from-.-range-to- {
  border-radius: 4px;
}
.default-dashboard .datepicker--cell.-selected- {
  color: #fff;
  border: none;
  background: #5cc4ef;
}
.default-dashboard .datepicker--cell.-selected-.-current- {
  color: #fff;
  background: #5cc4ef;
}
.default-dashboard .datepicker--cell.-selected-.-focus- {
  background: #45bced;
}
.default-dashboard .datepicker--cell:empty {
  cursor: default;
}
.default-dashboard .datepicker--days-names {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 8px 0 3px;
}
.default-dashboard .datepicker--day-name {
  color: #8D8D8D;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8em;
}
.default-dashboard .datepicker--cell-day {
  width: 14.28571%;
}
.default-dashboard .datepicker--cells-months {
  height: 170px;
}
.default-dashboard .datepicker--cell-month {
  width: 33.33%;
  height: 25%;
}
.default-dashboard .datepicker--years {
  height: 170px;
}
.default-dashboard .datepicker--cells-years {
  height: 170px;
}
.default-dashboard .datepicker--cell-year {
  width: 25%;
  height: 33.33%;
}
.default-dashboard .datepicker--cell-day.-other-month-,
.default-dashboard .datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .datepicker--cell-day.-other-month-:hover,
.default-dashboard .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}
.default-dashboard .-disabled-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .-selected-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}
.default-dashboard .-selected-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}
.default-dashboard .-in-range-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.default-dashboard .-in-range-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}
.default-dashboard .datepicker--cell-day.-other-month-:empty,
.default-dashboard .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}
.default-dashboard .datepickers-container {
  position: absolute;
  left: 0;
  top: 0;
}
@media print {
  .default-dashboard .datepickers-container {
    display: none;
  }
}
.default-dashboard .datepicker {
  background: transparent;
  -webkit-box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  font-family: Tahoma, sans-serif;
  font-size: 14px;
  color: #4a4a4a;
  width: 100%;
  position: absolute;
  left: -100000px;
  opacity: 0;
  transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  -webkit-transition: opacity 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0.3s, -webkit-transform 0.3s ease;
  z-index: 100;
}
.default-dashboard .datepicker.-from-top- {
  -webkit-transform: translateY(-8px);
  transform: translateY(-8px);
}
.default-dashboard .datepicker.-from-right- {
  -webkit-transform: translateX(8px);
  transform: translateX(8px);
}
.default-dashboard .datepicker.-from-bottom- {
  -webkit-transform: translateY(8px);
  transform: translateY(8px);
}
.default-dashboard .datepicker.-from-left- {
  -webkit-transform: translateX(-8px);
  transform: translateX(-8px);
}
.default-dashboard .datepicker.active {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  -webkit-transition: opacity 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s;
  transition: opacity 0.3s ease, transform 0.3s ease, left 0s 0s, -webkit-transform 0.3s ease;
}
.default-dashboard .datepicker-inline .datepicker {
  border-color: #d7d7d7;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: static;
  left: auto;
  right: auto;
  opacity: 1;
  -webkit-transform: none;
  transform: none;
}
.default-dashboard .datepicker-inline .datepicker--pointer {
  display: none;
}
.default-dashboard .datepicker--content {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  padding: 10px 4px 4px;
  background: transparent;
}
.default-dashboard .-only-timepicker- .datepicker--content {
  display: none;
}
.default-dashboard .datepicker--pointer {
  position: absolute;
  background: #fff;
  border-top: 1px solid #dbdbdb;
  border-right: 1px solid #dbdbdb;
  width: 10px;
  height: 10px;
  z-index: -1;
}
.default-dashboard .-top-left- .datepicker--pointer,
.default-dashboard .-top-center- .datepicker--pointer,
.default-dashboard .-top-right- .datepicker--pointer {
  top: calc(100% - 4px);
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
}
.default-dashboard .-right-top- .datepicker--pointer,
.default-dashboard .-right-center- .datepicker--pointer,
.default-dashboard .-right-bottom- .datepicker--pointer {
  right: calc(100% - 4px);
  -webkit-transform: rotate(225deg);
  transform: rotate(225deg);
}
.default-dashboard .-bottom-left- .datepicker--pointer,
.default-dashboard .-bottom-center- .datepicker--pointer,
.default-dashboard .-bottom-right- .datepicker--pointer {
  bottom: calc(100% - 4px);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.default-dashboard .-left-top- .datepicker--pointer,
.default-dashboard .-left-center- .datepicker--pointer,
.default-dashboard .-left-bottom- .datepicker--pointer {
  left: calc(100% - 4px);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.default-dashboard .-top-left- .datepicker--pointer,
.default-dashboard .-bottom-left- .datepicker--pointer {
  left: 10px;
}
.default-dashboard .-top-right- .datepicker--pointer,
.default-dashboard .-bottom-right- .datepicker--pointer {
  right: 10px;
}
.default-dashboard .-top-center- .datepicker--pointer,
.default-dashboard .-bottom-center- .datepicker--pointer {
  left: calc(50% - 5px);
}
.default-dashboard .-left-top- .datepicker--pointer,
.default-dashboard .-right-top- .datepicker--pointer {
  top: 10px;
}
.default-dashboard .-left-bottom- .datepicker--pointer,
.default-dashboard .-right-bottom- .datepicker--pointer {
  bottom: 10px;
}
.default-dashboard .-left-center- .datepicker--pointer,
.default-dashboard .-right-center- .datepicker--pointer {
  top: calc(50% - 5px);
}
.default-dashboard .datepicker--body {
  display: none;
}
.default-dashboard .datepicker--body.active {
  display: block;
}
.default-dashboard .datepicker--cell-day.-other-month-,
.default-dashboard .datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .datepicker--cell-day.-other-month-:hover,
.default-dashboard .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}
.default-dashboard .-disabled-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .-selected-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}
.default-dashboard .-selected-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}
.default-dashboard .-in-range-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.default-dashboard .-in-range-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}
.default-dashboard .datepicker--cell-day.-other-month-:empty,
.default-dashboard .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}
.default-dashboard .datepicker--nav {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-box-pack: justify;
          justify-content: space-between;
  min-height: 32px;
  padding: 4px;
}
.default-dashboard .-only-timepicker- .datepicker--nav {
  display: none;
}
.default-dashboard .datepicker--nav-title,
.default-dashboard .datepicker--nav-action {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  cursor: pointer;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  font-weight: 800;
  color: #3D434A;
}
.default-dashboard .datepicker--nav-action {
  width: 32px;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.default-dashboard .datepicker--nav-action:hover {
  background: #f0f0f0;
}
.default-dashboard .datepicker--nav-action.-disabled- {
  visibility: hidden;
}
.default-dashboard .datepicker--nav-action svg {
  width: 32px;
  height: 32px;
}
.default-dashboard .datepicker--nav-action path {
  fill: none;
  stroke: #9c9c9c;
  stroke-width: 2px;
}
.default-dashboard .datepicker--nav-title {
  border-radius: 4px;
  padding: 0 8px;
}
.default-dashboard .datepicker--nav-title i {
  font-style: normal;
  color: #9c9c9c;
  margin-left: 5px;
}
.default-dashboard .datepicker--nav-title:hover {
  background: #f0f0f0;
}
.default-dashboard .datepicker--nav-title.-disabled- {
  cursor: default;
  background: none;
}
.default-dashboard .datepicker--buttons {
  display: none;
}
.default-dashboard .datepicker--button {
  color: #4eb5e6;
  cursor: pointer;
  border-radius: 4px;
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
  display: -ms-inline-flexbox;
  display: -webkit-inline-box;
  display: inline-flex;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
          justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  height: 32px;
}
.default-dashboard .datepicker--button:hover {
  color: #4a4a4a;
  background: #f0f0f0;
}
.default-dashboard .datepicker--cell-day.-other-month-,
.default-dashboard .datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .datepicker--cell-day.-other-month-:hover,
.default-dashboard .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}
.default-dashboard .-disabled-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .-selected-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}
.default-dashboard .-selected-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}
.default-dashboard .-in-range-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.default-dashboard .-in-range-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.2);
}
.default-dashboard .datepicker--cell-day.-other-month-:empty,
.default-dashboard .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}
.default-dashboard .datepicker--time {
  border-top: 1px solid #efefef;
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  padding: 4px;
  position: relative;
}
.default-dashboard .datepicker--time.-am-pm- .datepicker--time-sliders {
  -ms-flex: 0 1 138px;
  -webkit-box-flex: 0;
          flex: 0 1 138px;
  max-width: 138px;
}
.default-dashboard .-only-timepicker- .datepicker--time {
  border-top: none;
}
.default-dashboard .datepicker--time-sliders {
  -ms-flex: 0 1 153px;
  -webkit-box-flex: 0;
          flex: 0 1 153px;
  margin-right: 10px;
  max-width: 153px;
}
.default-dashboard .datepicker--time-label {
  display: none;
  font-size: 12px;
}
.default-dashboard .datepicker--time-current {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
  font-size: 14px;
  text-align: center;
  margin: 0 0 0 10px;
}
.default-dashboard .datepicker--time-current-colon {
  margin: 0 2px 3px;
  line-height: 1;
}
.default-dashboard .datepicker--time-current-hours,
.default-dashboard .datepicker--time-current-minutes {
  line-height: 1;
  font-size: 19px;
  font-family: "Century Gothic", CenturyGothic, AppleGothic, sans-serif;
  position: relative;
  z-index: 1;
}
.default-dashboard .datepicker--time-current-hours:after,
.default-dashboard .datepicker--time-current-minutes:after {
  content: "";
  background: #f0f0f0;
  border-radius: 4px;
  position: absolute;
  left: -2px;
  top: -3px;
  right: -2px;
  bottom: -2px;
  z-index: -1;
  opacity: 0;
}
.default-dashboard .datepicker--time-current-hours.-focus-:after,
.default-dashboard .datepicker--time-current-minutes.-focus-:after {
  opacity: 1;
}
.default-dashboard .datepicker--time-current-ampm {
  text-transform: uppercase;
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #9c9c9c;
  margin-left: 6px;
  font-size: 11px;
  margin-bottom: 1px;
}
.default-dashboard .datepicker--time-row {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
          align-items: center;
  font-size: 11px;
  height: 17px;
  background: -webkit-gradient(linear, left top, right top, from(#dedede), to(#dedede)) left 50%/100% 1px no-repeat;
  background: linear-gradient(to right, #dedede, #dedede) left 50%/100% 1px no-repeat;
}
.default-dashboard .datepicker--time-row:first-child {
  margin-bottom: 4px;
}
.default-dashboard .datepicker--time-row input[type=range] {
  background: none;
  cursor: pointer;
  -ms-flex: 1;
  -webkit-box-flex: 1;
          flex: 1;
  height: 100%;
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
}
.default-dashboard .datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}
.default-dashboard .datepicker--time-row input[type=range]::-ms-tooltip {
  display: none;
}
.default-dashboard .datepicker--time-row input[type=range]:hover::-webkit-slider-thumb {
  border-color: #b8b8b8;
}
.default-dashboard .datepicker--time-row input[type=range]:hover::-moz-range-thumb {
  border-color: #b8b8b8;
}
.default-dashboard .datepicker--time-row input[type=range]:hover::-ms-thumb {
  border-color: #b8b8b8;
}
.default-dashboard .datepicker--time-row input[type=range]:focus {
  outline: none;
}
.default-dashboard .datepicker--time-row input[type=range]:focus::-webkit-slider-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}
.default-dashboard .datepicker--time-row input[type=range]:focus::-moz-range-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}
.default-dashboard .datepicker--time-row input[type=range]:focus::-ms-thumb {
  background: #5cc4ef;
  border-color: #5cc4ef;
}
.default-dashboard .datepicker--time-row input[type=range]::-webkit-slider-thumb {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  -webkit-transition: background 0.2s;
  transition: background 0.2s;
}
.default-dashboard .datepicker--time-row input[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  -moz-transition: background 0.2s;
  transition: background 0.2s;
}
.default-dashboard .datepicker--time-row input[type=range]::-ms-thumb {
  box-sizing: border-box;
  height: 12px;
  width: 12px;
  border-radius: 3px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;
  -ms-transition: background 0.2s;
  transition: background 0.2s;
}
.default-dashboard .datepicker--time-row input[type=range]::-webkit-slider-thumb {
  margin-top: -6px;
}
.default-dashboard .datepicker--time-row input[type=range]::-webkit-slider-runnable-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.default-dashboard .datepicker--time-row input[type=range]::-moz-range-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.default-dashboard .datepicker--time-row input[type=range]::-ms-track {
  border: none;
  height: 1px;
  cursor: pointer;
  color: transparent;
  background: transparent;
}
.default-dashboard .datepicker--time-row input[type=range]::-ms-fill-lower {
  background: transparent;
}
.default-dashboard .datepicker--time-row input[type=range]::-ms-fill-upper {
  background: transparent;
}
.default-dashboard .datepicker--time-row span {
  padding: 0 12px;
}
.default-dashboard .datepicker--time-icon {
  color: #9c9c9c;
  border: 1px solid;
  border-radius: 50%;
  font-size: 16px;
  position: relative;
  margin: 0 5px -1px 0;
  width: 1em;
  height: 1em;
}
.default-dashboard .datepicker--time-icon:after,
.default-dashboard .datepicker--time-icon:before {
  content: "";
  background: currentColor;
  position: absolute;
}
.default-dashboard .datepicker--time-icon:after {
  height: 0.4em;
  width: 1px;
  left: calc(50% - 1px);
  top: calc(50% + 1px);
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}
.default-dashboard .datepicker--time-icon:before {
  width: 0.4em;
  height: 1px;
  top: calc(50% + 1px);
  left: calc(50% - 1px);
}
.default-dashboard .datepicker--cell-day.-other-month-,
.default-dashboard .datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .datepicker--cell-day.-other-month-:hover,
.default-dashboard .datepicker--cell-year.-other-decade-:hover {
  color: #c5c5c5;
}
.default-dashboard .-disabled-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-disabled-.-focus-.datepicker--cell-year.-other-decade- {
  color: #dedede;
}
.default-dashboard .-selected-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.datepicker--cell-year.-other-decade- {
  color: #fff;
  background: #a2ddf6;
}
.default-dashboard .-selected-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-selected-.-focus-.datepicker--cell-year.-other-decade- {
  background: #8ad5f4;
}
.default-dashboard .-in-range-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.datepicker--cell-year.-other-decade- {
  background-color: rgba(92, 196, 239, 0.1);
  color: #cccccc;
}
.default-dashboard .-in-range-.-focus-.datepicker--cell-day.-other-month-,
.default-dashboard .-in-range-.-focus-.datepicker--cell-year.-other-decade- {
  background-color: rgba(122, 112, 186, 0.2);
}
.default-dashboard .datepicker--cell-day.-other-month-:empty,
.default-dashboard .datepicker--cell-year.-other-decade-:empty {
  background: none;
  border: none;
}

/**=====================
	2.14 Dropdown CSS Start
==========================**/
.img-cropper .dropup .dropdown-toggle::after {
  content: "";
  display: none;
}

.dropdown-auto {
  overflow: auto;
}

.dropdown-divider {
  margin: 0;
}

.dropdown .dropdown-toggle {
  background-color: rgba(115, 102, 255, 0.08);
  color: #337ab7;
  border-radius: 10px;
  padding: 5px 10px;
  width: 100px;
  text-align: left;
}
.dropdown .dropdown-toggle:after {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f107";
  border: none;
  font-family: "FontAwesome";
  font-size: 18px;
  margin: 0;
}
.dropdown .dropdown-toggle.btn-primary_ {
  color: #fff;
}
.dropdown .dropdown-menu {
  font-family: "Nunito", sans-serif, sans-serif;
}

.docs-toggles .dropdown .dropdown-toggle {
  width: auto;
}

.nav-tabs .dropdown .dropdown-toggle {
  width: 120px;
}
@media only screen and (max-width: 575.98px) {
  .nav-tabs .dropdown .dropdown-toggle {
    width: 100%;
    text-align: center;
  }
}

.dropdown-item {
  width: auto !important;
}

.icon-dropdown .dropdown-toggle {
  background: transparent;
  color: #9B9B9B;
  padding: 0;
  width: auto;
  border: none;
}
.icon-dropdown .dropdown-toggle i {
  font-size: 16px;
}
.icon-dropdown .dropdown-toggle::after {
  display: none;
}
.icon-dropdown .dropdown-item {
  line-height: 1.5;
}

.dropdown-basic {
  margin-bottom: -10px;
}
.dropdown-basic .btn-group .btn-round {
  border-radius: 50px;
}
.dropdown-basic .separated-btn {
  margin-left: -6px;
}
[dir=rtl] .dropdown-basic .separated-btn {
  margin-left: unset;
  margin-right: -6px;
}
.dropdown-basic .separated-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
}
.dropdown-basic .separated-btn.dropdown {
  margin-bottom: 0;
}
.dropdown-basic button {
  max-height: 43px;
}
.dropdown-basic .dropdown {
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
}
.dropdown-basic .dropdown .dropbtn {
  color: white;
  padding: 12px 35px;
  border: none;
  cursor: pointer;
}
.dropdown-basic .dropdown .dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 175px;
  -webkit-box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  left: 0;
  top: 45px;
}
.dropdown-basic .dropdown .dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropdown-basic .dropdown .dropdown-content .dropdown-header {
  padding: 12px 16px;
}
.dropdown-basic .dropdown-content a:hover {
  background-color: #f1f1f1;
}
.dropdown-basic .dropdown:hover .dropdown-content {
  display: block;
}

.dropup-basic .dropup {
  position: relative;
  display: inline-block;
}
.dropup-basic .dropup .dropbtn {
  color: white;
  padding: 12px;
  border: none;
}
.dropup-basic .dropup .dropup-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 170px;
  bottom: 45px;
  z-index: 999;
  left: 0;
}
.dropup-basic .dropup .dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.dropup-basic .dropup .dropup-content a:hover {
  background-color: rgba(106, 113, 133, 0.3);
}
.dropup-basic .dropup:hover .dropup-content {
  display: block;
}
.dropup-basic .dropup:hover .dropup-content a.active {
  background-color: rgba(106, 113, 133, 0.3);
}

.dropdown-block li .dropdown-item:hover {
  background-color: rgba(106, 113, 133, 0.3);
}
.dropdown-block li .helper-truncate:hover {
  background-color: unset !important;
}

.dropdown-basic .dropdown .dropdown-content a:hover {
  background-color: rgba(106, 113, 133, 0.3) !important;
}

.dropdown-menu .input-group {
  background-color: unset;
  padding: 6px 12px;
  border-top: 1px solid #efefef;
  background-color: #fff;
  opacity: 0.6;
}
.dropdown-menu .input-group:hover, .dropdown-menu .input-group:active {
  background-color: rgba(106, 113, 133, 0.3);
}
.dropdown-menu .input-group .input-group-text {
  background-color: unset;
  border: none;
}

.dark-form .form-label {
  color: var(--text-gray);
}
.dark-form .form-control {
  border: 1px solid var(--text-gray) !important;
}
.dark-form .form-check .form-check-input {
  border: 1px solid var(--text-gray);
}
.dark-form .form-check .form-check-label {
  color: var(--text-gray);
}
.dark-form h6 {
  color: var(--text-gray);
}
.dark-form p.dropdown-item {
  padding: 7px 0 0 0;
  border-top: 1px solid var(--chart-solid-border);
}
.dark-form .helper-truncate {
  color: var(--text-gray);
  background-color: transparent;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 8px;
  vertical-align: 4px;
  content: "";
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-bottom: 0;
  border-left: 4px solid transparent;
}
[dir=rtl] .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 8px;
}

.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 8px;
  content: "";
  border-top: 4px solid transparent;
  border-right: 4px solid;
  border-bottom: 4px solid transparent;
}
[dir=rtl] .dropstart .dropdown-toggle::before {
  margin-left: 8px;
  margin-right: unset;
}

.dropup .dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 8px;
  vertical-align: 4px;
  border-top: 0;
  border-right: 4px solid transparent;
  border-bottom: 4px solid;
  border-left: 4px solid transparent;
}
[dir=rtl] .dropup .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 8px;
}

.dropend .dropdown-toggle::after {
  content: "";
  display: inline-block;
  content: "";
  border-top: 4px solid transparent;
  border-right: 0;
  border-bottom: 4px solid transparent;
  border-left: 4px solid;
  margin-left: 8px;
}
[dir=rtl] .dropend .dropdown-toggle::after {
  margin-left: unset;
  margin-right: 8px;
}

.form-wrapper {
  width: 260px;
}

@media (max-width: 400px) {
  .dropdown-menu .helper-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.dark-dropdown .dropdown-block li .dropdown-item:hover, .dark-dropdown .dropdown-block li .dropdown-item.active {
  background-color: #2c323f;
  color: #fff;
}

btn-group .dropdown-wrapper {
  z-index: 2;
}
btn-group .dropdown-wrapper .input-group .input-group-text {
  padding: 0px 8px 0px 0px;
}
[dir=rtl] btn-group .dropdown-wrapper .input-group .input-group-text {
  padding: 0px 0px 0px 8px;
}

.needsclick {
  color: #000;
}

[dir=rtl] .rtl-dropdown .dropdown-menu li .dropdown-item {
  text-align: right;
}
[dir=rtl] .rtl-dropdown .btn-group .dropdown-menu {
  text-align: right;
}

.heading-dropdown .dropdown-menu > li:first-child > a {
  border-bottom: 4px solid var(--recent-border);
  border-top: 0;
  background-color: #d7e2e9;
}

/**=====================
	2.14 Dropdown CSS Ends
==========================**/
/**=====================
  2.15 Form-builder CSS Start
==========================**/
.component {
  cursor: pointer;
}
.component label {
  padding-top: 8px;
}
.component .form-group input,
.component .form-group textarea,
.component .form-group select {
  border-radius: 4px;
}

input {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

.popover .controls {
  margin-left: 0;
}
.popover .control-label {
  text-align: left;
}
.popover form {
  width: 100%;
}
.popover .fade.left.in {
  left: -215px;
}

.drag form {
  padding: 10px 5px;
  border: 1px dotted rgba(106, 113, 133, 0.3);
  position: absolute;
  background: #fff;
  -webkit-box-shadow: 0 0 30px rgba(106, 113, 133, 0.3);
          box-shadow: 0 0 30px rgba(106, 113, 133, 0.3);
  overflow: hidden;
}
.drag form input {
  border-radius: 5px;
}

/**=====================
  2.15 Form-builder CSS Ends
==========================**/
/**=====================
    2.16 Form-input CSS Start
==========================**/
.icon-addon .addon-md .fa, .icon-addon .addon-md .glyphicon {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.icon-addon .glyphicon,
.icon-addon .fa {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}

.input-group-square .input-group-text,
.input-group-square .form-control {
  border-radius: 0;
}

.input-group-air {
  -webkit-box-shadow: 0 3px 20px 0 #efefef;
          box-shadow: 0 3px 20px 0 #efefef;
}

.icon-addon {
  position: relative;
  color: #4c5667;
  display: block;
}
.icon-addon:after {
  display: table;
  content: "";
  clear: both;
}
.icon-addon:before {
  display: table;
  content: "";
}
.icon-addon .addon-md .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}
.icon-addon .form-control {
  padding-left: 30px;
  float: left;
  font-weight: normal;
}
.input-group-wrapper .input-group {
  margin-bottom: 16px;
}
.input-group-wrapper .input-group .form-control {
  font-size: 16px;
}
.input-group-wrapper .input-group .form-control:focus {
  border-color: var(--recent-border);
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.input-group-wrapper .input-group:last-child {
  margin-bottom: 0;
}
.input-group-wrapper .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.main-custom-form label {
  margin-bottom: 0;
}
.main-custom-form .form-select:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-color: var(--recent-border);
}
.main-custom-form .dropdown-menu {
  z-index: 3;
}
.main-custom-form .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.main-custom-form .form-select,
.main-custom-form .form-control {
  font-size: calc(14px + 1 * (100vw - 320px) / 1600);
}
.main-custom-form .input-group-text {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
@media (max-width: 338px) {
  .main-custom-form .dropdown-toggle.btn {
    padding: 6px 11px;
  }
}

.main-segment-btn .dropdown-toggle::after {
  content: "";
  margin-left: 0;
  border-top: 6px solid;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  vertical-align: 2px;
}
.main-segment-btn .dropdown-menu {
  z-index: 3;
}

#exampleDataList::-webkit-calendar-picker-indicator:hover, #exampleDataList::-webkit-calendar-picker-indicator:focus {
  display: none;
}

.form-control-color {
  max-width: 70rem;
}

.select2-container--default .select2-selection--single {
  border: 1px solid rgba(106, 113, 133, 0.3);
}

.theme-form select.form-select option {
  padding: 8px 10px;
  border-radius: 100px;
}

.input-radius .form-control:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-color: var(--recent-border);
}

.input-radius .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.floating-wrapper .form-floating .form-control {
  height: 54px;
}

/**=====================
    2.16 Form-input CSS Ends
	==========================**/
/**=====================
    2.17 Form-wizard CSS Start
==========================**/
/*Form Wizard One Start*/
.form-wizard .tab {
  display: none;
}
.form-wizard .step {
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: var(--theme-deafult);
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}
.form-wizard .step.active {
  opacity: 1;
}
.form-wizard .step.finish {
  background-color: var(--theme-secondary);
}

/*Form Wizard One ends*/
/*Form Wizard Four Start*/
.theme-form .wizard-4 {
  /* background-image: url(../../images/coming-soon-bg.jpg); */
  background-size: cover;
  background-repeat: no-repeat;
}

.wizard-4 .action-bar {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
[dir=rtl] .wizard-4 .action-bar {
  right: unset;
  left: 50px;
}
.wizard-4 .action-bar .btn {
  float: right;
  margin: 0 5px;
  background-color: #e3e0ff !important;
  border-color: #e3e0ff !important;
  color: var(--theme-deafult);
}
.wizard-4 .action-bar .buttonDisabled {
  color: #59667a;
  background-color: rgba(89, 102, 122, 0.05) !important;
  border: 1px solid rgba(89, 102, 122, 0.05) !important;
  cursor: text;
}
.wizard-4 .step-container {
  background: #f8f9fe;
  position: relative;
  margin: 0;
  padding: 0;
  border: 0 solid #e8ebf2;
  overflow: hidden;
  clear: right;
  width: 70%;
}
.wizard-4 .step-container div.content {
  display: block;
  position: absolute;
  float: left;
  text-align: left;
  overflow: auto;
  z-index: 1;
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  clear: both;
}
[dir=rtl] .wizard-4 .step-container div.content {
  text-align: unset;
  text-align: right;
}
.wizard-4 .step-container div.content .wizard-title {
  width: 450px;
  margin: 0 auto;
}
.wizard-4 .loader {
  display: none;
}
.wizard-4 .msg-box {
  position: fixed;
  display: none;
  padding: 5px 10px;
  background-color: #9a92cb;
  top: 30px;
  border-radius: 5px;
  right: 60px;
  padding: 10px 20px;
}
.wizard-4 .msg-box .content {
  padding: 0;
  float: left;
  padding-top: 3px;
  color: #fff;
}
.wizard-4 .msg-box .close {
  color: #fff;
  margin-left: 10px;
  opacity: 1;
  font-size: 18px;
  line-height: 1.4;
}
[dir=rtl] .wizard-4 .msg-box .close {
  margin-right: 10px;
  margin-left: unset;
}
.wizard-4 ul.anchor {
  float: left;
  width: 30%;
  height: calc(100vh - 300px);
  padding: 40px 30px;
  overflow: auto;
}
.wizard-4 ul.anchor .disabled {
  opacity: 0.7;
  color: #59667a;
}
.wizard-4 ul.anchor .disabled h4 {
  background-color: rgba(89, 102, 122, 0.15);
}
.wizard-4 ul.anchor .logo {
  padding-bottom: 20px;
}
.wizard-4 ul.anchor li {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  padding-bottom: 19px;
  float: left;
  clear: both;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 0;
}
.wizard-4 ul.anchor li + li a {
  padding-left: 80px;
}
[dir=rtl] .wizard-4 ul.anchor li + li a {
  padding-left: unset;
  padding-right: 80px;
}
.wizard-4 ul.anchor li:last-child {
  position: absolute;
  bottom: 0;
  width: auto;
  padding-bottom: 0;
  left: 9%;
}
[dir=rtl] .wizard-4 ul.anchor li:last-child {
  left: unset;
  right: 9%;
}
.wizard-4 ul.anchor li:last-child img {
  height: 300px;
}
@media (max-width: 1199px) {
  .wizard-4 ul.anchor li:last-child img {
    height: 220px;
  }
}
.wizard-4 ul.anchor li h4 {
  display: inline-block;
  background-color: rgba(122, 112, 186, 0.2);
  padding: 16px 23px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 6px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[dir=rtl] .wizard-4 ul.anchor li h4 {
  left: unset;
  right: 0;
}
.wizard-4 ul.anchor li a {
  display: block;
  position: relative;
  float: left;
  margin: 0;
  padding: 10px 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-decoration: none;
  outline-style: none;
  z-index: 1;
  font-size: 18px;
}
.wizard-4 ul.anchor li a small {
  display: block;
  font-size: 14px;
}
.wizard-4 ul.anchor li a .stepNumber {
  position: relative;
  float: left;
  width: 30px;
  text-align: center;
  padding: 5px;
  padding-top: 0;
}
.wizard-4 ul.anchor li a.done h4 {
  color: #fff;
  background: var(--theme-deafult);
}
.wizard-4 ul.anchor li a.done h4:before {
  font-size: 12px;
  position: absolute;
  left: 5px;
  top: -3px;
  content: "\e64c";
  font-family: "themify";
  background-color: #fff;
  color: var(--theme-deafult);
  border-radius: 15px;
  padding: 2px;
  border: 1px solid;
}
.wizard-4 ul.anchor li a.error {
  color: #898989;
  background: #fe8a7d;
  border: 1px solid #E44141;
}
.wizard-4 ul.anchor li a.error:hover {
  color: #000;
}

.wizard {
  max-width: 670px;
  margin: 0 auto;
}
.wizard .wizard-content {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  background-color: #fff;
  border-radius: 5px;
}

.vertical-main-wizard .header-vertical-wizard {
  background-color: var(--light-background);
  height: 100%;
  text-align: unset;
}
.vertical-main-wizard .header-vertical-wizard .nav-link {
  border-bottom: 1px solid var(--recent-border);
  border-radius: 0;
  padding: 20px;
}
.vertical-main-wizard .header-vertical-wizard .nav-link:last-child {
  border-bottom: 0;
}
.vertical-main-wizard .nav-pills .nav-link.active {
  background-color: transparent;
}
.vertical-main-wizard .nav-pills.show > .nav-link {
  background-color: transparent;
}
.vertical-main-wizard .nav-link .vertical-wizard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 14px;
}
.vertical-main-wizard .nav-link .vertical-wizard .stroke-icon-wizard {
  width: 33px;
  height: 33px;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  position: relative;
  border: 1px solid #52526c;
}
.vertical-main-wizard .nav-link .vertical-wizard .stroke-icon-wizard i {
  margin-right: 0;
  color: #52526c;
}
.vertical-main-wizard .nav-link .vertical-wizard .vertical-wizard-content h6 {
  font-weight: 400;
  line-height: 1;
  color: var(--body-font-color);
}
.vertical-main-wizard .nav-link .vertical-wizard .vertical-wizard-content p {
  color: var(--text-gray);
}
.vertical-main-wizard .nav-link:active, .vertical-main-wizard .nav-link.active {
  background-color: unset;
}
.vertical-main-wizard .nav-link:active .vertical-wizard .stroke-icon-wizard, .vertical-main-wizard .nav-link.active .vertical-wizard .stroke-icon-wizard {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.vertical-main-wizard .nav-link:active .vertical-wizard .stroke-icon-wizard i, .vertical-main-wizard .nav-link.active .vertical-wizard .stroke-icon-wizard i {
  color: #fff;
  font-size: 18px;
}
.vertical-main-wizard .nav-link:active .vertical-wizard .stroke-icon-wizard::before, .vertical-main-wizard .nav-link.active .vertical-wizard .stroke-icon-wizard::before {
  content: "";
  width: 45px;
  height: 45px;
  border-radius: 50%;
  position: absolute;
  border: 1px solid #337ab7;
}
.vertical-main-wizard .nav-link:active .vertical-wizard .vertical-wizard-content h6, .vertical-main-wizard .nav-link.active .vertical-wizard .vertical-wizard-content h6 {
  color: var(--theme-deafult);
}
.vertical-main-wizard .form-check .form-check-label {
  margin-bottom: 0;
}
.vertical-main-wizard .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.basic-wizard .stepper-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin: 0 auto;
  overflow: auto;
  margin-bottom: 24px;
}
.basic-wizard .stepper-horizontal .step {
  position: relative;
  padding-inline: 24px;
  z-index: 2;
  width: 100%;
}
.basic-wizard .stepper-horizontal .step:first-child {
  padding-left: 0;
}
.basic-wizard .stepper-horizontal .step:last-child {
  padding-right: 0;
}
.basic-wizard .stepper-horizontal .step:last-child .step-bar-left, .basic-wizard .stepper-horizontal .step:last-child .step-bar-right {
  display: none;
}
.basic-wizard .stepper-horizontal .step .step-circle {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  border-radius: 50%;
  text-align: center;
  line-height: 28px;
  font-size: 16px;
  font-weight: 600;
  z-index: 2;
  border: 2px solid rgba(106, 113, 133, 0.3);
  background: var(--white);
}
.basic-wizard .stepper-horizontal .step.done .step-circle {
  background-color: var(--white);
  border: 2px solid #9B9B9B;
  color: #337ab7;
  z-index: 4;
  position: relative;
}
.basic-wizard .stepper-horizontal .step.done .step-circle:before {
  font-family: "FontAwesome";
  font-weight: 100;
  content: "\f00c";
}
.basic-wizard .stepper-horizontal .step.done .step-circle * {
  display: none;
}
.basic-wizard .stepper-horizontal .step.done .step-title {
  color: #9B9B9B;
}
.basic-wizard .stepper-horizontal .step.editing .step-circle {
  background: var(--white);
  border-color: #9B9B9B;
  color: var(--theme-deafult);
}
.basic-wizard .stepper-horizontal .step .step-title {
  margin-top: 8px;
  font-size: 16px;
  font-weight: 600;
  white-space: nowrap;
  color: var(--chart-text-color);
}
.basic-wizard .stepper-horizontal .step .step-title,
.basic-wizard .stepper-horizontal .step .step-optional {
  text-align: center;
}
.basic-wizard .stepper-horizontal .step .step-bar-left,
.basic-wizard .stepper-horizontal .step .step-bar-right {
  position: absolute;
  top: 14px;
  height: 4px;
  background-color: var(--view-border-marker);
  border: solid var(--view-border-marker);
  border-width: 2px 0;
}
.basic-wizard .stepper-horizontal .step .step-bar-left {
  width: 100%;
  left: 50%;
  z-index: -1;
}
[dir=rtl] .basic-wizard .stepper-horizontal .step .step-bar-left {
  left: unset;
  right: 50%;
  margin-left: unset;
  margin-right: 1rem;
}
.basic-wizard .stepper-horizontal .step .step-bar-right {
  width: 0;
  left: 50%;
  z-index: -1;
  -webkit-transition: width 500ms ease-in-out;
  transition: width 500ms ease-in-out;
}
[dir=rtl] .basic-wizard .stepper-horizontal .step .step-bar-right {
  right: 50%;
  left: unset;
}
.basic-wizard .stepper-horizontal .step.done .step-bar-right {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
  z-index: 3;
  width: 100%;
}
.basic-wizard form.none {
  display: none;
}
.basic-wizard form.block {
  display: block;
}
.basic-wizard form .form-check-label {
  margin-bottom: 0;
}

.important-validation .form-label span {
  color: #E44141;
}

.select-size.form-select {
  font-size: 14px;
}

.wizard-footer button.disabled {
  background-color: #EAEBF2;
}
.wizard-footer button.back {
  background-color: #D77748;
}

.successful-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  padding-bottom: 24px;
}
.successful-form img {
  width: 100px;
  height: 100px;
}

.inquiries-form h6 {
  margin-bottom: 14px;
}
.inquiries-form .choose-option {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.inquiries-form .choose-option .form-check:last-child {
  margin-bottom: 0;
}

.form-completed img {
  height: 100px;
  margin: 0 auto;
  display: block;
}
.form-completed h6 {
  text-align: center;
  margin-top: 24px;
}

.horizontal-wizard-wrapper .main-horizontal-header {
  padding: 0 0 20px 0;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 14px;
}
@media (max-width: 1182px) {
  .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  /* display: block; */
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;

  /* border-left: 1px solid #0077B5; */
  /* border-radius: 0; */
  /* background-color: #eee; */
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a .horizontal-wizard {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--body-font-color);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a .horizontal-wizard .stroke-icon-wizard i {
  font-size: 18px;
  color: var(--body-font-color);
}
[dir=rtl] .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a .horizontal-wizard .stroke-icon-wizard i {
  margin-right: unset;
  margin-left: 10px;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link:active .horizontal-wizard, .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link.active .horizontal-wizard {
  color: var(--white);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link:active .horizontal-wizard .stroke-icon-wizard i, .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link.active .horizontal-wizard .stroke-icon-wizard i {
  color: var(--white);
}
.horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link:active .horizontal-wizard .stroke-icon-wizard span, .horizontal-wizard-wrapper .main-horizontal-header .horizontal-options a.nav-link.active .horizontal-wizard .stroke-icon-wizard span {
  color: var(--white);
}
.horizontal-wizard-wrapper .main-horizontal-header .form-check {
  margin-bottom: 0;
}
.horizontal-wizard-wrapper .bank-search {
  margin-bottom: 22px;
}
@media (max-width: 767px) {
  .horizontal-wizard-wrapper .bank-search {
    margin-bottom: 0;
  }
}
.horizontal-wizard-wrapper .bank-selection {
  padding: 10px 0 0 0;
}
@media (max-width: 401px) {
  .horizontal-wizard-wrapper .bank-selection .radio-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li .form-check-label {
  margin-bottom: 0;
  padding: 6px;
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li .form-check-label img {
  height: calc(45px + 7 * (100vw - 320px) / 1600);
}
.horizontal-wizard-wrapper .bank-selection .radio-wrapper li .form-check-label span {
  font-size: 14px;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options {
  gap: 12px;
}
@media (max-width: 1400px) {
  .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link {
  color: var(--body-font-color);
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link .horizontal-wizard {
  gap: 10px;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link .horizontal-wizard .stroke-icon-wizard {
  width: 30px;
  height: 30px;
  background-color: #E0DDFF;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 6px;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link .horizontal-wizard .stroke-icon-wizard i {
  margin-right: 0;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active {
  background-color: unset;
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active .horizontal-wizard, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active .horizontal-wizard {
  color: var(--body-font-color);
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active .horizontal-wizard .stroke-icon-wizard, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active .horizontal-wizard .stroke-icon-wizard {
  background-color: var(--theme-deafult);
}
.horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link:active .horizontal-wizard .stroke-icon-wizard i, .horizontal-wizard-wrapper.vertical-variations .main-horizontal-header .horizontal-options .nav-link.active .horizontal-wizard .stroke-icon-wizard i {
  margin-right: 0;
  color: var(--white);
}
.horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li {
  padding: 20px;
  display: grid;
  width: 100%;
  height: 100%;
  place-content: baseline;
  text-align: unset;
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
  max-width: unset;
}
.horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li .form-check-label {
  -webkit-box-pack: unset;
      -ms-flex-pack: unset;
          justify-content: unset;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li .form-check-label span > span + span {
  font-size: 13px;
}
@media (max-width: 400px) {
  .horizontal-wizard-wrapper.vertical-variations .select-account .radio-wrapper li .form-check-label span > span + span {
    display: none;
  }
}

.horizontal-wizard-wrapper.vertical-options .main-horizontal-header {
  padding-bottom: 0;
}
.horizontal-wizard-wrapper.vertical-options .main-horizontal-header .horizontal-options {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 12px;
}

.authentication-options .radio-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: calc(8px + 8 * (100vw - 320px) / 1600);
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.authentication-options .radio-wrapper li {
  padding: 20px;
  display: grid;
  width: 100%;
  height: 100%;
  place-content: baseline;
  text-align: unset;
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
  max-width: unset;
}
.authentication-options .radio-wrapper li .form-check-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  gap: 14px;
}
@media (max-width: 400px) {
  .authentication-options .radio-wrapper li .form-check-label {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
}
.authentication-options .radio-wrapper li .form-check-label i {
  padding: 0;
}
[dir=rtl] .authentication-options .radio-wrapper li .form-check-label i {
  padding: 0;
}
.authentication-options .radio-wrapper li .form-check-label span > span {
  font-size: 16px;
  color: #1f2533;
  font-weight: 500;
}
.authentication-options .radio-wrapper li .form-check-label span > span + span {
  color: var(--text-gray);
  font-size: 14px;
}

.main-qr-code .modal-toggle-wrapper .modal-img {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 22px;
}
@media (max-width: 480px) {
  .main-qr-code .modal-toggle-wrapper .modal-img {
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }
}
@media (max-width: 480px) {
  .main-qr-code .modal-toggle-wrapper .modal-img .qr-img {
    text-align: center;
  }
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-img img {
  height: 100px;
  width: 100px;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content {
  margin-top: 16px;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content .alert {
  -webkit-box-align: unset;
      -ms-flex-align: unset;
          align-items: unset;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content .alert div {
  word-break: break-word;
}
.main-qr-code .modal-toggle-wrapper .modal-img .qr-content i {
  font-size: 20px;
  margin-right: 0;
}
.main-qr-code .modal-toggle-wrapper .btn {
  display: block;
  margin: auto;
  margin-top: 12px;
}

.authentication-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: auto;
  text-align: center;
}
.authentication-body img {
  height: calc(200px + 220 * (100vw - 320px) / 1600);
}

.shopping-wizard .shipping-form .shipping-border {
  border-right: 1px solid var(--recent-solid-border);
}
@media (max-width: 1200px) {
  .shopping-wizard .shipping-form .shipping-border {
    border-right: unset;
  }
}
.shopping-wizard .shipping-form .shipping-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: rgba(122, 112, 186, 0.2);
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  margin-bottom: 18px;
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .shipping-options {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.shopping-wizard .shipping-form .shipping-options .nav-link {
  width: 100%;
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .shopping-wizard .shipping-form .shipping-options .nav-link {
    padding: calc(8px + 0 * (100vw - 300px) / 1620) calc(6px + 10 * (100vw - 300px) / 1620);
  }
}
.shopping-wizard .shipping-form .shipping-options .nav-link:active i, .shopping-wizard .shipping-form .shipping-options .nav-link.active i {
  background-color: #E0DDFF;
  color: var(--theme-deafult);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.shopping-wizard .shipping-form .shipping-options .nav-link:active h6, .shopping-wizard .shipping-form .shipping-options .nav-link.active h6 {
  color: var(--white);
}
.shopping-wizard .shipping-form .shipping-options .cart-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 11px;
}
.shopping-wizard .shipping-form .shipping-options .cart-options i {
  font-size: 18px;
  color: var(--white);
  width: 40px;
  height: 40px;
  background-color: var(--theme-deafult);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  margin: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .shopping-wizard .shipping-form .shipping-options .cart-options i {
    font-size: calc(14px + 4 * (100vw - 1200px) / 720);
    width: calc(30px + 10 * (100vw - 1200px) / 720);
    height: calc(30px + 10 * (100vw - 1200px) / 720);
  }
}
.shopping-wizard .shipping-form .shipping-options .cart-options h6 {
  color: var(--theme-deafult);
}
.shopping-wizard .shipping-form .shipping-info .table thead th {
  white-space: nowrap;
}
.shopping-wizard .shipping-form .shipping-info .table thead th:last-child {
  text-align: end;
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr td img {
  height: 50px;
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr td:last-child {
  text-align: end;
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr td span {
  color: var(--text-gray);
}
.shopping-wizard .shipping-form .shipping-info .table tbody tr:last-child td {
  border: 0;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot {
  font-weight: 400;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr td {
  white-space: nowrap;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr td:first-child {
  max-width: 370px;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr td:last-child {
  min-width: 0;
  text-align: end;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr:first-child {
  font-weight: 500;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr:last-child {
  font-weight: 600;
}
.shopping-wizard .shipping-form .shipping-info .table tfoot tr:last-child td {
  border-bottom: 0;
}
.shopping-wizard .shipping-form .shipping-content {
  height: 100%;
  padding: 0;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-title > .btn {
  background: none !important;
  border: 0;
  padding: 0 28px;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-title > .btn i {
  color: var(--theme-deafult);
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .collect-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 8px;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .collect-address .card-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .collect-address .card-icon i {
  font-size: 16px;
  color: var(--theme-deafult);
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 8px;
  padding-left: 20px;
}
[dir=rtl] .shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-address {
  padding-right: 20px;
  padding-left: unset;
}
.shopping-wizard .shipping-form .shipping-content .shipping-wizard .shipping-address h6 {
  font-size: 15px;
  font-weight: 500;
}
.shopping-wizard .shipping-form .shipping-content > .active.finish-wizard1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: calc(100% - 55px - 18px);
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .shipping-content > .active.finish-wizard1 {
    height: unset;
  }
}
.shopping-wizard .shipping-form .shipping-method p {
  color: var(--text-gray);
}
[dir=rtl] .shopping-wizard .shipping-form .shipping-method p {
  padding-right: 25px;
  padding-left: unset;
}
.shopping-wizard .shipping-form .proceed-next {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.shopping-wizard .shipping-form .payment-info-wrapper .card-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .payment-info-wrapper .card-wrapper {
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.shopping-wizard .shipping-form .payment-info-wrapper .card-wrapper img {
  height: 50px;
}
.shopping-wizard .shipping-form .payment-info-wrapper .pay-info {
  display: block;
}
.shopping-wizard .shipping-form .payment-info-wrapper .pay-info > div {
  margin-bottom: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 768px) {
  .shopping-wizard .shipping-form .payment-info-wrapper .pay-info > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.shopping-wizard .shipping-form .order-confirm {
  text-align: center;
}
@media (max-width: 1200px) {
  .shopping-wizard .shipping-form .order-confirm {
    margin-bottom: 18px;
  }
}
.shopping-wizard .shipping-form .order-confirm img {
  height: calc(95px + 45 * (100vw - 320px) / 1600);
}

.avatar-upload {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.avatar-upload div:first-child {
  position: relative;
  max-width: 205px;
  margin: auto;
}
.avatar-upload div:first-child .avatar-edit {
  position: absolute;
  right: 25px;
  z-index: 1;
  top: 4px;
}
.avatar-upload div:first-child .avatar-edit input {
  display: none;
}
.avatar-upload div:first-child .avatar-edit label {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--light-bg);
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.avatar-upload div:first-child .avatar-edit label::after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--body-font-color);
  position: absolute;
  top: 8px;
  left: 3px;
  right: 0;
  text-align: center;
  margin: auto;
}
[dir=rtl] .avatar-upload div:first-child .avatar-edit label::after {
  right: 3px;
  left: 0;
}
.avatar-upload div:first-child .avatar-preview {
  width: 150px;
  height: 150px;
  position: relative;
  border-radius: 100%;
  border: 6px solid #F8F8F8;
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  margin: auto;
}
.avatar-upload div:first-child .avatar-preview > div {
  background-image: url("../../images/user.png");
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.avatar-upload h6 {
  text-align: center;
  padding-top: 14px;
}

.email-verify .card-wrapper {
  display: grid;
  place-content: center;
}
.email-verify .card-wrapper > div {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.email-verify .card-wrapper .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.authenticate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.authenticate span:nth-of-type(1) {
  margin-top: 12px;
}
.authenticate h4 {
  margin-bottom: 12px;
}
.authenticate img {
  height: calc(145px + 55 * (100vw - 320px) / 1600);
}
.authenticate form {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  text-align: center;
}
.authenticate form .otp-generate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
  margin: 14px 0;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.authenticate form .form-control {
  width: calc(25px + 10 * (100vw - 320px) / 1600);
  height: calc(25px + 10 * (100vw - 320px) / 1600);
  border-radius: 5px;
  text-align: center;
  padding: 0;
  display: block;
  min-width: 0;
}
.authenticate form div {
  display: inline-grid;
  gap: 2px;
  margin-top: 12px;
}

.accordion .accordion-item .accordion-body .choose-bank .form-check .form-check-label {
  color: var(--body-font-color);
}

/*Form Wizard Four Ends*/
/**=====================
   2.17 Form-wizard CSS Ends
==========================**/
/**=====================
  2.18 Forms CSS Start
==========================**/
.form-label {
  margin-bottom: 4px;
  font-size: 15px !important;
  font-weight: 500 !important;
  display: block;
  margin-bottom: 2px;
}

.card-body button {
  padding: 14px 3.5em;
  border-radius: 10px;
  font-size: 16px;
}
.card-body button:hover {
 background-color: #564c92 !important;
}

.col-form-label {
  padding-bottom: 4px;
  font-weight: 600;
  font-size: 15px !important;
}

.form-space {
  margin-bottom: -10px;
}
.form-space > div {
  margin-bottom: 10px;
}

.form-control-plaintext {
  width: auto !important;
}

.form-control {
  border: 1px solid rgba(106, 113, 133, 0.1);
  /* border-color: rgba(106, 113, 133, 0.3); */
  border-style: solid;
  background-color: #fff;
  font-size: 17px !important;
  color: #555;
  padding: 13px 15px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  /* border-radius: 50px !important; */
}

.theme-form .form-control :focus {
  -webkit-box-shadow: 0 0 0 0.2rem rgba(122, 112, 186, 0.05);
          box-shadow: 0 0 0 0.2rem rgba(122, 112, 186, 0.05);
}
.theme-form textarea {
  border-color: #efefef;
}
.theme-form textarea::-webkit-input-placeholder {
  color: #898989;
  font-size: 14px;
  letter-spacing: 0px;
  font-weight: 100;
}
.theme-form input[type=text],
.theme-form input[type=email],
.theme-form input[type=search],
.theme-form input[type=password],
.theme-form input[type=number],
.theme-form input[type=tel],
.theme-form input[type=date],
.theme-form input[type=datetime-local],
.theme-form input[type=time],
.theme-form input[type=datetime-local],
.theme-form input[type=month],
.theme-form input[type=week],
.theme-form input[type=url],
.theme-form input[type=file],
.theme-form textarea,
.theme-form select {
  border-color: rgba(106, 113, 133, 0.4);
  border-style: solid;
  background-color: #fff;
  font-size: 16.5px;
  color: #222;
  font-weight: 500 !important;
  height: auto !important;
  padding: 13px 15px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 50px !important;
}
.theme-form input[type=text]::placeholder,
.theme-form input[type=email]::placeholder,
.theme-form input[type=search]::placeholder,
.theme-form input[type=password]::placeholder,
.theme-form input[type=number]::placeholder,
.theme-form input[type=tel]::placeholder,
.theme-form input[type=date]::placeholder,
.theme-form input[type=datetime-local]::placeholder,
.theme-form input[type=time]::placeholder,
.theme-form input[type=datetime-local]::placeholder,
.theme-form input[type=month]::placeholder,
.theme-form input[type=week]::placeholder,
.theme-form input[type=url]::placeholder,
.theme-form input[type=file]::placeholder,
.theme-form textarea::placeholder,
.theme-form select::placeholder {
  color: #666;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.theme-form input[type=password] {
  font-size: 20px !important;
  padding: 11px 15px !important;
}

.theme-form button:disabled {
  opacity: 0.7 !important;
  background: #337ab7 !important;
}
.theme-form input[type=password]::placeholder {
  color: #666;
}

.theme-form input[type=file] {
  height: auto;
}

.theme-form button{
  font-size: 18px !important;
  font-weight: 600 !important;
  border-radius: 50px !important;
  padding: 13px 20px !important;
}

.theme-form select.form-control:not([size]):not([multiple]) {
  border-color: #efefef;
  font-size: 14px;
}
.theme-form .checkbox label {
  padding-left: 10px;
}
[dir=rtl] .theme-form .checkbox label {
  padding-right: 10px;
  padding-left: unset;
}
.theme-form .form-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}
.theme-form .form-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  content: "Or Signup With";
  color: #9B9B9B;
  background: #fff;
}
[dir=rtl] .theme-form .form-divider:before {
  left: unset;
  right: 40%;
  margin-left: unset;
  margin-right: -10px;
}
.theme-form .login-divider {
  position: relative;
  clear: both;
  height: 0;
  margin: 30px 0;
  border-top: 1px solid #efefef;
}
.theme-form .login-divider:before {
  position: absolute;
  top: -11px;
  left: 40%;
  margin-left: -10px;
  padding: 0 5px;
  color: #9B9B9B;
  background: #fff;
  content: "Or Login With";
}
[dir=rtl] .theme-form .login-divider:before {
  left: unset;
  right: 40%;
  margin-left: unset;
  margin-right: -10px;
}
.theme-form .btn-fb,
.theme-form .btn-twitter,
.theme-form .btn-google,
.theme-form .btn-linkedin {
  color: #fff;
  width: 100%;
}
.theme-form .btn-fb {
  background-color: #50598e;
}
.theme-form .btn-twitter {
  background-color: #6fa2d8;
}
.theme-form .btn-google {
  background-color: #c64e40;
}
.theme-form .btn-linkedin {
  background-color: #0077B5;
}



.formInputs input[type=text],
.formInputs input[type=email],
.formInputs input[type=search],
.formInputs input[type=password],
.formInputs input[type=number],
.formInputs input[type=tel],
.formInputs input[type=date],
.formInputs input[type=datetime-local],
.formInputs input[type=time],
.formInputs input[type=datetime-local],
.formInputs input[type=month],
.formInputs input[type=week],
.formInputs input[type=url],
.formInputs input[type=file],
.formInputs textarea,
.formInputs select {
  border-color: rgba(106, 113, 133, 0.4);
  border-style: solid;
  background-color: #fff;
  font-size: 16px;
  color: #222;
  font-weight: 500 !important;
  height: auto !important;
  padding: 14px 15px !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 10px !important;

}
.formInputs input[type=text]::-webkit-input-placeholder,
.formInputs input[type=email]::-webkit-input-placeholder,
.formInputs input[type=search]::-webkit-input-placeholder,
.formInputs input[type=password]::-webkit-input-placeholder,
.formInputs input[type=number]::-webkit-input-placeholder,
.formInputs input[type=tel]::-webkit-input-placeholder,
.formInputs input[type=date]::-webkit-input-placeholder,
.formInputs input[type=datetime-local]::-webkit-input-placeholder,
.formInputs input[type=time]::-webkit-input-placeholder,
.formInputs input[type=datetime-local]::-webkit-input-placeholder,
.formInputs input[type=month]::-webkit-input-placeholder,
.formInputs input[type=week]::-webkit-input-placeholder,
.formInputs input[type=url]::-webkit-input-placeholder,
.formInputs input[type=file]::-webkit-input-placeholder,
.formInputs textarea::-webkit-input-placeholder,
.formInputs select::-webkit-input-placeholder,
.formInputs input[type=text]::placeholder,
.formInputs input[type=email]::placeholder,
.formInputs input[type=search]::placeholder,
.formInputs input[type=password]::placeholder,
.formInputs input[type=number]::placeholder,
.formInputs input[type=tel]::placeholder,
.formInputs input[type=date]::placeholder,
.formInputs input[type=datetime-local]::placeholder,
.formInputs input[type=time]::placeholder,
.formInputs input[type=datetime-local]::placeholder,
.formInputs input[type=month]::placeholder,
.formInputs input[type=week]::placeholder,
.formInputs input[type=url]::placeholder,
.formInputs input[type=file]::placeholder,
.formInputs textarea::placeholder,
.formInputs select::placeholder {
  color: #666;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  font-weight: 500 !important;
}
.formInputs input[type=password] {
  font-size: 20px !important;
  padding: 11px 15px !important;
}
.formInputs input[type=password]::placeholder {
  color: #666;
  font-size: 15px !important;
}
.formInputs button:disabled {
  opacity: 0.7 !important;
  background: #337ab7 !important;
}
.formInputs .form-label {
  font-weight: 600 !important;
  font-size: 16.5px !important;
  margin-bottom: 3px;
}
.formInputs button.btn {
  /* font-size: 26px !important; */
  padding: 16px 15px !important;
  border-radius: 10px !important;
}




.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
      flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 0;
}
.form-inline .col-form-label {
  margin-right: 5px;
}
[dir=rtl] .form-inline .col-form-label {
  margin-left: 5px;
  margin-right: unset;
}

.search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: #e8ebf2;
  left: 82px;
  top: 50px;
}
[dir=rtl] .search-form .form-group:before {
  left: unset;
  right: 82px;
}
.search-form .form-group:after {
  position: absolute;
  content: "\f002";
  font-family: "FontAwesome";
  top: 53px;
  left: 53px;
  color: #8e8e8e;
}
[dir=rtl] .search-form .form-group:after {
  left: unset;
  right: 53px;
}
.search-form input {
  border: 1px solid #efefef;
  padding: 10px 10px 10px 70px;
  border-radius: 10px;
  background-color: rgba(244, 244, 244, 0.3);
}
[dir=rtl] .search-form input {
  padding: 10px 70px 10px 10px;
}
.search-form input:focus {
  outline: none !important;
}

.form-label-align-right label {
  text-align: right;
  padding-top: 17px;
  font-weight: 500;
}

.custom-input.was-validated .form-control:valid,
.custom-input.was-validated .form-select:valid {
  border-color: #0DA759;
}
.custom-input.was-validated .form-control:invalid,
.custom-input.was-validated .form-select:invalid {
  border-color: #E44141;
}
.custom-input .valid-tooltip {
  background-color: #0DA759;
}
.custom-input .invalid-tooltip {
  background-color: rgba(228, 65, 65, 0.8);
}
.custom-input .invalid-feedback {
  color: #E44141 !important;
}
.custom-input .form-check .form-check-input:valid {
  border-color: #0DA759;
}
.custom-input .form-check .form-check-input:invalid {
  border-color: #E44141;
}
.custom-input .form-check .form-check-input:invalid ~ .form-check-label {
  color: #E44141;
}
.custom-input .form-check .form-check-input:valid ~ .form-check-label {
  color: #0DA759;
}
.custom-input .form-check .form-check-input:checked {
  background-color: #0DA759;
}
.custom-input .form-check .invalid-feedback {
  color: #E44141;
}
.custom-input #validationCustom03.form-control,
.custom-input #validationCustom03 .form-select,
.custom-input #validationCustom05.form-control,
.custom-input #validationCustom05 .form-select,
.custom-input #validationCustomUsername.form-control,
.custom-input #validationCustomUsername .form-select {
  border-color: rgba(106, 113, 133, 0.3);
}
.custom-input #validationCustom03.was-validated .form-control:valid,
.custom-input #validationCustom03.was-validated .form-select:valid,
.custom-input #validationCustom05.was-validated .form-control:valid,
.custom-input #validationCustom05.was-validated .form-select:valid,
.custom-input #validationCustomUsername.was-validated .form-control:valid,
.custom-input #validationCustomUsername.was-validated .form-select:valid {
  border-color: #0DA759;
}
.custom-input #validationCustom03.was-validated .form-control:invalid,
.custom-input #validationCustom03.was-validated .form-select:invalid,
.custom-input #validationCustom05.was-validated .form-control:invalid,
.custom-input #validationCustom05.was-validated .form-select:invalid,
.custom-input #validationCustomUsername.was-validated .form-control:invalid,
.custom-input #validationCustomUsername.was-validated .form-select:invalid {
  border-color: #E44141;
}
[dir=rtl] .custom-input .form-check.form-switch .form-check-input {
  margin-left: 7px;
}

.form-check-reverse {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0;
}
.form-check-reverse .form-check-input {
  float: left;
  margin-right: 0;
  margin-left: 10px;
}

.checkbox-checked.card-wrapper h6.sub-title {
  font-weight: 500;
}
.checkbox-checked .form-check-input:checked {
  background-color: #337ab7;
  border-color: #337ab7;
}
.checkbox-checked .form-check-input:focus {
  border-color: #337ab7;
  -webkit-box-shadow: 0 0 0 4px rgba(122, 112, 186, 0.25);
          box-shadow: 0 0 0 4px rgba(122, 112, 186, 0.25);
}
.checkbox-checked .card-wrapper .form-check:last-child {
  min-height: 0;
  margin-bottom: 0;
}
.checkbox-checked .card-wrapper .form-check:last-child label {
  margin-bottom: 0;
}

.form-check-size {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 22px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.form-check-size .form-check-inline {
  margin: 0;
  min-height: 0;
}
[dir=rtl] .form-check-size .form-check-inline {
  margin: 0;
}
.form-check-size .form-check-inline .form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 0;
}
.form-check-size .form-check-inline .check-size {
  width: 36px;
  height: 18px;
  margin-top: 0;
}
.form-check-size .form-check-inline label {
  margin: 0;
}

.main-img-checkbox .img-checkbox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.main-img-checkbox .img-checkbox .main-img-cover {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 24px;
  height: 24px;
}
[dir=rtl] .main-img-checkbox .img-checkbox .main-img-cover {
  left: unset;
  right: 15px;
}
.main-img-checkbox .img-checkbox .form-check-label {
  width: 100%;
}
.main-img-checkbox .img-checkbox img {
  width: 100%;
  height: 220px;
  -o-object-fit: cover;
     object-fit: cover;
}

.main-icon-checkbox.checkbox label {
  margin-top: 0;
}
.main-icon-checkbox.checkbox label::before {
  content: "";
  width: 100%;
  height: 100%;
  margin-left: 1px;
  background-color: transparent;
  top: 0;
  display: block;
}
[dir=rtl] .main-icon-checkbox.checkbox label::before {
  margin-left: unset;
  margin-right: 1px;
}
.main-icon-checkbox.checkbox-primary input[type=checkbox]:checked + label::before, .main-icon-checkbox.radio-primary input[type=checkbox]:checked + label::before {
  color: transparent;
  border: 2px solid #337ab7;
}
.main-icon-checkbox.checkbox-primary input[type=checkbox], .main-icon-checkbox.radio-primary input[type=checkbox] {
  opacity: 1;
}
.main-icon-checkbox.checkbox-primary .form-check-input, .main-icon-checkbox.radio-primary .form-check-input {
  border: 1px solid rgba(106, 113, 133, 0.3);
}
.main-icon-checkbox.checkbox-primary .form-check-input.checkbox-shadow:focus, .main-icon-checkbox.radio-primary .form-check-input.checkbox-shadow:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.main-icon-checkbox.checkbox-primary .form-check-input:checked[type=checkbox], .main-icon-checkbox.radio-primary .form-check-input:checked[type=checkbox] {
  background-image: none;
}

.radio .radio-wrapper li {
  border: 1px solid #999999;
}

.main-radio-toggle .btn,
.main-checkbox-toggle .btn {
  margin-bottom: 0;
}

.radio-wrapper,
.checkbox-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: calc(8px + 8 * (100vw - 320px) / 1600);
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (max-width: 1200px) {
  .radio-wrapper,
  .checkbox-wrapper {
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
}
.radio-wrapper li,
.checkbox-wrapper li {
  position: relative;
  width: 100%;
  padding: 12px;
  border: none;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  max-width: 130px;
}
@media (max-width: 1335px) {
  .radio-wrapper li,
  .checkbox-wrapper li {
    max-width: 113px;
  }
}
@media (max-width: 1232px) {
  .radio-wrapper li,
  .checkbox-wrapper li {
    padding: 10px;
    max-width: 105px;
  }
}
.radio-wrapper li .form-check-input,
.checkbox-wrapper li .form-check-input {
  position: absolute;
  width: 100%;
  height: 100%;
  float: none;
  margin: 0;
  top: 0;
  left: 0;
  opacity: 1;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid rgba(106, 113, 133, 0.3);
}
.radio-wrapper li .form-check-input:focus,
.checkbox-wrapper li .form-check-input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.radio-wrapper li .form-check-input[type=radio],
.checkbox-wrapper li .form-check-input[type=radio] {
  opacity: 1;
}
.radio-wrapper li .form-check-input:checked,
.checkbox-wrapper li .form-check-input:checked {
  border: 2px solid #337ab7;
  background-color: transparent;
}
.radio-wrapper li .form-check-input:checked[type=radio],
.checkbox-wrapper li .form-check-input:checked[type=radio] {
  background-image: none;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.radio-wrapper li .form-check-label,
.checkbox-wrapper li .form-check-label {
  position: unset;
  padding: 0;
  margin-bottom: 0;
}
.radio-wrapper li .form-check-label:after,
.checkbox-wrapper li .form-check-label:after {
  content: none;
}
.radio-wrapper li .form-check-label::before,
.checkbox-wrapper li .form-check-label::before {
  content: none;
}
.radio-wrapper li .form-check-label i,
.checkbox-wrapper li .form-check-label i {
  font-size: 18px;
  padding-right: 8px;
}
[dir=rtl] .radio-wrapper li .form-check-label i,
[dir=rtl] .checkbox-wrapper li .form-check-label i {
  padding-right: 0;
  padding-left: 8px;
}
.radio-wrapper li .form-check-label span,
.checkbox-wrapper li .form-check-label span {
  font-size: 16px;
}

[dir=rtl] .rtl-input .form-check.form-check-inline {
  padding: 0;
}
[dir=rtl] .rtl-input .form-check.form-switch {
  padding-right: 40px;
}
[dir=rtl] .rtl-input .form-check.form-switch .form-check-input {
  margin-left: unset;
  margin-right: -40px;
  float: right;
}

.radio-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 18px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.radio-form .form-check {
  min-height: 0;
  margin-bottom: 0;
}
.radio-form .form-check .form-check-label {
  margin-bottom: 0;
}

.basic-form {
  position: relative;
}
.basic-form svg {
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
[dir=rtl] .basic-form svg {
  right: 0;
  left: 12px;
}
.basic-form svg.stroke-icon {
  width: 25px;
  height: 25px;
  stroke: #2c323f;
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
[dir=rtl] .basic-form svg.stroke-icon {
  right: unset;
  left: 12px;
}

.form-check {
  margin-bottom: 0;
}

.theme-form .custom-input .row .col .row:last-child label {
  margin: 0;
}
.theme-form.dark-inputs input::-webkit-file-upload-button {
  border-radius: 30px;
}
.theme-form.dark-inputs input::file-selector-button {
  border-radius: 30px;
}
[dir=rtl] .theme-form .form-floating > label {
  left: unset;
  right: 0;
}

/**=====================
  2.18 Forms CSS Ends
==========================**/
/**=====================
	2.19 Icons CSS Start
==========================**/
.icon-lists div {
  cursor: pointer;
  line-height: 60px;
  white-space: nowrap;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.icon-lists div:hover {
  background-color: #fafafa;
}
.icon-lists div i {
  margin: 0 15px 0 10px;
  font-size: 24px;
}
.icon-lists.whether-icon svg {
  height: 50px;
  width: 50px;
}
.icon-lists.feather-icons .d-flex svg {
  margin: 0 15px 0 10px;
}
.icon-lists .feather-icon .icon-first h6 {
  display: none;
}

.flag-icons,
.feather-icons {
  margin-bottom: -15px;
  margin-top: -15px;
}
.flag-icons div,
.feather-icons div {
  line-height: 1;
  word-wrap: break-word;
  overflow: auto;
}
.flag-icons div .flag-icon,
.feather-icons div .flag-icon {
  height: 50px;
  width: 50px;
}
.flag-icons div .d-flex,
.feather-icons div .d-flex {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
}
.flag-icons div .flex-grow-1 h6,
.feather-icons div .flex-grow-1 h6 {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.flag-icons div .flex-grow-1 h5,
.feather-icons div .flex-grow-1 h5 {
  background-color: var(--theme-deafult);
  display: inline-block;
  padding: 3px 5px;
  font-size: 11px;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-right: 5px;
  color: #fff;
}
[dir=rtl] .flag-icons div .flex-grow-1 h5,
[dir=rtl] .feather-icons div .flex-grow-1 h5 {
  margin-left: 5px;
  margin-right: unset;
}

.icon-hover-bottom {
  background-color: #fdfeff;
  width: 100%;
  bottom: 0;
  left: 0;
  -webkit-box-shadow: 0px 0px 1px 1px #f4f4f4;
          box-shadow: 0px 0px 1px 1px #f4f4f4;
  z-index: 999;
}
.icon-hover-bottom .icon-popup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  padding: 20px;
}
.icon-hover-bottom .icon-popup .close-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 20px;
  color: rgba(106, 113, 133, 0.3);
  cursor: pointer;
}
[dir=rtl] .icon-hover-bottom .icon-popup .close-icon {
  right: unset;
  left: 10px;
}
.icon-hover-bottom .icon-popup > div {
  display: inline-block;
}
.icon-hover-bottom .icon-popup > div .flag-icon {
  width: 60px;
  height: 60px;
}
.icon-hover-bottom .icon-popup .icon-class {
  margin: 0 20px;
  cursor: pointer;
}
.icon-hover-bottom .icon-popup .icon-last .form-inline .form-group {
  display: initial;
}
.icon-hover-bottom .icon-title {
  display: block;
  color: #2c323f;
  text-transform: uppercase;
  font-weight: 500;
}
.icon-hover-bottom span,
.icon-hover-bottom label,
.icon-hover-bottom .btn {
  font-family: "Nunito", sans-serif, sans-serif;
}
.icon-hover-bottom .form-group input {
  display: inline-block;
  width: 300px;
  background-color: #fff;
  font-family: "Nunito", sans-serif, sans-serif;
}

.fa-fa-icon-show-div,
.icons-show-div {
  display: none;
}

.icon-first i {
  color: #898989;
}
.icon-first h6 {
  display: none;
}

/**=====================
    2.19 Icons CSS Ends
==========================**/
/**=====================
   2.20 List CSS Start
==========================**/
.list-group-item.active {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
}

.list-group i {
  margin-right: 10px;
}
.list-group img {
  height: 40px;
  width: 40px;
  margin-right: 10px;
}

.list-light-primary {
  background-color: #E0DDFF;
}
.list-light-primary:hover, .list-light-primary:active, .list-light-primary:focus {
  background-color: #E0DDFF;
}

.list-hover-primary {
  background-color: unset;
}
.list-hover-primary:hover, .list-hover-primary:active, .list-hover-primary.active, .list-hover-primary:focus {
  background-color: #E0DDFF;
  color: #2c323f;
  z-index: 0;
}

.list-light-secondary {
  background-color: #c7eaff;
}
.list-light-secondary:hover, .list-light-secondary:active, .list-light-secondary:focus {
  background-color: #c7eaff;
}

.list-hover-secondary {
  background-color: unset;
}
.list-hover-secondary:hover, .list-hover-secondary:active, .list-hover-secondary.active, .list-hover-secondary:focus {
  background-color: #c7eaff;
  color: #2c323f;
  z-index: 0;
}

.list-light-success {
  background-color: #c8f9e1;
}
.list-light-success:hover, .list-light-success:active, .list-light-success:focus {
  background-color: #c8f9e1;
}

.list-hover-success {
  background-color: unset;
}
.list-hover-success:hover, .list-hover-success:active, .list-hover-success.active, .list-hover-success:focus {
  background-color: #c8f9e1;
  color: #2c323f;
  z-index: 0;
}

.list-light-warning {
  background-color: #fbdac9;
}
.list-light-warning:hover, .list-light-warning:active, .list-light-warning:focus {
  background-color: #fbdac9;
}

.list-hover-warning {
  background-color: unset;
}
.list-hover-warning:hover, .list-hover-warning:active, .list-hover-warning.active, .list-hover-warning:focus {
  background-color: #fbdac9;
  color: #2c323f;
  z-index: 0;
}

.list-light-info {
  background-color: #d0f3fd;
}
.list-light-info:hover, .list-light-info:active, .list-light-info:focus {
  background-color: #d0f3fd;
}

.list-hover-info {
  background-color: unset;
}
.list-hover-info:hover, .list-hover-info:active, .list-hover-info.active, .list-hover-info:focus {
  background-color: #d0f3fd;
  color: #2c323f;
  z-index: 0;
}

.list-light-light {
  background-color: #F9F9FB;
}
.list-light-light:hover, .list-light-light:active, .list-light-light:focus {
  background-color: #F9F9FB;
}

.list-hover-light {
  background-color: unset;
}
.list-hover-light:hover, .list-hover-light:active, .list-hover-light.active, .list-hover-light:focus {
  background-color: #F9F9FB;
  color: #2c323f;
  z-index: 0;
}

.list-light-danger {
  background-color: #FEDDDB;
}
.list-light-danger:hover, .list-light-danger:active, .list-light-danger:focus {
  background-color: #FEDDDB;
}

.list-hover-danger {
  background-color: unset;
}
.list-hover-danger:hover, .list-hover-danger:active, .list-hover-danger.active, .list-hover-danger:focus {
  background-color: #FEDDDB;
  color: #2c323f;
  z-index: 0;
}

.list-light-dark {
  background-color: #EAEBF2;
}
.list-light-dark:hover, .list-light-dark:active, .list-light-dark:focus {
  background-color: #EAEBF2;
}

.list-hover-dark {
  background-color: unset;
}
.list-hover-dark:hover, .list-hover-dark:active, .list-hover-dark.active, .list-hover-dark:focus {
  background-color: #EAEBF2;
  color: #2c323f;
  z-index: 0;
}

.horizontal-list-wrapper .list-group .list-group-item {
  padding: 8px;
}

.list-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
@media (max-width: 414px) {
  .list-wrapper {
    -ms-flex-wrap: unset;
        flex-wrap: unset;
    word-break: break-word;
  }
}
@media (max-width: 366px) {
  .list-wrapper {
    padding-bottom: 8px;
  }
}
.list-wrapper .list-img {
  width: calc(40px + 15 * (100vw - 320px) / 1600);
  height: calc(40px + 15 * (100vw - 320px) / 1600);
  margin-bottom: 10px;
  border-radius: 50%;
}
.list-wrapper .list-content p {
  margin-bottom: 0;
}

.scrollbar-wrapper {
  max-height: 270px;
  overflow: auto;
}

@media (max-width: 480px) {
  .main-lists-content small {
    display: none;
  }
}

@media (max-width: 1199px) {
  .list-behavior-1 {
    gap: 12px;
  }
}
@media (max-width: 575px) {
  .list-behavior-1:first-child {
    margin-bottom: 14px;
  }
}
@media (max-width: 1999px) {
  .list-behavior-1 img {
    height: 86px;
  }
}
@media (max-width: 992px) {
  .list-behavior-1 img {
    height: 70px;
  }
}
.list-behavior-1 .flex-grow-1 {
  margin-left: 16px;
}
@media (max-width: 1199px) {
  .list-behavior-1 .flex-grow-1 {
    margin-left: 0;
  }
  .list-behavior-1 .flex-grow-1 p {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    margin: 0 auto;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
[dir=rtl] .list-behavior-1 .flex-grow-1 {
  margin-right: 16px;
  margin-left: unset;
}

.list-behaviors > div:nth-child(even) .flex-grow-1 {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
  margin-left: unset;
  margin-right: 16px;
}
@media (max-width: 1199px) {
  .list-behaviors > div:nth-child(even) .flex-grow-1 {
    margin-left: 0;
    margin-right: 0;
    -webkit-box-ordinal-group: unset;
        -ms-flex-order: unset;
            order: unset;
  }
}

.list-group .list-group-item {
  padding: 11px 16px;
}
.list-group .list-group-item .form-check-input:focus {
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
}
[dir=rtl] .list-group .list-group-item i {
  margin-left: 10px;
  margin-right: unset;
}

@media (max-width: 1216px) {
  .contact-profile {
    background-color: unset;
  }
  .contact-profile.list-light-dark:hover {
    background-color: unset;
  }
}

.badge-list .list-group-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.badge-list .list-group-item div {
  margin-right: auto;
  margin-left: 8px;
}
[dir=rtl] .badge-list .list-group-item div {
  margin-left: auto;
  margin-right: 8px;
}

/**=====================
   2.20 List CSS Ends
==========================**/
/**=====================
    2.21 Loader CSS Start
==========================**/
.loader-wrapper {
  height: 100%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: fixed;
  background-color: #fff;
  z-index: 11;
  top: 0;
}
.loader-wrapper .loader {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin: 75px;
  display: inline-block;
  vertical-align: middle;
}
.loader-wrapper .loader-1 .loader-outter {
  position: absolute;
  border: 4px solid #48A3D7;
  border-left-color: transparent;
  border-bottom: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: loader-1-outter 1.5s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
          animation: loader-1-outter 1.5s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.loader-wrapper .loader-1 .loader-inner {
  position: absolute;
  border: 4px solid #337ab7;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
          animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}
.loader-wrapper .loader-1 .loader-inner-1 {
  position: absolute;
  border: 4px solid #D77748;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  left: calc(50% - 30px);
  top: calc(50% - 30px);
  border-right: 0;
  border-top-color: transparent;
  -webkit-animation: loader-1-inner 2s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
          animation: loader-1-inner 2s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

@-webkit-keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes loader-1-inner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}
@-webkit-keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes loader-1-outter {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/**=====================
    2.21 Loader CSS Ends
==========================**/
/**=====================
    2.22 Modal CSS start
==========================**/
.modal-toggle-wrapper .form-control::-webkit-input-placeholder {
  font-size: 13px;
}
.modal-toggle-wrapper .form-control::-moz-placeholder {
  font-size: 13px;
}
.modal-toggle-wrapper .form-control:-ms-input-placeholder {
  font-size: 13px;
}
.modal-toggle-wrapper .form-control::-ms-input-placeholder {
  font-size: 13px;
}
.modal-toggle-wrapper .form-control::placeholder {
  font-size: 13px;
}
.modal-toggle-wrapper .modal-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.modal-toggle-wrapper .modal-img img {
  width: 200px;
  height: 200px;
}
@media (max-width: 992px) {
  .modal-toggle-wrapper .modal-img img {
    width: calc(136px + 64 * (100vw - 320px) / 672);
    height: calc(136px + 64 * (100vw - 320px) / 672);
  }
}
.modal-toggle-wrapper .modal-img li img {
  width: 100px;
  height: 100px;
}
@media (max-width: 992px) {
  .modal-toggle-wrapper .modal-img li img {
    width: calc(75px + 25 * (100vw - 320px) / 672);
    height: calc(75px + 25 * (100vw - 320px) / 672);
  }
}
.modal-toggle-wrapper .modal-img li + li {
  margin-left: -39px;
}
.modal-toggle-wrapper button svg {
  width: 16px;
  height: 16px;
}

.balance-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.balance-item .balance-icon-wrap {
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
}
@media (max-width: 1660px) {
  .balance-item .balance-icon-wrap {
    width: 25px;
    min-width: 25px;
    height: 25px;
    border-radius: 8px;
  }
}
.balance-item .balance-icon-wrap .balance-icon {
  width: 16px;
  height: 16px;
  border-radius: 100%;
}
@media (max-width: 1660px) {
  .balance-item .balance-icon-wrap .balance-icon {
    width: 14px;
    height: 14px;
  }
}
.balance-item .balance-icon-wrap .balance-icon svg {
  color: #fff;
  width: 14px;
  height: 14px;
}
@media (max-width: 1660px) {
  .balance-item .balance-icon-wrap .balance-icon svg {
    width: 12px;
    height: 12px;
    vertical-align: text-top;
  }
}
.balance-item .badge {
  padding: 8px 10px;
}
.balance-item.danger .balance-icon-wrap {
  background-color: rgba(228, 65, 65, 0.1);
}
.balance-item.danger .balance-icon-wrap .balance-icon {
  background-color: #E44141;
}
.balance-item.success .balance-icon-wrap {
  background-color: rgba(13, 167, 89, 0.1);
}
.balance-item.success .balance-icon-wrap .balance-icon {
  background-color: #0DA759;
}

.balance-box {
  text-align: center;
  background-image: url(../../images/bg-balance.png);
  background-position: right;
  background-size: cover;
}
.balance-box .balance-img {
  display: inline-block;
  padding: 5px;
  border: 2px solid var(--chart-border);
  border-left-color: #0DA759;
  border-bottom-color: #0DA759;
  border-radius: 100%;
  position: relative;
  margin-bottom: 15px;
}
.balance-box .balance-img img {
  background-color: var(--balance-profie-bg);
  border-radius: 100%;
}
.balance-box .balance-img .edit-icon {
  width: 26px;
  height: 26px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 2px solid var(--white);
  background-color: #DFDFF4;
  right: 0;
  bottom: 0;
  border-radius: 100%;
}
.balance-box .balance-img .edit-icon svg {
  width: 11px;
  height: 11px;
  fill: #337ab7;
}

.balance-profile ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 50px;
  margin-top: 16px;
}
@media (max-width: 1660px) {
  .balance-profile ul {
    gap: 25px;
  }
}
.balance-profile ul li {
  position: relative;
}
.balance-profile ul li + li::before {
  position: absolute;
  content: "";
  left: -25px;
  top: 50%;
  height: 40px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border: 1px solid var(--recent-solid-border);
}
[dir=rtl] .balance-profile ul li + li::before {
  left: unset;
  right: -25px;
}
@media (max-width: 1660px) {
  .balance-profile ul li + li::before {
    left: -16px;
  }
  [dir=rtl] .balance-profile ul li + li::before {
    right: -16px;
  }
}

.large-modal-header,
.large-modal-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #2c323f;
}
.large-modal-header svg,
.large-modal-body svg {
  width: 20px;
  height: 20px;
}

.modal-padding-space {
  padding-left: 20px;
}

.svg-modal {
  width: 15px;
  height: 15px;
  stroke: #48A3D7;
  vertical-align: middle;
}

.card-wrapper .modal-heading h5 {
  font-size: calc(15px + 3 * (100vw - 320px) / 1080);
}
@media (min-width: 750px) and (max-width: 1200px) {
  .card-wrapper .balance-modal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.modal-footer .btn {
  margin: 0 4px;
}

.modal-content .modal-header .btn-close:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.modal-details h6 {
  padding-bottom: 12px;
}
.modal-details .web-content {
  margin-bottom: 16px;
}
.modal-details .web-content .d-flex p {
  padding-bottom: 8px;
}
.modal-details .web-content .d-flex:last-child p {
  padding-bottom: 0;
}
.modal-details .web-content:last-child {
  margin-bottom: 0;
}

/**=====================
    2.22 Modal CSS end
==========================**/
/**=====================
  2.23 Popover CSS Start
==========================**/
.popover-header {
  background-color: #2c323f;
  color: #fff;
}

.popover-main .btn-showcase a {
  color: #fff;
}
.popover-main .btn-showcase button:last-child {
  margin-right: 0;
}

/**=====================
  2.23 Popover CSS Ends
==========================**/
/**=====================
  2.24 Print CSS Start
==========================**/
@media print {
  .sidebar-wrapper,
  .page-header {
    display: none;
  }
  .page-body {
    margin: 0 !important;
  }
  .page-wrapper .page-title {
    visibility: hidden;
  }
  .page-wrapper .page-body-wrapper .page-title {
    display: none;
  }
  .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    margin: 0;
  }
  table.fixedHeader-floating {
    display: none;
  }
  .email-left-aside {
    display: none;
  }
  .footer {
    display: none;
  }
}
.invoice-table {
  margin-top: 16px;
  margin-bottom: 16px;
}

.invoice .text-right input {
  margin-top: 6px;
}
.invoice .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

/**=====================
  2.24 Print CSS Ends
==========================**/
/**=====================
    2.25 Radio CSS Start
==========================**/
.animate-chk label {
  line-height: 1.6;
  cursor: pointer;
}
.animate-chk label:last-child {
  margin-bottom: 0;
}

.checkbox_animated {
  cursor: pointer;
  position: relative;
  margin: 0 1rem 0 0;
}
.checkbox_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: rotate(-45deg) scale(0, 0);
          transform: rotate(-45deg) scale(0, 0);
  content: "";
  position: absolute;
  left: 0.25rem;
  top: 0.225rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.375rem;
  border: 2px solid var(--theme-deafult);
  border-top-style: none;
  border-right-style: none;
}
.checkbox_animated:after {
  content: "";
  position: absolute;
  top: -0.125rem;
  left: 0;
  width: 1.3rem;
  height: 1.3rem;
  background: #fff;
  border: 2px solid rgba(106, 113, 133, 0.3);
  cursor: pointer;
}
.checkbox_animated:checked:before {
  -webkit-transform: rotate(-45deg) scale(1, 1);
          transform: rotate(-45deg) scale(1, 1);
}

.radio_animated {
  position: relative;
  margin: 0 1rem 0 0;
  cursor: pointer;
}
.radio_animated:before {
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  transition: transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75), -webkit-transform 0.4s cubic-bezier(0.45, 1.8, 0.5, 0.75);
  -webkit-transform: scale(0, 0);
          transform: scale(0, 0);
  content: "";
  position: absolute;
  top: 0;
  left: 0.125rem;
  z-index: 1;
  width: 0.75rem;
  height: 0.75rem;
  background: var(--theme-deafult);
  border-radius: 50%;
}
.radio_animated:after {
  content: "";
  position: absolute;
  top: -0.25rem;
  left: -0.125rem;
  width: 1.25rem;
  height: 1.25rem;
  background: #fff;
  border: 2px solid rgba(106, 113, 133, 0.3);
  border-radius: 50%;
}
.radio_animated:checked:before {
  -webkit-transform: scale(1, 1);
          transform: scale(1, 1);
}

.checkbox label {
  display: inline-block;
  position: relative;
  padding-left: 16px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 10px;
}
.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 0;
  margin-left: -16px;
  border: 1px solid #efefef;
  border-radius: 3px;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 0;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #9B9B9B;
}
.checkbox input[type=checkbox] {
  opacity: 0;
}
.checkbox input[type=checkbox]:focus {
  outline-offset: -2px;
}
.checkbox input[type=checkbox]:focus + label::before {
  outline-offset: -2px;
}
.checkbox input[type=checkbox]:checked + label::before {
  font-family: themify;
  content: "\e64c";
  text-align: center;
  line-height: 1.2;
}
.checkbox input[type=checkbox]:disabled + label {
  opacity: 0.65;
}
.checkbox input[type=checkbox]:disabled + label::before {
  background-color: #f4f4f4;
  cursor: not-allowed;
}
.checkbox .checkbox-circle label::before {
  border-radius: 50%;
}
.checkbox .checkbox-inline {
  margin-top: 0;
}

.m-squar label::before {
  border-radius: 0;
}
.m-squar .checkbox-circle label::before {
  border-radius: 50%;
}
.m-squar .checkbox-inline {
  margin-top: 0;
}

.checkbox-primary label::before {
  border-color: var(--theme-deafult);
}
.checkbox-primary input[type=checkbox]:checked + label::before {
  border-color: var(--theme-deafult);
  color: var(--theme-deafult);
}
.checkbox-primary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-primary label:before {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
  color: #fff;
}
.checkbox-solid-primary input[type=checkbox]:checked + label::before {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
  color: #fff;
}
.checkbox-solid-primary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-primary input[type=radio] + label::before {
  border-color: var(--theme-deafult);
}
.radio-primary input[type=radio] + label::after {
  background-color: var(--theme-deafult);
}
.radio-primary input[type=radio]:checked + label::before {
  border-color: var(--theme-deafult);
}
.radio-primary input[type=radio]:checked + label::after {
  background-color: var(--theme-deafult);
}

.checkbox-secondary label::before {
  border-color: #48A3D7;
}
.checkbox-secondary input[type=checkbox]:checked + label::before {
  border-color: #48A3D7;
  color: #48A3D7;
}
.checkbox-secondary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-secondary label:before {
  background-color: #48A3D7;
  border-color: #48A3D7;
  color: #fff;
}
.checkbox-solid-secondary input[type=checkbox]:checked + label::before {
  background-color: #48A3D7;
  border-color: #48A3D7;
  color: #fff;
}
.checkbox-solid-secondary input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-secondary input[type=radio] + label::before {
  border-color: #48A3D7;
}
.radio-secondary input[type=radio] + label::after {
  background-color: #48A3D7;
}
.radio-secondary input[type=radio]:checked + label::before {
  border-color: #48A3D7;
}
.radio-secondary input[type=radio]:checked + label::after {
  background-color: #48A3D7;
}

.checkbox-success label::before {
  border-color: #0DA759;
}
.checkbox-success input[type=checkbox]:checked + label::before {
  border-color: #0DA759;
  color: #0DA759;
}
.checkbox-success input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-success label:before {
  background-color: #0DA759;
  border-color: #0DA759;
  color: #fff;
}
.checkbox-solid-success input[type=checkbox]:checked + label::before {
  background-color: #0DA759;
  border-color: #0DA759;
  color: #fff;
}
.checkbox-solid-success input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-success input[type=radio] + label::before {
  border-color: #0DA759;
}
.radio-success input[type=radio] + label::after {
  background-color: #0DA759;
}
.radio-success input[type=radio]:checked + label::before {
  border-color: #0DA759;
}
.radio-success input[type=radio]:checked + label::after {
  background-color: #0DA759;
}

.checkbox-danger label::before {
  border-color: #E44141;
}
.checkbox-danger input[type=checkbox]:checked + label::before {
  border-color: #E44141;
  color: #E44141;
}
.checkbox-danger input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-danger label:before {
  background-color: #E44141;
  border-color: #E44141;
  color: #fff;
}
.checkbox-solid-danger input[type=checkbox]:checked + label::before {
  background-color: #E44141;
  border-color: #E44141;
  color: #fff;
}
.checkbox-solid-danger input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-danger input[type=radio] + label::before {
  border-color: #E44141;
}
.radio-danger input[type=radio] + label::after {
  background-color: #E44141;
}
.radio-danger input[type=radio]:checked + label::before {
  border-color: #E44141;
}
.radio-danger input[type=radio]:checked + label::after {
  background-color: #E44141;
}

.checkbox-info label::before {
  border-color: #16C7F9;
}
.checkbox-info input[type=checkbox]:checked + label::before {
  border-color: #16C7F9;
  color: #16C7F9;
}
.checkbox-info input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-info label:before {
  background-color: #16C7F9;
  border-color: #16C7F9;
  color: #fff;
}
.checkbox-solid-info input[type=checkbox]:checked + label::before {
  background-color: #16C7F9;
  border-color: #16C7F9;
  color: #fff;
}
.checkbox-solid-info input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-info input[type=radio] + label::before {
  border-color: #16C7F9;
}
.radio-info input[type=radio] + label::after {
  background-color: #16C7F9;
}
.radio-info input[type=radio]:checked + label::before {
  border-color: #16C7F9;
}
.radio-info input[type=radio]:checked + label::after {
  background-color: #16C7F9;
}

.checkbox-light label::before {
  border-color: #f4f4f4;
}
.checkbox-light input[type=checkbox]:checked + label::before {
  border-color: #f4f4f4;
  color: #f4f4f4;
}
.checkbox-light input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-light label:before {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: #fff;
}
.checkbox-solid-light input[type=checkbox]:checked + label::before {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: #fff;
}
.checkbox-solid-light input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-light input[type=radio] + label::before {
  border-color: #f4f4f4;
}
.radio-light input[type=radio] + label::after {
  background-color: #f4f4f4;
}
.radio-light input[type=radio]:checked + label::before {
  border-color: #f4f4f4;
}
.radio-light input[type=radio]:checked + label::after {
  background-color: #f4f4f4;
}

.checkbox-dark label::before {
  border-color: #2c323f;
}
.checkbox-dark input[type=checkbox]:checked + label::before {
  border-color: #2c323f;
  color: #2c323f;
}
.checkbox-dark input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-dark label:before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}
.checkbox-solid-dark input[type=checkbox]:checked + label::before {
  background-color: #2c323f;
  border-color: #2c323f;
  color: #fff;
}
.checkbox-solid-dark input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-dark input[type=radio] + label::before {
  border-color: #2c323f;
}
.radio-dark input[type=radio] + label::after {
  background-color: #2c323f;
}
.radio-dark input[type=radio]:checked + label::before {
  border-color: #2c323f;
}
.radio-dark input[type=radio]:checked + label::after {
  background-color: #2c323f;
}

.checkbox-warning label::before {
  border-color: #D77748;
}
.checkbox-warning input[type=checkbox]:checked + label::before {
  border-color: #D77748;
  color: #D77748;
}
.checkbox-warning input[type=checkbox]:checked + label::after {
  color: #fff;
}

.checkbox-solid-warning label:before {
  background-color: #D77748;
  border-color: #D77748;
  color: #fff;
}
.checkbox-solid-warning input[type=checkbox]:checked + label::before {
  background-color: #D77748;
  border-color: #D77748;
  color: #fff;
}
.checkbox-solid-warning input[type=checkbox]:checked + label::after {
  color: #fff;
}

.radio-warning input[type=radio] + label::before {
  border-color: #D77748;
}
.radio-warning input[type=radio] + label::after {
  background-color: #D77748;
}
.radio-warning input[type=radio]:checked + label::before {
  border-color: #D77748;
}
.radio-warning input[type=radio]:checked + label::after {
  background-color: #D77748;
}

.m-checkbox-inline .checkbox {
  display: inline-block;
}
.m-checkbox-inline .radio {
  display: inline-block;
}
.m-checkbox-inline label {
  margin-right: 20px;
}

.radio label {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}
.radio label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 19px;
  height: 19px;
  left: 20px;
  margin-left: -20px;
  border: 1px solid #efefef;
  border-radius: 50%;
  background-color: #fff;
  -webkit-transition: border 0.15s ease-in-out;
  transition: border 0.15s ease-in-out;
}
.radio label::after {
  display: inline-block;
  position: absolute;
  content: " ";
  width: 9px;
  height: 9px;
  left: 25px;
  top: 5px;
  margin-left: -20px;
  border-radius: 50%;
  background-color: #898989;
  -webkit-transform: scale(0, 0);
  transform: scale(0, 0);
  -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
  transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.radio input[type=radio] {
  opacity: 0;
  display: none;
}
.radio input[type=radio]:focus + label::before {
  outline-offset: -2px;
}
.radio input[type=radio]:checked + label::after {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}
.radio input[type=radio]:disabled + label {
  opacity: 0.65;
}
.radio input[type=radio]:disabled + label::before {
  cursor: not-allowed;
}
.radio .radio-inline {
  margin-top: 0;
}

/**=====================
    2.25 Radio CSS Ends
==========================**/
/**=====================
  2.26 Ribbon CSS Start
==========================**/
[class*=ribbon-wrapper] {
  position: relative;
  padding: 58px 18px 28px 18px;
}

.ribbon {
  padding: 0 20px;
  height: 30px;
  line-height: 30px;
  clear: left;
  position: absolute;
  top: 12px;
  left: 0px;
  color: #fff;
  z-index: 2;
}

.ribbon-clip {
  left: -14px;
}
.ribbon-clip:before {
  position: absolute;
  top: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-vertical-left-wrapper {
  padding-left: 40px;
  position: relative;
}

.ribbon-vertical-right-wrapper {
  padding-right: 40px;
}

.ribbon-wrapper-bottom {
  padding-bottom: 40px;
  padding-top: 0;
}

.ribbon-wrapper-right-bottom {
  padding-right: 40px;
}

.ribbon-space-bottom {
  top: 0px;
}

.ribbon-bookmark:before {
  position: absolute;
  top: 0;
  left: 100%;
  display: block;
  width: 0;
  height: 0;
  content: "";
  border: 15px solid #2c323f;
  border-right: 10px solid transparent;
}

.ribbon-right {
  left: auto;
  right: -2px;
}

.ribbon-clip-right {
  right: -14px;
}
.ribbon-clip-right:before {
  position: absolute;
  top: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom {
  left: -14px;
  bottom: 12px;
  top: auto;
}
.ribbon-clip-bottom:before {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-clip-bottom-right {
  right: -14px;
  left: auto;
  bottom: 12px;
  top: auto;
}
.ribbon-clip-bottom-right:before {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 0;
  height: 0;
  content: "";
  border: 7px solid transparent;
  border-top-color: #2c323f;
  border-right-color: #2c323f;
}

.ribbon-bookmark.ribbon-right:before {
  right: 100%;
  left: auto;
  border-right: 15px solid #2c323f;
  border-left: 10px solid transparent;
}

.ribbon-vertical-left {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  left: 12px;
  top: -2px;
}

.ribbon-vertical-right {
  clear: none;
  padding: 0 5px;
  height: 70px;
  width: 30px;
  line-height: 70px;
  text-align: center;
  top: -2px;
  left: auto;
  right: 20px;
}
[dir=rtl] .ribbon-vertical-right {
  left: auto !important;
  right: 20px !important;
}

.ribbon-bookmark.ribbon-vertical-left:before, .ribbon-bookmark.ribbon-vertical-right:before {
  top: 100%;
  left: 0;
  margin-top: -14px;
  border-right: 15px solid #2c323f;
  border-bottom: 10px solid transparent;
}

.ribbon-primary {
  background: #337ab7;
}

.ribbon-primary {
  background: #337ab7;
}

.ribbon-bookmark.ribbon-primary:before {
  border-color: #337ab7;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-primary:before {
  border-right-color: #337ab7;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-primary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-primary:before {
  border-right-color: #337ab7;
  border-bottom-color: transparent;
}

.ribbon-primary.ribbon-corner:before {
  border-top-color: #337ab7;
  border-left-color: #337ab7;
}
.ribbon-primary.ribbon-corner.ribbon-right:before {
  border-right-color: #337ab7;
  border-left-color: transparent;
}
.ribbon-primary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #337ab7;
}

.ribbon-primary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #5c50a7;
  border-right-color: #5c50a7;
}

.ribbon-primary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #5c50a7;
  border-left-color: #5c50a7;
}

.ribbon-primary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #5c50a7;
  border-right-color: #5c50a7;
}

.ribbon-primary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #5c50a7;
  border-right-color: transparent;
  border-left-color: #5c50a7;
}

.ribbon-secondary {
  background: #48A3D7;
}

.ribbon-secondary {
  background: #48A3D7;
}

.ribbon-bookmark.ribbon-secondary:before {
  border-color: #48A3D7;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-secondary:before {
  border-right-color: #48A3D7;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-secondary:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-secondary:before {
  border-right-color: #48A3D7;
  border-bottom-color: transparent;
}

.ribbon-secondary.ribbon-corner:before {
  border-top-color: #48A3D7;
  border-left-color: #48A3D7;
}
.ribbon-secondary.ribbon-corner.ribbon-right:before {
  border-right-color: #48A3D7;
  border-left-color: transparent;
}
.ribbon-secondary.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #48A3D7;
}

.ribbon-secondary.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #2a8bc2;
  border-right-color: #2a8bc2;
}

.ribbon-secondary.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #2a8bc2;
  border-left-color: #2a8bc2;
}

.ribbon-secondary.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2a8bc2;
  border-right-color: #2a8bc2;
}

.ribbon-secondary.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #2a8bc2;
  border-right-color: transparent;
  border-left-color: #2a8bc2;
}

.ribbon-success {
  background: #0DA759;
}

.ribbon-success {
  background: #0DA759;
}

.ribbon-bookmark.ribbon-success:before {
  border-color: #0DA759;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-success:before {
  border-right-color: #0DA759;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-success:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-success:before {
  border-right-color: #0DA759;
  border-bottom-color: transparent;
}

.ribbon-success.ribbon-corner:before {
  border-top-color: #0DA759;
  border-left-color: #0DA759;
}
.ribbon-success.ribbon-corner.ribbon-right:before {
  border-right-color: #0DA759;
  border-left-color: transparent;
}
.ribbon-success.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #0DA759;
}

.ribbon-success.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #097840;
  border-right-color: #097840;
}

.ribbon-success.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #097840;
  border-left-color: #097840;
}

.ribbon-success.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #097840;
  border-right-color: #097840;
}

.ribbon-success.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #097840;
  border-right-color: transparent;
  border-left-color: #097840;
}

.ribbon-danger {
  background: #E44141;
}

.ribbon-danger {
  background: #E44141;
}

.ribbon-bookmark.ribbon-danger:before {
  border-color: #E44141;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-danger:before {
  border-right-color: #E44141;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-danger:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-danger:before {
  border-right-color: #E44141;
  border-bottom-color: transparent;
}

.ribbon-danger.ribbon-corner:before {
  border-top-color: #E44141;
  border-left-color: #E44141;
}
.ribbon-danger.ribbon-corner.ribbon-right:before {
  border-right-color: #E44141;
  border-left-color: transparent;
}
.ribbon-danger.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #E44141;
}

.ribbon-danger.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #d41e1e;
  border-right-color: #d41e1e;
}

.ribbon-danger.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #d41e1e;
  border-left-color: #d41e1e;
}

.ribbon-danger.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #d41e1e;
  border-right-color: #d41e1e;
}

.ribbon-danger.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #d41e1e;
  border-right-color: transparent;
  border-left-color: #d41e1e;
}

.ribbon-info {
  background: #16C7F9;
}

.ribbon-info {
  background: #16C7F9;
}

.ribbon-bookmark.ribbon-info:before {
  border-color: #16C7F9;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-info:before {
  border-right-color: #16C7F9;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-info:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-right-color: #16C7F9;
  border-bottom-color: transparent;
}

.ribbon-info.ribbon-corner:before {
  border-top-color: #16C7F9;
  border-left-color: #16C7F9;
}
.ribbon-info.ribbon-corner.ribbon-right:before {
  border-right-color: #16C7F9;
  border-left-color: transparent;
}
.ribbon-info.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #16C7F9;
}

.ribbon-info.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #06a8d6;
  border-right-color: #06a8d6;
}

.ribbon-info.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #06a8d6;
  border-left-color: #06a8d6;
}

.ribbon-info.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #06a8d6;
  border-right-color: #06a8d6;
}

.ribbon-info.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #06a8d6;
  border-right-color: transparent;
  border-left-color: #06a8d6;
}

.ribbon-light {
  background: #f4f4f4;
}

.ribbon-light {
  background: #f4f4f4;
}

.ribbon-bookmark.ribbon-light:before {
  border-color: #f4f4f4;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-light:before {
  border-right-color: #f4f4f4;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-light:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-light:before {
  border-right-color: #f4f4f4;
  border-bottom-color: transparent;
}

.ribbon-light.ribbon-corner:before {
  border-top-color: #f4f4f4;
  border-left-color: #f4f4f4;
}
.ribbon-light.ribbon-corner.ribbon-right:before {
  border-right-color: #f4f4f4;
  border-left-color: transparent;
}
.ribbon-light.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #f4f4f4;
}

.ribbon-light.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #dbdbdb;
  border-right-color: #dbdbdb;
}

.ribbon-light.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #dbdbdb;
  border-left-color: #dbdbdb;
}

.ribbon-light.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #dbdbdb;
  border-right-color: #dbdbdb;
}

.ribbon-light.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #dbdbdb;
  border-right-color: transparent;
  border-left-color: #dbdbdb;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-dark {
  background: #2c323f;
}

.ribbon-bookmark.ribbon-dark:before {
  border-color: #2c323f;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-dark:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-dark:before {
  border-right-color: #2c323f;
  border-bottom-color: transparent;
}

.ribbon-dark.ribbon-corner:before {
  border-top-color: #2c323f;
  border-left-color: #2c323f;
}
.ribbon-dark.ribbon-corner.ribbon-right:before {
  border-right-color: #2c323f;
  border-left-color: transparent;
}
.ribbon-dark.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #2c323f;
}

.ribbon-dark.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #171a21;
  border-left-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: #171a21;
}

.ribbon-dark.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #171a21;
  border-right-color: transparent;
  border-left-color: #171a21;
}

.ribbon-warning {
  background: #D77748;
}

.ribbon-warning {
  background: #D77748;
}

.ribbon-bookmark.ribbon-warning:before {
  border-color: #D77748;
  border-right-color: transparent;
}
.ribbon-bookmark.ribbon-right.ribbon-warning:before {
  border-right-color: #D77748;
  border-left-color: transparent;
}
.ribbon-bookmark.ribbon-vertical-left.ribbon-warning:before, .ribbon-bookmark.ribbon-vertical-right.ribbon-warning:before {
  border-right-color: #D77748;
  border-bottom-color: transparent;
}

.ribbon-warning.ribbon-corner:before {
  border-top-color: #D77748;
  border-left-color: #D77748;
}
.ribbon-warning.ribbon-corner.ribbon-right:before {
  border-right-color: #D77748;
  border-left-color: transparent;
}
.ribbon-warning.ribbon-corner.ribbon-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #D77748;
}

.ribbon-warning.ribbon-clip:before {
  border-color: transparent;
  border-top-color: #c25c2a;
  border-right-color: #c25c2a;
}

.ribbon-warning.ribbon-clip-right:before {
  border-right-color: transparent;
  border-top-color: #c25c2a;
  border-left-color: #c25c2a;
}

.ribbon-warning.ribbon-clip-bottom:before {
  border-top-color: transparent;
  border-bottom-color: #c25c2a;
  border-right-color: #c25c2a;
}

.ribbon-warning.ribbon-clip-bottom-right:before {
  border-top-color: transparent;
  border-bottom-color: #c25c2a;
  border-right-color: transparent;
  border-left-color: #c25c2a;
}

.vertical-lp-space {
  padding: 58px 28px 28px 50px;
}

.vertical-rp-space {
  padding: 58px 50px 28px 28px;
}

.clip-bp-space {
  padding: 28px 18px 58px 18px;
}

/**=====================
  2.26 Ribbon CSS Ends
==========================**/
/**=====================
   2.27 Range-slider CSS Start
==========================**/
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single,
.irs--flat .irs-bar {
  background-color: #337ab7;
}
.irs--flat .irs-from::before,
.irs--flat .irs-to::before,
.irs--flat .irs-single::before,
.irs--flat .irs-bar::before {
  border-top-color: #337ab7;
}
.irs--flat .irs-handle i:first-child {
  background-color: #337ab7;
}

.irs--round .irs-single {
  background-color: #48A3D7;
}
.irs--round .irs-single::before {
  border-top-color: #48A3D7;
}
.irs--round .irs-handle {
  border: 4px solid #48A3D7;
}
.irs--round .irs-bar {
  background-color: #48A3D7;
}

.irs--square .irs-handle {
  border: 3px solid #0DA759;
}
.irs--square .irs-from,
.irs--square .irs-to,
.irs--square .irs-bar {
  background-color: #0DA759;
}

.irs--sharp .irs-from::before,
.irs--sharp .irs-to::before {
  border-top-color: #16C7F9;
}
.irs--sharp .irs-min,
.irs--sharp .irs-max,
.irs--sharp .irs-from,
.irs--sharp .irs-to,
.irs--sharp .irs-bar {
  background-color: #16C7F9;
}
.irs--sharp .irs-line {
  background-color: rgba(106, 113, 133, 0.3);
}
.irs--sharp .irs-handle {
  background-color: #16C7F9;
}
.irs--sharp .irs-handle i:first-child {
  border-top-color: #16C7F9;
}

.irs--big .irs-bar {
  border: 1px solid #D77748;
  background: #D77748;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.irs--big .irs-from,
.irs--big .irs-to {
  background-color: #D77748;
  background: unset;
}
.irs--big .irs-handle {
  background: #D77748;
}
.irs--big .irs-grid-pol {
  background-color: #D77748;
}
.irs--big .irs-grid-text {
  color: #D77748;
}

.irs--modern .irs-single {
  background-color: #E44141;
}
.irs--modern .irs-single::before {
  border-top-color: #E44141;
}
.irs--modern .irs-bar {
  background: #E44141;
}
.irs--modern .irs-grid-text {
  color: #E44141;
}

/**=====================
    2.27 Range-slider CSS Ends
==========================**/
/**=====================
    2.28 Switch CSS Start
==========================**/
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch input {
  display: none;
}
.switch input:checked + .switch-state {
  background-color: var(--theme-deafult);
}
.switch input:checked + .switch-state:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}
.switch input:focus + .switch-state {
  -webkit-box-shadow: 0 0 1px var(--theme-deafult);
          box-shadow: 0 0 1px var(--theme-deafult);
}

.switch-state {
  border-radius: 20px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f4f4f4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-state:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 20px;
}

.icon-state .switch-state:after {
  content: "\efb8";
  font-family: IcoFont;
  left: 10px;
  position: absolute;
  top: 10px;
  color: #2c323f;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  opacity: 0.3;
}
.icon-state input:checked + .switch-state:after {
  content: "\efad";
  left: 36px;
  opacity: 1;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch-lg .switch {
  width: 75px;
  height: 40px;
  padding: 0;
  margin: 0;
}
.switch-lg .switch .switch-state:before {
  height: 30px;
  width: 30px;
  left: 7px;
  bottom: 5px;
  top: 4px;
}

.switch-lg.icon-state .switch .switch-state:after {
  left: 12px;
  font-size: 18px;
}
.switch-lg.icon-state .switch input:checked + .switch-state:after {
  left: 47px;
}
.switch-lg.icon-state .switch input:checked + .switch-state:before {
  -webkit-transform: translateX(32px);
          transform: translateX(32px);
}

.switch-sm.icon-state .switch .switch-state:after {
  top: 4px;
  left: 4px;
  font-size: 8px;
}
.switch-sm.icon-state .switch input:checked + .switch-state:after {
  left: 13px;
  top: 4px;
}
.switch-sm .switch {
  width: 25px;
  height: 16px;
  margin-top: 10px;
  margin-bottom: 0px;
}
.switch-sm .switch .switch-state:before {
  height: 8px;
  width: 8px;
  left: 4px;
  bottom: 4px;
}
.switch-sm input:checked + .switch-state:before {
  left: -13px;
}

.switch-outline .switch-state {
  background-color: #fff;
  border: 1px solid var(--theme-deafult);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.switch-outline .switch-state:before {
  background-color: #f4f4f4;
  bottom: 3px;
  color: #fff;
}

.switch-outline .switch input {
  display: none;
}
.switch-outline .switch input:checked + .switch-state {
  background-color: transparent;
  border: 1px solid var(--theme-deafult);
}
.switch-outline .switch input:checked + .switch-state:before {
  background-color: var(--theme-deafult);
  bottom: 3px;
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}
.switch-outline .switch input:checked + .switch-state:after {
  color: #fff;
}

.switch-outline .switch-state.bg-primary {
  border: 1px solid var(--theme-deafult);
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid var(--theme-deafult);
}
.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: var(--theme-deafult);
}
.switch-outline .switch-state.bg-dark {
  border: 1px solid #2c323f;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-dark {
  background-color: transparent !important;
  border: 1px solid #2c323f;
}
.switch-outline .switch input:checked + .switch-state.bg-dark:before {
  background-color: #2c323f;
}
.switch-outline .switch-state.bg-warning {
  border: 1px solid #D77748;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-warning {
  background-color: transparent !important;
  border: 1px solid #D77748;
}
.switch-outline .switch input:checked + .switch-state.bg-warning:before {
  background-color: #D77748;
}
.switch-outline .switch-state.bg-success {
  border: 1px solid #0DA759;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-success {
  background-color: transparent !important;
  border: 1px solid #0DA759;
}
.switch-outline .switch input:checked + .switch-state.bg-success:before {
  background-color: #0DA759;
}
.switch-outline .switch-state.bg-info {
  border: 1px solid #16C7F9;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-info {
  background-color: transparent !important;
  border: 1px solid #16C7F9;
}
.switch-outline .switch input:checked + .switch-state.bg-info:before {
  background-color: #16C7F9;
}
.switch-outline .switch-state.bg-danger {
  border: 1px solid #E44141;
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-danger {
  background-color: transparent !important;
  border: 1px solid #E44141;
}
.switch-outline .switch input:checked + .switch-state.bg-danger:before {
  background-color: #E44141;
}
.switch-outline .switch-state.bg-primary {
  border: 1px solid var(--theme-deafult);
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-primary {
  background-color: transparent !important;
  border: 1px solid var(--theme-deafult);
}
.switch-outline .switch input:checked + .switch-state.bg-primary:before {
  background-color: var(--theme-deafult);
}
.switch-outline .switch-state.bg-secondary {
  border: 1px solid var(--theme-secondary);
  background-color: transparent !important;
}
.switch-outline .switch input:checked + .switch-state.bg-secondary {
  background-color: transparent !important;
  border: 1px solid var(--theme-secondary);
}
.switch-outline .switch input:checked + .switch-state.bg-secondary:before {
  background-color: var(--theme-secondary);
}

.switch-showcase {
  margin-bottom: -8px;
}
.switch-showcase .d-flex {
  line-height: 1;
}
.switch-showcase .form-check .form-check-input {
  float: right;
}

.form-switch .form-check-input:checked {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
}

.tg-list {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.tgl {
  display: none;
}
.tgl, .tgl:after, .tgl:before, .tgl *, .tgl *:after, .tgl *:before, .tgl + .tgl-btn {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.tgl::-moz-selection, .tgl:after::-moz-selection, .tgl:before::-moz-selection, .tgl *::-moz-selection, .tgl *:after::-moz-selection, .tgl *:before::-moz-selection, .tgl + .tgl-btn::-moz-selection {
  background: none;
}
.tgl::selection, .tgl:after::selection, .tgl:before::selection, .tgl *::selection, .tgl *:after::selection, .tgl *:before::selection, .tgl + .tgl-btn::selection {
  background: none;
}
.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 4em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.tgl + .tgl-btn:after, .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-skewed + .tgl-btn {
  overflow: hidden;
  -webkit-transform: skew(-10deg);
          transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #48A3D7;
}
.tgl-skewed + .tgl-btn:after, .tgl-skewed + .tgl-btn:before {
  -webkit-transform: skew(10deg);
          transform: skew(10deg);
  display: inline-block;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: var(--white);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
}
.tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}
.tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}
.tgl-skewed + .tgl-btn:active {
  background: #48A3D7;
}
.tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}
.tgl-skewed:checked + .tgl-btn {
  background: #337ab7;
}
.tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}
.tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}
.tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}

.tgl-flip + .tgl-btn {
  padding: 2px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-perspective: 100px;
          perspective: 100px;
}
.tgl-flip + .tgl-btn:after, .tgl-flip + .tgl-btn:before {
  display: inline-block;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 4px;
}
.tgl-flip + .tgl-btn:after {
  content: attr(data-tg-on);
  background: #D77748;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}
.tgl-flip + .tgl-btn:before {
  background: #E44141;
  content: attr(data-tg-off);
}
.tgl-flip + .tgl-btn:active:before {
  -webkit-transform: rotateY(-20deg);
          transform: rotateY(-20deg);
}
.tgl-flip:checked + .tgl-btn:before {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.tgl-flip:checked + .tgl-btn:after {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
  left: 0;
  background: #0DA759;
}
.tgl-flip:checked + .tgl-btn:active:after {
  -webkit-transform: rotateY(20deg);
          transform: rotateY(20deg);
}

.radius-none .form-check-input {
  border-radius: 3px;
}

.tg-list .tg-list-item .square-checked .switch-state::before {
  content: "";
  border-radius: 0;
}

.switch-wrapper .d-flex {
  margin-right: 20px;
}
.switch-wrapper .d-flex .col-form-label {
  padding-bottom: 0;
}
.switch-wrapper .tg-list p {
  margin: 0 8px 0 0;
}

.default-square .form-switch .form-check-input {
  background-image: url(../../images/square-gray.png);
}
.default-square .form-switch .form-check-input:checked {
  background-image: url(../../images/square.svg);
}

/**=====================
    2.28 Switch CSS Ends
==========================**/
/**=====================
  2.29 Tab CSS Start
==========================**/
.nav-pills .nav-link { /*here settings nav*/
  font-weight: 600;
  background-color: #eee;
  border-radius: 8px;
  
}
.nav-pills .nav-link h6 {
  font-size: 15px;
}

.nav-pills .nav-link:hover {
  background-color: #ddd;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--theme-deafult);
}
.nav-pills .nav-link.active .horizontal-wizard .horizontal-wizard-content h6,
.nav-pills .show > .nav-link .horizontal-wizard .horizontal-wizard-content h6 {
  color: #fff;
  
}
.nav-pills .nav-link.active .horizontal-wizard .business-wizard h6,
.nav-pills .show > .nav-link .horizontal-wizard .business-wizard h6 {
  background-color: var(--theme-deafult);
}

.business-wizard h6 {
  height: 30px;
  width: 130px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 6px;
  border-radius: 6px;
}

.nav i {
  margin-right: 10px;
}

.nav-right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.nav-theme .nav-link,
.nav-pills.nav-theme .nav-link {
  color: var(--theme-deafult);
}
.nav-theme .nav-link.active,
.nav-theme .show > .nav-link,
.nav-pills.nav-theme .nav-link.active,
.nav-pills.nav-theme .show > .nav-link {
  background-color: var(--theme-deafult);
  color: #fff;
}

.nav-primary .nav-link,
.nav-pills.nav-primary .nav-link {
  color: var(--theme-deafult);
}
.nav-primary .nav-link.active,
.nav-primary .show > .nav-link,
.nav-pills.nav-primary .nav-link.active,
.nav-pills.nav-primary .show > .nav-link {
  background-color: var(--theme-deafult);
  color: #fff;
}

.nav-secondary .nav-link,
.nav-pills.nav-secondary .nav-link {
  color: #48A3D7;
}
.nav-secondary .nav-link.active,
.nav-secondary .show > .nav-link,
.nav-pills.nav-secondary .nav-link.active,
.nav-pills.nav-secondary .show > .nav-link {
  background-color: #48A3D7;
  color: #fff;
  
}

.nav-success .nav-link,
.nav-pills.nav-success .nav-link {
  color: #0DA759;
}
.nav-success .nav-link.active,
.nav-success .show > .nav-link,
.nav-pills.nav-success .nav-link.active,
.nav-pills.nav-success .show > .nav-link {
  background-color: #0DA759;
  color: #fff;
}

.nav-danger .nav-link,
.nav-pills.nav-danger .nav-link {
  color: #E44141;
}
.nav-danger .nav-link.active,
.nav-danger .show > .nav-link,
.nav-pills.nav-danger .nav-link.active,
.nav-pills.nav-danger .show > .nav-link {
  background-color: #E44141;
  color: #fff;
}

.nav-info .nav-link,
.nav-pills.nav-info .nav-link {
  color: #C95E9E;
}
.nav-info .nav-link.active,
.nav-info .show > .nav-link,
.nav-pills.nav-info .nav-link.active,
.nav-pills.nav-info .show > .nav-link {
  background-color: #C95E9E;
  color: #fff;
}

.nav-tertiary .nav-link,
.nav-pills.nav-tertiary .nav-link {
  color: #16C7F9;
}
.nav-tertiary .nav-link.active,
.nav-tertiary .show > .nav-link,
.nav-pills.nav-tertiary .nav-link.active,
.nav-pills.nav-tertiary .show > .nav-link {
  background-color: #16C7F9;
  color: #fff;
}

.nav-light .nav-link,
.nav-pills.nav-light .nav-link {
  color: #f4f4f4;
}
.nav-light .nav-link.active,
.nav-light .show > .nav-link,
.nav-pills.nav-light .nav-link.active,
.nav-pills.nav-light .show > .nav-link {
  background-color: #f4f4f4;
  color: #fff;
}

.nav-dark .nav-link,
.nav-pills.nav-dark .nav-link {
  color: #2c323f;
}
.nav-dark .nav-link.active,
.nav-dark .show > .nav-link,
.nav-pills.nav-dark .nav-link.active,
.nav-pills.nav-dark .show > .nav-link {
  background-color: #2c323f;
  color: #fff;
}

.nav-warning .nav-link,
.nav-pills.nav-warning .nav-link {
  color: #D77748;
}
.nav-warning .nav-link.active,
.nav-warning .show > .nav-link,
.nav-pills.nav-warning .nav-link.active,
.nav-pills.nav-warning .show > .nav-link {
  background-color: #D77748;
  color: #fff;
}

.nav-tabs.nav-bottom .nav-item .nav-link.active,
.nav-tabs.nav-bottom .nav-item .nav-link.focus,
.nav-tabs.nav-bottom .nav-item .nav-link.hover {
  border-color: #fff #dee2e6 #dee2e6;
}

.tab-content ~ .nav-tabs.border-tab {
  margin-bottom: 0;
  margin-top: 30px;
}

.border-tab.nav-tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  margin-bottom: 30px;
}
.border-tab.nav-tabs .nav-item .nav-link {
  font-size: 16px;
  color: #2c323f;
  text-align: center;
  padding: 10px 25px;
  border: none;
  border-bottom: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
@media (min-width: 576px) and (max-width: 1920px) {
  .border-tab.nav-tabs .nav-item .nav-link {
    padding-top: 0;
  }
}
.border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item .nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus, .border-tab.nav-tabs .nav-item .nav-link:hover {
  color: var(--theme-deafult);
}
.border-tab.nav-tabs .nav-item .nav-link.active, .border-tab.nav-tabs .nav-item .nav-link.show, .border-tab.nav-tabs .nav-item .nav-link:focus {
  border-bottom: none;
}

.border-tab.nav-left .nav-link {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #2c323f;
}
.border-tab.nav-left .nav-link.active,
.border-tab.nav-left .show > .nav-link {
  background-color: transparent;
  border-left-color: var(--theme-deafult);
  color: var(--theme-deafult);
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.border-tab.nav-right .nav-link {
  border-right: 2px solid transparent;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  color: #2c323f;
  text-align: right;
}
.border-tab.nav-right .nav-link.active,
.border-tab.nav-right .show > .nav-link {
  background-color: transparent;
  border-right-color: var(--theme-deafult);
  color: var(--theme-deafult);
  border-radius: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.border-tab.nav-theme .nav-link.active,
.border-tab.nav-theme .nav-item.show,
.border-tab.nav-theme .nav-link:focus,
.border-tab.nav-theme .nav-link:hover {
  color: var(--theme-deafult);
  background-color: transparent;
}
.border-tab.nav-theme .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-theme .nav-item .nav-link.active, .border-tab.nav-theme .nav-item .nav-link.show, .border-tab.nav-theme .nav-item .nav-link:focus {
  border-bottom: 2px solid var(--theme-deafult);
}
.border-tab.nav-theme .nav-item .nav-link.active, .border-tab.nav-theme .nav-item .nav-link.show, .border-tab.nav-theme .nav-item .nav-link:focus, .border-tab.nav-theme .nav-item .nav-link:hover {
  color: var(--theme-deafult);
}

.border-tab.nav-left.nav-theme .nav-link.active,
.border-tab.nav-left.nav-theme .show > .nav-link {
  border-left-color: var(--theme-deafult);
  color: var(--theme-deafult);
}

.border-tab.nav-right.nav-theme .nav-link.active,
.border-tab.nav-right.nav-theme .show > .nav-link {
  border-right-color: var(--theme-deafult);
  color: var(--theme-deafult);
}

.border-tab.nav-primary .nav-link.active,
.border-tab.nav-primary .nav-item.show,
.border-tab.nav-primary .nav-link:focus,
.border-tab.nav-primary .nav-link:hover {
  color: var(--theme-deafult);
  background-color: transparent;
}
.border-tab.nav-primary .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus {
  border-bottom: 2px solid var(--theme-deafult);
}
.border-tab.nav-primary .nav-item .nav-link.active, .border-tab.nav-primary .nav-item .nav-link.show, .border-tab.nav-primary .nav-item .nav-link:focus, .border-tab.nav-primary .nav-item .nav-link:hover {
  color: var(--theme-deafult);
}

.border-tab.nav-left.nav-primary .nav-link.active,
.border-tab.nav-left.nav-primary .show > .nav-link {
  border-left-color: var(--theme-deafult);
  color: var(--theme-deafult);
}

.border-tab.nav-right.nav-primary .nav-link.active,
.border-tab.nav-right.nav-primary .show > .nav-link {
  border-right-color: var(--theme-deafult);
  color: var(--theme-deafult);
}

.border-tab.nav-secondary .nav-link.active,
.border-tab.nav-secondary .nav-item.show,
.border-tab.nav-secondary .nav-link:focus,
.border-tab.nav-secondary .nav-link:hover {
  color: #48A3D7;
  background-color: transparent;
}
.border-tab.nav-secondary .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-secondary .nav-item .nav-link.active, .border-tab.nav-secondary .nav-item .nav-link.show, .border-tab.nav-secondary .nav-item .nav-link:focus {
  border-bottom: 2px solid #48A3D7;
}
.border-tab.nav-secondary .nav-item .nav-link.active, .border-tab.nav-secondary .nav-item .nav-link.show, .border-tab.nav-secondary .nav-item .nav-link:focus, .border-tab.nav-secondary .nav-item .nav-link:hover {
  color: #48A3D7;
}

.border-tab.nav-left.nav-secondary .nav-link.active,
.border-tab.nav-left.nav-secondary .show > .nav-link {
  border-left-color: #48A3D7;
  color: #48A3D7;
}

.border-tab.nav-right.nav-secondary .nav-link.active,
.border-tab.nav-right.nav-secondary .show > .nav-link {
  border-right-color: #48A3D7;
  color: #48A3D7;
}

.border-tab.nav-success .nav-link.active,
.border-tab.nav-success .nav-item.show,
.border-tab.nav-success .nav-link:focus,
.border-tab.nav-success .nav-link:hover {
  color: #0DA759;
  background-color: transparent;
}
.border-tab.nav-success .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-success .nav-item .nav-link.active, .border-tab.nav-success .nav-item .nav-link.show, .border-tab.nav-success .nav-item .nav-link:focus {
  border-bottom: 2px solid #0DA759;
}
.border-tab.nav-success .nav-item .nav-link.active, .border-tab.nav-success .nav-item .nav-link.show, .border-tab.nav-success .nav-item .nav-link:focus, .border-tab.nav-success .nav-item .nav-link:hover {
  color: #0DA759;
}

.border-tab.nav-left.nav-success .nav-link.active,
.border-tab.nav-left.nav-success .show > .nav-link {
  border-left-color: #0DA759;
  color: #0DA759;
}

.border-tab.nav-right.nav-success .nav-link.active,
.border-tab.nav-right.nav-success .show > .nav-link {
  border-right-color: #0DA759;
  color: #0DA759;
}

.border-tab.nav-danger .nav-link.active,
.border-tab.nav-danger .nav-item.show,
.border-tab.nav-danger .nav-link:focus,
.border-tab.nav-danger .nav-link:hover {
  color: #E44141;
  background-color: transparent;
}
.border-tab.nav-danger .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-danger .nav-item .nav-link.active, .border-tab.nav-danger .nav-item .nav-link.show, .border-tab.nav-danger .nav-item .nav-link:focus {
  border-bottom: 2px solid #E44141;
}
.border-tab.nav-danger .nav-item .nav-link.active, .border-tab.nav-danger .nav-item .nav-link.show, .border-tab.nav-danger .nav-item .nav-link:focus, .border-tab.nav-danger .nav-item .nav-link:hover {
  color: #E44141;
}

.border-tab.nav-left.nav-danger .nav-link.active,
.border-tab.nav-left.nav-danger .show > .nav-link {
  border-left-color: #E44141;
  color: #E44141;
}

.border-tab.nav-right.nav-danger .nav-link.active,
.border-tab.nav-right.nav-danger .show > .nav-link {
  border-right-color: #E44141;
  color: #E44141;
}

.border-tab.nav-info .nav-link.active,
.border-tab.nav-info .nav-item.show,
.border-tab.nav-info .nav-link:focus,
.border-tab.nav-info .nav-link:hover {
  color: #C95E9E;
  background-color: transparent;
}
.border-tab.nav-info .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-info .nav-item .nav-link.active, .border-tab.nav-info .nav-item .nav-link.show, .border-tab.nav-info .nav-item .nav-link:focus {
  border-bottom: 2px solid #C95E9E;
}
.border-tab.nav-info .nav-item .nav-link.active, .border-tab.nav-info .nav-item .nav-link.show, .border-tab.nav-info .nav-item .nav-link:focus, .border-tab.nav-info .nav-item .nav-link:hover {
  color: #C95E9E;
}

.border-tab.nav-left.nav-info .nav-link.active,
.border-tab.nav-left.nav-info .show > .nav-link {
  border-left-color: #C95E9E;
  color: #C95E9E;
}

.border-tab.nav-right.nav-info .nav-link.active,
.border-tab.nav-right.nav-info .show > .nav-link {
  border-right-color: #C95E9E;
  color: #C95E9E;
}

.border-tab.nav-tertiary .nav-link.active,
.border-tab.nav-tertiary .nav-item.show,
.border-tab.nav-tertiary .nav-link:focus,
.border-tab.nav-tertiary .nav-link:hover {
  color: #16C7F9;
  background-color: transparent;
}
.border-tab.nav-tertiary .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-tertiary .nav-item .nav-link.active, .border-tab.nav-tertiary .nav-item .nav-link.show, .border-tab.nav-tertiary .nav-item .nav-link:focus {
  border-bottom: 2px solid #16C7F9;
}
.border-tab.nav-tertiary .nav-item .nav-link.active, .border-tab.nav-tertiary .nav-item .nav-link.show, .border-tab.nav-tertiary .nav-item .nav-link:focus, .border-tab.nav-tertiary .nav-item .nav-link:hover {
  color: #16C7F9;
}

.border-tab.nav-left.nav-tertiary .nav-link.active,
.border-tab.nav-left.nav-tertiary .show > .nav-link {
  border-left-color: #16C7F9;
  color: #16C7F9;
}

.border-tab.nav-right.nav-tertiary .nav-link.active,
.border-tab.nav-right.nav-tertiary .show > .nav-link {
  border-right-color: #16C7F9;
  color: #16C7F9;
}

.border-tab.nav-light .nav-link.active,
.border-tab.nav-light .nav-item.show,
.border-tab.nav-light .nav-link:focus,
.border-tab.nav-light .nav-link:hover {
  color: #f4f4f4;
  background-color: transparent;
}
.border-tab.nav-light .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-light .nav-item .nav-link.active, .border-tab.nav-light .nav-item .nav-link.show, .border-tab.nav-light .nav-item .nav-link:focus {
  border-bottom: 2px solid #f4f4f4;
}
.border-tab.nav-light .nav-item .nav-link.active, .border-tab.nav-light .nav-item .nav-link.show, .border-tab.nav-light .nav-item .nav-link:focus, .border-tab.nav-light .nav-item .nav-link:hover {
  color: #f4f4f4;
}

.border-tab.nav-left.nav-light .nav-link.active,
.border-tab.nav-left.nav-light .show > .nav-link {
  border-left-color: #f4f4f4;
  color: #f4f4f4;
}

.border-tab.nav-right.nav-light .nav-link.active,
.border-tab.nav-right.nav-light .show > .nav-link {
  border-right-color: #f4f4f4;
  color: #f4f4f4;
}

.border-tab.nav-dark .nav-link.active,
.border-tab.nav-dark .nav-item.show,
.border-tab.nav-dark .nav-link:focus,
.border-tab.nav-dark .nav-link:hover {
  color: #2c323f;
  background-color: transparent;
}
.border-tab.nav-dark .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-dark .nav-item .nav-link.active, .border-tab.nav-dark .nav-item .nav-link.show, .border-tab.nav-dark .nav-item .nav-link:focus {
  border-bottom: 2px solid #2c323f;
}
.border-tab.nav-dark .nav-item .nav-link.active, .border-tab.nav-dark .nav-item .nav-link.show, .border-tab.nav-dark .nav-item .nav-link:focus, .border-tab.nav-dark .nav-item .nav-link:hover {
  color: #2c323f;
}

.border-tab.nav-left.nav-dark .nav-link.active,
.border-tab.nav-left.nav-dark .show > .nav-link {
  border-left-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-right.nav-dark .nav-link.active,
.border-tab.nav-right.nav-dark .show > .nav-link {
  border-right-color: #2c323f;
  color: #2c323f;
}

.border-tab.nav-warning .nav-link.active,
.border-tab.nav-warning .nav-item.show,
.border-tab.nav-warning .nav-link:focus,
.border-tab.nav-warning .nav-link:hover {
  color: #D77748;
  background-color: transparent;
}
.border-tab.nav-warning .nav-item .nav-link {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.border-tab.nav-warning .nav-item .nav-link.active, .border-tab.nav-warning .nav-item .nav-link.show, .border-tab.nav-warning .nav-item .nav-link:focus {
  border-bottom: 2px solid #D77748;
}
.border-tab.nav-warning .nav-item .nav-link.active, .border-tab.nav-warning .nav-item .nav-link.show, .border-tab.nav-warning .nav-item .nav-link:focus, .border-tab.nav-warning .nav-item .nav-link:hover {
  color: #D77748;
}

.border-tab.nav-left.nav-warning .nav-link.active,
.border-tab.nav-left.nav-warning .show > .nav-link {
  border-left-color: #D77748;
  color: #D77748;
}

.border-tab.nav-right.nav-warning .nav-link.active,
.border-tab.nav-right.nav-warning .show > .nav-link {
  border-right-color: #D77748;
  color: #D77748;
}

.tabbed-card ul {
  position: absolute;
  right: 22px;
  top: 23px;
  border-bottom: none;
}
.tabbed-card ul.border-tab.nav-tabs {
  top: 12px;
}
.tabbed-card ul.border-tab.nav-tabs .nav-item {
  float: right;
}
.tabbed-card ul.border-tab.nav-tabs .nav-item .nav-link {
  padding: 23px 15px;
}

.flex-space {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 16px;
}
@media (max-width: 576px) {
  .flex-space {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

.tab-img {
  height: 100px;
}

.nav-item .navlink-title {
  background-color: unset;
  padding: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.simple-wrapper .dropdown-collection .dropdown-item:hover, .simple-wrapper .dropdown-collection .dropdown-item:active {
  background-color: rgba(106, 113, 133, 0.3) !important;
}

.blog-wrapper .blog-img {
  width: 100px;
  height: 100px;
}

.designer-details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 1400px) {
  .designer-details {
    gap: calc(14px + 21 * (100vw - 320px) / 1080);
  }
}
.designer-details .designer-profile {
  display: inline-block;
}
.designer-details .designer-profile .designer-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
}
.designer-details .designer-profile .designer-wrap .designer-img {
  height: 50px;
  border-radius: 100%;
}
.designer-details .designer-profile .designer-wrap .designer-content h6 {
  font-weight: 500;
}
.designer-details .designer-profile .designer-wrap .designer-content p {
  color: #999999;
}

.nav-tabs .nav-border {
  color: #000;
  border: none;
}
.nav-tabs .nav-link {
  font-weight: 600;
}

.ico-color {
  color: #D77748;
  font-size: 16px;
}

.main-inbox .header-inbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #999999;
  padding-bottom: 8px;
}
.main-inbox .header-inbox .header-left-inbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.main-inbox .header-inbox .header-left-inbox .inbox-img img {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}
.main-inbox .header-inbox .header-right-inbox {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
}
@media (max-width: 560px) {
  .main-inbox .header-inbox .header-right-inbox {
    display: none;
  }
}
.main-inbox .body-inbox .body-h-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 6px;
  border-bottom: 1px solid var(--light-border);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 630px) {
  .main-inbox .body-inbox .body-h-wrapper {
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }
}

.tab-space {
  font-size: 14px;
}

#bottom-tab .nav-border.tab-primary:hover {
  color: #337ab7;
}
#bottom-tab .nav-border.tab-primary:focus, #bottom-tab .nav-border.tab-primary:active, #bottom-tab .nav-border.tab-primary.active {
  color: #337ab7;
  border-bottom: 2px solid #337ab7;
}

#bottom-tab .nav-border.tab-secondary:hover {
  color: #48A3D7;
}
#bottom-tab .nav-border.tab-secondary:focus, #bottom-tab .nav-border.tab-secondary:active, #bottom-tab .nav-border.tab-secondary.active {
  color: #48A3D7;
  border-bottom: 2px solid #48A3D7;
}

#bottom-tab .nav-border.tab-success:hover {
  color: #0DA759;
}
#bottom-tab .nav-border.tab-success:focus, #bottom-tab .nav-border.tab-success:active, #bottom-tab .nav-border.tab-success.active {
  color: #0DA759;
  border-bottom: 2px solid #0DA759;
}

#bottom-tab .nav-border.tab-danger:hover {
  color: #E44141;
}
#bottom-tab .nav-border.tab-danger:focus, #bottom-tab .nav-border.tab-danger:active, #bottom-tab .nav-border.tab-danger.active {
  color: #E44141;
  border-bottom: 2px solid #E44141;
}

#bottom-tab .nav-border.tab-tertiary:hover {
  color: #D77748;
}
#bottom-tab .nav-border.tab-tertiary:focus, #bottom-tab .nav-border.tab-tertiary:active, #bottom-tab .nav-border.tab-tertiary.active {
  color: #D77748;
  border-bottom: 2px solid #D77748;
}

#bottom-tab .nav-border.tab-warning:hover {
  color: #C95E9E;
}
#bottom-tab .nav-border.tab-warning:focus, #bottom-tab .nav-border.tab-warning:active, #bottom-tab .nav-border.tab-warning.active {
  color: #C95E9E;
  border-bottom: 2px solid #C95E9E;
}

#bottom-tab .nav-border.tab-info:hover {
  color: #16C7F9;
}
#bottom-tab .nav-border.tab-info:focus, #bottom-tab .nav-border.tab-info:active, #bottom-tab .nav-border.tab-info.active {
  color: #16C7F9;
  border-bottom: 2px solid #16C7F9;
}

#bottom-tab .nav-border.tab-light:hover {
  color: #f4f4f4;
}
#bottom-tab .nav-border.tab-light:focus, #bottom-tab .nav-border.tab-light:active, #bottom-tab .nav-border.tab-light.active {
  color: #f4f4f4;
  border-bottom: 2px solid #f4f4f4;
}

#bottom-tab .nav-border.tab-dark:hover {
  color: #2c323f;
}
#bottom-tab .nav-border.tab-dark:focus, #bottom-tab .nav-border.tab-dark:active, #bottom-tab .nav-border.tab-dark.active {
  color: #2c323f;
  border-bottom: 2px solid #2c323f;
}

#sideline-tab .nav-effect {
  display: inline-block;
  position: relative;
  font-weight: 600;
}
#sideline-tab .nav-effect::after {
  content: "";
  position: absolute;
  left: 0;
  display: inline-block;
  height: 14px;
  width: 80%;
  border-bottom: 1px solid;
  margin-top: 10px;
  opacity: 0;
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
}
#sideline-tab .nav-effect:hover::after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.tabs-responsive-side .border-tab.nav-secondary.nav-left .nav-link:hover, .tabs-responsive-side .border-tab.nav-secondary.nav-left .nav-link.active {
  background-color: unset;
  border-left: none;
  border-radius: 0;
  -webkit-transition: none;
  transition: none;
}

@media (max-width: 576px) {
  .pills-blogger {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

::-webkit-input-placeholder {
  font-size: 14px;
}

::-moz-placeholder {
  font-size: 14px;
}

:-ms-input-placeholder {
  font-size: 14px;
}

::-ms-input-placeholder {
  font-size: 14px;
}

::placeholder {
  font-size: 14px;
}

.material-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
}
@media (max-width: 576px) {
  .material-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

/**=====================
    2.29 Tab CSS Ends
==========================**/
/**=====================
    2.30 Table CSS Start
==========================**/
thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: #dee2e6;
  border-style: solid;
}

.table tbody tr:last-child td {
  border-style: none;
  border-color: transparent;
}

.table > :not(caption) > * > * {
  background-color: transparent;
}

.pills-component {
  width: 20%;
}

#row_create td.highlight {
  font-weight: 600;
  color: #337ab7;
}
#row_create td.danger {
  font-weight: 600;
  color: #E44141;
}

.table {
  margin-bottom: 0px;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: rgba(106, 113, 133, 0.3);
  border-style: solid;
}
.table th,
.table td {
  padding: 0.75rem;
  color: #333 !important;
  font-size: 16px;
  font-weight: 700 !important;
}
.table td {
  font-size: 15px;
  font-weight: 500 !important;
}
.table.table-primary th {
  color: #fff;
}
.table thead.bg-primary tr th {
  color: #fff;
}
.table th {
  color: #3D434A;
  font-weight: 500;
}
.table th.f-light {
  color: var(--chart-text-color);
  opacity: 0.8;
}
.table td {
  color: #3D434A;
}
.table [class*=bg-] {
  color: #fff;
}
.table .bg-light {
  color: #3D434A;
}
.table tfoot {
  font-weight: bold;
}

.table-dark th {
  color: #fff;
}

.table-inverse {
  color: #fff;
  background-color: #292b2c;
}
.table-inverse th,
.table-inverse td {
  border-color: #fff;
  color: #fff;
}
.table-inverse.table-bordered {
  border: 0;
}

.table[class*=bg-] th,
.table[class*=bg-] td {
  color: #fff;
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: #f6f6f6;
}
.table-hover tbody tr {
  vertical-align: middle;
}
.table-hover tbody tr:hover {
  background-color: rgba(122, 112, 186, 0.1);
}
.table-hover tbody tr td svg {
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  margin-right: 10px;
}
[dir=rtl] .table-hover tbody tr td svg {
  margin-right: unset;
  margin-left: 10px;
}

.typography-table tr:last-child {
  border-bottom: none;
}
.typography-table tr:last-child td {
  border-bottom: none;
}

.table-double {
  border-left: 4px double #dee2e6;
  border-right: 4px double #dee2e6;
}
.table-double thead,
.table-double tbody,
.table-double tfoot,
.table-double tr,
.table-double td,
.table-double th {
  border-style: double;
  border-width: 4px;
  border-right: 0;
  border-left: 0;
}

.table-dotted {
  border-left: 2px dotted #dee2e6;
  border-right: 2px dotted #dee2e6;
}
.table-dotted thead,
.table-dotted tbody,
.table-dotted tfoot,
.table-dotted tr,
.table-dotted td,
.table-dotted th {
  border-style: dotted !important;
  border-width: 2px;
  border-right: 0;
  border-left: 0;
}

.table-solid {
  border-left: 2px solid #dee2e6;
  border-right: 2px solid #dee2e6;
}
.table-solid thead,
.table-solid tbody,
.table-solid tfoot,
.table-solid tr,
.table-solid td,
.table-solid th {
  border-style: solid !important;
  border-width: 2px;
  border-right: 0;
  border-left: 0;
}

.table-xl td,
.table-xl th {
  padding: 1.25rem 2rem;
  font-size: 120%;
}

.table-lg td,
.table-lg th {
  padding: 0.9rem 2rem;
  font-size: 110%;
}

.table-de td,
.table-de th {
  padding: 0.75rem 2rem;
}

.table-sm th,
.table-sm td {
  padding: 0.5rem 2rem;
  font-size: 90%;
}

.table-xs th,
.table-xs td {
  padding: 0.2rem 2rem;
  font-size: 80%;
}

.table-border-horizontal tr,
.table-border-horizontal th,
.table-border-horizontal td {
  border-top: 1px solid #f2f4ff;
  padding: 0.75rem 2rem;
}

.table-border-vertical tr,
.table-border-vertical th,
.table-border-vertical td {
  border-top: 0;
  border-right: 1px solid #f2f4ff;
}
.table-border-vertical tr td {
  border-bottom: 0;
}
.table-border-vertical tr td:last-child {
  border-right: none;
}
.table-border-vertical tbody tr th {
  border-bottom: 0;
}

.table-bordernone td {
  border: none !important;
}
.table-bordernone thead th {
  border: none !important;
}
.table-bordernone .u-s-tb {
  padding: 11px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--light-background);
  --bs-table-accent-bg: unset;
}

.table-bordered thead,
.table-bordered tbody,
.table-bordered tfoot,
.table-bordered tr,
.table-bordered td,
.table-bordered th {
  border-color: rgba(153, 153, 153, 0.3);
}

/**=====================
    2.30 Table CSS Ends
==========================**/
/**=====================
   2.31 Toasts CSS Start
==========================**/
.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.toast-index {
  z-index: 12;
}
.toast-index.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast-index.toast-fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
.toast-index.show {
  display: block;
}

.toast-img img {
  width: 30px;
  height: 30px;
}

.bd-example-toasts {
  min-height: 400px;
}

.toast:not(.showing):not(.show) {
  opacity: 0;
}

.toast-content {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 65px;
  margin: 0 auto;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.toast-container {
  position: unset;
}

[dir=rtl] .toast-rtl .toast .toast-header strong {
  margin-right: unset !important;
  margin-left: auto !important;
}
[dir=rtl] .toast-rtl .toast .btn-close {
  margin-right: 6px;
  margin-left: unset;
}

@media (max-width: 1920px) {
  .toast-dark .btn {
    padding: calc(6px + 0 * (100vw - 320px) / 1600) calc(4px + 24 * (100vw - 320px) / 1600);
  }
}

.toast-icons {
  margin-right: 6px;
  width: 20px;
  height: 20px;
}
[dir=rtl] .toast-icons {
  margin-right: unset;
  margin-left: 6px;
}

/**=====================
   2.31 Toasts CSS Ends
==========================**/
/**=====================
  2.32 Touchspin CSS Start
==========================**/
.bootstrap-touchspin input.touchspin {
  padding: 0 12px;
}
.bootstrap-touchspin .input-group .btn {
  padding: 0.5rem 1.75rem;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical {
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical .bootstrap-touchspin-down {
  margin-top: -2px;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical i {
  position: absolute;
  top: 4px;
  left: 7px;
  font-size: 10px;
  font-weight: normal;
}
.bootstrap-touchspin .touchspin-vertical-tab .input-group .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
  padding: 9px;
  margin-left: -1px;
  position: relative;
}
.bootstrap-touchspin .input-group {
  font-family: "Nunito", sans-serif, sans-serif;
}
.bootstrap-touchspin .input-group .btn {
  border-radius: 0 !important;
}
.bootstrap-touchspin .dropdown-basic .dropdown .dropbtn {
  padding: 9px 30px;
}
.bootstrap-touchspin .dropdown-basic .dropdown .dropdown-content {
  z-index: 9999;
}

.touchspin-wrapper .btn-touchspin {
  border-radius: 5px;
  width: 32px;
  height: 32px;
}
.touchspin-wrapper input[type=number] {
  width: 85px;
  padding: 3px;
  text-align: center;
  height: 30px;
}

.hover-touchspin .btn-touchspin.spin-border-dark:hover {
  background-color: #2c323f;
  color: #fff;
}

.pre-post-touchspin .decrement-touchspin,
.pre-post-touchspin .increment-touchspin,
.pre-post-touchspin .input-group-text {
  padding: 7px 14px;
  border: 0;
}
.pre-post-touchspin .input-group-text {
  background-color: var(--light-bg);
}
.pre-post-touchspin .input-touchspin {
  border: 1px solid var(--course-light-btn);
  border-width: 1px;
  text-align: center;
}
.pre-post-touchspin .input-group {
  width: auto;
}
@media (max-width: 768px) {
  .pre-post-touchspin .input-group .input-touchspin {
    width: 100px;
  }
}
.pre-post-touchspin.bg-touchspin button[type=button] {
  border: 1px solid var(--course-light-btn);
  border-width: 1px;
  text-align: center;
}
.pre-post-touchspin .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rounded-touchspin .btn-touchspin {
  border-radius: 50%;
}
.rounded-touchspin .touchspin-wrapper input[type=number].input-touchspin {
  border-radius: 15px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/**=====================
    2.32 Touchspin CSS Ends
==========================**/
/**=====================
  2.33 Tour CSS Start
==========================**/
.introjs-helperLayer {
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
          box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4);
}

.introjs-tooltipReferenceLayer {
  background-color: transparent;
}

.introjs-helperNumberLayer {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  background: #E44141;
  background: -webkit-gradient(linear, left top, left bottom, from(var(--theme-deafult)), to(var(--theme-deafult)));
  background: linear-gradient(to bottom, var(--theme-deafult) 0%, var(--theme-deafult) 100%);
  border: 3px solid #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
}

.introjs-arrow {
  border: 12px solid #fff;
}
.introjs-arrow.top {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--theme-deafult);
  border-left-color: transparent;
}
.introjs-arrow.top-right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--theme-deafult);
  border-left-color: transparent;
}
.introjs-arrow.top-middle {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: var(--theme-deafult);
  border-left-color: transparent;
}
.introjs-arrow.right {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: var(--theme-deafult);
}
.introjs-arrow.right-bottom {
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: var(--theme-deafult);
}
.introjs-arrow.bottom {
  border-top-color: var(--theme-deafult);
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.left {
  border-top-color: transparent;
  border-right-color: var(--theme-deafult);
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.introjs-arrow.left-bottom {
  border-top-color: transparent;
  border-right-color: var(--theme-deafult);
  border-bottom-color: transparent;
  border-left-color: transparent;
}

.introjs-tooltip {
  background-color: var(--theme-deafult);
  -webkit-box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
          box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
  color: #fff;
  border-color: var(--theme-deafult);
  min-width: calc(275px + 35 * (100vw - 320px) / 1600);
  max-width: 400px;
  min-height: 90px;
}

.introjs-button {
  border: 1px solid #efefef;
  text-shadow: 1px 1px 0 #fff;
  color: #3D434A !important;
  background-color: #fff;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#fff), to(#fff));
  background-image: linear-gradient(#fff, #fff);
  font-size: 13px;
}
.introjs-button:hover {
  border-color: #efefef;
}
.introjs-button:focus, .introjs-button:active {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(106, 113, 133, 0.3)), to(#efefef));
  background-image: linear-gradient(rgba(106, 113, 133, 0.3), #efefef);
}

.introjs-skipbutton {
  color: var(--theme-deafult);
}

.introjs-prevbutton.introjs-fullbutton {
  border: 1px solid #efefef;
}

.introjs-disabled {
  color: var(--theme-deafult);
  border-color: #efefef;
}
.introjs-disabled:hover, .introjs-disabled:focus {
  color: var(--theme-deafult);
  border-color: #efefef;
}

.introjs-bullets ul li a {
  background: rgba(255, 255, 255, 0.3);
}
.introjs-bullets ul li a:hover {
  background: #fff;
}
.introjs-bullets ul li a.active {
  background: #fff;
}

.introjs-progress {
  background-color: #f4f4f4;
}

.introjs-progressbar {
  background-color: var(--theme-deafult);
}

.introjs-hint:hover > .introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.57);
}

.introjs-hint-pulse {
  border: 5px solid rgba(60, 60, 60, 0.27);
  background-color: rgba(136, 136, 136, 0.24);
}

.introjs-hint-dot {
  border: 10px solid rgba(146, 146, 146, 0.36);
}

.flex-grow-1 .tour-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}
.flex-grow-1 .tour-wrapper .tour-dot {
  font-size: 5px;
}

.tour-blog {
  height: 350px;
}
@media (max-width: 992px) {
  .tour-blog {
    height: calc(200px + 150 * (100vw - 320px) / 672);
  }
}
.tour-blog img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.info h6 {
  font-size: calc(14px + 2 * (100vw - 320px) / 1600);
}
.info span {
  font-size: calc(13px + 1 * (100vw - 320px) / 1600);
}

.tour-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
@media (max-width: 576px) {
  .tour-email {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

.block-ellipsis {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 26px !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profile-img-style h5 {
  line-height: 25px;
}

.list-inline .list-inline-item.b-r-gray {
  border-right: 1px solid rgba(106, 113, 133, 0.3);
}

.social-tour {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 26px;
}
.social-tour .list-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: calc(12px + 14 * (100vw - 320px) / 1600);
}
.social-tour .list-inline .list-inline-item {
  margin-right: 0;
}
.social-tour .list-inline .list-inline-item a {
  padding: 0px;
}
.social-tour .list-inline .list-inline-item a i {
  font-size: 18px;
}
@media (max-width: 599px) {
  .social-tour .list-inline {
    display: none;
  }
}
.social-tour .float-sm-end {
  min-width: 81px;
}

.tour-mb-space {
  margin-bottom: calc(2px + 6 * (100vw - 320px) / 1600);
}

.like-comment .list-inline {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
@media (max-width: 324px) {
  .like-comment .list-inline {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    gap: 8px;
  }
}
[dir=rtl] .like-comment .list-inline .list-inline-item {
  border-right: 0;
  border-left: 1px solid rgba(106, 113, 133, 0.3);
}
[dir=rtl] .like-comment .list-inline .list-inline-item:last-child {
  border-right: 0;
  border-left: unset;
}

/**=====================
  2.33 Tour CSS Ends
==========================**/
/**=====================
   2.34 Tree CSS Start
==========================**/
[dir=rtl] .treejs .treejs-nodes {
  padding-left: unset;
  padding-right: 20px;
}
[dir=rtl] .treejs .treejs-node.treejs-placeholder {
  padding-right: 20px;
  padding-left: unset;
}
.treejs .treejs-node__halfchecked > .treejs-checkbox:before {
  background-color: #337ab7 !important;
  border-color: #337ab7 !important;
}
[dir=rtl] .treejs .treejs-node__halfchecked > .treejs-checkbox:after {
  right: 3px;
  left: unset;
}
.treejs .treejs-node__checked > .treejs-checkbox:before {
  background-color: #0DA759 !important;
  border-color: #2c323f !important;
}
[dir=rtl] .treejs .treejs-node__checked > .treejs-checkbox:after {
  right: 5px;
  left: unset;
}

/**=====================
   2.34 Tree CSS Ends
==========================**/
/**=====================
    2.35 Typeahead-search CSS Start
==========================**/
.Typeahead {
  width: 100%;
}
.Typeahead * {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.Typeahead *:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.Typeahead *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.Typeahead .tt-hint {
  display: none !important;
}

.u-cf:before {
  content: " ";
  display: table;
}
.u-cf:after {
  content: " ";
  display: table;
  clear: both;
}

.u-hidden {
  display: none !important;
}

.u-posRelative {
  position: relative;
}

.Typeahead-spinner {
  position: absolute;
  top: 14px;
  right: 18px;
  display: none;
  color: #c5c5c5;
}

.ProfileCard-avatar svg {
  height: 18px;
  vertical-align: bottom;
}
.ProfileCard-avatar svg path {
  color: #7e7c7c !important;
}
.ProfileCard-avatar svg polygon {
  color: #7e7c7c !important;
}

.Typeahead-hint {
  width: 100%;
  padding: 5px 8px;
  font-size: 24px;
  line-height: 30px;
  border: 1px solid #024e6a;
  border-radius: 8px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ccd6dd;
  opacity: 1;
}

.Typeahead-menu {
  position: absolute;
  top: 100%;
  left: 0;
  padding: 15px;
  z-index: 100;
  display: none;
  width: 100%;
  overflow: hidden;
  background-color: #fff;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.Typeahead-menu.is-open {
  display: block;
}

.Typeahead-selectable {
  cursor: pointer;
}
.Typeahead-selectable + .Typeahead-selectable {
  border-top: 1px solid #ccd6dd;
}

.ProfileCard {
  position: relative;
  padding: 10px;
  border: 1px solid rgba(106, 113, 133, 0.3) !important;
  margin-bottom: 10px;
  border-radius: 10px;
}
.ProfileCard:hover {
  color: #414345;
  background: #f8f8f8;
}
.ProfileCard:hover .ProfileCard-avatar {
  border-color: #f5f8fa;
}
.ProfileCard:hover .ProfileCard-screenName {
  color: #fff;
}
.ProfileCard:hover .ProfileCard-stat-label {
  color: #fff;
}

.ProfileCard-avatar {
  position: absolute;
  border-radius: 5px;
}

.ProfileCard-details {
  padding-left: 35px;
  float: left;
}

.ProfileCard-realName {
  display: inline-block;
  color: #898989;
}

.ProfileCard-screenName {
  display: inline-block;
  color: #898989;
}

.ProfileCard-description {
  margin-top: 5px;
  font-size: 14px;
  line-height: 18px;
}

.ProfileCard-stats {
  float: right;
  text-align: right;
}

.ProfileCard-stat {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
}
.ProfileCard-stat + .ProfileCard-stat {
  margin-left: 5px;
}

.ProfileCard-stat-label {
  color: #898989;
  font-weight: 500;
}

.ProfileCard.is-active {
  color: #fff;
  background: var(--theme-deafult);
}
.ProfileCard.is-active .ProfileCard-screenName {
  color: #fff;
}
.ProfileCard.is-active .ProfileCard-stat-label {
  color: #fff;
}

.EmptyMessage {
  position: relative;
  padding: 10px;
  font-size: 14px;
  line-height: 30px;
  text-align: center;
}

.Typeahead-spinner {
  width: 1rem;
  height: 1rem;
}

.sey-container {
  display: none;
  position: absolute;
  -webkit-box-shadow: 1px 2px 6px;
          box-shadow: 1px 2px 6px;
  background-color: #fff;
  color: #333;
  -webkit-transition: left 0.1s ease-in-out;
  transition: left 0.1s ease-in-out;
  z-index: 1;
}

.sey-list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.sey-show {
  display: block;
}

.sey-hide {
  display: none;
}

.sey-empty {
  cursor: default;
  padding: 7px;
}

.sey-item {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px;
}
.sey-item:hover {
  background-color: #444;
  color: #fff;
}

.sey-selected {
  background-color: #333;
  color: #fff;
}

.sey-char-highlight {
  font-weight: bold;
}

.sey-category-id {
  background-color: #eee;
  color: #aaa;
  text-align: right;
  text-transform: capitalize;
  font-style: italic;
  font-size: 12px;
  -webkit-box-shadow: 1px 0px 1px;
          box-shadow: 1px 0px 1px;
  padding: 7px;
}

/**=====================
  2.35 Typeahead-search CSS Ends
==========================**/
/**=====================
    2.36 Scrollbar CSS Start
==========================**/
[data-simplebar] {
  position: unset;
}

.simplebar-mask {
  top: 78px;
}

.simplebar-track {
  right: -2px;
}
.simplebar-track.simplebar-vertical {
  top: 100px;
  width: 10px;
}
.simplebar-track.simplebar-horizontal {
  visibility: hidden !important;
}

.simplebar-scrollbar:before {
  background: rgba(122, 112, 186, 0.2);
}

.scrollbar-images {
  margin-bottom: 12px;
}

.visible-wrapper img {
  width: calc(230px + 100 * (100vw - 320px) / 1600) !important;
  height: calc(230px + 100 * (100vw - 320px) / 1600);
}

.horizontal-scroll .horz-scroll-content .horizontal-img {
  height: 100%;
  max-width: 240px;
}
@media (max-width: 576px) {
  .horizontal-scroll .horz-scroll-content .horizontal-img {
    max-width: calc(220px + 20 * (100vw - 320px) / 256);
  }
}
.horizontal-scroll .horz-scroll-content .horizontal-img img {
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.scroll-demo.scroll-b-none {
  padding: 0;
  border: none;
}
[dir=rtl] .scroll-demo .scroll-rtl .list-group-item span {
  margin-right: auto;
}

/**=====================
    2.36 Scrollbar CSS Ends
==========================**/
/**=====================
   3.1 Blog CSS start
==========================**/
.blog-box img {
  border-radius: 0.25rem;
}
.blog-box .top-radius-blog {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.blog-box h6 {
  font-weight: 600;
  margin: 15px 0;
}
.blog-box .blog-details {
  padding-right: 20px;
}
.blog-box .blog-details .blog-social {
  margin-top: 30px;
}
.blog-box .blog-details .blog-social li {
  display: inline-block;
  font-size: 12px;
  color: #9B9B9B;
}
.blog-box .blog-details .blog-social li + li {
  padding-left: 20px;
  line-height: 1;
  padding-right: 0;
}
.blog-box .blog-details .blog-social li i {
  padding-right: 5px;
  font-weight: 300;
}
.blog-box .blog-details .blog-social li:first-child {
  border-right: 1px solid gray;
  padding-right: 20px;
}
.blog-box .blog-details-main hr {
  background-color: rgba(106, 113, 133, 0.3);
}
.blog-box .blog-details-main .blog-social {
  margin: 20px 0;
}
.blog-box .blog-details-main .blog-social li {
  display: inline-block;
  font-size: 12px;
  color: #9B9B9B;
  padding: 0 24px;
  border-right: 1px dotted;
}
@media (max-width: 1676px) {
  .blog-box .blog-details-main .blog-social li {
    padding: 0 10px;
  }
}
.blog-box .blog-details-main .blog-social li:last-child {
  border-right: 0;
}
.blog-box .blog-details-main .blog-social li i {
  padding-right: 5px;
}
.blog-box .blog-details-main .blog-bottom-details {
  margin: 10px 20px 20px;
  font-size: 14px;
  line-height: 1.714;
  font-weight: 500;
  color: #8D8D8D;
}
.blog-box .blog-date {
  color: #9B9B9B;
}
.blog-box .blog-date span {
  font-size: 36px;
  font-weight: 500;
  padding-right: 5px;
  color: #337ab7;
}

.blog-box.blog-shadow:before {
  -webkit-box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
          box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.6);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 5px;
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  width: 100%;
  bottom: 0;
}
.blog-box.blog-shadow:hover:before {
  -webkit-box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
          box-shadow: inset 0px -200px 100px -13px rgba(0, 0, 0, 0.65);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-box.blog-shadow .blog-details {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 20px;
}
.blog-box.blog-shadow .blog-details h4 {
  color: #fff;
  margin-bottom: 0;
  margin-top: 0;
}
.blog-box.blog-shadow .blog-details p {
  font-size: 14px;
  color: #fff;
  margin-bottom: 10px;
}
.blog-box.blog-shadow .blog-details .blog-social li {
  color: #fff;
}

.blog-list .blog-details .blog-social {
  margin-top: 0;
}
.blog-list .blog-details h5 {
  margin-top: 0;
  font-weight: 500;
}
.blog-list .blog-details hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.blog-list img {
  padding: 17px;
}

.blog-grid img {
  width: 100%;
}
.blog-grid .blog-details {
  padding: 20px;
}
.blog-grid .blog-details h6 {
  font-size: 14px;
  margin-bottom: 0;
  line-height: 1.8;
}

.blog-box {
  position: relative;
  max-height: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.blog-box .blog-details h6 {
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.blog-box .blog-details p {
  font-size: 14px;
  line-height: 1.8;
  letter-spacing: 0px;
  margin-top: 30px;
  margin-bottom: 0;
  color: #9B9B9B;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
@media (max-width: 1570px) {
  .blog-box .blog-details p {
    -webkit-line-clamp: 2;
  }
}
.blog-box .blog-details h4 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}
.blog-box .blog-details .single-blog-content-top {
  margin-top: 20px;
  border-top: 1px solid #efefef;
}
.blog-box .blog-details .blog-social li,
.blog-box .blog-details .comment-social li {
  font-size: 14px;
}

.comment-box {
  padding-top: 50px;
  padding-bottom: 50px;
}
.comment-box hr {
  margin-top: 20px;
  margin-bottom: 30px;
}
.comment-box li {
  margin-bottom: 30px;
}
.comment-box li:last-child {
  margin-bottom: 0;
}
.comment-box ul ul {
  margin-left: 135px;
}
.comment-box p {
  font-size: 14px;
  line-height: 2;
  color: #9B9B9B;
  text-align: justify;
  margin-bottom: 0;
}
.comment-box h4 {
  font-weight: 600;
}
.comment-box .d-flex img {
  margin-right: 30px;
  border-radius: 10px;
  height: 90px;
  padding: 7px;
  width: 90px;
  border: 1px solid #efefef;
}
.comment-box .d-flex h6 {
  font-weight: 500;
  margin-bottom: 5px;
}
.comment-box .d-flex h6 span {
  padding-left: 10px;
  font-size: 13px;
  color: #9B9B9B;
  font-weight: 400;
}
.comment-box .comment-social li {
  display: inline-block;
  color: rgba(106, 113, 133, 0.3);
  padding-left: 20px;
  margin-bottom: 0;
  text-transform: uppercase;
}
.comment-box .comment-social li:first-child {
  border-right: 1px solid #efefef;
  padding-right: 20px;
}
.comment-box .comment-social li i {
  padding-right: 10px;
}

/**=====================
   3.1 Blog CSS ends
==========================**/
/**=====================
    3.2 Bookmark-app CSS Start
==========================**/
.modal-bookmark .modal-body,
.modal-bookmark .modal-header {
  padding: 30px;
}

.bookmark-wrap .details-bookmark .row > div + div {
  padding-right: 15px !important;
}
.bookmark-wrap .left-bookmark {
  height: 700px;
}
.bookmark-wrap .left-bookmark ul li button {
  border: none;
  cursor: pointer;
}
.bookmark-wrap .left-bookmark ul li button:focus {
  outline: none;
}
.bookmark-wrap .left-bookmark ul li svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.bookmark-wrap .left-bookmark ul li > .main-title {
  color: var(--theme-deafult);
  font-weight: 600;
  margin-bottom: 5px;
  display: block;
  font-size: 16px;
}
.bookmark-wrap .left-bookmark ul li > .main-title span a:hover {
  background-color: transparent;
}
.bookmark-wrap .left-bookmark ul li > .main-title svg {
  color: #898989;
  stroke-width: 1px;
}
.bookmark-wrap .left-bookmark ul li a > .title {
  text-transform: capitalize;
}
.bookmark-wrap.todo-wrap .left-bookmark {
  height: unset;
}

.form-bookmark a {
  text-transform: capitalize !important;
  color: var(--theme-deafult) !important;
  line-height: 1 !important;
  font-weight: 500;
}
.form-bookmark a:hover {
  background-color: transparent !important;
}
.form-bookmark input#index_var {
  display: none;
}
.form-bookmark input,
.form-bookmark select {
  font-size: 14px;
}
.form-bookmark .select2-container .select2-selection--single {
  border-color: #ced4da;
}
.form-bookmark .select2 {
  width: 100% !important;
}
.form-bookmark .error {
  margin: 0 !important;
  font-size: 11px;
  color: red !important;
}
.form-bookmark button {
  margin-top: 10px;
}

@-webkit-keyframes displayTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes displayTransition {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bookmark-tabcontent .details-bookmark {
  height: auto;
  min-height: 359px;
}
.bookmark-tabcontent .details-bookmark .bookmark-card {
  position: relative;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  padding: 3px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.2);
  width: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon i {
  color: #fff;
  font-size: 16px;
  vertical-align: text-bottom;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon.favourite i {
  color: #fed112;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data {
  width: 100%;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .desciption-data .content-general {
  display: none;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
  text-align: left;
  padding: 15px;
  border-top: 1px solid #f4f4f4;
  position: relative;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark h5 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: 500;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark p {
  -webkit-animation: displayTransition 1s;
          animation: displayTransition 1s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block {
  opacity: 0;
  position: absolute;
  width: 100%;
  padding: 15px;
  top: 30px;
  left: 0;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li {
  display: inline-block;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li + li {
  margin-left: 5px;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark .hover-block ul li svg {
  width: 16px;
  height: 16px;
  color: #898989;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover {
  -webkit-box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
          box-shadow: 1px 4px 16px 3px rgba(0, 0, 0, 0.04);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .title-bookmark p {
  opacity: 0;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .title-bookmark .hover-block {
  opacity: 1;
  -webkit-animation: displayTransition 2s;
          animation: displayTransition 2s;
}
.bookmark-tabcontent .details-bookmark .bookmark-card.card:hover .favourite-icon {
  opacity: 1;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .row > div {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .desciption-data .content-general {
  display: block;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
  opacity: 1;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .hover-block {
  position: absolute;
  right: 30px;
  opacity: 1;
  text-align: right;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block {
  opacity: 1;
  -webkit-animation: displayTransition 0s;
          animation: displayTransition 0s;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark {
  border-top: none;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark p,
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6 {
  margin-top: 0;
  display: inline-block;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6 {
  position: relative;
  padding-right: 15px;
  margin-bottom: -5px;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark h6::after {
  position: absolute;
  content: "-";
  right: 0;
  color: #898989;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general {
  margin-top: 5px;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general p {
  width: 68%;
  margin-right: 20px;
}
.bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
  width: 20%;
}
.bookmark-tabcontent .tab-content .card .d-flex {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.bookmark-tabcontent .tab-content .card .d-flex svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.bookmark-tabcontent .tab-content .card .d-flex ul li {
  display: inline-block;
}
.bookmark-tabcontent .tab-content .card .d-flex ul li + li {
  margin-left: 5px;
}
.bookmark-tabcontent .tab-content .tab-pane > .card {
  -webkit-box-shadow: none;
          box-shadow: none;
}

@media only screen and (max-width: 1660px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .content-general p {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .hover-block {
    margin-top: 6px;
  }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .bookmark-wrap .left-bookmark ul li .btn-block svg {
    display: none;
  }
}
@media only screen and (max-width: 1366px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 40%;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card.card .title-bookmark .hover-block {
    top: 80px;
  }
}
@media only screen and (max-width: 1199px) {
  .bookmark-wrap .left-bookmark {
    height: auto;
  }
  .bookmark-tabcontent .details-bookmark {
    min-height: auto;
  }
}
@media only screen and (max-width: 991px) {
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 172px;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block p {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website:hover .title-bookmark .hover-block ul {
    position: relative;
    right: unset;
    top: unset;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 45%;
  }
}
@media only screen and (max-width: 575px) {
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card img {
    width: 100%;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website {
    display: block;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark {
    padding-left: 15px;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .hover-block {
    position: relative;
    right: unset;
    text-align: left;
    top: unset;
    padding: 0;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark .content-general {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p,
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark h6 {
    display: block;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark h6::after {
    display: none;
  }
  .bookmark-tabcontent .details-bookmark.list-bookmark .bookmark-card .details-website .title-bookmark p {
    margin-top: 15px;
  }
  .modal-bookmark .modal-body,
  .modal-bookmark .modal-header {
    padding: 15px;
  }
}
/**=====================
  3.2 Bookmark-app CSS Ends
==========================**/
/**=====================
  3.14 Add-product CSS Start
==========================**/
.sidebar-left-wrapper {
  border-right: 1px solid var(--recent-solid-border);
}
@media (max-width: 1199px) {
  .sidebar-left-wrapper {
    border-right: unset;
  }
}
[dir=rtl] .sidebar-left-wrapper {
  border-right: unset;
  border-left: 1px solid var(--recent-solid-border);
}
.sidebar-left-wrapper .sidebar-left-icons {
  display: block;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item {
  position: relative;
  padding-bottom: 50px;
}
@media (max-width: 1199px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item {
    padding-bottom: 25px;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link {
  padding-top: 0;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:last-child {
  padding-bottom: 0;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:last-child .nav-link {
  padding-bottom: 0;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item::before {
  content: "";
  position: absolute;
  border: 1px solid var(--recent-solid-border);
  top: 58px;
  height: 50px;
  left: 20px;
}
[dir=rtl] .sidebar-left-wrapper .sidebar-left-icons .nav-item::before {
  left: unset;
  right: 20px;
}
@media (max-width: 1199px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item::before {
    height: 25px;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:last-child::before {
  content: none;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:last-child .nav-link {
  padding-bottom: 0;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  padding: 8px 0;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active {
  background-color: unset;
  color: unset;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .nav-rounded, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .nav-rounded {
  border: 1px solid var(--theme-deafult);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .nav-rounded .product-icons, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .nav-rounded .product-icons {
  background-color: var(--theme-deafult);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .nav-rounded .product-icons svg, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .nav-rounded .product-icons svg {
  stroke: var(--white);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link.active .product-tab-content h5, .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link:active .product-tab-content h5 {
  color: var(--theme-deafult);
  font-weight: 600;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .nav-rounded {
  min-width: 42px;
  height: 42px;
  border: 1px solid var(--recent-solid-border);
  position: relative;
  z-index: 2;
  border-radius: 100%;
  padding: 2px;
  -webkit-box-shadow: inset 0px 9px 20px 0px rgba(46, 35, 94, 0.07);
          box-shadow: inset 0px 9px 20px 0px rgba(46, 35, 94, 0.07);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .nav-rounded .product-icons {
  min-width: 100%;
  height: 100%;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .nav-rounded .product-icons svg {
  width: 18px;
  height: 18px;
  stroke: var(--chart-text-color);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content h5 {
  color: var(--body-font-color);
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}
@media (max-width: 575px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content h5 {
    text-align: left;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content p {
  color: var(--chart-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  width: 100%;
}
@media (max-width: 575px) {
  .sidebar-left-wrapper .sidebar-left-icons .nav-item .nav-link .product-tab-content p {
    text-align: left;
  }
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .nav-rounded {
  border: 1px solid var(--theme-deafult);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .nav-rounded .product-icons {
  background-color: var(--light-shade-primary, 1);
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .nav-rounded .product-icons svg {
  stroke: var(--theme-deafult);
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.sidebar-left-wrapper .sidebar-left-icons .nav-item:hover .product-tab-content h5 {
  color: var(--theme-deafult);
}

.sidebar-body form .form-control:focus,
.sidebar-body form .form-select:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-color: unset;
}
[dir=rtl] .sidebar-body .ql-snow .ql-picker-label {
  padding-right: 8px;
  padding-left: 2px;
}
@media (max-width: 575px) {
  .sidebar-body .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0 !important;
  }
}
[dir=rtl] .sidebar-body .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: unset;
  left: 0;
}
[dir=rtl] .sidebar-body .form-select {
  padding: 6px 12px 6px 36px !important;
}
.sidebar-body .ql-formats {
  border-right: 1px solid var(--recent-solid-border);
}
[dir=rtl] .sidebar-body .ql-formats {
  border-left: 1px solid var(--recent-solid-border);
  border-right: unset;
}
@media (max-width: 575px) {
  .sidebar-body .ql-formats {
    border-right: unset;
  }
  [dir=rtl] .sidebar-body .ql-formats {
    border-left: unset;
  }
}
.sidebar-body .ql-formats:last-of-type {
  border-right: unset;
}
[dir=rtl] .sidebar-body .ql-formats:last-of-type {
  border-left: unset;
}
.sidebar-body #editor2 {
  min-height: 250px;
}

.sidebar-body input[type=number]::-webkit-inner-spin-button,
.sidebar-body input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: inner-spin-button;
}
.sidebar-body .form-select,
.sidebar-body .form-control {
  font-size: 15px;
  color: #4c5667;
}
.sidebar-body .product-upload p {
  margin-bottom: 18px;
  line-height: 0;
}
.sidebar-body .product-upload:nth-child(1) {
  margin-bottom: 30px;
}
@media (max-width: 400px) {
  .sidebar-body .product-upload:nth-child(1) {
    margin-bottom: 26px;
  }
}
.sidebar-body .product-upload:nth-child(2) {
  margin-bottom: 65px;
}
@media (max-width: 1199px) {
  .sidebar-body .product-upload:nth-child(2) {
    margin-bottom: 0;
  }
}
.sidebar-body .product-upload form svg {
  width: 48px;
  height: 48px;
}
.sidebar-body .product-upload form h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.8;
}
.sidebar-body .product-upload form span {
  color: var(--chart-text-color);
  font-size: 14px;
}

.product-buttons {
  margin: 15px 0 0 0;
  text-align: end;
  border-top: 1px solid var(--recent-solid-border);
  padding: 12px 0 0 0;
  position: absolute;
  bottom: 0;
  width: calc(100% - 48px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
[dir=rtl] .product-buttons {
  left: unset;
  right: 50%;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
}
@media (max-width: 1199px) {
  .product-buttons {
    position: unset;
    width: unset;
    -webkit-transform: unset;
            transform: unset;
  }
  [dir=rtl] .product-buttons {
    -webkit-transform: unset;
            transform: unset;
  }
}
.product-buttons svg {
  width: 18px;
  height: 18px;
  stroke: var(--chart-text-color);
}
@media (max-width: 400px) {
  .product-buttons svg {
    width: 15px;
    height: 15px;
  }
}
.product-buttons .btn {
  border: 1px solid var(--recent-solid-border);
  color: var(--chart-text-color);
  font-weight: 500;
}
@media (max-width: 1400px) {
  .product-buttons .btn {
    padding: 6px 16px;
  }
}
@media (max-width: 400px) {
  .product-buttons .btn {
    padding: 6px 8px;
  }
}
.product-buttons .btn:hover, .product-buttons .btn:active {
  background-color: var(--theme-deafult);
  color: #fdfeff;
}
.product-buttons .btn:hover svg, .product-buttons .btn:active svg {
  stroke: #fdfeff;
}

.category-button {
  padding-bottom: 30px;
  border-bottom: 1px solid var(--recent-solid-border);
}
@media (max-width: 575px) {
  .category-button {
    padding-bottom: 25px;
  }
}
.category-button a {
  padding: 12px;
}

.create-category .toolbar-box {
  margin-top: 12px;
}

.product-date .form-control[readonly] {
  background-color: var(--white);
}

.price-wrapper .radio-wrapper {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-top: 4px;
}
.price-wrapper .radio-wrapper li {
  max-width: 200px;
}
.price-wrapper .radio-wrapper li .form-check-input {
  border-radius: 5px;
  background-color: var(--light-background);
}
.price-wrapper .radio-wrapper li .form-check-input ~ .form-check-label span {
  color: #4c5667;
  font-size: 15px;
  position: relative;
}
.price-wrapper .radio-wrapper li .form-check-input:checked {
  background-color: var(--light-background);
  border: 1px solid var(--theme-deafult);
  border-radius: 5px;
}
.price-wrapper .radio-wrapper li .form-check-input:checked ~ .form-check-label span {
  color: var(--theme-deafult);
  position: relative;
}

.advance-options ul {
  border-bottom: 1px solid var(--recent-solid-border);
}
.advance-options ul.border-tab.nav-tabs .nav-item .nav-link {
  color: var(--body-font-color);
  border-bottom: 2px solid transparent;
  -webkit-transition: all 0.3S ease-in-out;
  transition: all 0.3S ease-in-out;
}
.advance-options ul.border-tab.nav-tabs .nav-item .nav-link:active, .advance-options ul.border-tab.nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid var(--theme-deafult);
  color: var(--body-font-color);
  -webkit-transition: all 0.3S ease-in-out;
  transition: all 0.3S ease-in-out;
}

.meta-body {
  padding-top: 16px;
}
.meta-body .custom-input .form-check .form-check-input:checked {
  background-color: var(--theme-deafult);
}
.meta-body .custom-input .form-check .form-check-input:valid {
  border-color: var(--theme-deafult);
}
.meta-body .custom-input .form-check .form-check-input:valid ~ .form-check-label {
  color: rgba(155, 155, 155, 0.8);
}

.product-tag .tagify {
  border-radius: 4px;
  padding: 2px;
  height: auto;
  width: 100%;
}

[dir=rtl] .tagify__tag {
  margin-right: 5px;
  margin-left: 0px;
}

[dir=rtl] .tagify__tag__removeBtn {
  margin-right: auto;
  margin-left: 4.6666666667px;
}

.list-product-header > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  margin-bottom: 14px;
}
.list-product-header .show {
  display: block;
}
.list-product-header .hide {
  display: none;
}
.list-product-header .collapse {
  line-height: 0.2;
}
.list-product-header .light-box {
  width: 39px;
  height: 39px;
  border-radius: 5px;
  background-color: var(--light-shade-primary);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  float: right;
}
.list-product-header .light-box svg {
  width: 17px;
  height: 17px;
  color: var(--chart-text-color);
}
.list-product-header a.btn {
  float: right;
}
[dir=rtl] .list-product-header a.btn {
  float: left;
}
.list-product-header a.btn i {
  padding-right: 8px;
}
[dir=rtl] .list-product-header a.btn i {
  padding-right: unset;
  padding-left: 8px;
}
@media (max-width: 575px) {
  .list-product-header a.btn {
    float: unset;
  }
}
.list-product-header .list-product-body {
  margin-top: 20px;
  padding: 0px;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.list-product-header .list-product-body .form-select {
  font-size: 14px;
  border-color: var(--recent-solid-border);
  color: var(--text-gray);
}

.list-product.list-category tr th:nth-child(2),
.list-product.list-category tr td:nth-child(2) {
  min-width: 60px;
}
.list-product.list-category tr th:nth-child(3),
.list-product.list-category tr td:nth-child(3) {
  min-width: 500px;
}
.list-product.list-category tr th:nth-child(4),
.list-product.list-category tr td:nth-child(4) {
  min-width: 150px;
}
.list-product.list-category tr th:nth-child(5),
.list-product.list-category tr td:nth-child(5) {
  min-width: 100px;
}
.list-product thead tr th:first-child .form-check {
  display: none;
}
.list-product .datatable-container {
  overflow-x: auto;
  border-bottom: none !important;
}
.list-product .datatable-dropdown {
  position: relative;
}
.list-product .datatable-dropdown label {
  color: var(--body-font-color);
}
.list-product .datatable-dropdown label:before {
  content: "\f107";
  position: absolute;
  top: 6px;
  left: 38px;
  font-family: "FontAwesome";
  font-size: 16px;
  margin: 0;
  color: var(--text-gray);
}
[dir=rtl] .list-product .datatable-dropdown label:before {
  right: 38px;
  left: unset;
}
.list-product .datatable-selector {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  min-width: 55px;
}
.list-product .datatable-selector:focus, .list-product .datatable-selector:focus-visible {
  outline: none;
}
[dir=rtl] .list-product .datatable-sorter::before, [dir=rtl] .list-product .datatable-sorter::after {
  right: unset;
  left: 4px;
}
.list-product tr th,
.list-product tr td {
  text-align: left;
}
[dir=rtl] .list-product tr th,
[dir=rtl] .list-product tr td {
  text-align: right;
}
.list-product tr th:nth-child(1) .datatable-sorter::before, .list-product tr th:nth-child(1) .datatable-sorter::after {
  border: none;
}
.list-product tr th:nth-child(1),
.list-product tr td:nth-child(1) {
  min-width: 20px;
}
.list-product tr th:nth-child(2),
.list-product tr td:nth-child(2) {
  min-width: 110px;
}
@media (max-width: 1460px) {
  .list-product tr th:nth-child(2),
  .list-product tr td:nth-child(2) {
    min-width: 230px;
  }
}
.list-product tr th:nth-child(3),
.list-product tr td:nth-child(3) {
  min-width: 90px;
}
.list-product tr th:nth-child(4),
.list-product tr td:nth-child(4) {
  min-width: 120px;
}
.list-product tr th:nth-child(5),
.list-product tr td:nth-child(5) {
  min-width: 120px;
}
.list-product tr th:nth-child(6),
.list-product tr td:nth-child(6) {
  min-width: 80px;
}
.list-product tr th:nth-child(7),
.list-product tr td:nth-child(7) {
  min-width: 100px;
}
.list-product tr th:nth-child(8),
.list-product tr td:nth-child(8) {
  min-width: 140px;
}
.list-product tr th:nth-child(9),
.list-product tr td:nth-child(9) {
  min-width: 105px;
}
.list-product:focus-visible {
  outline: 0;
}
.list-product .datatable-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 22px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.list-product .datatable-search {
  float: left;
}
[dir=rtl] .list-product .datatable-search {
  float: right;
}
.list-product .datatable-container:focus-visible {
  outline: 0;
}
.list-product .datatable-bottom .datatable-info {
  color: var(--body-font-color);
}
.list-product .starbox {
  cursor: pointer;
}
.list-product .starbox svg {
  width: 16px;
  height: 16px;
  fill: var(--chart-text-color);
}
.list-product .datatable-top {
  padding: 0;
}
.list-product .datatable-pagination li.datatable-hidden {
  visibility: visible;
}
.list-product .datatable-pagination li.datatable-hidden a {
  color: var(--theme-deafult);
  font-weight: 500;
  opacity: 1;
}
.list-product .datatable-pagination .datatable-active .datatable-pagination-list-item-link {
  background-color: var(--theme-deafult);
  color: var(--white);
}
.list-product .datatable-pagination .datatable-pagination-list-item-link {
  background-color: rgba(var(--light-shade-primary), 0.02);
  border-radius: 5px;
}

.table#project-status tbody tr td {
  vertical-align: inherit;
}

.product-names {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
}
.product-names .light-product-box {
  min-width: 49px;
  height: 49px;
  border-radius: 3px;
  background-color: var(--chart-progress-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.product-names .light-product-box img {
  height: 26px;
}

.product-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  gap: 8px;
}
.product-action svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
  fill: var(--text-gray);
}

.datatable-input,
.datatable-selector {
  border: 1px solid var(--recent-solid-border);
  border-radius: 5px;
  background: unset;
}

/**=====================
  3.14 Add-product CSS Start
==========================**/
/**=====================
    3.3 Cart CSS start
==========================**/
.cart .total-amount {
  text-align: right !important;
  margin-top: 30px;
}
.cart .amount-space {
  margin-left: 40px;
}
.cart .cart-btn-transform {
  text-transform: capitalize;
}
.cart .qty-box {
  width: 25%;
  margin: 0 auto;
}
.cart .qty-box .input-group .btn {
  background-color: #ddd !important;
  border-color: #ddd !important;
  padding: 8px 12px;
}

.cart-buttons {
  margin-top: 30px;
}
.cart-buttons .cart-btn {
  float: right;
  padding-right: 85px;
}

/**=====================
    3.3 Cart CSS Ends
==========================**/
/**=====================
   3.5 Chat CSS Start
==========================**/
.radial-bar.radial-bar-lg > img, .radial-bar.radial-bar-lg:after {
  width: 70px;
  height: 70px;
  margin-left: 15px;
  margin-top: 15px;
  line-height: 70px;
}

.radial-bar.radial-bar-sm > img, .radial-bar.radial-bar-sm:after {
  width: 35px;
  height: 35px;
  margin-left: 7.5px;
  margin-top: 7.5px;
  line-height: 35px;
}

.radial-bar.radial-bar-xs > img, .radial-bar.radial-bar-xs:after {
  width: 20px;
  height: 20px;
  margin-left: 5.5px;
  margin-top: 4.5px;
  line-height: 21px;
}

.chart-block canvas {
  width: 100% !important;
}
.chart-block .chart-overflow {
  overflow: hidden;
  text-align: center;
}

.candidcahrt {
  width: 100%;
  height: 470px;
}

.amcharts-export-canvas {
  position: absolute;
  display: none;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.amcharts-export-canvas .canvas-container {
  -webkit-transition: opacity 0.5s ease-out;
  transition: opacity 0.5s ease-out;
}
.amcharts-export-canvas.active {
  display: block;
}
.amcharts-export-canvas.dropbox .canvas-container {
  opacity: 0.5;
}

.flot-chart-container {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  width: 100%;
  height: 400px;
}
.flot-chart-container #toggling-series-flot {
  width: 79% !important;
}

.all-chart .flot-chart-container {
  height: 350px;
}

.flot-chart-placeholder {
  width: 100%;
  height: 100%;
  font-size: 14px;
  line-height: 1.2em;
  text-align: center;
}
.flot-chart-placeholder .legend table {
  border-spacing: 5px;
}

.morris-hover {
  position: absolute;
  z-index: 1;
}
.morris-hover.morris-default-style {
  border-radius: 10px;
  padding: 6px;
  color: #898989;
  background: #fff;
  border: solid 1px rgba(106, 113, 133, 0.3);
  font-family: sans-serif;
  font-size: 12px;
  text-align: center;
}
.morris-hover.morris-default-style .morris-hover-row-label {
  font-weight: bold;
  margin: 0.25em 0;
}
.morris-hover.morris-default-style .morris-hover-point {
  white-space: nowrap;
  margin: 0.1em 0;
}

.chart-sparkline {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  background: rgba(49, 49, 49, 0.7);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#99000000, endColorstr=#99000000);
  color: #fff;
  font: 10px "Nunito", sans-serif, sans-serif;
  text-align: left;
  white-space: nowrap;
  padding: 5px;
  border: 1px solid rgba(49, 49, 49, 0.75);
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  z-index: 10000;
  border-radius: 5px;
}
.chart-sparkline .jqsfield {
  color: #fff;
  font: 10px "Nunito", sans-serif, sans-serif;
  text-align: left;
}

.radial-bar {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  background-color: transparent;
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 80px;
  height: 80px;
  font-size: 18px;
  background-clip: content-box;
}
.radial-bar:after {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  content: attr(data-label);
  background-color: #fff;
  z-index: 1;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}
.radial-bar > img {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: #455a64;
  z-index: 3;
  width: 56px;
  height: 56px;
  margin-left: 12px;
  margin-top: 12px;
  line-height: 56px;
}

.radial-bar-danger.radial-bar-20 {
  background-image: linear-gradient(90deg, #efefef 50%, transparent 50%, transparent), linear-gradient(162deg, #E44141 50%, #efefef 50%, #efefef);
}

.radial-bar.radial-bar-lg {
  width: 100px;
  height: 100px;
  font-size: 20px;
}
.radial-bar.radial-bar-sm {
  width: 50px;
  height: 50px;
  font-size: 12px;
}
.radial-bar.radial-bar-xs {
  width: 30px;
  height: 30px;
  font-size: 8px;
}
.pieLabel div {
  color: #fff !important;
}
.pieLabel div input[type=checkbox] {
  margin: 7px;
}

.knob-chart {
  min-height: 255px;
}
.knob-chart .chart-clock-main {
  position: relative;
  width: 200px;
  margin: 45px auto;
}
.knob-chart .chart-clock-main .clock-large {
  position: absolute;
  left: -48px;
  top: -45px;
}
.knob-chart .chart-clock-main .clock-medium {
  position: absolute;
  left: 2px;
  top: 5px;
}
.knob-chart .chart-clock-main .clock-small {
  position: absolute;
  left: 15px;
  top: 55px;
}

.peity-charts svg {
  width: 100%;
  height: 250px;
}
.peity-charts.peity-chart-primary svg polygon {
  fill: rgba(122, 112, 186, 0.2);
}
.peity-charts.peity-chart-primary svg polyline {
  stroke: var(--theme-deafult);
}

.bar-odd-even svg rect:nth-child(odd) {
  fill: var(--theme-deafult);
}
.bar-odd-even svg rect:nth-child(even) {
  fill: var(--theme-secondary);
}

.donut-peity svg path:nth-child(odd) {
  fill: var(--theme-deafult);
}
.donut-peity svg path:nth-child(even) {
  fill: var(--theme-secondary);
}

.small-donut ~ svg path:nth-child(odd),
.small-donut svg path:nth-child(odd),
.donut-peity2 ~ svg path:nth-child(odd),
.donut-peity2 svg path:nth-child(odd),
.pie-peity ~ svg path:nth-child(odd),
.pie-peity svg path:nth-child(odd) {
  fill: var(--theme-deafult);
}
.small-donut ~ svg path:nth-child(even),
.small-donut svg path:nth-child(even),
.donut-peity2 ~ svg path:nth-child(even),
.donut-peity2 svg path:nth-child(even),
.pie-peity ~ svg path:nth-child(even),
.pie-peity svg path:nth-child(even) {
  fill: #efefef;
}

.small-donut2 ~ svg path:nth-child(odd) {
  fill: var(--theme-secondary);
}

.small-donut3 ~ svg path:nth-child(odd) {
  fill: #0DA759;
}

.small-donut4 ~ svg path:nth-child(odd) {
  fill: #16C7F9;
}

.small-donut5 ~ svg path:nth-child(odd) {
  fill: #D77748;
}

.small-donut6 ~ svg path:nth-child(odd) {
  fill: #E44141;
}

p.data-attributes svg {
  margin-right: 10px;
}

.knob-block {
  position: relative;
}
.knob-block input {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  left: 0 !important;
  top: 0 !important;
  margin-left: 0 !important;
  margin-top: -3px !important;
}

.knob-input-disable input {
  display: none !important;
}

.apex-chart {
  margin: 0 auto;
}

.apexcharts-toolbar {
  z-index: 1 !important;
}

.apexcharts-menu-item {
  width: 110px;
}

.word-tree {
  height: 400px;
}

.ct-series-a .ct-slice-pie,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-area {
  fill: var(--theme-deafult);
}
.ct-series-a .ct-point,
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-slice-donut {
  stroke: var(--theme-deafult);
}

.ct-series-b .ct-point,
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-slice-donut {
  stroke: var(--theme-secondary);
}
.ct-series-b .ct-slice-pie,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-area {
  fill: var(--theme-secondary);
}

.ct-series-g .ct-point,
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-slice-donut {
  stroke: #D77748;
}
.ct-series-g .ct-slice-pie,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-area {
  fill: #D77748;
}

.apache-cotainer {
  width: 100%;
  height: 350px;
  margin: 0;
}

.boxplot-cotainer {
  height: 420px;
}

.apache-cotainer-large {
  width: 100%;
  height: 500px;
  margin: 0;
}

.test-buttons button + button {
  margin-left: 10px;
}

.clip-chart-wrapper .test-chart-block {
  margin-bottom: -30px;
}

#echart-gauge .test-chart {
  width: 100%;
  height: 395px;
}

.polar-line {
  height: 350px;
}

#dotted {
  height: 390px;
}

.apexcharts-canvas .apexcharts-tooltip,
.apexcharts-canvas .apexcharts-xaxistooltip,
.apexcharts-canvas .apexcharts-yaxistooltip {
  z-index: 1;
}

@media (max-width: 1346px) {
  .flot-chart-container .flot-chart-placeholder ~ p.float-end {
    float: left !important;
  }
  [dir=rtl] .flot-chart-container .flot-chart-placeholder ~ p.float-end {
    float: right !important;
  }
}

/**=====================
    3.5 Chat CSS End
==========================**/
/**=====================
     14. Chat CSS Start
==========================**/
.status-circle {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 4px;
  left: 44px;
  border-radius: 50%;
  border: 2px solid #fff;
}

.user-image {
  height: 52px;
  margin-right: 5px;
}
.user-image .about {
  float: left;
  margin-top: 5px;
  padding-left: 10px;
}
.user-image .about .name {
  color: #2c323f;
  letter-spacing: 0px;
  font-weight: 500;
}

.left-sidebar-wrapper {
  padding: 20px;
  border-radius: 10px 0 0 10px;
  z-index: 1;
  border: 2px solid var(--white);
  border-right: unset;
  /* ===== Scrollbar CSS ===== */
  /* Firefox */
  /* Chrome, Edge, and Safari */
}
[dir=rtl] .left-sidebar-wrapper {
  border-left: unset;
}
@media (max-width: 767px) {
  .left-sidebar-wrapper {
    border-radius: 10px;
  }
}
.left-sidebar-wrapper * {
  scrollbar-width: auto;
  scrollbar-color: #e0ddff #ffffff;
}
.left-sidebar-wrapper *::-webkit-scrollbar {
  width: 6px;
}
.left-sidebar-wrapper *::-webkit-scrollbar-track {
  background: transparent;
}
.left-sidebar-wrapper *::-webkit-scrollbar-thumb {
  background-color: #e0ddff;
  border-radius: 10px;
  border: 3px solid transparent;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .input-group-text {
  background-color: var(--chart-progress-light);
  padding-right: 0;
  margin-right: 1px;
  border-right: none;
}
[dir=rtl] .left-sidebar-wrapper .left-sidebar-chat .input-group .input-group-text {
  padding-left: 0;
  padding-right: 12px;
  margin-left: 1px;
  border-left: none;
  margin-right: unset;
  border-right: unset;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .input-group-text svg {
  width: 16px;
  height: 16px;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .form-control {
  border-left: unset;
  background-color: var(--chart-progress-light);
}
[dir=rtl] .left-sidebar-wrapper .left-sidebar-chat .input-group .form-control {
  border-right: unset;
  border-left: 1px solid #ddd;
  border-radius: 0.25rem !important;
}
.left-sidebar-wrapper .left-sidebar-chat .input-group .form-control:focus, .left-sidebar-wrapper .left-sidebar-chat .input-group .form-control:focus-visible {
  border-color: var(--chart-solid-border);
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.left-sidebar-wrapper .advance-options ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0;
}
.left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
  color: var(--body-font-color);
  border-bottom: 2px solid transparent;
  background-color: transparent;
  padding: 16px 57px;
  text-decoration: unset;
}
@media (max-width: 1855px) {
  .left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
    padding: 16px 45px;
  }
}
@media (max-width: 1663px) {
  .left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
    padding: 16px 30px;
  }
}
@media (max-width: 1423px) {
  .left-sidebar-wrapper .advance-options ul .nav-item .nav-link {
    padding: 16px 28px;
  }
}
.left-sidebar-wrapper .advance-options ul .nav-item .nav-link:active, .left-sidebar-wrapper .advance-options ul .nav-item .nav-link.active {
  border-bottom: 2px solid var(--theme-deafult);
  color: var(--theme-deafult);
}
.left-sidebar-wrapper .advance-options .common-space {
  padding: 11px 0;
}
.left-sidebar-wrapper .advance-options .common-space p {
  margin: 0;
  color: #8D8D8D;
}
.left-sidebar-wrapper .advance-options .common-space .header-top .btn {
  padding: 6px 12px;
}
.left-sidebar-wrapper .advance-options .common-space .header-top .btn:hover i {
  color: var(--white);
}
.left-sidebar-wrapper .advance-options .chats-user {
  border-bottom: unset;
  height: 559px;
  overflow-y: auto;
}
.left-sidebar-wrapper .advance-options .chats-user li {
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid var(--chart-solid-border);
  cursor: pointer;
  padding: 11px;
}
.left-sidebar-wrapper .advance-options .chats-user li:hover {
  background-color: var(--light-slate);
}
@media (max-width: 1505px) {
  .left-sidebar-wrapper .advance-options .chats-user li {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 1px;
  }
}
.left-sidebar-wrapper .advance-options .chats-user li:last-child {
  border-bottom: unset;
}
.left-sidebar-wrapper .advance-options .chats-user li .chat-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.left-sidebar-wrapper .advance-options .chats-user li .chat-time > div {
  text-align: left;
}
[dir=rtl] .left-sidebar-wrapper .advance-options .chats-user li .chat-time > div {
  text-align: right;
}
@media (max-width: 1498px) {
  .left-sidebar-wrapper .advance-options .chats-user li > div {
    width: 100%;
    text-align: end;
  }
  [dir=rtl] .left-sidebar-wrapper .advance-options .chats-user li > div {
    text-align: start;
  }
}

.active-profile {
  position: relative;
}
.active-profile img {
  width: 42px;
  height: 42px;
}
@media (max-width: 1650px) {
  .active-profile img {
    width: 33px;
    height: 33px;
  }
}
.active-profile .status {
  position: absolute;
  height: 14px;
  width: 14px;
  border-radius: 100%;
  bottom: 5%;
  right: -2px;
  border: 3px solid var(--white);
}
[dir=rtl] .active-profile .status {
  left: -2px;
  right: unset;
}
@media (max-width: 1600px) {
  .active-profile .status {
    width: 11px;
    height: 11px;
    border: 2px solid var(--white);
  }
}

.contact-edit i {
  color: var(--chart-text-color);
  cursor: pointer;
}
.contact-edit i::after {
  content: unset;
}
.contact-edit svg {
  cursor: pointer;
  width: 18px;
  height: 18px;
}
@media (max-width: 360px) {
  .contact-edit svg {
    width: 14px;
    height: 14px;
  }
}
.contact-edit .dropdown-menu .dropdown-item:hover {
  background-color: var(--light-background);
  opacity: 1;
  text-decoration: unset;
}
.contact-edit.chat-alert {
  width: 40px;
  height: 40px;
  background: var(--light-slate);
  display: fke;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
}
@media (max-width: 360px) {
  .contact-edit.chat-alert {
    width: 32px;
    height: 32px;
  }
}
@media (max-width: 340px) {
  .contact-edit.chat-alert {
    display: none;
  }
}

.search-contacts {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
.search-contacts .form-control {
  padding-inline: 35px;
}
.search-contacts .form-control:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
  border-color: var(--chart-solid-border);
}
.search-contacts svg {
  stroke: var(--chart-text-color);
  width: 18px;
  height: 18px;
  left: 10px;
  top: 10px;
  position: absolute;
}
[dir=rtl] .search-contacts svg {
  left: unset;
  right: 10px;
}
.search-contacts .mic-search {
  right: 10px;
  top: 8px;
  position: absolute;
  left: unset;
}
[dir=rtl] .search-contacts .mic-search {
  right: unset;
  left: 10px;
}

.contact-wrapper {
  padding-top: 14px;
  border-bottom: unset;
  height: 526px;
  overflow-y: auto;
}
.contact-wrapper > p {
  line-height: 0;
  margin: 0;
  padding: 12px 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--body-font-color);
}
.contact-wrapper ul li {
  width: 100%;
  cursor: pointer;
}
.contact-wrapper ul li .chat-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.contact-wrapper ul li .chat-time img {
  width: 42px;
  height: 42px;
}
@media (max-width: 1650px) {
  .contact-wrapper ul li .chat-time img {
    width: 33px;
    height: 33px;
  }
}
.contact-wrapper ul li .chat-time > div span {
  color: var(--body-font-color);
}
.contact-wrapper ul li svg {
  width: 18px;
  height: 18px;
}
.contact-wrapper ul:last-child .common-space {
  padding-bottom: unset;
}

.right-sidebar-chat {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  z-index: 0;
  border: 2px solid var(--white);
  border-left: unset;
}
[dir=rtl] .right-sidebar-chat {
  border-right: unset;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@media (max-width: 767px) {
  .right-sidebar-chat {
    border-radius: 10px;
  }
}

.right-sidebar-title {
  padding: 13px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.right-sidebar-title .common-space {
  width: 100%;
}
.right-sidebar-title .common-space .chat-time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.right-sidebar-title .common-space .chat-time .active-profile img {
  width: 42px;
  height: 42px;
}
@media (max-width: 360px) {
  .right-sidebar-title .common-space .chat-time .active-profile img {
    width: 36px;
    height: 36px;
  }
}
.right-sidebar-title .common-space .chat-time div p {
  margin: 0;
  color: var(--chart-text-color);
}
.right-sidebar-title .common-space .chat-time ul li img {
  width: 42px;
  height: 42px;
}
.right-sidebar-title .common-space .chat-time.group-chat ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li img {
  border: 3px solid transparent;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li + li {
  margin-left: -12px;
}
[dir=rtl] .right-sidebar-title .common-space .chat-time.group-chat ul li + li {
  margin-left: unset;
  margin-right: -12px;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li + li img {
  border: 3px solid var(--white);
}
.right-sidebar-title .common-space .chat-time.group-chat ul li img {
  width: 38px;
  height: 38px;
}
.right-sidebar-title .common-space .chat-time.group-chat ul li .profile-count {
  width: 38px;
  height: 38px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(2), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(3), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(5), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(6) {
    display: none;
  }
}
@media (min-width: 341px) and (max-width: 410px) {
  .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(2), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(3), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(5), .right-sidebar-title .common-space .chat-time.group-chat ul li:nth-child(6) {
    display: none;
  }
}
@media (max-width: 1045px) {
  .right-sidebar-title .common-space .chat-time.group-chat > div span,
  .right-sidebar-title .common-space .chat-time.group-chat > div p {
    display: none;
  }
}

.msger-chat {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  overflow-y: auto;
  padding: 10px;
  background: -webkit-gradient(linear, left bottom, left top, from(#F5F6FA), to(#F5F6FA)), -webkit-gradient(linear, left bottom, left top, from(#FFFFFF), to(#FFFFFF));
  background: linear-gradient(0deg, #F5F6FA, #F5F6FA), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  height: 620px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.msg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}

.msg-img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
}
[dir=rtl] .msg-img {
  margin-left: 10px;
  margin-right: unset;
}

.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
}

.msg-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 10px;
}

.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
[dir=rtl] .msg-info-name {
  margin-left: 10px;
  margin-right: unset;
}

.msg-info-time {
  font-size: 0.85em;
}

.left-msg .msg-img {
  /* background-image: url(../../images/4.jpg); */
  width: 33px;
  height: 33px;
}
@media (max-width: 1200px) {
  .left-msg .msg-img {
    min-width: 16px;
    min-height: 16px;
  }
}
.left-msg .msg-bubble {
  position: relative;
  border-radius: 15px;
  background: var(--white);
  color: var(--chart-text-color);
  width: calc(100% - 33px);
}
[dir=rtl] .left-msg .msg-bubble {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: unset;
}

.right-msg {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  color: var(--white);
  background: var(--theme-deafult);
  width: calc(100% - 33px);
}
[dir=rtl] .right-msg .msg-bubble {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: unset;
}
.right-msg .msg-img {
  margin: 0 0 0 10px;
  /* background-image: url(../../images/comment.jpg); */
  width: 33px;
  height: 33px;
}
@media (max-width: 1200px) {
  .right-msg .msg-img {
    min-width: 16px;
    min-height: 16px;
  }
}

.msger-inputarea {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  border-top: 1px solid var(--chart-solid-border);
  background: -webkit-gradient(linear, left bottom, left top, from(#F5F6FA), to(#F5F6FA)), -webkit-gradient(linear, left bottom, left top, from(#FFFFFF), to(#FFFFFF));
  background: linear-gradient(0deg, #F5F6FA, #F5F6FA), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.msger-inputarea input {
  width: 100px;
}
.msger-inputarea .dropdown-toggle::after {
  content: unset;
}
.msger-inputarea .dropdown-toggle .dropdown-menu {
  inset: unset !important;
}
.msger-inputarea .dropdown-form {
  position: relative;
}
.msger-inputarea .dropdown-form i {
  font-size: 16px;
  font-weight: 900;
  color: var(--theme-deafult);
  cursor: pointer;
  padding: 0;
}
.msger-inputarea .dropdown-form .chat-icon {
  position: absolute;
  left: 0 !important;
  bottom: 22px !important;
  background: transparent;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
[dir=rtl] .msger-inputarea .dropdown-form .chat-icon {
  left: unset;
  right: 0;
}
.msger-inputarea .dropdown-form .chat-icon div {
  width: 47px !important;
  height: 47px;
  border-radius: 50%;
  background-color: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  opacity: 1;
}
.msger-inputarea .dropdown-form .chat-icon div svg {
  width: 42px;
  height: 42px;
  cursor: pointer;
}
.msger-inputarea .dropdown-form .chat-icon div:nth-child(1) svg {
  fill: var(--chart-text-color);
  stroke: none;
}
.msger-inputarea .dropdown-form .chat-icon div:nth-child(2) svg {
  stroke: var(--chart-text-color);
  fill: none;
}
.msger-inputarea .dropdown-form .chat-icon div:hover {
  -webkit-box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
          box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
  opacity: 1;
}
.msger-inputarea .dropdown-form .chat-icon div:hover:nth-child(1) svg {
  fill: #3D434A;
}
.msger-inputarea .dropdown-form .chat-icon div:hover:nth-child(2) svg {
  stroke: var(--white);
}
.msger-inputarea .msger-send-btn i {
  color: var(--white);
}
.msger-inputarea * {
  padding: 10px;
  border: none;
  border-radius: 3px;
  font-size: 1em;
}

.msger-input {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background: transparent;
}
.msger-input:focus, .msger-input:focus-visible {
  outline: unset;
}

.fg-emoji-container {
  left: calc(10px + 1395 * (100vw - 320px) / 1600) !important;
}
@media (min-width: 860px) and (max-width: 945px) {
  .fg-emoji-container {
    left: 417px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 417px;
  }
}
@media (min-width: 686px) and (max-width: 860px) {
  .fg-emoji-container {
    left: 360px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 360px;
  }
}
@media (min-width: 588px) and (max-width: 770px) {
  .fg-emoji-container {
    left: 200px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 200px;
  }
}
@media (min-width: 546px) and (max-width: 588px) {
  .fg-emoji-container {
    left: 144px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 144px;
  }
}
@media (min-width: 500px) and (max-width: 546px) {
  .fg-emoji-container {
    left: 88px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 88px;
  }
}
@media (max-width: 500px) {
  .fg-emoji-container {
    left: 22px !important;
    width: 262px !important;
  }
  [dir=rtl] .fg-emoji-container {
    left: unset;
    right: 22px;
  }
}
.fg-emoji-container .fg-emoji-nav {
  background-color: var(--chart-text-color);
}
.fg-emoji-container #fg-emoji-picker-move {
  display: none;
}

.open-emoji {
  padding: 0;
}
.open-emoji button {
  z-index: 0;
  position: relative;
  padding: 0 14px;
  background: transparent;
}
.open-emoji button::before {
  content: "\f118";
  font-family: "FontAwesome";
  z-index: 1;
  position: relative;
  font-size: 20px;
  color: var(--text-gray);
}

.second-btn {
  z-index: 0;
  position: relative;
  padding: 0 14px;
  background: transparent;
}
.second-btn::before {
  content: "\f118";
  font-family: "FontAwesome";
  z-index: 1;
  position: relative;
  font-size: 20px;
  color: var(--text-gray);
}

.msger-send-btn {
  margin-left: 10px;
  background: var(--theme-deafult);
  width: 40px;
  height: 39px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: all 0.23s;
  transition: all 0.23s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
[dir=rtl] .msger-send-btn {
  margin-left: unset;
  margin-right: 10px;
}
.msger-send-btn svg {
  width: 1px;
  height: 12px;
}

.custom-name {
  width: 42px;
  height: 42px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50px;
}
@media (max-width: 1650px) {
  .custom-name {
    width: 33px;
    height: 33px;
  }
}

/**=====================
  14.  Chat CSS Ends
==========================**/
/**=====================
   3.6 Checkout CSS start
==========================**/
.checkout .checkout-details {
  background-color: #f9f9f9;
  border: 1px solid #dddddd;
  padding: 40px;
}
.checkout .checkout-details .order-place {
  margin-top: 15px;
}

.order-box .title-box {
  padding-bottom: 20px;
  color: #444444;
  font-size: 22px;
  border-bottom: 1px solid #ededed;
  margin-bottom: 20px;
}
.order-box .title-box span {
  width: 35%;
  float: right;
  font-weight: 600;
  text-align: right;
}
.order-box .title-box h4 {
  font-weight: 600;
}
.order-box .title-box .checkbox-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.order-box .sub-total li {
  position: relative;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.order-box .sub-total li .count {
  position: relative;
  font-size: 18px;
  line-height: 20px;
  font-weight: 400;
  width: 35%;
  float: right;
  text-align: right;
}
.order-box .sub-total .shipping-class {
  margin-bottom: 12px;
}
.order-box .sub-total .shipping-class .shopping-checkout-option {
  margin-top: -4px;
  position: relative;
  font-size: 18px;
  line-height: 20px;
  color: var(--theme-deafult);
  font-weight: 400;
  width: 35%;
  float: right;
  text-align: right;
}
.order-box .total {
  position: relative;
  margin-bottom: 30px;
}
.order-box .total li {
  position: relative;
  display: block;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  font-size: 18px;
}
.order-box .qty {
  position: relative;
  border-bottom: 1px solid #ededed;
  margin-bottom: 30px;
}
.order-box .qty li {
  position: relative;
  display: block;
  font-size: 15px;
  color: #444444;
  line-height: 20px;
  margin-bottom: 20px;
}
.order-box .qty li span {
  float: right;
  font-size: 18px;
  line-height: 20px;
  color: #232323;
  font-weight: 400;
  width: 35%;
  text-align: right;
}

.radio-option {
  position: relative;
}

.img-paypal {
  width: 30%;
  margin-left: 15px;
}

/**=====================
    86. Checkout CSS Ends
==========================**/
/**=====================
    Responsive CSS Start
==========================**/
@media screen and (max-width: 1199px) {
  .checkout .checkout-details {
    padding: 25px;
  }
  .order-box .title-box span {
    width: 30%;
  }
  .order-box .sub-total li .count {
    width: 38%;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 46%;
  }
  .order-box .qty li span {
    width: 38%;
  }
  .order-box .total li .count {
    width: 38%;
  }
}
@media screen and (max-width: 991px) {
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 38%;
  }
}
@media screen and (max-width: 575px) {
  .checkout .checkout-details {
    padding: 15px;
  }
  .order-box .sub-total .shipping-class .shopping-checkout-option {
    width: 50%;
  }
  .order-box .sub-total li .count {
    width: 50%;
  }
}
/**=====================
   3.6 Checkout CSS ends
==========================**/
/**=====================
    3.7 Comingsoon CSS Start
==========================**/
.comingsoon-bgimg {
  /* background: url(../../images/coming-soon-bg.jpg); */
  background-position: bottom;
  background-size: cover;
}

.comingsoon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
  padding: 50px 0;
}
.comingsoon .comingsoon-inner h5 {
  font-size: 18px;
  letter-spacing: 0px;
  color: #9B9B9B;
  font-weight: 500;
  margin-bottom: 30px;
  margin-top: 30px;
}
.comingsoon .comingsoon-inner .countdown {
  padding: 30px 0;
  border-top: 1px solid var(--theme-deafult);
  border-bottom: 1px solid var(--theme-deafult);
}
.comingsoon .comingsoon-inner .countdown .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 50%;
  color: #fff;
  font-weight: 500;
  width: 100px;
  height: 100px;
  font-size: 36px;
  background: var(--theme-deafult);
}
.comingsoon .comingsoon-inner .countdown .title {
  padding-top: 13px;
  font-size: 14px;
  font-weight: 400;
  color: #9B9B9B;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.comingsoon .comingsoon-inner .countdown ul li {
  display: inline-block;
  text-transform: uppercase;
  margin: 0 20px;
}
.comingsoon .comingsoon-inner .countdown ul li span {
  display: block;
}
.comingsoon video {
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -100;
  -webkit-transform: translateX(-50%) translateY(-50%);
          transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 1s opacity;
  transition: 1s opacity;
}

/**=====================
  3.7 Comingsoon CSS Ends
==========================**/
/**=====================
    3.8 Contacts CSS Start
==========================**/
.alert-contactadd {
  background-color: #fff;
  border: 1px solid var(--theme-deafult);
  width: 12%;
  padding: 10px;
}

.updateimg {
  position: absolute;
  left: 15px;
  width: 100px;
  top: 32px;
  line-height: 93px;
  opacity: 0;
}

#right-history {
  top: 75px;
  right: -320px;
  height: 100%;
  position: fixed;
  width: 320px;
  z-index: 9;
  background-color: #fff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
          box-shadow: 0 0 9px rgba(191, 191, 191, 0.36);
}
[dir=rtl] #right-history {
  right: unset;
  left: -320px;
}
#right-history h6 span a {
  color: #3D434A;
}
#right-history.show {
  right: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
[dir=rtl] #right-history.show {
  right: unset;
  left: 0;
}

.history-details > div {
  padding: 30px 0;
}
.history-details .text-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.history-details .text-center i {
  margin: 0 6px;
}
.history-details .d-flex {
  padding: 30px 15px;
  border-top: 1px solid #f4f4f4;
}
.history-details .d-flex i {
  padding: 5px;
  background: #fed112;
  color: #fff;
  border-radius: 100%;
}
.history-details .d-flex h6 {
  margin-bottom: 5px;
}

.delete-contact {
  display: none;
}

.more-data {
  -webkit-animation: displayTransition 1s;
          animation: displayTransition 1s;
}

.contact-editform {
  padding: 30px;
  -webkit-animation: displayTransition 1s;
          animation: displayTransition 1s;
}
.contact-editform a {
  display: block;
}
.contact-editform button {
  margin-top: 30px;
}
.contact-editform .select2-container .select2-selection--single {
  height: 39px !important;
  border-color: #ced4da !important;
}

#deletemodal .modal-header {
  border-bottom: none;
}
#deletemodal .modal-header .delete-data span svg {
  vertical-align: middle;
  margin-right: 15px;
}

.contact-options li .btn-category {
  line-height: 39px;
  border-radius: 3px;
  padding-left: 40px !important;
  padding: 0;
  color: var(--theme-deafult);
  font-weight: 500;
}

.list-persons p {
  font-family: "Nunito", sans-serif, sans-serif;
}
.list-persons .profile-mail {
  padding: 30px 0;
}
.list-persons .profile-mail .d-flex img {
  height: 100px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 h5 {
  margin-bottom: 5px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul {
  margin-top: 5px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul li {
  display: inline-block;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul li a {
  font-size: 13px;
}
.list-persons .profile-mail .d-flex .flex-grow-1 ul li + li {
  padding-left: 10px;
  margin-left: 5px;
  border-left: 2px solid #f4f4f4;
}
.list-persons .profile-mail .email-general {
  padding-top: 50px;
}
.list-persons .profile-mail .email-general ul {
  padding-right: 20px;
}
.list-persons .profile-mail .email-general ul li {
  color: #898989;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.list-persons .profile-mail .email-general ul li:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}
.list-persons .profile-mail .email-general ul li > span {
  float: right;
}
.list-persons .profile-mail .email-general p span {
  margin-left: 30px;
}
.list-persons .profile-mail .email-general .gender {
  margin-top: 30px;
}

.contacts-tabs .nav-pills {
  border-right: 1px solid #f4f4f4;
  height: 100%;
}
.contacts-tabs .nav-pills .nav-link.active,
.contacts-tabs .nav-pills .nav-link > .nav-link,
.contacts-tabs .nav-pills .show.active,
.contacts-tabs .nav-pills .show > .nav-link {
  background-color: rgba(122, 112, 186, 0.05);
  color: #3D434A;
  
}
.contacts-tabs .nav-pills .nav-link {
  color: #3D434A;
  padding: 0;
}
.contacts-tabs .nav-pills .nav-link .d-flex .img-50 {
  height: 50px;
}
.contacts-tabs .nav-pills .nav-link + .nav-link {
  border-top: 1px solid #f4f4f4;
}
.contacts-tabs .nav-pills .d-flex {
  padding: 20px;
}
.contacts-tabs .tab-content .tab-content-child {
  -webkit-animation: displayTransition 1s;
          animation: displayTransition 1s;
}

@media only screen and (max-width: 991px) {
  #right-history {
    top: 60px;
  }
  .updateimg {
    top: 21px;
  }
  .list-persons .profile-mail {
    padding: 20px;
  }
  .list-persons .profile-mail .email-general .gender {
    margin-top: 20px;
  }
  .contact-editform button {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-editform {
    padding: 20px;
  }
  .contact-editform.pl-0 {
    padding-left: 20px !important;
  }
  .contacts-tabs .nav-pills {
    border-right: none;
    border-bottom: 1px solid #f4f4f4;
  }
}
@media only screen and (max-width: 575px) {
  #right-history {
    width: 270px;
  }
  .updateimg {
    top: 16px;
    left: 29px;
  }
  .contacts-tabs .d-flex img.m-r-20 {
    margin-right: 15px;
  }
  .contacts-tabs .nav-pills .d-flex {
    padding: 15px;
  }
  .list-persons .profile-mail {
    padding: 15px;
  }
  .list-persons .profile-mail .email-general .gender {
    margin-top: 15px;
  }
  .list-persons .nav-pills .nav-link .d-flex .flex-grow-1 {
    text-align: left;
  }
  .contact-editform {
    padding: 15px;
  }
  .contact-editform button {
    margin-top: 15px;
  }
  .contact-editform.pl-0 {
    padding-left: 15px !important;
  }
  .contact-editform form .row > div .row > div > div,
  .contact-options form .row > div .row > div > div {
    margin-bottom: 0;
  }
  .contact-editform form .row > div .row > div + div,
  .contact-options form .row > div .row > div + div {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .updateimg {
    line-height: 73px;
    width: 80px;
  }
  .list-persons .profile-mail .email-general p span {
    margin-left: 10px;
  }
  .list-persons .profile-mail .d-flex {
    display: block !important;
  }
  .list-persons .profile-mail .d-flex img {
    height: 80px;
    width: 80px !important;
    margin-bottom: 15px;
  }
}
.dark-contact .nav .nav-link .d-flex .flex-grow-1 h6 {
  color: var(--body-font-color);
}

/**=====================
   3.8 Contacts CSS Ends
==========================**/
.dashboard-2 .projects table thead tr th {
  padding-bottom: 8px;
  color: #8D8D8D;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.dashboard-2 .projects table thead tr th .form-check input {
  height: 15px;
}
.dashboard-2 .projects table thead tr th .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-2 .projects table thead tr th .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1718px) and (min-width: 1200px) {
  .dashboard-2 .projects table thead tr th:nth-child(5) {
    display: none;
  }
}
.dashboard-2 .projects table thead tr th:first-child, .dashboard-2 .projects table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-2 .projects table thead tr th:first-child {
  min-width: 0px;
}
.dashboard-2 .projects table tbody tr .form-check input {
  height: 15px;
}
.dashboard-2 .projects table tbody tr .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-2 .projects table tbody tr .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dashboard-2 .projects table tbody tr:hover td:nth-child(2) a {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .projects table tbody tr td {
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .projects table tbody tr td:last-child, .dashboard-2 .projects table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
@media (max-width: 680px) {
  .dashboard-2 .projects table tbody tr td:nth-child(2) {
    min-width: 100px;
  }
}
@media (max-width: 1718px) and (min-width: 1200px) {
  .dashboard-2 .projects table tbody tr td:nth-child(5) {
    display: none;
  }
}
.dashboard-2 .projects table tbody tr td a {
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .projects table tbody tr td.radial-chart-wrap {
  height: 30px;
  width: 68px;
}
.dashboard-2 .projects table tbody tr td.radial-chart-wrap > div {
  height: 30px;
  width: 100px;
}
.dashboard-2 .projects table tbody tr td.radial-chart-wrap > div.widgetsChart .apexcharts-canvas {
  height: 80px !important;
}
.dashboard-2 .projects table tbody tr td.radial-chart-wrap > div.widgetsChart .apexcharts-theme-light .apexcharts-graphical .apexcharts-datalabels-group .apexcharts-datalabel-label {
  display: none;
}
.dashboard-2 .projects table tbody tr td .d-flex img {
  margin-left: 0px;
}
.dashboard-2 .projects table tbody tr td .d-flex .flex-shrink-0 img {
  margin-left: 0px;
}
.dashboard-2 .projects table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
@media (max-width: 991px) {
  .dashboard-2 .projects table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
    min-width: 110px;
  }
}
.dashboard-2 .projects table tbody tr td h6 {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  font-weight: 500;
}
.dashboard-2 .projects table tbody tr td span {
  font-size: 13px;
  font-weight: 500;
}
.dashboard-2 .projects table tbody tr .customers {
  min-width: 68px;
}
@media (max-width: 680px) {
  .dashboard-2 .projects table tbody tr .customers {
    min-width: 100px;
  }
}
.dashboard-2 .projects table tbody tr .customers ul {
  display: inline-block;
}
.dashboard-2 .projects table tbody tr .customers ul li img {
  border: 2px solid #fff;
  -webkit-box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .projects table tbody tr .customers ul li img:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
          transform: translateY(-4px) scale(1.02);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .projects table tbody tr .customers ul li + li {
  margin-left: -24% !important;
}
[dir=rtl] .dashboard-2 .projects table tbody tr .customers ul li + li {
  margin-right: -33% !important;
  margin-left: unset;
}
.dashboard-2 .projects table tbody tr .customers ul li:nth-child(4) {
  margin-left: -4px;
}
[dir=rtl] .dashboard-2 .projects table tbody tr .customers ul li:last-child {
  margin-right: -8% !important;
}
.dashboard-2 .projects table tbody tr .customers ul li:last-child p {
  padding: 6px 8px;
  border: 2px solid #fff;
}
.dashboard-2 .projects table tbody tr .customers.avatar-group {
  margin-right: 30px;
}
.dashboard-2 .projects table tbody tr .customers.avatar-group:last-child {
  margin-right: 0;
}
.dashboard-2 .projects table tbody tr:last-child td {
  border-bottom: none !important;
}
.dashboard-2 #total-project {
  min-height: 210px !important;
}
.dashboard-2 .total-project h5 {
  color: #8D8D8D;
}
.dashboard-2 .total-project ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 6px;
}
@media (max-width: 1199px) {
  .dashboard-2 .total-project ul {
    display: none;
  }
}
.dashboard-2 .total-project ul li {
  padding: 12px 16px;
  width: 33.3333333333%;
  border-radius: 4px;
  background-color: #f4f4f4;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 1717px) {
  .dashboard-2 .total-project ul li {
    padding: 12px 4px;
  }
}
.dashboard-2 .total-project ul li:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .total-project ul li span {
  height: 12px;
  width: 12px;
  border-radius: 25px;
}
.dashboard-2 .total-project ul li p {
  color: #3D434A;
  font-weight: 500;
}
@media (max-width: 360px) {
  .dashboard-2 .project-status-col .row .col-6 {
    width: 100%;
  }
}
@media (max-width: 360px) {
  .dashboard-2 .project-status-col .row .col-6:nth-child(3) {
    margin-bottom: 26px;
  }
}
.dashboard-2 .upcoming-box {
  padding: 20px 0px;
  text-align: center;
  margin-bottom: 26px;
}
.dashboard-2 .upcoming-box:hover .upcoming-icon img {
  -webkit-transform: rotateY(360deg);
          transform: rotateY(360deg);
  -webkit-transition: all 0.9s ease-in-out;
  transition: all 0.9s ease-in-out;
}
.dashboard-2 .upcoming-box .upcoming-icon {
  height: 50px;
  width: 50px;
  margin: 0 auto 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
}
.dashboard-2 .upcoming-box .upcoming-icon img {
  -webkit-transform: rotateY(0);
          transform: rotateY(0);
  -webkit-transition: all 0.9s ease-in-out;
  transition: all 0.9s ease-in-out;
}
.dashboard-2 .upcoming-box h6 {
  font-weight: 500;
  color: #8D8D8D;
}
.dashboard-2 .upcoming-box p {
  font-size: 18px;
  color: #3D434A;
  font-weight: 600;
}
@media (max-width: 1614px) {
  .dashboard-2 .upcoming-box p {
    font-size: 14px;
  }
}
.dashboard-2 .designer-card .d-flex {
  margin-bottom: 16px;
}
.dashboard-2 .designer-card .d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
.dashboard-2 .designer-card .d-flex .flex-grow-1 a h5 {
  color: #3D434A;
  font-weight: 500;
}
.dashboard-2 .designer-card .d-flex .flex-grow-1 p {
  color: #8D8D8D;
  font-weight: 500;
}
.dashboard-2 .designer-card .design-button {
  margin-bottom: 28px;
}
@media (max-width: 1844px) and (min-width: 1501) {
  .dashboard-2 .designer-card .design-button {
    margin-bottom: 13px;
  }
}
@media (max-width: 1666px) {
  .dashboard-2 .designer-card .design-button .btn {
    padding: 6px 12px;
  }
}
.dashboard-2 .designer-card .ratting-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin-bottom: 26px;
}
@media (max-width: 1844px) and (min-width: 1501) {
  .dashboard-2 .designer-card .ratting-button {
    margin-bottom: 13px;
  }
}
.dashboard-2 .designer-card .ratting-button .d-flex .flex-shrink-0 {
  width: 32px;
  height: 23px;
  border-radius: 25px;
  background-color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.dashboard-2 .designer-card .ratting-button .d-flex .flex-grow-1 span {
  color: #8D8D8D;
}
.dashboard-2 .designer-card .progress {
  height: 10px;
}
.dashboard-2 .designer-card .progress .progress-bar {
  background-color: transparent !important;
}
.dashboard-2 .task-box > li {
  padding: 11px 16px;
  margin-bottom: 16px;
}
.dashboard-2 .task-box > li:last-child {
  margin-bottom: 0px;
}
.dashboard-2 .task-box > li div h6 {
  padding-bottom: 12px;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers > ul {
  margin-right: -20px;
  display: inline-block;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers > ul li img {
  border: 2px solid #fff;
  -webkit-box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers > ul li img:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
          transform: translateY(-4px) scale(1.02);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers > ul li + li {
  margin-left: -26% !important;
}
[dir=rtl] .dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers > ul li + li {
  margin-right: -26% !important;
  margin-left: unset;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers > ul li:nth-child(4) {
  margin-left: -4px;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers > ul li:last-child p {
  padding: 6px 8px;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers.avatar-group {
  margin-right: 30px;
}
.dashboard-2 .task-box > li .d-flex .flex-shrink-0 .customers.avatar-group:last-child {
  margin-right: 0;
}
.dashboard-2 .task-box > li .d-flex .flex-grow-1 p {
  color: #8D8D8D;
}
.dashboard-2 .task-box > li .d-flex .flex-grow-1 h5 {
  color: #3D434A;
  font-weight: 500;
}
.dashboard-2 .running-box .d-flex {
  padding-bottom: 26px;
}
.dashboard-2 .running-box .d-flex .flex-grow-1 {
  min-width: 141px;
}
.dashboard-2 .running-box .d-flex .flex-grow-1 a h5 {
  color: #3D434A;
  padding-bottom: 4px;
  font-weight: 500;
}
.dashboard-2 .running-box .d-flex .flex-grow-1 p {
  color: #8D8D8D;
  font-weight: 500;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers {
  text-align: end;
}
@media (max-width: 1880px) {
  .dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers {
    display: none;
  }
}
@media (max-width: 1435px) and (min-width: 1200px) {
  .dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers {
    display: none;
  }
}
[dir=rtl] .dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers {
  text-align: center;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul {
  display: inline-block;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul li img {
  border: 2px solid #fff;
  -webkit-box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul li img:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
          transform: translateY(-4px) scale(1.02);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul li + li {
  margin-left: -16% !important;
}
[dir=rtl] .dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul li + li {
  margin-right: -16% !important;
  margin-left: unset !important;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul li:nth-child(4) {
  margin-left: -4px;
}
[dir=rtl] .dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul li:last-child {
  margin-right: -17% !important;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers ul li:last-child p {
  padding: 6px 8px;
  border: 2px solid #fff;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers.avatar-group {
  margin-right: 30px;
}
.dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers.avatar-group:last-child {
  margin-right: 0;
}
.dashboard-2 .running-box p {
  color: #8D8D8D;
  font-weight: 500;
  margin-bottom: 24px;
}
.dashboard-2 .running-box > ul {
  padding-bottom: 26px;
}
.dashboard-2 .running-box > ul li {
  padding: 20px 6px;
  width: 100%;
}
@media (max-width: 1880px) {
  .dashboard-2 .running-box > ul li {
    padding: 18px 6px;
  }
}
@media (max-width: 1435px) {
  .dashboard-2 .running-box > ul li {
    padding: 2px 6px;
  }
}
.dashboard-2 .running-box > ul li h5 {
  font-size: 18px;
  font-weight: 500;
  color: #3D434A;
}
.dashboard-2 .running-box > ul li p {
  color: #8D8D8D;
  font-weight: 500;
}
@media (max-width: 1810px) and (min-width: 1501px) {
  .dashboard-2 .running-box > ul li p {
    display: none;
  }
}
@media (max-width: 1880px) {
  .dashboard-2 .running-box div .btn {
    padding: 6px 8px;
  }
}
@media (max-width: 1435px) {
  .dashboard-2 .running-box div .btn {
    padding: 6px 10px;
  }
}
.dashboard-2 .client-activity table thead tr th {
  padding-bottom: 8px;
  color: #8D8D8D;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.dashboard-2 .client-activity table thead tr th .form-check input {
  height: 15px;
}
.dashboard-2 .client-activity table thead tr th .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-2 .client-activity table thead tr th .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dashboard-2 .client-activity table thead tr th:first-child, .dashboard-2 .client-activity table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-2 .client-activity table thead tr th:first-child {
  min-width: 0px;
}
@media (max-width: 910px) {
  .dashboard-2 .client-activity table thead tr th:nth-child(5) {
    min-width: 90px;
  }
}
@media (max-width: 1780px) and (min-width: 1501px) {
  .dashboard-2 .client-activity table thead tr th:nth-child(4), .dashboard-2 .client-activity table thead tr th:last-child {
    display: none;
  }
}
.dashboard-2 .client-activity table tbody tr .form-check input {
  height: 15px;
}
.dashboard-2 .client-activity table tbody tr .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-2 .client-activity table tbody tr .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dashboard-2 .client-activity table tbody tr:first-child:hover td .d-flex .flex-shrink-0 {
  background-color: rgba(122, 112, 186, 0.1);
  border: 1px solid var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .client-activity table tbody tr:nth-child(2):hover td .d-flex .flex-shrink-0 {
  background-color: rgba(72, 163, 215, 0.1);
  border: 1px solid var(--theme-secondary);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .client-activity table tbody tr:nth-child(3):hover td .d-flex .flex-shrink-0 {
  background-color: rgba(215, 119, 72, 0.1);
  border: 1px solid #D77748;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .client-activity table tbody tr:last-child:hover td .d-flex .flex-shrink-0 {
  background-color: rgba(201, 94, 158, 0.1);
  border: 1px solid #C95E9E;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .client-activity table tbody tr:hover td .d-flex .flex-shrink-0 img {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .client-activity table tbody tr:hover td .d-flex .flex-grow-1.ms-2 a h5 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .client-activity table tbody tr td {
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  font-weight: 500;
  color: #3D434A;
}
@media (max-width: 1780px) and (min-width: 1501px) {
  .dashboard-2 .client-activity table tbody tr td:nth-child(4), .dashboard-2 .client-activity table tbody tr td:last-child {
    display: none;
  }
}
@media (max-width: 991px) {
  .dashboard-2 .client-activity table tbody tr td:last-child {
    min-width: 70px;
  }
}
.dashboard-2 .client-activity table tbody tr td:last-child, .dashboard-2 .client-activity table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-2 .client-activity table tbody tr td .progress {
  height: 8px;
  overflow: visible;
}
.dashboard-2 .client-activity table tbody tr td .d-flex img {
  margin-left: 0px;
}
.dashboard-2 .client-activity table tbody tr td .d-flex .flex-shrink-0 {
  width: 38px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  background-color: rgba(106, 113, 133, 0.07);
  border: 1px solid rgba(106, 113, 133, 0.3);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-2 .client-activity table tbody tr td .d-flex .flex-shrink-0 img {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}
@media (max-width: 904px) {
  .dashboard-2 .client-activity table tbody tr td .d-flex .flex-grow-1.ms-2 {
    min-width: 150px;
  }
}
.dashboard-2 .client-activity table tbody tr td .d-flex .flex-grow-1.ms-2 a h5 {
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
@media (max-width: 991px) {
  .dashboard-2 .client-activity table tbody tr td .d-flex .flex-grow-1.ms-2 a h5 {
    min-width: 110px;
  }
}
.dashboard-2 .client-activity table tbody tr td .d-flex .flex-grow-1.ms-2 p {
  color: #8D8D8D;
}
@media (max-width: 910px) {
  .dashboard-2 .client-activity table tbody tr td.customers {
    min-width: 103px;
  }
}
.dashboard-2 .client-activity table tbody tr td.customers ul {
  display: inline-block;
}
.dashboard-2 .client-activity table tbody tr td.customers ul li img {
  border: 2px solid #fff;
  -webkit-box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
          box-shadow: -2px 2px 4px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .client-activity table tbody tr td.customers ul li img:hover {
  -webkit-transform: translateY(-4px) scale(1.02);
          transform: translateY(-4px) scale(1.02);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.dashboard-2 .client-activity table tbody tr td.customers ul li + li {
  margin-left: -16% !important;
}
[dir=rtl] .dashboard-2 .client-activity table tbody tr td.customers ul li + li {
  margin-right: -16% !important;
  margin-left: unset;
}
.dashboard-2 .client-activity table tbody tr td.customers ul li:nth-child(4) {
  margin-left: -4px;
}
[dir=rtl] .dashboard-2 .client-activity table tbody tr td.customers ul li:last-child {
  margin-right: -1px !important;
}
.dashboard-2 .client-activity table tbody tr td.customers ul li:last-child p {
  padding: 6px 8px;
  border: 2px solid #fff;
}
.dashboard-2 .client-activity table tbody tr td.customers.avatar-group {
  margin-right: 30px;
}
.dashboard-2 .client-activity table tbody tr td.customers.avatar-group:last-child {
  margin-right: 0;
}
.dashboard-2 .client-activity table tbody tr td h6 {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  font-weight: 500;
}
.dashboard-2 .client-activity table tbody tr td span {
  font-size: 13px;
  font-weight: 500;
}
.dashboard-2 .client-activity table tbody tr .project-dot .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}
.dashboard-2 .client-activity table tbody tr .project-dot .d-flex .flex-shrink-0 span {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.dashboard-2 .client-activity table tbody tr:last-child td {
  border-bottom: none;
}
.dashboard-2 .order-container .apexcharts-canvas .apexcharts-marker {
  stroke-width: 4;
}
.dashboard-2 .overview-wrapper {
  position: relative;
  z-index: 1;
}
.dashboard-2 .back-bar-container {
  position: absolute;
  width: calc(100% - 64px);
  bottom: -8px;
  margin: 0 auto !important;
  left: 28px;
}
@media (max-width: 1199px) {
  .dashboard-2 .back-bar-container {
    bottom: 22px;
  }
}
@media (max-width: 480px) {
  .dashboard-2 .back-bar-container {
    width: calc(100% - 34px);
    left: 18px;
  }
}
@media (max-width: 327px) {
  .dashboard-2 .back-bar-container {
    bottom: 42px;
  }
}
.dashboard-2 .back-bar-container {
  position: absolute;
  width: calc(100% - 85px);
  bottom: 21px;
  margin: 0 auto !important;
  left: 51px;
}
@media (max-width: 1794px) {
  .dashboard-2 .back-bar-container {
    bottom: 44px;
  }
}
@media (max-width: 1199px) {
  .dashboard-2 .back-bar-container {
    bottom: 22px;
  }
}
@media (max-width: 480px) {
  .dashboard-2 .back-bar-container {
    width: calc(100% - 34px);
    left: 18px;
  }
}
@media (max-width: 327px) {
  .dashboard-2 .back-bar-container {
    bottom: 42px;
    left: 32px;
  }
}
@media (max-width: 660px) {
  .dashboard-2 .back-bar-container {
    bottom: 46px;
  }
}
.dashboard-2 .overview-card .balance-data {
  right: 12px;
}
[dir=rtl] .dashboard-2 .overview-card .balance-data {
  right: unset;
  left: 12px;
}
@media (max-width: 767px) {
  .dashboard-2 .overview-card .balance-data {
    right: -40%;
  }
  [dir=rtl] .dashboard-2 .overview-card .balance-data {
    left: -40%;
  }
}
@media (max-width: 590px) {
  .dashboard-2 .rinning-col .col-6 {
    width: 100%;
  }
}
.dashboard-2 .running-events {
  overflow: hidden;
  background-image: radial-gradient(49.83% 49.83% at 50% 50.17%, #9B93CD 0%, var(--theme-deafult) 100%);
  border-radius: 10px;
  position: relative;
}
@media (max-width: 590px) {
  .dashboard-2 .running-events {
    display: none;
  }
}
.dashboard-2 .running-events > img {
  position: absolute;
  top: -40px;
  left: 20px;
}
.dashboard-2 .running-events .running-events-image {
  position: absolute;
  top: 38px;
  left: 65px;
  -webkit-animation: bounce_effect 3s infinite ease-in;
          animation: bounce_effect 3s infinite ease-in;
}
@media (max-width: 1666px) and (min-width: 1200px) {
  .dashboard-2 .running-events .running-events-image {
    left: 42px !important;
  }
}
@media (max-width: 1568px) and (min-width: 1501px) {
  .dashboard-2 .timeline-calendar {
    width: 495px;
  }
}
@media (max-width: 1194px) and (min-width: 768px) {
  .dashboard-2 .timeline-calendar {
    width: 495px;
  }
}
@media (max-width: 508px) {
  .dashboard-2 .timeline-calendar {
    width: 495px;
  }
}
.dashboard-2 .timeline-calendar .custom-calendar {
  height: 300px !important;
}
@-webkit-keyframes bounce_effect {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  50% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}
@keyframes bounce_effect {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  50% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}

.dashboard-3 .total-sells .d-flex .flex-shrink-0,
.dashboard-3 .total-sells-4 .d-flex .flex-shrink-0,
.dashboard-3 .total-sells-3 .d-flex .flex-shrink-0,
.dashboard-3 .total-sells-2 .d-flex .flex-shrink-0 {
  width: 52px;
  height: 52px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .dashboard-3 .total-sells .d-flex .flex-shrink-0,
  .dashboard-3 .total-sells-4 .d-flex .flex-shrink-0,
  .dashboard-3 .total-sells-3 .d-flex .flex-shrink-0,
  .dashboard-3 .total-sells-2 .d-flex .flex-shrink-0 {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .dashboard-3 .total-sells .d-flex .flex-shrink-0 img,
  .dashboard-3 .total-sells-4 .d-flex .flex-shrink-0 img,
  .dashboard-3 .total-sells-3 .d-flex .flex-shrink-0 img,
  .dashboard-3 .total-sells-2 .d-flex .flex-shrink-0 img {
    width: 28px;
  }
}
.dashboard-3 .total-sells .d-flex .flex-grow-1 h2,
.dashboard-3 .total-sells-4 .d-flex .flex-grow-1 h2,
.dashboard-3 .total-sells-3 .d-flex .flex-grow-1 h2,
.dashboard-3 .total-sells-2 .d-flex .flex-grow-1 h2 {
  font-weight: 600;
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .dashboard-3 .total-sells .d-flex .flex-grow-1 h2,
  .dashboard-3 .total-sells-4 .d-flex .flex-grow-1 h2,
  .dashboard-3 .total-sells-3 .d-flex .flex-grow-1 h2,
  .dashboard-3 .total-sells-2 .d-flex .flex-grow-1 h2 {
    font-size: 22px;
  }
}
@media (max-width: 1644px) {
  .dashboard-3 .total-sells .d-flex .flex-grow-1 .total-icon,
  .dashboard-3 .total-sells-4 .d-flex .flex-grow-1 .total-icon,
  .dashboard-3 .total-sells-3 .d-flex .flex-grow-1 .total-icon,
  .dashboard-3 .total-sells-2 .d-flex .flex-grow-1 .total-icon {
    display: none !important;
  }
}
.dashboard-3 .total-sells .d-flex .flex-grow-1 .up-arrow,
.dashboard-3 .total-sells-4 .d-flex .flex-grow-1 .up-arrow,
.dashboard-3 .total-sells-3 .d-flex .flex-grow-1 .up-arrow,
.dashboard-3 .total-sells-2 .d-flex .flex-grow-1 .up-arrow {
  height: 18px;
  width: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2px;
}
.dashboard-3 .total-sells .d-flex .flex-grow-1 .up-arrow i,
.dashboard-3 .total-sells-4 .d-flex .flex-grow-1 .up-arrow i,
.dashboard-3 .total-sells-3 .d-flex .flex-grow-1 .up-arrow i,
.dashboard-3 .total-sells-2 .d-flex .flex-grow-1 .up-arrow i {
  font-size: 10px;
  font-weight: 700;
}
.dashboard-3 .total-sells .d-flex .flex-grow-1 p,
.dashboard-3 .total-sells-4 .d-flex .flex-grow-1 p,
.dashboard-3 .total-sells-3 .d-flex .flex-grow-1 p,
.dashboard-3 .total-sells-2 .d-flex .flex-grow-1 p {
  color: #8D8D8D;
  font-weight: 500;
}
@media (max-width: 1520px) {
  .dashboard-3 .total-sells .d-flex .flex-grow-1 p,
  .dashboard-3 .total-sells-4 .d-flex .flex-grow-1 p,
  .dashboard-3 .total-sells-3 .d-flex .flex-grow-1 p,
  .dashboard-3 .total-sells-2 .d-flex .flex-grow-1 p {
    display: block;
    width: 50%;
  }
}
.dashboard-3 .daily-revenue-card h4 {
  font-size: 18px;
  color: #8D8D8D;
}
.dashboard-3 .total-sells .d-flex .flex-shrink-0 {
  background-color: var(--theme-deafult);
}
.dashboard-3 .total-sells .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-deafult);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-3 .total-sells:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-3 .total-sells:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-3 .total-sells-2 .d-flex .flex-shrink-0 {
  background-color: var(--theme-secondary);
}
.dashboard-3 .total-sells-2 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-secondary);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-3 .total-sells-2:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-3 .total-sells-2:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-3 .total-sells-3 .d-flex .flex-shrink-0 {
  background-color: #D77748;
}
.dashboard-3 .total-sells-3 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #D77748;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-3 .total-sells-3:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-3 .total-sells-3:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-3 .total-sells-4 .d-flex .flex-shrink-0 {
  background-color: #C95E9E;
}
.dashboard-3 .total-sells-4 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #C95E9E;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-3 .total-sells-4:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-3 .total-sells-4:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-3 .recent-customers li {
  padding: 16px 0px;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
@media (max-width: 1590px) {
  .dashboard-3 .recent-customers li {
    padding: 14px 0px;
  }
}
@media (max-width: 1484px) {
  .dashboard-3 .recent-customers li {
    padding: 8px 0px;
  }
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .dashboard-3 .recent-customers li {
    padding: 5px 0px;
  }
}
.dashboard-3 .recent-customers li:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}
.dashboard-3 .recent-customers li:hover.d-flex .active-online {
  opacity: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-customers li:hover.d-flex .flex-grow-1 a h5 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-customers li.d-flex {
  position: relative;
}
.dashboard-3 .recent-customers li.d-flex .active-status {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border: 2px solid #fff;
  top: 17px;
  left: 30px;
  border-radius: 100%;
}
@media (max-width: 1400px) and (min-width: 1199px) {
  .dashboard-3 .recent-customers li.d-flex .active-status {
    top: 9px;
    left: 25px;
  }
}
.dashboard-3 .recent-customers li.d-flex .active-online {
  background-color: #337ab7;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-customers li.d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
@media (max-width: 1400px) and (min-width: 1199px) {
  .dashboard-3 .recent-customers li.d-flex .flex-shrink-0 img {
    height: 32px;
  }
}
.dashboard-3 .recent-customers li.d-flex .flex-grow-1 a h5 {
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-customers li.d-flex .flex-grow-1 p {
  color: #8D8D8D;
  padding: 0 6px;
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .dashboard-3 .recent-customers li.d-flex .flex-grow-1 p {
    display: block;
    width: 50%;
  }
}
@media (max-width: 1590px) and (min-width: 1199px) {
  .dashboard-3 .recent-customers li.d-flex div.recent-text {
    display: none;
  }
}
@media (max-width: 718px) and (min-width: 576px) {
  .dashboard-3 .recent-customers li.d-flex div.recent-text {
    display: none;
  }
}
@media (max-width: 354px) {
  .dashboard-3 .recent-customers li.d-flex div.recent-text {
    display: none;
  }
}
.dashboard-3 .recent-customers li.d-flex div.recent-text h5 {
  font-weight: 500;
  color: #3D434A;
}
.dashboard-3 .recent-customers li.d-flex div.recent-text p {
  color: #8D8D8D;
}
.dashboard-3 .product-slider .product-page-main {
  overflow: hidden;
}
@media (max-width: 1660px) and (min-width: 1200px) {
  .dashboard-3 .product-slider .product-page-main .row .col-3 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
    width: 100%;
  }
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right div {
    margin: 0 2px !important;
  }
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right div:first-child {
    margin-left: 0px !important;
  }
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list {
    height: 118px !important;
  }
}
@media (max-width: 1660px) and (min-width: 1200px) and (max-width: 1550px) {
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list {
    height: 109px !important;
  }
}
@media (max-width: 1660px) and (min-width: 1200px) and (max-width: 1458px) {
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list {
    height: 100px !important;
  }
}
@media (max-width: 1660px) and (min-width: 1200px) and (max-width: 1401px) {
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list {
    height: 95px !important;
  }
}
@media (max-width: 1660px) and (min-width: 1200px) and (max-width: 1343px) {
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list {
    height: 91px !important;
  }
}
@media (max-width: 1660px) and (min-width: 1200px) and (max-width: 1210px) {
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list {
    height: 80px !important;
  }
}
@media (max-width: 1660px) and (min-width: 1200px) {
  .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list .slick-track {
    height: 81px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .dashboard-3 .product-slider .product-page-main .row .col-9 {
    width: 100%;
  }
}
.dashboard-3 .product-slider .pro-slide-right {
  margin-bottom: -20px;
}
.dashboard-3 .product-slider .pro-slide-right .slick-list {
  margin-bottom: -20px;
}
@media (max-width: 1870px) {
  .dashboard-3 .product-slider .pro-slide-right .slick-list .slick-track .slick-slide {
    width: auto !important;
  }
}
@media (max-width: 1870px) {
  .dashboard-3 .product-slider .pro-slide-right .slick-list .slick-track .slick-slide .slide-box img {
    height: auto !important;
  }
}
.dashboard-3 .product-slider .pro-slide-right img {
  width: auto;
  height: 72px;
  margin: auto;
  border-radius: 10px;
  background-color: #ddd;
}
.dashboard-3 .product-slider .pro-slide-right .slick-slide {
  width: 70px !important;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .dashboard-3 .product-slider .pro-slide-right .slick-slide {
    margin-bottom: 0px;
  }
}
.dashboard-3 .product-slider .pro-slide-right .slick-slide.slick-current .slide-box img {
  border: 1px solid var(--theme-deafult);
  border-radius: 10px;
}
.dashboard-3 .product-slider .slick-slide img {
  width: 274px !important;
  border-radius: 10px;
  background: #ddd;
  margin-right: 0px;
}
@media (max-width: 1500px) {
  .dashboard-3 .product-slider .pro-slide-right img {
    height: 130px;
  }
}
@media (max-width: 1280px) and (min-width: 1199px) {
  .dashboard-3 .product-slider .pro-slide-right img {
    height: 110px;
  }
}
@media (max-width: 1199px) {
  .dashboard-3 .product-slider .pro-slide-right .slick-slide {
    margin-top: 20px;
  }
  .dashboard-3 .product-slider .pro-slide-right .slide-box {
    margin-right: 5px;
    margin-left: 5px;
  }
  .dashboard-3 .product-slider .pro-slide-right img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: 576px) {
  .dashboard-3 .product-slider .pro-slide-right .slick-slide {
    margin-top: 24px;
  }
}
@media (max-width: 420px) {
  .dashboard-3 .product-slider .pro-slide-right .slick-slide {
    margin-top: 15px;
  }
}
.dashboard-3 .product-slider:hover .product-details a h4 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 1644px) {
  .dashboard-3 .product-slider .product-details {
    margin: 0 !important;
  }
}
.dashboard-3 .product-slider .product-details a h4 {
  padding-bottom: 4px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 1770px) {
  .dashboard-3 .product-slider .product-details a h4 {
    display: block;
    width: 90%;
  }
}
.dashboard-3 .product-slider .product-details h3 {
  font-size: 25px;
  padding-bottom: 10px;
  font-weight: 600;
}
@media (max-width: 1770px) {
  .dashboard-3 .product-slider .product-details h3 {
    display: block;
    width: 90%;
  }
}
.dashboard-3 .product-slider .product-details h5 {
  color: #3D434A;
  padding-bottom: 6px;
  font-weight: 500;
}
.dashboard-3 .product-slider .product-details .product-size {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  padding-bottom: 11px;
}
.dashboard-3 .product-slider .product-details .product-size > li {
  width: 28px;
  height: 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: transparent;
  border: 1px solid #3D434A;
  border-radius: 50%;
}
.dashboard-3 .product-slider .product-details .product-color {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
  padding-bottom: 8px;
}
.dashboard-3 .product-slider .product-details .product-color li {
  border-width: 1px;
  border-style: solid;
}
.dashboard-3 .product-slider .product-details .product-color li span {
  height: 18px;
  width: 18px;
  border-radius: 50px;
  border: 3px solid #fff;
}
.dashboard-3 .product-slider .product-details .product-color li:first-child {
  border-width: 1px;
  border-style: solid;
}
.dashboard-3 .product-slider .product-details .product-color li:first-child span {
  height: 18px;
  width: 18px;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 10px;
}
.dashboard-3 .product-slider .product-details .product-color li:first-child span i {
  color: #fff;
  font-weight: 600;
  font-size: 10px;
}
.dashboard-3 .product-slider .product-details .discount-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.dashboard-3 .product-slider .product-details .discount-box h6 {
  padding: 3px 7px;
  margin-bottom: 10px;
  background: -webkit-gradient(linear, left top, right top, from(#ddd), to(rgba(244, 247, 249, 0)));
  background: linear-gradient(90deg, #ddd 0%, rgba(244, 247, 249, 0) 100%);
  font-weight: 500;
  color: #8D8D8D;
}
.dashboard-3 .product-slider .product-details > .countdown .time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: var(--theme-deafult);
  font-weight: 600;
  font-size: 16px;
}
.dashboard-3 .product-slider .product-details > .countdown .title {
  font-size: 14px;
  font-weight: 500;
  color: #3D434A;
  letter-spacing: 1px;
}
.dashboard-3 .product-slider .product-details > .countdown ul li {
  background-color: #ddd;
  padding: 3px 10px;
  display: inline-block;
  margin: 0 6px 10px;
}
@media (max-width: 1604px) and (min-width: 1199px) {
  .dashboard-3 .product-slider .product-details > .countdown ul li {
    padding: 3px 2px !important;
    margin: 0 2px 0 !important;
  }
}
@media (max-width: 700px) {
  .dashboard-3 .product-slider .product-details > .countdown ul li {
    padding: 3px 2px !important;
    margin: 0 2px 0 !important;
  }
}
.dashboard-3 .product-slider .product-details > .countdown ul li span {
  display: block;
}
.dashboard-3 .product-slider .product-details > .countdown ul li:first-child {
  margin-left: 0px;
}
.dashboard-3 .product-slider .product-details > .countdown ul li:last-child {
  margin-right: 0px;
}
.dashboard-3 .user-continent .user-map-menu {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
@media (max-width: 1590px) {
  .dashboard-3 .user-continent .user-map-menu {
    padding-bottom: 8px;
  }
}
@media (max-width: 1484px) and (min-width: 1200px) {
  .dashboard-3 .user-continent .user-map-menu {
    border-bottom: none;
    display: none !important;
  }
}
@media (max-width: 380px) {
  .dashboard-3 .user-continent .user-map-menu {
    border-bottom: none;
    display: none !important;
  }
}
.dashboard-3 .user-continent .user-map-menu .user-items {
  min-width: 300px;
}
@media (max-width: 1484px) and (min-width: 1200px) {
  .dashboard-3 .user-continent .user-map-menu .user-items {
    display: none;
  }
}
.dashboard-3 .user-continent .user-map-menu .user-items .progress {
  height: 14px;
}
.dashboard-3 .user-continent .user-map-menu .user-items h5 span {
  font-size: 14px;
  color: #8D8D8D;
  padding: 0 4px;
}
@media (max-width: 1870px) and (min-width: 992px) {
  .dashboard-3 .user-continent .user-map-menu .map-items {
    display: none;
  }
}
@media (max-width: 786px) {
  .dashboard-3 .user-continent .user-map-menu .map-items {
    display: none;
  }
}
.dashboard-3 .user-continent .user-map-menu .map-items ul li span {
  height: 10px;
  width: 10px;
  border-radius: 25px;
  margin-right: 4px;
}
[dir=rtl] .dashboard-3 .user-continent .user-map-menu .map-items ul li span {
  margin-left: 4px;
  margin-right: unset;
}
.dashboard-3 .user-continent .user-map-menu .map-items ul li p {
  padding-left: 16px;
  color: #8D8D8D;
}
[dir=rtl] .dashboard-3 .user-continent .user-map-menu .map-items ul li p {
  padding-left: unset;
  padding-right: 16px;
}
.dashboard-3 .user-continent .contries-sale {
  margin-top: 20px;
}
@media (max-width: 1590px) {
  .dashboard-3 .user-continent .contries-sale {
    margin-top: 8px;
  }
}
.dashboard-3 .user-continent .contries-sale .map-value {
  width: 300px;
}
@media (max-width: 1590px) {
  .dashboard-3 .user-continent .contries-sale .map-value {
    margin-top: 8px;
  }
}
@media (max-width: 1580px) and (min-width: 992px) {
  .dashboard-3 .user-continent .contries-sale .map-value {
    display: none;
  }
}
@media (max-width: 600px) {
  .dashboard-3 .user-continent .contries-sale .map-value {
    display: none;
  }
}
.dashboard-3 .user-continent .contries-sale .map-value h5 {
  padding-bottom: 8px;
  font-weight: 600;
}
.dashboard-3 .user-continent .contries-sale .map-value ul li {
  padding: 8px 0;
}
.dashboard-3 .user-continent .contries-sale .map-value ul li .d-flex .flex-shrink-0 svg {
  height: 14px;
  width: 14px;
}
.dashboard-3 .user-continent .contries-sale .map-value ul li .d-flex span {
  color: #8D8D8D;
  font-weight: 500;
}
@media (max-width: 1660px) and (min-width: 1199px) {
  .dashboard-3 .user-continent .contries-sale .map-value ul li .d-flex span {
    display: none;
  }
}
.dashboard-3 .user-continent .contries-sale .map #world-map2 {
  height: 268px;
  width: 400px;
  -webkit-transform: translateY(10px);
          transform: translateY(10px);
}
.dashboard-3 .user-continent .contries-sale .map #world-map2 .jvectormap-container .jvectormap-zoomin,
.dashboard-3 .user-continent .contries-sale .map #world-map2 .jvectormap-container .jvectormap-zoomout {
  display: none;
}
.dashboard-3 .revenue-category .morris-chart g.x-axis text {
  font-size: 14px !important;
}
.dashboard-3 .revenue-category #pie-chart {
  min-height: 290px;
}
@media (max-width: 1600px) {
  .dashboard-3 .revenue-category #pie-chart {
    min-height: 260px;
  }
}
@media (max-width: 1482px) {
  .dashboard-3 .revenue-category #pie-chart {
    min-height: 240px;
  }
}
@media (max-width: 991px) {
  .dashboard-3 .revenue-category #pie-chart {
    min-height: 220px;
  }
}
@media (max-width: 762px) {
  .dashboard-3 .revenue-category #pie-chart {
    min-height: 260px;
  }
}
.dashboard-3 .revenue-category #pie-chart svg text {
  -webkit-transform: scale(1.4) translate(-45px, -40px);
          transform: scale(1.4) translate(-45px, -40px);
}
.dashboard-3 .revenue-category #pie-chart svg text:first-child tspan {
  color: #8D8D8D;
}
.dashboard-3 .revenue-category .donut-legend {
  padding: 20px 0 0;
}
@media (max-width: 1870px) {
  .dashboard-3 .revenue-category .donut-legend {
    padding: 0px 0 0;
  }
}
@media (max-width: 1732px) {
  .dashboard-3 .revenue-category .donut-legend {
    padding: 22px 0 8px;
  }
}
@media (max-width: 1590px) {
  .dashboard-3 .revenue-category .donut-legend {
    padding: 8px 0 8px;
  }
}
@media (max-width: 1200px) {
  .dashboard-3 .revenue-category .donut-legend {
    padding: 16px 0;
  }
}
@media (max-width: 1484px) and (min-width: 1199px) {
  .dashboard-3 .revenue-category .donut-legend {
    display: none;
  }
}
@media (max-width: 762px) {
  .dashboard-3 .revenue-category .donut-legend {
    display: none;
  }
}
.dashboard-3 .revenue-category .donut-legend > span {
  display: inline-block;
  margin-right: 25px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
}
@media (max-width: 1870px) {
  .dashboard-3 .revenue-category .donut-legend > span {
    margin-bottom: 0px;
    margin-right: 10px;
  }
}
.dashboard-3 .revenue-category .donut-legend > span:last-child {
  margin-right: 0;
}
.dashboard-3 .revenue-category .donut-legend > span > i {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 25px;
  margin-right: 7px;
  margin-top: -3px;
  vertical-align: middle;
}
.dashboard-3 #salse-overview .apexcharts-canvas .apexcharts-svg .apexcharts-inner .apexcharts-grid .apexcharts-gridlines-horizontal line:nth-child(odd) {
  display: none !important;
}
.dashboard-3 .recent-orders table thead tr th {
  padding-bottom: 8px;
  color: #8D8D8D;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.dashboard-3 .recent-orders table thead tr th .form-check input {
  height: 15px;
}
.dashboard-3 .recent-orders table thead tr th .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-3 .recent-orders table thead tr th .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 806px) {
  .dashboard-3 .recent-orders table thead tr th:nth-child(3) {
    min-width: 80px;
  }
}
@media (max-width: 1650px) and (min-width: 1199px) {
  .dashboard-3 .recent-orders table thead tr th:nth-child(4), .dashboard-3 .recent-orders table thead tr th:nth-child(5) {
    display: none;
  }
}
.dashboard-3 .recent-orders table thead tr th:first-child, .dashboard-3 .recent-orders table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-3 .recent-orders table thead tr th:first-child {
  min-width: 0px;
}
.dashboard-3 .recent-orders table tbody tr .form-check input {
  height: 15px;
}
.dashboard-3 .recent-orders table tbody tr .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-3 .recent-orders table tbody tr .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dashboard-3 .recent-orders table tbody tr:hover td .d-flex .flex-grow-1 a h6 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-orders table tbody tr:hover td:nth-child(3) {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-orders table tbody tr:hover td p {
  background-color: rgba(122, 112, 186, 0.1);
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-orders table tbody tr td {
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 991px) {
  .dashboard-3 .recent-orders table tbody tr td:last-child {
    min-width: 70px;
  }
}
@media (max-width: 1650px) and (min-width: 1199px) {
  .dashboard-3 .recent-orders table tbody tr td:nth-child(4), .dashboard-3 .recent-orders table tbody tr td:nth-child(5) {
    display: none;
  }
}
.dashboard-3 .recent-orders table tbody tr td:last-child, .dashboard-3 .recent-orders table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-3 .recent-orders table tbody tr td p {
  padding: 4px 8px;
  border-radius: 4px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-orders table tbody tr td > .d-flex .flex-shrink-0 img {
  border-radius: 10px;
}
.dashboard-3 .recent-orders table tbody tr td > .d-flex .flex-grow-1 a h6 {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .recent-orders table tbody tr td.customer-img .d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
@media (max-width: 1402px) and (min-width: 1199px) {
  .dashboard-3 .top-sellers h4 {
    display: block;
    width: 135px;
  }
}
.dashboard-3 .seller-month table thead tr th {
  padding-bottom: 8px;
  color: #8D8D8D;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.dashboard-3 .seller-month table thead tr th .form-check input {
  height: 15px;
}
.dashboard-3 .seller-month table thead tr th .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-3 .seller-month table thead tr th .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 758px) {
  .dashboard-3 .seller-month table thead tr th:nth-child(2) {
    min-width: 86px;
  }
}
@media (max-width: 758px) {
  .dashboard-3 .seller-month table thead tr th:nth-child(3) {
    min-width: 86px;
  }
}
@media (max-width: 1690px) and (min-width: 1200px) {
  .dashboard-3 .seller-month table thead tr th:nth-child(3) {
    display: none;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .dashboard-3 .seller-month table thead tr th:last-child {
    display: none;
  }
}
.dashboard-3 .seller-month table thead tr th:first-child, .dashboard-3 .seller-month table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-3 .seller-month table thead tr th:first-child {
  min-width: 0px;
}
.dashboard-3 .seller-month table tbody tr .form-check input {
  height: 15px;
}
.dashboard-3 .seller-month table tbody tr .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-3 .seller-month table tbody tr .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .dashboard-3 .seller-month table tbody tr:last-child {
    display: none;
  }
}
.dashboard-3 .seller-month table tbody tr:hover {
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 10px 6px rgba(46, 35, 94, 0.07);
          box-shadow: 0px 0px 10px 6px rgba(46, 35, 94, 0.07);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .seller-month table tbody tr:hover td .d-flex .flex-grow-1 a h6 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .seller-month table tbody tr:hover td p {
  background-color: rgba(122, 112, 186, 0.1);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .seller-month table tbody tr:hover td:nth-child(2) {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .seller-month table tbody tr td {
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 991px) {
  .dashboard-3 .seller-month table tbody tr td:last-child {
    min-width: 70px;
  }
}
@media (max-width: 1690px) and (min-width: 1200px) {
  .dashboard-3 .seller-month table tbody tr td:nth-child(3) {
    display: none;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .dashboard-3 .seller-month table tbody tr td:last-child {
    display: none;
  }
}
.dashboard-3 .seller-month table tbody tr td:last-child, .dashboard-3 .seller-month table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-3 .seller-month table tbody tr td p {
  padding: 4px 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  border: 1px solid transparent;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-3 .seller-month table tbody tr td .d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
.dashboard-3 .seller-month table tbody tr td .d-flex .flex-grow-1 a h6 {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@-webkit-keyframes ripple1 {
  0% {
    -webkit-transform: scale(5.5);
            transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(8.5);
            transform: scale(8.5);
    opacity: 0;
  }
}
@keyframes ripple1 {
  0% {
    -webkit-transform: scale(5.5);
            transform: scale(5.5);
    opacity: 0.3;
  }
  100% {
    -webkit-transform: scale(8.5);
            transform: scale(8.5);
    opacity: 0;
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.dashboard-4 .student .d-flex .flex-grow-1 h2,
.dashboard-4 .student-2 .d-flex .flex-grow-1 h2,
.dashboard-4 .student-3 .d-flex .flex-grow-1 h2,
.dashboard-4 .student-4 .d-flex .flex-grow-1 h2 {
  padding-bottom: 24px;
  color: #3D434A;
  font-weight: 600;
}
.dashboard-4 .student .d-flex .flex-grow-1 > p,
.dashboard-4 .student-2 .d-flex .flex-grow-1 > p,
.dashboard-4 .student-3 .d-flex .flex-grow-1 > p,
.dashboard-4 .student-4 .d-flex .flex-grow-1 > p {
  color: #3D434A;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 7px;
}
@media (max-width: 1410px) and (min-width: 1200px) {
  .dashboard-4 .student .d-flex .flex-grow-1 > p,
  .dashboard-4 .student-2 .d-flex .flex-grow-1 > p,
  .dashboard-4 .student-3 .d-flex .flex-grow-1 > p,
  .dashboard-4 .student-4 .d-flex .flex-grow-1 > p {
    display: block;
    width: 88px;
  }
}
.dashboard-4 .student .d-flex .flex-grow-1 .student-arrow,
.dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow,
.dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow,
.dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow {
  color: #8D8D8D;
}
@media (max-width: 1656px) and (min-width: 1199px) {
  .dashboard-4 .student .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: none !important;
  }
}
@media (max-width: 710px) and (min-width: 576px) {
  .dashboard-4 .student .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: none !important;
  }
}
@media (max-width: 347px) {
  .dashboard-4 .student .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow,
  .dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: block;
    width: 88px;
  }
}
.dashboard-4 .student .d-flex .flex-grow-1 .student-arrow .up-arrow,
.dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow .up-arrow,
.dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow .up-arrow,
.dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow .up-arrow {
  height: 18px;
  width: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2px;
}
.dashboard-4 .student .d-flex .flex-grow-1 .student-arrow .up-arrow i,
.dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow .up-arrow i,
.dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow .up-arrow i,
.dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow .up-arrow i {
  font-size: 10px;
  font-weight: 700;
}
.dashboard-4 .student .d-flex .flex-grow-1 .student-arrow span,
.dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow span,
.dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow span,
.dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow span {
  padding: 0 6px;
}
.dashboard-4 .student .d-flex .flex-shrink-0,
.dashboard-4 .student-2 .d-flex .flex-shrink-0,
.dashboard-4 .student-3 .d-flex .flex-shrink-0,
.dashboard-4 .student-4 .d-flex .flex-shrink-0 {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}
.dashboard-4 .student .d-flex .flex-shrink-0 {
  background-color: var(--theme-deafult);
  position: relative;
}
.dashboard-4 .student .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-deafult);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-4 .student:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-4 .student:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-4 .student-2 .d-flex .flex-shrink-0 {
  background-color: var(--theme-secondary);
  position: relative;
}
.dashboard-4 .student-2 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-secondary);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-4 .student-2:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-4 .student-2:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-4 .student-3 .d-flex .flex-shrink-0 {
  background-color: #D77748;
  position: relative;
}
.dashboard-4 .student-3 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #D77748;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-4 .student-3:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-4 .student-3:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-4 .student-4 .d-flex .flex-shrink-0 {
  background-color: #C95E9E;
  position: relative;
}
.dashboard-4 .student-4 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #C95E9E;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.dashboard-4 .student-4:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.dashboard-4 .student-4:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}
.dashboard-4 .enrolled-class li {
  padding: 14px 0;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-4 .enrolled-class li:first-child:hover.d-flex span::before {
  background-color: rgba(122, 112, 186, 0.1);
}
.dashboard-4 .enrolled-class li:first-child:hover.d-flex .flex-grow-1 a h5 {
  color: #337ab7;
}
.dashboard-4 .enrolled-class li:nth-child(2):hover.d-flex span::before {
  background-color: rgba(72, 163, 215, 0.1);
}
.dashboard-4 .enrolled-class li:nth-child(2):hover.d-flex .flex-grow-1 a h5 {
  color: #48A3D7;
}
.dashboard-4 .enrolled-class li:nth-child(3):hover.d-flex span::before {
  background-color: rgba(215, 119, 72, 0.1);
}
.dashboard-4 .enrolled-class li:nth-child(3):hover.d-flex .flex-grow-1 a h5 {
  color: #D77748;
}
.dashboard-4 .enrolled-class li:nth-child(4):hover.d-flex span::before {
  background-color: rgba(201, 94, 158, 0.1);
}
.dashboard-4 .enrolled-class li:nth-child(4):hover.d-flex .flex-grow-1 a h5 {
  color: #C95E9E;
}
.dashboard-4 .enrolled-class li:last-child:hover.d-flex span::before {
  background-color: rgba(13, 167, 89, 0.1);
}
.dashboard-4 .enrolled-class li:last-child:hover.d-flex .flex-grow-1 a h5 {
  color: #0DA759;
}
.dashboard-4 .enrolled-class li:last-child {
  border-bottom: none;
}
.dashboard-4 .enrolled-class li:hover.d-flex span::before {
  width: 100%;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}
.dashboard-4 .enrolled-class li.d-flex {
  position: relative;
}
.dashboard-4 .enrolled-class li.d-flex span {
  height: 50px;
  width: 4px;
  border-radius: 2px;
  border-style: solid;
  border-width: 3px;
}
.dashboard-4 .enrolled-class li.d-flex span::before {
  content: "";
  position: absolute;
  width: 0;
  height: 65%;
  border-radius: 4px;
  top: 14px;
  left: 0;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
}
.dashboard-4 .enrolled-class li.d-flex .flex-grow-1 {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.dashboard-4 .enrolled-class li.d-flex .flex-grow-1 a h5 {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1700px) and (min-width: 1199px) {
  .dashboard-4 .enrolled-class li.d-flex .flex-grow-1 a h5 {
    display: block;
    width: 140px;
  }
}
@media (max-width: 372px) {
  .dashboard-4 .enrolled-class li.d-flex .flex-grow-1 a h5 {
    display: block;
    width: 140px;
  }
}
.dashboard-4 .enrolled-class li.d-flex .flex-grow-1 p {
  color: #8D8D8D;
}
.dashboard-4 .schedult-calendar .schedule-container > div {
  margin-bottom: -40px;
}
.dashboard-4 .schedult-calendar .schedule-container svg path {
  -webkit-clip-path: inset(1% 0% 0% 0% round 1.5rem);
          clip-path: inset(1% 0% 0% 0% round 1.5rem);
}
.dashboard-4 #monthly-reportchart .apexcharts-canvas .apexcharts-svg .apexcharts-inner .apexcharts-line-series .apexcharts-series path {
  stroke: #48A3D7;
}
.dashboard-4 .live-meet .live-metting {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-deafult)), to(var(--theme-secondary)));
  background-image: linear-gradient(90deg, var(--theme-deafult) 0%, var(--theme-secondary) 100%);
  position: relative;
  border-radius: 10px;
  padding-top: 30px;
  text-align: center;
  margin-bottom: 17px;
}
@media (max-width: 1402px) and (min-width: 1300px) {
  .dashboard-4 .live-meet .live-metting {
    padding-top: 18px;
    margin-bottom: 6px;
  }
}
.dashboard-4 .live-meet .live-metting .star-img img {
  position: absolute;
}
.dashboard-4 .live-meet .live-metting .star-img img:first-child {
  top: 40%;
  left: 19%;
  -webkit-animation: move1 4s infinite ease-in;
          animation: move1 4s infinite ease-in;
}
@media (max-width: 1772px) and (min-width: 1401px) {
  .dashboard-4 .live-meet .live-metting .star-img img:first-child {
    top: 35%;
    left: 10%;
  }
}
@media (max-width: 1401px) and (min-width: 1300px) {
  .dashboard-4 .live-meet .live-metting .star-img img:first-child {
    top: 35%;
    left: 6%;
    width: 56px;
  }
}
@media (max-width: 1300px) and (min-width: 1199px) {
  .dashboard-4 .live-meet .live-metting .star-img img:first-child {
    top: 35%;
    left: 6%;
    width: 56px;
  }
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(2) {
  top: 12%;
  left: 26%;
  -webkit-animation: bounce-effect 4s linear infinite;
          animation: bounce-effect 4s linear infinite;
}
@media (max-width: 1401px) and (min-width: 1300px) {
  .dashboard-4 .live-meet .live-metting .star-img img:nth-child(2) {
    width: 36px;
  }
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(3) {
  top: 45%;
  left: 13%;
  -webkit-animation: displayTransition 1s infinite;
          animation: displayTransition 1s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(4) {
  top: 17%;
  left: 20%;
  -webkit-animation: displayTransition 1.5s infinite;
          animation: displayTransition 1.5s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(5) {
  top: 6%;
  left: 39%;
  -webkit-animation: displayTransition 0.8s infinite;
          animation: displayTransition 0.8s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(6) {
  top: 18%;
  left: 55%;
  -webkit-animation: displayTransition 1.8s infinite;
          animation: displayTransition 1.8s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(7) {
  top: 35%;
  right: 13%;
  -webkit-animation: displayTransition 1.7s infinite;
          animation: displayTransition 1.7s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(8) {
  top: 80%;
  right: 18%;
  -webkit-animation: displayTransition 1.2s infinite;
          animation: displayTransition 1.2s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(9) {
  top: 95%;
  left: 17%;
  -webkit-animation: displayTransition 1.9s infinite;
          animation: displayTransition 1.9s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(10) {
  top: 24%;
  left: 13%;
  -webkit-animation: displayTransition 2s infinite;
          animation: displayTransition 2s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(11) {
  top: 7%;
  right: 30%;
  -webkit-animation: displayTransition 2s infinite;
          animation: displayTransition 2s infinite;
}
.dashboard-4 .live-meet .live-metting .star-img img:nth-child(12) {
  top: 58%;
  right: 13%;
  -webkit-animation: displayTransition 1.2s infinite;
          animation: displayTransition 1.2s infinite;
}
.dashboard-4 .live-meet ul li {
  padding-bottom: 14px;
}
@media (max-width: 1402px) and (min-width: 1300px) {
  .dashboard-4 .live-meet ul li {
    padding-bottom: 2px;
  }
}
.dashboard-4 .live-meet ul li:last-child {
  padding-bottom: 0px;
}
.dashboard-4 .live-meet ul li h5 {
  font-size: 18px;
}
@media (max-width: 1552px) and (min-width: 1400px) {
  .dashboard-4 .live-meet ul li h5 {
    display: block;
    width: 80%;
  }
}
@media (max-width: 1402px) and (min-width: 1300px) {
  .dashboard-4 .live-meet ul li h5 {
    font-size: 14px !important;
  }
}
.dashboard-4 .live-meet ul li h5 span {
  color: #8D8D8D;
  padding-right: 8px;
}
[dir=rtl] .dashboard-4 .live-meet ul li h5 span {
  padding-left: 8px;
  padding-right: unset;
}
.dashboard-4 .assignments-table table thead tr th {
  padding-bottom: 8px;
  color: #8D8D8D;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.dashboard-4 .assignments-table table thead tr th .form-check input {
  height: 15px;
}
.dashboard-4 .assignments-table table thead tr th .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-4 .assignments-table table thead tr th .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.dashboard-4 .assignments-table table thead tr th:first-child, .dashboard-4 .assignments-table table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-4 .assignments-table table thead tr th:nth-child(5) {
  min-width: 74px !important;
}
@media (max-width: 1460px) and (min-width: 1199px) {
  .dashboard-4 .assignments-table table thead tr th:nth-child(6) {
    display: none;
  }
}
@media (max-width: 1199px) {
  .dashboard-4 .assignments-table table thead tr th:nth-child(6) {
    min-width: 80px;
  }
}
@media (max-width: 1400px) and (min-width: 1199px) {
  .dashboard-4 .assignments-table table thead tr th:last-child {
    display: none;
  }
}
.dashboard-4 .assignments-table table thead tr th:first-child {
  min-width: 0px;
}
.dashboard-4 .assignments-table table tbody tr .form-check input {
  height: 15px;
}
.dashboard-4 .assignments-table table tbody tr .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-4 .assignments-table table tbody tr .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1400px) and (min-width: 1199px) {
  .dashboard-4 .assignments-table table tbody tr:last-child {
    display: none;
  }
}
.dashboard-4 .assignments-table table tbody tr:hover td span {
  background-color: rgba(122, 112, 186, 0.1);
  border-radius: 5px;
  border-left: 2px solid var(--theme-deafult);
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-4 .assignments-table table tbody tr:hover td .d-flex .active-online {
  opacity: 1;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-4 .assignments-table table tbody tr:hover td .d-flex .flex-grow-1 a h6 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-4 .assignments-table table tbody tr:hover td:nth-child(5) {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 991px) {
  .dashboard-4 .assignments-table table tbody tr td:last-child {
    min-width: 70px;
  }
}
.dashboard-4 .assignments-table table tbody tr td:last-child, .dashboard-4 .assignments-table table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-4 .assignments-table table tbody tr td:nth-child(5) {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 1460px) and (min-width: 1199px) {
  .dashboard-4 .assignments-table table tbody tr td:nth-child(6) {
    display: none;
  }
}
@media (max-width: 1400px) and (min-width: 1199px) {
  .dashboard-4 .assignments-table table tbody tr td:last-child {
    display: none;
  }
}
.dashboard-4 .assignments-table table tbody tr td span {
  padding: 4px 8px;
  border-left: 2px solid transparent;
  display: inline-block;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-4 .assignments-table table tbody tr td .progress {
  height: 8px;
  overflow: visible;
}
.dashboard-4 .assignments-table table tbody tr td .d-flex {
  position: relative;
}
.dashboard-4 .assignments-table table tbody tr td .d-flex .active-status {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border: 2px solid #fff;
  top: 0px;
  left: 30px;
  border-radius: 100%;
}
.dashboard-4 .assignments-table table tbody tr td .d-flex .active-online {
  background-color: #337ab7;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-4 .assignments-table table tbody tr td .d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
.dashboard-4 .assignments-table table tbody tr td .d-flex .flex-grow-1 a h6 {
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.dashboard-4 .featured-table .dataTables_wrapper .dataTables_filter {
  display: none;
}
.dashboard-4 .featured-table table thead tr th {
  padding-bottom: 8px;
  color: #8D8D8D;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.dashboard-4 .featured-table table thead tr th .form-check input {
  height: 15px;
}
.dashboard-4 .featured-table table thead tr th .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-4 .featured-table table thead tr th .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1756px) and (min-width: 1200px) {
  .dashboard-4 .featured-table table thead tr th:nth-child(4) {
    display: none;
  }
}
.dashboard-4 .featured-table table thead tr th:first-child, .dashboard-4 .featured-table table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-4 .featured-table table thead tr th:first-child {
  min-width: 0px;
}
.dashboard-4 .featured-table table tbody tr .form-check input {
  height: 15px;
}
.dashboard-4 .featured-table table tbody tr .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.dashboard-4 .featured-table table tbody tr .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1399px) {
  .dashboard-4 .featured-table table tbody tr:nth-child(3) {
    display: none;
  }
}
.dashboard-4 .featured-table table tbody tr:last-child td {
  border-bottom: none !important;
}
.dashboard-4 .featured-table table tbody tr:hover td .d-flex .flex-grow-1 a h5 {
  color: var(--theme-deafult);
}
.dashboard-4 .featured-table table tbody tr:hover td span svg {
  fill: var(--theme-deafult);
}
@media (max-width: 991px) {
  .dashboard-4 .featured-table table tbody tr td:last-child {
    min-width: 70px;
  }
}
@media (max-width: 1756px) and (min-width: 1200px) {
  .dashboard-4 .featured-table table tbody tr td:nth-child(4) {
    display: none;
  }
}
.dashboard-4 .featured-table table tbody tr td:last-child, .dashboard-4 .featured-table table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.dashboard-4 .featured-table table tbody tr td span {
  cursor: pointer;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.dashboard-4 .featured-table table tbody tr td span svg {
  width: 18px !important;
  height: 18px !important;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.dashboard-4 .featured-table table tbody tr td .d-flex .flex-shrink-0 img {
  border-radius: 8px;
}
.dashboard-4 .featured-table table tbody tr td .d-flex .flex-grow-1 {
  min-width: 160px;
}
@media (max-width: 784px) {
  .dashboard-4 .featured-table table tbody tr td .d-flex .flex-grow-1 {
    min-width: 115px;
  }
}
.dashboard-4 .featured-table table tbody tr td .d-flex .flex-grow-1 a h5 {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.dashboard-4 .featured-table table tbody tr td .d-flex .flex-grow-1 span {
  color: #8D8D8D;
}
@-webkit-keyframes bounce-effect {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  50% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}
@keyframes bounce-effect {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  50% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}
.dashboard-4 .initial-color {
  cursor: pointer;
}
.dashboard-4 .initial-color svg {
  fill: transparent;
  stroke: #8D8D8D;
}
.dashboard-4 .clicked-color {
  cursor: pointer;
}
.dashboard-4 .clicked-color svg {
  fill: var(--theme-deafult);
  stroke: var(--theme-deafult);
}
.dashboard-4 .studay-statistics,
.dashboard-4 .monthly-report {
  position: relative;
}
.dashboard-4 .studay-statistics ul,
.dashboard-4 .monthly-report ul {
  position: absolute;
  top: -40px;
  right: 27px;
}
@media (max-width: 1298px) {
  .dashboard-4 .studay-statistics ul,
  .dashboard-4 .monthly-report ul {
    top: -11px;
    right: -2px;
  }
}
.dashboard-4 .studay-statistics ul li span,
.dashboard-4 .monthly-report ul li span {
  height: 10px;
  width: 10px;
  border-radius: 10px;
  margin: 0 4px;
}
@-webkit-keyframes move1 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-7px);
            transform: translateX(-7px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes move1 {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-7px);
            transform: translateX(-7px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes displayTransition {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(1turn);
            transform: rotate(1turn);
  }
}

.default-dashboard div.dataTables_wrapper,
.dashboard-2 div.dataTables_wrapper,
.dashboard-3 div.dataTables_wrapper,
.dashboard-4 div.dataTables_wrapper {
  position: unset;
}
.default-dashboard div.dataTables_wrapper .dataTables_info,
.dashboard-2 div.dataTables_wrapper .dataTables_info,
.dashboard-3 div.dataTables_wrapper .dataTables_info,
.dashboard-4 div.dataTables_wrapper .dataTables_info {
  padding-left: 24px;
  color: #8D8D8D;
  font-family: "Nunito", sans-serif;
  font-weight: 500;
}
@media (max-width: 1230px) {
  .default-dashboard div.dataTables_wrapper .dataTables_info,
  .dashboard-2 div.dataTables_wrapper .dataTables_info,
  .dashboard-3 div.dataTables_wrapper .dataTables_info,
  .dashboard-4 div.dataTables_wrapper .dataTables_info {
    display: none;
  }
}
.default-dashboard div.dataTables_wrapper .dataTables_paginate,
.dashboard-2 div.dataTables_wrapper .dataTables_paginate,
.dashboard-3 div.dataTables_wrapper .dataTables_paginate,
.dashboard-4 div.dataTables_wrapper .dataTables_paginate {
  margin-right: 24px;
  font-family: "Nunito", sans-serif;
  color: #8D8D8D;
  border: none;
}
.default-dashboard div.dataTables_wrapper .dataTables_paginate .paginate_button,
.dashboard-2 div.dataTables_wrapper .dataTables_paginate .paginate_button,
.dashboard-3 div.dataTables_wrapper .dataTables_paginate .paginate_button,
.dashboard-4 div.dataTables_wrapper .dataTables_paginate .paginate_button {
  border: none;
  padding: 2px 9px;
  border-radius: 3px;
  margin: 0 6px !important;
  font-weight: 500;
}
.default-dashboard div.dataTables_wrapper .dataTables_paginate .paginate_button .disabled:hover,
.dashboard-2 div.dataTables_wrapper .dataTables_paginate .paginate_button .disabled:hover,
.dashboard-3 div.dataTables_wrapper .dataTables_paginate .paginate_button .disabled:hover,
.dashboard-4 div.dataTables_wrapper .dataTables_paginate .paginate_button .disabled:hover {
  border: none !important;
}
.default-dashboard div.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dashboard-2 div.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dashboard-3 div.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dashboard-4 div.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  border: none;
}
.default-dashboard div.dataTables_wrapper .dataTables_length,
.dashboard-2 div.dataTables_wrapper .dataTables_length,
.dashboard-3 div.dataTables_wrapper .dataTables_length,
.dashboard-4 div.dataTables_wrapper .dataTables_length {
  margin-bottom: 26px;
}
.default-dashboard div.dataTables_wrapper .dataTables_length label,
.dashboard-2 div.dataTables_wrapper .dataTables_length label,
.dashboard-3 div.dataTables_wrapper .dataTables_length label,
.dashboard-4 div.dataTables_wrapper .dataTables_length label {
  display: none !important;
}
.default-dashboard div.dataTables_wrapper div.dataTables_filter,
.dashboard-2 div.dataTables_wrapper div.dataTables_filter,
.dashboard-3 div.dataTables_wrapper div.dataTables_filter,
.dashboard-4 div.dataTables_wrapper div.dataTables_filter {
  position: absolute;
  top: 17px;
  right: 53px;
}
@media (max-width: 575px) {
  .default-dashboard div.dataTables_wrapper div.dataTables_filter,
  .dashboard-2 div.dataTables_wrapper div.dataTables_filter,
  .dashboard-3 div.dataTables_wrapper div.dataTables_filter,
  .dashboard-4 div.dataTables_wrapper div.dataTables_filter {
    left: 18px;
    right: unset;
    margin-top: 34px;
  }
}
[dir=rtl] .default-dashboard div.dataTables_wrapper div.dataTables_filter,
[dir=rtl] .dashboard-2 div.dataTables_wrapper div.dataTables_filter,
[dir=rtl] .dashboard-3 div.dataTables_wrapper div.dataTables_filter,
[dir=rtl] .dashboard-4 div.dataTables_wrapper div.dataTables_filter {
  left: 50px;
  right: unset;
}
.default-dashboard div.dataTables_wrapper div.dataTables_filter input[type=search],
.dashboard-2 div.dataTables_wrapper div.dataTables_filter input[type=search],
.dashboard-3 div.dataTables_wrapper div.dataTables_filter input[type=search],
.dashboard-4 div.dataTables_wrapper div.dataTables_filter input[type=search] {
  width: 150px !important;
  height: 34px;
  border-radius: 6px;
  border-style: solid;
}
@media (max-width: 575px) {
  .default-dashboard div.dataTables_wrapper div.dataTables_filter input[type=search],
  .dashboard-2 div.dataTables_wrapper div.dataTables_filter input[type=search],
  .dashboard-3 div.dataTables_wrapper div.dataTables_filter input[type=search],
  .dashboard-4 div.dataTables_wrapper div.dataTables_filter input[type=search] {
    height: 27px;
  }
}
.default-dashboard div.dataTables_wrapper table.dataTable,
.dashboard-2 div.dataTables_wrapper table.dataTable,
.dashboard-3 div.dataTables_wrapper table.dataTable,
.dashboard-4 div.dataTables_wrapper table.dataTable {
  margin-top: 13px !important;
  border: none;
}
@media (max-width: 575px) {
  .default-dashboard div.dataTables_wrapper table.dataTable,
  .dashboard-2 div.dataTables_wrapper table.dataTable,
  .dashboard-3 div.dataTables_wrapper table.dataTable,
  .dashboard-4 div.dataTables_wrapper table.dataTable {
    margin-top: 42px !important;
  }
}
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd,
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even,
.dashboard-2 div.dataTables_wrapper table.dataTable.display tbody tr.odd,
.dashboard-2 div.dataTables_wrapper table.dataTable.display tbody tr.even,
.dashboard-3 div.dataTables_wrapper table.dataTable.display tbody tr.odd,
.dashboard-3 div.dataTables_wrapper table.dataTable.display tbody tr.even,
.dashboard-4 div.dataTables_wrapper table.dataTable.display tbody tr.odd,
.dashboard-4 div.dataTables_wrapper table.dataTable.display tbody tr.even {
  background-color: transparent !important;
}
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
.default-dashboard div.dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1,
.dashboard-2 div.dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
.dashboard-2 div.dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1,
.dashboard-3 div.dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
.dashboard-3 div.dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1,
.dashboard-4 div.dataTables_wrapper table.dataTable.display tbody tr.odd > .sorting_1,
.dashboard-4 div.dataTables_wrapper table.dataTable.display tbody tr.even > .sorting_1 {
  background-color: transparent !important;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead,
.dashboard-2 div.dataTables_wrapper table.dataTable thead,
.dashboard-3 div.dataTables_wrapper table.dataTable thead,
.dashboard-4 div.dataTables_wrapper table.dataTable thead {
  background-color: #ddd;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th,
.dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th,
.dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th,
.dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th {
  border: none !important;
  text-transform: capitalize;
  font-family: "Nunito", sans-serif;
  font-weight: 700;
  padding-top: 9px;
  color: #3D434A;
  padding-bottom: 9px;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:before, .default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:after,
.dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th:before,
.dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th:after,
.dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th:before,
.dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th:after,
.dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th:before,
.dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th:after {
  display: none;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th,
[dir=rtl] .dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th,
[dir=rtl] .dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th,
[dir=rtl] .dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th {
  padding-right: 24px !important;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child,
.dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th:first-child,
.dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th:first-child,
.dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: 24px;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:first-child,
[dir=rtl] .dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th:first-child,
[dir=rtl] .dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th:first-child,
[dir=rtl] .dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th:first-child {
  padding-left: unset;
  padding-right: 24px;
}
.default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:last-child,
.dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th:last-child,
.dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th:last-child,
.dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th:last-child {
  padding-right: 24px;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable thead > tr > th:last-child,
[dir=rtl] .dashboard-2 div.dataTables_wrapper table.dataTable thead > tr > th:last-child,
[dir=rtl] .dashboard-3 div.dataTables_wrapper table.dataTable thead > tr > th:last-child,
[dir=rtl] .dashboard-4 div.dataTables_wrapper table.dataTable thead > tr > th:last-child {
  padding-right: unset;
  padding-left: 24px;
}
.default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td,
.dashboard-2 div.dataTables_wrapper table.dataTable tbody > tr > td,
.dashboard-3 div.dataTables_wrapper table.dataTable tbody > tr > td,
.dashboard-4 div.dataTables_wrapper table.dataTable tbody > tr > td {
  font-family: "Nunito", sans-serif;
  font-weight: 500;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child,
.dashboard-2 div.dataTables_wrapper table.dataTable tbody > tr > td:first-child,
.dashboard-3 div.dataTables_wrapper table.dataTable tbody > tr > td:first-child,
.dashboard-4 div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: 24px;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:first-child,
[dir=rtl] .dashboard-2 div.dataTables_wrapper table.dataTable tbody > tr > td:first-child,
[dir=rtl] .dashboard-3 div.dataTables_wrapper table.dataTable tbody > tr > td:first-child,
[dir=rtl] .dashboard-4 div.dataTables_wrapper table.dataTable tbody > tr > td:first-child {
  padding-left: unset;
  padding-right: 24px;
}
.default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:last-child,
.dashboard-2 div.dataTables_wrapper table.dataTable tbody > tr > td:last-child,
.dashboard-3 div.dataTables_wrapper table.dataTable tbody > tr > td:last-child,
.dashboard-4 div.dataTables_wrapper table.dataTable tbody > tr > td:last-child {
  padding-right: 24px;
}
[dir=rtl] .default-dashboard div.dataTables_wrapper table.dataTable tbody > tr > td:last-child,
[dir=rtl] .dashboard-2 div.dataTables_wrapper table.dataTable tbody > tr > td:last-child,
[dir=rtl] .dashboard-3 div.dataTables_wrapper table.dataTable tbody > tr > td:last-child,
[dir=rtl] .dashboard-4 div.dataTables_wrapper table.dataTable tbody > tr > td:last-child {
  padding-right: unset;
  padding-left: 24px;
}

.default-dashboard .widget-hover:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.default-dashboard .profile-greeting {
  overflow: hidden;
}
.default-dashboard .profile-greeting .card-body {
  background-image: url(../../images/bg.png);
  background-repeat: no-repeat;
  background-position: center right;
  display: block;
  height: 252px;
  padding: 22px 24px;
  position: relative;
}
@media (max-width: 1270px) {
  .default-dashboard .profile-greeting .card-body {
    height: 240px;
  }
}
@media (max-width: 1268px) {
  .default-dashboard .profile-greeting .card-body {
    height: 245px;
  }
}
@media (max-width: 1199px) {
  .default-dashboard .profile-greeting .card-body {
    height: 217px;
    padding: 22px 30px 24px 22px;
    right: -6px;
  }
}
[dir=rtl] .default-dashboard .profile-greeting .card-body {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.default-dashboard .profile-greeting .img-overlay h1 {
  margin-bottom: 8px;
  -webkit-text-fill-color: transparent;
  background: -o-linear-gradient(transparent, transparent);
  -webkit-background-clip: text;
  background-image: -webkit-linear-gradient(transparent, transparent), url(../../images/1.gif);
  background-position: left;
  background-size: 130%;
  -webkit-filter: opacity(86%);
          filter: opacity(86%);
  color: #3D434A;
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 1666px) {
  .default-dashboard .profile-greeting .img-overlay h1 {
    text-align: center;
  }
}
@media (max-width: 1268px) {
  .default-dashboard .profile-greeting .img-overlay h1 {
    margin-bottom: 0px;
  }
}
[dir=rtl] .default-dashboard .profile-greeting .img-overlay h1 {
  text-align: end;
}
.default-dashboard .profile-greeting .img-overlay p {
  /* width: 220px; */
  font-size: 16px;
  margin-bottom: 0;
  font-weight: 500;
  color: #8D8D8D;
}
@media (max-width: 1666px) {
  .default-dashboard .profile-greeting .img-overlay p {
    margin: 0 auto;
    /* width: 275px; */
    text-align: center;
    color: #fff;
  }
}
@media (max-width: 420px) {
  .default-dashboard .profile-greeting .img-overlay p {
    /* width: 215px; */
  }
}
@media (max-width: 788px) and (min-width: 768px) {
  .default-dashboard .profile-greeting .img-overlay p {
    display: none;
  }
}
[dir=rtl] .default-dashboard .profile-greeting .img-overlay p {
  text-align: end;
  margin-right: auto;
}
.default-dashboard .profile-greeting .img-overlay .btn {
  margin-top: 50px;
  background-color: var(--theme-deafult);
  border: 1px solid transparent;
  color: #fff;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 1666px) {
  .default-dashboard .profile-greeting .img-overlay .btn {
    margin: 20px auto 0;
  }
}
@media (max-width: 1270px) {
  .default-dashboard .profile-greeting .img-overlay .btn {
    margin: 10px auto 0;
  }
}
@media (max-width: 1268px) {
  .default-dashboard .profile-greeting .img-overlay .btn {
    margin: 4px auto 0;
  }
}
@media (max-width: 788px) and (min-width: 768px) {
  .default-dashboard .profile-greeting .img-overlay .btn {
    margin: 45px auto 0;
  }
}
[dir=rtl] .default-dashboard .profile-greeting .img-overlay .btn {
  float: left;
}
.default-dashboard .profile-greeting .img-overlay .btn:hover {
  background-color: transparent;
  border: 1px solid var(--theme-deafult);
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
@media (max-width: 1666px) {
  .default-dashboard .profile-greeting .img-overlay .btn:hover {
    background-color: #337ab7;
    color: #fff;
  }
}
.default-dashboard .overview .apexcharts-canvas svg .apexcharts-inner .apexcharts-pie > g circle {
  fill: #ddd !important;
}
.default-dashboard .overview .apexcharts-canvas svg .apexcharts-inner .apexcharts-pie .apexcharts-datalabels-group {
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
}
.default-dashboard .overview .apexcharts-canvas svg .apexcharts-inner .apexcharts-pie .apexcharts-datalabels-group .apexcharts-datalabel-value {
  font-size: 14px;
  font-weight: 500;
  font-family: "Nunito", sans-serif !important;
  fill: #8D8D8D !important;
}
@media (max-width: 1666px) {
  .default-dashboard .shifts-char-box .col-5 {
    width: 100%;
  }
}
@media (max-width: 1200px) {
  .default-dashboard .shifts-char-box .col-7 {
    width: 100%;
  }
  .default-dashboard .shifts-char-box .col-7.shifts-overview {
    margin: 24px 0;
  }
  .default-dashboard .shifts-char-box .col-7.shifts-overview .d-flex {
    padding-bottom: 10px;
  }
}
.default-dashboard .shifts-overview {
  margin: 15px 0px;
  display: grid;
}
@media (max-width: 1666px) and (min-width: 1200px) {
  .default-dashboard .shifts-overview {
    display: none;
  }
}
.default-dashboard .shifts-overview .location-menu.dropdown {
  right: 10px;
}
.default-dashboard .shifts-overview .d-flex .flex-shrink-0 span {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}
.default-dashboard .shifts-overview .d-flex .flex-grow-1 h6 {
  font-weight: 500;
  color: #3D434A;
}
.default-dashboard .shifts-overview .d-flex span {
  color: #8D8D8D;
}
.default-dashboard .projects table thead tr th {
  padding-bottom: 8px;
  color: #8D8D8D;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.default-dashboard .projects table thead tr th .form-check input {
  height: 15px;
}
.default-dashboard .projects table thead tr th .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.default-dashboard .projects table thead tr th .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
@media (max-width: 1370px) and (min-width: 1200px) {
  .default-dashboard .projects table thead tr th:nth-child(5) {
    display: none;
  }
}
@media (max-width: 1270px) and (min-width: 1200px) {
  .default-dashboard .projects table thead tr th:nth-child(6) {
    display: none;
  }
}
.default-dashboard .projects table thead tr th:first-child, .default-dashboard .projects table thead tr th:last-child {
  padding-left: 0px;
  padding-right: 0px;
}
.default-dashboard .projects table thead tr th:first-child {
  min-width: 0px;
}
.default-dashboard .projects table tbody tr .form-check input {
  height: 15px;
}
.default-dashboard .projects table tbody tr .form-check input:checked {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
}
.default-dashboard .projects table tbody tr .form-check input:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.default-dashboard .projects table tbody tr:hover .d-flex .flex-grow-1.ms-2 a h6 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .projects table tbody tr td {
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  font-weight: 500;
  color: #3D434A;
}
@media (max-width: 768px) {
  .default-dashboard .projects table tbody tr td:nth-child(4) {
    min-width: 50px;
  }
}
@media (max-width: 1370px) and (min-width: 1200px) {
  .default-dashboard .projects table tbody tr td:nth-child(5) {
    display: none;
  }
}
[class=box-layout] .default-dashboard .projects table tbody tr td:nth-child(5) {
  display: none;
}
@media (max-width: 1270px) and (min-width: 1200px) {
  .default-dashboard .projects table tbody tr td:nth-child(6) {
    display: none;
  }
}
@media (max-width: 991px) {
  .default-dashboard .projects table tbody tr td:last-child {
    min-width: 70px;
  }
}
.default-dashboard .projects table tbody tr td:last-child, .default-dashboard .projects table tbody tr td:first-child {
  padding-left: 0px;
  padding-right: 0px;
}
.default-dashboard .projects table tbody tr td .d-flex img {
  margin-left: 0px;
}
.default-dashboard .projects table tbody tr td .d-flex .flex-shrink-0 img {
  margin-left: 0px;
}
.default-dashboard .projects table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
@media (max-width: 991px) {
  .default-dashboard .projects table tbody tr td .d-flex .flex-grow-1.ms-2 a h6 {
    min-width: 110px;
  }
}
.default-dashboard .projects table tbody tr td h6 {
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
  font-weight: 500;
}
.default-dashboard .projects table tbody tr td span {
  font-size: 13px;
  font-weight: 500;
}
.default-dashboard .projects table tbody tr .project-dot .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
}
.default-dashboard .projects table tbody tr .project-dot .d-flex .flex-shrink-0 span {
  width: 10px;
  height: 10px;
  border-radius: 50px;
}
.default-dashboard .projects table tbody tr:last-child td {
  border-bottom: none;
}
.default-dashboard .notification-box li {
  padding: 21px 0px;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.default-dashboard .notification-box li:first-child {
  padding-top: 16px;
}
.default-dashboard .notification-box li:last-child {
  padding-bottom: 0px;
  border-bottom: none;
}
.default-dashboard .notification-box li:hover.d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.default-dashboard .notification-box li.d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6px;
}
.default-dashboard .notification-box li.d-flex .flex-shrink-0 {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 a {
  text-decoration: none;
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 a h5 {
  color: #3D434A;
  font-weight: 500;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 a:hover h5 {
  color: var(--theme-deafult);
}
.default-dashboard .notification-box li.d-flex .flex-grow-1 p {
  font-size: 14px;
  color: #9B9B9B;
}
@media (max-width: 1600px) {
  .default-dashboard .notification-box li.d-flex .flex-grow-1 p {
    display: block;
    width: 50%;
  }
}
.default-dashboard .notification-box li.d-flex span {
  font-size: 13px;
  font-weight: 500;
  color: var(--theme-deafult);
}
@media (max-width: 1800px) and (min-width: 1399px) {
  .default-dashboard .notification-box li.d-flex span {
    display: none;
  }
}
@media (max-width: 1100px) and (min-width: 768px) {
  .default-dashboard .notification-box li.d-flex span {
    display: none;
  }
}
@media (max-width: 410px) {
  .default-dashboard .notification-box li.d-flex span {
    display: none;
  }
}
.default-dashboard .active-members table thead tr {
  background-color: #ddd;
}
.default-dashboard .active-members table thead tr th {
  color: #8D8D8D;
  border-width: 0px;
}
.default-dashboard .active-members table thead tr th:first-child {
  padding-left: 24px;
}
.default-dashboard .active-members table thead tr th:last-child {
  padding-right: 24px;
}
@media (max-width: 1800px) and (min-width: 1200px) {
  .default-dashboard .active-members table thead tr th:nth-child(2) {
    display: none;
  }
}
@media (max-width: 903px) and (min-width: 768px) {
  .default-dashboard .active-members table thead tr th:nth-child(2) {
    display: none;
  }
}
@media (max-width: 576px) {
  .default-dashboard .active-members table thead tr th:nth-child(2) {
    min-width: 122px;
  }
}
.default-dashboard .active-members table tbody tr td {
  color: #3D434A;
  font-weight: 500;
  padding: 14px 12px;
}
.default-dashboard .active-members table tbody tr td:first-child {
  padding-left: 24px;
}
.default-dashboard .active-members table tbody tr td:last-child {
  padding-right: 24px;
}
@media (max-width: 1800px) and (min-width: 1200px) {
  .default-dashboard .active-members table tbody tr td:nth-child(2) {
    display: none;
  }
}
[class=box-layout] .default-dashboard .active-members table tbody tr td:nth-child(2) {
  display: none;
}
@media (max-width: 903px) and (min-width: 768px) {
  .default-dashboard .active-members table tbody tr td:nth-child(2) {
    display: none;
  }
}
.default-dashboard .active-members table tbody tr td:hover .d-flex .flex-grow-1 a h5 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .active-members table tbody tr td .d-flex {
  gap: 10px;
}
.default-dashboard .active-members table tbody tr td .d-flex .flex-shrink-0 img {
  border-radius: 50%;
}
@media (max-width: 576px) {
  .default-dashboard .active-members table tbody tr td .d-flex .flex-grow-1 {
    min-width: 109px;
  }
}
.default-dashboard .active-members table tbody tr td .d-flex .flex-grow-1 a h5 {
  color: #3D434A;
  font-weight: 500;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .active-members table tbody tr td .d-flex .flex-grow-1 span {
  color: #8D8D8D;
  font-weight: 400;
}
.default-dashboard .active-members table tbody tr:last-child td {
  border-bottom: none;
}
.default-dashboard .active-members table tbody tr p.members-box {
  padding: 7px 31px;
  border-radius: 5px;
}
@media (max-width: 1420px) {
  .default-dashboard .active-members table tbody tr p.members-box {
    padding: 7px 24px;
  }
}
@media (max-width: 484px) {
  .default-dashboard .appointments .col-5 {
    display: none;
  }
  .default-dashboard .appointments .col-7 {
    width: 100%;
  }
}
.default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
  width: 90%;
  position: absolute;
  top: -46px;
  border-bottom: none;
}
@media (max-width: 1750px) {
  .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
    top: -22px;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
    top: -5px;
  }
}
[class=box-layout] .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
  top: -22px;
}
.default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
  border-left: 1px solid rgba(106, 113, 133, 0.3);
}
@media (max-width: 484px) {
  .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
    border-left: none !important;
  }
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
    border-left: none !important;
  }
}
[dir=rtl] .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
  border-left: unset;
  border-right: 1px solid rgba(106, 113, 133, 0.3);
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
    margin-top: 48px;
  }
}
.default-dashboard .appointments .default-datepicker .datepicker-inline .datepicker {
  padding: 0 0 12px 0;
}
.default-dashboard .appointments .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
  width: 100%;
}
.default-dashboard .appointments .default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action[data-action=prev] {
  left: 0;
}
.default-dashboard .appointments .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
  margin: 18px 0 0;
}
.default-dashboard .appointments .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-current- {
  background-color: var(--theme-deafult);
  color: #fff;
  border-radius: 100%;
}
.default-dashboard .appointments .appointments-user li {
  padding: 18px 0;
}
.default-dashboard .appointments .appointments-user li:hover.d-flex .flex-grow-1 a h5 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .appointments .appointments-user li.d-flex {
  gap: 10px;
}
.default-dashboard .appointments .appointments-user li.d-flex .flex-shrink-0 img {
  border-radius: 50%;
}
.default-dashboard .appointments .appointments-user li.d-flex .flex-grow-1 a h5 {
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .appointments .appointments-user li.d-flex .flex-grow-1 p {
  color: #8D8D8D;
}
.default-dashboard .appointments .appointments-user li.d-flex .flex-grow-1 p span {
  color: var(--theme-deafult);
}
@media (max-width: 1750px) and (min-width: 1200px) {
  .default-dashboard .appointments .appointments-user li.d-flex .flex-grow-1 p {
    display: none;
  }
}
[class=box-layout] .default-dashboard .appointments .appointments-user li.d-flex .flex-grow-1 p {
  display: none;
}
@media (max-width: 660px) {
  .default-dashboard .appointments .appointments-user li.d-flex .flex-grow-1 p {
    display: none;
  }
}
.default-dashboard .sales-product table thead tr {
  background-color: #ddd;
}
.default-dashboard .sales-product table thead tr th {
  color: #3D434A;
  font-weight: 700;
  border-width: 0px;
}
.default-dashboard .sales-product table thead tr th:first-child {
  padding-left: 24px;
}
.default-dashboard .sales-product table thead tr th:last-child {
  padding-right: 24px;
}
.default-dashboard .sales-product table tbody tr td {
  color: #3D434A;
  font-weight: 500;
  padding: 14px 12px;
}
.default-dashboard .sales-product table tbody tr td:first-child {
  padding-left: 24px;
}
.default-dashboard .sales-product table tbody tr td:last-child {
  padding-right: 24px;
}
.default-dashboard .sales-product table tbody tr td:hover .d-flex .flex-shrink-0 {
  background-color: rgba(122, 112, 186, 0.2);
  border: 1px solid var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .sales-product table tbody tr td:hover .d-flex .flex-grow-1 a h5 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .sales-product table tbody tr td .d-flex {
  gap: 10px;
}
.default-dashboard .sales-product table tbody tr td .d-flex .flex-shrink-0 {
  width: 38px;
  height: 38px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 10px;
  background-color: rgba(106, 113, 133, 0.07);
  border: 1px solid rgba(106, 113, 133, 0.1);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.default-dashboard .sales-product table tbody tr td .d-flex .flex-shrink-0 img {
  border-radius: 50%;
}
.default-dashboard .sales-product table tbody tr td .d-flex .flex-grow-1 a h5 {
  color: #3D434A;
  font-weight: 500;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  min-width: 99px;
}
.default-dashboard .sales-product table tbody tr td .progress {
  height: 10px;
}
.default-dashboard .sales-product table tbody tr td .progress .progress-bar {
  background-color: transparent !important;
}
@media (max-width: 1399px) {
  .default-dashboard .sales-product table tbody tr:nth-child(4) td {
    border-bottom: none;
  }
}
.default-dashboard .sales-product table tbody tr:last-child td {
  border-bottom: none;
}
@media (max-width: 1399px) {
  .default-dashboard .sales-product table tbody tr:last-child {
    display: none;
  }
}
.default-dashboard .sales-product table tbody tr p.members-box {
  padding: 7px 31px;
  border-radius: 5px;
}
.default-dashboard .sale-statistic .statistic-icon {
  width: 30% !important;
}
@media (max-width: 780px) {
  .default-dashboard .sale-statistic .statistic-icon {
    display: none;
  }
}
.default-dashboard .sale-statistic .statistic-icon .balance-card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 15px;
  border-radius: 5px;
  gap: 15px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #ddd;
}
@media (max-width: 1600px) {
  .default-dashboard .sale-statistic .statistic-icon .balance-card {
    gap: 5px;
  }
}
@media (max-width: 1400px) {
  .default-dashboard .sale-statistic .statistic-icon .balance-card {
    gap: 0px;
  }
}
@media (max-width: 1199px) {
  .default-dashboard .sale-statistic .statistic-icon .balance-card {
    gap: 8px;
  }
}
.default-dashboard .sale-statistic .statistic-icon .balance-card h5 {
  color: #3D434A;
  font-weight: 600;
}
.default-dashboard .sale-statistic .statistic-icon .balance-card .icon-dropdown .dropdown-toggle {
  color: #3D434A;
}
.default-dashboard .sale-statistic .statistic-icon .balance-card .icon-box {
  width: 43px;
  height: 43px;
  background: #fff;
  -webkit-box-shadow: 0px 71.2527px 51.5055px rgba(229, 229, 245, 0.189815), 0px 42.3445px 28.0125px rgba(229, 229, 245, 0.151852), 0px 21.9866px 14.2913px rgba(229, 229, 245, 0.125), 0px 8.95749px 7.16599px rgba(229, 229, 245, 0.0981481), 0px 2.03579px 3.46085px rgba(229, 229, 245, 0.0601852);
          box-shadow: 0px 71.2527px 51.5055px rgba(229, 229, 245, 0.189815), 0px 42.3445px 28.0125px rgba(229, 229, 245, 0.151852), 0px 21.9866px 14.2913px rgba(229, 229, 245, 0.125), 0px 8.95749px 7.16599px rgba(229, 229, 245, 0.0981481), 0px 2.03579px 3.46085px rgba(229, 229, 245, 0.0601852);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.default-dashboard .sale-statistic .statistic-icon .balance-card .icon-box img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
@media (max-width: 1199px) {
  .default-dashboard .sale-statistic .statistic-icon .balance-card .icon-box {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 1712px) {
  .default-dashboard .sale-statistic .statistic-icon .balance-card {
    padding: 12px;
  }
}
@media (max-width: 1410px) {
  .default-dashboard .opening-box .d-flex {
    display: none !important;
  }
}
[class=box-layout] .default-dashboard .opening-box .d-flex {
  display: none !important;
}
.default-dashboard .opening-box .d-flex h2 {
  font-weight: 600;
}
.default-dashboard .opening-box .up-arrow {
  height: 16px;
  width: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(13, 167, 89, 0.2);
  border-radius: 2px;
}
.default-dashboard .opening-box .up-arrow i {
  color: #0DA759;
  font-size: 10px;
  font-weight: 700;
}
@media (max-width: 1380px) {
  .default-dashboard .location-menu {
    display: none;
  }
}
@-webkit-keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  10% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  70% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  60% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}
@keyframes tada {
  0% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
  10% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  20% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
            transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  70% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  90% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  60% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  80% {
    -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
            transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
  }
}

/**=====================
    3.11 General widget CSS Start
==========================**/
.total-sells .d-flex .flex-shrink-0,
.total-sells-4 .d-flex .flex-shrink-0,
.total-sells-3 .d-flex .flex-shrink-0,
.total-sells-2 .d-flex .flex-shrink-0 {
  width: 52px;
  height: 52px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 4px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .total-sells .d-flex .flex-shrink-0,
  .total-sells-4 .d-flex .flex-shrink-0,
  .total-sells-3 .d-flex .flex-shrink-0,
  .total-sells-2 .d-flex .flex-shrink-0 {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .total-sells .d-flex .flex-shrink-0 img,
  .total-sells-4 .d-flex .flex-shrink-0 img,
  .total-sells-3 .d-flex .flex-shrink-0 img,
  .total-sells-2 .d-flex .flex-shrink-0 img {
    width: 28px;
  }
}
.total-sells .d-flex .flex-grow-1 h2,
.total-sells-4 .d-flex .flex-grow-1 h2,
.total-sells-3 .d-flex .flex-grow-1 h2,
.total-sells-2 .d-flex .flex-grow-1 h2 {
  font-weight: 600;
}
@media (max-width: 1399px) and (min-width: 1199px) {
  .total-sells .d-flex .flex-grow-1 h2,
  .total-sells-4 .d-flex .flex-grow-1 h2,
  .total-sells-3 .d-flex .flex-grow-1 h2,
  .total-sells-2 .d-flex .flex-grow-1 h2 {
    font-size: 22px;
  }
}
@media (max-width: 1644px) {
  .total-sells .d-flex .flex-grow-1 .total-icon,
  .total-sells-4 .d-flex .flex-grow-1 .total-icon,
  .total-sells-3 .d-flex .flex-grow-1 .total-icon,
  .total-sells-2 .d-flex .flex-grow-1 .total-icon {
    display: none !important;
  }
}
.total-sells .d-flex .flex-grow-1 .up-arrow,
.total-sells-4 .d-flex .flex-grow-1 .up-arrow,
.total-sells-3 .d-flex .flex-grow-1 .up-arrow,
.total-sells-2 .d-flex .flex-grow-1 .up-arrow {
  height: 18px;
  width: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2px;
}
.total-sells .d-flex .flex-grow-1 .up-arrow i,
.total-sells-4 .d-flex .flex-grow-1 .up-arrow i,
.total-sells-3 .d-flex .flex-grow-1 .up-arrow i,
.total-sells-2 .d-flex .flex-grow-1 .up-arrow i {
  font-size: 10px;
  font-weight: 700;
}
.total-sells .d-flex .flex-grow-1 p,
.total-sells-4 .d-flex .flex-grow-1 p,
.total-sells-3 .d-flex .flex-grow-1 p,
.total-sells-2 .d-flex .flex-grow-1 p {
  color: #8D8D8D;
  font-weight: 500;
}
@media (max-width: 1520px) {
  .total-sells .d-flex .flex-grow-1 p,
  .total-sells-4 .d-flex .flex-grow-1 p,
  .total-sells-3 .d-flex .flex-grow-1 p,
  .total-sells-2 .d-flex .flex-grow-1 p {
    display: block;
    width: 50%;
  }
}

.daily-revenue-card h4 {
  font-size: 18px;
  color: #8D8D8D;
}

.total-sells .d-flex .flex-shrink-0 {
  background-color: var(--theme-deafult);
}
.total-sells .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-deafult);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.total-sells:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.total-sells:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

.total-sells-2 .d-flex .flex-shrink-0 {
  background-color: var(--theme-secondary);
}
.total-sells-2 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-secondary);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.total-sells-2:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.total-sells-2:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

.total-sells-3 .d-flex .flex-shrink-0 {
  background-color: #D77748;
}
.total-sells-3 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #D77748;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.total-sells-3:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.total-sells-3:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

.total-sells-4 .d-flex .flex-shrink-0 {
  background-color: #C95E9E;
}
.total-sells-4 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #C95E9E;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.total-sells-4:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.total-sells-4:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

.visitor-card .card-header svg {
  width: 18px;
  height: 18px;
  fill: var(--theme-default);
}

.visitors-container {
  margin: 0 -12px -27px -17px;
}
.visitors-container svg .apexcharts-series path {
  -webkit-clip-path: inset(1% 0% 0% 0% round 3rem);
          clip-path: inset(1% 0% 0% 0% round 3rem);
}
.visitors-container svg .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 1410px) {
  .opening-box .d-flex {
    display: none !important;
  }
}
[class=box-layout] .opening-box .d-flex {
  display: none !important;
}
.opening-box .up-arrow {
  height: 16px;
  width: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(13, 167, 89, 0.2);
  border-radius: 2px;
}
.opening-box .up-arrow i {
  color: #0DA759;
  font-size: 10px;
  font-weight: 700;
}

.header-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
}
.header-top a i {
  font-size: 11px;
  margin-bottom: -2px;
}
.header-top a.btn {
  padding: 8px 12px;
}
@media (max-width: 575px) {
  .header-top a.btn {
    padding: 5px;
  }
}
.header-top a.btn.badge-light-primary:hover {
  background-color: var(--theme-deafult);
}

.currency-chart-wrap {
  height: 90px;
}

.order-chart,
.profit-chart {
  height: 100px;
}

.course-box {
  overflow: hidden;
}
.course-box:hover {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.course-box:hover .course-icon svg {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}

.designer-card .d-flex {
  margin-bottom: 16px;
}
@media (max-width: 1844px) and (min-width: 1200px) {
  .designer-card .d-flex {
    margin-bottom: 10px;
  }
}
@media (max-width: 804px) and (min-width: 576px) {
  .designer-card .d-flex {
    margin-bottom: 8px;
  }
}
.designer-card .d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
.designer-card .d-flex .flex-grow-1 a h5 {
  color: #3D434A;
  font-weight: 500;
}
.designer-card .d-flex .flex-grow-1 p {
  color: #8D8D8D;
  font-weight: 500;
}
.designer-card .design-button {
  margin-bottom: 28px;
}
@media (max-width: 1844px) and (min-width: 1200px) {
  .designer-card .design-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 804px) and (min-width: 576px) {
  .designer-card .design-button {
    margin-bottom: 10px;
  }
}
@media (max-width: 1666px) {
  .designer-card .design-button .btn {
    padding: 6px 12px;
  }
}
.designer-card .ratting-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 15px;
  margin-bottom: 26px;
}
@media (max-width: 1844px) and (min-width: 1200px) {
  .designer-card .ratting-button {
    margin-bottom: 8px;
    gap: 6px;
  }
}
@media (max-width: 804px) and (min-width: 576px) {
  .designer-card .ratting-button {
    margin-bottom: 8px;
    gap: 6px;
  }
}
.designer-card .ratting-button .d-flex .flex-shrink-0 {
  width: 32px;
  height: 23px;
  border-radius: 25px;
  background-color: #ddd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.designer-card .ratting-button .d-flex .flex-grow-1 span {
  color: #8D8D8D;
}
.designer-card .progress {
  height: 10px;
}
.designer-card .progress .progress-bar {
  background-color: transparent !important;
}

.redial-social-widget {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 25px;
  margin: 0 auto;
  background-image: -webkit-gradient(linear, left top, right top, color-stop(50%, #f4f4f4), color-stop(50%, transparent), to(transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(106, 113, 133, 0.3)), color-stop(50%, #f4f4f4), to(#f4f4f4));
  background-image: linear-gradient(90deg, #f4f4f4 50%, transparent 50%, transparent), linear-gradient(90deg, rgba(106, 113, 133, 0.3) 50%, #f4f4f4 50%, #f4f4f4);
}
.redial-social-widget i {
  background-color: #fff;
  height: 80px;
  width: 80px;
  border-radius: 100%;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.redial-social-widget.radial-bar-70 {
  background-image: linear-gradient(342deg, var(--theme-deafult) 50%, transparent 50%, transparent), linear-gradient(270deg, var(--theme-deafult) 50%, #f4f4f4 50%, #f4f4f4);
}

.social-img {
  padding: 5px;
  background: var(--white);
  border-radius: 100%;
}
.social-widget .card-body {
  padding: 15px;
}
.social-widget .social-icons {
  min-width: 55px;
  height: 55px;
  background: var(--white);
  -webkit-box-shadow: 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037);
          box-shadow: 0px 42.1092px 27.8569px rgba(41, 72, 152, 0.0303704), 0px 8.90772px 7.12618px rgba(41, 72, 152, 0.0196296), 0px 2.02448px 3.44162px rgba(41, 72, 152, 0.012037);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.social-widget .social-icons img {
  margin-left: 0px;
}
@media (max-width: 1399px) {
  .social-widget .social-icons {
    min-width: 40px;
    height: 40px;
  }
  .social-widget .social-icons img {
    height: 20px;
  }
}
.social-widget .social-content {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.social-widget .social-content h5 {
  font-size: 22px;
  font-weight: 700;
}
.social-widget .social-content span {
  font-weight: 600;
}
.social-widget .social-content .social-chart {
  margin: -34px -42px -13px;
  width: 150px;
}
.social-widget .social-content .social-chart .apexcharts-canvas .apexcharts-datalabels-group .apexcharts-datalabel-label {
  display: none;
}

.student .d-flex .flex-grow-1 h2,
.student-2 .d-flex .flex-grow-1 h2,
.student-3 .d-flex .flex-grow-1 h2,
.student-4 .d-flex .flex-grow-1 h2 {
  padding-bottom: 24px;
  color: #3D434A;
  font-weight: 600;
}
.student .d-flex .flex-grow-1 > p,
.student-2 .d-flex .flex-grow-1 > p,
.student-3 .d-flex .flex-grow-1 > p,
.student-4 .d-flex .flex-grow-1 > p {
  color: #3D434A;
  font-weight: 600;
  font-size: 18px;
  padding-bottom: 7px;
}
@media (max-width: 1410px) and (min-width: 1200px) {
  .student .d-flex .flex-grow-1 > p,
  .student-2 .d-flex .flex-grow-1 > p,
  .student-3 .d-flex .flex-grow-1 > p,
  .student-4 .d-flex .flex-grow-1 > p {
    display: block;
    width: 88px;
  }
}
@media (max-width: 1656px) and (min-width: 1199px) {
  .student .d-flex .flex-grow-1 .student-arrow,
  .student-2 .d-flex .flex-grow-1 .student-arrow,
  .student-3 .d-flex .flex-grow-1 .student-arrow,
  .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: none !important;
  }
}
@media (max-width: 710px) and (min-width: 576px) {
  .student .d-flex .flex-grow-1 .student-arrow,
  .student-2 .d-flex .flex-grow-1 .student-arrow,
  .student-3 .d-flex .flex-grow-1 .student-arrow,
  .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: none !important;
  }
}
@media (max-width: 347px) {
  .student .d-flex .flex-grow-1 .student-arrow,
  .student-2 .d-flex .flex-grow-1 .student-arrow,
  .student-3 .d-flex .flex-grow-1 .student-arrow,
  .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: block;
    width: 88px;
  }
}
.student .d-flex .flex-grow-1 .student-arrow .up-arrow,
.student-2 .d-flex .flex-grow-1 .student-arrow .up-arrow,
.student-3 .d-flex .flex-grow-1 .student-arrow .up-arrow,
.student-4 .d-flex .flex-grow-1 .student-arrow .up-arrow {
  height: 18px;
  width: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 2px;
}
.student .d-flex .flex-grow-1 .student-arrow .up-arrow i,
.student-2 .d-flex .flex-grow-1 .student-arrow .up-arrow i,
.student-3 .d-flex .flex-grow-1 .student-arrow .up-arrow i,
.student-4 .d-flex .flex-grow-1 .student-arrow .up-arrow i {
  font-size: 10px;
  font-weight: 700;
}
.student .d-flex .flex-shrink-0,
.student-2 .d-flex .flex-shrink-0,
.student-3 .d-flex .flex-shrink-0,
.student-4 .d-flex .flex-shrink-0 {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 10px;
}

.student .d-flex .flex-shrink-0 {
  background-color: var(--theme-deafult);
  position: relative;
}
.student .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-deafult);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.student:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.student:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

.student-2 .d-flex .flex-shrink-0 {
  background-color: var(--theme-secondary);
  position: relative;
}
.student-2 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid var(--theme-secondary);
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.student-2:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.student-2:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

.student-3 .d-flex .flex-shrink-0 {
  background-color: #D77748;
  position: relative;
}
.student-3 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #D77748;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.student-3:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.student-3:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

.student-4 .d-flex .flex-shrink-0 {
  background-color: #C95E9E;
  position: relative;
}
.student-4 .d-flex .flex-shrink-0::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px solid #C95E9E;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  -webkit-animation: spin 10s infinite linear;
          animation: spin 10s infinite linear;
}
.student-4:hover .d-flex .flex-shrink-0 img {
  -webkit-animation: tada 1.5s ease infinite;
          animation: tada 1.5s ease infinite;
}
.student-4:hover .d-flex .flex-shrink-0::before {
  opacity: 1;
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .appointments .col-5 {
    display: none;
  }
  .appointments .col-7 {
    width: 100%;
  }
}
@media (max-width: 484px) {
  .appointments .col-5 {
    display: none;
  }
  .appointments .col-7 {
    width: 100%;
  }
}
.appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
  width: 90%;
  position: absolute;
  top: -46px;
  border-bottom: none;
}
@media (max-width: 1750px) {
  .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
    top: -18px;
  }
}
[class=box-layout] .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
  top: -22px;
}
.appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
  border-left: 1px solid rgba(106, 113, 133, 0.3);
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
    border-left: none;
  }
}
@media (max-width: 484px) {
  .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
    border-left: none;
  }
}
[dir=rtl] .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--content {
  border-left: unset;
  border-right: 1px solid rgba(106, 113, 133, 0.3);
}
.appointments .default-datepicker .datepicker-inline .datepicker {
  padding: 0 0 12px 0;
}
.appointments .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
  width: 100%;
}
.appointments .default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action[data-action=prev] {
  left: 0;
}
.appointments .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
  margin: 0;
}
.appointments .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-current- {
  background-color: var(--theme-deafult);
  color: #fff;
  border-radius: 10px;
}
@media (max-width: 1400px) and (min-width: 1200px) {
  .appointments .appointments-user {
    display: none;
  }
}
.appointments .appointments-user li {
  padding: 8px 0;
}
.appointments .appointments-user li:hover.d-flex .flex-grow-1 a h5 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.appointments .appointments-user li.d-flex {
  gap: 10px;
}
.appointments .appointments-user li.d-flex .flex-shrink-0 img {
  border-radius: 50%;
}
.appointments .appointments-user li.d-flex .flex-grow-1 a h5 {
  font-weight: 500;
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.appointments .appointments-user li.d-flex .flex-grow-1 p {
  color: #8D8D8D;
}
.appointments .appointments-user li.d-flex .flex-grow-1 p span {
  color: var(--theme-deafult);
}
@media (max-width: 1750px) and (min-width: 1200px) {
  .appointments .appointments-user li.d-flex .flex-grow-1 p {
    display: none;
  }
}
[class=box-layout] .appointments .appointments-user li.d-flex .flex-grow-1 p {
  display: none;
}
@media (max-width: 660px) {
  .appointments .appointments-user li.d-flex .flex-grow-1 p {
    display: none;
  }
}

.default-datepicker .datepicker-inline .datepicker {
  width: auto;
  background: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  padding: 0;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content {
  padding: 0;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
  margin: 27px 0 0;
  padding: 15px 0;
}
@media (max-width: 1401px) and (min-width: 1200px) {
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names {
    padding: 10px 0;
  }
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--days-names .datepicker--day-name {
  color: #2b2b2b;
  font-size: 14px;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
  height: 35px;
  width: 14%;
  color: #2b2b2b;
}
@media (max-width: 1401px) and (min-width: 1200px) {
  .default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day {
    height: 33px;
  }
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day.-other-month- {
  color: #2b2b2b;
  opacity: 20%;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell-day.-weekend- + .-weekend- {
  color: #E44141;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  z-index: 0;
  letter-spacing: 2px;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-selected- {
  background: var(--theme-deafult);
  color: #fff;
  position: relative;
  -webkit-box-shadow: 0px 0px 13px 0 rgba(122, 112, 186, 0.5);
          box-shadow: 0px 0px 13px 0 rgba(122, 112, 186, 0.5);
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-current- {
  background-color: #0DA759;
  border: none;
  color: #fff;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-current-::after {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  border: 2px solid var(--white);
  background: #E44141;
  right: -3px;
  top: -2px;
  border-radius: 100%;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker--days .datepicker--cells .datepicker--cell.-focus- {
  color: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--content .datepicker-cell .datepicker--cell-month.-current-.-selected- {
  background-color: var(--theme-deafult);
}
.default-datepicker .datepicker-inline .datepicker .datepicker--nav {
  border-bottom: none;
  padding: 0;
  text-transform: capitalize;
  margin-top: 0;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action {
  background-color: transparent;
  width: 22px;
  height: 22px;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action[data-action=prev] {
  position: absolute;
  right: 50px;
}
[dir=rtl] .default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action[data-action=prev] {
  right: unset;
  left: 50px;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action svg {
  width: 22px;
  height: 22px;
}
.default-datepicker .datepicker-inline .datepicker .datepicker--nav .datepicker--nav-action path {
  stroke: var(--chart-text-color);
}
.default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
  color: #2b2b2b;
  font-size: 20px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title {
    font-size: 14px;
  }
}
.default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
  margin-left: 10px;
  font-weight: 500;
  font-size: 20px;
  color: #2b2b2b;
}
@media (max-width: 767px) {
  .default-datepicker .datepicker-inline .datepicker .datepicker--nav-title i {
    font-size: 14px;
  }
}

/**=====================
      3.11 General widget CSS Ends
  ==========================**/
/**=====================
  3.14 Ecommerce CSS Start
==========================**/
/**====== Product Start ======**/
.toggle-data {
  cursor: pointer;
}

.filter-block ul li {
  padding-top: 15px;
  opacity: 0.5;
}
@media (max-width: 1500px) {
  .filter-block ul li {
    padding-top: 10px;
  }
}

.product-wrapper-grid.list-view .modal-content .product-box .product-img {
  width: 50%;
}

.collection-filter-block svg {
  width: 50px;
  height: 25px;
  stroke: var(--theme-deafult);
  margin-right: 10px;
}
.collection-filter-block h5 {
  margin-bottom: 0;
  font-size: 16px;
}
.collection-filter-block p {
  font-size: 13px;
}
.collection-filter-block .flex-grow-1 {
  opacity: 0.5;
}
.collection-filter-block li + li {
  margin-top: 16px;
}
.collection-filter-block ul.pro-services li svg {
  width: auto;
}

.qty-box .btn {
  background-color: transparent !important;
  border-color: rgba(106, 113, 133, 0.3) !important;
  padding: 8px 12px;
}
.qty-box .btn i {
  color: #898989;
}
.qty-box .btn svg {
  width: 14px !important;
  stroke: #2c323f;
}
.qty-box span {
  padding: 0 4px;
}
.qty-box .input-group {
  background-color: rgba(44, 50, 63, 0.1);
}
.qty-box input {
  background-color: rgba(44, 50, 63, 0.1);
  color: #2c323f;
  max-width: 44px;
  padding: 0;
  text-align: center;
  border: none;
  max-height: 37px;
}

.product-social li a {
  color: #898989;
  font-size: 15px;
  border: 1px solid rgba(106, 113, 133, 0.3);
  border-radius: 100%;
  height: 35px;
  width: 35px;
  display: inline-block;
  text-align: center;
  line-height: 2.3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-social li a:hover {
  border-color: #337ab7;
  color: #337ab7;
  background-color: rgba(122, 112, 186, 0.1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-social li:nth-child(n+2) {
  margin-left: 10px;
}
[dir=rtl] .product-social li:nth-child(n+2) {
  margin-left: unset;
  margin-right: 10px;
}

.product-sidebar .filter-section .card .card-header {
  padding: 16px 30px;
  border-radius: 5px;
  border-bottom: none;
}
.product-sidebar .filter-section .card .card-header h6 {
  position: relative;
}
.product-sidebar .filter-section .card .card-header h6 .pull-right i {
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 10px;
  width: 100%;
  height: 10px;
  text-align: right;
  cursor: pointer;
}

.product-wrapper .product-sidebar .filter-section .card .left-filter {
  z-index: 0;
  opacity: 0;
  visibility: hidden;
  height: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  min-width: 320px;
}
.product-wrapper .product-sidebar .filter-section .card .left-filter .product-filter .irs-with-grid {
  margin-bottom: 15px;
}
.product-wrapper .product-grid .product-wrapper-grid {
  margin-left: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.product-wrapper.sidebaron .product-sidebar .filter-section .card .left-filter {
  z-index: 3;
  opacity: 1;
  visibility: visible;
  position: absolute;
  width: 100%;
  background-color: white;
  top: 53px;
  height: auto;
}

.d-none-productlist {
  display: none;
}
.d-none-productlist svg {
  vertical-align: middle;
  cursor: pointer;
}

.product-wrapper-grid.list-view .product-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.product-wrapper-grid.list-view .product-box .product-img {
  width: 20%;
}
.product-wrapper-grid.list-view .product-box .product-details {
  text-align: left;
}

.slider-product {
  padding: 15px 0;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 15px;
}

.products-total {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.grid-options {
  height: 33px;
  margin-left: 10px;
}
.grid-options ul li a {
  position: relative;
}
.grid-options ul li a .line-grid {
  position: absolute;
  width: 4px;
  height: 15px;
  top: 9px;
}
.grid-options ul li a .line-grid-1 {
  left: 12px;
}
.grid-options ul li a .line-grid-2 {
  left: 18px;
}
.grid-options ul li a .line-grid-3 {
  left: 36px;
}
.grid-options ul li a .line-grid-4 {
  left: 42px;
}
.grid-options ul li a .line-grid-5 {
  left: 48px;
}
.grid-options ul li a .line-grid-6 {
  left: 66px;
}
.grid-options ul li a .line-grid-7 {
  left: 72px;
}
.grid-options ul li a .line-grid-8 {
  left: 78px;
}
.grid-options ul li a .line-grid-9 {
  left: 84px;
}
.grid-options ul li a .line-grid-10 {
  left: 103px;
}
.grid-options ul li a .line-grid-11 {
  left: 109px;
}
.grid-options ul li a .line-grid-12 {
  left: 115px;
}
.grid-options ul li a .line-grid-13 {
  left: 121px;
}
.grid-options ul li a .line-grid-14 {
  left: 127px;
}
.grid-options ul li a .line-grid-15 {
  left: 133px;
}

.square-product-setting {
  height: 36px;
  vertical-align: middle;
}
.square-product-setting a {
  color: #3D434A;
}
.square-product-setting .icon-grid {
  padding: 7px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 10px;
}
.square-product-setting .icon-grid svg {
  width: 20px;
  height: 20px;
}

.product-filter .banner-product {
  margin-top: 15px;
}
.product-filter h6 {
  margin-bottom: 15px;
}
.product-filter .color-selector {
  line-height: 0.9;
}
.product-filter .color-selector ul li {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #444;
  border-radius: 100%;
  cursor: pointer;
}
.product-filter .color-selector ul li.white {
  background-color: #fff;
}
.product-filter .color-selector ul li.gray {
  background-color: #9B9B9B;
}
.product-filter .color-selector ul li.black {
  background-color: #000;
}
.product-filter .color-selector ul li.orange {
  background-color: #ffb17a;
}
.product-filter .color-selector ul li.green {
  background-color: #6fb866;
}
.product-filter .color-selector ul li.pink {
  background-color: pink;
}
.product-filter .color-selector ul li.yellow {
  background-color: #f2f896;
}
.product-filter .color-selector ul li.blue {
  background-color: #63b4f2;
}
.product-filter .color-selector ul li.red {
  background-color: #ff647f;
}
.product-filter.new-products {
  margin-top: 20px;
}
.product-filter.new-products button {
  width: auto;
}
.product-filter.new-products .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: -46px;
  right: 0;
}
.product-filter.new-products .owl-theme .owl-nav button:focus {
  outline: transparent;
}
.product-filter.new-products .owl-theme .owl-nav button span {
  font-size: 20px;
}
.product-filter.new-products .owl-theme .owl-nav button span:focus {
  outline-color: transparent;
}
.product-filter.new-products .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: transparent;
  color: inherit;
}
.product-filter.new-products .owl-theme .owl-item .item .product-box .product-details {
  padding: 25px 0;
}
.product-filter.new-products .owl-theme .owl-item .item .product-box + .product-box {
  margin-top: 15px;
}

.select2-drpdwn-product .form-control {
  border: none;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
          box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  margin-bottom: 10px;
  height: 36px;
}

.feature-products form .form-group {
  position: relative;
}
.feature-products form .form-group input {
  margin-bottom: 15px;
  border: none;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
          box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  height: 50px;
  padding-left: 30px;
}
.feature-products form .form-group i {
  position: absolute;
  top: 18px;
  right: 30px;
  color: #898989;
}

.product-box {
  border-radius: 10px;
  overflow: hidden;
}
.product-box .product-details {
  padding: 15px 25px;
}
.product-box .product-details > a {
  color: #3D434A;
}
.product-box .product-details p {
  margin-bottom: 0;
  opacity: 0.8;
}
.product-box .product-details h4 {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 20px;
  font-family: "Nunito", sans-serif;
}
.product-box .product-details h6 {
  color: #3D434A;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.product-box .product-details span {
  color: #898989;
}
.product-box .product-details .rating i {
  font-size: 16px;
  letter-spacing: 3px;
  color: #ffa800;
}
.product-box .modal .modal-header .product-box .product-details {
  padding: 25px 0;
}
.product-box .modal .modal-header .product-box .product-details h6 {
  text-transform: capitalize;
}
.product-box .modal .modal-header .product-box .product-details .product-price {
  font-size: 22px;
  margin-bottom: 10px;
}
.product-box .modal .modal-header .product-box .product-details .product-view {
  padding: 20px 0;
  border-top: 1px dotted #59667a;
  border-bottom: 1px dotted #59667a;
}
.product-box .modal .modal-header .product-box .product-details .product-size {
  margin: 20px 0;
}
.product-box .modal .modal-header .product-box .product-details .product-size ul li {
  display: inline-block;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset {
  margin-bottom: 20px;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group {
  width: 35%;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group .btn {
  padding: 5px 12px;
}
.product-box .modal .modal-header .product-box .product-details .product-qnty fieldset .input-group .btn-primary_ {
  background-color: #59667a !important;
  border: 1px solid #59667a !important;
}
.product-box .modal .modal-header .btn-close {
  position: absolute;
  right: 15px;
  top: 10px;
}
.product-box .product-img {
  position: relative;
}
.product-box .product-img .product-hover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-box .product-img .product-hover ul li {
  display: inline-block;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
          box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
  padding: 10px 13px;
  background-color: #fff;
  font-size: 18px;
  border-radius: 10px;
  height: 45px;
  width: 45px;
  margin: 0 3px;
  cursor: pointer;
}
.product-box .product-img .product-hover ul li i {
  font-size: 18px;
}
.product-box .product-img .product-hover ul li .btn {
  padding: 0;
}
.product-box .product-img .product-hover ul li:hover {
  background-color: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-box .product-img .product-hover ul li:hover .btn,
.product-box .product-img .product-hover ul li:hover i {
  color: #fff;
}
.product-box:hover .product-hover {
  opacity: 1;
  border-radius: 0%;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.product-page-main {
  padding: 30px;
}
.product-page-main .owl-item .item {
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  background-color: #f8f8f8;
}
.product-page-main .owl-item.current .item {
  border: 1px solid var(--theme-deafult);
}
.product-page-main .product-slider {
  margin-bottom: 20px;
}
@media (min-width: 1400px) {
  .product-page-main .product-slider img {
    height: 520px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
@media (min-width: 1500px) {
  .product-page-main .product-slider img {
    height: 400px;
    -o-object-fit: cover;
       object-fit: cover;
  }
}
.product-page-main .product-color {
  margin-top: 10px;
}

.product-page-details h4 {
  color: #3D434A;
  text-transform: uppercase;
  font-size: 18px;
}
.product-page-details span {
  padding-left: 15px;
}

.br-theme-fontawesome-stars .br-widget a,
.br-theme-fontawesome-stars .br-widget a.br-selected:after,
.br-theme-fontawesome-stars .br-widget a.br-active:after {
  color: #ffa800 !important;
  font: normal normal normal 14px/1 FontAwesome;
}

.product-price {
  font-size: 18px;
  font-weight: 700;
  margin-top: 5px;
  color: var(--theme-deafult);
}
.product-price del {
  color: rgba(44, 50, 63, 0.4);
  padding-left: 10px;
  font-size: 80%;
}

.product-color li {
  display: inline-block;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  margin: 0 2px;
}

.product-page-main p {
  font-size: 15px;
}
.product-page-main .nav-link {
  text-transform: uppercase;
  font-weight: 500;
}

/**====== Product  Ends ======**/
.payment-opt li {
  display: inline-block;
}
.payment-opt li img {
  height: 20px;
  margin: 15px 15px 0 0;
}

/**=====================
  3.14 Ecommerce CSS End
==========================**/
@media screen and (max-width: 1660px) and (min-width: 1200px) {
  .owl-carousel .owl-item img {
    max-width: 420px;
    margin: 0 auto;
  }
}
/**=====================
  3.15 Email-application CSS Start
==========================**/
.email-wrap .row .col-xl-3 + .col-xl-3 {
  padding-right: 0;
}
.email-wrap .row .col-xl-6 {
  padding-left: 0;
}
.email-wrap .dropdown .dropdown-toggle {
  background-color: rgba(115, 102, 255, 0.08);
  color: #337ab7;
  border-radius: 10px;
  padding: 5px 10px;
  width: 100px;
  text-align: left;
}
[dir=rtl] .email-wrap .dropdown .dropdown-toggle {
  text-align: right;
}
.email-wrap .dropdown .dropdown-toggle:after {
  position: absolute;
  top: 50%;
  right: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f107";
  border: none;
  font-family: "FontAwesome";
  font-size: 18px;
  margin: 0;
}
[dir=rtl] .email-wrap .dropdown .dropdown-toggle:after {
  right: unset;
  left: 10px;
}
.email-wrap .dropdown .dropdown-menu {
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
}
.email-wrap .dropdown .dropdown-menu .dropdown-item {
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  padding: 6px 12px;
  border-top: 1px solid #f5f5f5;
  background: #fff;
  line-height: 19px;
}
.email-wrap .dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #fff;
}
.email-wrap .email-app-sidebar .d-flex img {
  border: 2px solid rgba(106, 113, 133, 0.3);
}
.email-wrap .email-app-sidebar .main-menu > li {
  width: 100%;
  text-align: left;
}
.email-wrap .email-app-sidebar .main-menu > li a {
  line-height: 39px;
  text-transform: uppercase;
  border-radius: 3px;
}
.email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: 10px;
}
.email-wrap .email-app-sidebar .main-menu > li a.active > a {
  color: #000;
}
.email-wrap .email-app-sidebar .main-menu > li a:hover {
  background-color: #f5f6f9;
  text-decoration: none;
}
.email-wrap .email-app-sidebar ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 20px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.email-wrap .email-app-sidebar ul li a > .title {
  width: 100%;
  color: #2c323f;
}
.email-wrap .email-app-sidebar ul li a > .badge {
  -ms-flex-item-align: center;
      align-self: center;
  color: #898989;
}
.email-wrap .email-left-aside {
  float: left;
  width: 100%;
}
.email-wrap .email-left-aside .card-body {
  padding: 20px;
}
@media (min-width: 1200px) {
  .email-wrap .email-right-aside .email-body .pr-0 {
    padding-right: 0;
  }
  [dir=rtl] .email-wrap .email-right-aside .email-body .pr-0 {
    padding-right: 12px;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .email-wrap .email-right-aside .email-body .pl-0 {
    padding-left: 0;
  }
  [dir=rtl] .email-wrap .email-right-aside .email-body .pl-0 {
    padding-left: 12px;
    padding-right: 0;
  }
}
.email-wrap .email-right-aside .email-body .inbox {
  overflow: auto;
}
@media (max-width: 1199.98px) {
  .email-wrap .email-right-aside .email-body .inbox {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
.email-wrap .email-right-aside .email-body .inbox .d-flex {
  padding: 20px;
}
.email-wrap .email-right-aside .email-body .inbox .d-flex.active {
  background-color: #fefefe;
}
.email-wrap .email-right-aside .radius-left {
  border-radius: 8px;
  height: 100%;
}
.email-wrap .btn-mail {
  border-radius: 2px;
  text-align: left;
  padding: 13px 15px;
  margin: 20px 0 20px;
  letter-spacing: 1px;
  line-height: 12px !important;
}
.email-wrap p {
  margin-bottom: 0;
  color: #898989;
}
.email-wrap .flex-grow-1 {
  margin-top: 5px;
}
.email-wrap .flex-grow-1 h6 {
  margin-bottom: 2px;
  color: #9B9B9B;
  margin-top: 3px;
}
.email-wrap .flex-grow-1 h6 small {
  font-size: 12px;
}
.email-wrap .flex-grow-1 h6 small span {
  color: #777777;
}
.email-wrap .flex-grow-1 p {
  color: #9B9B9B;
  font-size: 12px;
}
.email-wrap .email-top {
  padding: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  line-height: 49px;
}
.email-wrap .email-top i {
  cursor: pointer;
}
.email-wrap .email-top h5 {
  margin-bottom: 0;
  padding: 12px 0;
}
.email-wrap .email-top .d-flex {
  padding: 13px 0;
}
.email-wrap .email-wrapper {
  padding: 20px;
}
.email-wrap .email-wrapper hr {
  margin-top: 0;
  margin-bottom: 20px;
}
.email-wrap .email-wrapper .d-inline-block {
  width: 100%;
}
.email-wrap .email-wrapper h6 {
  margin-bottom: 11px;
  float: left;
}
.email-wrap .email-wrapper .right-download {
  float: right;
}
.email-wrap .email-wrapper p + p {
  margin-bottom: 20px;
}
.email-wrap .email-wrapper h5 {
  margin-bottom: 20px;
}
.email-wrap .email-content .email-top .user-emailid:after {
  content: "";
  position: relative;
  height: 20px;
  width: 1px;
  right: 0;
  top: 3px;
  z-index: 2;
  border: 1px solid #efefef;
  float: right;
  padding: 0;
  margin: 0 20px;
}
.email-wrap .email-body .attachment ul li img {
  margin-bottom: 20px;
  width: 80px;
  height: 80px;
  -o-object-fit: cover;
     object-fit: cover;
}
.email-wrap .email-body .attachment .list-inline .list-inline-item {
  padding-right: 13px;
}
.email-wrap .email-body .email-compose .cke_contents.cke_reset {
  max-height: 178px;
  width: 100%;
  border: 1px solid rgba(106, 113, 133, 0.3);
}
.email-wrap .email-body .email-compose .email-top h4 {
  padding: 11px 0;
}
.email-wrap .email-body .email-compose .compose-border .btn-middle {
  padding-top: 5px;
}
.email-wrap .actions li {
  display: inline-block;
  margin-right: 25px;
}

/**=====================
  3.15 Email-application CSS Ends
==========================**/
/**=====================
    2.25 Letter_box CSS Start
==========================**/
.email-right-aside .email-list {
  display: block;
}
.email-right-aside .email-list.hide {
  display: none;
}
.email-right-aside .email-read {
  display: none;
}
.email-right-aside .email-read.show {
  display: block;
}
.email-right-aside .modal-footer {
  text-align: end;
}

.email-main-wrapper .email-app-sidebar .nav-pills .nav-item:active, .email-main-wrapper .email-app-sidebar .nav-pills .nav-item.active {
  border-radius: 5px;
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link {
  color: var(--chart-text-color);
  gap: 13px;
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding: 8px 20px;
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link:hover {
  background-color: unset;
  color: var(--theme-deafult);
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active {
  background-color: var(--light-shade-primary);
  color: var(--theme-deafult);
  border-radius: 5px;
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active svg, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active svg {
  stroke: rgba(155, 155, 155, 0.8);
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active::after, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active::after {
  content: "";
  border-right: 3px solid var(--theme-deafult);
  position: absolute;
  top: 9px;
  width: 4px;
  right: 0;
  height: 35px;
  border: 2px solid var(--theme-deafult);
  border-radius: 100px 0 0 100px;
}
[dir=rtl] .email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active::after, [dir=rtl] .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active::after {
  right: unset;
  left: 0;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .nav-pills .nav-link.active::after, .email-main-wrapper .email-app-sidebar .nav-pills .nav-link:active::after {
    top: 8px;
    height: 28px;
  }
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link svg {
  width: 18px;
  height: 18px;
  stroke: rgba(155, 155, 155, 0.8);
}
.email-main-wrapper .email-app-sidebar .nav-pills .nav-link div {
  width: calc(100% - 13px - 18px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .nav-pills .nav-link div {
    font-size: 15px;
  }
}
.email-main-wrapper .email-app-sidebar span {
  background-color: #E0DDFF;
  color: var(--theme-deafult);
  border-radius: 20px;
  padding: 5px 13px;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar span {
    padding: 3px 10px;
  }
}
.email-main-wrapper .email-app-sidebar .compose-mail {
  display: block;
  text-align: center;
}
.email-main-wrapper .email-app-sidebar .compose-mail .nav-link.active {
  padding: 15px;
  background-color: var(--theme-deafult);
  color: var(--white);
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .compose-mail .nav-link.active {
    padding: 10px;
  }
}
.email-main-wrapper .email-app-sidebar .compose-mail .nav-link.active::before {
  content: unset;
}
.email-main-wrapper .email-app-sidebar .compose-mail .nav-link i {
  font-size: 13px;
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .email-category.nav-pills .nav-item .nav-link {
    padding: 3px 20px;
  }
}
@media (max-width: 1400px) {
  .email-main-wrapper .email-app-sidebar .taged-mail.nav-pills .nav-item .nav-link {
    padding: 3px 20px;
  }
}
.email-main-wrapper .email-app-sidebar .taged-mail.nav-pills .nav-item:first-child a {
  margin-top: 16px;
}
.email-main-wrapper .email-app-sidebar .taged-mail.nav-pills .nav-item svg {
  width: 24px;
  height: 24px;
}
.email-main-wrapper .email-app-sidebar .emailbox.btn {
  width: 100%;
  margin-bottom: 15px;
  padding: 12px;
  font-size: 16px;
  font-weight: 500;
}
@media (max-width: 576px) {
  .email-main-wrapper .email-app-sidebar .emailbox.btn {
    padding: 7px;
  }
}
.email-main-wrapper .email-app-sidebar .emailbox.btn i {
  padding-right: 12px;
}
[dir=rtl] .email-main-wrapper .email-app-sidebar .emailbox.btn i {
  padding-right: unset;
  padding-left: 12px;
}

.email-body .mail-header-wrapper {
  padding: 16px;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(82, 82, 108, 0.08);
          box-shadow: 0px 5px 10px 0px rgba(82, 82, 108, 0.08);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper {
    padding: 12px;
  }
}
@media (max-width: 600px) {
  .email-body .mail-header-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.email-body .mail-header-wrapper .mail-header .form-check {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 420px) {
  .email-body .mail-header-wrapper .mail-header .form-check {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
}
.email-body .mail-header-wrapper .mail-header .form-check .form-check-input {
  width: 18px;
  height: 18px;
  margin-top: 9px;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-header .form-check .form-check-input {
    margin-right: 8px;
  }
  [dir=rtl] .email-body .mail-header-wrapper .mail-header .form-check .form-check-input {
    margin-left: 8px;
    margin-right: unset;
  }
}
.email-body .mail-header-wrapper .mail-header .form-check .form-check-input:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
@media (max-width: 424px) {
  .email-body .mail-header-wrapper .mail-header ul {
    gap: 12px;
  }
}
@media (max-width: 351px) {
  .email-body .mail-header-wrapper .mail-header ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}
.email-body .mail-header-wrapper .mail-header ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 9px;
  color: var(--chart-text-color);
  position: relative;
  padding-bottom: 0;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-header ul li a {
    padding: 7px;
  }
}
.email-body .mail-header-wrapper .mail-header ul li a::before {
  content: "";
  position: absolute;
  bottom: -23px;
  left: 0;
  width: 0;
  background-color: var(--theme-deafult);
  height: 2px;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
[dir=rtl] .email-body .mail-header-wrapper .mail-header ul li a::before {
  left: unset;
  right: 0;
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-header ul li a::before {
    bottom: -15px;
  }
}
@media (max-width: 991px) {
  .email-body .mail-header-wrapper .mail-header ul li a::before {
    bottom: -13px;
  }
}
@media (max-width: 351px) {
  .email-body .mail-header-wrapper .mail-header ul li a::before {
    bottom: -2px;
  }
}
.email-body .mail-header-wrapper .mail-header ul li a.active {
  color: var(--theme-deafult);
}
.email-body .mail-header-wrapper .mail-header ul li a.active::before {
  width: 100%;
  -webkit-transition: all 0.1s ease-in;
  transition: all 0.1s ease-in;
}
.email-body .mail-header-wrapper .mail-header ul li a svg {
  width: 22px;
  height: 22px;
}
@media (max-width: 424px) {
  .email-body .mail-header-wrapper .mail-header ul li a svg {
    display: none;
  }
}
.email-body .mail-header-wrapper .mail-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
}
@media (max-width: 1500px) {
  .email-body .mail-header-wrapper .mail-body {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: end;
  }
}
@media (max-width: 600px) {
  .email-body .mail-header-wrapper .mail-body {
    display: none;
  }
}
.email-body .mail-header-wrapper .mail-body .mail-search {
  position: relative;
}
@media (max-width: 1500px) {
  .email-body .mail-header-wrapper .mail-body .mail-search {
    width: 55%;
  }
}
@media (max-width: 1468px) {
  .email-body .mail-header-wrapper .mail-body .mail-search {
    width: 48%;
  }
}
@media (max-width: 1400px) {
  .email-body .mail-header-wrapper .mail-body .mail-search {
    display: none;
  }
}
.email-body .mail-header-wrapper .mail-body .mail-search .form-control {
  display: inline-block;
  padding: 8px 30px;
}
.email-body .mail-header-wrapper .mail-body .mail-search i {
  position: absolute;
  top: 12px;
  left: 11px;
}
[dir=rtl] .email-body .mail-header-wrapper .mail-body .mail-search i {
  left: 0;
  right: 11px;
}
.email-body .light-square {
  width: 40px;
  height: 39px;
  border-radius: 8px;
  background-color: var(--light-slate);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
@media (max-width: 992px) {
  .email-body .light-square {
    width: 34px;
    height: 35px;
  }
}
.email-body .light-square.dropdown-toggle::after {
  content: "";
  display: none;
}
.email-body .light-square .dropdown-menu .dropdown-item {
  border-top: 0;
}
.email-body .light-square .dropdown-menu .dropdown-item:hover {
  background-color: rgba(106, 113, 133, 0.3);
}
.email-body .light-square .dropdown-menu .dropdown-item i {
  padding-right: 12px;
}
[dir=rtl] .email-body .light-square .dropdown-menu .dropdown-item i {
  padding-right: 0;
  padding-left: 12px;
}
.email-body .mail-body-wrapper ul li {
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  border-bottom: 1px solid var(--chart-solid-border);
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .email-timing {
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .email-options {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 14px;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  min-width: 100px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .email-options i {
  font-size: 18px;
}
@media (max-width: 375px) {
  .email-body .mail-body-wrapper ul li:hover .inbox-message .email-options i {
    font-size: 16px;
  }
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .show {
  display: block;
}
.email-body .mail-body-wrapper ul li:hover .inbox-message .hide {
  display: none;
}
@media (max-width: 460px) {
  .email-body .mail-body-wrapper ul li.inbox-data {
    display: block;
  }
}
.email-body .mail-body-wrapper ul li:last-child {
  border-bottom: unset;
}
.email-body .mail-body-wrapper ul li .inbox-user {
  min-width: 290px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 13px;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user {
    min-width: 280px;
  }
}
@media (max-width: 576px) {
  .email-body .mail-body-wrapper ul li .inbox-user {
    min-width: 260px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .form-check-input {
  width: 18px;
  height: 18px;
}
.email-body .mail-body-wrapper ul li .inbox-user .form-check-input:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border {
  width: 50px;
  height: 50px;
  border: 2px solid var(--chart-solid-border);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border {
    width: 42px;
    height: 42px;
  }
}
@media (max-width: 355px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border {
    display: none;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div {
  width: 40px;
  height: 40px;
  background-color: #E0DDFF;
  border-radius: 100%;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div {
    width: 32px;
    height: 32px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  font-weight: 900;
}
@media (max-width: 1200px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border > div p {
    font-size: 13px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border .circle-success {
  background-color: #c8f9e1;
}
.email-body .mail-body-wrapper ul li .inbox-user .rounded-border img {
  border-radius: 100%;
  height: 38px;
}
@media (max-width: 1400px) {
  .email-body .mail-body-wrapper ul li .inbox-user .rounded-border img {
    height: 32px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  gap: 16px;
}
@media (max-width: 700px) {
  .email-body .mail-body-wrapper ul li .inbox-message {
    display: block;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data span {
  font-weight: 500;
  color: var(--chart-text-color);
  letter-spacing: 0.3;
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data span span {
  font-weight: 400;
  line-height: 1.9;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data .badge {
  border-radius: 20px;
}
@media (max-width: 1236px) {
  .email-body .mail-body-wrapper ul li .inbox-message .email-data .badge {
    margin-bottom: 5px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-data .badge:last-child {
  margin-bottom: 0;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-timing {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-width: 80px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .email-body .mail-body-wrapper ul li .inbox-message .email-timing {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    height: 18px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-options {
  display: none;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .email-body .mail-body-wrapper ul li .inbox-message .email-options {
    height: 18px;
  }
}
.email-body .mail-body-wrapper ul li .inbox-message .email-options .show {
  display: block;
}
.email-body .mail-body-wrapper ul li .inbox-message .email-options .hide {
  display: none;
}
.email-body .mail-body-wrapper ul li.page-item {
  padding: 0;
}
.email-body .mail-body-wrapper .hidden {
  display: none !important;
}
.email-body .mail-pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  gap: 12px;
  padding: 16px;
}
.email-body .mail-pagination .pagination-button,
.email-body .mail-pagination .pagination-number {
  padding: 0px 12px;
  width: 35px;
  height: 35px;
  border-radius: 10px;
  border: unset;
}
.email-body .mail-pagination .pagination-button.disabled {
  background-color: var(--light-background);
  color: var(--chart-text-color);
  opacity: 1;
}
.email-body .mail-pagination .pagination-number {
  padding: 6px 12px;
}
.email-body .mail-pagination .pagination-number.active {
  background-color: var(--theme-deafult);
  color: var(--white);
}
.email-body .mail-pagination .pagination-index {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 12px;
}

.compose-modal form .form-control {
  position: relative;
}
.compose-modal form .add-bcc {
  position: absolute;
  top: 2px;
  right: 16px;
}
[dir=rtl] .compose-modal form .add-bcc {
  right: unset;
  left: 16px;
}
.compose-modal form .add-bcc .btn {
  padding: 5px 8px;
  background-color: var(--light-shade-primary);
  color: var(--theme-deafult);
}

.mail-header-wrapper.header-wrapper1 {
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
      -ms-flex-direction: unset;
          flex-direction: unset;
}
@media (max-width: 440px) {
  .mail-header-wrapper.header-wrapper1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
  }
}
.mail-header-wrapper .mail-header1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 18px;
}
.mail-header-wrapper .mail-header1 span {
  font-weight: 500;
  color: var(--body-font-color);
}
.mail-header-wrapper .mail-header1 .btn-email {
  width: 20px;
  height: 20px;
}
.mail-header-wrapper .mail-body1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
.mail-header-wrapper .mail-body1 svg {
  width: 20px;
  height: 20px;
}
@media (max-width: 480px) {
  .mail-header-wrapper .mail-body1 svg {
    width: 18px;
    height: 18px;
  }
}

.mail-body-wrapper .user-mail-wrapper {
  padding: 16px;
}
.mail-body-wrapper .user-mail-wrapper .user-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid var(--chart-solid-border);
}
@media (max-width: 390px) {
  .mail-body-wrapper .user-mail-wrapper .user-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding-bottom: 12px;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
    -webkit-box-align: unset;
        -ms-flex-align: unset;
            align-items: unset;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 12px;
  padding-bottom: 12px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .rounded-border {
  width: 50px;
  height: 50px;
  border: 2px solid var(--chart-solid-border);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 100%;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .rounded-border img {
  height: 38px;
  border-radius: 100%;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .dropdown-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  color: var(--text-gray);
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .dropdown-button svg {
  width: 16px;
  height: 16px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security {
  padding: 16px;
  -webkit-box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
          box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  min-width: 350px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security p {
  margin-bottom: 8px;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security p:last-child {
  margin-bottom: 0;
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown .inbox-security p span {
  color: var(--body-font-color);
}
@media (max-width: 475px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: -27px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: unset;
    right: -27px;
  }
}
@media (max-width: 445px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: -70px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    left: unset;
    right: -70px;
  }
}
@media (max-width: 395px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown.onhover-show-div {
    min-width: 270px;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
  top: 30px;
  z-index: 1;
}
[dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
  left: unset;
  right: 0;
}
@media (max-width: 575px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    opacity: 1;
    visibility: visible;
  }
}
@media (max-width: 475px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: -27px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: unset;
    right: -27px;
  }
}
@media (max-width: 445px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: -70px;
  }
  [dir=rtl] .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    left: unset;
    right: -70px;
  }
}
@media (max-width: 395px) {
  .mail-body-wrapper .user-mail-wrapper .user-title > div .onhover-dropdown:hover .onhover-show-div {
    min-width: 270px;
  }
}
@media (max-width: 390px) {
  .mail-body-wrapper .user-mail-wrapper .user-title .inbox-options {
    padding-bottom: 0;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title .inbox-options span {
  color: var(--chart-text-color);
}
@media (max-width: 623px) {
  .mail-body-wrapper .user-mail-wrapper .user-title .inbox-options span {
    display: none;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-title .inbox-options svg {
  width: 18px;
  height: 18px;
}
.mail-body-wrapper .user-mail-wrapper .user-body {
  padding: 14px 0;
  border-bottom: 1px solid var(--chart-solid-border);
}
.mail-body-wrapper .user-mail-wrapper .user-body p:nth-child(1) {
  padding-bottom: 14px;
}
.mail-body-wrapper .user-mail-wrapper .user-body p:nth-child(2), .mail-body-wrapper .user-mail-wrapper .user-body p:nth-child(4) {
  line-height: 1.8;
  margin-bottom: 10px;
}
.mail-body-wrapper .user-mail-wrapper .user-body .mail-subcontent {
  padding-top: 14px;
}
.mail-body-wrapper .user-mail-wrapper .user-body .mail-subcontent p {
  padding-bottom: 0;
}
.mail-body-wrapper .user-mail-wrapper .user-footer {
  padding: 14px 0;
  border-bottom: 1px solid var(--chart-solid-border);
}
.mail-body-wrapper .user-mail-wrapper .user-footer > div:not(.toolbar-box) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 8px;
  padding-bottom: 12px;
  cursor: pointer;
}
.mail-body-wrapper .user-mail-wrapper .user-footer svg {
  width: 20px;
  height: 20px;
}
@media (max-width: 480px) {
  .mail-body-wrapper .user-mail-wrapper .user-footer svg {
    width: 18px;
    height: 18px;
  }
}
.mail-body-wrapper .user-mail-wrapper .user-footer .attchment-file {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 30px;
  padding: 12px;
  background-color: var(--light-background);
}
.mail-body-wrapper .user-mail-wrapper .user-footer .attchment-file .common-flex img {
  height: 36px;
}
.mail-body-wrapper .user-mail-wrapper .send-btn {
  text-align: end;
  padding-top: 14px;
}
.mail-body-wrapper .user-mail-wrapper .send-btn i {
  margin-left: 8px;
  font-size: 12px;
}
[dir=rtl] .mail-body-wrapper .user-mail-wrapper .send-btn i {
  margin-left: 0;
  margin-right: 8px;
}

.toolbar-box {
  display: block;
  padding: 0;
  border: 1px solid var(--chart-solid-border);
  border-radius: 5px;
}
.toolbar-box .ql-toolbar.ql-snow {
  border: 0;
}
.toolbar-box .ql-container.ql-snow {
  border: 0;
  min-height: 100px;
}
[dir=rtl] .toolbar-box .ql-editor {
  text-align: right;
}
.toolbar-box .ql-editor p:nth-child(3) {
  margin: 12px 0;
}
.toolbar-box .ql-editor p:nth-child(8), .toolbar-box .ql-editor p:nth-child(9) {
  margin: 12px 0;
}
.toolbar-box .ql-bold:hover,
.toolbar-box .ql-italic:hover,
.toolbar-box .ql-underline:hover,
.toolbar-box .ql-list:hover,
.toolbar-box .ql-link:hover,
.toolbar-box .ql-image:hover {
  color: var(--theme-deafult);
}
.toolbar-box .ql-bold:hover svg .ql-stroke,
.toolbar-box .ql-bold:hover svg .ql-fill,
.toolbar-box .ql-italic:hover svg .ql-stroke,
.toolbar-box .ql-italic:hover svg .ql-fill,
.toolbar-box .ql-underline:hover svg .ql-stroke,
.toolbar-box .ql-underline:hover svg .ql-fill,
.toolbar-box .ql-list:hover svg .ql-stroke,
.toolbar-box .ql-list:hover svg .ql-fill,
.toolbar-box .ql-link:hover svg .ql-stroke,
.toolbar-box .ql-link:hover svg .ql-fill,
.toolbar-box .ql-image:hover svg .ql-stroke,
.toolbar-box .ql-image:hover svg .ql-fill {
  stroke: var(--theme-deafult);
}
.toolbar-box .ql-bold button.ql-active .ql-stroke,
.toolbar-box .ql-italic button.ql-active .ql-stroke,
.toolbar-box .ql-underline button.ql-active .ql-stroke,
.toolbar-box .ql-list button.ql-active .ql-stroke,
.toolbar-box .ql-link button.ql-active .ql-stroke,
.toolbar-box .ql-image button.ql-active .ql-stroke {
  stroke: var(--theme-deafult);
}

#editor .ql-editing,
#editor1 .ql-editing {
  left: 60.375px !important;
}
[dir=rtl] #editor .ql-editing,
[dir=rtl] #editor1 .ql-editing {
  left: 0;
  right: 60.375px !important;
}
#editor .ql-editing.ql-out-bottom,
#editor1 .ql-editing.ql-out-bottom {
  visibility: visible !important;
}
#editor .ql-tooltip,
#editor1 .ql-tooltip {
  left: 35.9688px !important;
}
[dir=rtl] #editor .ql-tooltip,
[dir=rtl] #editor1 .ql-tooltip {
  left: 0;
  right: 35.9688px !important;
}
@media (max-width: 430px) {
  #editor .ql-tooltip,
  #editor1 .ql-tooltip {
    left: 2.9688px !important;
  }
  [dir=rtl] #editor .ql-tooltip,
  [dir=rtl] #editor1 .ql-tooltip {
    left: 0;
    right: 2.9688px !important;
  }
}
#editor .ql-tooltip.ql-out-bottom,
#editor1 .ql-tooltip.ql-out-bottom {
  visibility: visible !important;
}
@media (max-width: 430px) {
  #editor .ql-tooltip.ql-out-bottom input[type=text],
  #editor1 .ql-tooltip.ql-out-bottom input[type=text] {
    width: 94px;
  }
}

.important-mail {
  width: 18px;
  height: 18px;
  fill: transparent;
  stroke: var(--chart-text-color);
  cursor: pointer;
}
.important-mail:active, .important-mail.active {
  fill: #D77748;
  stroke: #D77748;
}

.bookmark-box {
  stroke: var(--chart-text-color);
}
.bookmark-box:active, .bookmark-box.active {
  stroke: var(--theme-deafult);
}

.dropdown-subtitle > p {
  color: #52526c;
  font-weight: 500;
}

/**=====================
    2.25 Letter_box CSS End
==========================**/
/**=====================
    3.16 Errorpage CSS Start
==========================**/
.error-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  min-height: 100vh;
  text-align: center;
}
.error-wrapper .error-400 {
  /* background-image: url(../../images/other-images/400.png); */
  background-position: center;
  background-repeat: no-repeat;
  height: 70vh;
  background-size: contain;
}
.error-wrapper.maintenance-bg {
  background-image: url(../../images/maintenance-bg.jpg);
  background-color: rgba(255, 255, 255, 0.6);
  background-blend-mode: overlay;
}
.error-wrapper .maintenance-icons li i {
  color: #59667a;
  font-size: 50px;
  animation: rotate-effect 40s infinite linear reverse;
}
.error-wrapper .maintenance-icons li:nth-child(2) i {
  font-size: 100px;
  margin-left: 110px;
  margin-top: -25px;
  -webkit-animation: reverse-rotate-effect 40s linear infinite;
          animation: reverse-rotate-effect 40s linear infinite;
}
.error-wrapper .maintenance-icons li:nth-child(3) i {
  -webkit-animation: rotate-effect 30s infinite linear;
          animation: rotate-effect 30s infinite linear;
  font-size: 150px;
  margin-top: -85px;
  margin-right: 120px;
}
@-webkit-keyframes rotate-effect {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate-effect {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes reverse-rotate-effect {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
@keyframes reverse-rotate-effect {
  0% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}
.error-wrapper .maintenance-heading {
  margin-top: 70px;
}
.error-wrapper .maintenance-heading .headline {
  font-size: 99px;
  font-weight: 900;
  letter-spacing: 10px;
  color: var(--theme-deafult);
  z-index: 2;
  position: relative;
  margin-top: -70px;
}
.error-wrapper .error-heading {
  margin-top: 115px;
}
.error-wrapper .error-heading .headline {
  font-size: 285px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-top: -130px;
}
.error-wrapper .sub-content {
  font-size: 18px;
  color: #3D434A;
  letter-spacing: 1px;
  font-weight: normal;
  line-height: 35px;
  z-index: 3;
  position: relative;
  margin-top: 30px;
  margin-bottom: 0;
}
.error-wrapper .btn {
  margin-top: 40px;
}

.error {
  margin: 70px 0 100px;
  color: #000;
}
.error .error-title {
  font-size: 9.5rem;
  color: #fff;
  text-shadow: 6px 6px 7px rgba(0, 0, 0, 0.32);
}
.error .line-bottom {
  width: 10%;
  height: 5px;
  background-color: #D77748;
  border: none;
}
.error .c-white {
  color: #fff;
}
.error .error-btn {
  text-align: center;
}
.error .padd {
  padding: 10px 30px 10px 30px;
}
.error .btn-back-home {
  background-color: #f4f4f4;
  color: #000;
}
.error .btn-back-home:hover {
  background-color: #D77748;
  color: #fff;
  -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
          box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
}
.error .btn-adnc-serc {
  background-color: #2c323f;
  color: #fff;
}
.error .btn-adnc-serc:hover {
  background-color: #D77748;
  color: #fff;
  -webkit-box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
          box-shadow: 0 20px 35px 0 rgba(0, 0, 0, 0.21);
}

/**=====================
    3.16 Errorpage CSS Start
==========================**/
/**=====================
    3.17 Faq CSS start
==========================**/
.knowledgebase-bg {
  height: 500px;
  border-radius: 15px;
  margin-bottom: 30px;
}

.knowledgebase-search {
  position: absolute;
  top: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  left: 5%;
}
.knowledgebase-search .form-inline {
  background-color: #fff;
  width: 500px;
  padding: 12px 50px;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  margin-top: 10px;
  position: relative;
}
.knowledgebase-search .form-inline ::-webkit-input-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.knowledgebase-search .form-inline ::-moz-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.knowledgebase-search .form-inline :-ms-input-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.knowledgebase-search .form-inline ::-ms-input-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.knowledgebase-search .form-inline ::placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.knowledgebase-search .form-inline svg {
  position: absolute;
  left: 20px;
  width: 20px;
  top: 17px;
  stroke: rgba(106, 113, 133, 0.3);
}

.faq-widgets {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.faq-widgets .flex-grow-1 h4 {
  font-weight: 600;
  padding-bottom: 10px;
  color: rgba(255, 255, 255, 0.9);
}
.faq-widgets .flex-grow-1 p {
  padding-right: 20px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}
.faq-widgets svg {
  width: 60px;
  height: 60px;
  opacity: 0.5;
}

.header-faq {
  margin-bottom: 30px;
}
.header-faq h5 {
  font-weight: 600;
}

.features-faq.card .card-body,
.features-faq.card .card-footer {
  padding: 20px 25px;
}
.features-faq.card .card-body h4 {
  padding-bottom: 6px;
}
.features-faq .faq-image img {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
}
.features-faq .card-body h6 {
  font-weight: 500;
}

.add-project.table td {
  vertical-align: middle;
}
.add-project .text-inherit {
  color: black;
}

.faq-accordion.default-according {
  margin-bottom: 30px;
}
.faq-accordion.default-according .card .btn-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.faq-accordion.default-according .card .btn-link svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.faq-accordion.default-according .card .faq-header {
  position: relative;
  padding: 30px;
}
.faq-accordion.default-according .card .faq-header svg {
  position: absolute;
  right: 30px;
  top: 30px;
  width: 20px;
  height: 20px;
}
.faq-accordion.default-according .card .faq-header span {
  margin-top: 3px;
}
.faq-accordion.default-according .faq-body {
  padding: 20px !important;
}
.faq-accordion.default-according .card-mb-faq {
  margin-bottom: 30px;
}

.faq-title {
  margin: 30px 0;
}
.faq-title h5 {
  font-weight: 600;
}

.faq-form {
  position: relative;
}
.faq-form .search-icon {
  position: absolute;
  right: 12px;
  top: 9px;
  width: 20px;
  height: 20px;
}

.navigation-btn {
  margin-bottom: 20px;
}
.navigation-btn a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.navigation-btn a svg {
  width: 15px;
  height: 15px;
}

.navigation-option ul li {
  position: relative;
  padding: 8px 8px 8px 0;
}
.navigation-option ul li:hover {
  background-color: #fdfeff;
}
.navigation-option ul li a {
  padding-left: 40px;
  color: #3D434A;
}
.navigation-option ul li a svg {
  position: absolute;
  width: 15px;
  height: 15px;
  top: 11px;
  left: 15px;
}

.updates-faq {
  width: 40px;
  height: 40px;
  border: 2px solid var(--theme-deafult);
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-right: 20px;
}
.updates-faq svg {
  width: 18px;
  height: 18px;
}

.updates-bottom-time p {
  margin-bottom: 2px;
}
.updates-bottom-time p + p {
  color: #999999;
}

.updates-faq-main {
  margin-bottom: 20px;
}
.updates-faq-main a {
  font-weight: 500;
}
.updates-faq-main:last-child {
  margin-bottom: 0;
}

@media only screen and (max-width: 1399.98px) {
  .faq-widgets svg {
    width: 80px;
    height: 80px;
  }
}
@media only screen and (max-width: 1199px) {
  .faq-accordion {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .faq-accordion.default-according .card .faq-header {
    padding: 20px !important;
  }
  .faq-accordion.default-according .card .faq-header svg {
    right: 20px;
    top: 20px;
  }
  .navigation-option ul li {
    padding: 10px 10px 10px 0;
  }
  .navigation-option ul li a svg {
    top: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .xs-mt-search {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .xs-mt-search {
    margin-top: 0px;
  }
  .faq-widgets svg {
    width: 60px;
    height: 60px;
  }
  .faq-title {
    margin: 15px 0;
  }
  .header-faq {
    margin-bottom: 15px;
    margin-top: 10px;
  }
  .faq-accordion {
    margin-bottom: 0px;
  }
  .faq-accordion .faq-header {
    padding: 15px !important;
  }
  .faq-accordion .faq-header .pull-right {
    float: right;
  }
  .faq-accordion .faq-header svg {
    right: 15px;
    top: 15px;
  }
  .navigation-btn,
  .updates-faq-main {
    margin-bottom: 15px;
  }
}
/**=====================
    3.17 Faq CSS end
==========================**/
/**=====================
   3.18 File CSS
==========================**/
.files-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -10px;
}
.files-content li {
  margin-right: 20px;
  margin-bottom: 10px;
}
.files-content li .files-list .file-left {
  background-color: rgba(122, 112, 186, 0.05);
  border: 1px solid rgba(106, 113, 133, 0.3);
  padding: 18px 22px;
  border-radius: 5px;
}

.file-sidebar .card .card-body,
.file-sidebar .card .card-header,
.file-content .card .card-body,
.file-content .card .card-header {
  padding: 20px !important;
}
.file-sidebar .card .card-body .file-manager,
.file-sidebar .card .card-header .file-manager,
.file-content .card .card-body .file-manager,
.file-content .card .card-header .file-manager {
  padding-top: unset !important;
}
.file-sidebar .card .card-body .form-group input,
.file-sidebar .card .card-header .form-group input,
.file-content .card .card-body .form-group input,
.file-content .card .card-header .form-group input {
  line-height: 0;
}

.file-manager .folder {
  margin-bottom: -12px;
}
.file-manager .folder li:nth-child(n+2) {
  margin-left: 12px;
  margin-bottom: 12px;
}
.file-manager .folder li:nth-child(5), .file-manager .folder li:nth-child(9), .file-manager .folder li:nth-child(13) {
  margin-left: 0;
}

.file-sidebar ul li + li {
  margin-top: 8px;
}
.file-sidebar .btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 8px 20px;
  font-weight: 400;
}
.file-sidebar .btn.btn-light:hover {
  color: #fff !important;
}
.file-sidebar .btn.btn-light:hover svg {
  stroke: #fff;
}
.file-sidebar .btn svg {
  width: 16px;
  height: 16px;
  vertical-align: middle;
  margin-right: 8px;
}
.file-sidebar .pricing-plan {
  border: 1px solid rgba(106, 113, 133, 0.3);
  border-radius: 5px;
  margin-top: 10px;
  padding: 15px;
  position: relative;
  overflow: hidden;
}
.file-sidebar .pricing-plan h6 {
  font-size: 14px;
  margin-bottom: 2px;
  color: #898989;
}
.file-sidebar .pricing-plan h5 {
  font-weight: 500;
  font-size: 20px;
}
.file-sidebar .pricing-plan p {
  margin-bottom: 5px;
  color: #898989;
}
.file-sidebar .pricing-plan .btn {
  display: inline-block;
  font-size: 12px;
}
.file-sidebar .pricing-plan .bg-img {
  position: absolute;
  top: 40px;
  opacity: 0.1;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  right: -40px;
}

.file-manager h5 {
  font-size: 18px;
  font-weight: 600;
}
.file-manager > h6 {
  opacity: 0.6;
  font-weight: 400 !important;
  font-size: 15px;
  margin-bottom: 12px;
}
.file-manager .files-gap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 5px;
}
.file-manager .files-gap.list-group {
  -webkit-box-orient: unset;
  -webkit-box-direction: unset;
      -ms-flex-direction: unset;
          flex-direction: unset;
}
.file-manager p {
  font-size: 12px;
  color: #999999;
  margin-bottom: 0;
}

.folder h6 {
  opacity: 0.9;
  font-weight: 500 !important;
  font-size: 14px;
}

.files-content,
.quick-file {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.quick-file {
  margin-bottom: -10px;
}
.quick-file li {
  margin-right: 20px;
  margin-bottom: 10px;
}
.quick-file li:last-child {
  margin-right: 0;
}
.quick-file li h6 {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  margin-top: 8px;
}
.quick-file li .quick-box {
  background-color: rgba(122, 112, 186, 0.05);
  border: 1px solid rgba(106, 113, 133, 0.3);
  padding: 20px 24px;
  border-radius: 5px;
}
.quick-file li .quick-box i {
  font-size: 30px;
  margin-right: 0;
}

.file-content .folder-box .files-list h6 {
  font-size: 16px;
  font-weight: 400;
}
.file-content .folder-box .files-list i {
  margin-right: 0;
}
.file-content .ellips {
  position: absolute;
  top: 30px;
  right: 30px;
  opacity: 0.7;
}
.file-content .form-inline {
  border: 1px solid rgba(106, 113, 133, 0.3);
  padding: 0 20px;
  border-radius: 5px;
}
.file-content .form-inline i {
  padding-right: 10px;
  color: #898989;
  line-height: 3;
}
.file-content .form-inline input::-webkit-input-placeholder {
  color: #898989;
}
.file-content .form-inline input:focus {
  outline: none !important;
}
.file-content .search-form input {
  padding: 5px 10px 5px 70px;
  border-radius: 5px;
}
.file-content .search-form .form-group:before {
  left: 82px;
  top: 37px;
}
.file-content .search-form .form-group:after {
  top: 39px;
  left: 53px;
}
.file-content .btn svg {
  height: 16px;
  margin-right: 2px;
  vertical-align: -3px;
}
.file-content h4 {
  font-weight: 500;
  margin-bottom: 5px;
  font-size: 24px;
  line-height: 1;
}
.file-content .folder .folder-box {
  border: 1px solid rgba(106, 113, 133, 0.3);
  border-radius: 5px;
  padding: 15px;
  background-color: #fff;
  width: calc(25% - 15px);
  display: inline-block;
  margin: 2px 0;
  position: relative;
}
.file-content .folder .folder-box h6 {
  font-size: 16px;
}
.file-content .folder .folder-box .ellips {
  top: 22px;
  right: 22px;
}
.file-content .folder .folder-box:nth-child(1) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100000ms;
          animation: fadeIncustom 0.5s linear 100000ms;
}
.file-content .folder .folder-box:nth-child(2) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 200000ms;
          animation: fadeIncustom 0.5s linear 200000ms;
}
.file-content .folder .folder-box:nth-child(3) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 300000ms;
          animation: fadeIncustom 0.5s linear 300000ms;
}
.file-content .folder .folder-box:nth-child(4) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 400000ms;
          animation: fadeIncustom 0.5s linear 400000ms;
}
.file-content .folder .folder-box:nth-child(5) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 500000ms;
          animation: fadeIncustom 0.5s linear 500000ms;
}
.file-content .folder .folder-box:nth-child(6) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 600000ms;
          animation: fadeIncustom 0.5s linear 600000ms;
}
.file-content .folder .folder-box:nth-child(7) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 700000ms;
          animation: fadeIncustom 0.5s linear 700000ms;
}
.file-content .folder .folder-box:nth-child(8) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 800000ms;
          animation: fadeIncustom 0.5s linear 800000ms;
}
.file-content .folder .folder-box:nth-child(9) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 900000ms;
          animation: fadeIncustom 0.5s linear 900000ms;
}
.file-content .folder .folder-box:nth-child(10) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1000000ms;
          animation: fadeIncustom 0.5s linear 1000000ms;
}
.file-content .folder .folder-box:nth-child(11) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1100000ms;
          animation: fadeIncustom 0.5s linear 1100000ms;
}
.file-content .folder .folder-box:nth-child(12) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1200000ms;
          animation: fadeIncustom 0.5s linear 1200000ms;
}
.file-content .folder .folder-box:nth-child(13) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1300000ms;
          animation: fadeIncustom 0.5s linear 1300000ms;
}
.file-content .folder .folder-box:nth-child(14) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1400000ms;
          animation: fadeIncustom 0.5s linear 1400000ms;
}
.file-content .folder .folder-box:nth-child(15) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1500000ms;
          animation: fadeIncustom 0.5s linear 1500000ms;
}

.md-sidebar .md-sidebar-toggle {
  display: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

@media screen and (max-width: 1440px) {
  .file-content .folder .folder-box .d-flex {
    display: block !important;
    text-align: center;
  }
  .file-content .folder .folder-box .d-flex .flex-grow-1 {
    margin-left: 0 !important;
    margin-top: 5px;
  }
}
@media screen and (max-width: 1366px) {
  .file-content .folder {
    margin-bottom: -10px;
  }
  .file-content .folder .folder-box {
    margin-bottom: 10px;
    margin-right: 0;
    padding: 13px;
  }
  .file-content .folder .folder-box .media i {
    font-size: 30px;
  }
  .file-content .card-header .btn {
    padding: 8px 15px;
    z-index: 0;
  }
  .file-sidebar .btn {
    padding: 8px 15px;
  }
}
@media screen and (max-width: 1199px) {
  .job-sidebar .job-left-aside {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 0;
    opacity: 1;
    visibility: hidden;
    overflow-y: scroll;
    z-index: 3;
    max-width: 270px;
    height: 600 px;
    background-color: #fff;
    border: 1 px solid #e6edef;
    padding: 12px;
  }
  .job-sidebar .job-toggle {
    display: block !important;
    margin-bottom: 20px;
  }
  .job-sidebar .job-left-aside.open {
    opacity: 1;
    visibility: visible;
  }
  .md-sidebar .md-sidebar-toggle {
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .md-sidebar .md-sidebar-aside {
    position: absolute;
    top: 70%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    width: 280px;
  }
  .md-sidebar .md-sidebar-aside.open {
    opacity: 1;
    visibility: visible;
  }
}
@media screen and (max-width: 768px) {
  .file-content .form-inline {
    display: inline-block;
  }
  .file-content .folder {
    margin-bottom: -10px;
  }
  .file-content .folder .folder-box {
    width: calc(50% - 20px);
    margin-bottom: 10px;
    margin-right: 8px;
  }
  [dir=rtl] .file-content .folder .folder-box {
    margin-right: 0px;
  }
  .file-content .folder .folder-box:nth-child(odd) {
    margin-left: 0;
  }
}
@media screen and (max-width: 420px) {
  .file-content .folder .folder-box {
    width: 100%;
    margin-right: unset;
  }
  .file-content h4 {
    font-size: 20px;
  }
  .file-content .card-header .btn {
    padding: 7px 10px;
    font-size: 12px;
  }
  .file-manager .folder li:nth-child(n+2) {
    margin-left: 0;
  }
  .file-manager > h6 {
    font-size: 14px;
  }
}
/**=====================
    3.18 File CSS
==========================**/
/**=====================
    3.19 Gallery CSS Start
==========================**/
.profile-img-style img,
.scroll-bar-wrap img {
  width: 100%;
}

.my-gallery.gallery-with-description img {
  border: 1px solid rgba(106, 113, 133, 0.3) !important;
  border-bottom: none !important;
}
.my-gallery .caption h4 {
  padding-bottom: 10px;
}

.my-gallery img {
  width: 100%;
}

.img-thumbnail {
  border: 1px solid rgba(106, 113, 133, 0.3);
}

.gallery {
  margin-bottom: -30px;
}
.gallery > a {
  margin-bottom: 30px;
}
.gallery > a:before {
  content: "\edee";
  font-family: IcoFont;
  position: absolute;
  height: calc(100% - 10px);
  left: 20px;
  width: calc(100% - 40px);
  background-color: rgba(255, 255, 255, 0.27);
  top: 5px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  font-size: 30px;
  color: #000;
}
.gallery > a:hover:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.gallery > a .img-thumbnail {
  border-radius: 0;
}

.gallery-with-description {
  margin-bottom: -30px;
}
.gallery-with-description a > div {
  padding: 10px;
  border: 1px solid #e4e6ec;
  margin-bottom: 30px;
  border-radius: 0 0 5px 5px;
}
.gallery-with-description a:hover {
  text-decoration: none !important;
}
.gallery-with-description h4 {
  color: #2c323f;
  margin-top: 15px;
  font-size: 18px;
}
.gallery-with-description p {
  color: #a3a3a3;
}

.lg-backdrop {
  background-color: #fff;
}

.lg-outer .lg-actions .lg-next,
.lg-outer .lg-actions .lg-prev {
  background-color: rgb(255, 255, 255);
  border: 1px solid #efefef;
}
.lg-outer .lg-actions .lg-next:hover,
.lg-outer .lg-actions .lg-prev:hover {
  color: #2c323f;
}
.lg-outer .lg-toolbar {
  background-color: rgba(255, 255, 255, 0.45);
  border-bottom: 1px solid #2c323f;
}
.lg-outer .lg-toolbar .lg-icon:hover {
  color: #2c323f;
}
.lg-outer #lg-counter {
  color: #2c323f;
}
.lg-outer .lg-sub-html {
  background-color: rgba(255, 255, 255, 0.45);
}
.lg-outer .lg-sub-html h4 {
  font-size: inherit;
  color: #2c323f;
}
.lg-outer .lg-sub-html p {
  color: #2c323f;
}
.lg-outer .lg-thumb-outer {
  background-color: rgba(106, 113, 133, 0.3);
}
.lg-outer .lg-thumb-item {
  padding: 4px;
  border: 1px solid #efefef;
  border-radius: 0;
}
.lg-outer .lg-thumb-item .active, .lg-outer .lg-thumb-item:hover {
  border-color: #000;
}

.gallery a:before, .gallery a:after {
  content: none;
}
.gallery .img-hover > div {
  overflow: hidden;
}
.gallery .hover-1 img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-1 img:hover {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.gallery .hover-2 img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}
.gallery .hover-2:hover img {
  -webkit-transform: translateX(40px) scale(1.2);
          transform: translateX(40px) scale(1.2);
}
.gallery .hover-3 img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-3:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.gallery .hover-4 img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-4:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.gallery .hover-5 img {
  margin-left: 30px;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-5:hover img {
  margin-left: 0;
}
.gallery .hover-6 img {
  -webkit-transform: rotate(15deg) scale(1.4);
  transform: rotate(15deg) scale(1.4);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-6:hover img {
  -webkit-transform: rotate(0) scale(1);
  transform: rotate(0) scale(1);
}
.gallery .hover-7 img {
  -webkit-filter: blur(3px);
  filter: blur(3px);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-7:hover img {
  -webkit-filter: blur(0);
  filter: blur(0);
}
.gallery .hover-8 img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-8:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
.gallery .hover-9 img {
  -webkit-filter: sepia(100%);
  filter: sepia(100%);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-9:hover img {
  -webkit-filter: sepia(0);
  filter: sepia(0);
}
.gallery .hover-10 img {
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-10:hover img {
  -webkit-filter: grayscale(100%) blur(3px);
  filter: grayscale(100%) blur(3px);
}
.gallery .hover-11 img {
  opacity: 1;
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.gallery .hover-11:hover img {
  opacity: 0.5;
}
@-webkit-keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
.gallery .hover-13:hover img {
  opacity: 1;
  -webkit-animation: flash 1.5s;
  animation: flash 1.5s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.gallery .hover-14 div {
  position: relative;
}
.gallery .hover-14 div:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(rgba(255, 255, 255, 0.3)));
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.gallery .hover-14 div:hover:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
.gallery .hover-15 div {
  position: relative;
}
.gallery .hover-15 div:before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  display: block;
  content: "";
  width: 0;
  height: 0;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  opacity: 0;
}
.gallery .hover-15 div:hover:before {
  -webkit-animation: circle 0.75s;
  animation: circle 0.75s;
}

@-webkit-keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 200%;
    height: 200%;
    opacity: 0;
  }
}
* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
*:after, *:before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.grid {
  position: relative;
}

/* clear fix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- .grid-item ---- */
.grid-item img {
  max-width: 100%;
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.gallery-with-description .grid-item img {
  border: 0;
  padding: 0;
}

/**=====================
    3.19 Gallery CSS Ends
==========================**/
/**=====================
    3.20 Helper CSS start
==========================**/
.helper-common-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 8px;
  gap: 8px;
}
.helper-common-box:last-child {
  margin-bottom: 0;
}
.helper-common-box.helper-p-wrapper {
  gap: 12px;
  margin-bottom: 0;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.border-wrapper {
  padding: 20px;
}

.helper-box {
  width: 40px;
  height: 40px;
  border-radius: 25%;
}
.helper-box.helper-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-weight: 900;
}

.helper-radius {
  width: 60px;
  height: 30px;
}

.fill-wrapper {
  background-color: #f4f4f4;
}

.gradient-border {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 22px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (max-width: 1200px) {
  .gradient-border {
    gap: calc(13px + 9 * (100vw - 320px) / 880);
  }
}

.radius-wrapper {
  background-color: #efefef;
}

.font-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 12px 20px;
}

.helper-padding {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
}

.helper-p-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.helper-p-box span {
  color: var(--body-font-color);
}

.common-p-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 12px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

/**=====================
    3.20 Helper CSS end
==========================**/
/**=====================
    3.21 Internationalization CSS start
==========================**/
.hdg_main {
  position: relative;
}

.main .langChoice {
  position: absolute;
  right: 28px;
  top: 16px;
  padding: 5px 12px;
  background-color: transparent;
}

@media (max-width: 991px) {
  .main .langChoice {
    right: 20px;
    top: 11px;
    padding: 8px;
  }
}
@media only screen and (max-width: 575px) {
  .main .langChoice {
    top: 14px;
    padding: 4px 7px;
  }
  .language-xs {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .language-header {
    padding-bottom: 60px !important;
  }
  .main .langChoice {
    left: 0;
    margin: 0 auto;
    top: 50px;
    width: 30%;
    right: 0;
  }
}
/**=====================
    3.21 Internationalization CSS End
==========================**/
.invoice-1 body {
  font-family: "Nunito", sans-serif;
  display: block;
  color: #000248;
}
.invoice-1 .table-wrapper {
  margin: 0 auto;
}
.invoice-1 h2 {
  margin: 0;
  font-weight: 500;
  font-size: 32px;
}
.invoice-1 h6 {
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}
.invoice-1 span {
  line-height: 1.5;
  font-weight: 400;
}
.invoice-1 .banner-image {
  margin: 13px 0 10px;
}
.invoice-1 .order-details td span {
  margin-bottom: -4px;
  display: block;
}
.invoice-2 .card-body > table {
  display: block;
}
.invoice-2 .card-body > table > tbody {
  display: block;
}
.invoice-2 .card-body > table > tbody > tr {
  display: block;
}
.invoice-2 .card-body > table > tbody > tr > td {
  display: block;
}

/**=====================
    3.22 Job-search CSS Start
==========================**/
.filter-cards-view .job-filter:first-child {
  margin-bottom: 30px;
}
.filter-cards-view .checkbox-animated {
  margin-top: 30px;
  margin-bottom: 30px;
}

.location-checkbox span {
  color: #999999;
  padding-left: 33px;
}
[dir=rtl] .location-checkbox span {
  padding-left: unset;
  padding-right: 33px;
}

.job-accordion .card {
  margin-bottom: 30px;
}
.job-accordion .btn-block {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.job-search p {
  margin-top: 20px;
  font-size: 15px;
}
.job-search .d-flex .flex-grow-1 h6 {
  margin-bottom: 3px;
}
.job-search .d-flex .flex-grow-1 h6 span {
  font-size: 13px;
  color: #999999;
  font-weight: normal;
}
.job-search .d-flex .flex-grow-1 h6 span.badge {
  color: #fff;
}
.job-search .d-flex .flex-grow-1 p {
  margin-top: 0;
  color: #999999;
}
.job-search .job-description {
  margin-top: 30px;
}
.job-search .job-description h5 {
  font-weight: 500;
  margin-bottom: 8px;
}
.job-search .job-description h4 {
  font-weight: 600;
}
.job-search .job-description p {
  margin-top: 0;
  margin-bottom: 6px;
  color: #8D8D8D;
}
.job-search .job-description ul {
  list-style-type: disc;
  list-style-position: inside;
}
.job-search .job-description ul li {
  margin-bottom: 10px;
  color: #8D8D8D;
}
.job-search .job-description ul li:last-child {
  margin-bottom: 0;
}
.job-search .job-description .theme-form {
  margin-top: 30px;
  margin-bottom: 30px;
}
.job-search .job-description .theme-form .row div[class^=col-] + div[class^=col-] .select2-container {
  margin-top: 18px;
}
.job-search .job-description .theme-form .row div[class^=col-] .job-select2 ~ .select2-container {
  margin-top: 0 !important;
}
.job-search .job-description .theme-form .row div[class^=col-] .input-group .datepicker-here {
  font-size: 14px;
  border: 1px solid rgba(106, 113, 133, 0.3);
  padding-top: 12px;
  padding-bottom: 12px;
}

.select2-container .select2-selection--single {
  border-radius: 0.25rem !important;
  height: 47px !important;
  padding: 10px;
}

.job-pagination {
  margin-bottom: 30px;
}
.job-pagination nav ul {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media only screen and (max-width: 1366px) {
  .xl-mt-job {
    margin-bottom: 20px;
  }
}
@media only screen and (max-width: 991px) {
  .filter-cards-view {
    padding: 20px !important;
  }
  .filter-cards-view .job-filter:first-child {
    margin-bottom: 20px;
  }
  .filter-cards-view .checkbox-animated {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .job-accordion .animate-chk {
    padding: 20px !important;
  }
  .job-search p {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .filter-cards-view {
    padding: 15px !important;
  }
  .filter-cards-view .job-filter:first-child {
    margin-bottom: 15px;
  }
  .filter-cards-view .checkbox-animated {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job-accordion .animate-chk {
    padding: 15px !important;
  }
  .job-search p {
    margin-top: 15px;
  }
  .job-search .d-flex {
    display: block !important;
    text-align: center;
  }
  .job-search .d-flex .flex-grow-1 h6 .pull-right {
    float: none;
    margin-top: 3px;
    display: block;
  }
  .job-search .d-flex .flex-grow-1 h6 a {
    display: block;
  }
  .job-search .d-flex .flex-grow-1 p span + span {
    display: block;
  }
  .job-search .d-flex img {
    margin-bottom: 15px;
  }
  .job-search .d-flex .m-r-20 {
    margin-right: 0;
  }
  .job-search .job-description {
    margin-top: 15px;
  }
  .job-search .job-description .theme-form {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job-search .job-description .theme-form .row div[class^=col-] + div[class^=col-] .select2-container {
    margin-top: 2px;
  }
  .job-search .job-description .theme-form .xs-mt-period {
    margin-top: 15px;
  }
  .job-pagination {
    margin-bottom: 15px;
  }
  .xl-mt-job {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .job-search .job-description .btn {
    padding: 6px 12px;
  }
}
.select-date .select2-container--default .select2-selection--single .select2-selection__arrow {
  top: 9px;
  right: 12px;
}

/**=====================
    3.22 Job-search CSS End
==========================**/
/**=====================
  3.23 Jsgrid CSS Start
==========================**/
.jsgrid .jsgrid-grid-body .jsgrid-cell {
  padding: 0.75rem;
}
.jsgrid .jsgrid-pager-container {
  text-align: right;
}
.jsgrid .jsgrid-pager {
  padding: 0;
  margin-top: 20px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button {
  margin-right: -5px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-nav-inactive-button:first-child {
  border-radius: 5px 0 0 5px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button {
  margin-left: -5px;
}
.jsgrid .jsgrid-pager .jsgrid-pager-page ~ .jsgrid-pager-nav-button:last-child {
  border-radius: 0 5px 5px 0;
}
.jsgrid .jsgrid-pager [class*=jsgrid-pager] {
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  border: 1px solid #f4f4f4;
}
.jsgrid .jsgrid-pager .jsgrid-pager-page a {
  color: #2c323f;
}
.jsgrid .jsgrid-pager .jsgrid-pager-current-page {
  color: #2c323f;
}
.jsgrid .jsgrid-selected-row > .jsgrid-cell {
  background: #f4f4f4;
  border-color: #fafafa;
}
.jsgrid .jsgrid-header-row > .jsgrid-header-cell {
  padding: 0.75rem;
  font-weight: 500;
}

/**=====================
  3.23 Jsgrid CSS Ends
==========================**/
/**=====================
  3.24 Kanban CSS start
==========================**/
.jkanban-container .btn {
  margin-bottom: 10px;
}
.jkanban-container .note a {
  display: contents;
}

.kanban-board-header {
  height: auto;
  width: auto;
  line-height: unset;
  background: #f8f8f8;
  border-bottom: 1px solid #ddd;
}
.kanban-board-header.bg-info ~ .kanban-drag {
  background-color: rgba(22, 199, 249, 0.3) !important;
}
.kanban-board-header.bg-warning ~ .kanban-drag {
  background-color: rgba(215, 119, 72, 0.3) !important;
}
.kanban-board-header.bg-success ~ .kanban-drag {
  background-color: rgba(13, 167, 89, 0.3) !important;
}

.kanban-container {
  display: contents;
}

.kanban-board {
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  -webkit-box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
          box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
  overflow: hidden;
  width: calc(33.3333333333% - 30px) !important;
}
.kanban-board .kanban-drag {
  background: #f8f8f8;
}

.kanban-item {
  background: transparent;
  padding: 0;
  margin-bottom: 20px;
}
.kanban-item .kanban-box .badge {
  line-height: 13px;
}
.kanban-item p {
  opacity: 0.7;
}
.kanban-item:last-child {
  margin-bottom: 0;
}
.kanban-item .list {
  display: inline-block;
  opacity: 0.4;
}
.kanban-item .list li {
  display: inline-block;
  font-size: 12px;
  border-right: 1px solid rgba(106, 113, 133, 0.3);
  padding: 0 4px;
}
.kanban-item .list li:last-child {
  border-right: none;
}
.kanban-item .list li i {
  margin-right: 3px;
}
.kanban-item .kanban-box {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  display: block;
  color: inherit;
  background-color: #fff;
}
.kanban-item .kanban-box:hover {
  color: inherit;
}
.kanban-item .kanban-box + .kanban-box {
  margin-top: 20px;
}
.kanban-item .kanban-box .badge {
  font-weight: 400;
}
.kanban-item .kanban-box h6 {
  font-size: 15px;
  margin-top: 5px;
  font-weight: 500;
  margin-bottom: 10px;
}
.kanban-item .kanban-box .dropdown {
  display: inline-block;
  right: 10px;
  position: absolute;
  top: 20px;
}
.kanban-item .kanban-box .dropdown .dropdown-item {
  font-size: 13px;
  font-weight: 500;
  padding: 5px 10px;
  text-align: left;
}
.kanban-item .kanban-box .dropdown svg {
  height: 14px;
}
.kanban-item .kanban-box .d-flex {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.kanban-item .kanban-box .dropdown-toggle:after {
  content: none;
}
.kanban-item .kanban-box .customers ul li + li {
  margin-left: -10px;
}
.kanban-item .kanban-box .date {
  font-size: 11px;
  opacity: 0.5;
}

footer {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 1199px) {
  .jkanban-container .btn {
    margin-bottom: 10px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
    padding: 10px 15px;
  }
  .kanban-container .kanban-board {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}
@media only screen and (max-width: 480px) {
  .jkanban-container .card-body {
    padding: 20px 0 !important;
  }
  .kanban-board .kanban-drag,
  .kanban-container .kanban-item .kanban-box {
    padding: 10px 15px;
  }
}
/**=====================
   3.24 Kanban CSS ends
==========================**/
/**=====================
    3.25 Knowledgebase CSS start
==========================**/
.browse .browse-articles span {
  vertical-align: middle;
}
.browse .browse-articles span:nth-child(2) {
  padding-left: 20px;
}
[dir=rtl] .browse .browse-articles span:nth-child(2) {
  padding-left: unset;
  padding-right: 20px;
}
.browse .browse-articles span svg {
  width: 14px;
  height: 14px;
  vertical-align: text-top;
  position: absolute;
  left: 0;
  top: 0;
}
[dir=rtl] .browse .browse-articles span svg {
  left: unset;
  right: 0;
}
.browse .browse-articles h5 {
  padding-bottom: 10px;
  font-weight: 500;
  margin-bottom: 0px;
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
  padding-left: 25px;
}
[dir=rtl] .browse .browse-articles h5 {
  padding-left: unset;
  padding-right: 36px;
}
.browse .browse-articles h5 span svg {
  width: 16px;
  height: 16px;
  font-weight: 500;
  vertical-align: text-top;
  top: 0;
  left: 7px;
}
[dir=rtl] .browse .browse-articles h5 span svg {
  left: unset;
  right: 15px;
}
.browse .browse-articles ul li {
  padding: 8px;
  border-radius: 8px;
}
.browse .browse-articles ul li a {
  color: #3D434A;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 10px;
}
.browse .browse-articles ul li:hover {
  background-color: #fdfeff;
}

@media only screen and (max-width: 1366px) {
  .browse div[class^=col-]:nth-child(3) {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 360px) {
  .browse .browse-articles ul li a span:nth-child(2) {
    width: 158px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
.badge.pull-right {
  padding-top: 6px;
}

.articles-icons svg {
  min-width: 18px;
}
.articles-icons .flex-grow-1 h5 {
  padding-bottom: 6px;
}

.d-flex.faq-widgets svg {
  min-width: 18px;
}

/**=====================
    3.25 Knowledgebase CSS End
==========================**/
/**=====================
    3.26 Landing CSS Start
==========================**/
/* MESH LOADER */
::-moz-selection {
  background-color: #337ab7;
  color: #fff;
}
::selection {
  background-color: #337ab7;
  color: #fff;
}

::-moz-selection {
  background-color: #337ab7;
  color: #fff;
}

html:hover .cursor {
  opacity: 1;
}

.landing-page div canvas:first-child {
  display: none !important;
}

.cursor {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 50px;
  height: 50px;
  margin: -25px 0 0 -25px;
  opacity: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: opacity 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
}
.cursor--hover .cursor-inner {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  opacity: 0;
}
.cursor--hover .cursor-outer {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  border-color: #337ab7;
  opacity: 1;
}

.cursor-move-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  margin-top: -3px;
  margin-left: -3px;
}

.cursor-move-outer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cursor-inner {
  display: block;
  width: 80%;
  height: 80%;
  background: #337ab7;
  border-radius: 50%;
  -webkit-transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

.cursor-outer {
  display: block;
  width: 70%;
  height: 70%;
  border: 1px solid #337ab7;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-shadow: 0px 0px 4px 4px rgba(122, 112, 186, 0.22);
          box-shadow: 0px 0px 4px 4px rgba(122, 112, 186, 0.22);
  -webkit-transition: border 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition: border 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition: border 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: border 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), transform 0.4s cubic-bezier(0.23, 1, 0.32, 1), opacity 0.4s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}

@-webkit-keyframes border-transform {
  0%, 100% {
    border-radius: 63% 37% 54% 46%/55% 48% 52% 45%;
  }
  14% {
    border-radius: 40% 60% 54% 46%/49% 60% 40% 51%;
  }
  28% {
    border-radius: 54% 46% 38% 62%/49% 70% 30% 51%;
  }
  42% {
    border-radius: 61% 39% 55% 45%/61% 38% 62% 39%;
  }
  56% {
    border-radius: 61% 39% 67% 33%/70% 50% 50% 30%;
  }
  70% {
    border-radius: 50% 50% 34% 66%/56% 68% 32% 44%;
  }
  84% {
    border-radius: 46% 54% 50% 50%/35% 61% 39% 65%;
  }
}
/* #Progress
================================================== */
.blok:nth-of-type(odd) {
  background-color: #fff;
}
.blok:nth-of-type(even) {
  background-color: #337ab7;
}

.progress-wrap {
  position: fixed;
  right: 30px;
  bottom: 30px;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  border-radius: 50px;
  -webkit-box-shadow: inset 0 0 0 2px rgba(122, 112, 186, 0.1);
          box-shadow: inset 0 0 0 2px rgba(122, 112, 186, 0.1);
  z-index: 10000;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap::after {
  position: absolute;
  font-family: "FontAwesome";
  font-weight: 600;
  content: "\f106";
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  color: #337ab7;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 1;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap:hover::after {
  opacity: 0;
}
.progress-wrap:hover::before {
  opacity: 1;
}
.progress-wrap::before {
  position: absolute;
  font-family: "FontAwesome";
  content: "\f106";
  font-weight: 600;
  text-align: center;
  line-height: 46px;
  font-size: 24px;
  opacity: 0;
  background: #337ab7;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.progress-wrap svg path {
  fill: none;
}
.progress-wrap svg.progress-circle path {
  stroke: rgba(122, 112, 186, 0.4);
  stroke-width: 4;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.progress-wrap.active-progress {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

@-webkit-keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  50.1% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes mesh {
  0% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform-origin: 50% -100%;
            transform-origin: 50% -100%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
  50.1% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform-origin: 50% 200%;
            transform-origin: 50% 200%;
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.mesh-loader {
  overflow: hidden;
  height: inherit;
  width: inherit;
}
.mesh-loader .circle {
  position: absolute;
  background: #337ab7;
  border-radius: 50%;
  margin: -3px;
  -webkit-animation: mesh 3s ease-in-out infinite -1.5s;
          animation: mesh 3s ease-in-out infinite -1.5s;
  width: 6px;
  height: 6px;
}
.mesh-loader > div .circle:last-child {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.mesh-loader > div {
  position: absolute;
  top: 50%;
  left: 50%;
}
.mesh-loader > div:last-child {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.d-flex .flex-grow-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@-webkit-keyframes animloader14 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
@keyframes animloader14 {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
section .title {
  margin-bottom: 35px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
section .title h5 {
  font-family: "Caveat", cursive;
  font-size: calc(18px + 8 * (100vw - 300px) / 1620);
  font-weight: 700;
  color: #C95E9E;
}
section .title h2 {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 0;
}
section .title p {
  color: #8D8D8D;
  font-weight: 500;
  width: 78%;
  margin: 0 auto;
}

.landing-page {
  color: #3D434A;
}
.landing-page .accordion-item {
  border: none;
}
.landing-page .accordion-button:not(.collapsed) {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.animate-slider .slick-slide img {
  width: 100%;
}

.f-light {
  color: #52526C;
  opacity: 0.8;
}

.rating-title {
  font-size: calc(14px + 6 * (100vw - 300px) / 1620);
  font-weight: normal;
}

.layout h5 {
  margin-bottom: 10px;
  opacity: 0.8;
  letter-spacing: 1.2px;
}
.layout .btn {
  padding: 0.15rem 0.5rem;
}

.common-card {
  background: #FCFCFD;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
          box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  border-radius: 10px;
}

.boxed {
  background-image: -webkit-gradient(linear, left top, right top, color-stop(20.35%, rgba(122, 112, 186, 0.1)), color-stop(50%, rgba(72, 163, 215, 0.1)), to(rgba(201, 94, 158, 0.1)));
  background-image: linear-gradient(90deg, rgba(122, 112, 186, 0.1) 20.35%, rgba(72, 163, 215, 0.1) 50%, rgba(201, 94, 158, 0.1) 100%);
}

.faq-section {
  background-image: url(../../images/bg-1.png);
  background-size: cover;
}
.faq-section .d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 26px;
  margin-bottom: 40px;
}
.faq-section .d-flex:last-child {
  margin-bottom: 0px;
}
.faq-section .d-flex .flex-shrink-0 {
  height: 60px;
  width: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: rgba(30, 34, 40, 0.02) 0 2px 1px, rgba(30, 34, 40, 0.02) 0 4px 2px, rgba(30, 34, 40, 0.02) 0 8px 4px, rgba(30, 34, 40, 0.02) 0 16px 8px, rgba(30, 34, 40, 0.03) 0 32px 16px;
          box-shadow: rgba(30, 34, 40, 0.02) 0 2px 1px, rgba(30, 34, 40, 0.02) 0 4px 2px, rgba(30, 34, 40, 0.02) 0 8px 4px, rgba(30, 34, 40, 0.02) 0 16px 8px, rgba(30, 34, 40, 0.03) 0 32px 16px;
}
.faq-section .d-flex .flex-grow-1 {
  text-align: start;
}
.faq-section .d-flex .flex-grow-1 h3 {
  font-family: "Nunito", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color: #3D434A;
}
.faq-section .d-flex .flex-grow-1 p {
  font-weight: 500;
  color: #8D8D8D;
}
@media (max-width: 991px) {
  .faq-section .faq-img {
    padding-top: 30px;
  }
}
.faq-section .faq-img img {
  position: relative;
}
.faq-section .faq-img .faq-bg {
  position: absolute;
  bottom: -182px;
  right: -144px;
  z-index: -1;
}
@media (max-width: 1660px) {
  .faq-section .faq-img .faq-bg {
    display: none;
  }
}

.demo-section .demo-box {
  background-image: linear-gradient(45deg, rgba(122, 112, 186, 0.12) 20.35%, rgba(72, 163, 215, 0.1) 50%, rgba(201, 94, 158, 0.12) 100%);
  padding: 20px;
  border-radius: 20px;
  -webkit-transition: all;
  transition: all;
}
.demo-section .demo-box .demo-title {
  padding-top: 20px;
  text-align: center;
}
.demo-section .demo-box .demo-title .btn {
  text-transform: capitalize;
}
.demo-section .demo-box .img-wrraper {
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.demo-section .demo-box .img-wrraper img {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
}
.demo-section .demo-box:hover {
  background-image: linear-gradient(45deg, rgba(201, 94, 158, 0.12) 20.35%, rgba(122, 112, 186, 0.1) 50%, rgba(72, 163, 215, 0.12) 100%);
}
.demo-section .demo-box:hover .img-wrraper img {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}
.demo-section .demo-block {
  margin-bottom: -30px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.demo-section .demo-block > div {
  margin-bottom: 30px;
}

.landing-footer {
  background-image: url(../../images/home-bg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.landing-footer .footer-contain {
  text-align: center;
}
.landing-footer .footer-contain img {
  margin-bottom: 5px;
}
.landing-footer .footer-contain h2 {
  font-size: calc(18px + 17 * (100vw - 320px) / 1600);
  font-weight: 700;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #fff;
}
.landing-footer .footer-contain .star-rate {
  margin-bottom: 40px;
}
.landing-footer .footer-contain .star-rate li {
  display: inline-block;
}
.landing-footer .footer-contain .star-rate li i {
  font-size: calc(16px + 14 * (100vw - 320px) / 1600);
}
.landing-footer .footer-contain .btn-footer a {
  margin-top: 8px;
}
.landing-footer .footer-contain .btn-footer a:nth-child(n+2) {
  margin-left: 10px;
}

@media (max-width: 991px) {
  .landing-home .landing-footer .footer-contain img {
    height: 100px;
  }
  .landing-home .landing-footer .footer-contain .star-rate {
    margin-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .landing-home .landing-footer .footer-contain img {
    height: 80px;
    margin-bottom: 10px;
  }
  .landing-home .landing-footer .footer-contain h2 {
    margin-bottom: 5px;
  }
  .landing-home .landing-footer .footer-contain p {
    margin-bottom: 10px;
  }
  .landing-home .landing-footer .footer-contain .star-rate {
    margin-bottom: 25px;
  }
  .landing-home .landing-footer .footer-contain .btn-footer .btn {
    padding: 10px 25px;
    font-size: 14px;
    line-height: 1;
  }
}
@media (max-width: 480px) {
  .landing-home .landing-footer .footer-contain .star-rate {
    margin-bottom: 20px;
  }
  .landing-home .landing-footer .footer-contain img {
    height: 60px;
  }
  .landing-home .landing-footer .footer-contain .btn-footer a:nth-child(n+2) {
    margin-left: 5px;
  }
  .landing-home .landing-footer .footer-contain .btn-footer a:last-child {
    margin-top: 10px;
  }
  .landing-home .landing-footer .footer-contain .btn-footer .btn {
    padding: 10px 15px;
  }
}
@media (max-width: 410px) {
  .landing-home .landing-footer .footer-contain .btn-footer a:last-child {
    margin-top: 10px !important;
  }
}
@media (max-width: 767px) {
  .landing-home .demo-section .demo-block {
    margin-bottom: -20px;
  }
  .landing-home .demo-section .demo-block > div {
    margin-bottom: 20px;
  }
  .landing-home .demo-section .demo-box {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
  }
  .landing-home .demo-section .demo-box .img-wrraper ul.demo-link li:nth-child(n+2) {
    margin-left: 5px;
  }
  .landing-home .demo-section .demo-box .img-wrraper ul.demo-link li a {
    font-size: 10px;
  }
  .landing-home .demo-section .demo-box .demo-title h3 {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .landing-home .demo-block .demo-box {
    margin: 0 15px;
  }
}
@media (max-width: 450px) {
  .landing-home .demo-block .demo-box {
    margin: 0 12px;
  }
}
@media (max-width: 405px) {
  .demo-block .demo-box {
    margin: 0 12px;
  }
}
.customer-wrap {
  position: relative;
}
.customer-wrap .line {
  position: absolute;
  top: 30%;
  z-index: -1;
  -webkit-animation: bounce-effect 3s infinite ease-in;
          animation: bounce-effect 3s infinite ease-in;
}
@media (max-width: 1400px) {
  .customer-wrap .line {
    width: 1000px;
    left: 42px;
  }
}
@media (max-width: 1199px) {
  .customer-wrap .line {
    width: 600px;
    left: 190px;
  }
}
@media (max-width: 991px) {
  .customer-wrap .line {
    width: 472px;
    left: 125px;
  }
}
@media (max-width: 767px) {
  .customer-wrap .line {
    display: none;
  }
}
.customer-wrap .col-md-3.col-6:first-child {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .customer-wrap .col-md-3.col-6:first-child {
    margin-bottom: 0px;
  }
}
.customer-wrap .col-md-3.col-6:nth-child(2) {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .customer-wrap .col-md-3.col-6:nth-child(2) {
    margin-top: 0px;
  }
}
.customer-wrap .col-md-3.col-6:nth-child(3) {
  margin-bottom: 30px;
}
@media (max-width: 767px) {
  .customer-wrap .col-md-3.col-6:nth-child(3) {
    margin-bottom: 0px;
  }
}
.customer-wrap .col-md-3.col-6:last-child {
  margin-top: 30px;
}
@media (max-width: 767px) {
  .customer-wrap .col-md-3.col-6:last-child {
    margin-top: 0px;
  }
}

.customer-box {
  position: relative;
  background-color: #f7f7f7;
  text-align: center;
  padding: 40px 30px;
  border-radius: 15px;
  -webkit-box-shadow: 0px 0px 33px rgba(119, 119, 119, 0.25);
          box-shadow: 0px 0px 33px rgba(119, 119, 119, 0.25);
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-transform: translate(0);
          transform: translate(0);
}
.customer-box:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 0;
  width: 100%;
  border-radius: 10px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  background-color: #337ab7;
}
.customer-box:hover {
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}
.customer-box:hover::before {
  height: 100%;
}
.customer-box:hover h6 {
  color: #fff;
}
.customer-box h6 {
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1399px) {
  .customer-box {
    padding: 40px 35px;
  }
}
@media (max-width: 1199px) {
  .customer-box {
    padding: 30px 25px;
  }
}
@media (max-width: 991px) {
  .customer-box {
    padding: 17px 13px;
  }
}
@media (max-width: 575px) {
  .customer-box {
    -webkit-box-shadow: 12px 12px 12px rgba(119, 119, 119, 0.25);
            box-shadow: 12px 12px 12px rgba(119, 119, 119, 0.25);
  }
}
.customer-box img {
  height: 70px;
  width: auto;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .customer-box img {
    height: 50px;
  }
}

.customer-wrapper {
  position: relative;
  padding: 12px;
}
@media (max-width: 767px) {
  .customer-wrapper {
    padding: 0;
  }
}

.customer-wrap {
  margin-bottom: -11px;
}
@media (max-width: 1199px) {
  .customer-wrap {
    margin: 0 -15px;
  }
}
@media (max-width: 991px) {
  .customer-wrap {
    margin: 0 -10px;
  }
}
@media (max-width: 767px) {
  .customer-wrap {
    margin: -12px;
  }
}
.customer-wrap > div {
  padding: 0 28px;
}
@media (max-width: 1199px) {
  .customer-wrap > div {
    padding: 0 15px;
  }
}
@media (max-width: 991px) {
  .customer-wrap > div {
    padding: 0 10px;
  }
}
@media (max-width: 767px) {
  .customer-wrap > div {
    padding: 12px;
  }
}
@media (max-width: 1199px) {
  .customer-wrap > div:last-child .outline-box {
    width: 100%;
  }
}

.framework-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #FCFCFD;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
          box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
  border-radius: 10px;
  padding: 18px 16px;
  gap: 16px;
  margin-left: 30px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
@media (max-width: 1400px) {
  .framework-box {
    margin-bottom: 20px;
  }
}
.framework-box::after {
  position: absolute;
  content: "";
  width: calc(100% + 4px);
  height: 47px;
  border-bottom: 1px solid rgba(0, 2, 72, 0.2);
  right: -1px;
  border-right: 1px solid rgba(0, 2, 72, 0.2);
  border-left: 1px solid rgba(0, 2, 72, 0.2);
  border-radius: 10px;
  top: 81px;
  bottom: 0px;
}
@media (max-width: 1400px) {
  .framework-box::after {
    top: 59px;
  }
}
@media (max-width: 1199px) {
  .framework-box::after {
    display: none;
  }
}
.framework-box .frame-icon {
  position: relative;
  min-width: 55px;
  height: 55px;
  background: #fff;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-left: -32px;
  margin-top: -100px;
}
@media (max-width: 575px) {
  .framework-box .frame-icon {
    min-width: 52px;
    height: 52px;
  }
}
.framework-box .frame-icon::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  -webkit-box-shadow: 3px 4px 5px rgba(46, 35, 94, 0.11), inset 3px 0px 1px rgba(204, 204, 215, 0.5);
          box-shadow: 3px 4px 5px rgba(46, 35, 94, 0.11), inset 3px 0px 1px rgba(204, 204, 215, 0.5);
  border-radius: 100%;
}
.framework-box .frame-icon img {
  width: 30px;
  height: 30px;
}
@media (max-width: 575px) {
  .framework-box .frame-icon img {
    width: 25px;
    height: 25px;
  }
}
.framework-box .frame-details {
  position: relative;
  text-align: left;
}
.framework-box .frame-details h5 {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.framework-box .frame-details p {
  opacity: 0.7;
  margin-bottom: 0;
}
.framework-box .frame-details::before, .framework-box .frame-details::after {
  position: absolute;
  content: "";
  width: 8px;
  height: 8px;
  background: #FEFEFF;
  border: 1px solid rgba(0, 2, 72, 0.2);
  border-radius: 100%;
  right: 17px;
}
@media (max-width: 1400px) {
  .framework-box .frame-details::before, .framework-box .frame-details::after {
    top: 40px;
  }
}
@media (max-width: 1199px) {
  .framework-box .frame-details::before, .framework-box .frame-details::after {
    display: none;
  }
}
.framework-box .frame-details::before {
  top: 60px;
  right: -20px;
}
.framework-box .frame-details::after {
  bottom: 19px;
  left: -60px;
}
@media (min-width: 576px) {
  .framework-box:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
  }
}
.framework-box:hover .frame-icon::before {
  -webkit-animation-name: rotate;
          animation-name: rotate;
  -webkit-animation-duration: 5s;
          animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
}
.framework-box:hover .frame-details h5 {
  color: var(--theme-deafult);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.customization-wrap .landing-title p {
  width: 100%;
}

.sub-title {
  font-size: calc(18px + 8 * (100vw - 300px) / 1620);
  font-family: "Caveat", cursive;
  font-weight: 700;
  color: #C95E9E;
}

.rotate-title {
  color: #FF5A82;
}

.landing-home {
  scroll-behavior: smooth;
  overflow: hidden;
  background-color: #fff;
}
.landing-home .home-bg {
  padding: 94px 0 110px;
  border-radius: 30px;
  background-image: url(../../images/home-bg.png);
  margin: 78px 43px 64px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}
@media (max-width: 578px) {
  .landing-home .home-bg {
    margin: 78px 15px 64px;
  }
}
.landing-home .home-bg ul li {
  position: absolute;
}
.landing-home .home-bg ul li:first-child {
  top: 26%;
  left: 43%;
  -webkit-animation: bounce-effect 3s infinite ease-in;
          animation: bounce-effect 3s infinite ease-in;
}
.landing-home .home-bg ul li:nth-child(2) {
  bottom: 19%;
  left: 36%;
  -webkit-animation: rounded 1.5s infinite linear;
          animation: rounded 1.5s infinite linear;
}
.landing-home .home-bg ul li:nth-child(3) {
  top: 4%;
  right: 24%;
  -webkit-animation: rounded 2s infinite linear;
          animation: rounded 2s infinite linear;
}
.landing-home .home-bg ul li:nth-child(4) {
  bottom: 6%;
  left: 21%;
}
.landing-home .home-bg ul li:nth-child(5) {
  top: 12%;
  left: 15%;
}
.landing-home .home-bg ul li:last-child {
  bottom: 4%;
  right: 10%;
  -webkit-animation: bounce-effect 2s infinite ease-in;
          animation: bounce-effect 2s infinite ease-in;
}
.landing-home .home-bg .home-text {
  padding: 0 94px 0 119px;
}
@media (max-width: 1830px) {
  .landing-home .home-bg .home-text {
    padding: 0px 75px 0 90px;
  }
}
@media (max-width: 1400px) {
  .landing-home .home-bg .home-text {
    padding: 0px 50px 0 63px;
  }
}
@media (max-width: 1200px) {
  .landing-home .home-bg .home-text {
    padding: 0px 28px 0 30px;
  }
}
@media (max-width: 578px) {
  .landing-home .home-bg .home-text {
    padding: 0px 15px 0 15px;
  }
}
.landing-home .home-bg .home-text .main-title {
  border-radius: 30px;
  background: -webkit-gradient(linear, left top, right top, from(rgba(72, 164, 215, 0.4)), to(rgba(72, 164, 215, 0)));
  background: linear-gradient(90deg, rgba(72, 164, 215, 0.4) 0%, rgba(72, 164, 215, 0) 100%);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding: 8px 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 15px;
}
.landing-home .home-bg .home-text .main-title .d-flex .flex-grow-1 p {
  font-size: 18px;
  font-family: "Nunito", sans-serif;
  color: #48A3D7;
  font-weight: 500;
}
@media (max-width: 388px) {
  .landing-home .home-bg .home-text .main-title .d-flex .flex-grow-1 p {
    font-size: 13px;
  }
}
.landing-home .home-bg .home-text h2 {
  color: #fff;
  font-family: "Nunito", sans-serif;
  font-size: calc(23px + 45 * (100vw - 768px) / 1152);
  font-weight: 600;
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}
@media (max-width: 768px) {
  .landing-home .home-bg .home-text h2 {
    font-size: 38px;
  }
}
@media (max-width: 672px) {
  .landing-home .home-bg .home-text h2 {
    font-size: 31px;
  }
}
@media (max-width: 578px) {
  .landing-home .home-bg .home-text h2 {
    font-size: 30px;
  }
}
@media (max-width: 342px) {
  .landing-home .home-bg .home-text h2 {
    font-size: 28px;
  }
}
.landing-home .home-bg .home-text h2 span {
  padding-right: 10px;
  background: -webkit-gradient(linear, left top, right top, color-stop(20.35%, #337ab7), color-stop(50%, #48A3D7), to(#C95E9E));
  background: linear-gradient(90deg, #337ab7 20.35%, #48A3D7 50%, #C95E9E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing-home .home-bg .home-text h2 .line-text {
  position: absolute;
  top: 56%;
  left: 50%;
  z-index: -1;
  -webkit-animation: arrowsvg 3s infinite;
          animation: arrowsvg 3s infinite;
}
@media (max-width: 1830px) {
  .landing-home .home-bg .home-text h2 .line-text {
    left: 44%;
  }
}
@media (max-width: 1532px) {
  .landing-home .home-bg .home-text h2 .line-text {
    width: 174px;
    top: 58%;
  }
}
@media (max-width: 1400px) {
  .landing-home .home-bg .home-text h2 .line-text {
    width: 129px;
  }
}
@media (max-width: 1199px) {
  .landing-home .home-bg .home-text h2 .line-text {
    left: 36%;
    width: 122px;
  }
}
@media (max-width: 991px) {
  .landing-home .home-bg .home-text h2 .line-text {
    left: 0%;
    width: 98px;
    top: 88%;
  }
}
@media (max-width: 892px) {
  .landing-home .home-bg .home-text h2 .line-text {
    width: 68px;
  }
}
@media (max-width: 892px) {
  .landing-home .home-bg .home-text h2 .line-text {
    width: 104px;
  }
}
@media (max-width: 479px) {
  .landing-home .home-bg .home-text h2 .line-text {
    display: none;
  }
}
.landing-home .home-bg .home-text p {
  font-size: 17px;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.62);
  margin-bottom: 34px;
}
.landing-home .home-bg .home-text .docutment-button a svg {
  fill: #fff !important;
  margin-right: 6px;
  vertical-align: -4px;
  height: 20px;
  width: 20px;
}
.landing-home .home-bg .home-text .docutment-button a:first-child {
  padding: 10px 12px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  margin-right: 10px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.landing-home .home-bg .home-text .docutment-button a:first-child:hover {
  color: #fff !important;
}
.landing-home .home-bg .home-text .docutment-button a:last-child {
  padding: 10px 13px;
}
.landing-home .home-bg .home-screen {
  position: relative;
  padding-right: 80px;
}
@media (max-width: 991px) {
  .landing-home .home-bg .home-screen {
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .landing-home .home-bg .home-screen {
    display: none;
  }
}
.landing-home .home-bg .home-screen .screen-1 {
  -webkit-transform: rotate(-5.42deg);
          transform: rotate(-5.42deg);
}
.landing-home .home-bg .home-screen .screen-1 img {
  border: 5px solid #fff;
  border-radius: 16px;
  -webkit-box-shadow: 0px 4px 63px 0px rgba(0, 0, 0, 0.25);
          box-shadow: 0px 4px 63px 0px rgba(0, 0, 0, 0.25);
}
.landing-home .home-bg .home-screen .screen-2 {
  position: absolute;
  top: 25px;
  right: 74px;
  -webkit-filter: drop-shadow(-13px 8px 41px rgba(42, 54, 80, 0.25));
          filter: drop-shadow(-13px 8px 41px rgba(42, 54, 80, 0.25));
  -webkit-transform: rotate(-5.42deg);
          transform: rotate(-5.42deg);
}
@media (max-width: 1820px) {
  .landing-home .home-bg .home-screen .screen-2 {
    right: 24px;
  }
}
@media (max-width: 1610px) {
  .landing-home .home-bg .home-screen .screen-2 {
    right: 63px;
    width: 236px;
  }
}
@media (max-width: 1400px) {
  .landing-home .home-bg .home-screen .screen-2 {
    right: 30px;
    width: 220px;
    top: 16px;
  }
}
@media (max-width: 1328px) {
  .landing-home .home-bg .home-screen .screen-2 {
    width: 200px;
  }
}
@media (max-width: 1199px) {
  .landing-home .home-bg .home-screen .screen-2 {
    width: 180px;
    right: 39px;
    top: 12px;
  }
}
@media (max-width: 991px) {
  .landing-home .home-bg .home-screen .screen-2 {
    left: -38px;
    top: -52px;
  }
}
@media (max-width: 870px) {
  .landing-home .home-bg .home-screen .screen-2 {
    left: -38px;
    top: -63px;
    width: 160px;
  }
}
.landing-home .home-bg .home-screen .screen-2 img {
  border-radius: 10px;
  -webkit-animation: bounce-effect 2s infinite ease-in;
          animation: bounce-effect 2s infinite ease-in;
}
.landing-home .home-bg .home-screen .screen-3 {
  position: absolute;
  bottom: -58px;
  left: 278px;
  -webkit-filter: drop-shadow(-32px -13px 41px rgba(42, 54, 80, 0.25));
          filter: drop-shadow(-32px -13px 41px rgba(42, 54, 80, 0.25));
  -webkit-transform: rotate(-5.42deg);
          transform: rotate(-5.42deg);
}
@media (max-width: 1610px) {
  .landing-home .home-bg .home-screen .screen-3 {
    width: 236px;
  }
}
@media (max-width: 1400px) {
  .landing-home .home-bg .home-screen .screen-3 {
    width: 220px;
  }
}
@media (max-width: 1328px) {
  .landing-home .home-bg .home-screen .screen-3 {
    width: 200px;
    bottom: -45px;
    left: 219px;
  }
}
@media (max-width: 1199px) {
  .landing-home .home-bg .home-screen .screen-3 {
    width: 180px;
  }
}
@media (max-width: 991px) {
  .landing-home .home-bg .home-screen .screen-3 {
    bottom: -63px;
    left: 122px;
  }
}
@media (max-width: 870px) {
  .landing-home .home-bg .home-screen .screen-3 {
    bottom: -81px;
    left: 72px;
    width: 160px;
  }
}
.landing-home .home-bg .home-screen .screen-3 img {
  border-radius: 10px;
  -webkit-animation: bounce-effect 3s infinite ease-in;
          animation: bounce-effect 3s infinite ease-in;
}
.landing-home .sub-title {
  font-size: calc(18px + 12 * (100vw - 300px) / 1620);
}
.landing-home .content h1 {
  color: #fff;
  line-height: 1.2;
  font-size: calc(25px + 35 * (100vw - 300px) / 1620);
  text-transform: uppercase;
}
.landing-home .content h1 span {
  background: -webkit-gradient(linear, left top, right top, color-stop(20.35%, #FFB905), color-stop(50%, #6AEBAD)), -webkit-gradient(linear, left bottom, left top, from(#FFFFFF), to(#FFFFFF));
  background: linear-gradient(90deg, #FFB905 20.35%, #6AEBAD 50%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.landing-home .content p {
  font-size: calc(14px + 4 * (100vw - 300px) / 1620);
  font-weight: 500;
  font-family: "Merriweather Sans", sans-serif;
  color: #fff;
  opacity: 0.8;
  width: 64%;
  margin: 0 auto;
}
@media (max-width: 1366px) {
  .landing-home .content p {
    width: 90%;
  }
}
@media (max-width: 767px) {
  .landing-home .content p {
    width: 100%;
  }
}
.landing-home .content-title {
  position: relative;
  display: inline-block;
}
.landing-home .content-title .arrow-decore {
  position: absolute;
  left: -203px;
  top: -25px;
}
@media (max-width: 1660px) {
  .landing-home .content-title .arrow-decore {
    left: -166px;
    width: 150px;
  }
}
@media (max-width: 991px) {
  .landing-home .content-title .arrow-decore {
    left: -125px;
    width: 110px;
    top: -14px;
  }
}
@media (max-width: 575px) {
  .landing-home .content-title .arrow-decore {
    left: -61px;
    width: 60px;
    top: -4px;
  }
}
.landing-home .sticky-header header .nav-padding {
  padding: 16px 50px;
}

@-webkit-keyframes animationtop {
  0% {
    -webkit-transform: translate(-10px, -5px);
            transform: translate(-10px, -5px);
  }
  50% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-10px, -5px);
            transform: translate(-10px, -5px);
  }
}

@keyframes animationtop {
  0% {
    -webkit-transform: translate(-10px, -5px);
            transform: translate(-10px, -5px);
  }
  50% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-10px, -5px);
            transform: translate(-10px, -5px);
  }
}
@-webkit-keyframes animationleft {
  0% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(20px, 0);
            transform: translate(20px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
}
@keyframes animationleft {
  0% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
  50% {
    -webkit-transform: translate(20px, 0);
            transform: translate(20px, 0);
  }
  100% {
    -webkit-transform: translate(0, 0px);
            transform: translate(0, 0px);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.navbar {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.navbar .navbar-toggler {
  margin-left: auto;
}

.navbar-nav {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.navbar-nav .nav-item {
  position: relative;
}
.navbar-nav .nav-item .nav-link {
  font-size: 18px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 12px 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.navbar-nav .nav-item .nav-link:hover {
  color: var(--theme-deafult) !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.landing-page .navbar-expand-xl .navbar-collapse {
  background-color: transparent;
}
.landing-page .page-body-wrapper {
  min-height: 100vh;
  background: #eaf4ff;
  overflow: hidden;
}
.landing-page .buy-btn {
  font-size: 16px;
  font-family: "Merriweather Sans", sans-serif;
  font-weight: 500;
  background: var(--theme-deafult);
  border-radius: 4px;
  padding: 12px 27px;
  margin-left: 10px;
}
@media (max-width: 991px) {
  .landing-page .buy-btn {
    padding: 10px 24px;
  }
}
@media (max-width: 767px) {
  .landing-page .buy-btn {
    padding: 9px 20px;
    font-size: 14px;
  }
}
.landing-page .buy-btn a {
  color: #fff;
  padding: 0;
}
.landing-page .navbar-nav .nav-item .nav-link {
  letter-spacing: 1.5px;
  color: #1F2F3E;
}
.landing-page .sticky-header header {
  background: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: 100;
  left: 0;
  height: auto;
}
.landing-page .sticky-header header.sticky {
  margin-top: 0;
}
.landing-page .sticky-header header.sticky .navbar-nav .nav-item .nav-link {
  font-size: 16px;
  padding: 16px 15px;
}

.navbar-expand-xl .navbar-brand {
  padding-left: 18px;
}
.navbar-expand-xl .navbar-collapse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  background-color: #fff;
}

.section-space {
  position: relative;
  padding: 80px 0;
  text-align: center;
}
.section-space p {
  font-size: 15px;
  line-height: 1.5;
}

.landing-main-title {
  margin-bottom: 30px;
}

.bottom-section-grad {
  position: relative;
}
.bottom-section-grad::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(-3.32%, #EDF7FF), color-stop(144.14%, rgba(227, 242, 255, 0)));
  background: linear-gradient(180deg, #EDF7FF -3.32%, rgba(227, 242, 255, 0) 144.14%);
  opacity: 0.7;
  height: 100%;
  width: 100%;
}

.pricing-box {
  background: rgba(243, 245, 245, 0.5);
  border: 2px solid #fff;
  border-radius: 10px;
  padding: 30px 20px;
}
.pricing-box img {
  width: 23px;
  height: 22px;
}
@media (max-width: 767px) {
  .pricing-box {
    padding: 20px 15px;
  }
}
.pricing-box p {
  color: #52526C;
}
.pricing-box h4 {
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .pricing-box h4 {
    margin-bottom: 15px;
  }
}
.pricing-box p {
  line-height: 1.5;
}
.pricing-box .purchase-details {
  background: #FCFCFD;
  border: 2px solid #fff;
  -webkit-box-shadow: 0px 4px 20px rgba(46, 35, 94, 0.05);
          box-shadow: 0px 4px 20px rgba(46, 35, 94, 0.05);
  border-radius: 10px;
  padding: 24px 40px;
  margin-top: 20px;
}
@media (max-width: 1199px) {
  .pricing-box .purchase-details {
    padding: 24px 20px;
  }
}
.pricing-box .purchase-details h2 {
  margin-bottom: 14px;
}
.pricing-box .purchase-details .btn-lg {
  font-weight: 500;
  padding: 12px 20px;
  width: 100%;
  min-width: auto;
}
@media (max-width: 1199px) {
  .pricing-box .purchase-details .btn-lg {
    padding: 12px 10px;
    font-size: 15px;
  }
}

.license-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 15px;
}
@media (max-width: 767px) {
  .license-list {
    gap: 10px;
  }
}
.license-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: left;
  gap: 10px;
}
@media (max-width: 991px) {
  .license-list li {
    gap: 5px;
  }
}
@media (max-width: 575px) {
  .license-list li img {
    width: 18px;
    height: 18px;
  }
}
.license-list li h6 {
  font-weight: normal;
  margin-bottom: 0;
}

.feature-section .feature-box {
  padding: 35px 40px 30px;
  border-radius: 20px;
  background: rgb(255, 255, 255);
  -webkit-box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
          box-shadow: 0px 5px 20px rgba(46, 35, 94, 0.12);
  margin: 40px 0 0px;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}
.feature-section .feature-box:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(122, 112, 186, 0.2)), to(rgba(233, 247, 255, 0.1)));
  background: linear-gradient(180deg, rgba(122, 112, 186, 0.2) 0%, rgba(233, 247, 255, 0.1) 100%);
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}
.feature-section .feature-box:hover .feature-icon::before {
  opacity: 1;
}
.feature-section .feature-box:hover .feature-icon:after {
  opacity: 0;
}
.feature-section .feature-box .feature-icon {
  width: 75px;
  height: 80px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: -80px auto 7px;
}
.feature-section .feature-box .feature-icon img {
  z-index: 1;
  position: relative;
}
.feature-section .feature-box .feature-icon:after {
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  background-repeat: no-repeat;
  background-position: center 2px;
  background-image: url(../../images/1_3.png);
}
.feature-section .feature-box .feature-icon:before {
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  opacity: 0;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
  background-repeat: no-repeat;
  background-position: center 2px;
  background-image: url(../../images/3_3.png);
}

.rating-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.rating-title .decore-1 {
  margin-top: -35px;
  -webkit-animation: animationtop 5s infinite;
          animation: animationtop 5s infinite;
}

.ratings li i {
  color: #D77748;
}

.ptb50 {
  padding: 80px 0;
}
@media (max-width: 991.98px) {
  .ptb50 {
    padding: 60px 0;
  }
}
@media (max-width: 575.98px) {
  .ptb50 {
    padding: 50px 0;
  }
}

.purchase-btn {
  padding: 12px 20px;
  min-width: 200px;
  border: none;
}
@media (max-width: 991px) {
  .purchase-btn {
    min-width: 160px;
  }
}
@media (max-width: 767px) {
  .purchase-btn {
    min-width: 140px;
    padding: 10px 17px;
    font-size: 16px;
  }
}

@-webkit-keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}

@keyframes move_wave {
  0% {
    -webkit-transform: translateX(0) translateZ(0) scaleY(1);
            transform: translateX(0) translateZ(0) scaleY(1);
  }
  50% {
    -webkit-transform: translateX(-25%) translateZ(0) scaleY(0.55);
            transform: translateX(-25%) translateZ(0) scaleY(0.55);
  }
  100% {
    -webkit-transform: translateX(-50%) translateZ(0) scaleY(1);
            transform: translateX(-50%) translateZ(0) scaleY(1);
  }
}
.landing-page .bg-Widget {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(69%, #F7F8F9), color-stop(91.83%, rgba(247, 248, 249, 0)));
  background: linear-gradient(180deg, #F7F8F9 69%, rgba(247, 248, 249, 0) 91.83%);
}
.landing-page .bg-Widget:before {
  top: 220px !important;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg);
}
.landing-page .bg-Widget:after {
  top: unset !important;
  bottom: 0 !important;
}

.btn-md:hover {
  -webkit-animation: 0.3s pulse 3;
          animation: 0.3s pulse 3;
}

.tap-top {
  text-align: center;
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 5;
  color: #fff;
  background: var(--theme-deafult);
  border: none;
  padding: 7px;
  -webkit-box-shadow: 0 0 10px var(--theme-deafult);
          box-shadow: 0 0 10px var(--theme-deafult);
  opacity: 0.5;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.tap-top:hover {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 1;
}
.tap-top svg {
  width: 20px;
}

@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
            transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
            transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
            transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
            transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
}

@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
            transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
            transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
            transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
            transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0px, 0px) rotate(0deg);
            transform: translate(0px, 0px) rotate(0deg);
  }
}
.page-wrapper,
.landing-page {
  overflow: hidden;
}
.page-wrapper .section-space,
.landing-page .section-space {
  padding: 80px 0;
}
.page-wrapper .mofi-demo-section,
.landing-page .mofi-demo-section {
  position: relative;
}
.page-wrapper .mofi-demo-section .img-effect,
.landing-page .mofi-demo-section .img-effect {
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
  box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
  border-radius: 15px;
  padding: 20px;
  position: relative;
}
.page-wrapper .mofi-demo-section .img-effect .hover-link,
.landing-page .mofi-demo-section .img-effect .hover-link {
  position: absolute;
  height: 100%;
  width: 100%;
  background: linear-gradient(10deg, rgba(44, 50, 63, 0.8) 0%, transparent 80%);
  border-radius: 15px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding-bottom: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  left: 0;
  top: 0;
}
.page-wrapper .mofi-demo-section .img-effect:hover .hover-link,
.landing-page .mofi-demo-section .img-effect:hover .hover-link {
  opacity: 1;
  z-index: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .mofi-demo-section .img-effect img,
.landing-page .mofi-demo-section .img-effect img {
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .mofi-demo-section .img-effect:hover,
.landing-page .mofi-demo-section .img-effect:hover {
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
          box-shadow: 0 0 37px rgba(8, 21, 66, 0.15);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .mofi-demo-section .img-effect:hover img,
.landing-page .mofi-demo-section .img-effect:hover img {
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.landing-page {
  overflow: unset;
}

.fluid-space {
  padding: 0 calc(10px + 70 * (100vw - 300px) / 1620);
}
@media (max-width: 1399px) {
  .fluid-space {
    padding: 0 30px;
  }
}
@media (max-width: 575px) {
  .fluid-space {
    padding: 0 12px;
  }
}

ul.dot-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 6px;
  padding: 15px 10px 0;
}
ul.dot-group li {
  width: 8px;
  height: 8px;
  background: #E8E8EC;
  border-radius: 100%;
}

.demo-imgs {
  margin: -10px;
}
.demo-imgs .demo-content {
  margin-top: 0px;
}
.demo-imgs .demo-content .mofi-demo-img {
  color: #337ab7;
  font-weight: 700;
  display: inline-block;
  border-radius: 14px;
  background: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-shadow: 7px 7px 5px rgba(192, 202, 216, 0.22);
          box-shadow: 7px 7px 5px rgba(192, 202, 216, 0.22);
  position: relative;
}
.demo-imgs .demo-content .mofi-demo-img .overflow-hidden {
  padding: 10px;
}
.demo-imgs .demo-content .mofi-demo-img .overflow-hidden img {
  width: 100%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.demo-imgs .demo-content .mofi-demo-img:hover .overflow-hidden img {
  -webkit-transform: scale(1.01);
  transform: scale(1.01);
}
.demo-imgs .demo-content:hover .title-wrapper h3 a {
  color: #337ab7;
}
.demo-imgs .demo-content .title-wrapper {
  padding: 20px 10px 10px;
}
.demo-imgs .demo-content .title-wrapper h3 a {
  font-size: 22px;
  color: #3D434A;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
.demo-imgs .demo-content .title-wrapper .theme-name {
  position: relative;
  font-size: calc(15px + 5 * (100vw - 300px) / 1620);
  text-transform: capitalize;
  font-weight: 600;
}
.demo-imgs .demo-content .title-wrapper .theme-name span {
  color: #52526C;
}
@media (max-width: 1670px) {
  .demo-imgs > div {
    padding: 15px;
    margin: 0;
  }
}

.text-marqee {
  height: 230px;
}
@media (max-width: 1199px) {
  .text-marqee {
    height: 166px;
  }
}
@media (max-width: 991px) {
  .text-marqee {
    height: 130px;
  }
}
@media (max-width: 767px) {
  .text-marqee {
    height: 95px;
  }
}
@media (max-width: 575px) {
  .text-marqee {
    height: 65px;
  }
}

.flower-gif {
  top: -40px;
  left: 19%;
}
@media (max-width: 991px) {
  .flower-gif {
    top: 0;
    left: 15%;
  }
}
@media (max-width: 767px) {
  .flower-gif {
    left: 8%;
  }
}
@media (max-width: 575px) {
  .flower-gif {
    display: none;
  }
}

.big-title {
  font-weight: 700;
  font-size: 200px;
  line-height: 229px;
  letter-spacing: -0.045em;
  color: rgba(234, 235, 241, 0.5);
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .big-title {
    font-size: 140px;
    line-height: 170px;
  }
}
@media (max-width: 991px) {
  .big-title {
    font-size: 110px;
    line-height: 140px;
  }
}
@media (max-width: 767px) {
  .big-title {
    font-size: 80px;
    line-height: 100px;
  }
}
@media (max-width: 575px) {
  .big-title {
    font-size: 50px;
    line-height: 60px;
  }
}

.navabr_btn-set {
  position: relative;
}
.navabr_btn-set:focus {
  outline: 0;
}
.navabr_btn-set span {
  display: block;
  background-color: #fff;
  height: 3px;
  width: 25px;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}
.navabr_btn-set:not(.custom_nav) span:nth-child(1) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  opacity: 0.9;
}
.navabr_btn-set:not(.custom_nav) span:nth-child(2) {
  height: 12px;
  visibility: hidden;
  background-color: transparent;
}
.navabr_btn-set:not(.custom_nav) span:nth-child(3) {
  position: absolute;
  left: 12px;
  top: 10px;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  opacity: 0.9;
}

.navbar-b {
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  background-color: transparent;
  padding-top: 1.563rem;
  padding-bottom: 1.563rem;
}

.fixed-top {
  position: relative;
  top: 0;
  right: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  z-index: 1030;
  background-color: #fff;
}

.navbar-toggler span {
  display: block;
  background-color: transparent;
  height: 3px;
  width: 25px;
  z-index: 112;
  margin-top: 4px;
  margin-bottom: 4px;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  left: 0;
  opacity: 1;
}
.navbar-toggler span:nth-child(1) {
  -webkit-transition: -webkit-transform 0.35s ease-in-out;
  transition: -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}
.navbar-toggler span:nth-child(3) {
  -webkit-transition: -webkit-transform 0.35s ease-in-out;
  transition: -webkit-transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out;
  transition: transform 0.35s ease-in-out, -webkit-transform 0.35s ease-in-out;
}
.navbar-toggler:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}

.navbar_nav_modify li {
  text-align: center;
}

.sticky-header header {
  position: fixed;
  width: 100%;
  z-index: 6;
  text-align: center;
  line-height: normal;
  height: 80px;
  background: var(--theme-deafult);
  color: #fff;
  -webkit-box-shadow: 12px 12px 12px rgba(119, 119, 119, 0.25);
          box-shadow: 12px 12px 12px rgba(119, 119, 119, 0.25);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.sticky-header header .nav-padding {
  padding: 14px;
}
.sticky-header header.sticky {
  line-height: normal;
  background: var(--theme-deafult);
  text-align: left;
}
.sticky-header header.sticky .nav-padding {
  padding: 7px 14px;
}

.landing-home .sticky .navbar-brand img {
  max-height: 35px;
}

.title-padding {
  padding: 130px 0;
}

@-webkit-keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
}

@keyframes mover {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-4px);
            transform: translateY(-4px);
  }
}
@media (max-width: 1440px) {
  .navbar-nav .nav-item .nav-link {
    font-size: 16px;
    padding: 12px 7px;
  }
}
/* ========= responsive scss for 1200 screen ========= */
@media only screen and (max-width: 1199px) {
  .sticky-header header.sticky .nav-padding {
    padding: 15px 25px;
  }
  .navbar-toggler span {
    background-color: #3D434A;
  }
  .sticky-header .navbar-nav .nav-item .nav-link {
    color: #000;
    padding: 10px 12px !important;
    font-size: 15px;
  }
  .sticky-header .navbar-nav li {
    display: block;
    width: 100%;
  }
  .landing-page .navbar-expand-xl .navbar-collapse {
    background-color: white;
    position: absolute;
    width: calc(100% - 30px);
    left: 15px;
    top: 70px;
    border-top: 1px solid #9B9B9B;
  }
}
@media only screen and (max-width: 1199px) and (max-width: 575px) {
  .landing-page .navbar-expand-xl .navbar-collapse {
    top: 64px;
  }
}
@media only screen and (max-width: 1199px) {
  .landing-page .navbar-expand-xl .navbar-collapse .navbar-nav {
    padding: 20px 30px 30px 30px;
  }
  header.sticky .navbar-expand-xl .navbar-collapse {
    top: 66px;
  }
  .landing-main .sticky .navbar-expand-xl .navbar-brand {
    top: 8px;
  }
  .landing-main .sticky .navbar-expand-xl .navabr_btn-set {
    top: 16px;
  }
  .landing-main .navbar-toggler span {
    background-color: #fff;
  }
  .landing-main .navbar_nav_modify {
    width: 100vw;
    left: 0;
    position: fixed;
    height: 100vh;
    background-color: #293240;
    top: 0;
    padding: 120px 0px;
  }
  .landing-main h4 {
    font-size: 20px;
  }
  .landing-main .navbar-expand-xl .navabr_btn-set {
    position: fixed;
    top: 24px;
    z-index: 1;
    right: 20px;
  }
  .landing-main .navbar-expand-xl .navbar-brand {
    padding-left: 5px;
    z-index: 1;
    position: fixed;
    top: 14px;
  }
  .landing-main .navbar-expand-xl .navbar-collapse .navbar-nav .nav-item a {
    padding: 28px 60px;
    width: 16px;
    margin: 0 auto;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .landing-main .navbar-expand-lg .navbar-brand {
    padding-left: 5px;
  }
  .landing-main .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item a {
    padding: 9px;
  }
}
/* ========= responsive scss for 992 screen ========= */
@media only screen and (max-width: 991px) {
  .landing-home .navbar-brand img {
    height: 35px;
  }
  .frameworks-section > .container {
    max-width: 100%;
  }
  .landing-main .navbar-expand-lg .navbar-collapse {
    position: absolute;
    top: 90px;
    background-color: rgb(255, 255, 255);
    width: calc(100% - 30px);
    border-radius: 5px;
    -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 15px;
  }
  .landing-main .section-space {
    padding: 60px 0;
  }
  .landing-main .section-space p {
    font-size: 16px;
  }
  .landing-main .navbar-expand-lg .navbar-toggler {
    background-color: #fff;
  }
  .landing-main .navbar-expand-lg .navbar-toggler .navbar-toggler-icon i {
    margin-top: 5px;
    color: var(--theme-deafult);
  }
  .landing-main .navbar-expand-lg .navbar-collapse {
    background-color: #fff;
    z-index: 111;
  }
  .landing-main .navbar-expand-lg .navbar-collapse .navbar-nav .nav-item a {
    color: #000;
  }
  .landing-main .footer-bg h2 {
    font-size: 28px;
  }
}
/* ========= responsive scss for 768 screen ========= */
@media only screen and (max-width: 767px) {
  .landing-page .sticky-header header.sticky .navbar-nav .nav-item .nav-link {
    font-size: 14px;
    padding: 10px 12px;
  }
}
/* ========= responsive scss for 575 screen ========= */
@media only screen and (max-width: 575px) {
  .landing-home .sticky-header header .nav-padding {
    padding: 15px 30px;
  }
  .page-wrapper .section-space,
  .landing-page .section-space {
    padding: 40px 0;
  }
  .landing-main h4 {
    font-size: 17px;
  }
  .landing-main .footer-bg h2 {
    font-size: 21px;
  }
}
@media only screen and (max-width: 480px) {
  .sticky-header header.sticky {
    height: 56px;
  }
  .landing-home .sticky-header header .nav-padding {
    padding: 15px;
  }
  .landing-home .navbar-toggler {
    border: none;
    background-color: transparent;
    padding: 0;
  }
  .landing-home .navbar-brand img {
    height: 25px;
  }
}
@-webkit-keyframes arrowsvg {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}
@keyframes arrowsvg {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: left;
            transform-origin: left;
  }
}
@-webkit-keyframes rounded {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rounded {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes bounce-effect {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  50% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
}
/**=====================
    3.26 Landing CSS Ends
==========================**/
/**=====================
    3.27 Language CSS start
==========================**/
.current_lang {
  cursor: pointer;
  text-transform: uppercase;
  overflow: hidden;
}

.lang.selected {
  display: none;
}
.lang span.lang-txt {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-left: 8px;
  font-weight: 500;
}
.lang span span {
  color: #999;
  font-weight: 400;
  margin-left: 5px;
}

/*more lang*/
.more_lang {
  -webkit-transform: translateY(-20px);
          transform: translateY(-20px);
  opacity: 0;
  cursor: pointer;
  display: none;
  -webkit-transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
  transition: all 0.3s cubic-bezier(0.25, 1.15, 0.35, 1.15);
}

.translate_wrapper.active .more_lang {
  display: block;
  position: absolute;
  background-color: #fff;
  top: 47px;
  right: 0;
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
          box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
}
.translate_wrapper.active .more_lang:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  content: "";
  top: -7px;
  position: absolute;
  right: 20px;
  z-index: 2;
}
.translate_wrapper.active .more_lang:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #bdc2c5;
  content: "";
  top: -7px;
  position: absolute;
  right: 20px;
  z-index: 1;
}

.more_lang.active {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
}
.more_lang .lang {
  padding: 10px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.more_lang .lang:hover {
  background: var(--theme-deafult);
  color: #fff;
}
.more_lang .lang:hover span {
  color: #fff;
}

.translate_wrapper.active .more_lang .lang {
  border-bottom: 1px solid #eaeaea;
}

/**=====================
    3.27 Language CSS end
==========================**/
/**=====================
     3.28 Learning CSS start
==========================**/
.learning-header {
  margin-bottom: 10px;
}

.categories .learning-header {
  padding-left: 15px;
}
.categories ul li {
  padding: 10px 15px;
}
.categories ul li a {
  color: #3D434A;
}
.categories ul li:hover {
  background-color: #f4f4f4;
}

.upcoming-course .d-flex + .d-flex {
  margin-top: 15px;
}

.job-accordion {
  margin-bottom: 0;
}

@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .learning-comment {
    margin-left: -14px !important;
    float: left !important;
  }
  .blog-box .blog-details .blog-social li:first-child {
    padding-right: 30px;
  }
  .blog-box .blog-details .blog-social li + li {
    padding-left: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .categories {
    padding: 20px 0;
  }
  .upcoming-course .d-flex + .d-flex {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .categories {
    padding: 15px 0;
  }
  .upcoming-course .d-flex + .d-flex {
    margin-top: 15px;
  }
}
/**=====================
     3.28 Learning CSS end
==========================**/
/**=====================
    3.29 Login CSS Start
==========================**/
.login-card {
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  /* background: url("../../images/login_bg.jpg"); */
  background-color: #999;
  background-position: center;
  padding: 30px 12px;
}
.login-card .logo {
  display: block;
  margin-bottom: 30px;
  text-align: center;
}
.login-card .btn-showcase .btn {
  line-height: 1;
  padding: 10px 15px;
  margin: 0;
}
.login-card .btn-showcase .btn + .btn {
  margin-left: 5px;
}
.login-card .btn-showcase .btn svg {
  height: 16px;
  vertical-align: bottom;
}
.login-card .login-main {
  width: 450px;
  padding: 40px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
          box-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  margin: 0 auto;
  background-color: #fff;
}
.login-card .login-main .theme-form h4 {
  margin-bottom: 5px;
  font-size: 20px;
}
.login-card .login-main .theme-form label {
  font-size: 15px;
  letter-spacing: 0px;
}
.login-card .login-main .theme-form .checkbox label::before {
  background-color: #f9f9fa;
  border: 1px solid #dfdfdf;
}
.login-card .login-main .theme-form .or {
  position: relative;
}
.login-card .login-main .theme-form .or:before {
  content: "";
  position: absolute;
  width: 65%;
  height: 2px;
  background-color: #f3f3ff;
  top: 9px;
  z-index: 0;
  right: 0;
}
.login-card .login-main .theme-form input {
  background-color: #f3f3ff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  /* border-color: var(--theme-deafult) !important; */
}
.login-card .login-main .theme-form input::-webkit-input-placeholder {
  color: #999999;
}
.login-card .login-main .theme-form input:hover, .login-card .login-main .theme-form input:focus {
  border: 1px solid #b9c1cc;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.login-card .login-main .theme-form p {
  margin-bottom: 25px;
  font-size: 14px;
  color: #898989;
}
.login-card .login-main .theme-form .form-group {
  margin-bottom: 10px;
  position: relative;
}
.login-card .login-main .theme-form .link {
  position: absolute;
  top: 10px;
  right: 0;
}
.login-card .login-main .theme-form .social .btn:hover {
  background-color: var(--theme-deafult);
  border-color: var(--theme-deafult);
  color: #fff !important;
}
.login-card .login-main .theme-form .social .btn:hover .feather {
  color: #fff !important;
}

.show-hide {
  position: absolute;
  top: 52px;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.show-hide {
  cursor: pointer;
  font-size: 13px;
  color: var(--theme-deafult);
}
/* .show-hide span.show:before {
  content: "show";
}
.show-hide span:before {
  content: "hide";
} */

.needs-validation .invalid-feedback {
  color: #898989;
}
.needs-validation .show-hide {
  right: 30px;
}
.needs-validation .invalid-tooltip {
  top: 10px;
  right: 10px;
}

/**=====================
    3.29 Login CSS Ends
==========================**/
/**=====================
	3.30 Megaoption CSS Start
==========================**/
.mega-inline {
  margin-bottom: -30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mega-inline .card {
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
          box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.mega-inline .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.mega-inline .d-flex .radio {
  margin-left: 3px;
}
[dir=rtl] .mega-inline .d-flex .radio {
  margin-right: 3px;
  margin-left: unset;
}

.mega-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.mega-vertical .card {
  -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
          box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.mega-vertical .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.mega-vertical .d-flex .radio {
  margin-left: 3px;
}

.mega-title {
  font-weight: 600;
}

.mega-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.mega-horizontal .card {
  border: 1px solid #f3f3f3;
  -webkit-box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
          box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.mega-horizontal .mega-title-badge {
  display: table;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}
.mega-horizontal .d-flex .radio {
  margin-left: 3px;
}

.plain-style .card {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: none !important;
}

.border-style .card {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(106, 113, 133, 0.3) !important;
}

.offer-style .card {
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid rgba(106, 113, 133, 0.3) !important;
}

.rating-star-wrapper {
  color: #797979;
}

.card-wrapper .payment-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 8px;
  margin-bottom: 16px;
  border-bottom: 1px solid var(--chart-solid-border);
}
.card-wrapper .payment-wrapper:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
  border-bottom: unset;
}
.card-wrapper .payment-wrapper .payment-second img {
  width: 50px;
}
.card-wrapper .payment-wrapper .payment-second .mega-icons {
  width: 20px;
  height: 20px;
}

.main-upgrade {
  margin: 0 auto;
  text-align: center;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.main-upgrade > div:first-child {
  margin-bottom: 20px;
}
.main-upgrade i {
  font-size: calc(40px + 10 * (100vw - 320px) / 1600);
  display: block;
  color: #337ab7;
}
.main-upgrade p {
  line-height: 1.4;
}
.main-upgrade .variation-box {
  gap: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
@media (min-width: 768px) and (max-width: 1200px) {
  .main-upgrade .variation-box {
    display: grid;
    grid-template-columns: auto auto;
    gap: 18px;
  }
}
@media (max-width: 700px) {
  .main-upgrade .variation-box {
    display: grid;
    grid-template-columns: auto auto;
    gap: 18px;
  }
}
@media (max-width: 380px) {
  .main-upgrade .variation-box {
    grid-template-columns: unset;
  }
}
.main-upgrade .variation-box .selection-box {
  position: relative;
  height: 100px;
  border: 1px solid #337ab7;
  min-width: 125px;
  border-radius: 15px;
}
@media (min-width: 1200px) and (max-width: 1920px) {
  .main-upgrade .variation-box .selection-box {
    min-width: calc(110px + 15 * (100vw - 1200px) / 720);
  }
}
.main-upgrade .variation-box .selection-box .custom--mega-checkbox {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 25px;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}
.main-upgrade .variation-box .selection-box input {
  position: absolute;
  top: 0;
  left: 0;
  width: 118px;
  height: 96px;
  opacity: 0;
  cursor: pointer;
}
[dir=rtl] .main-upgrade .variation-box .selection-box input {
  left: unset;
  right: 0;
}
.main-upgrade .variation-box .selection-box input[type=checkbox]:checked ~ div {
  background-color: #E0DDFF;
  border-radius: 15px;
}

.megaoptions-border-space-sm .d-flex .radio label {
  padding-left: 30px;
}
[dir=rtl] .megaoptions-border-space-sm .d-flex .radio label {
  padding-left: unset;
  padding-right: 18px;
}
@media (min-width: 618px) and (max-width: 1199px) {
  .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    gap: 18px;
  }
}
@media (max-width: 618px) {
  .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 12px;
  }
}
.megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 .flex-grow-1 {
  margin-top: -6px;
}
@media (max-width: 618px) {
  .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 .flex-grow-1 {
    margin-left: 0px;
  }
  [dir=rtl] .megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1 .flex-grow-1 {
    margin-left: unset;
    margin-right: 0;
  }
}
.megaoptions-border-space-sm .d-flex .flex-grow-1 .list-behavior-1:first-child {
  margin-bottom: 0;
}

.megaoption-space .badge.pull-right,
.megaoptions-border-space-sm .badge.pull-right {
  margin-bottom: 12px;
}

/**=====================
    3.30 Megaoption CSS Ends
==========================**/
/**=====================
    3.31 Order-history CSS start
==========================**/
.prooduct-details-box .close {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 16px;
  cursor: pointer;
}
.prooduct-details-box .btn {
  line-height: 13px;
}
.prooduct-details-box .d-flex {
  border: 1px solid #d7e2e9;
  border-radius: 10px;
  padding: 15px 0;
  width: 100%;
  position: relative;
}
.prooduct-details-box .d-flex img {
  margin-left: 10px;
  border-radius: 6px;
}
[dir=rtl] .prooduct-details-box .d-flex img {
  margin-right: 10px;
  margin-left: unset;
}
.prooduct-details-box .rating {
  color: #ffaf45;
  letter-spacing: 3px;
}
.prooduct-details-box .product-name a {
  color: gray;
  font-weight: 600;
}
.prooduct-details-box .product-name h6 {
  margin-bottom: 0;
}
.prooduct-details-box .btn {
  position: absolute;
  right: 15px;
  bottom: 15px;
}

.order-history .input-group {
  text-align: right;
  max-width: 300px;
}
.order-history table th,
.order-history table td {
  text-align: center;
}
.order-history table thead tr th {
  vertical-align: middle;
  border-top: none;
  background-color: #f8f8f8;
  border: 1px solid rgba(106, 113, 133, 0.3);
  padding: 1.25rem;
  font-size: 16px;
}
.order-history table tbody tr td img {
  border-radius: 6px;
}
.order-history table .qty-box {
  width: 120px;
  margin: 0 auto;
  border-radius: 5px;
  overflow: hidden;
}
.order-history table .qty-box input {
  width: 44px;
  max-width: 44px;
  background-color: transparent;
  border: none !important;
}
.order-history table .qty-box .input-group {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid rgba(106, 113, 133, 0.3);
  overflow: hidden;
}
.order-history table .qty-box .btn {
  background-color: transparent;
  border: none;
}
.order-history table .qty-box .btn.bootstrap-touchspin-down {
  border-right: 1px solid;
}
[dir=rtl] .order-history table .qty-box .btn.bootstrap-touchspin-down {
  border-right: unset;
  border-left: 1px solid;
}
.order-history table .qty-box .btn.bootstrap-touchspin-up {
  border-left: 1px solid;
}
[dir=rtl] .order-history table .qty-box .btn.bootstrap-touchspin-up {
  border-left: unset;
  border-right: 1px solid;
}
.order-history table tbody tr td {
  vertical-align: middle;
}
.order-history table tbody tr td .product-name a {
  font-size: 15px;
  color: #898989;
}
.order-history table tbody tr td .product-name .order-process {
  font-size: 13px;
  padding-left: 15px;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle {
  position: relative;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle:before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background-color: #898989;
  left: -15px;
  top: -6px;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle.shipped-order:before {
  background-color: #0DA759;
}
.order-history table tbody tr td .product-name .order-process .order-process-circle.cancel-order:before {
  background-color: #E44141;
}

@media only screen and (max-width: 1199px) {
  .order-history table {
    overflow: auto;
  }
}
/**=====================
    3.31 Order-history CSS End
==========================**/
/**=====================
  3.32 Page CSS Start
==========================**/
/*test Nav bar color */
.pagination-theme .page-item .page-link {
  color: var(--theme-deafult) !important;
}
.pagination-theme .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult);
}

.pagination-primary .page-item .page-link {
  color: var(--theme-deafult) !important;
}
.pagination-primary .page-item.active .page-link {
  color: #fff !important;
  background-color: var(--theme-deafult) !important;
  border-color: var(--theme-deafult);
}

.pagination-secondary .page-item .page-link {
  color: #48A3D7 !important;
}
.pagination-secondary .page-item.active .page-link {
  color: #fff !important;
  background-color: #48A3D7 !important;
  border-color: #48A3D7;
}

.pagination-success .page-item .page-link {
  color: #0DA759 !important;
}
.pagination-success .page-item.active .page-link {
  color: #fff !important;
  background-color: #0DA759 !important;
  border-color: #0DA759;
}

.pagination-danger .page-item .page-link {
  color: #E44141 !important;
}
.pagination-danger .page-item.active .page-link {
  color: #fff !important;
  background-color: #E44141 !important;
  border-color: #E44141;
}

.pagination-info .page-item .page-link {
  color: #16C7F9 !important;
}
.pagination-info .page-item.active .page-link {
  color: #fff !important;
  background-color: #16C7F9 !important;
  border-color: #16C7F9;
}

.pagination-dark .page-item .page-link {
  color: #2c323f !important;
}
.pagination-dark .page-item.active .page-link {
  color: #fff !important;
  background-color: #2c323f !important;
  border-color: #2c323f;
}

.pagination-warning .page-item .page-link {
  color: #D77748 !important;
}
.pagination-warning .page-item.active .page-link {
  color: #fff !important;
  background-color: #D77748 !important;
  border-color: #D77748;
}

.pagin-border-primary .page-item.active, .pagin-border-primary .page-item:active {
  background-color: #E0DDFF;
}
.pagin-border-primary .page-item .page-link {
  border-color: #E0DDFF;
}
.pagin-border-primary .page-item .page-link:hover, .pagin-border-primary .page-item .page-link:focus {
  background-color: #E0DDFF;
}
.pagin-border-primary .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.pagin-border-secondary .page-item.active, .pagin-border-secondary .page-item:active {
  background-color: #c7eaff;
}
.pagin-border-secondary .page-item .page-link {
  border-color: #c7eaff;
}
.pagin-border-secondary .page-item .page-link:hover, .pagin-border-secondary .page-item .page-link:focus {
  background-color: #c7eaff;
}
.pagin-border-secondary .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.pagin-border-success .page-item.active, .pagin-border-success .page-item:active {
  background-color: #c8f9e1;
}
.pagin-border-success .page-item .page-link {
  border-color: #c8f9e1;
}
.pagin-border-success .page-item .page-link:hover, .pagin-border-success .page-item .page-link:focus {
  background-color: #c8f9e1;
}
.pagin-border-success .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.pagin-border-danger .page-item.active, .pagin-border-danger .page-item:active {
  background-color: #FEDDDB;
}
.pagin-border-danger .page-item .page-link {
  border-color: #FEDDDB;
}
.pagin-border-danger .page-item .page-link:hover, .pagin-border-danger .page-item .page-link:focus {
  background-color: #FEDDDB;
}
.pagin-border-danger .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.pagin-border-warning .page-item.active, .pagin-border-warning .page-item:active {
  background-color: #fbdac9;
}
.pagin-border-warning .page-item .page-link {
  border-color: #fbdac9;
}
.pagin-border-warning .page-item .page-link:hover, .pagin-border-warning .page-item .page-link:focus {
  background-color: #fbdac9;
}
.pagin-border-warning .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.pagin-border-info .page-item.active, .pagin-border-info .page-item:active {
  background-color: #d0f3fd;
}
.pagin-border-info .page-item .page-link {
  border-color: #d0f3fd;
}
.pagin-border-info .page-item .page-link:hover, .pagin-border-info .page-item .page-link:focus {
  background-color: #d0f3fd;
}
.pagin-border-info .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.pagin-border-light .page-item.active, .pagin-border-light .page-item:active {
  background-color: #F9F9FB;
}
.pagin-border-light .page-item .page-link {
  border-color: #F9F9FB;
}
.pagin-border-light .page-item .page-link:hover, .pagin-border-light .page-item .page-link:focus {
  background-color: #F9F9FB;
}
.pagin-border-light .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

.pagin-border-dark .page-item.active, .pagin-border-dark .page-item:active {
  background-color: #EAEBF2;
}
.pagin-border-dark .page-item .page-link {
  border-color: #EAEBF2;
}
.pagin-border-dark .page-item .page-link:hover, .pagin-border-dark .page-item .page-link:focus {
  background-color: #EAEBF2;
}
.pagin-border-dark .page-item .page-link:focus {
  -webkit-box-shadow: unset;
          box-shadow: unset;
}

@media (max-width: 576px) {
  .pagination-lg .page-item .page-link {
    font-size: calc(15px + 5 * (100vw - 320px) / 256);
    padding: calc(12px + 0 * (100vw - 320px) / 256) calc(12px + 12 * (100vw - 320px) / 256);
  }
}

@media (max-width: 339px) {
  .pagination {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}
.pagination .page-item:active {
  background-color: unset;
}
.pagination .page-item .page-link.rounded-circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 35px;
  height: 35px;
}

/**=====================
    3.32 Page CSS Ends
==========================**/
/**=====================
    3.33 Pricing CSS Start
==========================**/
.pricing-simple {
  -webkit-box-shadow: 0 0 10px 5px rgba(68, 102, 242, 0.05);
          box-shadow: 0 0 10px 5px rgba(68, 102, 242, 0.05);
  margin-bottom: 0 !important;
}
.pricing-simple .card-body {
  border-bottom: none;
}
.pricing-simple .card-body h3 {
  position: relative;
  margin-bottom: 38px;
}
.pricing-simple .card-body h3:before {
  position: absolute;
  content: "";
  width: 80px;
  height: 1px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -10px;
  background-color: #999999;
}
.pricing-simple .card-body h5 {
  font-size: 50px;
  line-height: 0.9;
  margin-bottom: 30px;
  font-weight: 400;
  margin-top: 20px;
}

.pricingtable {
  text-align: center;
  padding-top: 45px;
  padding-bottom: 45px;
  border-radius: 10px;
  -webkit-box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
          box-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  overflow: hidden;
  position: relative;
  z-index: 1;
  height: 100%;
}
.pricingtable:before, .pricingtable:after {
  content: "";
  background-color: var(--theme-deafult);
  width: 300px;
  height: 300px;
  border-radius: 50%;
  position: absolute;
  right: -213px;
  z-index: -1;
  opacity: 0.1;
}

.pricingtable:after {
  background-color: var(--theme-deafult);
  height: 300px;
  width: 300px;
  top: auto;
  bottom: -210px;
  right: auto;
  left: -160px;
  opacity: 0.1;
}
.pricingtable .title {
  text-transform: uppercase;
  margin-bottom: 20px;
}
.pricingtable .price-value {
  color: #fff;
  background-color: var(--theme-deafult);
  width: 130px;
  height: 130px;
  padding: 45px 0 0;
  margin: 0 auto 25px;
  border-radius: 50%;
}
.pricingtable .price-value .duration {
  margin: 0 0 0 -5px;
}
.pricingtable .price-value .amount {
  font-size: 58px;
  font-weight: 700;
  line-height: 45px;
  display: inline-block;
}
.pricingtable .price-value .currency {
  vertical-align: top;
}
.pricingtable .pricing-content {
  margin: 0 0 25px;
  display: inline-block;
}
.pricingtable .pricing-content li {
  font-weight: 500;
  text-align: left;
  margin: 0 0 20px;
  position: relative;
  padding-left: 30px;
}
.pricingtable .pricing-content li:last-child {
  margin: 0;
}
.pricingtable .pricing-content li:before {
  content: "\f00c";
  color: var(--theme-deafult);
  font-family: FontAwesome;
  text-align: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: 1px;
  left: 0;
}

@media (max-width: 1470px) {
  .pricing-block > div {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
  }
  .pricing-block > div:nth-child(n+3) {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 991px) {
  .pricing-simple .card-body h1 {
    margin-bottom: 20px;
  }
  .pricing-simple .card-body h3 {
    margin-bottom: 28px;
  }
  .pricing-block > div:nth-child(n+2) {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .pricing-simple .card-body h1 {
    margin-bottom: 15px;
  }
  .pricing-block > div {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
  }
  .pricingtable {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
/**=====================
   3.33 Pricing CSS Ends
==========================**/
/**=====================
   3.34 Progress CSS Start
==========================**/
.progress-showcase .progress {
  margin-bottom: 10px;
}
.progress-showcase .progress:last-child {
  margin-bottom: 0;
}

.sm-progress-bar {
  height: 8px;
}

.lg-progress-bar {
  height: 20px;
}

.progress-number button {
  width: 2rem;
  height: 2rem;
}
.progress-number .progress-wrapper {
  height: 1px;
}
.progress-number .progress-wrapper.progress {
  background-color: var(--recent-solid-border);
}
.progress-number .progress-wrapper .progress-bar {
  background-color: var(--theme-deafult);
}

.progress-btn {
  background-color: #f6f9fd;
  border-color: #f6f9fd;
}

.small-progressbar {
  position: relative;
  overflow: visible;
}
.small-progressbar::after {
  position: absolute;
  content: "";
  top: -2px;
  right: -9px;
  background-color: #337ab7;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}
[dir=rtl] .small-progressbar::after {
  left: -9px;
  right: unset;
}
.small-progressbar .progress-label {
  position: absolute;
  top: -26px;
  right: -6px;
  font-weight: 700;
}
[dir=rtl] .small-progressbar .progress-label {
  right: unset;
  left: -6px;
}
.small-progressbar .animate-circle {
  position: absolute;
  top: -11px;
  right: -18px;
  height: 30px;
  width: 30px;
  border: 14px solid #337ab7;
  border-radius: 70px;
  -webkit-animation: heart 1s ease-in-out;
          animation: heart 1s ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}
[dir=rtl] .small-progressbar .animate-circle {
  right: unset;
  left: -18px;
}

@-webkit-keyframes heart {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@keyframes heart {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 0;
  }
  25% {
    -webkit-transform: scale(0.1);
            transform: scale(0.1);
    opacity: 1;
  }
  50% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    opacity: 0.3;
  }
  75% {
    -webkit-transform: scale(0.8);
            transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}
.progress-b-space .progress {
  margin-bottom: 35px;
}

.progress-striped-primary {
  background-color: rgba(122, 112, 186, 0.1);
}
.progress-striped-primary .progress-bar {
  background-image: linear-gradient(120deg, #337ab7 25%, transparent 25%, transparent 50%, #337ab7 50%, #337ab7 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-secondary {
  background-color: rgba(72, 163, 215, 0.1);
}
.progress-striped-secondary .progress-bar {
  background-image: linear-gradient(120deg, #48a3d7 25%, transparent 25%, transparent 50%, #48a3d7 50%, #48a3d7 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-tertiary {
  background-color: rgba(201, 94, 158, 0.1);
}
.progress-striped-tertiary .progress-bar {
  background-image: linear-gradient(120deg, #c95e9e 25%, transparent 25%, transparent 50%, #c95e9e 50%, #c95e9e 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-warning {
  background-color: rgba(215, 119, 72, 0.1);
}
.progress-striped-warning .progress-bar {
  background-image: linear-gradient(120deg, #d77748 25%, transparent 25%, transparent 50%, #d77748 50%, #d77748 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-info {
  background-color: rgba(22, 199, 249, 0.1);
}
.progress-striped-info .progress-bar {
  background-image: linear-gradient(120deg, #16c7f9 25%, transparent 25%, transparent 50%, #16c7f9 50%, #16c7f9 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-success {
  background-color: rgba(13, 167, 89, 0.1);
}
.progress-striped-success .progress-bar {
  background-image: linear-gradient(120deg, #0da759 25%, transparent 25%, transparent 50%, #0da759 50%, #0da759 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-danger {
  background-color: rgba(228, 65, 65, 0.1);
}
.progress-striped-danger .progress-bar {
  background-image: linear-gradient(120deg, #e44141 25%, transparent 25%, transparent 50%, #e44141 50%, #e44141 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-light {
  background-color: rgba(244, 244, 244, 0.1);
}
.progress-striped-light .progress-bar {
  background-image: linear-gradient(120deg, #f4f4f4 25%, transparent 25%, transparent 50%, #f4f4f4 50%, #f4f4f4 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

.progress-striped-dark {
  background-color: rgba(44, 50, 63, 0.1);
}
.progress-striped-dark .progress-bar {
  background-image: linear-gradient(120deg, #2c323f 25%, transparent 25%, transparent 50%, #2c323f 50%, #2c323f 75%, transparent 75%, transparent);
  background-size: 16px 30px;
  --bs-progress-bar-bg: rgba($val, 0.1);
}

/**=====================	
   3.34 Progress CSS Ends
==========================**/
/**=====================
   3.35 Projectlist CSS Start
==========================**/
.project-list .row {
  margin: 15px;
}
.project-list button:focus {
  outline: none !important;
}
.project-list .theme-form .form-group {
  margin-bottom: 15px;
}
.project-list .border-tab.nav-tabs .nav-item .nav-link {
  border: 1px solid transparent;
  padding: 5px 30px 5px 0;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.project-list .btn {
  margin-right: 5px;
  vertical-align: -12px;
  float: right;
}
[dir=rtl] .project-list .btn {
  float: left;
}
.project-list .btn svg {
  vertical-align: middle;
  height: 16px;
}
.project-list ul {
  margin-bottom: 0 !important;
  border-bottom: 0;
}
.project-list ul li svg {
  height: 18px;
  vertical-align: middle;
  margin-right: 5px;
}

.tab-content .row > div.col-xl-4:nth-child(1) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 100000ms;
          animation: fadeIncustom 0.5s linear 100000ms;
}
.tab-content .row > div.col-xl-4:nth-child(2) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 200000ms;
          animation: fadeIncustom 0.5s linear 200000ms;
}
.tab-content .row > div.col-xl-4:nth-child(3) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 300000ms;
          animation: fadeIncustom 0.5s linear 300000ms;
}
.tab-content .row > div.col-xl-4:nth-child(4) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 400000ms;
          animation: fadeIncustom 0.5s linear 400000ms;
}
.tab-content .row > div.col-xl-4:nth-child(5) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 500000ms;
          animation: fadeIncustom 0.5s linear 500000ms;
}
.tab-content .row > div.col-xl-4:nth-child(6) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 600000ms;
          animation: fadeIncustom 0.5s linear 600000ms;
}
.tab-content .row > div.col-xl-4:nth-child(7) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 700000ms;
          animation: fadeIncustom 0.5s linear 700000ms;
}
.tab-content .row > div.col-xl-4:nth-child(8) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 800000ms;
          animation: fadeIncustom 0.5s linear 800000ms;
}
.tab-content .row > div.col-xl-4:nth-child(9) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 900000ms;
          animation: fadeIncustom 0.5s linear 900000ms;
}
.tab-content .row > div.col-xl-4:nth-child(10) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1000000ms;
          animation: fadeIncustom 0.5s linear 1000000ms;
}
.tab-content .row > div.col-xl-4:nth-child(11) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1100000ms;
          animation: fadeIncustom 0.5s linear 1100000ms;
}
.tab-content .row > div.col-xl-4:nth-child(12) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1200000ms;
          animation: fadeIncustom 0.5s linear 1200000ms;
}
.tab-content .row > div.col-xl-4:nth-child(13) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1300000ms;
          animation: fadeIncustom 0.5s linear 1300000ms;
}
.tab-content .row > div.col-xl-4:nth-child(14) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1400000ms;
          animation: fadeIncustom 0.5s linear 1400000ms;
}
.tab-content .row > div.col-xl-4:nth-child(15) {
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation: fadeIncustom 0.5s linear 1500000ms;
          animation: fadeIncustom 0.5s linear 1500000ms;
}

.project-cards .tab-content {
  margin-bottom: -30px;
}

.project-box {
  border: 1px solid #efefef;
  border-radius: 5px;
  padding: 30px;
  background-color: white;
  margin-bottom: 30px;
}
.project-box h6 {
  font-weight: 500 !important;
}
.project-box .badge {
  position: absolute;
  right: 30px;
  top: 15px;
  line-height: 13px;
}
.project-box .project-status p {
  margin-bottom: 5px;
  font-weight: 800;
}
.project-box .d-flex {
  margin-bottom: 15px;
  margin-top: 10px;
}
.project-box .d-flex .flex-grow-1 {
  opacity: 0.5;
}
.project-box .details div {
  margin-bottom: 5px;
  opacity: 0.7;
}

.projectdetails .card .card-body {
  padding: 20px;
}
.projectdetails .card .card-footer {
  padding: 20px;
}
.projectdetails .card select {
  width: 90px;
  height: 28px;
  font-size: 11px;
  right: 20px;
  top: 20px;
}

.projectmore .details .col-4 {
  margin-bottom: 5px;
}
.projectmore .details .col-4:nth-child(even) {
  opacity: 0.5;
}
.projectmore h5 {
  font-size: 20px;
  font-weight: 600;
}
.projectmore .task-list {
  position: relative;
}
.projectmore .task-list ul li {
  margin-bottom: 5px;
}
.projectmore .task-list ul + ul {
  position: absolute;
  left: 50%;
  top: 0;
}

.new-users.activity .gradient-round {
  padding: 13px;
  font-size: 20px;
  line-height: 1.4;
  text-align: center;
  top: unset !important;
}
.new-users.activity .gradient-round::after {
  display: none;
}

/**=====================
   3.35 Projectlist CSS Ends
==========================**/
/**=====================
   3.36 Rating CSS Start
==========================**/
@-webkit-keyframes shake {
  30% {
    -webkit-transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
            transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
  }
  60% {
    -webkit-transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
            transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
  }
  100% {
    -webkit-transform: perspective(240px) translateZ(4px);
            transform: perspective(240px) translateZ(4px);
  }
}
@keyframes shake {
  30% {
    -webkit-transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
            transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
  }
  60% {
    -webkit-transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
            transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
  }
  100% {
    -webkit-transform: perspective(240px) translateZ(4px);
            transform: perspective(240px) translateZ(4px);
  }
}
@-webkit-keyframes tear {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2px) scale(0) translateZ(0);
            transform: translateY(-2px) scale(0) translateZ(0);
  }
  50% {
    -webkit-transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
            transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
  }
  20%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
            transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
  }
}
@keyframes tear {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2px) scale(0) translateZ(0);
            transform: translateY(-2px) scale(0) translateZ(0);
  }
  50% {
    -webkit-transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
            transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
  }
  20%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
            transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
  }
}
@-webkit-keyframes toggle {
  50% {
    -webkit-transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
            transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
  }
}
@keyframes toggle {
  50% {
    -webkit-transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
            transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
  }
}
@-webkit-keyframes angry {
  40% {
    background: var(--active);
  }
  45% {
    -webkit-box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
            box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
  }
}
@keyframes angry {
  40% {
    background: var(--active);
  }
  45% {
    -webkit-box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
            box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
  }
}
.feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.feedback {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.feedback li {
  position: relative;
  border-radius: 50%;
  background: var(--sb, var(--normal));
  -webkit-box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
          box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
  -webkit-transition: background 0.4s, -webkit-box-shadow 0.4s, -webkit-transform 0.3s;
  transition: background 0.4s, -webkit-box-shadow 0.4s, -webkit-transform 0.3s;
  transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
  transition: background 0.4s, box-shadow 0.4s, transform 0.3s, -webkit-box-shadow 0.4s, -webkit-transform 0.3s;
  -webkit-tap-highlight-color: transparent;
}
.feedback li:not(:last-child) {
  margin-right: 20px;
}
.feedback li div {
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: perspective(240px) translateZ(4px);
          transform: perspective(240px) translateZ(4px);
}
.feedback li div svg {
  display: block;
  position: absolute;
  left: var(--l, 9px);
  top: var(--t, 13px);
  width: var(--w, 8px);
  height: var(--h, 2px);
  -webkit-transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
          transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  fill: none;
  stroke: var(--s);
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
  -webkit-transition: stroke 0.4s;
  transition: stroke 0.4s;
}
.feedback li div:before {
  display: block;
  position: absolute;
  left: var(--l, 9px);
  top: var(--t, 13px);
  width: var(--w, 8px);
  height: var(--h, 2px);
  -webkit-transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
          transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  content: "";
  z-index: var(--zi, 1);
  border-radius: var(--br, 1px);
  background: var(--b, var(--e, var(--normal-eye)));
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.feedback li div:after {
  display: block;
  position: absolute;
  left: var(--l, 9px);
  top: var(--t, 13px);
  width: var(--w, 8px);
  height: var(--h, 2px);
  -webkit-transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
          transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  content: "";
  z-index: var(--zi, 1);
  border-radius: var(--br, 1px);
  background: var(--b, var(--e, var(--normal-eye)));
  -webkit-transition: background 0.4s;
  transition: background 0.4s;
}
.feedback li div svg.eye {
  --s: var(--e, var(--normal-eye));
  --t: 17px;
  --w: 7px;
  --h: 4px;
}
.feedback li div svg.eye.right {
  --l: 23px;
}
.feedback li div svg.mouth {
  --s: var(--m, var(--normal-mouth));
  --l: 11px;
  --t: 23px;
  --w: 18px;
  --h: 7px;
}
.feedback li:not(.active) {
  cursor: pointer;
}
.feedback li:not(.active):active {
  -webkit-transform: scale(0.925);
          transform: scale(0.925);
}
.feedback li.angry {
  --step-1-rx: -24deg;
  --step-1-ry: 20deg;
  --step-2-rx: -24deg;
  --step-2-ry: -20deg;
}
.feedback li.angry div:before {
  --r: 20deg;
}
.feedback li.angry div:after {
  --l: 23px;
  --r: -20deg;
}
.feedback li.angry div svg.eye {
  stroke-dasharray: 4.55;
  stroke-dashoffset: 8.15;
}
.feedback li.angry.active {
  -webkit-animation: angry 1s linear;
  animation: angry 1s linear;
}
.feedback li.angry.active div:before {
  --middle-y: -2px;
  --middle-r: 22deg;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.angry.active div:after {
  --middle-y: 1px;
  --middle-r: -18deg;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.sad {
  --step-1-rx: 20deg;
  --step-1-ry: -12deg;
  --step-2-rx: -18deg;
  --step-2-ry: 14deg;
}
.feedback li.sad div:before {
  --b: var(--active-tear);
  --sc: 0;
  --w: 5px;
  --h: 5px;
  --t: 15px;
  --br: 50%;
}
.feedback li.sad div:after {
  --b: var(--active-tear);
  --sc: 0;
  --w: 5px;
  --h: 5px;
  --t: 15px;
  --br: 50%;
  --l: 25px;
}
.feedback li.sad div svg.eye {
  --t: 16px;
}
.feedback li.sad div svg.mouth {
  --t: 24px;
  stroke-dasharray: 9.5;
  stroke-dashoffset: 33.25;
}
.feedback li.sad.active div:before {
  -webkit-animation: tear 0.6s linear forwards;
  animation: tear 0.6s linear forwards;
}
.feedback li.sad.active div:after {
  -webkit-animation: tear 0.6s linear forwards;
  animation: tear 0.6s linear forwards;
}
.feedback li.ok {
  --step-1-rx: 4deg;
  --step-1-ry: -22deg;
  --step-1-rz: 6deg;
  --step-2-rx: 4deg;
  --step-2-ry: 22deg;
  --step-2-rz: -6deg;
}
.feedback li.ok div:before {
  --l: 12px;
  --t: 17px;
  --h: 4px;
  --w: 4px;
  --br: 50%;
  -webkit-box-shadow: 12px 0 0 var(--e, var(--normal-eye));
          box-shadow: 12px 0 0 var(--e, var(--normal-eye));
}
.feedback li.ok div:after {
  --l: 13px;
  --t: 26px;
  --w: 14px;
  --h: 2px;
  --br: 1px;
  --b: var(--m, var(--normal-mouth));
}
.feedback li.ok.active div:before {
  --middle-s-y: .35;
  -webkit-animation: toggle 0.2s linear forwards;
  animation: toggle 0.2s linear forwards;
}
.feedback li.ok.active div:after {
  --middle-s-x: .5;
  -webkit-animation: toggle 0.7s linear forwards;
  animation: toggle 0.7s linear forwards;
}
.feedback li.good {
  --step-1-rx: -14deg;
  --step-1-rz: 10deg;
  --step-2-rx: 10deg;
  --step-2-rz: -8deg;
}
.feedback li.good div:before {
  --b: var(--m, var(--normal-mouth));
  --w: 5px;
  --h: 5px;
  --br: 50%;
  --t: 22px;
  --zi: 0;
  opacity: 0.5;
  -webkit-box-shadow: 16px 0 0 var(--b);
          box-shadow: 16px 0 0 var(--b);
  -webkit-filter: blur(2px);
          filter: blur(2px);
}
.feedback li.good div:after {
  --sc: 0;
}
.feedback li.good div svg.eye {
  --t: 15px;
  --sc: -1;
  stroke-dasharray: 4.55;
  stroke-dashoffset: 8.15;
}
.feedback li.good div svg.mouth {
  --t: 22px;
  --sc: -1;
  stroke-dasharray: 13.3;
  stroke-dashoffset: 23.75;
}
.feedback li.good.active div svg.mouth {
  --middle-y: 1px;
  --middle-s: -1;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.happy div {
  --step-1-rx: 18deg;
  --step-1-ry: 24deg;
  --step-2-rx: 18deg;
  --step-2-ry: -24deg;
}
.feedback li.happy div:before {
  --sc: 0;
}
.feedback li.happy div:after {
  --b: var(--m, var(--normal-mouth));
  --l: 11px;
  --t: 23px;
  --w: 18px;
  --h: 8px;
  --br: 0 0 8px 8px;
}
.feedback li.happy div svg.eye {
  --t: 14px;
  --sc: -1;
}
.feedback li.happy.active div:after {
  --middle-s-x: .95;
  --middle-s-y: .75;
  -webkit-animation: toggle 0.8s linear forwards;
  animation: toggle 0.8s linear forwards;
}
.feedback li.active {
  --sb: var(--active);
  --sh: var(--active-shadow);
  --m: var(--active-mouth);
  --e: var(--active-eye);
}
.feedback li.active div {
  -webkit-animation: shake 0.8s linear forwards;
  animation: shake 0.8s linear forwards;
}

html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
}

* {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
*:before {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}
*:after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit;
}

/**=====================
   3.36 Rating CSS Ends
==========================**/
/**=====================
    3.37 Social-app CSS start
==========================**/
.user-profile .hovercard .socialheader {
  /* background: url(../../images/social-image.png); */
}
.user-profile .hovercard .user-image .share-icons {
  position: absolute;
  right: 100px;
  top: -27px;
}
.user-profile .hovercard .user-image .share-icons li {
  display: inline-block;
  margin-right: 10px;
}
.user-profile .hovercard .user-image .share-icons li .social-icon {
  width: 50px;
  height: 50px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  vertical-align: middle;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 100%;
}
.user-profile .hovercard .user-image .share-icons li .social-icon i {
  color: #fff;
  font-size: 25px;
}
.user-profile .hovercard .user-image .share-icons li:last-child {
  margin-right: 0;
}
.user-profile .card-footer > div h3 {
  font-size: 16px;
}

.market-tabs .border-tab.nav-tabs .nav-item .nav-link.active {
  border-bottom: none !important;
}
.market-tabs .nav {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.market-tabs .nav .nav-item {
  width: 100%;
}
.market-tabs .nav .nav-item .nav-link {
  height: 81px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 30px;
  text-align: center;
}

.tabs-scoial {
  border-bottom: none;
  margin-bottom: 20px !important;
  margin-top: 20px;
}
.tabs-scoial li a {
  font-weight: 600;
}
.tabs-scoial li a svg circle {
  color: #999999;
}

.social-network span {
  margin-bottom: 30px;
  display: block;
}

.social-list .d-flex {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.social-list .d-flex:last-child {
  margin-bottom: 0;
}
.social-list .d-flex .flex-grow-1 a {
  color: #999999;
}
.social-list .d-flex .flex-grow-1 span {
  font-weight: 600;
}
.social-list .d-flex .flex-grow-1:hover a {
  color: var(--theme-deafult);
}

.photos {
  margin-bottom: -15px;
}
.photos ul li {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 70px;
}
.photos ul li:nth-child(4n) {
  margin-right: 0;
}

.new-users-social {
  margin-bottom: 30px;
}
.new-users-social svg {
  cursor: pointer;
}
.new-users-social .d-flex img {
  width: 58px;
}
.new-users-social .d-flex .flex-grow-1 {
  margin-top: 10px;
}
.new-users-social svg circle {
  color: #999999;
}

.like-content span i {
  font-size: 14px;
}

.timeline-content p {
  margin-top: 30px;
  color: #999999;
}
.timeline-content .comment-number i {
  color: #999999;
  margin-right: 20px;
}
.timeline-content .comments-box {
  margin-top: 30px;
}
.timeline-content .comments-box .input-group {
  margin-top: 6px;
}
.timeline-content .comments-box .input-group .input-txt-bx {
  border: none;
}
.timeline-content .comments-box .input-group .input-txt-bx::-webkit-input-placeholder {
  color: #999999;
}
.timeline-content .comments-box .input-group .input-txt-bx::-moz-placeholder {
  color: #999999;
}
.timeline-content .comments-box .input-group .input-txt-bx:-ms-input-placeholder {
  color: #999999;
}
.timeline-content .comments-box .input-group .input-txt-bx::-ms-input-placeholder {
  color: #999999;
}
.timeline-content .comments-box .input-group .input-txt-bx::placeholder {
  color: #999999;
}
.timeline-content .comments-box .input-group .input-group-append .btn i {
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: #999999;
}

.social-chat {
  margin-top: 30px;
}
.social-chat span span {
  font-weight: normal;
  color: #999999;
  font-size: 12px;
}
.social-chat .flex-grow-1 {
  padding: 30px;
  border: 1px solid rgba(89, 102, 122, 0.28);
  position: relative;
}
.social-chat .flex-grow-1 p {
  margin-top: 5px;
}
.social-chat .flex-grow-1:before {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #59667a;
}
.social-chat .flex-grow-1:after {
  content: "";
  position: absolute;
  top: 19px;
  right: 100%;
  border: 7px solid transparent;
  border-right: 7px solid #fff;
}
.social-chat .your-msg,
.social-chat .other-msg {
  margin-bottom: 30px;
}
.social-chat .other-msg {
  margin-left: 40px;
}

.socialprofile span {
  color: #999999;
}
.socialprofile .social-btngroup {
  margin: 30px 0;
}
.socialprofile .likes-profile h5 span {
  color: #3D434A;
}
.socialprofile .social-group {
  margin-top: 30px;
}
.socialprofile .social-group ul li:nth-child(4) {
  margin-left: -22px;
}

.social-status form .form-group .form-control-social {
  border: 1px solid #f4f4f4;
  padding-left: 15px;
  margin-bottom: 30px;
}
.social-status .d-flex {
  position: relative;
  margin-bottom: 30px;
}
.social-status .d-flex:last-child {
  margin-bottom: 0;
}
.social-status .d-flex .social-status {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: #fff;
  bottom: 0;
  left: 35px;
  border-radius: 100%;
}
.social-status .d-flex .social-online {
  border: 3px solid #0DA759;
}
.social-status .d-flex .social-busy {
  border: 3px solid #D77748;
}
.social-status .d-flex .social-offline {
  border: 3px solid #59667a;
}
.social-status .d-flex .flex-grow-1 p {
  color: #999999;
  margin-bottom: 5px;
}
.social-status .d-flex .flex-grow-1 span + span {
  color: #999999;
}
.social-status .d-flex .flex-grow-1 .light-span {
  color: #999999;
}

.social-header h5 span:first-child {
  line-height: 1.48;
}
.social-header h5 span svg circle {
  color: #999999;
}

.details-about + .details-about {
  margin-top: 30px;
}
.details-about .your-details p {
  color: #999999;
}

.avatar-showcase .friend-pic {
  margin-right: 8px;
  margin-bottom: 10px;
}
.avatar-showcase .pepole-knows ul li {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
}
.avatar-showcase .pepole-knows ul li:last-child {
  margin-right: 0;
}
.avatar-showcase .pepole-knows ul li .add-friend h6 {
  margin-top: 3px;
}

.activity-log .my-activity + .my-activity {
  margin-top: 30px;
}
.activity-log .my-activity p {
  margin-bottom: 5px;
}
.activity-log .my-activity p span svg {
  width: 15px;
  height: 15px;
}

/**=====================
    3.37 Social-app CSS end
==========================**/
/**=====================
    3.38 Sweet-alert CSS Start
==========================**/
.swal-modal .swal-title {
  font-size: 24px;
}
.swal-modal .swal-text {
  font-size: 17px;
}

/**=====================
    3.38 Sweet-alert CSS ends
==========================**/
/**=====================
    3.39 Task CSS Start
==========================**/
.modal-open .datepickers-container {
  z-index: 1075;
}

.date-details {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.date-details > div + div {
  margin-left: 10px;
}

.taskadd table tr td {
  border-top: none;
}
.taskadd table tr td:first-child {
  padding-left: 30px;
}
.taskadd table tr td:last-child {
  padding-right: 30px;
}
.taskadd table tr td svg {
  width: 18px;
  height: 18px;
  color: #59667a;
}
.taskadd table tr:first-child td {
  padding-top: 30px;
}
.taskadd table tr:last-child td {
  padding-bottom: 30px;
}

@media only screen and (max-width: 1660px) {
  .taskadd table tr td:first-child {
    min-width: 160px;
  }
  .taskadd table tr td:nth-child(3) {
    min-width: 70px;
  }
}
@media only screen and (max-width: 991px) {
  .date-details {
    display: block !important;
    margin-bottom: -10px;
  }
  .date-details .d-inline-block {
    margin-bottom: 10px;
  }
  .taskadd table tr td:first-child {
    padding-left: 20px;
  }
  .taskadd table tr:first-child td {
    padding-top: 20px;
  }
  .taskadd table tr:last-child td {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .taskadd table tr td:nth-child(2) {
    min-width: 745px;
  }
}
@media only screen and (max-width: 575px) {
  .taskadd table tr td:first-child {
    padding-left: 15px;
  }
  .taskadd table tr:first-child td {
    padding-top: 15px;
  }
  .taskadd table tr:last-child td {
    padding-bottom: 15px;
  }
}
/**=====================
  3.39 Task CSS Ends
==========================**/
/**=====================
    3.40 Timeline-v CSS Start
==========================**/
.timeline-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-bottom: 12px;
}

.time-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 4px;
}
.time-content i {
  font-size: 18px;
}

.notification li .recent-images ul::before {
  display: none;
}
.notification li .recent-images li {
  padding-bottom: 3px;
}

.activity-dot-primary {
  min-width: 6px;
  height: 6px;
  background-color: #337ab7;
  border-radius: 100%;
  outline: 5px solid rgba(122, 112, 186, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-primary {
  min-width: 12px;
  height: 12px;
  background-color: #337ab7;
  outline: 5px solid rgba(122, 112, 186, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-secondary {
  min-width: 6px;
  height: 6px;
  background-color: #48A3D7;
  border-radius: 100%;
  outline: 5px solid rgba(72, 163, 215, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-secondary {
  min-width: 12px;
  height: 12px;
  background-color: #48A3D7;
  outline: 5px solid rgba(72, 163, 215, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-success {
  min-width: 6px;
  height: 6px;
  background-color: #0DA759;
  border-radius: 100%;
  outline: 5px solid rgba(13, 167, 89, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-success {
  min-width: 12px;
  height: 12px;
  background-color: #0DA759;
  outline: 5px solid rgba(13, 167, 89, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-danger {
  min-width: 6px;
  height: 6px;
  background-color: #E44141;
  border-radius: 100%;
  outline: 5px solid rgba(228, 65, 65, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-danger {
  min-width: 12px;
  height: 12px;
  background-color: #E44141;
  outline: 5px solid rgba(228, 65, 65, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-info {
  min-width: 6px;
  height: 6px;
  background-color: #16C7F9;
  border-radius: 100%;
  outline: 5px solid rgba(22, 199, 249, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-info {
  min-width: 12px;
  height: 12px;
  background-color: #16C7F9;
  outline: 5px solid rgba(22, 199, 249, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-light {
  min-width: 6px;
  height: 6px;
  background-color: #f4f4f4;
  border-radius: 100%;
  outline: 5px solid rgba(244, 244, 244, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-light {
  min-width: 12px;
  height: 12px;
  background-color: #f4f4f4;
  outline: 5px solid rgba(244, 244, 244, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-dark {
  min-width: 6px;
  height: 6px;
  background-color: #2c323f;
  border-radius: 100%;
  outline: 5px solid rgba(44, 50, 63, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-dark {
  min-width: 12px;
  height: 12px;
  background-color: #2c323f;
  outline: 5px solid rgba(44, 50, 63, 0.25);
  position: relative;
  z-index: 2;
}

.activity-dot-warning {
  min-width: 6px;
  height: 6px;
  background-color: #D77748;
  border-radius: 100%;
  outline: 5px solid rgba(215, 119, 72, 0.25);
  position: relative;
  z-index: 2;
}

.timeline-dot-warning {
  min-width: 12px;
  height: 12px;
  background-color: #D77748;
  outline: 5px solid rgba(215, 119, 72, 0.25);
  position: relative;
  z-index: 2;
}

.recent-images {
  margin-top: 10px;
}
.recent-images ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 8px;
}
.recent-images li {
  border: 1px solid var(--recent-solid-border);
  padding: 3px;
  border-radius: 2px;
}
.recent-images li .recent-img-wrap {
  width: 40px;
  height: 40px;
  background: var(--light2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.notification ul {
  position: relative;
}
.notification ul::before {
  position: absolute;
  content: "";
  border: 1px solid #9B9B9B;
  opacity: 0.3;
  top: 12px;
  left: 2px;
  height: calc(100% - 12px);
}
[dir=rtl] .notification ul::before {
  left: unset;
  right: 2px;
}
.notification li {
  padding-bottom: 22px;
}
.notification div[class*=activity-dot-] {
  margin-top: 12px;
  -webkit-animation: round 1.3s ease-in-out infinite;
          animation: round 1.3s ease-in-out infinite;
}
.notification .card .d-flex .w-100 p .badge {
  color: #fff;
  margin-left: 10px;
  font-weight: 500;
}
.notification .card .d-flex .w-100 h6 {
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}
.notification .card .d-flex .w-100 h6 ~ p {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  -webkit-text-emphasis: inherit;
          text-emphasis: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  width: 260px;
}
@media (max-width: 767px) {
  .notification .card .d-flex .w-100 h6 ~ p {
    -webkit-line-clamp: unset;
    -webkit-text-emphasis: unset;
            text-emphasis: unset;
    white-space: unset;
    text-overflow: unset;
    display: block;
    width: unset;
    overflow: visible;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes round {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.notification .card .d-flex .w-100 span {
  color: #9B9B9B;
}
.notification .card .d-flex:nth-child(2) .media-body h6 .dot-notification {
  background-color: #D77748;
  border: 5px solid #f2d4c6;
}
.notification .card .d-flex:last-child {
  margin-bottom: 0 !important;
}
.notification .date-content {
  padding: 4px 8px;
  border-radius: 5px;
  font-weight: 600;
}

.activity .d-flex {
  margin-bottom: 30px;
}
.activity .d-flex:last-child {
  margin-bottom: 0;
}
.activity .d-flex .gradient-round {
  position: relative;
  width: 53px;
  height: 53px;
  background-image: linear-gradient(26deg, var(--theme-deafult) 0%, #a26cf8 100%);
  border-radius: 100%;
  top: -18px;
}
.activity .d-flex .gradient-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .gradient-round svg path,
.activity .d-flex .gradient-round svg line,
.activity .d-flex .gradient-round svg circle {
  color: #fff;
}
.activity .d-flex .gradient-round.gradient-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -77px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .gradient-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .gradient-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.activity .d-flex .flex-grow-1 h6 {
  font-weight: 600;
  margin-bottom: 30px;
}
.activity .d-flex .flex-grow-1 h6 span {
  font-weight: normal;
  color: #999999;
}
.activity .d-flex .flex-grow-1 p {
  width: 80%;
}

.timeline-small .d-flex {
  margin-bottom: 30px;
}
.timeline-small .d-flex:last-child {
  margin-bottom: 0;
}
.timeline-small .d-flex .timeline-round {
  position: relative;
  width: 53px;
  height: 53px;
  border-radius: 100%;
  border-radius: 15px;
  top: -18px;
}
.timeline-small .d-flex .timeline-round svg {
  position: absolute;
  width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  left: 0;
  top: 18px;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .timeline-round svg path,
.timeline-small .d-flex .timeline-round svg line,
.timeline-small .d-flex .timeline-round svg circle {
  color: #fff;
}
.timeline-small .d-flex .timeline-round.timeline-line-1:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 62px;
  bottom: -88px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .timeline-round.small-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 19px;
  bottom: -34px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .timeline-round.medium-line:after {
  position: absolute;
  content: "";
  background-color: #e9e9e9;
  width: 2px;
  height: 53px;
  bottom: -68px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.timeline-small .d-flex .flex-grow-1 h6 {
  margin-bottom: 30px;
}
.timeline-small .d-flex .flex-grow-1 h6 span {
  font-weight: normal;
  color: #999999;
}
.timeline-small .d-flex .flex-grow-1 p {
  width: 80%;
}

.cd-container {
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}

.cd-container::after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (min-width: 1170px) {
  #cd-timeline::before {
    left: 50% !important;
    margin-left: -2px;
  }
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    right: 122%;
    text-align: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    border-right-color: #f4f4f4;
  }
  .cd-timeline-img {
    width: 60px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
  }
  .cd-timeline-img > i {
    font-size: 18px;
    top: 50%;
    left: 50%;
    padding: 3px;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
            animation: cd-bounce-1 0.6s;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
            animation: cd-bounce-2 0.6s;
  }
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
            animation: cd-bounce-2-inverse 0.6s;
  }
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    top: 6px;
    font-size: 16px;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    border-left-color: #fff;
  }
}
#cd-timeline {
  position: relative;
  padding: 2em 0;
}

#cd-timeline::before {
  content: "";
  position: absolute;
  top: 0;
  font-size: 1.5rem;
  height: 100%;
  width: 2px;
  background: #f4f4f4;
  left: -15px;
}

.cd-timeline-block {
  position: relative;
  margin: 30px 0;
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}

.cd-timeline-img {
  width: 40px;
  height: 40px;
  left: 51%;
  margin-left: -30px;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  position: absolute;
  top: 0;
  border-radius: 50px;
}
.cd-timeline-img i {
  display: block;
  position: relative;
  left: 45%;
  top: 44%;
  margin-left: -12px;
  margin-top: -12px;
  font-size: 20px;
  color: #fff;
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.cd-timeline-content {
  position: relative;
  background: #f4f4f4;
  border-radius: 4px;
  padding: 30px;
  -webkit-box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
          box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.08);
}
.cd-timeline-content p {
  padding-top: 9px;
}
.cd-timeline-content h4 {
  margin-bottom: 0;
}
.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-content audio {
  width: 100%;
}
.cd-timeline-content .cd-date {
  font-size: 13px;
  display: inline-block;
  float: left;
  padding: 0.8em 0 0;
  opacity: 0.7;
}

.cd-timeline-content::before {
  content: "";
  position: absolute;
  top: 28px;
  right: 100%;
  height: 0;
  width: 0;
  border: 15px solid transparent;
  border-left: 15px solid #f4f4f4;
}
[dir=rtl] .cd-timeline-content::before {
  border-right: 15px solid #f4f4f4;
  border-left: unset;
}
@media (max-width: 1169px) {
  .cd-timeline-content::before {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .cd-timeline-content .cd-date {
    font-size: 14px;
    padding-bottom: 0;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
            transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
            transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 1;
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
            transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
            transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}
@media only screen and (max-width: 1169px) {
  .cd-timeline-block .cd-timeline-img {
    left: -2px;
    height: 30px;
    width: 30px;
    margin-left: -30px;
    top: 14px;
    z-index: 7;
  }
  .cd-timeline-block .cd-timeline-img > i {
    font-size: 15px;
    top: 16px;
    left: 17px;
    padding: 3px;
  }
  .landing-main #cd-timeline::before {
    left: -39px;
  }
  .landing-main .cd-timeline-block .cd-timeline-img {
    left: -34px;
    height: 50px;
    width: 50px;
    top: 1px;
    z-index: 8;
  }
  .landing-main .cd-timeline-block .cd-timeline-img > i {
    font-size: 23px;
    top: 21px;
    left: 7px;
    padding: 3px;
  }
}
@media only screen and (max-width: 576px) {
  .cd-timeline-content {
    padding: 15px;
  }
  .cd-timeline-content h4 {
    font-size: 18px;
  }
}
.main-timeline ul::before {
  left: 5px;
}
[dir=rtl] .main-timeline ul::before {
  right: 5px;
  left: unset;
}

.square-timeline {
  position: relative;
}
.square-timeline::before {
  border: 1px solid rgba(106, 113, 133, 0.3);
  content: "";
  margin-left: 0px;
  position: absolute;
  top: 0;
  left: 18px;
  width: 1px;
  height: 100%;
}
[dir=rtl] .square-timeline::before {
  margin-left: unset;
  margin-right: 0;
  left: unset;
  right: 18px;
}
.square-timeline .timeline-event {
  position: relative;
}
.square-timeline .timeline-event:hover .timeline-event-icon {
  -webkit-transform: rotate(-105deg);
          transform: rotate(-105deg);
  background-color: #52526c;
}
.square-timeline .timeline-event:hover .timeline-event-wrapper .timeline-thumbnail {
  -webkit-box-shadow: inset 40em 0 0 0 #fbdac9;
          box-shadow: inset 40em 0 0 0 #fbdac9;
}
.square-timeline .timeline-event .timeline-event-wrapper {
  padding: 0 0 0 14px;
  position: relative;
  top: -7px;
  left: 38px;
  width: 95%;
}
[dir=rtl] .square-timeline .timeline-event .timeline-event-wrapper {
  left: unset;
  right: 38px;
  padding: 0 14px 0 0;
}
@media (max-width: 1200px) {
  .square-timeline .timeline-event .timeline-event-wrapper {
    width: calc(84% + 11 * (100vw - 320px) / 880);
  }
}
.square-timeline .timeline-event .timeline-event-wrapper .designer-details {
  padding-top: 20px;
}
.square-timeline .timeline-event .timeline-event-wrapper .timeline-thumbnail {
  -webkit-transition: -webkit-box-shadow 0.5s ease-in 0.1s;
  transition: -webkit-box-shadow 0.5s ease-in 0.1s;
  transition: box-shadow 0.5s ease-in 0.1s;
  transition: box-shadow 0.5s ease-in 0.1s, -webkit-box-shadow 0.5s ease-in 0.1s;
  color: #2c323f;
  background-color: #E0DDFF;
  -webkit-box-shadow: inset 0 0 0 0em #d0f3fd;
          box-shadow: inset 0 0 0 0em #d0f3fd;
  display: inline-block;
  margin-bottom: 12px;
  padding: 2px 25px;
}
.square-timeline .timeline-event .timeline-event-wrapper .list-group-item-action:hover, .square-timeline .timeline-event .timeline-event-wrapper .list-group-item-action:active, .square-timeline .timeline-event .timeline-event-wrapper .list-group-item-action:focus {
  background-color: unset;
}
.square-timeline .timeline-event .timeline-event-wrapper > div {
  display: inline-block;
  width: 100%;
}
.square-timeline .timeline-event .timeline-event-icon {
  -webkit-transition: 0.2s ease-in;
  transition: 0.2s ease-in;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #337ab7;
  outline: 6px solid #E0DDFF;
  display: block;
  margin: 0px;
  position: absolute;
  top: 0;
  left: 14px;
  width: 10px;
  height: 10px;
}
[dir=rtl] .square-timeline .timeline-event .timeline-event-icon {
  right: 14px;
  left: unset;
}

.list-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.list-inline.events .event-list {
  display: block;
  position: relative;
  text-align: center;
  padding-top: 70px;
  margin-right: 0;
}
.list-inline.events .event-list p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.list-inline.events .event-list .event-date {
  position: absolute;
  top: 38px;
  left: 0;
  right: 0;
  width: 75px;
  margin: 0 auto;
  border-radius: 4px;
  padding: 2px 4px;
}
.list-inline.events .event-list .event-date p {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 1.5;
  margin-top: 7px;
}

.timeline-list.timeline-border {
  border-bottom: 1px solid rgba(106, 113, 133, 0.3);
}
.timeline-list.list-inline {
  padding-bottom: 0;
}
.timeline-list.list-inline.events .event-list {
  padding-top: 35px;
}
.timeline-list.list-inline.events .event-list::before {
  content: "";
  height: 0;
  border-right: unset;
}
.timeline-list.list-inline.events .event-list .event-date {
  top: 2px;
}
.timeline-list.list-inline.events .event-list .event-date p {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  line-height: 1.5;
  margin-top: 7px;
}
.timeline-list.list-inline.events .event-list .read-more-btn {
  position: relative;
  margin-bottom: 3px;
}
.timeline-list.list-inline .vertical-line {
  height: 45px;
  width: 1px;
  margin: 0 auto;
  position: relative;
  border: 1px solid #337ab7;
}

.list-group.main-lists-content {
  padding-top: 20px;
}
@media (max-width: 1218px) {
  .list-group .list-group-item .timeline-icon {
    display: none;
  }
}

@media (max-width: 1400px) {
  .horizontal-timeline {
    display: none;
  }
}

hr {
  border-bottom: 1px solid #ccc !important;
  /* border-color: #ccc; */
}

/**=====================
    3.40 Timeline-v CSS Ends
==========================**/
/**=====================
    3.41 User-profile CSS start
==========================**/
.user-profile .ttl-info h6 {
  font-size: 12px;
  color: #59667a;
}
.user-profile .border-right {
  border-right: 1px solid #f4f4f4;
}
.user-profile hr {
  margin: 16px 0;
}
.user-profile .hovercard .cardheader {
  /* background: url(../../images/bg-profile.png); */
  background-size: cover;
  background-position: 10%;
  height: 470px;
}
.user-profile .hovercard .user-image {
  position: relative;
  height: 0;
}
.user-profile .hovercard .user-image .avatar {
  margin-top: -80px;
}
.user-profile .hovercard .user-image .avatar img {
  width: 100px;
  height: 100px;
  max-width: 155px;
  max-height: 155px;
  border-radius: 50%;
  border: 7px solid #fff;
}
.user-profile .hovercard .user-image .icon-wrapper {
  position: absolute;
  bottom: -20px;
  left: 51%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  font-size: 17px;
  -webkit-box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
          box-shadow: 0 0 6px 3px rgba(68, 102, 242, 0.1);
}
.user-profile .hovercard .info {
  padding: 30px;
}
.user-profile .hovercard .info .title {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1;
  color: #2c323f;
  vertical-align: middle;
}
.user-profile .hovercard .info .title a {
  color: #3D434A;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 600;
}
.user-profile .hovercard .info .desc {
  overflow: hidden;
  font-size: 14px;
  line-height: 20px;
  color: #59667a;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.user-profile .follow {
  margin-top: 20px;
}
.user-profile .follow .col-6:last-child {
  border-left: 1px solid #f4f4f4;
}
[dir=rtl] .user-profile .follow .col-6:last-child {
  border-left: unset;
  border-right: 1px solid #f4f4f4;
}
.user-profile .follow .follow-num {
  font-size: 20px;
  color: #9B9B9B;
  font-family: "Nunito", sans-serif, sans-serif;
  font-weight: 500;
}
.user-profile .follow span {
  color: #59667a;
}
.user-profile .profile-img-style {
  padding: 30px;
}
.user-profile .profile-img-style .img-container {
  margin-top: 30px;
}
.user-profile .profile-img-style .user-name {
  font-size: 14px;
  color: #9B9B9B;
  margin: 0;
  font-weight: 600;
}
.user-profile .profile-img-style p {
  line-height: 30px;
  font-size: 14px;
  color: #898989;
  margin-bottom: 0;
}
.user-profile .profile-img-style .d-flex img {
  width: 50px;
}
.user-profile .like-comment li a {
  color: #898989;
}
.user-profile .like-comment li:first-child a:hover, .user-profile .like-comment li:first-child a:active {
  color: #E44141;
}
.user-profile .like-comment span {
  font-family: "Nunito", sans-serif, sans-serif;
}
.user-profile .social-media a {
  padding: 0 15px;
  color: #59667a;
  font-size: 24px;
  cursor: pointer;
}
.user-profile .social-media a:hover {
  color: var(--theme-deafult);
}

.edit-profile .profile-title .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 30px;
}
.edit-profile .profile-title .d-flex .flex-grow-1 {
  margin-left: 14px;
}
[dir=rtl] .edit-profile .profile-title .d-flex .flex-grow-1 {
  margin-left: unset;
  margin-right: 14px;
}

/**=====================
    3.41 User-profile CSS Ends
==========================**/
/**=====================
    3.42 Wishlist CSS start
==========================**/
.wishlist table tr td svg {
  color: #898989;
  cursor: pointer;
}

/**=====================
    3.42 Wishlist CSS Ends
==========================**/
/* ---------------------
	*** themes ***
-----------------------*/
/**=====================
	4.1. Dark CSS Start
==========================**/
body.dark-sidebar .toggle-sidebar svg {
  stroke: var(--theme-deafult);
}
body.dark-sidebar .page-wrapper .sidebar-main-title p {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .logo-wrapper .for-light {
  display: block;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .logo-wrapper .for-dark {
  display: none;
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  background-color: #2A3650;
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
  stroke: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  background-color: rgba(122, 112, 186, 0.2);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
  color: var(--theme-deafult);
  stroke: var(--theme-deafult);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-deafult);
}
body.dark-sidebar .page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper .radius-wrapper {
  background-color: #374462;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
  background: #2A3650;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
  background-color: #2A3650;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
  background: #2A3650;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active, body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
  color: var(--theme-deafult);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
  background: #2A3650;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
  background: #2A3650;
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
body.dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
  background-color: #2A3650;
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  background: #2A3650;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
  stroke: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  background-color: #2A3650;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  background-color: rgba(122, 112, 186, 0.2);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
  color: var(--theme-deafult);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
  color: var(--theme-deafult);
  stroke: var(--theme-deafult);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-deafult);
}
body.dark-sidebar .page-wrapper.compact-sidebar .page-body-wrapper .according-menu i {
  color: rgba(255, 255, 255, 0.7);
}
body .for-dark {
  display: none;
}
body[class*=dark-] .for-dark {
  display: block;
}
body[class*=dark-] .for-light {
  display: none;
}

/**=====================
	4.1. Dark CSS ends
==========================**/
/**=====================
  4.2 Theme-customizer CSS Start
==========================**/
.customizer-links {
  position: fixed;
  right: 0px;
  top: 50%;
  z-index: 3;
  -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
          box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
}
.customizer-links > .nav {
  border-radius: 8px;
  padding: 10px;
  background-color: #fff;
}
.customizer-links.open {
  right: 330px;
  border-radius: 8px 0 0 8px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-links .nav-link {
  padding: 0;
  position: relative;
}
.customizer-links .nav-link div {
  padding: 4px;
}
.customizer-links .nav-link:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(115, 102, 255, 0.1);
  border-radius: 5px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-links .nav-link:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  top: 12px;
  border-width: 5px 0 5px 5px;
  border-left-color: #cac6e3;
  opacity: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  color: #fff;
  left: -10px;
  z-index: 2;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-links .nav-link:hover i {
  color: var(--theme-deafult);
}
.customizer-links .nav-link:hover:before, .customizer-links .nav-link:hover:after {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-links .nav-link:hover span {
  opacity: 1;
  right: 38px;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-links .nav-link + .nav-link {
  margin-bottom: 5px;
}
.customizer-links .nav-link span {
  position: absolute;
  right: -60px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-align: right;
  padding: 7px 10px;
  display: block;
  top: 0;
  border-radius: 5px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  opacity: 0;
  color: #fff;
  background-color: #337ab7;
  -webkit-box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
          box-shadow: 0 0 37px rgba(8, 21, 66, 0.1);
}
.customizer-links .nav-link i {
  font-size: 17px;
  padding: 8px 10px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
  color: gray;
  border-radius: 5px;
}

.customizer-contain {
  direction: ltr;
  position: fixed;
  height: 100vh;
  top: 79px;
  width: 333px;
  right: -335px;
  background-color: #fff;
  z-index: 8;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-contain.open {
  right: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
          box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
}
.customizer-contain li {
  position: relative;
}
.customizer-contain li.active {
  -webkit-box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
          box-shadow: 0 0 11px 5px rgba(226, 226, 226, 0.5);
}
.customizer-contain h5 {
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0px;
  margin-top: 12px;
  padding-top: 12px;
  margin-bottom: 5px;
  color: #000;
}
.customizer-contain .customizer-header {
  padding: 15px 25px;
  border-bottom: 1px solid #f4f4f4;
}
.customizer-contain .customizer-header .icon-close {
  position: absolute;
  font-size: 18px;
  cursor: pointer;
  font-weight: 900;
  right: 24px;
  color: var(--theme-deafult);
}
.customizer-contain .customizer-header h5 {
  text-transform: uppercase;
}
.customizer-contain .customizer-body {
  padding: 15px 25px;
  max-height: calc(100vh - 200px);
  overflow-y: scroll;
}
.customizer-contain .customizer-body .fade:not(.show) {
  display: none;
}
.customizer-contain .customizer-body .unlimited-color-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.customizer-contain .customizer-body .unlimited-color-layout .btn {
  font-size: 15px;
  padding: 4px 20px;
}
.customizer-contain .customizer-body .unlimited-color-layout input {
  border: 1px solid rgba(106, 113, 133, 0.3);
  height: 35px;
  width: 35px;
  border-radius: 5px;
  background-color: #fff;
  margin-right: 5px;
}
.customizer-contain .customizer-body .layout-grid.customizer-mix .color-layout {
  height: 70px;
  width: 80px;
  border: 1px solid #f6f7fb;
  border-radius: 5px;
}
.customizer-contain .customizer-body .tab-pane > h6:first-child {
  padding-top: 0;
  margin-top: 0;
}
.customizer-contain .customizer-body li {
  cursor: pointer;
  opacity: 0.9;
}
.customizer-contain .customizer-body li.active {
  opacity: 1;
}
.customizer-contain .customizer-body .color-layout {
  height: 35px;
  width: 35px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 3px;
  border: 1px solid #b8b8b8;
  padding: 3px;
}
.customizer-contain .customizer-body .color-layout > div {
  background-color: #337ab7;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-2] div {
  background-color: #88967e;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-3] div {
  background-color: #c6164f;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-4] div {
  background-color: #4b232d;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-5] div {
  background-color: #007a91;
}
.customizer-contain .customizer-body .color-layout[data-attr=color-6] div {
  background-color: #56409f;
}
.customizer-contain .customizer-body .dark > li {
  background-color: #2f3c4e;
}
.customizer-contain .customizer-body .main-layout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.customizer-contain .customizer-body .main-layout > li {
  height: 65px;
  width: 85px;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  display: inline-block;
}
.customizer-contain .customizer-body .main-layout > li + li {
  margin-left: 7px;
}
.customizer-contain .customizer-body .main-layout .box-layout .body .badge {
  left: -14px;
}
.customizer-contain .customizer-body .sidebar-type > li,
.customizer-contain .customizer-body .sidebar-setting > li {
  height: 70px;
  width: 85px;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  display: inline-block;
}
.customizer-contain .customizer-body .sidebar-type > li + li,
.customizer-contain .customizer-body .sidebar-setting > li + li {
  margin-left: 7px;
}
.customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.bg-dark, .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.sidebar {
  width: 100%;
  height: 10px;
  display: block;
}
.customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.bg-light, .customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar] .body ul li.body {
  height: calc(100% - 10px);
  width: 100%;
}
.customizer-contain .customizer-body .sidebar-type li[data-attr=normal-sidebar].body-compact .body ul li.body {
  width: calc(100% - 25px);
}
.customizer-contain .customizer-body .layout-types {
  margin-bottom: -30px;
}
.customizer-contain .customizer-body .layout-types > li {
  display: block;
  width: unset;
  height: unset;
  margin-bottom: 30px;
}
.customizer-contain .customizer-body .layout-types > li .layout-img h6 {
  padding-top: 0;
  text-transform: inherit;
  text-align: center;
}
.customizer-contain .customizer-body .layout-types .box-layout {
  background: unset;
}

.layout-grid:not(.customizer-color) li {
  padding: 5px;
}
.layout-grid:not(.customizer-color) li > .header {
  height: 9px;
  border-radius: 3px;
  position: relative;
}
.layout-grid:not(.customizer-color) li > .header ul {
  position: absolute;
  top: -8px;
  left: 5px;
}
.layout-grid:not(.customizer-color) li > .header ul > li {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 5px;
  top: calc(50% - 3px);
  background-color: var(--theme-deafult);
  margin-right: 2px;
  padding: 0;
}
.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(2) {
  background-color: var(--theme-secondary);
}
.layout-grid:not(.customizer-color) li > .header ul > li:nth-child(3) {
  background-color: #0DA759;
}
.layout-grid:not(.customizer-color) li > .body {
  background-color: #f4f4f4;
  height: calc(100% - 16px);
  border-radius: 3px;
  position: relative;
  margin-top: 5px;
  text-align: center;
  line-height: 3.1;
}
.layout-grid:not(.customizer-color) li > .body ul {
  height: 100%;
  background-color: #fff;
}
.layout-grid:not(.customizer-color) li > .body ul .sidebar {
  width: 18px;
  height: 100%;
  border-radius: 3px;
  display: inline-block;
  margin-right: 3px;
  padding: 0;
}
.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact {
  width: 12px;
}
.layout-grid:not(.customizer-color) li > .body ul .sidebar.compact ~ .body {
  width: calc(100% - 19px);
}
.layout-grid:not(.customizer-color) li > .body ul .body {
  width: calc(100% - 25px);
  padding: 0;
  margin: 0;
  height: 100%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 3px;
}
.layout-grid:not(.customizer-color) li > .body ul .body .badge {
  position: absolute;
}

/**=====================
    4.2 Theme-customizer CSS Ends
==========================**/
/**=====================
  4.3 Update CSS Start
==========================**/
.jsgrid-filter-row select,
.jsgrid-filter-row input {
  padding: 6px 10px;
  border: 1px solid #f2f4ff;
  background: #fff;
}

hr {
  border-style: solid;
}

.todo-list-header .new-task-wrapper .form-control.error {
  margin: 0px;
}

.starter-main .alert {
  background-color: rgba(var(--theme-deafult), 0.2) !important;
}

.form-control:focus,
.form-select:focus {
  border-color: #999 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.popover {
  background-color: #fff;
  border: none;
  -webkit-box-shadow: 0 0 20px rgba(122, 112, 186, 0.1);
  box-shadow: 0 0 20px rgba(122, 112, 186, 0.1);
}
.popover .popover-header {
  background-color: #eae8ff;
  color: #337ab7;
  border-bottom: none;
}
.popover .popover-body {
  color: rgba(43, 43, 43, 0.7);
}

.tooltip {
  z-index: 1;
}
.tooltip.bs-tooltip-top .tooltip-arrow:before {
  border-top-color: #eae8ff;
}
.tooltip.bs-tooltip-bottom .tooltip-arrow:before {
  border-bottom-color: #eae8ff;
  border-top-color: transparent;
}
.tooltip.bs-tooltip-start .tooltip-arrow:before {
  border-left-color: #eae8ff;
  border-top-color: transparent;
}
.tooltip.bs-tooltip-end .tooltip-arrow:before {
  border-right-color: #eae8ff;
  border-top-color: transparent;
}
.tooltip .tooltip-inner {
  background-color: #eae8ff;
  color: #337ab7;
}
.tooltip .tooltip-arrow:before {
  border-top-color: #eae8ff;
}

.dropdown-basic .dropdown .dropdown-content a {
  padding: 6px 16px;
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  border-top: 1px solid #efefef;
  background: #fff;
}
.dropdown-basic .dropdown .dropdown-content a:hover {
  background-color: #fff;
}
.dropdown-basic .dropdown .dropdown-content .dropdown-header {
  padding: 8px 16px;
  font-weight: 400;
  color: #2c323f;
  font-size: 13px;
}
.dropdown-basic .dropup .dropup-content {
  top: auto;
}

.default-according .card .card-header {
  padding: 0;
  border: none;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.default-according .card .card-header.bg-primary .btn, .default-according .card .card-header.bg-secondary .btn {
  border-color: transparent;
}
.default-according .card .card-header i {
  position: initial;
  font-size: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
}
[dir=rtl] .default-according .card .card-header i {
  margin-right: unset;
  margin-left: 5px;
}
.default-according .card .card-header h5 {
  margin-top: 0;
}
.default-according .card .card-header .btn-link {
  padding: 12px 20px;
  width: 100%;
  font-weight: 600;
  text-align: left;
  letter-spacing: 0.7px;
  font-family: "Nunito", sans-serif;
  border: 1px solid #efefef;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
[dir=rtl] .default-according .card .card-header .btn-link {
  text-align: right;
}
@media (max-width: 575px) {
  .default-according .card .card-header .btn-link {
    padding: 12px 15px;
  }
}
.default-according .card .card-body {
  padding: 15px 20px;
  line-height: 22px;
  font-size: 14px;
  border-color: #efefef;
  border-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #2c323f;
}

.nav-tabs {
  border-bottom-color: #efefef;
}
.nav-tabs .nav-bottom .nav-item .nav-link.active {
  border-color: #efefef;
}

span.twitter-typeahead .league-name {
  font-size: 16px;
  padding: 6px 10px 0;
}
span.twitter-typeahead .tt-menu {
  float: left;
  width: 100%;
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
}
span.twitter-typeahead .tt-suggestion {
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  padding: 6px 12px;
  border-top: 1px solid #efefef;
  background: #fff;
  cursor: pointer;
}
span.twitter-typeahead .tt-suggestion:hover, span.twitter-typeahead .tt-suggestion:focus {
  background-color: #fff;
}
span.twitter-typeahead .tt-suggestion.active, span.twitter-typeahead .tt-suggestion:active {
  color: #fff;
  background-color: var(--theme-deafult);
}

.nav-tabs .nav-item.show .nav-link {
  font-weight: 500;
}
.nav-tabs .nav-link {
  color: #2c323f;
}
.nav-tabs .nav-link.active {
  font-weight: 500;
}
.nav-tabs.nav-bottom .nav-item .nav-link.active {
  border-color: rgba(106, 113, 133, 0.3) rgba(106, 113, 133, 0.3) #fff;
}

.dropdown-menu {
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  border: none;
  padding: 0;
  z-index: 5;
}
.dropdown-menu .dropdown-item {
  color: #2b2b2b;
  opacity: 0.6;
  font-size: 13px;
  padding: 6px 12px;
  border-top: 1px solid #efefef;
  background: #fff;
}
.dropdown-menu .dropdown-item:hover {
  background-color: #fff;
}
.dropdown-menu .dropdown-divider {
  margin: 0;
  border-top: 1px solid #cccccc;
}

.collapse-horizontal .card-body {
  width: 350px;
}

.border-tab .nav-tabs .nav-item .nav-link.active {
  border-bottom: 2px solid #337ab7;
}
.border-tab .nav-tabs .nav-item .nav-link.show {
  border-bottom: 2px solid #337ab7;
}
.border-tab .nav-tabs .nav-item .nav-link:focus {
  border-bottom: 2px solid #337ab7;
}

.tabbed-card .nav-tabs .nav-item .nav-link {
  top: 17px;
}

.timeliny .timeliny-dot::before {
  font-size: 18px;
}

.component .input-group .btn {
  line-height: 32px;
  text-transform: capitalize;
}

.login-card .login-main .theme-form label {
  font-size: 14px;
}
.login-card .login-main .theme-form .show-hide {
  top: 50%;
}

.faq-accordion .card .btn-link svg {
  margin-left: 20px;
}
@media (max-width: 575.98px) {
  .faq-accordion .card .btn-link svg {
    margin-left: 10px;
  }
}

.job-filter .faq-form .form-control {
  font-size: 14px;
}
.job-filter .faq-form .search-icon {
  width: 16px;
  height: 16px;
}

.note-btn-group .dropdown-toggle.show ~ .dropdown-menu {
  display: block;
  top: 30px;
}

.carousel .carousel-indicators {
  margin-bottom: 3px;
}

.mouse-wheel .owl-stage-outer .owl-stage {
  width: 5600px !important;
}
.mouse-wheel .owl-stage-outer .owl-stage .owl-item {
  width: 225.333px !important;
}

.carousel-item .carousel-caption {
  width: 100%;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 26px 10px;
  background-color: rgba(42, 54, 80, 0.51);
}
.carousel-item .carousel-opacity {
  background-color: rgba(244, 244, 244, 0.51);
}

.carousel .carousel-control-prev-icon {
  width: calc(23px + 9 * (100vw - 320px) / 1600);
  height: calc(23px + 9 * (100vw - 320px) / 1600);
}
.carousel .carousel-control-next-icon {
  width: calc(23px + 9 * (100vw - 320px) / 1600);
  height: calc(23px + 9 * (100vw - 320px) / 1600);
}

[dir=rtl] .rating-header h4 {
  direction: ltr;
}

.animate-img .animate-widget img {
  width: 100%;
  height: 350px;
  margin: 0 auto;
  -o-object-fit: cover;
     object-fit: cover;
}

.modal-popup.modal {
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}
.modal-popup.modal .modal-dialog .theme-close {
  width: calc(25px + 15 * (100vw - 320px) / 1600);
  height: calc(25px + 15 * (100vw - 320px) / 1600);
  font-size: calc(12px + 2 * (100vw - 320px) / 1600);
}

@media (max-width: 442px) {
  .animated-modal .form-select {
    width: 100% !important;
  }
}

.task-container .badge {
  line-height: 13px;
}

.starter-main .alert-primary.inverse {
  padding: 13px 20px 13px 65px;
  border-color: #337ab7;
  color: #2c323f;
}
[dir=rtl] .starter-main .alert-primary.inverse {
  padding: 13px 65px 13px 20px;
}
.starter-main .alert-primary.inverse:before {
  content: "";
  position: absolute;
  left: 54px;
  width: 0;
  height: 0;
  border-left: 7px solid #337ab7;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}
[dir=rtl] .starter-main .alert-primary.inverse:before {
  border-right: 7px solid #337ab7;
  border-left: unset;
}
.starter-main .alert-primary.inverse i {
  padding: 17px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #337ab7;
  color: #fff;
  border-radius: 3px 0 0 3px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
}
[dir=rtl] .starter-main .alert-primary.inverse i {
  right: 0;
  left: unset;
}

.todo .notification-popup {
  z-index: 5;
}

.search-page .info-block {
  border-color: rgba(89, 102, 122, 0.28) !important;
}

.map-z-index {
  z-index: 1;
}

.apexcharts-canvas .apexcharts-xaxistooltip-bottom {
  display: none;
}

/**=====================
    4.3 Update CSS Ends
==========================**/
/**=====================
    5.1 Footer CSS Start
==========================**/
.footer {
  background-color: #fff;
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
          box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  padding: 15px;
  bottom: 0;
  left: 0;
  margin-left: 252px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.footer.footer-dark {
  background-color: #2A3650;
}
.footer.footer-dark p {
  color: #fff;
}

.footer-icon {
  width: 20px;
  height: 20px;
  vertical-align: sub !important;
}

.footer-fix {
  width: calc(100% - 252px);
  position: fixed;
}

/**=====================
    5.1 Footer CSS Ends
==========================**/
/**=====================
  5.2 Grid CSS Start
==========================**/
.grid-showcase {
  margin-bottom: -10px;
}
.grid-showcase span,
.grid-showcase .grid-wrapper {
  display: block;
  padding: 10px;
  margin-bottom: 10px;
  text-align: center;
  background-color: rgba(242, 243, 247, 0.7);
}

.grid-align {
  margin-bottom: -16px;
}
.grid-align .row {
  padding: 15px 0;
  min-height: 7rem;
  margin-bottom: 16px;
}

.grid-vertical {
  min-height: 150px;
  padding: 10px;
  color: #000;
}

table.table-bordered thead tr th p {
  margin-bottom: 0;
}

/**=====================
    5.2 Grid CSS Ends
==========================**/
/**=====================
  5.3 Header CSS Start
==========================**/
.left-header .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.left-header h6 {
  line-height: 1.6;
}
@media (max-width: 1199px) {
  .left-header h6 {
    width: 160px;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@media (max-width: 991px) {
  .left-header h6 {
    width: 120px;
  }
}
@media (max-width: 767px) {
  .left-header h6 {
    width: 66px;
  }
}
@media (max-width: 755px) {
  .left-header {
    display: none;
  }
}
.left-header img {
  margin-top: -12px;
  height: 26px;
}
.left-header i {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: -4px;
}

/*======= Page Header css ends  ======= */
/**======Main Header css Start ======**/
.offcanvas .page-wrapper .page-body-wrapper .page-body,
.offcanvas-bookmark .page-wrapper .page-body-wrapper .page-body {
  position: relative;
  -webkit-filter: blur(5px) grayscale(100%);
          filter: blur(5px) grayscale(100%);
}

.offcanvas {
  position: unset;
  visibility: visible;
  background: unset;
  z-index: 2;
}

.toggle-sidebar {
  position: absolute;
  right: 25px;
  top: 18px;
  cursor: pointer;
  padding: 7px;
  background-color: #374462;
  border-radius: 6px;
}
.toggle-sidebar svg {
  width: 20px;
  height: 20px;
  stroke: #fff;
  -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
  -webkit-transition: all 0.6s ease-in-out;
  transition: all 0.6s ease-in-out;
}
.toggle-sidebar:hover > svg {
  color: rgba(122, 112, 186, 0.07) !important;
  -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
}

.onhover-dropdown {
  cursor: pointer;
  position: relative;
}
.onhover-show-div {
  top: 80px;
  position: absolute;
  z-index: 8;
  background-color: #fff;
  -webkit-transition: all linear 0.3s;
  transition: all linear 0.3s;
}
.onhover-show-div li a svg {
  margin-top: 0 !important;
}
.onhover-show-div li a svg path,
.onhover-show-div li a svg line {
  color: #3D434A !important;
}

/**======Main Header css Ends ======**/
/*=======Mega menu css start=======*/
.mega-menu .default-according .card .btn-link {
  font-weight: 500;
  color: gray;
}
.mega-menu .default-according .card .card-body {
  font-size: 12px;
}
.mega-menu .onhover-show-div {
  width: 1500px;
}
.mega-menu .card-body {
  padding: 20px;
}
.mega-menu div > div a {
  margin-bottom: 0px;
  display: inline-block;
  color: #9B9B9B;
}
.mega-menu .list-unstyled div a {
  padding: 8px 35px 8px 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.mega-menu .list-unstyled div a:hover {
  padding: 8px 35px 8px 10px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

/*=======Mega menu css end=======*/
/**=====================
    5.3 Header CSS Ends
==========================**/
/**=====================
    5.4 Navs CSS Start
==========================**/
#accordionoc #collapseicon,
#accordionoc #collapseicon1 {
  padding-top: 0px;
}
#accordionoc li button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navs-icon.default-according.style-1 li button {
  position: relative;
}
.navs-icon.default-according.style-1 li button:hover {
  color: var(--theme-deafult) !important;
}
.navs-icon.default-according.style-1 li button[aria-expanded=true]:before {
  right: 2px;
  top: 6px;
}
.navs-icon.default-according.style-1 li button[aria-expanded=false]:before {
  right: 2px;
  top: 6px;
}

.navs-icon {
  padding: 30px;
}
.navs-icon li {
  padding: 9px 0;
}
.navs-icon li a {
  color: #3D434A;
}
.navs-icon li a:hover {
  color: var(--theme-deafult);
}
.navs-icon li ul {
  padding-top: 9px;
}

.onhover-dropdown.navs-dropdown:hover .onhover-show-div:before, .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  right: 77px;
  left: unset;
}

.icon-lists {
  font-family: "Nunito", sans-serif, sans-serif;
}

.pl-navs-inline {
  padding-left: 30px !important;
}

.inline-nav li {
  line-height: 1.3;
}

/**=====================
    5.4 Navs CSS Ends
==========================**/
/**=====================
   5.5 Search CSS Start
==========================**/
.search-page .theme-form input {
  border: 1px solid var(--theme-deafult);
  padding-left: 20px;
  border-radius: 10px 0 0 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 100% !important;
}
.search-page .theme-form input::-webkit-input-placeholder {
  color: #337ab7;
}
.search-page .theme-form input::-moz-placeholder {
  color: #337ab7;
}
.search-page .theme-form input:-ms-input-placeholder {
  color: #337ab7;
}
.search-page .theme-form input::-ms-input-placeholder {
  color: #337ab7;
}
.search-page .theme-form input::placeholder {
  color: #337ab7;
}
.search-page .theme-form input:focus {
  outline: none;
}
.search-page .theme-form .input-group .btn {
  line-height: 32px;
  font-size: 16px;
}
.search-page .theme-form .input-group .form-control-plaintext {
  background: transparent;
}
.search-page h6 {
  line-height: 1.4;
}
.search-page .nav-link {
  font-weight: 500;
  text-transform: uppercase;
}
.search-page .border-tab.nav-tabs .material-border {
  border-bottom-width: 1px;
}
.search-page .border-tab.nav-tabs .nav-item .nav-link {
  font-size: 15px;
  padding: 10px 18px;
}
.search-page .d-flex .flex-grow-1 {
  overflow: hidden;
}
.search-page .info-block {
  padding: 30px;
  border-radius: 15px;
  border: 1px solid #f4f4f4;
}
.search-page .info-block a {
  color: #656565;
  margin-bottom: 3px;
  display: block;
}
.search-page .info-block h5 {
  color: #337ab7;
}
.search-page .info-block + .info-block {
  margin-top: 20px;
}
.search-page .border-tab.nav-tabs {
  -webkit-box-align: left;
      -ms-flex-align: left;
          align-items: left;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}
.search-page .border-tab.nav-tabs .nav-item {
  width: auto;
}
.search-page .search-links h5 {
  margin-bottom: 10px;
  font-weight: 600;
}
.search-page p {
  text-transform: lowercase;
  margin-bottom: 0;
  color: #a5a2a2;
}
.search-page ul.search-info li {
  display: inline-block;
  font-size: 12px;
  line-height: 1;
  color: #586082;
}
.search-page ul.search-info li + li {
  border-left: 1px solid #a8b0c2;
  padding-left: 8px;
  margin-left: 8px;
}
.search-page ul.search-info li i {
  color: #D77748;
}
.search-page ul.search-info li i:not(:last-child) {
  margin-right: 4px;
}
.search-page #video-links .embed-responsive + .embed-responsive {
  margin-top: 30px;
}

.search-list {
  margin-bottom: 30px;
  width: auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  border-radius: 10px;
  overflow: hidden;
  border: 1px solid #337ab7;
  border: none;
}
.search-list .nav-item {
  background-color: rgba(122, 112, 186, 0.1);
}
.search-list .nav-item .nav-link {
  border: none;
  padding: 0.7rem 1.5rem;
  opacity: 0.5;
}
.search-list .nav-item .nav-link.active {
  background-color: rgba(122, 112, 186, 0.1);
  color: var(--theme-deafult);
  opacity: 1;
}
.search-list .nav-item.show, .search-list .nav-item.active {
  background-color: rgba(122, 112, 186, 0.1);
}

/**=====================
  5.5 Search CSS Ends
==========================**/
/**=====================
    5.6 Select2 CSS Start
==========================**/
.dropzone {
  background: unset;
}
.dropzone.dropzone-secondary {
  border: 2px solid #48A3D7;
}
.dropzone.dropzone-secondary i {
  color: #48A3D7;
}
@media (max-width: 360px) {
  .dropzone .note {
    min-width: unset !important;
  }
}
.dropzone .dz-preview {
  margin: 28px 18px !important;
  -webkit-box-shadow: unset;
          box-shadow: unset;
}
.dropzone .dz-preview .dz-details {
  top: unset;
  bottom: -6px;
  position: relative;
  padding-bottom: 6px;
  background-color: unset;
}
[dir=rtl] .dropzone .dz-preview .dz-details {
  left: unset;
  right: 32px;
}
.dropzone .dz-preview:hover .dz-error-message {
  display: none !important;
}
.dropzone .dz-error-mark .dz-remove {
  position: relative;
}

.dz-preview .dz-remove {
  cursor: pointer;
}

.dropzone-secondary .dz-preview .dz-remove {
  color: #48A3D7;
}

.select2 {
  max-width: 100%;
}

.add-post #cke_text-box {
  border: 1px solid #efefef;
}
.add-post form .m-checkbox-inline label {
  margin-bottom: 0;
}
.add-post form .form-control {
  border: 1px solid #efefef;
}
.add-post form .col-form-label {
  padding-bottom: 0;
  padding-top: 0;
}
.add-post form .col-form-label .select2-container--default {
  margin-top: 10px;
  max-width: 100%;
}
.add-post form .col-form-label .select2-container--default .selection .select2-selection .select2-search__field {
  padding: 2px;
}
.add-post form .col-form-label .select2-container--default .select2-selection--multiple {
  border: 1px solid #efefef;
  max-width: 100%;
}
.add-post form .col-form-label .select2-container--default .select2-selection--multiple .select2-selection__rendered li {
  margin-top: 6px !important;
}
.add-post .select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: var(--theme-deafult);
  border: 1px solid var(--theme-deafult);
  color: var(--white);
}
.add-post .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  color: var(--white);
  float: right;
  margin-left: 0.5rem;
}
[dir=rtl] .add-post .select2-container--default .select2-selection--multiple .select2-selection__choice .select2-selection__choice__remove {
  float: left;
  margin-left: unset;
  margin-right: 0.5rem;
}
.add-post .dropzone {
  margin-bottom: 30px;
}

.select2-container--open .select2-dropdown {
  z-index: 7;
}

.select2-container {
  width: 100% !important;
}
.select2-container.select2 .selection {
  display: block !important;
}

.select2-drpdwn .select2-selection {
  border-radius: 0 !important;
  border-color: #f4f4f4 !important;
  height: 40px !important;
  padding: 5px;
}
.select2-drpdwn .form-control {
  border-radius: 5px;
}
.select2-drpdwn .form-control-primary {
  border-color: var(--theme-deafult);
  color: var(--theme-deafult);
}
.select2-drpdwn .form-control-secondary {
  border-color: var(--theme-secondary);
  color: var(--theme-secondary);
}
.select2-drpdwn .form-control-success {
  border-color: #0DA759;
  color: #0DA759;
}
.select2-drpdwn .form-control-info {
  border-color: #16C7F9;
  color: #16C7F9;
}
.select2-drpdwn .form-control-warning {
  border-color: #D77748;
  color: #D77748;
}
.select2-drpdwn .form-control-danger {
  border-color: #E44141;
  color: #E44141;
}
.select2-drpdwn .form-control-inverse {
  border-color: #2c323f;
  color: #2c323f;
}
.select2-drpdwn .form-control-primary-fill {
  background-color: var(--theme-deafult);
  color: #fff;
}
.select2-drpdwn .form-control-secondary-fill {
  background-color: var(--theme-secondary);
  color: #fff;
}
.select2-drpdwn .form-control-success-fill {
  background-color: #0DA759;
  color: #fff;
}
.select2-drpdwn .form-control-info-fill {
  background-color: #16C7F9;
  color: #fff;
}
.select2-drpdwn .form-control-warning-fill {
  background-color: #D77748;
  color: #fff;
}
.select2-drpdwn .form-control-danger-fill {
  background-color: #E44141;
  color: #fff;
}
.select2-drpdwn .form-control-inverse-fill {
  background-color: #2c323f;
  color: #fff;
}

.filepond--drop-label .filepond--label-action {
  color: #E44141;
  text-decoration: none;
}

.filepond--list .filepond--file {
  background-color: #337ab7;
}

/* Do not show the "remove tag" (x) button when only a single tag remains */
.customLook {
  display: inline-block;
  min-width: 0;
  border: none;
}
.customLook .tagify__tag {
  margin-top: 0;
}
.customLook .tagify__tag:only-of-type .tagify__tag__removeBtn {
  display: none;
}
.customLook .tagify__tag:hover .tagify__tag__removeBtn {
  -webkit-transform: none;
          transform: none;
  opacity: 1;
  margin-left: -1ch;
}
.customLook .tagify__tag__removeBtn {
  opacity: 0;
  -webkit-transform: translateX(-6px) scale(0.5);
          transform: translateX(-6px) scale(0.5);
  margin-left: -3ch;
  -webkit-transition: 0.12s;
  transition: 0.12s;
}
.customLook + button {
  color: var(--theme-deafult);
  font: bold 1.4em/1.65 Arial;
  border: 0;
  background: none;
  -webkit-box-shadow: 0 0 0 2px inset var(--theme-deafult);
          box-shadow: 0 0 0 2px inset var(--theme-deafult);
  border-radius: 50%;
  width: 1.65em;
  height: 1.65em;
  cursor: pointer;
  outline: none;
  -webkit-transition: 0.1s ease-out;
  transition: 0.1s ease-out;
  margin: 0 0 0 5px;
  vertical-align: top;
}
.customLook + button:hover {
  -webkit-box-shadow: 0 0 0 5px inset var(--theme-deafult);
          box-shadow: 0 0 0 5px inset var(--theme-deafult);
}
.customLook .tagify__input {
  display: none;
}

.tagify--empty .tagify__input::before {
  color: var(--chart-text-color);
}

.customSuggestionsList > div {
  max-height: 300px;
  min-height: 50px;
  border: 2px solid var(--chart-solid-border);
  overflow: auto;
}
.customSuggestionsList .empty {
  color: var(--theme-deafult);
  font-size: 20px;
  text-align: center;
  padding: 1em;
}

.tagify__dropdown.extra-properties .tagify__dropdown__item > img {
  display: inline-block;
  vertical-align: middle;
  height: 20px;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  margin-right: 5px;
  border-radius: 2px;
  -webkit-transition: 0.12s ease-out;
  transition: 0.12s ease-out;
}
.tagify__dropdown.extra-properties .tagify__dropdown__item:hover > img {
  -webkit-transform: none;
          transform: none;
  margin-right: 12px;
}
.tagify__dropdown.extra-properties .tagify__dropdown__item--active > img {
  -webkit-transform: none;
          transform: none;
  margin-right: 12px;
}

.tagify.countries .tagify__input {
  min-width: 175px;
}
.tagify.countries tag {
  white-space: nowrap;
}
.tagify.countries tag img {
  display: inline-block;
  height: 16px;
  margin-right: 3px;
  border-radius: 2px;
  pointer-events: none;
}

.tagify--select {
  width: 100%;
}

.tagify__dropdown__wrapper {
  background: var(--white);
  border-color: var(--recent-solid-border);
}

.tagify__dropdown__item--active {
  background-color: rgba(106, 113, 133, 0.3);
  color: var(--body-font-color);
}

.tagify {
  --tags-focus-border-color: #cccccc;
}

.tagify__input {
  color: #2c323f;
}

.tagify--select::after {
  color: var(--chart-text-color);
}
[dir=rtl] .tagify--select::after {
  right: unset;
  left: 0;
  -webkit-transform: translate(150%, -50%) scaleX(1.2) rotate(90deg);
          transform: translate(150%, -50%) scaleX(1.2) rotate(90deg);
}
[dir=rtl] .tagify--select .tagify__tag {
  right: unset;
  left: 1.8em;
}

[dir=rtl] .tagify--select[aria-expanded=true]::after {
  -webkit-transform: translate(150%, -50%) rotate(270deg) scale(1.2);
          transform: translate(150%, -50%) rotate(270deg) scale(1.2);
}

.international-num input {
  width: calc(240px + 10 * (100vw - 320px) / 1600);
  padding: 10px;
  border-radius: 2px;
  border: 1px solid rgba(106, 113, 133, 0.3);
}
.international-num input:focus {
  outline-color: rgba(106, 113, 133, 0.3);
}
.international-num input::-webkit-input-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.international-num input::-moz-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.international-num input:-ms-input-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.international-num input::-ms-input-placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.international-num input::placeholder {
  color: rgba(106, 113, 133, 0.3);
}
.international-num .results ul {
  border: 1px solid rgba(106, 113, 133, 0.3);
  margin-top: 12px;
  padding: 8px;
  line-height: 1.9;
}

.iti--allow-dropdown .iti__flag {
  background-image: url("../../images/flags.png");
}

.select-box {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 400px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.select-box .options-container {
  background: var(--recent-box-bg);
  color: var(--body-font-color);
  max-height: 0;
  width: 100%;
  opacity: 0;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}
.select-box .options-container::-webkit-scrollbar {
  width: 8px;
  background: var(--view-grid-bg);
  border-radius: 0 8px 8px 0;
}
.select-box .options-container::-webkit-scrollbar-thumb {
  background: var(--view-grid-bg);
  border-radius: 0 8px 8px 0;
}
.select-box .options-container.active {
  max-height: 240px;
  opacity: 1;
  overflow-y: scroll;
  margin-top: 53px;
}
@media (min-width: 1007px) and (max-width: 1920px) {
  .select-box .options-container.active {
    max-width: calc(265px + 135 * (100vw - 1007px) / 913);
  }
}
@media (min-width: 768px) and (max-width: 1006px) {
  .select-box .options-container.active {
    max-width: calc(295px + 55 * (100vw - 1007px) / 238);
  }
}
@media (max-width: 767px) {
  .select-box .options-container.active {
    max-width: calc(242px + 173 * (100vw - 320px) / 447);
    margin-top: 46px;
  }
}
.select-box .options-container.active + .selected-box::after {
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  top: -6px;
}
.select-box .options-container.active ~ .search-box input {
  opacity: 1;
  pointer-events: auto;
}
.select-box .selection-option {
  padding: 12px 24px;
  cursor: pointer;
}
.select-box .selection-option:hover {
  background: rgba(106, 113, 133, 0.3);
}
.select-box .selection-option .radio {
  display: none;
}
.select-box label {
  cursor: pointer;
}

.selected-box {
  background: var(--light2);
  color: rgba(0, 0, 0, 0.3);
  position: relative;
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0;
  padding: 12px 24px;
  cursor: pointer;
}
@media (min-width: 1007px) and (max-width: 1920px) {
  .selected-box {
    max-width: calc(265px + 135 * (100vw - 1007px) / 913);
  }
}
@media (min-width: 768px) and (max-width: 1006px) {
  .selected-box {
    max-width: calc(295px + 55 * (100vw - 1007px) / 238);
  }
}
@media (max-width: 767px) {
  .selected-box {
    max-width: calc(242px + 173 * (100vw - 320px) / 447);
  }
}
.selected-box::after {
  content: "\f078";
  font-family: "FontAwesome";
  position: absolute;
  height: 100%;
  width: 32px;
  right: 10px;
  top: 12px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
[dir=rtl] .selected-box::after {
  left: 10px;
  right: unset;
}

.search-box input {
  width: 100%;
  padding: 12px 16px;
  font-size: 16px;
  position: absolute;
  z-index: 1;
  border: 2px solid var(--chart-solid-border);
  opacity: 0;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  border-radius: 6px;
}
@media (min-width: 1007px) and (max-width: 1920px) {
  .search-box input {
    max-width: calc(265px + 135 * (100vw - 1007px) / 913);
  }
}
@media (min-width: 768px) and (max-width: 1006px) {
  .search-box input {
    max-width: calc(295px + 55 * (100vw - 1007px) / 238);
  }
}
@media (max-width: 767px) {
  .search-box input {
    max-width: calc(242px + 173 * (100vw - 320px) / 447);
    padding: 9px 16px;
  }
}
.search-box input:focus {
  outline: none;
}

/**=====================
    5.6 Select2 CSS Ends
==========================**/
/**=====================
  5.7 Sidebar CSS Start
==========================**/
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5:after, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  position: absolute;
  content: "";
  top: 48%;
  width: 16px;
  border-top: 1px solid rgba(203, 204, 206, 0.57);
}

.d-flex.media-wrapper {
  width: 100%;
  display: -webkit-flex;
}
.d-flex.media-wrapper .flex-grow-1 {
  -webkit-box-flex: unset;
      -ms-flex: unset;
          flex: unset;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a::before {
  position: absolute;
  content: "";
  width: 5px;
  height: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 43px;
  border-radius: 100%;
  background: rgba(203, 204, 206, 0.57);
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title [dir=rtl] h5::before, [dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li [dir=rtl] a::before {
  left: unset;
  right: 44px;
}

.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 0;
  background: rgba(203, 204, 206, 0.57);
  top: 6px;
  left: 27px;
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li [dir=rtl] .mega-menu-container::before, [dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu::before, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li [dir=rtl] .sidebar-submenu::before {
  left: unset;
  right: 27px;
}

.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  position: absolute;
  content: "";
  top: 49%;
  width: 5px;
  height: 1px;
  border-radius: 100%;
  background-color: #59667a;
}

.onhover-show-div {
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
          box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  -webkit-transform: translateY(30px);
          transform: translateY(30px);
  opacity: 0;
  visibility: hidden;
  left: 0;
}

.onhover-dropdown:hover .onhover-show-div {
  opacity: 1;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  visibility: visible;
  border-radius: 5px;
  overflow: hidden;
}
.onhover-dropdown:hover .onhover-show-div:before {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #fff;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 2;
}
.onhover-dropdown:hover .onhover-show-div:after {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #d7e2e9;
  content: "";
  top: -7px;
  position: absolute;
  left: 10px;
  z-index: 1;
}

.page-wrapper {
  position: relative;
}
.page-wrapper .page-header {
  max-width: 100vw;
  position: fixed;
  top: 0;
  z-index: 8;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: #fff;
  -webkit-box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
          box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
  padding: 15px 30px;
}
@media (max-width: 1428px) {
  .page-wrapper .page-header {
    padding: 15px 4px;
  }
}
@media (max-width: 1199px) {
  .page-wrapper .page-header {
    padding: 15px;
  }
}
.page-wrapper .page-header .breadcrumb .breadcrumb-item {
  color: rgba(61, 67, 74, 0.9);
  font-weight: 500;
}
.page-wrapper .page-header .breadcrumb .breadcrumb-item:before {
  color: rgba(61, 67, 74, 0.9);
}
.page-wrapper .page-header .breadcrumb .breadcrumb-item a svg {
  width: 16px;
  height: 16px;
  stroke: rgba(61, 67, 74, 0.9);
  vertical-align: text-top;
}
.page-wrapper .page-header .breadcrumb .breadcrumb-item.active {
  color: var(--theme-deafult);
  font-weight: 500;
}
.page-wrapper .page-header .header-wrapper {
  width: 100%;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
}
.page-wrapper .page-header .header-wrapper .search-full {
  background-color: #fff;
  position: absolute;
  right: 0;
  z-index: 1;
  height: 0;
  width: 0;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .search-full.open {
  height: 100%;
  width: 100%;
  -webkit-animation: zoomIn 0.5s ease-in-out;
          animation: zoomIn 0.5s ease-in-out;
  -webkit-transform: scale(1);
          transform: scale(1);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .search-full input {
  line-height: 30px;
  padding-left: 60px;
  width: 100% !important;
  background-color: #fff;
  -webkit-box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
          box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
}
.page-wrapper .page-header .header-wrapper .search-full input:focus {
  outline: none !important;
}
.page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
  position: absolute;
  font-size: 15px;
  right: 30px;
  top: 11px;
  color: #898989;
  cursor: pointer;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
  left: 30px;
  right: unset;
}
.page-wrapper .page-header .header-wrapper .search-full .form-group:before {
  position: absolute;
  left: 30px;
  top: 11px;
  z-index: 2;
  content: "\f002";
  font-size: 16px;
  color: #898989;
  font-family: FontAwesome;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group:before {
  left: unset;
  right: 30px;
}
.page-wrapper .page-header .header-wrapper .nav-right {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li svg {
  vertical-align: middle;
  width: 20px;
  height: 18px;
  stroke: rgba(61, 67, 74, 0.9);
  fill: none;
  vertical-align: sub;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li svg path {
  color: #9B9B9B;
}
.page-wrapper .page-header .header-wrapper .nav-right ul li .mode.active svg {
  fill: #fff;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode {
  cursor: pointer;
  text-align: center;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode svg {
  width: 18px !important;
  height: 18px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode i {
  font-size: 18px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .mode i.fa-lightbulb-o {
  font-weight: 500;
  font-size: 22px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-animation: zoomIn 300ms ease-in-out;
          animation: zoomIn 300ms ease-in-out;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .header-search {
  cursor: pointer;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 {
  margin-left: 15px;
  min-width: 84px;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 span {
  font-weight: 500;
}
.page-wrapper .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 p {
  font-size: 12px;
  line-height: 1;
  color: rgba(61, 67, 74, 0.7);
}
.page-wrapper .page-header .header-wrapper .nav-right > ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li {
  padding: 10px 12px;
  display: inline-block;
  position: relative;
  background-color: #ddd;
  border-radius: 50px;
  margin-right: 10px;
}
@media (max-width: 1366px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    margin-right: 8px;
  }
  [dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    margin-left: 8px !important;
    margin-right: unset;
  }
}
@media (max-width: 360px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    margin-right: 2px;
  }
  [dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    margin-left: 2px !important;
    margin-right: unset;
  }
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li .form-group svg {
  margin-right: 8px;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li .form-group svg {
  margin-left: 8px;
  margin-right: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li .form-group input:focus {
  outline: none;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
  margin-left: 15px;
  margin-right: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li:last-child {
  background-color: transparent;
  border-radius: 0px;
  margin-right: 0px;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul > li:last-child {
  margin-left: 15px;
  margin-right: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li:first-child {
  display: none;
}
@media (max-width: 1199px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li:first-child {
    display: block !important;
  }
}
@media (max-width: 1199px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li:nth-child(2) {
    display: none !important;
  }
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li .badge {
  position: absolute;
  right: -5px;
  top: -6px;
  padding: 2px 4px;
  font-size: 11px;
  font-weight: 700;
  font-family: work-Sans, sans-serif;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul {
  left: inherit;
  right: -10px;
  width: 130px;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:before, .page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul:after {
  left: inherit;
  right: 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li {
  display: block;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a {
  font-size: 14px;
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul > li span ul li a i {
  margin-left: 10px;
  font-size: 13px;
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right > ul .flag-icon {
  font-size: 16px;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip {
  width: unset !important;
  -webkit-box-shadow: unset !important;
          box-shadow: unset !important;
  background-color: transparent !important;
  overflow: initial;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip::after, .page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip::before {
  display: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content {
  text-align: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content .bookmark-icon {
  width: 52px;
  height: 52px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
}
.page-wrapper .page-header .header-wrapper .nav-right .bookmark-flip .bookmark-dropdown .bookmark-content span {
  font-family: "Nunito", sans-serif, sans-serif;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  margin-top: 6px;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card {
  width: 300px;
  height: 260px;
  background-color: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner {
  position: relative;
  height: 100%;
  text-align: center;
  -webkit-transition: -webkit-transform 0.6s;
  transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front,
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
  position: absolute;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
          box-shadow: 0 0 20px rgba(89, 102, 122, 0.1);
  width: 300px;
  height: 260px;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front button,
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button {
  background-color: transparent;
  color: #337ab7;
  border: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front button:focus,
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button:focus {
  outline: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front {
  background-color: #fff;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg {
  stroke: #2c323f;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .front svg:hover {
  stroke: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back {
  background-color: #fff;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back li:first-child {
  margin-top: 20px;
  height: 140px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back button {
  width: 100%;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input {
  border-radius: 25px;
  padding: 6px 12px;
  width: 100%;
  border: 1px solid #ddd;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner .back .flip-back-content input:focus {
  outline: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner.flipped {
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg);
}
.page-wrapper .page-header .header-wrapper .nav-right .flip-card .flip-card-inner.flipped .front {
  opacity: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
  width: 330px;
  top: 50px;
  right: 0;
  left: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div .dropdown-title {
  padding: 14px 20px;
  text-align: center;
  border-bottom: 1px solid rgba(145, 142, 153, 0.3);
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div ul {
  padding: 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li {
  padding: 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li p {
  font-size: 13px;
  letter-spacing: 0.3px;
  margin-bottom: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li a {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  font-family: "Nunito", sans-serif, sans-serif;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li + li {
  margin-top: 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li:last-child {
  padding-bottom: 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex img {
  width: 40px;
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex .status-circle {
  left: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex .flex-grow-1 > span {
  letter-spacing: 0.8px;
  padding-right: 10px;
  display: inline-block;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex .flex-grow-1 h6 {
  margin: 8px 8px 0px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div li .d-flex .flex-grow-1 p {
  margin-bottom: 8px;
}
.page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:before, .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div:after {
  right: 10px !important;
  left: unset !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-box {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-box {
  position: relative;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown ul {
  padding: 0 0 15px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li {
  margin: 0 15px;
  padding: 10px 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex .flex-grow-1 {
  margin-right: 20px;
}
[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li .d-flex .flex-grow-1 {
  margin-right: unset;
  margin-left: 20px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li + li {
  margin-top: 0;
  border-top: 1px solid rgba(145, 142, 153, 0.1);
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown li:last-child {
  padding: 20px 15px;
  border-top: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown p {
  line-height: 1.3;
  margin-top: 3px;
  opacity: 0.6;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle {
  position: absolute;
  right: 0;
  top: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle a {
  width: 18px;
  height: 18px;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .close-circle svg {
  width: 14px;
  stroke: #fff;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box {
  margin: 5px 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group {
  width: 80px;
  background-color: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group input {
  background-color: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group input,
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group .btn {
  font-size: 10px;
  font-family: "Nunito", sans-serif, sans-serif;
  font-weight: 700;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .qty-box .input-group .btn {
  padding: 0px 7px;
  background-color: #F7F6FF !important;
  border-color: #F7F6FF !important;
  border-radius: 100%;
  font-size: 14px;
  color: #3D434A;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total {
  margin: 0;
  padding: 15px;
  background-color: #f9f9f9;
  border: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total h6 {
  font-family: "Nunito", sans-serif, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .total h6 span {
  font-size: 14px;
}
.page-wrapper .page-header .header-wrapper .nav-right .cart-dropdown .view-checkout {
  text-decoration: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
  top: 52px;
  width: 300px;
  right: -20px !important;
  left: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .notification-box .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 6px;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .notification-box .d-flex .flex-shrink-0 {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .notification-box .d-flex .flex-grow-1 a {
  text-decoration: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .notification-box .d-flex .flex-grow-1 a h6 {
  color: #3D434A;
  font-weight: 600;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .notification-box .d-flex .flex-grow-1 p {
  font-size: 14px;
  color: #9B9B9B;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .messages .d-flex {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .messages .d-flex .flex-shrink-0 img {
  border-radius: 50px;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .messages .d-flex .flex-grow-1 a {
  text-decoration: none;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .messages .d-flex .flex-grow-1 a h6 {
  text-transform: capitalize;
  padding-bottom: 6px;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .messages .d-flex .flex-grow-1 p {
  color: #3D434A;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown .messages .d-flex span {
  color: #9B9B9B;
  font-size: 13px;
  font-weight: 600;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li {
  background-color: #f9f9f9;
  border-radius: 5px;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown li:last-child {
  text-align: center;
  background-color: transparent;
}
.page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown:before, .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown:after {
  right: 28px !important;
  left: unset !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown {
  width: 160px;
  top: 57px;
  left: -12px;
  padding: 0 10px;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li {
  padding: 10px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li a {
  text-decoration: unset;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a span {
  color: var(--theme-deafult);
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a svg {
  stroke: var(--theme-deafult);
  -webkit-transition: stroke 0.3s ease;
  transition: stroke 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:hover a svg path {
  stroke: var(--theme-deafult);
  -webkit-transition: stroke 0.3s ease;
  transition: stroke 0.3s ease;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li + li {
  margin-top: 0;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li:last-child {
  padding-top: 10px !important;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li span {
  color: #2c323f;
}
.page-wrapper .page-header .header-wrapper .nav-right .profile-dropdown li svg {
  width: 16px;
  vertical-align: bottom;
  margin-right: 10px;
  stroke: #2c323f;
}
.page-wrapper .page-body-wrapper {
  background-color: #ddd;
}
.page-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 136px);
  margin-top: 136px;
  padding: 0 15px 0 15px;
  position: relative;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.page-wrapper .page-body-wrapper .page-body:before {
  -webkit-animation: fadeIn 0.5s ease-in-out;
          animation: fadeIn 0.5s ease-in-out;
}
.page-wrapper .page-body-wrapper .page-title {
  padding: 15px 30px;
  margin: 0 -27px 28px;
  background-color: #fff;
}
.page-wrapper .page-body-wrapper .page-title > .row {
  margin: 0;
}
.page-wrapper .page-body-wrapper .page-title > .row .col-6 {
  padding: 0;
}
.page-wrapper .page-body-wrapper .page-title .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-wrapper .page-body-wrapper .page-title .row h3 {
  font-size: 24px;
  margin-bottom: 0;
  text-transform: capitalize;
}
.page-wrapper .page-body-wrapper .page-title .row h3 small {
  display: block;
  font-size: 12px;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: #9B9B9B;
}
.page-wrapper .page-body-wrapper .page-title nav .breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.page-wrapper .page-body-wrapper .page-title nav .breadcrumb li {
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Nunito", sans-serif, sans-serif;
}
.page-wrapper .page-body-wrapper .page-title nav .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
  content: "/";
}
.page-wrapper .page-body-wrapper .page-title nav .breadcrumb .breadcrumb-item a svg {
  width: 16px;
  height: 16px;
  stroke: #2c323f;
  vertical-align: text-top;
}
.page-wrapper .page-body-wrapper .page-title nav .breadcrumb .breadcrumb-item.active {
  color: var(--theme-deafult);
  font-weight: 500;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon {
  stroke: #fff;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon {
  fill: #757589;
  stroke: none !important;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.stroke-icon {
  stroke: #fff;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.fill-icon {
  fill: #fff;
}
.page-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content li a {
  text-transform: capitalize;
}
.page-wrapper .sidebar-wrapper[data-layout=stroke-svg] .stroke-icon {
  display: inline-block;
}
.page-wrapper .sidebar-wrapper[data-layout=stroke-svg] .fill-icon {
  display: none;
}
.page-wrapper .sidebar-wrapper[data-layout=fill-svg] .stroke-icon {
  display: none;
}
.page-wrapper .sidebar-wrapper[data-layout=fill-svg] .fill-icon {
  display: inline-block;
  fill: #fff;
}
.page-wrapper.horizontal-wrapper [data-simplebar] {
  position: relative;
}
.page-wrapper.horizontal-wrapper .footer {
  margin-left: 0;
}
.page-wrapper.horizontal-wrapper .header-logo-wrapper {
  width: 150px;
}
.page-wrapper.horizontal-wrapper .header-logo-wrapper .toggle-sidebar {
  display: none;
}
.page-wrapper.horizontal-wrapper .header-wrapper .header-logo-wrapper {
  display: none;
}
.page-wrapper.horizontal-wrapper .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 19px;
  right: 0px;
  color: #337ab7;
}
[dir=rtl] .page-wrapper.horizontal-wrapper .sidebar-list i.fa-thumb-tack {
  left: 0;
  right: unset;
}
.page-wrapper.horizontal-wrapper .sidebar-list:hover i.fa-thumb-tack {
  visibility: hidden;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
  height: 54px;
  line-height: inherit;
  top: 75px;
  background: #fff;
  -webkit-box-shadow: 0px 9px 24px rgba(89, 102, 122, 0.05);
          box-shadow: 0px 9px 24px rgba(89, 102, 122, 0.05);
  overflow-x: hidden;
  z-index: 1;
  overflow-y: visible;
  position: fixed;
  width: 100vw;
  border-top: 1px solid rgba(106, 113, 133, 0.3);
  z-index: 3;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .simplebar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper li {
  display: inline-block;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .badge {
  position: absolute;
  top: 2px;
  right: 0px;
  z-index: 1;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .logo-icon-wrapper {
  display: none;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main {
  text-align: left;
  position: relative;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
  position: absolute;
  top: 0px;
  padding: 15px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  z-index: 1;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow.disabled,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow.disabled {
  display: none;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .left-arrow {
  left: 0;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .right-arrow {
  right: 0;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: normal;
  direction: ltr;
  text-align: left;
  display: -webkit-box !important;
  padding: 0 5px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  white-space: nowrap;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list a.active .according-menu i:before {
  content: "-";
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list a .according-menu {
  display: none;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-deafult);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links li.sidebar-list ul.sidebar-submenu li a.active:after {
  background-color: var(--theme-deafult);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title.show {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover > a > span {
  color: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover a {
  background-color: rgba(122, 112, 186, 0.0015);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover a svg.stroke-icon {
  stroke: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover a svg.fill-icon {
  fill: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:first-child {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
  display: block;
  color: #3D434A;
  line-height: 23px;
  text-decoration: none;
  text-transform: capitalize;
  padding: 5px 15px;
  margin: 0 3px;
  letter-spacing: 0em;
  position: relative;
  border-radius: 5px;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active {
  background-color: #c0bcdf;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active svg,
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a.active span {
  color: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a .badge {
  position: absolute;
  top: -5px;
  margin-left: 1px;
  right: 10px;
  padding: 0.2em 0.7em;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a svg {
  width: 18px;
  height: 18px;
  vertical-align: sub;
  stroke: #3D434A !important;
  margin-right: 10px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
  position: fixed;
  z-index: 4;
  -webkit-box-shadow: 0 0 1px 0 #898989;
          box-shadow: 0 0 1px 0 #898989;
  padding: 15px 0px 15px 0px;
  background: white;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  background: transparent;
  padding: 4px 30px;
  display: block;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  line-height: 1.9;
  color: rgba(43, 43, 43, 0.6);
  letter-spacing: 0em;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  left: 20px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
  color: var(--theme-deafult);
  letter-spacing: 1px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover:after {
  background-color: var(--theme-deafult);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 4;
  -webkit-box-shadow: 0 0 1px 0 #898989;
          box-shadow: 0 0 1px 0 #898989;
  padding: 30px;
  background: white;
  width: 95%;
  border-radius: 4px;
  display: block !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box {
  width: 20%;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  text-transform: uppercase;
  font-size: 14px;
  margin-left: -13px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content {
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: transparent;
  position: relative;
  display: block;
  padding: 0;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li {
  border: 0;
  float: none;
  position: relative;
  display: block;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a {
  border: 0 !important;
  background: transparent;
  color: #3D434A;
  border-radius: 0 !important;
  text-transform: capitalize;
  padding: 3px 0;
  font-size: 13px;
  display: block;
  letter-spacing: 0.07em;
  line-height: 1.9;
  position: relative;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:hover {
  color: var(--theme-deafult);
  letter-spacing: 1.5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:hover:after {
  color: var(--theme-deafult);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content li a:after {
  position: absolute;
  content: "";
  left: -12px;
  top: 50%;
  width: 5px;
  height: 1px;
  background-color: #59667a;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover > a:after {
  left: 0;
  right: 0;
  opacity: 1;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.mega-menu {
  position: unset;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper.overlay-white {
  background-color: rgba(255, 255, 255, 0.08);
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav i {
  font-size: 24px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  margin-top: 165px;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main-title {
  display: none !important;
}
.page-wrapper.horizontal-wrapper .page-body-wrapper.scorlled .sidebar-wrapper {
  display: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.horizontal-wrapper .page-header {
  width: 100%;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  left: 12px;
}
.page-wrapper.horizontal-wrapper .page-header .header-wrapper .form-control-plaintext {
  width: auto !important;
}
.page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
  width: 30%;
}
@media (max-width: 1428px) and (min-width: 1324px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 32%;
  }
}
@media (max-width: 1323px) and (min-width: 1200px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 37%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 32%;
  }
}
@media (max-width: 1428px) and (min-width: 1324px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right {
    width: 55%;
  }
}
@media (max-width: 1323px) and (min-width: 1200px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right {
    width: 40%;
  }
}
@media (max-width: 1199px) and (min-width: 992px) {
  .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right {
    width: 50%;
  }
}
.page-wrapper.horizontal-wrapper.enterprice-type footer {
  margin-left: 0;
}
.page-wrapper.material-icon .card {
  border-radius: 30px;
  -webkit-box-shadow: 0 0 20px rgba(122, 112, 186, 0.1);
          box-shadow: 0 0 20px rgba(122, 112, 186, 0.1);
}
.page-wrapper .sidebar-main-title h6 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 0;
  letter-spacing: 0px;
}
.page-wrapper .dark-sidebar .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a svg {
  stroke: #fff !important;
}
.page-wrapper.compact-wrapper .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 11px;
  right: -9px;
  color: #337ab7;
  z-index: 9999;
}
[dir=rtl] .page-wrapper.compact-wrapper .sidebar-list i.fa-thumb-tack {
  left: -9px;
  right: unset;
}
.page-wrapper.compact-wrapper .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
  cursor: pointer;
}
.page-wrapper.compact-wrapper .sidebar-list.pined {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.page-wrapper.compact-wrapper .pin-title {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.page-wrapper.compact-wrapper .page-header {
  margin-left: 252px;
  width: calc(100% - 252px);
}
.page-wrapper.compact-wrapper .page-header .header-logo-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-header.close_icon {
  margin-left: 77px;
  width: calc(100% - 77px);
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
  height: auto;
  line-height: inherit;
  background: #2A3650;
  width: 252px;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
          box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
  padding: 18px 24px;
  -webkit-box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
          box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
  width: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .badge {
  opacity: 0;
  width: 0;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  max-width: 86px;
  margin: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  text-align: center;
  padding: 12px 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main-title {
  display: none !important;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .logo-icon-wrapper {
  text-align: right;
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
  width: 265px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .badge {
  opacity: 1;
  width: auto;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  max-width: unset;
  margin: initial;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  text-align: left;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  text-align: right;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a {
  padding: 12px 15px 12px 20px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li > a span {
  display: inline-block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main-title {
  opacity: 1;
  display: block !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main-title.pin-title {
  display: none !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .sidebar-main-title.pin-title.show {
  display: block !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-wrapper {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-icon-wrapper {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .mobile-sidebar {
  -webkit-transform: translate(183px);
          transform: translate(183px);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
  margin-left: 80px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer-fix {
  width: calc(100% - 84px);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
  height: calc(100vh - 118px);
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link::before {
  right: 20px;
  left: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a span {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon .according-menu {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .left-arrow,
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .right-arrow {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 16px;
  right: 35px;
  color: #fff;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list i.fa-thumb-tack {
  left: 35px;
  right: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
  cursor: pointer;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list.pined, .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-list.pin-title {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .pin-title {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  height: calc(100vh - 105px);
  left: -300px;
  z-index: 99;
  -webkit-transition: color 1s ease;
  transition: color 1s ease;
  overflow: auto;
  color: rgba(0, 0, 0, 0);
  margin-top: 20px;
  margin-bottom: 30px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a {
  font-weight: 500;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
  vertical-align: -2px;
  float: none;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  letter-spacing: 0px;
  text-transform: capitalize;
  color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.link-nav .according-menu {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li:last-child {
  border-bottom: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links.opennav {
  left: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .back-btn {
  display: block;
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .back-btn .mobile-back {
  padding: 20px;
  color: #9B9B9B;
  font-weight: 700;
  text-transform: uppercase;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
  display: block;
  width: 100%;
  position: relative;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title {
  padding: 25px 0 12px 20px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title div {
  padding: 6px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: #374462;
  border-radius: 6px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title.pin-title.show {
  display: block;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title {
  padding: 25px 20px 12px 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title:nth-child(2) {
  margin-top: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title:nth-child(2)::before {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-list .badge {
  position: absolute;
  right: 50px;
  top: 14.5px;
  padding: 0.45em 0.8em 0.33em;
  z-index: 1;
  font-size: 10px;
  letter-spacing: 0px;
  font-weight: 600;
  font-family: "Nunito", sans-serif, sans-serif;
  border-radius: 10px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-list:hover > a:hover {
  background-color: #374462;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .sidebar-link:not(.active):hover svg {
  fill: rgba(122, 112, 186, 0.001);
  stroke: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .sidebar-link:not(.active):hover svg.fill-icon {
  fill: #fff;
  stroke: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .sidebar-link:not(.active):hover span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .sidebar-link:not(.active):hover .according-menu i {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a {
  padding: 12px 20px;
  position: relative;
  color: #222222;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow {
  right: 20px;
  position: absolute;
  top: 10px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow i {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a .sub-arrow:before {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:first-child {
  display: none !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
  width: 100%;
  padding: 0;
  position: relative !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a {
  padding-left: 62px !important;
  font-weight: 400;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu .submenu-title .according-menu {
  top: 7px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu.opensubmenu {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li:hover > a {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
  display: none;
  position: relative !important;
  right: 0;
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu.opensubchild {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu li a {
  padding: 6px 45px;
  padding-left: 56px !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu li a:after {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  padding: 7px 25px;
  font-size: 14px;
  color: rgba(203, 204, 206, 0.57);
  display: block;
  position: relative;
  letter-spacing: 0em;
  font-weight: 500;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active {
  color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active::before {
  background: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a.active:after {
  border-top-color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:after {
  left: 27px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover {
  margin-left: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
  padding: 0;
  position: relative;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container.opensubmenu {
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box {
  width: 100%;
  padding: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section:hover h5 {
  color: #fff !important;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title {
  position: relative;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 6px 25px 6px 62px;
  font-size: 14px;
  position: relative;
  color: rgba(255, 255, 255, 0.57);
  display: block;
  letter-spacing: 0em;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  padding: 6px 62px 6px 25px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5:after {
  left: 28px;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5:after {
  right: 28px;
  left: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title.active h5 {
  color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title.active h5::before {
  opacity: 1;
  visibility: visible;
  background-color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title.active h5::after {
  border-top-color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title .according-menu {
  top: 8px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  margin-bottom: 0;
  line-height: 1.9;
  padding: 6px 25px 6px 76px;
  font-size: 14px;
  position: relative;
  color: rgba(255, 255, 255, 0.57);
  display: block;
  letter-spacing: 0em;
  font-weight: 500;
  font-family: "Nunito", sans-serif;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  padding: 6px 76px 6px 25px;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a:after {
  display: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a.active {
  color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content ul li a {
  line-height: 1.9;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content ul li a:hover {
  margin-left: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.mega-menu .sidebar-title.active ~ .mega-menu-container {
  display: block !important;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.mega-menu .sidebar-title.active ~ .mega-menu-container::before {
  height: calc(100% - 9px);
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link::after {
  position: absolute;
  content: "";
  width: 4px;
  height: 35px;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background-color: #337ab7;
  border-radius: 5px 0px 0px 5px;
  opacity: 0;
  visibility: hidden;
}
[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link::after {
  right: unset;
  left: 0;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: relative;
  margin-bottom: 10px;
  background-color: #374462;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active::after {
  opacity: 1;
  visibility: visible;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active ~ .sidebar-submenu::before {
  height: calc(100% - 12px);
  -webkit-transition: height 0.5s;
  transition: height 0.5s;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active .according-menu i {
  color: #fff;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active span {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .simplebar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page-wrapper.compact-wrapper .page-body-wrapper .mega-box.col {
  -ms-flex-preferred-size: unset;
      flex-basis: unset;
}
.page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  position: absolute;
  right: 20px;
  top: 11px;
  display: block;
}
.page-wrapper.compact-wrapper .page-body-wrapper .according-menu i {
  vertical-align: middle;
  color: rgba(155, 155, 155, 0.8);
  font-weight: 500;
}
.page-wrapper.compact-wrapper .page-body-wrapper .main-menu.border-section {
  border: none;
}
.page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav {
  position: absolute;
  z-index: 2;
  right: 145px;
  top: 6px;
}
.page-wrapper.compact-wrapper .page-body-wrapper .main-menu .menu-left .main-menu-right .toggle-nav .sidebar-name {
  font-size: 18px;
  padding-left: 5px;
  text-transform: uppercase;
}
.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  min-height: calc(100vh - 80px);
  margin-top: 110px;
  margin-left: 252px;
}
.page-wrapper.compact-wrapper.dark-sidebar .sidebar-main-title h6, .page-wrapper.compact-wrapper.color-sidebar .sidebar-main-title h6 {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar .toggle-sidebar svg, .page-wrapper.compact-wrapper.color-sidebar .toggle-sidebar svg {
  stroke: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.stroke-icon {
  stroke: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg.fill-icon {
  fill: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.stroke-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.stroke-icon {
  stroke: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.fill-icon, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a.active svg.fill-icon {
  fill: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title::before, .page-wrapper.compact-wrapper.color-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.sidebar-main-title::before {
  background: rgba(255, 255, 255, 0.3);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
  background-color: #2A3650;
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a span, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.stroke-icon, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.stroke-icon {
  stroke: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.fill-icon, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links li a svg.fill-icon {
  fill: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container .mega-box .link-section .submenu-content.opensubmegamenu li a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container::after {
  background-color: rgba(255, 255, 255, 0.2);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active {
  background-color: rgba(122, 112, 186, 0.2);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-link.active svg {
  color: var(--theme-deafult);
  stroke: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a span {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list ul.sidebar-submenu li a.active {
  color: var(--theme-deafult);
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .logo-wrapper .for-light, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .logo-wrapper .for-light {
  display: none;
}
.page-wrapper.compact-wrapper.dark-sidebar div.sidebar-wrapper .logo-wrapper .for-dark, .page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper .logo-wrapper .for-dark {
  display: block;
}
.page-wrapper.compact-wrapper.dark-sidebar .according-menu i, .page-wrapper.compact-wrapper.color-sidebar .according-menu i {
  color: rgba(255, 255, 255, 0.7);
}
.page-wrapper.compact-wrapper.color-sidebar div.sidebar-wrapper {
  background-color: #2f3c4e;
}
.page-wrapper.compact-sidebar .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.page-wrapper.compact-sidebar .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 8px;
  right: 54px;
  color: #337ab7;
  width: 20px;
  height: 20px;
  background-color: #E0DDFF;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[dir=rtl] .page-wrapper.compact-sidebar .sidebar-list i.fa-thumb-tack {
  left: 54px;
  right: unset;
}
.page-wrapper.compact-sidebar .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
  cursor: pointer;
  z-index: 1;
}
.page-wrapper.compact-sidebar .sidebar-list.pined {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.page-wrapper.compact-sidebar .pin-title {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.page-wrapper.compact-sidebar ~ .bg-overlay1 {
  -webkit-transition: 0.8s;
  transition: 0.8s;
}
.page-wrapper.compact-sidebar ~ .bg-overlay1.active {
  z-index: 8;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  top: 0;
}
.page-wrapper.compact-sidebar .toggle-sidebar {
  position: relative;
  right: unset;
  top: unset;
  margin-right: 15px;
}
.page-wrapper.compact-sidebar .sidebar-main-title {
  display: none;
}
.page-wrapper.compact-sidebar .logo-wrapper {
  display: none;
}
.page-wrapper.compact-sidebar .logo-icon-wrapper {
  display: block;
  text-align: center;
  padding: 24px 30px;
  -webkit-box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
          box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-sidebar .page-header {
  margin-left: 150px;
  width: calc(100% - 150px);
}
.page-wrapper.compact-sidebar .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
  width: 30%;
}
@media (max-width: 1578px) and (min-width: 1274px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 38%;
  }
}
@media (max-width: 1273px) and (min-width: 1200px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 45%;
  }
}
@media (max-width: 991px) and (min-width: 768px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 36%;
  }
}
@media (max-width: 767px) and (min-width: 756px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header {
    width: 28%;
  }
}
@media (max-width: 1199px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header h6 {
    width: 132px;
  }
}
@media (max-width: 767px) {
  .page-wrapper.compact-sidebar .page-header .header-wrapper .left-header h6 {
    width: 83px;
  }
}
.page-wrapper.compact-sidebar .page-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}
.page-wrapper.compact-sidebar .page-body-wrapper .page-body {
  margin-top: 80px;
  margin-left: 150px;
}
.page-wrapper.compact-sidebar .page-body-wrapper .footer {
  margin-left: 150px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper {
  position: fixed;
  top: 0;
  z-index: 9;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  height: auto;
  line-height: inherit;
  background: #fff;
  width: 150px;
  text-align: left;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
          box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .left-arrow,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .right-arrow {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  margin: 30px 0;
  height: calc(100vh - 113px);
  overflow: auto;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a {
  color: #2c323f;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a span {
  text-transform: capitalize;
  color: #2c323f;
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 14px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active {
  background: rgba(122, 112, 186, 0.1);
  color: #337ab7;
  border-radius: 20px;
  margin: 0 10px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active span {
  color: #337ab7;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
  -webkit-animation: fadeInLeft 300ms ease-in-out;
          animation: fadeInLeft 300ms ease-in-out;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
  display: block;
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  position: fixed;
  top: 0px;
  height: calc(100vh - 0px);
  overflow: auto;
  left: 150px;
  width: 230px;
  background-color: #fff;
  -webkit-box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
          box-shadow: 0 16px 15px 15px rgba(126, 55, 216, 0.03);
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  display: none;
  padding-top: 20px;
  z-index: -1;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  padding: 15px 15px 15px 40px;
  display: block;
  position: relative;
  border-bottom: 1px solid #f1f1f1;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .sub-arrow,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .sub-arrow {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
  position: absolute;
  right: 20px;
  top: 16px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a.active,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a.active {
  background-color: rgba(122, 112, 186, 0.1);
  color: #337ab7;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li .submenu-content li a.active,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li .submenu-content li a.active {
  background-color: transparent;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu > li > a::after,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container > li > a::after {
  position: absolute;
  content: "";
  left: 20px;
  top: 48%;
  width: 8px;
  border-top: 2px solid rgba(155, 155, 155, 0.4);
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
  padding: 0;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section {
  text-align: left;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  position: relative;
  font-size: 14px;
  font-weight: 400;
  padding: 15px 15px 15px 40px;
  letter-spacing: 0px;
  margin-bottom: 0;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5::after,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5::after {
  position: absolute;
  content: "";
  left: 20px;
  top: 48%;
  width: 6px;
  border-top: 2px solid #595c60;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list {
  position: relative;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
  position: absolute;
  left: 53%;
  top: 7px;
  font-weight: 500;
  font-size: 9px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link {
  text-align: center;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link svg {
  margin: 0 auto 6px;
  width: 24px;
  height: 24px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link > span {
  display: block;
  font-weight: 500;
  letter-spacing: 0px;
  font-size: 14px;
  word-break: break-word;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link .according-menu {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu {
  text-align: center;
  padding: 0;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu svg {
  width: 24px;
  height: 24px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu .according-menu {
  position: absolute;
  right: 20px;
  top: 13px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=stroke-svg] .sidebar-links .mega-menu svg.stroke-icon {
  display: block;
  margin: 0 auto 6px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=stroke-svg] .sidebar-links .mega-menu svg.fill-icon {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=fill-svg] .sidebar-links .mega-menu svg.stroke-icon {
  display: none;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper[data-layout=fill-svg] .sidebar-links .mega-menu svg.fill-icon {
  display: block;
  margin: 0 auto 6px;
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
  -webkit-transform: translateX(-150px);
          transform: translateX(-150px);
}
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
  margin-left: 0px;
}
.page-wrapper.compact-small .toggle-sidebar {
  position: relative;
  right: unset;
  top: unset;
  margin-right: 15px;
}
.page-wrapper.compact-small .sidebar-list i.fa-thumb-tack {
  visibility: hidden;
  position: absolute;
  top: 8px;
  right: 22px;
  color: #337ab7;
  width: 20px;
  height: 20px;
  background-color: #E0DDFF;
  border-radius: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[dir=rtl] .page-wrapper.compact-small .sidebar-list i.fa-thumb-tack {
  left: 22px;
  right: unset;
}
.page-wrapper.compact-small .sidebar-list:hover i.fa-thumb-tack {
  visibility: visible;
}
.page-wrapper.compact-small .sidebar-list.pined, .page-wrapper.compact-small .sidebar-list.pin-title {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}
.page-wrapper.compact-small .sidebar-main-title,
.page-wrapper.compact-small .logo-wrapper {
  display: none;
}
.page-wrapper.compact-small .logo-icon-wrapper {
  display: block;
  text-align: center;
  padding: 27px 30px;
  -webkit-box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
          box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.page-wrapper.compact-small .page-header {
  margin-left: 90px;
  width: calc(100% - 90px);
}
.page-wrapper.compact-small .page-header .header-wrapper .logo-wrapper {
  display: none;
}
.page-wrapper.compact-small .page-header.close_icon {
  margin-left: 0;
  width: calc(100% - 0px);
}
.page-wrapper.compact-small .page-body-wrapper .page-body {
  margin-left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper .footer {
  margin-left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper > div {
  width: 90px;
}
@media (max-width: 420px) {
  .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 105px);
  }
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-title {
  display: block;
  padding: 15px 0;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  left: 90px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  padding: 10px 15px 10px 40px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .sub-arrow,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .sub-arrow {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
  top: 11px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link svg {
  margin: 0 auto;
  width: 20px;
  height: 20px;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link > span {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu svg {
  margin: 0 auto;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu a > span {
  display: none;
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon {
  -webkit-transform: translateX(-150px);
          transform: translateX(-150px);
}
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
.page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
  margin-left: 0px;
}
.page-wrapper.only-body {
  background-color: #f2f0fa;
}
.page-wrapper.only-body .page-body-wrapper {
  background-color: #f2f0fa;
}
.page-wrapper.only-body .page-header {
  position: relative;
}
.page-wrapper.only-body .page-header .header-logo-wrapper {
  display: none;
}
.page-wrapper.only-body .page-header .header-wrapper {
  padding: 0 !important;
}
.page-wrapper.only-body .page-header .header-wrapper .left-header {
  display: none;
}
.page-wrapper.only-body .page-header .header-wrapper .nav-right {
  position: absolute;
  right: 60px;
  top: 58px;
}
.page-wrapper.only-body .page-header .header-wrapper .search-full {
  top: 37px;
  width: calc(100% - 50px);
  left: 25px;
}
[dir=rtl] .page-wrapper.only-body .page-header .header-wrapper .search-full {
  left: unset;
  right: 25px;
}
.page-wrapper.only-body .page-header .header-wrapper .search-full .form-group .Typeahead .u-posRelative {
  background-color: #fff;
  border-radius: 15px;
}
.page-wrapper.only-body .page-header .header-wrapper .search-full .Typeahead-menu {
  top: 78px;
  width: calc(100% - 30px);
  margin: 0 auto;
  right: 0;
}
.page-wrapper.only-body .page-body-wrapper .page-title {
  padding-top: 20px;
  padding-bottom: 20px;
}
.page-wrapper.only-body .sidebar-wrapper,
.page-wrapper.only-body footer {
  display: none;
}
.page-wrapper.only-body .page-body-wrapper .page-body {
  margin-top: 0px;
  min-height: calc(100vh - 0px);
}
.page-wrapper.only-body .page-body-wrapper .page-title {
  padding-left: 30px;
  padding-right: 30px;
  margin: 30px 0;
  border-radius: 15px;
}
@media (max-width: 991.98px) {
  .page-wrapper.only-body .page-body-wrapper .page-title {
    padding-left: 20px;
    padding-right: 20px;
    margin: 20px 0;
  }
}
.page-wrapper.only-body .page-body-wrapper .page-title > .row .col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}
.page-wrapper.only-body .page-body-wrapper .page-title .breadcrumb {
  margin-top: 5px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.page-wrapper.modern-type .progress-chart-wrap {
  margin-left: -22px;
}
[dir=rtl] .page-wrapper.modern-type .progress-chart-wrap {
  margin-left: unset;
  margin-right: -22px;
}
.page-wrapper.modern-type .ecommerce-widget {
  border: 1px solid #c0bcdf !important;
}
.page-wrapper.modern-type .file-sidebar .pricing-plan {
  border: 1px solid #c0bcdf !important;
}
.page-wrapper.modern-type .alert-primary {
  color: #337ab7;
}
.page-wrapper.modern-type .project-box {
  background-color: #c0bcdf;
}
.page-wrapper.modern-type .nav-tabs .nav-link.active {
  background-color: transparent;
}
.page-wrapper.modern-type .simplebar-mask {
  top: 10px;
}
.page-wrapper.modern-type .page-header {
  margin-left: 0px;
  width: calc(100% - 0px);
}
.page-wrapper.modern-type .page-header.close_icon {
  margin-left: 0px;
  width: calc(100% - 0px);
}
.page-wrapper.modern-type .page-header .header-wrapper .left-header {
  width: 30%;
}
@media (max-width: 1428px) and (min-width: 1200px) {
  .page-wrapper.modern-type .page-header .header-wrapper .left-header {
    width: 37%;
  }
}
@media (max-width: 818px) {
  .page-wrapper.modern-type .page-header .header-wrapper .left-header {
    width: 36%;
  }
}
@media (max-width: 1669px) {
  .page-wrapper.modern-type .page-header .header-wrapper .nav-right {
    width: 50%;
  }
}
@media (max-width: 1428px) and (min-width: 1200px) {
  .page-wrapper.modern-type .page-header .header-wrapper .nav-right {
    width: 40%;
  }
}
.page-wrapper.modern-type .page-header .header-wrapper .header-logo-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
  display: block;
  margin-right: 30px;
}
.page-wrapper.modern-type .page-header .header-wrapper .toggle-sidebar {
  display: block;
  position: relative;
  top: unset;
  right: unset;
  margin-right: 15px;
}
.page-wrapper.modern-type .page-body-wrapper .page-title {
  background-color: transparent;
  padding-bottom: 0;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper {
  margin: 30px !important;
  top: 80px;
  bottom: 0;
  border-radius: 15px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-wrapper,
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .logo-icon-wrapper {
  display: none;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
  height: calc(100vh - 160px);
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon .logo-icon-wrapper {
  display: none;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
  margin-left: 0px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
  margin-left: 0px;
  margin-right: 0px;
}
.page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon:hover .logo-wrapper {
  display: none;
}
.page-wrapper.modern-type .page-body-wrapper .page-body {
  margin-left: 296px;
}
.page-wrapper.modern-type .page-body-wrapper .page-body .container-fluid {
  max-width: 1500px;
}
.page-wrapper.modern-type .page-body-wrapper .footer {
  margin-left: 360px;
  margin-right: 70px;
  border-radius: 10px;
}
@media (max-width: 991px) {
  .page-wrapper.modern-type .page-body-wrapper .footer {
    margin-right: 0;
    margin-left: 0;
  }
}

.bg-img-cover {
  width: 105%;
  height: 100%;
}

.page-wrapper.material-type {
  position: relative;
}
.page-wrapper.material-type::before {
  position: absolute;
  content: "";
  left: 0;
  width: 100%;
  height: 300px;
  background-image: linear-gradient(103.75deg, #33B1EE -13.9%, var(--theme-deafult) 79.68%);
}
.page-wrapper.material-type .card {
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}
.page-wrapper.material-type .page-header {
  position: relative;
  max-width: 1560px;
  margin: 50px auto 0;
  border-radius: 15px 15px 0 0;
}
.page-wrapper.material-type .page-header .header-wrapper .left-header {
  width: 31%;
}
@media (max-width: 1600px) {
  .page-wrapper.material-type .page-header .header-wrapper .left-header {
    width: 33%;
  }
}
@media (max-width: 1366px) {
  .page-wrapper.material-type .page-header .header-wrapper .left-header {
    width: 38%;
  }
}
@media (max-width: 1199px) {
  .page-wrapper.material-type .page-header .header-wrapper .left-header {
    width: 30%;
  }
}
@media (max-width: 1600px) {
  .page-wrapper.material-type .page-header .header-wrapper .nav-right {
    width: 40%;
  }
}
@media (max-width: 1199px) {
  .page-wrapper.material-type .page-header .header-wrapper .nav-right {
    width: 45%;
  }
}
.page-wrapper.material-type .page-body-wrapper {
  background-image: url(../../images/boxbg.jpg);
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.5);
}
.page-wrapper.material-type .page-body-wrapper .sidebar-wrapper {
  position: relative;
  max-width: 1560px;
  margin: 0 auto;
  top: unset;
  height: 56px;
}
.page-wrapper.material-type .page-body-wrapper .page-body {
  margin: 0 auto;
  max-width: 1560px;
  background-color: #f8f8f8;
  -webkit-box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
          box-shadow: 0 0 20px 7px rgba(8, 21, 66, 0.05);
  border-radius: 0 0 15px 15px;
}
.page-wrapper.material-type .footer {
  margin-top: 40px;
}
.page-wrapper.material-type.compact-wrapper .page-header .header-wrapper .left-header {
  width: 38%;
}
@media (max-width: 991px) {
  .page-wrapper.material-type.compact-wrapper .page-header .header-wrapper .nav-right {
    width: 50%;
  }
}
.page-wrapper.advance-layout {
  background-blend-mode: overlay;
  background-color: #ddd;
}
.page-wrapper.advance-layout .card {
  border-radius: 5px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #ecf3fa;
}
.page-wrapper.advance-layout .page-header {
  position: relative;
  background-color: #fff;
  margin: 0 auto;
}
.page-wrapper.advance-layout .page-body-wrapper {
  background: transparent;
}
.page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper {
  position: relative !important;
  top: unset !important;
  height: unset;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-top: 1px solid #efefef;
  border-bottom: 1px solid #efefef;
}
.page-wrapper.advance-layout .page-body-wrapper .sidebar-wrapper > div {
  overflow-x: hidden;
}
.page-wrapper.advance-layout .page-body-wrapper .page-body {
  margin-top: 0 !important;
}

@-webkit-keyframes pagebody {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
            transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }
  50% {
    opacity: 1;
  }
}

@keyframes pagebody {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
            transform: scale3d(0.98, 0.98, 0.98) translate(-20px, -50px);
  }
  50% {
    opacity: 1;
  }
}
.md-sidebar {
  position: relative;
}
.md-sidebar .md-sidebar-toggle {
  display: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  margin-bottom: 20px;
}

@media screen and (max-width: 1480px) {
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -18%;
    width: 91%;
  }
}
@media screen and (max-width: 1460px) {
  .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -54%;
    width: 75%;
  }
  .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    margin-right: 8px;
  }
}
@media screen and (max-width: 1366px) {
  .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -49%;
    width: 70%;
  }
  .profile-dropdown.onhover-show-div {
    right: 0;
    left: unset !important;
  }
  .profile-dropdown.onhover-show-div:before, .profile-dropdown.onhover-show-div:after {
    right: 10px !important;
    left: unset !important;
  }
}
@media screen and (max-width: 1199px) {
  .page-wrapper .page-header .header-wrapper .nav-right > ul > li {
    padding: 6px;
  }
  .mobile-title.d-none {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 20px 30px 17px;
    border-bottom: 1px solid #ddd;
    margin: 0 -15px;
  }
  .mobile-title.d-none h5 {
    color: var(--theme-deafult);
  }
  .mobile-title.d-none svg {
    stroke: var(--theme-deafult) !important;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper.sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    right: -35%;
    width: 56%;
  }
}
@media screen and (min-width: 992px) {
  .page-header .header-wrapper .nav-right .notification-dropdown {
    top: 46px;
  }
  .responsive-btn {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-mask {
    top: 0;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu {
    width: 200px;
    opacity: 0;
    visibility: hidden;
    border-radius: 5px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu a .sub-arrow {
    position: absolute;
    right: 20px;
    top: 5px;
    color: #59667a;
    font-weight: 100;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    font-size: 15px;
    top: 5px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li .nav-sub-childmenu {
    display: none;
    border-radius: 4px;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li:hover .nav-sub-childmenu {
    display: block;
    position: absolute;
    left: 200px;
    background-color: #fff;
    top: -10px;
    padding: 15px 0px 15px 0px;
    -webkit-box-shadow: 0 0 1px 0 #898989;
            box-shadow: 0 0 1px 0 #898989;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu li a:hover .sub-arrow {
    right: 16px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .mega-menu-container {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li.hoverd .sidebar-submenu {
    opacity: 1;
    visibility: visible;
    margin-top: 8px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li:hover .mega-menu-container {
    opacity: 1;
    visibility: visible;
    margin-top: 20px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .toggle-nav {
    display: none;
  }
  .page-wrapper.horizontal-wrapper .page-body-wrapper .mobile-back {
    display: none !important;
  }
}
@media only screen and (min-width: 768px) {
  .page-wrapper.material-icon {
    position: relative;
  }
  .page-wrapper.material-icon::before {
    position: absolute;
    content: "";
    left: 0;
    width: 100%;
    height: 200px;
    background: linear-gradient(103.75deg, #33B1EE -13.9%, var(--theme-deafult) 79.68%);
  }
  .page-wrapper.material-icon .toggle-sidebar {
    color: rgba(255, 255, 255, 0.8);
  }
  .page-wrapper.material-icon .toggle-sidebar:hover > svg {
    color: #fff !important;
    stroke: #fff !important;
  }
  .page-wrapper.material-icon .page-header {
    position: relative;
    background: transparent;
    padding-left: 90px;
    max-width: 1660px;
    margin: 0 auto;
    width: calc(100% - 0px);
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .page-wrapper.material-icon .page-header .f-light {
    color: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right ul li svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right ul li .profile-dropdown li svg {
    stroke: #3D434A;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li {
    color: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li .notification-box svg,
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li .cart-box svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li > a > svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right > ul > li > svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right .language-nav .more_lang {
    color: #3D434A;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right .onhover-show-div {
    color: #3D434A;
  }
  .page-wrapper.material-icon .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 p {
    color: rgba(255, 255, 255, 0.7);
  }
  .page-wrapper.material-icon .page-header.close_icon {
    padding-left: 0;
    margin: 0 auto !important;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-body {
    margin-top: 0 !important;
    padding-left: 90px;
    max-width: 1660px;
    margin: 0 auto;
  }
  .page-wrapper.material-icon .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
    padding-left: 15px;
    margin: 0 auto !important;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title {
    color: #fff;
    background-color: transparent;
    border-bottom: none;
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item a svg {
    stroke: #fff;
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item.active {
    color: rgba(255, 255, 255, 0.6);
  }
  .page-wrapper.material-icon .page-body-wrapper .page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: rgba(255, 255, 255, 0.8);
  }
}
@media only screen and (min-width: 992px) {
  .page-wrapper.material-icon::before {
    height: 240px;
  }
}
@media screen and (max-width: 767px) {
  .sidebar-wrapper .menu .brand-logo img {
    width: 120px;
  }
}
@media screen and (max-width: 576px) {
  .toggle-nav i {
    font-size: 20px;
  }
}
/**=====================
  5.7 Sidebar CSS Ends
==========================**/
/**=====================
    5.8 Rtl CSS Start
==========================**/
html[dir=rtl] ul {
  padding-right: 0;
}
html[dir=rtl] .ms-auto {
  margin-left: unset !important;
  margin-right: auto !important;
}
html[dir=rtl] .user-profile .hovercard .user-image .icon-wrapper {
  left: -30%;
}
html[dir=rtl] .contacts-tabs .nav-pills {
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: none;
}
html[dir=rtl] .slider-wrapper {
  direction: ltr;
}
html[dir=rtl] .fg-emoji-container {
  left: 107px !important;
}
html[dir=rtl] .f-right {
  float: left;
}
html[dir=rtl] .papernote-wrap .note-labels ul {
  gap: 9px;
}
html[dir=rtl] .form-check-inline {
  margin-right: unset;
  margin-left: 1rem;
}
html[dir=rtl] .color-box button + button {
  margin-left: unset;
  margin-right: 5px;
}
html[dir=rtl] .knowledgebase-search .form-inline svg {
  left: unset;
  right: 20px;
}
html[dir=rtl] .switch-showcase .form-check .form-check-input {
  float: left;
}
html[dir=rtl] .msger-inputarea .dropdown-form .chat-icon {
  right: 0 !important;
  left: 0 !important;
}
html[dir=rtl] .pill-input-group span:last-child {
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
  border-top-left-radius: 1.3rem !important;
  border-bottom-left-radius: 1.3rem !important;
}
html[dir=rtl] .pill-input-group span:first-child {
  border-top-left-radius: unset !important;
  border-top-right-radius: unset !important;
  border-top-right-radius: 1.3rem !important;
  border-bottom-right-radius: 1.3rem !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .toggle-sidebar {
  right: unset;
  left: 30px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
  padding: 0;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .badge {
  left: 50px !important;
  right: unset !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon {
  margin-right: 0;
  margin-left: unset;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .logo-icon-wrapper {
  text-align: left;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .logo-icon-wrapper img {
  margin-left: 8px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .page-body,
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
  margin-left: 0;
  margin-right: 88px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  margin-left: 0;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
  display: none !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .according-menu {
  display: none;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  margin-left: 13px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover {
  -webkit-transform: translate(0px);
          transform: translate(0px);
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .sidebar-main .sidebar-links .sidebar-list .sidebar-title.active ~ .sidebar-submenu {
  display: block !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  float: none;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper:hover .according-menu {
  display: block;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu:after {
  right: 30px;
  left: unset;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a {
  padding-right: 62px !important;
  padding-left: unset !important;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li .sidebar-submenu > li a:after {
  right: 28px;
  left: unset;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 265px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li a svg {
  margin-right: unset;
  margin-left: 15px;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper .according-menu {
  right: unset;
  left: 20px;
}
html[dir=rtl] .page-wrapper.only-body .page-header .header-wrapper .nav-right {
  right: unset;
  left: 30px;
}
html[dir=rtl] .date-details > div + div {
  margin-left: unset;
  margin-right: 10px;
}
html[dir=rtl] .contact-editform.ps-0 {
  padding-left: 20px !important;
}
html[dir=rtl] .contact-options li .btn-category {
  padding-left: unset !important;
  padding-right: 40px;
}
html[dir=rtl] .list-persons .profile-mail .email-general ul {
  padding-right: unset;
  padding-left: 20px;
}
html[dir=rtl] .list-persons .profile-mail .email-general ul li > span {
  float: left;
}
html[dir=rtl] .list-persons .profile-mail .d-flex .flex-grow-1 ul li + li {
  padding-left: unset;
  padding-right: 10px;
  margin-left: unset;
  margin-right: 5px;
  border-left: unset;
  border-right: 1px solid #f4f4f4;
}
html[dir=rtl] .quick-file li {
  margin-left: 20px;
  margin-right: unset;
  margin-bottom: 10px;
}
html[dir=rtl] .file-manager .folder {
  margin-bottom: -12px;
}
html[dir=rtl] .file-manager .folder li:nth-child(n+2) {
  margin-right: 0px;
  margin-left: unset;
  margin-bottom: 12px;
}
html[dir=rtl] .files-content li {
  margin-left: 20px;
  margin-right: unset;
  margin-bottom: 10px;
}
html[dir=rtl] .file-content .folder .folder-box .ellips {
  right: unset;
  left: 22px;
}
html[dir=rtl] .file-sidebar .pricing-plan .bg-img {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  left: -40px;
  right: unset;
}
html[dir=rtl] .file-content .form-inline i {
  padding-right: unset;
  padding-left: 10px;
}
html[dir=rtl] .file-sidebar .btn svg {
  margin-right: unset;
  margin-left: 8px;
}
html[dir=rtl] .form-select {
  background-position: left 0.75rem center;
}
html[dir=rtl] .customers ul li + li {
  margin-left: unset;
  margin-right: -10%;
}
html[dir=rtl] .project-list .border-tab.nav-tabs .nav-item .nav-link {
  padding: 5px 0 5px 10px;
}
html[dir=rtl] .project-box .badge {
  right: unset;
  left: 30px;
}
html[dir=rtl] .kanban-board {
  float: right;
}
html[dir=rtl] .kanban-item .kanban-box .customers ul li + li {
  margin-left: unset;
  margin-right: -10px;
}
html[dir=rtl] .kanban-item .list li i {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .page-wrapper.horizontal-wrapper .header-logo-wrapper {
  text-align: right;
}
html[dir=rtl] .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
  margin-right: 0;
}
html[dir=rtl] .page-wrapper.horizontal-wrapper .page-body-wrapper footer {
  margin-right: 0;
}
html[dir=rtl] .page-wrapper.material-type .page-body-wrapper .page-body {
  margin-right: auto;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:first-child {
  margin-right: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul li .profile-media .flex-grow-1 {
  margin-right: 15px;
  margin-left: unset;
}
html[dir=rtl] .page-header .header-wrapper .toggle-sidebar {
  margin-left: 2px;
  margin-right: unset;
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
  left: -20px !important;
  right: unset !important;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .notification-dropdown {
    left: 12px !important;
  }
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full input {
  padding-left: unset;
  padding-right: 42px;
  text-align: right;
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group:before {
  left: unset;
  right: 30px;
}
html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
  right: unset;
  left: 14px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .toggle-sidebar {
  margin-left: 15px;
  margin-right: unset;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-header {
  margin-left: unset;
  margin-right: 150px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-header.close_icon {
  margin-right: 0;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 150px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper .footer {
  margin-left: unset;
  margin-right: 150px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper > div {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
  -webkit-animation: fadeInRight 1000ms ease-in-out;
          animation: fadeInRight 1000ms ease-in-out;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  right: 150px;
  left: unset;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a {
  padding: 15px 40px 15px 15px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu li a .according-menu,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container li a .according-menu {
  right: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu > li > a::after,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container > li > a::after {
  left: unset;
  right: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5 {
  padding: 15px 40px 15px 15px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu .mega-box .link-section .submenu-title h5::after,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container .mega-box .link-section .submenu-title h5::after {
  left: unset;
  right: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .badge {
  left: unset;
  right: 53%;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .sidebar-list .sidebar-link {
  text-align: center;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu {
  text-align: center;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links .mega-menu .according-menu {
  right: unset;
  left: 20px;
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
  -webkit-transform: translateX(150px);
          transform: translateX(150px);
}
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body,
html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon ~ .footer {
  margin-right: 0px;
}
html[dir=rtl] .page-wrapper.compact-small .page-header {
  margin-right: 90px;
}
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
  right: 90px;
}
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper .footer,
html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper .page-body {
  margin-right: 90px;
}
html[dir=rtl] .page-wrapper.material-icon .page-header {
  padding-left: unset;
  padding-right: 90px;
  margin: 0 auto;
}
html[dir=rtl] .page-wrapper.material-icon .page-header.close_icon {
  padding-right: 0;
}
html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper .page-body {
  padding-left: 15px;
  padding-right: 90px;
  margin: 0 auto;
}
html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
  padding-right: 15px;
  margin: 0 auto !important;
}
html[dir=rtl] .apexcharts-canvas {
  direction: ltr;
}
html[dir=rtl] .email-wrap .btn-mail {
  text-align: right;
}
html[dir=rtl] .email-wrap .email-app-sidebar ul li a {
  padding-left: unset;
  padding-right: 20px;
}
html[dir=rtl] .bookmark-tabcontent .details-bookmark .bookmark-card.card .favourite-icon {
  text-align: center;
}
html[dir=rtl] .bookmark-tabcontent .details-bookmark .bookmark-card.card .title-bookmark {
  text-align: right;
}
html[dir=rtl] .bookmark-wrap .details-bookmark .row > div + div {
  padding-left: 15px !important;
}
html[dir=rtl] .appointment-table table tr td .status-circle {
  left: unset;
  right: 35px;
}
html[dir=rtl] .notification .card .d-flex .w-100 p .badge {
  margin-left: unset;
  margin-right: 10px;
}
html[dir=rtl] .progress-gradient-primary {
  background-image: -webkit-gradient(linear, left top, right top, from(var(--theme-deafult)), to(transparent));
  background-image: linear-gradient(to right, var(--theme-deafult), transparent);
}
html[dir=rtl] .progress-gradient-primary::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-primary .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-secondary {
  background-image: -webkit-gradient(linear, left top, right top, from(#48A3D7), to(transparent));
  background-image: linear-gradient(to right, #48A3D7, transparent);
}
html[dir=rtl] .progress-gradient-secondary::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-secondary .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-success {
  background-image: -webkit-gradient(linear, left top, right top, from(#0DA759), to(transparent));
  background-image: linear-gradient(to right, #0DA759, transparent);
}
html[dir=rtl] .progress-gradient-success::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-success .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-danger {
  background-image: -webkit-gradient(linear, left top, right top, from(#E44141), to(transparent));
  background-image: linear-gradient(to right, #E44141, transparent);
}
html[dir=rtl] .progress-gradient-danger::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-danger .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-info {
  background-image: -webkit-gradient(linear, left top, right top, from(#16C7F9), to(transparent));
  background-image: linear-gradient(to right, #16C7F9, transparent);
}
html[dir=rtl] .progress-gradient-info::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-info .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-light {
  background-image: -webkit-gradient(linear, left top, right top, from(#f4f4f4), to(transparent));
  background-image: linear-gradient(to right, #f4f4f4, transparent);
}
html[dir=rtl] .progress-gradient-light::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-light .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-dark {
  background-image: -webkit-gradient(linear, left top, right top, from(#2c323f), to(transparent));
  background-image: linear-gradient(to right, #2c323f, transparent);
}
html[dir=rtl] .progress-gradient-dark::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-dark .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-gradient-warning {
  background-image: -webkit-gradient(linear, left top, right top, from(#D77748), to(transparent));
  background-image: linear-gradient(to right, #D77748, transparent);
}
html[dir=rtl] .progress-gradient-warning::after {
  right: unset;
  left: -1px;
}
html[dir=rtl] .progress-gradient-warning .animate-circle {
  right: unset;
  left: -8px;
}
html[dir=rtl] .progress-animate span {
  left: -10px;
  right: unset;
}
html[dir=rtl] .me-4 {
  margin-left: 1.5rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .pe-4 {
  padding-left: 1.5rem !important;
  padding-right: unset !important;
}
html[dir=rtl] .me-1 {
  margin-left: 0.25rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .navs-dropdown .onhover-show-div {
  right: 0;
}
html[dir=rtl] .default-according.style-1 button {
  text-align: right;
}
html[dir=rtl] .default-according.style-1 button:before {
  left: 20px;
  right: unset;
}
@media (max-width: 575px) {
  html[dir=rtl] .default-according.style-1 button:before {
    left: 15px;
  }
}
html[dir=rtl] .default-according.style-1 button i {
  right: 18px;
  left: unset;
}
html[dir=rtl] .default-according .card .card-header i {
  right: 18px;
  left: unset;
}
html[dir=rtl] .faq-wrap .faq-accordion .card .card-header .btn-link {
  padding-right: 20px !important;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .faq-wrap .faq-accordion .card .card-header .btn-link {
    padding-right: 10px !important;
  }
}
html[dir=rtl] .ProfileCard-details {
  padding-right: 30px;
  padding-left: unset;
  float: right;
}
html[dir=rtl] .starter-main .card-body ul {
  padding-right: 30px;
  padding-left: unset;
}
html[dir=rtl] .theme-tab .tab-title li a svg {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .spent .spent-graph .project-budget {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .support-table table tbody tr td:first-child {
  padding-right: 0;
  padding-left: unset;
}
html[dir=rtl] .order-box .sub-total .shipping-class .shopping-checkout-option,
html[dir=rtl] .order-box .sub-total .shipping-class span,
html[dir=rtl] .order-box .sub-total li .shopping-checkout-option,
html[dir=rtl] .order-box .sub-total li span,
html[dir=rtl] .order-box .total .shipping-class .shopping-checkout-option,
html[dir=rtl] .order-box .total .shipping-class span,
html[dir=rtl] .order-box .total li .shopping-checkout-option,
html[dir=rtl] .order-box .total li span,
html[dir=rtl] .order-box .qty .shipping-class .shopping-checkout-option,
html[dir=rtl] .order-box .qty .shipping-class span,
html[dir=rtl] .order-box .qty li .shopping-checkout-option,
html[dir=rtl] .order-box .qty li span {
  float: left;
}
html[dir=rtl] .order-box .sub-total li .count,
html[dir=rtl] .order-box .total li .count,
html[dir=rtl] .order-box .qty li .count {
  float: left;
}
html[dir=rtl] .img-paypal {
  margin-right: 15px;
  margin-left: unset;
}
html[dir=rtl] .cart .qty-box .input-group .btn {
  border-radius: 0 !important;
}
html[dir=rtl] .product-qnty fieldset .input-group .input-group-prepend .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .grid-options {
  margin-right: 10px;
  margin-left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-1 {
  right: 12px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-2 {
  right: 18px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-3 {
  right: 36px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-4 {
  right: 42px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-5 {
  right: 48px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-6 {
  right: 66px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-7 {
  right: 72px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-8 {
  right: 78px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-9 {
  right: 84px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-10 {
  right: 103px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-11 {
  right: 109px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-12 {
  right: 115px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-13 {
  right: 121px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-14 {
  right: 127px;
  left: unset;
}
html[dir=rtl] .grid-options ul li a .line-grid-15 {
  right: 133px;
  left: unset;
}
html[dir=rtl] .product-filter.new-products .owl-theme .owl-nav {
  left: 0;
  right: unset;
}
html[dir=rtl] .product-filter.new-products .owl-theme .owl-item .item .product-box .product-details.text-start {
  text-align: left !important;
}
html[dir=rtl] .product-sidebar .filter-section .card .card-header h6 .pull-right i {
  left: auto;
  right: unset;
}
html[dir=rtl] .prooduct-details-box .btn {
  right: unset;
  left: 15px;
}
html[dir=rtl] .prooduct-details-box .close {
  right: unset;
  left: 10px;
}
html[dir=rtl] .collection-filter-block svg {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .feature-products form .form-group i {
  left: 30px;
  right: unset;
}
html[dir=rtl] .product-wrapper-grid.list-view .product-box .product-details {
  text-align: right;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .breadcrumb {
  margin-left: unset;
}
html[dir=rtl] .bookmark ul li + li {
  margin-left: unset;
  margin-right: 10px;
}
html[dir=rtl] .bookmark ul li .search-form .form-control-search {
  left: -50px;
  right: unset;
}
html[dir=rtl] .bookmark ul li .search-form .form-control-search:before {
  right: 50px;
  left: unset;
}
html[dir=rtl] .bookmark ul li .search-form .form-control-search:after {
  right: 20px;
  left: unset;
}
html[dir=rtl] .me-0 {
  margin-left: 0 !important;
  margin-right: unset !important;
}
html[dir=rtl] .footer .pull-right {
  float: none;
}
html[dir=rtl] .checkbox_animated,
html[dir=rtl] .radio_animated {
  margin: 0 8px 0 16px;
}
html[dir=rtl] .button.remove {
  left: -10px;
  right: unset;
}
html[dir=rtl] .code-box-copy__btn {
  left: 11px;
  right: unset;
}
html[dir=rtl] ul.nav-menus {
  border-bottom: none;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li {
  text-align: right;
}
html[dir=rtl] .badge + .badge {
  margin-right: 5px;
  margin-left: unset;
}
html[dir=rtl] .offset-xl-3 {
  margin-right: 25%;
  margin-left: unset;
}
html[dir=rtl] .m-l-10 {
  margin-right: 10px !important;
  margin-left: unset !important;
}
html[dir=rtl] .m-r-20 {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .m-r-30 {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .m-r-5 {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .text-start {
  text-align: right !important;
}
html[dir=rtl] .m-r-10 {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .pe-3 {
  padding-left: 1rem !important;
  padding-right: unset !important;
}
html[dir=rtl] .p-r-0 {
  padding-left: 0;
  padding-right: 15px;
}
html[dir=rtl] .m-l-20 {
  margin-right: 20px !important;
  margin-left: unset !important;
}
html[dir=rtl] .ps-3 {
  padding-right: 1rem !important;
}
html[dir=rtl] .b-r-light {
  border-left: 1px solid #f4f4f4 !important;
  border-right: unset !important;
}
html[dir=rtl] .float-end {
  float: left !important;
}
html[dir=rtl] .float-start {
  float: right !important;
}
html[dir=rtl] .text-end {
  text-align: left !important;
}
html[dir=rtl] .border-right {
  border-left: 1px solid #dee2e6 !important;
  border-right: unset !important;
}
html[dir=rtl] .pe-0 {
  padding-left: 0 !important;
  padding-right: 12px !important;
}
html[dir=rtl] .ps-0 {
  padding-right: 0 !important;
  padding-left: unset !important;
}
html[dir=rtl] .ms-1 {
  margin-left: unset !important;
  margin-right: 0.25rem !important;
}
html[dir=rtl] .owl-carousel {
  direction: ltr;
}
html[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle),
html[dir=rtl] .btn-group > :not(:last-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .btn-group > .btn:not(:first-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .btn-group > .btn:nth-child(2) {
  border-radius: 0 !important;
}
html[dir=rtl] .btn-group .btn-group > :not(:first-child) > .btn {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .btn-group .btn + .btn,
html[dir=rtl] .btn-group .btn + .btn-group {
  margin-right: -1px;
  border-right: none;
}
html[dir=rtl] .btn-group .btn-group + .btn,
html[dir=rtl] .btn-group .btn-group + .btn-group {
  margin-right: -1px;
}
html[dir=rtl] .btn-group .btn-group-vertical .btn + .btn,
html[dir=rtl] .btn-group .btn-group-vertical .btn + .btn-group {
  margin-right: -1px;
}
html[dir=rtl] .btn-group .btn-group-vertical .btn-group + .btn,
html[dir=rtl] .btn-group .btn-group-vertical .btn-group + .btn-group {
  margin-right: -1px;
}
html[dir=rtl] .me-3 {
  margin-right: unset !important;
  margin-left: 1rem !important;
}
html[dir=rtl] .me-5 {
  margin-left: 3rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .ms-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .m-r-15 {
  margin-left: 15px;
  margin-right: unset;
}
html[dir=rtl] .b-l-light {
  border-right: 1px solid #f4f4f4 !important;
  border-left: unset !important;
}
html[dir=rtl] .p-l-0 {
  padding-left: unset;
  padding-right: 0px;
}
html[dir=rtl] .ps-4 {
  padding-right: 1.5rem !important;
  padding-left: unset !important;
}
html[dir=rtl] .me-2 {
  margin-left: 0.5rem !important;
  margin-right: unset !important;
}
html[dir=rtl] .ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .pe-2 {
  padding-right: unset !important;
  padding-left: 0.5rem !important;
}
html[dir=rtl] .prooduct-details-box .flex-grow-1 {
  margin-right: 0 !important;
}
html[dir=rtl] .alert {
  text-align: right;
}
html[dir=rtl] .pull-right {
  float: left;
}
html[dir=rtl] .form-inline .form-group {
  margin-left: 15px;
  margin-right: 0;
}
html[dir=rtl] .card {
  text-align: right;
}
html[dir=rtl] .card.social-profile {
  text-align: center;
}
html[dir=rtl] .card .card-header h5:not(.mb-0) {
  float: right;
}
html[dir=rtl] .card .card-header span {
  clear: both;
}
html[dir=rtl] .card .card-header .card-header-right {
  left: 26px;
  right: unset;
}
html[dir=rtl] .card .card-header .card-header-right .card-option {
  text-align: left;
}
html[dir=rtl] .card .card-header .card-header-right .card-option.list-unstyled {
  padding-right: 0;
  padding-left: unset;
}
html[dir=rtl] .card .card-header .pull-left {
  float: right;
}
html[dir=rtl] .card.full-card {
  left: 0;
  right: unset;
}
html[dir=rtl] .card .card-body .alert svg {
  margin-left: 4px;
  margin-right: unset;
}
html[dir=rtl] .card .card-body .value-left {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .card .card-body .square {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .explore-card {
  text-align: left;
}
html[dir=rtl] .explore-card .group-btn {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
html[dir=rtl] .balance-box {
  text-align: center;
}
html[dir=rtl] .purchase-card {
  text-align: center;
}
html[dir=rtl] .modal-header .btn-close {
  margin: -0.5rem auto -0.5rem -0.5rem;
}
html[dir=rtl] .modal-footer > :not(:first-child) {
  margin-right: 0.5rem;
  margin-left: 0;
}
html[dir=rtl] .form-inline .form-group .col-form-label {
  margin-left: 5px;
  margin-right: 0;
}
html[dir=rtl] .breadcrumb-item + .breadcrumb-item {
  padding-right: 0.4rem;
  padding-left: unset;
}
html[dir=rtl] .breadcrumb-item + .breadcrumb-item::before {
  padding-left: 0.4rem;
  padding-right: unset;
  float: right;
}
html[dir=rtl] code[class*=language-],
html[dir=rtl] pre[class*=language-] {
  text-align: right;
}
html[dir=rtl] .input-group-prepend {
  margin-left: -1px;
  margin-right: unset;
}
html[dir=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
html[dir=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[dir=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: unset;
  margin-right: -1px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .was-validated .form-control:invalid,
html[dir=rtl] .form-control.is-invalid,
html[dir=rtl] .was-validated .form-control:valid,
html[dir=rtl] .form-control.is-valid {
  background-position: left calc(0.375em + 0.1875rem) center;
  padding-left: calc(1.5em + 0.75rem);
  padding-right: 12px;
}
html[dir=rtl] .input-group > .form-control:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .input-group > .input-group-prepend > .btn,
html[dir=rtl] .input-group > .input-group-prepend > .input-group-text,
html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .btn,
html[dir=rtl] .input-group > .input-group-append:not(:last-child) > .input-group-text,
html[dir=rtl] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
html[dir=rtl] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .input-group > .input-group-append > .btn,
html[dir=rtl] .input-group > .input-group-append > .input-group-text,
html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .btn,
html[dir=rtl] .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
html[dir=rtl] .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
html[dir=rtl] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .border-tab.nav-left .nav-link {
  text-align: left;
}
html[dir=rtl] .socialprofile .social-group ul li:nth-child(4) {
  margin-right: -22px;
}
html[dir=rtl] .social-status form .form-group .form-control-social {
  padding-right: 15px;
  padding-left: unset;
}
html[dir=rtl] .social-status .d-flex .social-status {
  right: 35px;
  left: unset;
}
html[dir=rtl] .social-chat .other-msg {
  margin-right: 40px;
  margin-left: unset;
}
html[dir=rtl] .social-chat .flex-grow-1:before {
  left: 100%;
  right: unset;
  border-left: 7px solid var(-semi-dark);
  border-right: unset;
}
html[dir=rtl] .social-chat .flex-grow-1:after {
  left: 100%;
  right: unset;
  border-left: 7px solid #fff;
  border-right: unset;
}
html[dir=rtl] .timeline-content .comment-number i {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .photos ul li {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .avatar-showcase .friend-pic {
  margin-left: 8px;
  margin-right: unset;
}
html[dir=rtl] .avatar-showcase .pepole-knows ul li {
  margin-left: 21px;
  margin-right: unset;
}
html[dir=rtl] .avatar-showcase .pepole-knows ul li:last-child {
  margin-left: 0;
}
html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
  left: 100px;
  right: unset;
}
html[dir=rtl] .user-profile .hovercard .user-image .share-icons li {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .user-profile .hovercard .user-image .share-icons li:last-child {
  margin-left: 0;
}
html[dir=rtl] .faq-form .search-icon {
  left: 12px;
  right: unset;
}
html[dir=rtl] .faq-widgets .flex-grow-1 p {
  padding-left: 20px;
  padding-right: unset;
}
html[dir=rtl] .header-faq,
html[dir=rtl] .faq-title {
  text-align: right;
}
html[dir=rtl] .faq-accordion.default-according .card .faq-header svg {
  left: 30px;
  right: unset;
}
html[dir=rtl] .faq-accordion.default-according .card .btn-link svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .navigation-option ul li {
  padding: 8px 0 8px 8px;
}
html[dir=rtl] .navigation-option ul li a {
  padding-right: 40px;
  padding-left: unset;
}
html[dir=rtl] .navigation-option ul li a svg {
  right: 15px;
  left: unset;
}
html[dir=rtl] .updates-faq {
  margin-right: unset;
  margin-left: 20px;
}
html[dir=rtl] .browse .browse-articles span svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .categories .learning-header {
  padding-right: 15px;
  padding-left: unset;
}
html[dir=rtl] .main .langChoice {
  left: 30px;
  right: unset;
}
html[dir=rtl] .pricing-block svg {
  direction: ltr;
}
html[dir=rtl] p.data-attributes svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .avatars .avatar {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .avatars .avatar:last-child {
  margin-left: 0;
}
html[dir=rtl] .avatars .avatar .status {
  left: 4px;
  right: unset;
}
html[dir=rtl] .customers.avatar-group {
  margin-left: 30px;
  margin-right: unset;
}
html[dir=rtl] .card .card-header h5 {
  float: none !important;
}
html[dir=rtl] .card-header .header-top .dropdown .dropdown-menu {
  -webkit-transform: translate(142px, 23px) !important;
          transform: translate(142px, 23px) !important;
}
html[dir=rtl] .knob-chart .chart-clock-main .clock-small {
  right: 10px;
  left: unset;
}
html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
  left: 30px;
  right: unset;
}
html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks .btn-label {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .todo .todo-list-wrapper #todo-list li .task-container .task-action-btn {
  text-align: left;
}
html[dir=rtl] .todo .notification-popup {
  left: 10px;
  right: unset;
}
html[dir=rtl] .todo-options .badges-todo h6 {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .todo-options .badges-todo h6:last-child {
  margin-left: 0;
}
html[dir=rtl] .todo-list-btns .dropdown-basic .btn-group .form-group .checkbox {
  padding-right: 14px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-left: unset;
  border-top-left-radius: unset;
  border-bottom-left-radius: unset;
}
html[dir=rtl] .todo-list-btns .dropdown-basic .separated-btn {
  margin-right: -6px;
  margin-left: unset;
}
html[dir=rtl] .todo-list-btns .dropdown-basic .separated-btn .btn {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}
html[dir=rtl] .search-todo .dropdown-basic .btn-group {
  margin-left: 18px;
  margin-right: unset;
}
html[dir=rtl] .sticky-note .note {
  float: right;
}
html[dir=rtl] .mega-menu .list-unstyled div a {
  padding: 8px 0 8px 35px;
}
html[dir=rtl] .mega-menu .list-unstyled div a:hover {
  padding: 8px 10px 8px 35px;
}
html[dir=rtl] #testimonial .owl-stage-outer .owl-stage .owl-item .slide--item .d-flex .me-3 {
  margin-right: 1rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .needs-validation .input-group > .form-control:not(:last-child),
html[dir=rtl] .needs-validation .input-group > .custom-select:not(:last-child),
html[dir=rtl] .form-row .input-group > .form-control:not(:last-child),
html[dir=rtl] .form-row .input-group > .custom-select:not(:last-child) {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .form-check {
  padding-right: 1.25rem;
  padding-left: unset;
}
html[dir=rtl] .form-check .form-check-input {
  float: right;
  margin-left: unset;
  margin-right: -1.5em;
}
html[dir=rtl] .form-check-reverse .form-check-input {
  float: right;
  margin-left: 0;
  margin-right: 10px;
}
html[dir=rtl] .mega-menu .default-according .card .btn-link {
  text-align: right;
}
html[dir=rtl] .mega-menu .default-according.style-1 button[aria-expanded=true]:before,
html[dir=rtl] .mega-menu .default-according.style-1 button[aria-expanded=false]:before {
  right: unset;
  left: 20px;
}
html[dir=rtl] .email-wrap .row .col-xl-6 {
  padding-right: 0;
  padding-left: 15px;
}
html[dir=rtl] .email-wrap .row .col-xl-3 + .col-xl-3 {
  padding-left: 0;
  padding-right: 15px;
}
html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li {
  text-align: right;
}
html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li a i {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .email-wrap .email-app-sidebar ul {
  padding-right: 0;
}
html[dir=rtl] .email-wrap .email-content .email-top .user-emailid:after {
  float: left;
}
html[dir=rtl] .email-wrap .email-right-aside .email-body .pe-0 {
  padding-right: unset !important;
}
html[dir=rtl] .email-wrap .email-right-aside .radius-left {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-radius: 4px;
}
html[dir=rtl] #renderRange {
  float: left;
  padding-left: unset;
  padding-right: 12px;
}
html[dir=rtl] .event-calendar {
  direction: ltr;
}
html[dir=rtl] .calendar-wrap .fc {
  direction: rtl;
  text-align: right;
}
html[dir=rtl] .calendar-wrap .fc .fc-toolbar .fc-left {
  float: right;
}
html[dir=rtl] .calendar-wrap .fc .fc-toolbar .fc-right {
  float: left;
}
html[dir=rtl] .calendar-wrap .fc .fc-toolbar > * > * {
  margin-left: 0;
}
html[dir=rtl] .calendar-wrap .fc-events-container {
  text-align: right;
}
html[dir=rtl] .calendar-wrap .fc-scroller {
  margin-right: unset;
  margin-left: -17px;
}
html[dir=rtl] .fc-agenda-view .fc-day-grid .fc-row {
  margin-right: 0 !important;
}
html[dir=rtl] .fc-unthemed .fc-row {
  margin-right: 0 !important;
}
html[dir=rtl] .btn-group-showcase .btn-group {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .btn-showcase .btn {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .btn-square.dropdown-toggle {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
html[dir=rtl].button-builder ul.the-icons li {
  float: right;
}
html[dir=rtl].button-builder .color-slelector {
  float: right;
}
html[dir=rtl].button-builder .custom-button-color .form-control {
  border-radius: 0 5px 5px 0;
}
html[dir=rtl].button-builder .custom-button-color .form-control.pull-left {
  float: right;
}
html[dir=rtl].button-builder .hint-label {
  float: right;
  padding: 4px 20px 0 0;
}
html[dir=rtl] #customer-review .owl-item img {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}
html[dir=rtl] .options > div {
  margin: 0 0 8px 8px;
}
html[dir=rtl] .modal-dialog .modal-content .modal-body p a {
  margin-left: 0;
}
html[dir=rtl] .drag-box fieldset .component .form-group .text-lg-left {
  text-align: right !important;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .btn,
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .btn-right,
html[dir=rtl] .drag-box fieldset .component .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group #prependedcheckbox {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group #appendedcheckbox {
  border-right-color: #efefef;
}
html[dir=rtl] .drag-box fieldset .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}
html[dir=rtl] .drag form {
  text-align: right;
}
html[dir=rtl] .drag form input {
  text-align: right;
}
html[dir=rtl] .drag form label {
  text-align: right !important;
}
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .btn,
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .btn-right,
html[dir=rtl] .drag form .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .drag form .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}
html[dir=rtl] .draggable label {
  text-align: right;
}
html[dir=rtl] .form-builder-column .tab-content .tab-pane .theme-form .ui-draggable .input-group > .input-group-prepend > .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .form-group .input-group #buttondropdown,
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group #buttondropdown {
  margin-right: unset;
  margin-left: 15px;
}
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .form-group .input-group .input-group-btn button,
html[dir=rtl] .form-builder-column #pills-tabContent .theme-form .ui-draggable .input-group .input-group-btn button {
  border-radius: 4px !important;
}
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .text-lg-left {
  text-align: right !important;
}
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .btn,
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .btn-right,
html[dir=rtl] .form-horizontal.theme-form fieldset .tab-content .tab-pane .component .form-group .input-group .input-group-prepend .checkbox-radius {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .form-builder .component .form-group .input-group #buttondropdown {
  margin-right: unset;
  margin-left: -1px;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .logo-wrapper {
  -webkit-box-shadow: 9px 0 20px rgba(89, 102, 122, 0.1) !important;
          box-shadow: 9px 0 20px rgba(89, 102, 122, 0.1) !important;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .logo-wrapper a {
  -webkit-transform: translate(-64px);
          transform: translate(-64px);
}
html[dir=rtl] .search-page .nav i {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .product-stts {
  display: inline-block;
}
html[dir=rtl] .product-stts .ms-1 {
  margin-right: 0.25rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .search-form input {
  padding: 10px 70px 10px 10px;
  text-align: right;
}
html[dir=rtl] .icon-hover-bottom .icon-title {
  text-align: right;
}
html[dir=rtl] .icon-lists div i {
  margin: 0 10px 0 15px;
}
html[dir=rtl] .icon-lists ul {
  padding-right: 0;
}
html[dir=rtl] ul.icon-lists {
  padding-right: 30px;
}
html[dir=rtl] .floated-customizer-btn.third-floated-btn {
  left: 35px;
  right: unset;
}
html[dir=rtl] .floated-customizer-btn > span {
  margin-right: 10px;
}
html[dir=rtl] .floated-customizer-panel {
  text-align: right;
  left: -85px;
  right: unset;
}
html[dir=rtl] .floated-customizer-panel.active {
  left: 35px;
  right: unset;
}
html[dir=rtl] .floated-customizer-panel .close-customizer-btn {
  left: 30px;
  right: unset;
}
html[dir=rtl] .floated-customizer-panel ul {
  padding-right: 0;
}
html[dir=rtl] .form-builder-2-header > div nav {
  float: left;
}
html[dir=rtl] .radio label {
  padding-left: 0;
  padding-right: 8px;
}
html[dir=rtl] .radio label::before {
  right: 0;
  margin-right: -20px;
  left: unset;
}
html[dir=rtl] .input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .row h3 {
  text-align: right;
  padding-left: 20px;
  padding-right: unset !important;
}
html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .row h6 {
  text-align: right;
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-header {
  margin-right: 250px;
  margin-left: unset;
  width: calc(100% - 250px);
}
html[dir=rtl] .page-wrapper.compact-wrapper .page-header.close_icon {
  margin-right: 86px;
  margin-left: unset;
  width: calc(100% - 86px);
}
html[dir=rtl] .page-wrapper.modern-type .page-header {
  margin-right: 0;
  width: calc(100% - 0px);
}
html[dir=rtl] .page-wrapper.modern-type .page-header.close_icon {
  margin-right: 0;
  width: calc(100% - 0px);
}
html[dir=rtl] .page-wrapper.modern-type .page-header .header-wrapper .logo-wrapper {
  margin-right: unset;
  margin-left: 30px;
}
html[dir=rtl] .page-wrapper.modern-type .page-header .header-wrapper .toggle-sidebar {
  margin-right: unset;
  margin-left: 15px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper .page-body {
  margin-right: 310px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper .footer {
  margin-left: 55px;
  margin-right: 360px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ .page-body {
  margin-right: 0px;
}
html[dir=rtl] .page-wrapper.modern-type .page-body-wrapper div.sidebar-wrapper.close_icon ~ footer {
  margin-right: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right {
  text-align: right;
}
html[dir=rtl] .page-header .header-wrapper .nav-right .onhover-show-div {
  right: unset;
  left: 0;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .page-header .header-wrapper .nav-right .onhover-show-div {
    left: 12px;
  }
}
@media (max-width: 575.98px) {
  html[dir=rtl] .page-header .header-wrapper .nav-right .cart-dropdown li {
    padding: 10px;
  }
}
html[dir=rtl] .page-header .header-wrapper .nav-right .cart-dropdown .close-circle {
  right: unset;
  left: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li {
  text-align: right;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li .lang-txt {
  margin-left: 0;
  margin-right: 8px;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:before {
  right: 0;
  left: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li .dot {
  left: 17px;
  right: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:before {
  right: 10px;
  left: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:hover .onhover-show-div:after {
  right: 10px;
  left: unset;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li.onhover-dropdown:last-child {
  padding-left: 0;
  padding-right: 0 !important;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:last-child {
  padding-right: 20px;
  padding-left: 0;
}
html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li i.ms-2 {
  margin-right: 0.5rem !important;
  margin-left: unset !important;
}
html[dir=rtl] .page-header .header-wrapper .nav-right .profile-dropdown li svg {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] .page-header .header-wrapper .mobile-sidebar {
  padding-left: 20px;
  padding-right: unset;
}
html[dir=rtl] .page-header.open {
  margin-right: 0;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div {
  right: initial;
  left: -30px;
  padding: 0;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div:before {
  right: inherit !important;
  left: 35px !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div:after {
  right: inherit !important;
  left: 35px !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li {
  margin-right: 0 !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li span.badge {
  margin-left: unset !important;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li span svg {
  margin-right: unset;
  margin-left: 10px;
}
html[dir=rtl] ul.notification-dropdown.onhover-show-div li .notification-icon {
  margin-left: 20px;
  margin-right: unset;
}
html[dir=rtl] .card-body .alert svg ~ p {
  padding-left: unset;
  padding-right: 20px;
}
html[dir=rtl] .alert i {
  margin-left: 5px;
  margin-right: 0;
}
html[dir=rtl] .inverse {
  padding: 13px 65px 13px 20px;
}
html[dir=rtl] .inverse i {
  left: unset;
  right: 0;
}
html[dir=rtl] .alert-primary.inverse:before {
  border-right: 7px solid #337ab7;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-secondary.inverse:before {
  border-right: 7px solid #48A3D7;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-tertiary.inverse:before {
  border-right: 7px solid #C95E9E;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-success.inverse:before {
  border-right: 7px solid #0DA759;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-danger.inverse:before {
  border-right: 7px solid #E44141;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-warning.inverse:before {
  border-right: 7px solid #D77748;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-info.inverse:before {
  border-right: 7px solid #16C7F9;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-light.inverse:before {
  border-right: 7px solid #f4f4f4;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-dark.inverse:before {
  border-right: 7px solid #2c323f;
  right: 54px;
  left: unset;
  border-left: unset;
}
html[dir=rtl] .alert-dismissible .btn-close {
  left: 0;
  right: unset;
}
html[dir=rtl] .alert-dismissible .btn-close span {
  padding-left: 0;
  padding-right: unset;
}
html[dir=rtl] .icofont.icofont-truck {
  display: inline-block;
}
html[dir=rtl] .blog-box .blog-details {
  padding-left: 20px;
  text-align: right;
}
html[dir=rtl] .blog-box .blog-details .blog-social {
  padding-right: 0;
}
html[dir=rtl] .blog-box .blog-details .blog-social li {
  padding-left: 40px;
  padding-right: unset;
}
html[dir=rtl] .blog-box .blog-details .blog-social li:first-child {
  border-left: 1px solid #777777;
  border-right: unset;
  padding-left: 40px;
  padding-right: unset;
}
html[dir=rtl] .blog-box .blog-details .blog-social li + li {
  margin-right: 30px;
  margin-left: unset;
  padding-left: 0;
}
html[dir=rtl] .blog-box .blog-details-main .blog-social li {
  border-left: 1px dotted;
  border-right: unset;
}
html[dir=rtl] .blog-box .blog-details-main .blog-social li:last-child {
  border-left: none;
}
html[dir=rtl] .blog-box .blog-date span {
  font-size: 36px;
  font-weight: 500;
  padding-left: 5px;
}
html[dir=rtl] .comment-box .d-flex img {
  margin-left: 45px;
  margin-right: unset;
}
html[dir=rtl] .comment-box .d-flex h6 {
  text-align: right;
}
html[dir=rtl] .comment-box ul ul {
  margin-right: 135px;
  margin-left: unset;
  padding-right: 0;
}
@media (max-width: 575.98px) {
  html[dir=rtl] .comment-box ul ul {
    margin-right: 30px;
  }
}
html[dir=rtl] .comment-box .comment-social {
  text-align: left;
}
html[dir=rtl] .comment-box .comment-social li {
  padding-left: 0;
  padding-right: 20px;
}
html[dir=rtl] .comment-box .comment-social li:first-child {
  border-left: 1px solid #efefef;
  border-right: unset;
  padding-right: 0;
  padding-left: 20px;
}
html[dir=rtl] .button-builder-wrap .form-group .btn {
  margin-left: 15px;
  margin-right: 0;
  padding: 9px;
}
html[dir=rtl] .button-builder ul.the-icons {
  padding-right: 0 !important;
}
html[dir=rtl] .call-chat-body .chat-box .chat-right-aside .chat .chat-history .caller-img.ps-0 {
  padding-left: 15px !important;
}
html[dir=rtl] .chat-box .user-image {
  float: right;
  margin-left: 5px;
  margin-right: 0;
}
html[dir=rtl] .chat-box .people-list .search i {
  left: 10px;
  right: unset;
}
html[dir=rtl] .chat-box .about {
  float: right;
  padding-right: 10px;
  padding-left: unset;
  text-align: right;
}
html[dir=rtl] .chat-box .chat-menu {
  border-right: 1px solid #f4f4f4;
  border-left: unset;
}
html[dir=rtl] .chat-box .chat-menu .nav {
  padding-right: 0;
}
html[dir=rtl] .chat-box .chat-menu.ps-0 {
  padding-left: 15px !important;
}
html[dir=rtl] .chat-box .chat-right-aside .chat .chat-message .smiley-box {
  margin-left: 0.5rem;
  margin-right: unset;
}
html[dir=rtl] .chat-box .chat-right-aside .chat .chat-message .text-box .btn {
  border-radius: 5px 0 0 5px;
}
html[dir=rtl] .chat-box .chat-right-aside.pe-0 {
  padding-right: 15px !important;
}
html[dir=rtl] .chat-box .chat .chat-message .text-box .input-group-append {
  margin-right: -2px;
}
html[dir=rtl] .chat-msg-box ul {
  padding-right: 0;
}
html[dir=rtl] .chat-left-aside .status-circle {
  left: unset;
  right: 40px;
}
html[dir=rtl] .chat-right-aside .chat .chat-header .chat-menu-icons {
  padding-left: 0;
  padding-right: unset;
}
html[dir=rtl] .chat-right-aside .chat .chat-header .chat-menu-icons.float-sm-right {
  float: left !important;
}
html[dir=rtl] .chat-right-aside .chat .chat-header img {
  float: right;
}
html[dir=rtl] .cke_toolbar,
html[dir=rtl] .cke_toolgroup,
html[dir=rtl] a.cke_button,
html[dir=rtl] .cke_combo_text {
  float: right;
}
html[dir=rtl] .cke_reset_all,
html[dir=rtl] .cke_reset_all *,
html[dir=rtl] .cke_reset_all a,
html[dir=rtl] .cke_reset_all textarea {
  text-align: right;
}
html[dir=rtl] .cke_combo_text {
  padding-left: unset;
  padding-right: 10px;
}
html[dir=rtl] #editor1 #cke_editor1 #cke_1_contents iframe html {
  direction: rtl !important;
}
html[dir=rtl] #clockdiv ul {
  padding-right: 0;
}
html[dir=rtl] .custom-card .card-footer > div + div {
  border-right: 1px solid #efefef;
  border-left: unset !important;
}
html[dir=rtl] .card-social {
  padding-right: 0;
}
html[dir=rtl] .card-absolute .card-header {
  right: 15px;
  left: unset;
}
html[dir=rtl] .dataTables_wrapper .dataTables_filter input[type=search] {
  margin-right: 10px;
  margin-left: 0;
}
html[dir=rtl] .dataTables_wrapper .dataTables_length {
  float: right;
}
html[dir=rtl] .dataTables_wrapper .dataTables_filter {
  margin-left: unset;
  margin-right: 15px;
  float: left;
}
html[dir=rtl] .dataTables_wrapper .dataTables_info {
  float: right;
}
html[dir=rtl] .dataTables_wrapper .advance-5_filter {
  margin-left: 0;
}
html[dir=rtl] .dataTables_wrapper .btn-group button {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .dataTables_wrapper .btn-group button:last-child {
  margin-left: 0;
}
html[dir=rtl] .dataTables_wrapper table.dataTable tbody td.select-checkbox:after,
html[dir=rtl] .dataTables_wrapper table.dataTable tbody th.select-checkbox:after {
  margin-left: 31px;
}
html[dir=rtl] div.dataTables_wrapper div.dataTables_paginate {
  margin-right: 15px !important;
  margin-left: 0 !important;
  float: left;
}
html[dir=rtl] table.dataTable thead > tr > th {
  padding-left: 30px;
  padding-right: 12px;
}
html[dir=rtl] table.dataTable thead > tr > th:before {
  left: 1em !important;
  right: unset;
}
html[dir=rtl] table.dataTable thead > tr > th:after {
  left: 0.5em !important;
  right: unset;
}
html[dir=rtl] table.dataTable thead .sorting:before,
html[dir=rtl] table.dataTable thead .sorting_asc:before,
html[dir=rtl] table.dataTable thead .sorting_desc:before,
html[dir=rtl] table.dataTable thead .sorting_asc_disabled:before,
html[dir=rtl] table.dataTable thead .sorting_desc_disabled:before {
  left: 1em !important;
  right: unset;
}
html[dir=rtl] .dataTables_scrollHeadInner {
  padding-right: unset !important;
  padding-left: 0 !important;
}
html[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:first-child {
  padding-right: 0;
  padding-left: 0;
}
html[dir=rtl] div.table-responsive > div.dataTables_wrapper > div.row > div[class^=col-]:last-child {
  padding-right: 0;
  padding-left: 0;
}
html[dir=rtl] .dt-plugin-buttons button.m-r-10 {
  margin-left: 10px;
  margin-right: 0;
}
html[dir=rtl] .datetime-picker .theme-form .form-group label {
  text-align: left !important;
}
html[dir=rtl] .datetime-picker .theme-form .form-group .input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .datetime-picker .theme-form .form-group .input-group .form-control {
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[dir=rtl] .dropdown-basic .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
html[dir=rtl] .dropdown-basic .separated-btn .btn {
  border-radius: 0.25rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
html[dir=rtl] .dropdown-basic .btn-group {
  margin-right: unset;
  margin-left: 18px;
}
html[dir=rtl] .error-wrapper .col-md-8.offset-md-2 {
  margin: 0 auto !important;
}
html[dir=rtl] .product-price del {
  padding-left: 20px;
  padding-right: 0;
}
html[dir=rtl] .product-box .modal .modal-header .btn-close {
  right: unset;
  left: 15px;
}
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-left.ribbon-info:before,
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-right.ribbon-info:before {
  border-left-color: #007bff;
}
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-left:before,
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-vertical-right:before {
  right: 0;
  left: unset;
  border-left: 15px solid #2a3142;
}
html[dir=rtl] .product-box .ribbon-bookmark.ribbon-info:before {
  border-left-color: transparent;
}
html[dir=rtl] .product-box .ribbon-warning.ribbon-clip:before {
  border-left-color: #ff850d;
  border-right-color: unset;
}
html[dir=rtl] .product-box .ribbon-clip {
  right: -14px;
  left: unset;
}
html[dir=rtl] .product-box .ribbon-clip:before {
  right: 0;
  left: unset;
}
html[dir=rtl] .product-hover ul {
  padding-right: 0;
}
html[dir=rtl] .customer-review .d-flex img {
  margin-left: 25px;
  margin-right: 0;
}
html[dir=rtl] .nav {
  padding-right: 0;
}
html[dir=rtl] .custom-tab {
  padding: 4px;
}
html[dir=rtl] .custom-file-label::after {
  left: 0;
  right: unset;
}
html[dir=rtl] .wizard-4 ul.anchor {
  float: right;
}
html[dir=rtl] .wizard-4 .step-container {
  clear: unset;
}
html[dir=rtl] .wizard-4 .form-group {
  text-align: right;
}
html[dir=rtl] .wizard-4 .action-bar .btn {
  float: left;
}
html[dir=rtl] .f1 .f1-buttons {
  text-align: left;
}
html[dir=rtl] .f1 .f1-steps .f1-step {
  float: right;
}
html[dir=rtl] .jstree-default .jstree-node {
  margin-left: unset;
  margin-right: 24px;
  background-position: -292px -4px;
  background-repeat: repeat-y;
}
html[dir=rtl] .img-cropper .btn-group {
  margin-right: 0;
  margin-left: 10px;
}
html[dir=rtl] .img-cropper .btn-group button:last-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
html[dir=rtl] .img-cropper .btn-group button:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
html[dir=rtl] .docs-toggles .btn-group .btn {
  border-radius: 0;
}
html[dir=rtl] .docs-toggles .btn-group .btn:last-child {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
html[dir=rtl] .docs-toggles .btn-group .btn:first-child {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
html[dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle) > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .jsgrid-button + .jsgrid-button {
  margin-right: 5px;
  margin-left: unset;
}
html[dir=rtl] .list-group {
  padding-right: 0;
}
html[dir=rtl] .list-group i,
html[dir=rtl] .list-group img {
  margin-left: 10px;
  margin-right: 0;
}
html[dir=rtl] .authentication-box {
  text-align: right;
}
html[dir=rtl] .mega-horizontal .offset-sm-3 {
  margin-right: 25%;
  margin-left: unset;
}
html[dir=rtl] .navs-icon svg {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .navs-icon .fa {
  margin-left: 10px;
  margin-right: unset;
}
html[dir=rtl] .nav-list .nav-list-disc {
  text-align: right;
  padding-right: 0;
}
html[dir=rtl] .pl-navs-inline {
  padding-right: 30px !important;
  padding-left: 0 !important;
}
html[dir=rtl] .onhover-dropdown.navs-dropdown:hover .onhover-show-div:before {
  left: 77px;
  right: unset;
}
html[dir=rtl] .onhover-dropdown.navs-dropdown:hover .onhover-show-div:after {
  left: 77px;
  right: unset;
}
html[dir=rtl] .navs-dropdown .onhover-show-div .navs-icon li {
  text-align: right;
}
html[dir=rtl] .navs-icon.default-according.style-1 li button[aria-expanded=true]:before {
  left: 2px;
  right: unset;
}
html[dir=rtl] .navs-icon.default-according.style-1 li button[aria-expanded=false]:before {
  left: 2px;
  right: unset;
}
html[dir=rtl] .search-form .form-group:after {
  right: 53px;
  left: unset;
}
html[dir=rtl] .search-form .form-group:before {
  right: 82px;
  left: unset;
}
html[dir=rtl] .order-history table tbody tr td .product-name .order-process .order-process-circle:before {
  right: -15px;
  left: unset;
}
html[dir=rtl] .order-history table th:first-child,
html[dir=rtl] .order-history table td:first-child {
  text-align: right;
}
html[dir=rtl] .product-page-details span {
  padding-right: 15px;
  padding-left: 0;
}
html[dir=rtl] .m-checkbox-inline label {
  margin-left: 20px;
  margin-right: 0;
}
html[dir=rtl] .form-label-align-right label {
  text-align: left;
}
html[dir=rtl] .br-theme-bars-reversed .br-widget .br-current-rating,
html[dir=rtl] .br-theme-bars-movie .br-widget .br-current-rating,
html[dir=rtl] .br-theme-bars-1to10 .br-widget .br-current-rating,
html[dir=rtl] .br-theme-bars-square .br-widget .br-current-rating {
  float: right;
}
html[dir=rtl] .br-theme-bars-reversed .br-widget a,
html[dir=rtl] .br-theme-bars-movie .br-widget a,
html[dir=rtl] .br-theme-bars-1to10 .br-widget a,
html[dir=rtl] .br-theme-bars-square .br-widget a {
  float: right;
}
html[dir=rtl] .ribbon-left {
  right: auto;
  left: -2px;
}
html[dir=rtl] .ribbon-vertical-left {
  right: auto;
  left: 12px;
}
html[dir=rtl] .ribbon-vertical-right {
  right: auto;
  left: 12px;
}
html[dir=rtl] .star-ratings ul.search-info {
  padding-right: 0;
}
html[dir=rtl] .star-ratings ul.search-info li + li {
  border-right: 1px solid #a5afc6;
  border-left: unset;
  padding-right: 8px;
  padding-left: 0;
  margin-right: 8px;
  margin-left: 0;
}
html[dir=rtl] .select2-container--default .select2-selection--single .select2-selection__arrow {
  left: 10px !important;
  right: unset !important;
}
html[dir=rtl] .select2-container--default .select2-selection--multiple .select2-selection__choice {
  float: right;
}
html[dir=rtl] .selection .select2-selection .select2-search__field {
  text-align: right;
}
html[dir=rtl] .select2-results__option {
  text-align: right;
}
html[dir=rtl] .editor-statusbar {
  text-align: left;
}
html[dir=rtl] .editor-statusbar span {
  margin-right: 1em;
  margin-left: unset;
}
html[dir=rtl] .CodeMirror-scroll {
  margin-left: -30px;
  margin-right: unset;
}
html[dir=rtl] .primary-color ul,
html[dir=rtl] .yellow-color ul {
  padding-right: 0;
}
html[dir=rtl] .u-step-desc {
  text-align: right;
}
html[dir=rtl] .u-step-number {
  right: 20px;
  left: unset;
}
html[dir=rtl] .u-step-number ~ .u-step-desc {
  margin-right: 50px;
}
html[dir=rtl] .u-pearl:after {
  left: 0;
  right: unset;
}
html[dir=rtl] .u-pearl:before {
  right: 0;
  left: unset;
}
html[dir=rtl] .u-step-icon {
  float: right;
  margin-left: 0.5em;
  margin-right: unset;
}
html[dir=rtl] .list-icons {
  padding-right: 0;
}
html[dir=rtl] .card-header.note-toolbar .note-color .dropdown-toggle {
  padding-right: 5px;
}
html[dir=rtl] .card-header.note-toolbar .form-group.draggable {
  text-align: right;
}
html[dir=rtl] .card-header.note-toolbar .form-check {
  padding-right: 24px;
  padding-left: unset;
}
html[dir=rtl] .card-header.note-toolbar form .custom-control {
  padding-right: 24px;
  padding-left: unset;
}
html[dir=rtl] .card-header.note-toolbar form .custom-control .custom-control-label::after {
  right: 0;
  left: unset;
}
html[dir=rtl] .card-header.note-toolbar form .custom-control .custom-control-label::before {
  right: 0;
  left: unset;
}
html[dir=rtl] .dropdown-toggle::after {
  margin-right: 0.255em;
  margin-left: unset;
}
html[dir=rtl] .tabbed-card ul {
  left: 22px;
  right: unset;
}
html[dir=rtl] .checkbox label {
  padding-right: 8px;
  padding-left: unset;
}
html[dir=rtl] .checkbox label::before {
  right: 0;
  left: unset;
  margin-right: -16px;
  margin-left: unset;
}
html[dir=rtl] .checkbox-wrapper li label,
html[dir=rtl] .radio-wrapper li label {
  padding-right: 0;
}
html[dir=rtl] .radio label::after {
  right: 5px;
  left: unset;
  margin-right: -20px;
  margin-left: unset;
}
html[dir=rtl] .cd-timeline-img i {
  right: 40%;
  left: unset;
  margin-right: -12px;
  margin-left: unset;
}
html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content {
  float: left;
  border-left: 5px solid #eeeeee;
  border-right: unset;
}
html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
  right: auto;
  left: 122%;
  text-align: left;
}
html[dir=rtl] .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
  right: auto;
  left: 100%;
  border: 15px solid transparent;
  border-left: 15px solid #f4f4f4;
}
html[dir=rtl] .cd-timeline-content .cd-date {
  right: 122%;
  left: unset;
}
html[dir=rtl] .introjs-tooltip {
  right: 0;
  left: unset;
}
html[dir=rtl] .introjs-helperNumberLayer {
  right: -16px;
  left: unset;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltiptext {
  text-align: right;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons {
  text-align: left;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-skipbutton {
  margin-left: 5px;
  margin-right: unset;
}
html[dir=rtl] .introjs-tooltipReferenceLayer .introjs-tooltip .introjs-tooltipbuttons .introjs-nextbutton {
  border-radius: 0.2em 0 0 0.2em;
}
html[dir=rtl] .list-inline-item:not(:last-child) {
  margin-left: 0.5rem;
  margin-right: unset;
}
html[dir=rtl] .like-comment ul.list-inline {
  padding-right: 0;
}
html[dir=rtl] .typeahead {
  text-align: right;
}
html[dir=rtl] .listing ul {
  padding-right: 0;
}
html[dir=rtl] .listing ul .icofont {
  float: right;
}
html[dir=rtl] .gallery .hover-5 img {
  margin-right: 30px;
}
html[dir=rtl] .gallery .hover-5:hover img {
  margin-right: 0;
}
html[dir=rtl] .lg-outer {
  text-align: right;
}
html[dir=rtl] .lg-toolbar .lg-icon {
  float: left;
}
html[dir=rtl] #lg-counter {
  padding-right: 20px;
  padding-left: unset;
  float: right;
}
html[dir=rtl] .chart-widget-top .text-end {
  text-align: left !important;
}
html[dir=rtl] .chart-widget-top .num .ms-1 {
  margin-right: 0.25rem !important;
}
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-sm .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-md .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-lg .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xl .list-group-item:first-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xxl .list-group-item:first-child {
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0;
}
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-sm .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-md .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-lg .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xl .list-group-item:last-child,
html[dir=rtl] .horizontal-list-wrapper .list-group-horizontal-xxl .list-group-item:last-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 0;
  border-left-width: 1px;
}
html[dir=rtl] .bar-chart-widget .earning-details i {
  left: -240px;
}
html[dir=rtl] .bar-chart-widget .bottom-content .b-r-light {
  border-left: 1px solid #eeeeee !important;
  border-right: unset !important;
}
html[dir=rtl] .bar-chart-widget .bottom-content .num .ms-1 {
  margin-right: 0.25rem !important;
}
html[dir=rtl] .chart-vertical-center #myDoughnutGraph,
html[dir=rtl] .chart-vertical-center #myPolarGraph {
  width: auto !important;
}
html[dir=rtl] .crm-activity ul.dates li + li {
  border-right: 1px solid #ddd;
  padding-right: 10px;
  margin-right: 5px;
  border-left: unset;
  padding-left: unset;
  margin-left: unset;
}
html[dir=rtl] div.dt-buttons {
  float: right;
}
html[dir=rtl] .dt-button-collection {
  left: -39px !important;
}
html[dir=rtl] .dropzone .dz-preview .dz-error-mark,
html[dir=rtl] .dropzone .dz-preview .dz-success-mark {
  right: 50%;
  margin-right: -27px;
  margin-left: unset;
  left: unset;
}
html[dir=rtl] .page-wrapper .page-body-wrapper footer {
  margin-right: 250px;
  margin-left: unset;
}
html[dir=rtl] .page-wrapper .page-body-wrapper footer p i {
  margin-right: 5px;
  margin-left: unset;
}
html[dir=rtl] .footer-copyright {
  text-align: right;
}
html[dir=rtl] .footer-links {
  text-align: left;
}
html[dir=rtl] .page-item:first-child .page-link {
  margin-right: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none;
}
html[dir=rtl] .page-item:last-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .popover.top > .arrow:after,
html[dir=rtl] .popover.bottom > .arrow:after {
  margin-right: -10px;
  margin-left: unset;
}
html[dir=rtl] .bootstrap-touchspin > .input-group-append > .btn,
html[dir=rtl] .bootstrap-touchspin > .input-group-append > .input-group-text {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
html[dir=rtl] .bootstrap-touchspin > .form-control:not(:last-child),
html[dir=rtl] .bootstrap-touchspin > .custom-select:not(:last-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
html[dir=rtl] .typeahead span.twitter-typeahead .tt-suggestion {
  text-align: right;
}
html[dir=rtl] .typeahead .league-name {
  text-align: right;
  margin-right: 5px;
}
html[dir=rtl] .follow .text-md-right {
  text-align: left !important;
}
html[dir=rtl] .jvectormap-zoomin,
html[dir=rtl] .jvectormap-zoomout,
html[dir=rtl] .jvectormap-goback {
  right: 10px;
  left: unset;
}
html[dir=rtl] .customizer-links {
  right: unset;
  left: 0;
}
html[dir=rtl] .customizer-links > .nav {
  padding-right: 10px;
}
html[dir=rtl] .customizer-links .nav-link:after {
  right: -10px;
  left: unset;
  border-width: 5px 5px 5px 0;
  border-left-color: unset;
  border-right-color: #cac6e3;
}
html[dir=rtl] .customizer-links .nav-link span {
  right: unset;
  left: -60px;
}
html[dir=rtl] .customizer-links .nav-link:hover span {
  left: 48px;
}
html[dir=rtl] .customizer-links.open {
  right: unset;
  left: 330px;
  border-radius: 0 8px 8px 0;
}
html[dir=rtl] .customizer-contain {
  width: 330px;
  right: unset;
  left: -330px;
}
html[dir=rtl] .customizer-contain.open {
  right: unset;
  left: 0px;
}
html[dir=rtl] .customizer-contain .customizer-header .icon-close {
  left: unset;
  right: 30px;
}
html[dir=rtl] .box-layout.page-wrapper.horizontal-wrapper .page-header {
  padding-right: 250px;
  padding-left: unset;
}
html[dir=rtl] .box-layout.page-wrapper.horizontal-wrapper .page-header.open {
  padding-right: 0;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
  margin-left: unset;
  margin-right: 230px;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
  width: 1280px;
  padding-left: 0;
  left: 50%;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-header {
  margin-left: auto;
  margin-right: auto;
  padding-left: unset;
  padding-right: 230px;
}
html[dir=rtl] .box-layout.page-wrapper.compact-wrapper .page-header.close_icon {
  padding-right: 100px;
}
@media screen and (max-width: 1660px) {
  html[dir=rtl] .caller-img {
    left: unset;
    right: 15px;
  }
}
@media only screen and (max-width: 1366px) {
  html[dir=rtl] .profile-dropdown.onhover-show-div {
    right: unset !important;
    left: 0 !important;
  }
  html[dir=rtl] .product-wrapper .product-grid .feature-products {
    margin-right: 0;
  }
  html[dir=rtl] .product-grid {
    margin-right: 0;
  }
  html[dir=rtl] .d-none-productlist {
    margin-left: 10px;
    margin-right: unset;
  }
  html[dir=rtl] .photos ul li:nth-child(3n) {
    margin-left: 15px;
    margin-right: unset;
  }
  html[dir=rtl] .chat-left-aside .status-circle {
    left: unset;
    right: 22px;
  }
}
@media (min-width: 1200px) {
  html[dir=rtl] .float-xl-end {
    float: left !important;
  }
  html[dir=rtl] .chat-box .pe-xl-0 {
    padding-left: 0 !important;
    padding-right: 15px !important;
  }
  html[dir=rtl] .chat-box .ps-xl-0 {
    padding-right: 0 !important;
    padding-left: 15px !important;
  }
  html[dir=rtl] .pixelstrap ul a:hover .sub-arrow, html[dir=rtl] .pixelstrap ul a:focus .sub-arrow, html[dir=rtl] .pixelstrap ul a:active .sub-arrow, html[dir=rtl] .pixelstrap ul a.highlighted .sub-arrow {
    left: 3px;
    right: unset;
  }
}
@media (min-width: 992px) {
  html[dir=rtl] .text-lg-start {
    text-align: right !important;
  }
}
@media only screen and (max-width: 1199.98px) {
  html[dir=rtl] .jkanban-container .btn {
    margin-left: unset;
    margin-right: 20px;
  }
  html[dir=rtl] .link-section > div > h6:before {
    right: unset;
    left: 10px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul li .submenu li:first-child {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
    left: 45px;
  }
  html[dir=rtl] .email-wrap .row .col-xl-6 {
    padding-right: 15px;
  }
  html[dir=rtl] .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-left: 15px;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li {
    text-align: right;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar .main-menu > li a i {
    margin-right: unset;
    margin-left: 10px;
  }
  html[dir=rtl] .email-wrap .email-app-sidebar ul {
    padding-right: 0;
  }
  html[dir=rtl] .email-wrap .email-content .email-top .user-emailid:after {
    float: left;
  }
  html[dir=rtl] .email-wrap .email-right-aside .email-body .pe-0 {
    padding-right: unset !important;
  }
  html[dir=rtl] .email-wrap .email-right-aside .radius-left {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-radius: 4px;
  }
  html[dir=rtl] .chat-menu-icons {
    text-align: left;
  }
  html[dir=rtl] .chat-box .chat-history .row .col-sm-7 {
    padding-left: 0 !important;
    padding-right: unset !important;
  }
  html[dir=rtl] .chat-menu {
    left: 0;
    right: unset;
  }
}
@media only screen and (max-width: 991.98px) {
  html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
    left: 20px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon {
    -webkit-transform: translate(285px);
            transform: translate(285px);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper.close_icon:hover {
    -webkit-transform: translate(285px);
            transform: translate(285px);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper div.sidebar-wrapper .logo-wrapper .back-btn {
    float: left;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar {
    margin-right: 0;
    border-right: none;
    padding-right: 8px;
    border-left: none;
    margin-left: 8px;
    padding-left: 8px;
  }
  html[dir=rtl] .page-wrapper.compact-sidebar .page-header {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-header {
    padding-right: 0;
  }
  html[dir=rtl] .page-wrapper.material-icon .page-body-wrapper .page-body {
    padding-right: 15px;
  }
  html[dir=rtl] .faq-accordion .faq-header svg {
    left: 20px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-logo-wrapper .logo-wrapper img {
    margin-left: 15px;
    margin-right: unset;
  }

  

  html[dir=rtl] .page-body-wrapper .page-body,
  html[dir=rtl] .page-body-wrapper footer {
    margin-right: 0 !important;
  }
  html[dir=rtl] .main .langChoice {
    left: 20px;
  }
  html[dir=rtl] .cd-timeline-img i {
    right: 50% !important;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .page-title .breadcrumb {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 60px);
  }
  html[dir=rtl] .page-wrapper .page-header {
    margin-right: 0;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header {
    margin-right: 0 !important;
    margin-left: unset !important;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-body-wrapper.sidebar-wrapper.close_icon {
    -webkit-transform: translate(285px);
            transform: translate(285px);
  }
  html[dir=rtl] .card-body .value-left {
    margin-left: 20px;
  }
  html[dir=rtl] .bookmark ul li .search-form .form-control-search {
    left: -127px;
  }
  html[dir=rtl] .card .card-header .card-header-right {
    left: 15px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  html[dir=rtl] .page-wrapper.compact-wrapper.material-type .page-body-wrapper div.sidebar-wrapper {
    left: unset;
    right: 0;
  }
}
@media (min-width: 768px) {
  html[dir=rtl] .float-md-end {
    float: left !important;
  }
  html[dir=rtl] .income-wrap .ps-md-0 {
    padding-left: 30px !important;
    padding-right: 0 !important;
  }
  html[dir=rtl] .text-md-end {
    text-align: left !important;
  }
  html[dir=rtl] .offset-md-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  html[dir=rtl] .text-md-start {
    text-align: right !important;
  }
  html[dir=rtl] .ecommerce-widget .text-md-end {
    text-align: left !important;
  }
  html[dir=rtl] .ecommerce-widget .text-md-end ul {
    text-align: left;
  }
}
@media only screen and (max-width: 767.98px) {
  html[dir=rtl] .list-persons .profile-mail .email-general ul {
    padding-left: 0;
  }
  html[dir=rtl] .product-wrapper.sidebaron .product-grid .product-wrapper-grid {
    margin-right: 0;
  }
  html[dir=rtl] .product-sidebar {
    -webkit-transform: translateX(300px) scaleX(1);
            transform: translateX(300px) scaleX(1);
  }
  html[dir=rtl] .product-sidebar.open {
    -webkit-transform: translateX(0px) scaleX(1);
            transform: translateX(0px) scaleX(1);
  }
  html[dir=rtl] .feature-products .filter-toggle {
    margin-right: 10px;
    margin-left: unset;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
    left: 25px !important;
  }
  html[dir=rtl] .ecommerce-widget .text-md-right {
    text-align: left !important;
  }
  html[dir=rtl] .bar-chart-widget .earning-details i {
    left: -147px !important;
  }
}
@media (min-width: 576px) {
  html[dir=rtl] .offset-sm-3 {
    margin-right: 25%;
    margin-left: unset;
  }
  html[dir=rtl] .text-sm-end {
    text-align: left !important;
  }
  html[dir=rtl] .float-sm-end {
    float: left !important;
  }
  html[dir=rtl] .me-sm-2 {
    margin-left: 0.5rem !important;
    margin-right: unset !important;
  }
}
@media only screen and (max-width: 575.98px) {
  html[dir=rtl] .todo .todo-list-wrapper .mark-all-tasks {
    left: 15px;
  }
  html[dir=rtl] .list-persons .nav-pills .nav-link .d-flex .flex-grow-1 {
    text-align: right;
  }
  html[dir=rtl] .mega-menu {
    padding-left: unset;
    padding-right: 15px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right > ul li {
    margin-right: unset;
    margin-left: 1px;
  }
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .search-full .form-group .close-search {
    left: 12px;
  }
  html[dir=rtl] .page-wrapper.compact-wrapper .page-header .header-wrapper .toggle-sidebar, html[dir=rtl] .page-wrapper.compact-sidebar .page-header .header-wrapper .toggle-sidebar {
    margin-left: 8px;
    padding-left: 8px;
    margin-right: unset;
    padding-right: 8px;
    border-right: unset;
    border-left: none;
  }
  html[dir=rtl] .translate_wrapper.active .more_lang.active {
    left: unset;
    right: 12px;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right > ul > li:last-child {
    padding-right: 10px;
  }
  html[dir=rtl] .page-header .header-wrapper .nav-right.right-header ul.notification-dropdown {
    left: unset !important;
    right: 15px !important;
  }
  html[dir=rtl] .faq-accordion .faq-header svg {
    left: 15px;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons {
    left: 15px !important;
  }
  html[dir=rtl] .user-profile .hovercard .user-image .share-icons li {
    margin-left: 0px;
  }
  html[dir=rtl] .main .langChoice {
    left: 15px;
  }
  html[dir=rtl] .page-wrapper .search-form .form-group {
    margin-left: 0;
  }
  html[dir=rtl] .card-body .value-left {
    margin-left: 15px;
  }
}
@media only screen and (max-width: 480px) {
  html[dir=rtl] .page-wrapper .page-header .header-wrapper .nav-right .onhover-show-div {
    right: unset;
    left: 12px;
  }
  html[dir=rtl] .knob-chart .chart-clock-main {
    width: unset;
  }
  html[dir=rtl] .knob-chart .chart-clock-main .clock-small {
    right: 32px;
  }
  html[dir=rtl] .knob-chart .chart-clock-main .clock-large {
    right: 0;
    left: unset;
  }
  html[dir=rtl] .knob-chart .chart-clock-main .clock-medium {
    right: 32px;
    left: unset;
  }
}
@media only screen and (max-width: 420px) {
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    right: 112px;
  }
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  html[dir=rtl] .page-wrapper.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    right: 80px;
  }
}
@media only screen and (max-width: 360px) {
  html[dir=rtl] .prooduct-details-box .close {
    left: 0;
  }
  html[dir=rtl] .social-app-profile .hovercard .user-image .avatar {
    margin-top: -47px;
  }
  html[dir=rtl] .social-app-profile .hovercard .user-image .avatar img {
    width: 85px;
    height: 85px;
  }
  html[dir=rtl] .social-chat .other-msg {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1460px) {
  html[dir=rtl] .sidebar-wrapper .sidebar-main .sidebar-links .simplebar-wrapper .simplebar-mask .simplebar-content-wrapper .simplebar-content > li {
    margin-left: 8px;
    margin-right: unset;
  }
}

/**=====================
    5.8 Rtl CSS Ends
==========================**/
/**=====================
  5.9 Box-layout CSS start
==========================**/
.box-layout {
  background-image: url(../../images/boxbg.jpg);
  /* background-image: url(../../images); */
  background-blend-mode: overlay;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.logo-wrapper img.img-fluid {
  max-width: 145px !important;
}

.logo img.img-fluids {
  width: 170px !important;
}


@media only screen and (min-width: 1280px) {
  .box-layout .page-wrapper .page-header .header-wrapper .nav-right > ul > li .form-group .Typeahead .u-posRelative input {
    width: 96px !important;
  }
  .box-layout .learning-filter {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .box-layout .artist-table .appointment-table.customer-table table tr td:last-child {
    min-width: 100px;
  }
  .box-layout .md-sidebar .md-sidebar-toggle {
    display: block;
  }
  .box-layout .md-sidebar .md-sidebar-aside {
    position: absolute;
    top: 40px;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 3;
    width: 280px;
  }
  .box-layout .md-sidebar .md-sidebar-aside.open {
    opacity: 1;
    visibility: visible;
  }
  .box-layout .job-sidebar {
    background-color: var(--white);
    padding: 20px;
  }
  .box-layout .widget-1.mb-xl-0 {
    margin-bottom: 25px !important;
  }
  .box-layout .radial-progress-card .radial-chart-wrap {
    margin-right: -10px;
  }
  .box-layout .currency-widget div[class*=bg-light-] {
    display: none;
  }
  .box-layout .box-order .order-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }
  .box-layout .activity-card .appointment-table.customer-table {
    height: 187px;
  }
  .box-layout .transaction-table tr td:first-child {
    min-width: 150px;
  }
  .box-layout .welcome-card .welcome-img {
    height: 170px;
    bottom: -22px;
  }
  .box-layout .purchase-card img {
    margin: -210px auto 0;
    width: 80%;
  }
  .box-layout .purchase-card.discover {
    margin-top: 90px;
  }
  .box-layout .purchase-card.discover img {
    margin-top: -90px;
    width: 64%;
  }
  .box-layout .apache-cotainer-large > div,
  .box-layout .apache-cotainer-large canvas,
  .box-layout #line-visual > div,
  .box-layout #line-visual canvas,
  .box-layout #line-visulH > div,
  .box-layout #line-visulH canvas {
    width: 100% !important;
  }
  .box-layout .page-wrapper.box-layout, .box-layout.page-wrapper.box-layout {
    padding-top: 40px;
    margin-top: 0 !important;
  }
  .box-layout .page-wrapper.compact-wrapper, .box-layout.page-wrapper.compact-wrapper {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper {
    width: 230px;
    top: 40px;
    border-radius: 10px 0 0 10px;
    overflow: hidden;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
    width: 1200px;
    left: 48%;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .footer-fix, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon ~ .footer-fix {
    padding-left: 0;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
    height: calc(100vh - 180px);
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .logo-wrapper {
    -webkit-box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
            box-shadow: 0 8px 6px -6px rgba(89, 102, 122, 0.1);
  }

  

  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
    margin-top: 10px;
    margin-bottom: 20px;
    height: calc(100vh - 182px);
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body {
    min-height: calc(100vh - 130px);
    margin-top: 0;
    top: 105px;
    overflow: scroll;
    max-height: calc(100vh - 220px);
    padding-bottom: 80px;
    margin-left: 230px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(106, 113, 133, 0.3);
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
    width: 6px;
  }
  .box-layout .page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.compact-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(68, 102, 242, 0.15);
  }
  .box-layout .page-wrapper.compact-sidebar .page-header, .box-layout.page-wrapper.compact-sidebar .page-header {
    padding-left: 148px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper {
    top: 40px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper.close_icon {
    display: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 166px);
    margin: 0;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 470px;
    top: 40px;
    height: calc(100vh - 84px);
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li a.active ~ .mega-menu-container {
    -webkit-animation: unset;
            animation: unset;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .footer, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .footer {
    margin-left: 0;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body {
    min-height: calc(100vh - 90px);
    margin-top: 0;
    top: 80px;
    overflow: scroll;
    max-height: calc(100vh - 170px);
    padding-bottom: 80px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(106, 113, 133, 0.3);
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar {
    width: 6px;
  }
  .box-layout .page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.compact-sidebar .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(68, 102, 242, 0.15);
  }
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-header, .box-layout.page-wrapper.compact-sidebar.compact-small .page-header {
    padding-left: 90px;
  }
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-header.close_icon, .box-layout.page-wrapper.compact-sidebar.compact-small .page-header.close_icon {
    padding-left: 0;
  }
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout .page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container, .box-layout.page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .sidebar-submenu,
  .box-layout.page-wrapper.compact-sidebar.compact-small .page-body-wrapper div.sidebar-wrapper .sidebar-main .sidebar-links li .mega-menu-container {
    left: 410px;
  }
  .box-layout .page-wrapper.horizontal-wrapper:before, .box-layout.page-wrapper.horizontal-wrapper:before {
    display: none;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper {
    margin-top: 40px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body {
    margin-top: 106px;
    min-height: calc(100vh - 310px);
    max-height: calc(100vh - 242px);
    overflow: scroll;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(106, 113, 133, 0.3);
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar {
    width: 6px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .page-body::-webkit-scrollbar-thumb {
    background-color: rgba(68, 102, 242, 0.15);
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .footer, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .footer {
    padding-left: 15px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper {
    width: 1280px;
    top: 75px;
    margin: 0;
    overflow-x: unset;
    position: relative;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main #sidebar-menu, .box-layout.page-wrapper.horizontal-wrapper .page-body-wrapper .sidebar-wrapper .sidebar-main #sidebar-menu {
    left: 0%;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    top: 125px;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-header, .box-layout.page-wrapper.horizontal-wrapper .page-header {
    padding-left: 0;
  }
  .box-layout .page-wrapper.modern-type .page-header, .box-layout.page-wrapper.modern-type .page-header {
    padding-left: 0px;
  }
  .box-layout .page-wrapper.modern-type .page-header .header-wrapper .left-header, .box-layout.page-wrapper.modern-type .page-header .header-wrapper .left-header {
    width: 36%;
  }
  .box-layout .page-wrapper.modern-type .page-header .header-wrapper .nav-right, .box-layout.page-wrapper.modern-type .page-header .header-wrapper .nav-right {
    width: 46%;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .page-body, .box-layout.page-wrapper.modern-type .page-body-wrapper .page-body {
    margin-left: 260px;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper {
    top: 120px;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper .sidebar-main .sidebar-links {
    height: calc(100vh - 311px);
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon .sidebar-main .sidebar-links {
    height: calc(100vh - 316px);
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon ~ footer, .box-layout.page-wrapper.modern-type .page-body-wrapper .sidebar-wrapper.close_icon ~ footer {
    width: 1280px;
    left: 50%;
    margin-left: 0;
  }
  .box-layout .page-wrapper.modern-type .page-body-wrapper .footer, .box-layout.page-wrapper.modern-type .page-body-wrapper .footer {
    margin-left: 0;
  }
  .box-layout .page-wrapper .page-header, .box-layout.page-wrapper .page-header {
    max-width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    padding-left: 230px;
    margin-top: 40px;
    border-radius: 10px;
    width: unset;
  }
  .box-layout .page-wrapper .page-header.close_icon, .box-layout.page-wrapper .page-header.close_icon {
    padding-left: 100px;
    margin-left: auto;
    width: calc(100% - 0px);
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }
  .box-layout .page-wrapper .page-header .header-wrapper, .box-layout.page-wrapper .page-header .header-wrapper {
    border-radius: 10px 10px 0 0;
  }
  .box-layout .page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right,
  .box-layout .page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header, .box-layout.page-wrapper.horizontal-wrapper .page-header .header-wrapper .nav-right,
  .box-layout.page-wrapper.horizontal-wrapper .page-header .header-wrapper .left-header {
    width: 36%;
  }
  .box-layout .page-wrapper.material-type .page-body-wrapper, .box-layout.page-wrapper.material-type .page-body-wrapper {
    margin-top: 0;
  }
  .box-layout .page-wrapper.material-type .page-body-wrapper .page-body, .box-layout.page-wrapper.material-type .page-body-wrapper .page-body {
    margin-top: 0;
  }
  .box-layout .page-wrapper.material-type .page-body-wrapper .sidebar-wrapper, .box-layout.page-wrapper.material-type .page-body-wrapper .sidebar-wrapper {
    top: 0;
  }
  .box-layout .page-wrapper.material-icon .page-header.close_icon, .box-layout.page-wrapper.material-icon .page-header.close_icon {
    margin-top: 40px !important;
  }
  .box-layout .page-wrapper.material-icon .page-body-wrapper, .box-layout.page-wrapper.material-icon .page-body-wrapper {
    margin-top: 0;
  }
  .box-layout .page-wrapper.material-icon .page-body-wrapper .page-body, .box-layout.page-wrapper.material-icon .page-body-wrapper .page-body {
    top: 0;
    min-height: calc(100vh - 170px);
    padding-bottom: 40px;
  }
  .box-layout .page-wrapper.advance-layout .page-header, .box-layout.page-wrapper.advance-layout .page-header {
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
  }
  .box-layout .page-wrapper.advance-layout .page-body-wrapper, .box-layout.page-wrapper.advance-layout .page-body-wrapper {
    margin-top: 0;
  }
  .box-layout .page-wrapper .page-body-wrapper, .box-layout.page-wrapper .page-body-wrapper {
    width: 1280px;
    -webkit-box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
            box-shadow: 4px 11px 25px rgba(0, 0, 0, 0.07);
    margin: 0 auto;
    overflow: hidden;
  }
  .box-layout .page-wrapper .page-body-wrapper .todo .todo-list-wrapper #todo-list li .task-responsive, .box-layout.page-wrapper .page-body-wrapper .todo .todo-list-wrapper #todo-list li .task-responsive {
    min-width: 1087px;
    overflow: auto;
  }
  .box-layout .page-wrapper .page-body-wrapper .active-order-table, .box-layout.page-wrapper .page-body-wrapper .active-order-table {
    max-width: 443px;
    overflow: auto;
  }
  .box-layout .page-wrapper .page-body-wrapper .active-order-table table tbody tr td p, .box-layout.page-wrapper .page-body-wrapper .active-order-table table tbody tr td p {
    width: 100px;
  }
  .box-layout .page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.gradient-line-1:after, .box-layout.page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.gradient-line-1:after {
    height: 57px;
    bottom: -64px;
  }
  .box-layout .page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.small-line:after, .box-layout.page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.small-line:after {
    height: 36px;
    bottom: -43px;
  }
  .box-layout .page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.medium-line:after, .box-layout.page-wrapper .page-body-wrapper .activity .d-flex .gradient-round.medium-line:after {
    height: 40px;
    bottom: -48px;
  }
  .box-layout .page-wrapper .page-body-wrapper footer, .box-layout.page-wrapper .page-body-wrapper footer {
    width: 1280px;
    margin: 0 auto;
    padding-left: 230px;
    margin-bottom: 40px !important;
    position: fixed;
    left: 50%;
    z-index: 7;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    border-radius: 0 0 10px;
  }
  .box-layout .page-wrapper .page-body-wrapper .blockquote-footer, .box-layout.page-wrapper .page-body-wrapper .blockquote-footer {
    margin-left: 0;
    width: 100%;
  }
  .box-layout .page-wrapper .page-body-wrapper .footer-fix, .box-layout.page-wrapper .page-body-wrapper .footer-fix {
    padding-left: 230px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 300px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content > div, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content > div {
    z-index: 8;
    background-color: rgba(255, 255, 255, 0.75);
    background-blend-mode: overlay;
    width: 100%;
    padding: 30px;
    left: 15px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-content button, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-content button {
    width: 40%;
    font-size: 14px;
    margin: 0 auto;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons ul li, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .call-icons ul li {
    border: 1px solid #717171;
    width: 50px;
    height: 50px;
    padding: 8px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img img, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .receiver-img img {
    width: 38%;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-box .chat-history .total-time h2, .box-layout.page-wrapper .page-body-wrapper .chat-box .chat-history .total-time h2 {
    font-size: 28px;
    color: #717171;
  }
  .box-layout .page-wrapper .page-body-wrapper canvas#myLineCharts, .box-layout.page-wrapper .page-body-wrapper canvas#myLineCharts {
    width: 100%;
  }
  .box-layout .page-wrapper .page-body-wrapper .chat-right-aside, .box-layout.page-wrapper .page-body-wrapper .chat-right-aside {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
            flex: 0 0 60%;
    max-width: 60%;
    overflow: hidden;
  }
  .box-layout .page-wrapper .page-body-wrapper .caller-img, .box-layout.page-wrapper .page-body-wrapper .caller-img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    left: 15px;
  }
  .box-layout .page-wrapper .page-body-wrapper .caller-img img, .box-layout.page-wrapper .page-body-wrapper .caller-img img {
    opacity: 0.7;
  }
  .box-layout .page-wrapper .page-body-wrapper .browser-widget img, .box-layout.page-wrapper .page-body-wrapper .browser-widget img {
    height: 65px;
  }
  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-header img, .box-layout.page-wrapper .page-body-wrapper .custom-card .card-header img {
    margin-top: -73px;
  }
  .box-layout .page-wrapper .page-body-wrapper .custom-card .card-profile img, .box-layout.page-wrapper .page-body-wrapper .custom-card .card-profile img {
    height: 130px;
    top: -17px;
  }
  .box-layout .page-wrapper .page-body-wrapper .select2, .box-layout.page-wrapper .page-body-wrapper .select2 {
    width: 901.781px;
  }
  .box-layout .page-wrapper ul.close_icon > li label, .box-layout.page-wrapper ul.close_icon > li label {
    padding: 0;
  }
  .box-layout .page-wrapper ul.close_icon > li label:after, .box-layout.page-wrapper ul.close_icon > li label:after {
    display: none;
  }
  .box-layout .page-wrapper .bookmark ul, .box-layout.page-wrapper .bookmark ul {
    margin-right: -1px;
  }
  .box-layout .page-wrapper #batchDelete .jsgrid-grid-header .jsgrid-table tr th .btn, .box-layout.page-wrapper #batchDelete .jsgrid-grid-header .jsgrid-table tr th .btn {
    padding-left: 20px;
    padding-right: 20px;
  }
  .box-layout .page-wrapper .btn-group-showcase .btn-radio .btn-group .radio input[type=radio], .box-layout.page-wrapper .btn-group-showcase .btn-radio .btn-group .radio input[type=radio] {
    display: none;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph, .box-layout.page-wrapper .d-flex.bitcoin-graph {
    display: block;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .top-bitcoin, .box-layout.page-wrapper .d-flex.bitcoin-graph .top-bitcoin {
    display: inline-block;
    vertical-align: middle;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .flex-grow-1, .box-layout.page-wrapper .d-flex.bitcoin-graph .flex-grow-1 {
    margin-top: 30px;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content .bitcoin-numbers h6, .box-layout.page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content .bitcoin-numbers h6 {
    font-size: 14PX;
  }
  .box-layout .page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content.text-right, .box-layout.page-wrapper .d-flex.bitcoin-graph .flex-grow-1 .bitcoin-content.text-right {
    text-align: center !important;
  }
  .box-layout .page-wrapper .alert.inverse p, .box-layout.page-wrapper .alert.inverse p {
    max-width: 238px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .box-layout .page-wrapper .auth-bg-effect .second-effect, .box-layout.page-wrapper .auth-bg-effect .second-effect {
    left: 55%;
  }
  .box-layout .page-wrapper .auth-bg-video video, .box-layout.page-wrapper .auth-bg-video video {
    min-width: 67%;
    width: 67%;
  }
  .box-layout .page-wrapper .page-builder .ge-canvas.ge-layout-desktop, .box-layout.page-wrapper .page-builder .ge-canvas.ge-layout-desktop {
    margin-top: 40px;
  }
  .box-layout .page-wrapper .page-builder .ge-addRowGroup, .box-layout.page-wrapper .page-builder .ge-addRowGroup {
    margin-bottom: 10px;
  }
  .box-layout .page-wrapper .pricing-wrapper-card, .box-layout.page-wrapper .pricing-wrapper-card {
    padding: 50px 20px;
  }
  .box-layout .page-wrapper .card .blog-box.blog-grid.set-min-height, .box-layout.page-wrapper .card .blog-box.blog-grid.set-min-height {
    min-height: 400px;
  }
  .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris-daily, .box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris-daily {
    min-height: 430px;
  }
  .box-layout .page-wrapper .flot-chart-placeholder#donut-color-chart-morris, .box-layout.page-wrapper .flot-chart-placeholder#donut-color-chart-morris {
    min-height: 430px;
  }
  .box-layout .page-wrapper .box-col-12, .box-layout.page-wrapper .box-col-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .box-layout .page-wrapper .box-col-6, .box-layout.page-wrapper .box-col-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .box-layout .page-wrapper .box-col-3, .box-layout.page-wrapper .box-col-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .box-layout .page-wrapper .box-col-7, .box-layout.page-wrapper .box-col-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 60%;
            flex: 0 0 60%;
    max-width: 60%;
  }
  .box-layout .page-wrapper .box-col-5, .box-layout.page-wrapper .box-col-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 40%;
            flex: 0 0 40%;
    max-width: 40%;
  }
  .box-layout .page-wrapper .box-col-8, .box-layout.page-wrapper .box-col-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 70%;
            flex: 0 0 70%;
    max-width: 70%;
  }
  .box-layout .page-wrapper .box-col-4e, .box-layout.page-wrapper .box-col-4e {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    max-width: 30%;
  }
  .box-layout .page-wrapper .box-col-4, .box-layout.page-wrapper .box-col-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33%;
            flex: 0 0 33.33%;
    max-width: 33%;
  }
  .box-layout .page-wrapper .box-col-8e, .box-layout.page-wrapper .box-col-8e {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.67%;
            flex: 0 0 66.67%;
    max-width: 66.67%;
  }
  .box-layout .page-wrapper .box-col-none, .box-layout.page-wrapper .box-col-none {
    display: none !important;
  }
  .box-layout .page-wrapper .chat-box .chat-right-aside, .box-layout.page-wrapper .chat-box .chat-right-aside {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: calc(100% - 15px);
    overflow: hidden;
  }
  .box-layout .page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i, .box-layout.page-wrapper .chat-box .chat-right-aside .chat .chat-header .chat-menu-icons li a i {
    font-size: 19px;
  }
  .box-layout .page-wrapper .chat-box .toogle-bar, .box-layout.page-wrapper .chat-box .toogle-bar {
    display: inline-block;
    margin-right: 0 !important;
  }
  .box-layout .page-wrapper .chat-menu, .box-layout.page-wrapper .chat-menu {
    right: 0;
    border-top: 1px solid #ddd;
    opacity: 0;
    -webkit-transform: translateY(-30px);
            transform: translateY(-30px);
    visibility: hidden;
    top: 81px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
  }
  .box-layout .page-wrapper .chat-menu.show, .box-layout.page-wrapper .chat-menu.show {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    -webkit-transition: all linear 0.3s;
    transition: all linear 0.3s;
    padding-bottom: 25px;
  }
  .box-layout .page-wrapper .ct-10.total-chart .ct-chart-bar .ct-series .ct-bar, .box-layout.page-wrapper .ct-10.total-chart .ct-chart-bar .ct-series .ct-bar {
    stroke-width: 23px !important;
  }
  .box-layout .page-wrapper .social-app-profile #friends .box-col-4, .box-layout.page-wrapper .social-app-profile #friends .box-col-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 30%;
            flex: 0 0 30%;
    max-width: 30%;
  }
  .box-layout .page-wrapper .email-wrap .email-body .email-compose .cke_contents.cke_reset, .box-layout.page-wrapper .email-wrap .email-body .email-compose .cke_contents.cke_reset {
    max-height: 165px;
  }
  .box-layout .page-wrapper .email-wrap .row .col-xl-6, .box-layout.page-wrapper .email-wrap .row .col-xl-6 {
    padding-left: 12px;
  }
  .box-layout .page-wrapper .email-wrap .row .col-xl-3 + .col-xl-3, .box-layout.page-wrapper .email-wrap .row .col-xl-3 + .col-xl-3 {
    padding-right: 12px;
  }
  .box-layout .page-wrapper .email-wrap .email-right-aside .email-body .inbox, .box-layout.page-wrapper .email-wrap .email-right-aside .email-body .inbox {
    height: 644px;
  }
  .box-layout .page-wrapper .email-wrap .email-content .email-top .user-emailid:after, .box-layout.page-wrapper .email-wrap .email-content .email-top .user-emailid:after {
    right: -10px;
  }
  .box-layout .page-wrapper .todo .notification-popup, .box-layout.page-wrapper .todo .notification-popup {
    right: 320px;
  }
  .box-layout .page-wrapper .touchspin, .box-layout.page-wrapper .touchspin {
    padding: 0 10px;
  }
  .box-layout .page-wrapper .vertical-menu-main, .box-layout.page-wrapper .vertical-menu-main {
    width: 1280px;
    margin: 0 auto;
    left: 0;
    right: 0;
  }
  .box-layout .page-wrapper .vertical-menu-main .mega-menu, .box-layout.page-wrapper .vertical-menu-main .mega-menu {
    width: 1050px !important;
    max-width: 1050px !important;
    left: -320px !important;
  }
  .box-layout .page-wrapper .comingsoon video, .box-layout.page-wrapper .comingsoon video {
    min-width: 67%;
    width: 67%;
  }
  .box-layout .proorder-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .box-layout .proorder-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .box-layout .proorder-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
  .box-layout .proorder-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4;
  }
  .box-layout .proorder-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5;
  }
  .box-layout .proorder-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6;
  }
  .box-layout .proorder-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7;
  }
  .box-layout .proorder-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8;
  }
  .box-layout .proorder-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9;
  }
  .box-layout .proorder-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10;
  }
  .box-layout .proorder-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11;
  }
  .box-layout .proorder-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12;
  }
  .box-layout .proorder-xl-13 {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13;
  }
  .box-layout .col-xl-100 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }
  .box-layout .col-xl-50 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }
  .box-layout .col-xl-40 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }
  .box-layout .col-xl-33 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.333333%;
  }
  .box-layout .col-xl-70 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }
  .box-layout .proorder-4-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  .box-layout .default-dashboard .profile-greeting .card-body {
    height: 240px;
  }
  /* .box-layout .default-dashboard .profile-greeting .img-overlay {
    background: rgba(122, 112, 186, 0.25);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    border-radius: 10px;
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 15px;
  } */

  .box-layout .default-dashboard .profile-greeting .img-overlay p {
    margin: 0 auto;
    /* width: 275px; */
    text-align: center;
  }
  .box-layout .default-dashboard .profile-greeting .img-overlay .btn {
    margin: 10px auto 0;
  }
  .box-layout .default-dashboard .profile-greeting .img-overlay .btn:hover {
    background-color: #337ab7;
    color: #fff;
  }
  .box-layout .default-dashboard .shifts-char-box .col-5 {
    width: 100%;
  }
  .box-layout .default-dashboard .shifts-overview {
    display: none;
  }
  .box-layout .default-dashboard .projects table thead tr th:nth-child(5) {
    display: none;
  }
  .box-layout .default-dashboard .projects table tbody tr td:nth-child(5) {
    display: none;
  }
  .box-layout .default-dashboard .notification-box li.d-flex .flex-grow-1 p {
    display: block;
    width: 50%;
  }
  .box-layout .default-dashboard .active-members table thead tr th:nth-child(2) {
    display: none;
  }
  .box-layout .default-dashboard .active-members table tbody tr td:nth-child(2) {
    display: none;
  }
  .box-layout .default-dashboard .active-members table tbody tr p.members-box {
    padding: 7px 24px;
  }
  .box-layout .default-dashboard .appointments .datepicker-here .datepicker-inline .datepicker .datepicker--nav {
    top: -22px;
  }
  .box-layout .default-dashboard .appointments .appointments-user li.d-flex .flex-grow-1 p {
    display: none;
  }
  .box-layout .default-dashboard .sales-product table tbody tr:nth-child(4) td {
    border-bottom: none;
  }
  .box-layout .default-dashboard .sales-product table tbody tr:last-child {
    display: none;
  }
  .box-layout .default-dashboard .sale-statistic .statistic-icon .balance-card {
    gap: 0px;
  }
  .box-layout .default-dashboard .opening-box .d-flex {
    display: none !important;
  }
  .box-layout .default-dashboard .location-menu {
    display: none !important;
  }
  .box-layout .dashboard-2 .projects table thead tr th:nth-child(5) {
    display: none;
  }
  .box-layout .dashboard-2 .projects tbody tr td:nth-child(5) {
    display: none;
  }
  .box-layout .dashboard-2 .total-project ul li {
    padding: 12px 4px;
  }
  .box-layout .dashboard-2 .upcoming-box p {
    font-size: 14px;
  }
  .box-layout .dashboard-2 .designer-card .design-button .btn {
    padding: 6px 12px;
  }
  .box-layout .dashboard-2 .running-box .d-flex .flex-shrink-0 > .customers {
    display: none;
  }
  .box-layout .dashboard-2 .running-box > ul li {
    padding: 2px 6px;
  }
  .box-layout .dashboard-2 .running-box div .btn {
    padding: 6px 10px;
  }
  .box-layout .dashboard-2 .back-bar-container {
    bottom: 44px;
  }
  .box-layout .dashboard-3 .total-sells .d-flex .flex-shrink-0,
  .box-layout .dashboard-3 .total-sells-4 .d-flex .flex-shrink-0,
  .box-layout .dashboard-3 .total-sells-3 .d-flex .flex-shrink-0,
  .box-layout .dashboard-3 .total-sells-2 .d-flex .flex-shrink-0 {
    width: 45px;
    height: 45px;
  }
  .box-layout .dashboard-3 .total-sells .d-flex .flex-shrink-0 img,
  .box-layout .dashboard-3 .total-sells-4 .d-flex .flex-shrink-0 img,
  .box-layout .dashboard-3 .total-sells-3 .d-flex .flex-shrink-0 img,
  .box-layout .dashboard-3 .total-sells-2 .d-flex .flex-shrink-0 img {
    width: 28px;
  }
  .box-layout .dashboard-3 .total-sells .d-flex .flex-grow-1 h2,
  .box-layout .dashboard-3 .total-sells-4 .d-flex .flex-grow-1 h2,
  .box-layout .dashboard-3 .total-sells-3 .d-flex .flex-grow-1 h2,
  .box-layout .dashboard-3 .total-sells-2 .d-flex .flex-grow-1 h2 {
    font-size: 22px;
  }
  .box-layout .dashboard-3 .total-sells .d-flex .flex-grow-1 .total-icon,
  .box-layout .dashboard-3 .total-sells-4 .d-flex .flex-grow-1 .total-icon,
  .box-layout .dashboard-3 .total-sells-3 .d-flex .flex-grow-1 .total-icon,
  .box-layout .dashboard-3 .total-sells-2 .d-flex .flex-grow-1 .total-icon {
    display: none !important;
  }
  .box-layout .dashboard-3 .total-sells .d-flex .flex-grow-1 p,
  .box-layout .dashboard-3 .total-sells-4 .d-flex .flex-grow-1 p,
  .box-layout .dashboard-3 .total-sells-3 .d-flex .flex-grow-1 p,
  .box-layout .dashboard-3 .total-sells-2 .d-flex .flex-grow-1 p {
    display: block;
    width: 50%;
  }
  .box-layout .dashboard-3 .recent-customers li {
    padding: 5px 0px;
  }
  .box-layout .dashboard-3 .recent-customers li.d-flex .active-status {
    top: 9px;
    left: 25px;
  }
  .box-layout .dashboard-3 .recent-customers li.d-flex .flex-shrink-0 img {
    height: 32px;
  }
  .box-layout .dashboard-3 .recent-customers li.d-flex .flex-grow-1 p {
    width: 50%;
  }
  .box-layout .dashboard-3 .recent-customers li.d-flex div.recent-text {
    display: none;
  }
  .box-layout .dashboard-3 .product-slider .product-page-main .row .col-3 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
    width: 100%;
  }
  .box-layout .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right div {
    margin: 0 2px !important;
  }
  .box-layout .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right div:first-child {
    margin-left: 0px !important;
  }
  .box-layout .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list {
    height: 91px !important;
  }
  .box-layout .dashboard-3 .product-slider .product-page-main .row .col-3 .pro-slide-right .slick-list .slick-track {
    height: 81px !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .box-layout .dashboard-3 .product-slider .product-page-main .row .col-9 {
    width: 100%;
  }
  .box-layout .dashboard-3 .product-slider .pro-slide-right img {
    height: 70px;
  }
  .box-layout .dashboard-3 .product-slider .product-details {
    margin: 0 !important;
  }
  .box-layout .dashboard-3 .product-slider .product-details a h4 {
    display: block;
    width: 90%;
  }
  .box-layout .dashboard-3 .product-slider .product-details h3 {
    display: block;
    width: 90%;
  }
  .box-layout .dashboard-3 .product-slider .product-details > .countdown ul li {
    padding: 3px 2px !important;
    margin: 0 2px 0 !important;
  }
  .box-layout .dashboard-3 .user-continent .user-map-menu {
    border-bottom: none;
    display: none !important;
  }
  .box-layout .dashboard-3 .user-continent .user-map-menu .user-items {
    display: none;
  }
  .box-layout .dashboard-3 .user-continent .user-map-menu .map-items {
    display: none;
  }
  .box-layout .dashboard-3 .user-continent .contries-sale {
    margin-top: 8px;
  }
  .box-layout .dashboard-3 .user-continent .contries-sale .map-value {
    display: none;
  }
  .box-layout .dashboard-3 .user-continent .contries-sale .map-value ul li .d-flex span {
    display: none;
  }
  .box-layout .dashboard-3 .revenue-category #pie-chart {
    min-height: 220px !important;
  }
  .box-layout .dashboard-3 .revenue-category .donut-legend {
    display: none;
  }
  .box-layout .dashboard-3 .revenue-category .donut-legend > span {
    margin-bottom: 0px;
    margin-right: 10px;
  }
  .box-layout .dashboard-3 .recent-orders table thead tr th:nth-child(4), .box-layout .dashboard-3 .recent-orders table thead tr th:nth-child(5) {
    display: none;
  }
  .box-layout .dashboard-3 .recent-orders tbody tr td:nth-child(4), .box-layout .dashboard-3 .recent-orders tbody tr td:nth-child(5) {
    display: none;
  }
  .box-layout .dashboard-3 .top-sellers h4 {
    display: block;
    width: 135px;
  }
  .box-layout .dashboard-3 .seller-month table thead tr th:nth-child(3) {
    display: none;
  }
  .box-layout .dashboard-3 .seller-month table thead tr th:last-child {
    display: none;
  }
  .box-layout .dashboard-3 .seller-month table tbody tr td:nth-child(3) {
    display: none;
  }
  .box-layout .dashboard-3 .seller-month table tbody tr td:last-child {
    display: none;
  }
  .box-layout .dashboard-4 .student .d-flex .flex-grow-1 > p,
  .box-layout .dashboard-4 .student-2 .d-flex .flex-grow-1 > p,
  .box-layout .dashboard-4 .student-3 .d-flex .flex-grow-1 > p,
  .box-layout .dashboard-4 .student-4 .d-flex .flex-grow-1 > p {
    display: block;
    width: 88px;
  }
  .box-layout .dashboard-4 .student .d-flex .flex-grow-1 .student-arrow,
  .box-layout .dashboard-4 .student-2 .d-flex .flex-grow-1 .student-arrow,
  .box-layout .dashboard-4 .student-3 .d-flex .flex-grow-1 .student-arrow,
  .box-layout .dashboard-4 .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: none !important;
  }
  .box-layout .dashboard-4 .enrolled-class li.d-flex .flex-grow-1 a h5 {
    display: block;
    width: 140px;
  }
  .box-layout .dashboard-4 .live-meet .live-metting .star-img img:first-child {
    top: 35%;
    left: 6%;
    width: 56px;
  }
  .box-layout .dashboard-4 .assignments-table table thead tr th:nth-child(6) {
    display: none;
  }
  .box-layout .dashboard-4 .assignments-table table thead tr th:last-child {
    display: none;
  }
  .box-layout .dashboard-4 .assignments-table table tbody tr:last-child {
    display: none;
  }
  .box-layout .dashboard-4 .assignments-table table tbody tr td:nth-child(6) {
    display: none;
  }
  .box-layout .dashboard-4 .assignments-table table tbody tr td:last-child {
    display: none;
  }
  .box-layout .dashboard-4 .featured-table table thead tr th:nth-child(4) {
    display: none;
  }
  .box-layout .dashboard-4 .featured-table table tbody tr:nth-child(3) {
    display: none;
  }
  .box-layout .dashboard-4 .featured-table table tbody tr td:nth-child(4) {
    display: none;
  }
  .box-layout .total-sells .d-flex .flex-shrink-0,
  .box-layout .total-sells-4 .d-flex .flex-shrink-0,
  .box-layout .total-sells-3 .d-flex .flex-shrink-0,
  .box-layout .total-sells-2 .d-flex .flex-shrink-0 {
    width: 45px;
    height: 45px;
  }
  .box-layout .total-sells .d-flex .flex-shrink-0 img,
  .box-layout .total-sells-4 .d-flex .flex-shrink-0 img,
  .box-layout .total-sells-3 .d-flex .flex-shrink-0 img,
  .box-layout .total-sells-2 .d-flex .flex-shrink-0 img {
    width: 28px;
  }
  .box-layout .total-sells .d-flex .flex-grow-1 h2,
  .box-layout .total-sells-4 .d-flex .flex-grow-1 h2,
  .box-layout .total-sells-3 .d-flex .flex-grow-1 h2,
  .box-layout .total-sells-2 .d-flex .flex-grow-1 h2 {
    font-size: 22px;
  }
  .box-layout .total-sells .d-flex .flex-grow-1 .total-icon,
  .box-layout .total-sells-4 .d-flex .flex-grow-1 .total-icon,
  .box-layout .total-sells-3 .d-flex .flex-grow-1 .total-icon,
  .box-layout .total-sells-2 .d-flex .flex-grow-1 .total-icon {
    display: none !important;
  }
  .box-layout .total-sells .d-flex .flex-grow-1 p,
  .box-layout .total-sells-4 .d-flex .flex-grow-1 p,
  .box-layout .total-sells-3 .d-flex .flex-grow-1 p,
  .box-layout .total-sells-2 .d-flex .flex-grow-1 p {
    display: block;
    width: 50%;
  }
  .box-layout .student .d-flex .flex-grow-1 > p,
  .box-layout .student-2 .d-flex .flex-grow-1 > p,
  .box-layout .student-3 .d-flex .flex-grow-1 > p,
  .box-layout .student-4 .d-flex .flex-grow-1 > p {
    display: block;
    width: 88px;
  }
  .box-layout .student .d-flex .flex-grow-1 .student-arrow,
  .box-layout .student-2 .d-flex .flex-grow-1 .student-arrow,
  .box-layout .student-3 .d-flex .flex-grow-1 .student-arrow,
  .box-layout .student-4 .d-flex .flex-grow-1 .student-arrow {
    display: none !important;
  }
  .box-layout .designer-card .d-flex {
    margin-bottom: 10px;
  }
  .box-layout .designer-card .design-button {
    margin-bottom: 10px;
  }
  .box-layout .designer-card .design-button .btn {
    padding: 6px 10px;
  }
  .box-layout .designer-card .ratting-button {
    margin-bottom: 8px;
    gap: 2px;
  }
}

/**=====================
  5.9 Box-layout CSS Ends
==========================**/








/*MENU*/
.layout {
  z-index: 1;
}
.layout .header {
  display: flex;
  align-items: center;
  padding: 20px;
}
.layout .content {
  padding: 12px 50px;
  display: flex;
  flex-direction: column;
}
.layout .footer {
  text-align: center;
  margin-top: auto;
  margin-bottom: 20px;
  padding: 20px;
}
.sidebar {
  color: #7d84ab;
  overflow-x: hidden !important;
  position: relative;
}
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 4px;
}
.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: #1a4173;
}
.sidebar::-webkit-scrollbar {
  width: 6px;
  background-color: #0c1e35;
}
.sidebar .image-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}
.sidebar .image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.sidebar.has-bg-image .image-wrapper {
  display: block;
}
.sidebar .sidebar-layout {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #0c1e35;
  z-index: 2;
}
.sidebar .sidebar-layout .sidebar-header {
  height: 100px;
  min-height: 100px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.sidebar .sidebar-layout .sidebar-header > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.sidebar .sidebar-layout .sidebar-content {
  flex-grow: 1;
  padding: 10px 0;
}
.sidebar .sidebar-layout .sidebar-footer {
  height: 230px;
  min-height: 230px;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.sidebar .sidebar-layout .sidebar-footer > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
@keyframes swing {
  0%, 30%, 50%, 70%, 100% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(10deg);
  }
  40% {
    transform: rotate(-10deg);
  }
  60% {
    transform: rotate(5deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}
.layout .sidebar .menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.layout .sidebar .menu .menu-header {
  font-weight: 600;
  padding: 10px 25px;
  font-size: 0.8em;
  letter-spacing: 2px;
  transition: opacity 0.3s;
  opacity: 0.5;
}
.layout .sidebar .menu .menu-item a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  color: #7d84ab;
}
.layout .sidebar .menu .menu-item a .menu-icon {
  font-size: 1.2rem;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  border-radius: 2px;
  transition: color 0.3s;
}
.layout .sidebar .menu .menu-item a .menu-icon i {
  display: inline-block;
}
.layout .sidebar .menu .menu-item a .menu-title {
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  transition: color 0.3s;
}
.layout .sidebar .menu .menu-item a .menu-prefix, .layout .sidebar .menu .menu-item a .menu-suffix {
  display: inline-block;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.3s;
}
.layout .sidebar .menu .menu-item a:hover .menu-title {
  color: #dee2ec;
}
.layout .sidebar .menu .menu-item a:hover .menu-icon {
  color: #dee2ec;
}
.layout .sidebar .menu .menu-item a:hover .menu-icon i {
  animation: swing ease-in-out 0.5s 1 alternate;
}
.layout .sidebar .menu .menu-item a:hover::after {
  border-color: #dee2ec !important;
}
.layout .sidebar .menu .menu-item.sub-menu {
  position: relative;
}
.layout .sidebar .menu .menu-item.sub-menu > a::after {
  content: '';
  transition: transform 0.3s;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  width: 5px;
  height: 5px;
  transform: rotate(-45deg);
}
.layout .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
  padding-left: 20px;
  display: none;
  overflow: hidden;
  z-index: 999;
}
.layout .sidebar .menu .menu-item.sub-menu.open > a {
  color: #dee2ec;
}
.layout .sidebar .menu .menu-item.sub-menu.open > a::after {
  transform: rotate(45deg);
}
.layout .sidebar .menu .menu-item.active > a .menu-title {
  color: #dee2ec;
}
.layout .sidebar .menu .menu-item.active > a::after {
  border-color: #dee2ec;
}
.layout .sidebar .menu .menu-item.active > a .menu-icon {
  color: #dee2ec;
}
.layout .sidebar .menu > ul > .sub-menu > .sub-menu-list {
  background-color: #0b1a2c;
}
.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon, .layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon, .layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: #0b1a2c;
}
.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon {
  border-radius: 50%;
}
.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon {
  border-radius: 4px;
}
.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  border-radius: 0;
}
.layout .sidebar:not(.collapsed) .menu > ul > .menu-item.sub-menu > .sub-menu-list {
  visibility: visible !important;
  position: static !important;
  transform: translate(0, 0) !important;
}
.layout .sidebar.collapsed .menu > ul > .menu-header {
  opacity: 0;
}
.layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-prefix, .layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-suffix {
  opacity: 0;
}
.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a::after {
  content: '';
  width: 5px;
  height: 5px;
  background-color: currentcolor;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  border: none;
  transform: translateY(-50%);
}
.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a:hover::after {
  background-color: #dee2ec;
}
.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > .sub-menu-list {
  transition: none !important;
  width: 200px;
  margin-left: 3px !important;
  border-radius: 4px;
  display: block !important;
}
.layout .sidebar.collapsed .menu > ul > .menu-item.active > a::after {
  background-color: #dee2ec;
}
.layout .sidebar.has-bg-image .menu.icon-shape-circle .menu-item a .menu-icon, .layout .sidebar.has-bg-image .menu.icon-shape-rounded .menu-item a .menu-icon, .layout .sidebar.has-bg-image .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: rgba(11, 26, 44, 0.6);
}
.layout .sidebar.has-bg-image:not(.collapsed) .menu > ul > .sub-menu > .sub-menu-list {
  background-color: rgba(11, 26, 44, 0.6);
}
.layout.rtl .sidebar .menu .menu-item a .menu-icon {
  margin-left: 10px;
  margin-right: 0;
}
.layout.rtl .sidebar .menu .menu-item.sub-menu > a::after {
  transform: rotate(135deg);
}
.layout.rtl .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
  padding-left: 0;
  padding-right: 20px;
}
.layout.rtl .sidebar .menu .menu-item.sub-menu.open > a::after {
  transform: rotate(45deg);
}
.layout.rtl .sidebar.collapsed .menu > ul > .menu-item.sub-menu a::after {
  right: auto;
  left: 10px;
}
.layout.rtl .sidebar.collapsed .menu > ul > .menu-item.sub-menu > .sub-menu-list {
  margin-left: -3px !important;
}
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  color: #3f4750;
  font-size: 0.9rem;
}
a {
  text-decoration: none;
}
@media (max-width: 576px) {
  #btn-collapse {
    display: none;
  }
}
.layout .sidebar .pro-sidebar-logo {
  display: flex;
  align-items: center;
}
.layout .sidebar .pro-sidebar-logo > div {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #ff8100;
  margin-right: 10px;
}
.layout .sidebar .pro-sidebar-logo > h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 30px;
  transition: opacity 0.3s;
  opacity: 1;
}
.layout .sidebar .footer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8em;
  padding: 20px 0;
  border-radius: 8px;
  width: 180px;
  min-width: 190px;
  margin: 0 auto;
  background-color: #162d4a;
}
.layout .sidebar .footer-box img.react-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.layout .sidebar .footer-box a {
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}
.layout .sidebar .sidebar-collapser {
  transition: left, right, 0.3s;
  position: fixed;
  left: 260px;
  top: 40px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #00829f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  transform: translateX(50%);
  z-index: 111;
  cursor: pointer;
  color: white;
  box-shadow: 1px 1px 4px #0c1e35;
}
.layout .sidebar.collapsed .pro-sidebar-logo > h5 {
  opacity: 0;
}
.layout .sidebar.collapsed .footer-box {
  display: none;
}
.layout .sidebar.collapsed .sidebar-collapser {
  left: 60px;
}
.layout .sidebar.collapsed .sidebar-collapser i {
  transform: rotate(180deg);
}
.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #6c757d;
}
.badge.primary {
  background-color: #ab2dff;
}
.badge.secondary {
  background-color: #079b0b;
}
.sidebar-toggler {
  position: fixed;
  right: 20px;
  top: 20px;
}
.social-links a {
  margin: 0 10px;
  color: #3f4750;
}
/*MENU*/


.show-menu {
  display: block;
}
.rotate-icon {
  transform: rotate(90deg);
}
.fa-angle-right {
  transition: transform 0.3s ease-in-out;
}




.table_dropdown .dropdown-item{
  color: #222 !important;
  font-weight: 600 !important;
  padding-left: 20px !important;
  font-size: 14px !important;
  position: relative;
  top: -2px;
}
.table_dropdown .dropdown-item:hover{
  color: #2491e4 !important;
}
.table_dropdown .dropdown-menu i{
  font-size: 15px !important;
  font-weight: 600 !important;
  position: relative;
  left: -5px;
  top: 2px;
}






.react-clock {
  display: block;
  position: relative;
}

.react-clock,
.react-clock *,
.react-clock *:before,
.react-clock *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-clock__face {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border: 3px solid white;
  border-radius: 50%;
  background-color: #22345b;
}

.react-clock__hand {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}

.react-clock__hand__body {
  position: absolute;
  background-color: white;
  transform: translateX(-50%);
  border: 1.5px solid white;
}

.react-clock__mark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 50%;
}

.react-clock__mark__body {
  position: absolute;
  background-color: white;
  transform: translateX(-50%);
}

.react-clock__mark__number {
  position: absolute;
  left: -40px;
  width: 80px;
  text-align: center;
}

.react-clock__second-hand__body {
  background-color: red;
  border: 1px solid red;
}




.Sample__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0px 0;
  padding: 0px;
}

.Sample__container > * > * {
  margin: 10px;
}

.Sample__container__content {
  display: flex;
  max-width: 100%;
  flex-basis: 420px;
  flex-direction: column;
  flex-grow: 100;
  align-items: stretch;
  padding-top: 1em;
}

.Sample__container__content .react-clock {
  margin: 0 auto;
}

.date-display {
  font-size: 17px;
  color: #333;
  margin-bottom: -8px;
  font-weight: bold;
  text-align: center;
  margin-top: -20px;
}
.time-display {
  font-size: 1.4em;
  color: #333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 7px;
}






