
/*======================
    404 page
=======================*/

@import url('https://fonts.googleapis.com/css?family=Arvo');

body {
  background:#fff;
}

.page_404{ 
  padding:40px 0; background:#fff; font-family: 'Arvo', serif !important;
  font-size: 17px;
  height: 100vh;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(../../images/dribbble_img.gif);
    height: 400px;
    background-position: center;
 }
 
 
 .four_zero_four_bg h1{
  font-size:80px;
  font-weight: 600;
 }
 
  h3{
		font-size:27px !important;
    font-weight: 600 !important;
  }
			 
  .link_404{			 
      color: #fff!important;
      padding: 15px 2.6em;
      background: #3160ac;
      margin: 20px 0;
      display: inline-block;
      font-size: 16px;
      border-radius: 5px;
    }

    .contant_box_404{ 
      margin-top:-50px;
    }